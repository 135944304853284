import { createAction, createSlice } from '@reduxjs/toolkit'
import { INotificationsSlice } from './interfaces'

const initialState = {} as INotificationsSlice

const notifications = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        getNotificationsData: (state) => {
            return state
        },
        updateNotifications: (state, action) => {
            return { ...state, ...action.payload }
        },
        dropNotifications: () => {
            return { ...initialState }
        },
    },
})

interface INotificationRequest {
    offset?: number
    limit?: number
}

interface INotificationMessage {
    title: string
    message: string
    links: string[]
}

export const getNotifications = createAction<INotificationRequest>('notifications/getNotifications')

export const addNewMessage = createAction<INotificationMessage>('notifications/addNewMessage')

export const { getNotificationsData, updateNotifications, dropNotifications } =
    notifications.actions

export default notifications.reducer
