import { RequestTypes } from '../hooks/useRequest'

export const BILLING_API_PREFIX = '/api/billing/v1/'
export const TARIFFS_CREDENTIALS = 'payment-tariffs'

export const getTariffsSettings = {
    link: `${BILLING_API_PREFIX}${TARIFFS_CREDENTIALS}`,
    method: RequestTypes.get,
}

export const setTariffsSettings = {
    link: `${BILLING_API_PREFIX}${TARIFFS_CREDENTIALS}`,
    method: RequestTypes.post,
}
