import { FC } from 'react'
import { Tag } from 'antd'
import { colorPicker } from '../../../helpers'
import style from './style.m.less'

interface IPowerTypeTag {
    value: string
    customStyle?: React.CSSProperties
}

const PowerTypeTag: FC<IPowerTypeTag> = ({ value, customStyle }) => {
    return (
        <Tag color={colorPicker(value)} style={customStyle} className={style.typeTag}>
            {value}
        </Tag>
    )
}

export default PowerTypeTag
