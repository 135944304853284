import { configureStore, combineReducers } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import sagas from './sagas'
import auth from './auth'
import generatorsList from './generators'
import user from './user'
import dictionaries from './dictionaries'
import notifications from './notifications'
import accounts from './accounts'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
    auth,
    generatorsList,
    user,
    dictionaries,
    notifications,
    accounts,
})

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})
sagaMiddleware.run(sagas)

export default store
