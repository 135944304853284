import { FC } from 'react'
import { Input } from 'antd'
import style from './style.m.less'

interface IThreePartsBlock {
    labels: string[]
}

const ThreePartsBlock: FC<IThreePartsBlock> = ({ labels }) => {
    return (
        <div className={style.inputsWrapperShort}>
            <div>
                <div className={style.inputBlock}>
                    {labels[0]}
                    <div className={style.star}>*</div>
                </div>
                <Input className={style.inputMiddle} maxLength={10} />
            </div>
            <div>
                <div className={style.inputBlock}>
                    {labels[1]}
                    <div className={style.star}>*</div>
                </div>
                <Input className={style.inputMiddle} maxLength={10} />
            </div>
            <div>
                <div className={style.inputBlock}>
                    {labels[2]}
                    <div className={style.star}>*</div>
                </div>
                <Input className={style.inputMiddle} maxLength={13} />
            </div>
        </div>
    )
}

export default ThreePartsBlock
