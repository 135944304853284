// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoBlockTitle_TYhQ7 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.infoBlockTitleWrapper_mza3y {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n  margin-bottom: 24px;\n}\n.infoBlockItem_zt4cB {\n  margin-bottom: 16px;\n}\n.infoBlockItemWrapper_TpVEu {\n  display: flex;\n  gap: 24px;\n  margin-bottom: 16px;\n}\n.infoBlockItemName_d7bn6 {\n  width: 220px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GODataKUPeriod/components/InfoBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAGA;EACI,mBAAA;AADJ;AAIA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AAFJ;AAKA;EACI,YAAA;AAHJ","sourcesContent":[".infoBlockTitle {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.infoBlockTitleWrapper {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n.infoBlockItem {\n    margin-bottom: 16px;\n}\n\n.infoBlockItemWrapper {\n    display: flex;\n    gap: 24px;\n    margin-bottom: 16px;\n}\n\n.infoBlockItemName {\n    width: 220px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlockTitle": "infoBlockTitle_TYhQ7",
	"infoBlockTitleWrapper": "infoBlockTitleWrapper_mza3y",
	"infoBlockItem": "infoBlockItem_zt4cB",
	"infoBlockItemWrapper": "infoBlockItemWrapper_TpVEu",
	"infoBlockItemName": "infoBlockItemName_d7bn6"
};
export default ___CSS_LOADER_EXPORT___;
