import React from 'react'
import { GrennBlock, VolcanoBlock } from './ObjectBlock'
import { TariffsActiveType, convertDate } from './RealisationCard'
import moment from 'moment'

type Props = {
    isSeller: boolean
    isBuyer: boolean
    is_buyer_tax_payer: boolean
    consumersNotZero: boolean
    pay_before: string
    tariffsActive: TariffsActiveType
    isNewVolume: boolean
    seller_fee_status: number
    buyer_fee_status: number
    rateNumber: number
}

export enum FeeStatus {
    NotPayed = 0,
    Reserved = 1,
    Payed = 2,
    Canceled = 3,
}

const AGHeader = ({
    isSeller,
    isBuyer,
    is_buyer_tax_payer,
    consumersNotZero,
    pay_before,
    tariffsActive,
    isNewVolume,
    seller_fee_status,
    buyer_fee_status,
    rateNumber,
}: Props) => {
    const isTransferCommisionActive = tariffsActive.transfer
    const isRealisationCommisionActive = tariffsActive.realisation
    const comissionOff = !isTransferCommisionActive && !isRealisationCommisionActive
    const lowerTheThirdControlDate = moment(pay_before).unix() > moment().unix()
    const realisationFinished =
        [FeeStatus.Reserved, FeeStatus.Payed].includes(buyer_fee_status) && // Продавец оплатил комиссию “Реализация АГ по ЗД“
        [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && //  Продавец оплатил комиссию “Передача АГ по ЗД“
        consumersNotZero // Покупатель указал конечных потребителей по ЗД

    if (isSeller) {
        if (realisationFinished) {
            return <GrennBlock text="Реализация прошла успешно" />
        }

        if (
            comissionOff || // Комиссии выключены
            (!isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Покупатель)
        ) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                seller_fee_status === FeeStatus.NotPayed && // Комиссии “Передача АГ по ЗД“ еще не оплачен Продавцом
                (!isTransferCommisionActive || rateNumber === 0) // Комиссия “Передача АГ по ЗД“ = 0 или “выкл”
            ) {
                return (
                    <VolcanoBlock
                        text={`Подтвердить передачу АГ по ЗД до ${convertDate(pay_before)}`}
                    />
                )
            }

            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && // Комиссии “Передача АГ по ЗД“ еще не оплачен Продавцом
                !consumersNotZero
            ) {
                return <GrennBlock text="Подтверждена передача АГ по ЗД" />
            }
        }

        if (
            (isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Продавец
            (isTransferCommisionActive && !isRealisationCommisionActive && is_buyer_tax_payer) || // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Покупатель)
            (isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) || //  Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Покупатель
            (isTransferCommisionActive && !isRealisationCommisionActive && !is_buyer_tax_payer) // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Продавец)
        ) {
            if (
                isNewVolume &&
                seller_fee_status === FeeStatus.NotPayed && // Комиссии “Передача АГ по ЗД“ еще не оплачен Продавцом
                lowerTheThirdControlDate // Не наступила третья контрольная дата (см. тут)
            ) {
                return <VolcanoBlock text={`Оплатить до ${convertDate(pay_before)}`} />
            }

            if (
                [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && // Продавец оплатил комиссию “Реализация АГ по ЗД“
                !consumersNotZero && // Не указаны потребители
                lowerTheThirdControlDate // Не наступила третья контрольная дата (см. тут)
            ) {
                return <GrennBlock text="Денежные средства зарезервированы" />
            }
        }

        // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Продавец)
        if (!isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) {
            if (
                isNewVolume &&
                seller_fee_status === FeeStatus.NotPayed && // Комиссии “Передача АГ по ЗД“ еще не оплачен Продавцом
                buyer_fee_status === FeeStatus.NotPayed &&
                lowerTheThirdControlDate // Не наступила третья контрольная дата (см. тут)
            ) {
                return <VolcanoBlock text={`Оплатить до ${convertDate(pay_before)}`} />
            }
            if (
                [FeeStatus.Reserved, FeeStatus.Payed].includes(buyer_fee_status) && // Продавец оплатил комиссию “Реализация АГ по ЗД“
                !consumersNotZero && // Не указаны потребители
                lowerTheThirdControlDate // Не наступила третья контрольная дата (см. тут)
            ) {
                return <GrennBlock text="Денежные средства зарезервированы" />
            }
        }
    }

    if (isBuyer) {
        if (realisationFinished) {
            return <GrennBlock text="Реализация прошла успешно" />
        }

        if (comissionOff) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero && // В ЗД Покупателем не указаны конечные потребители
                isNewVolume //  в ЗД появился новый объем АГ
            ) {
                return <VolcanoBlock text={`Указать потребителей до ${convertDate(pay_before)}`} />
            }
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                consumersNotZero && // В ЗД Покупателем указаны конечные потребители
                seller_fee_status === FeeStatus.NotPayed // Комиссии “Передача АГ по ЗД“  еще не оплачен Продавцом
            ) {
                return <GrennBlock text="Потребители указаны" />
            }
        }

        if (
            (isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Продавец
            (isTransferCommisionActive && !isRealisationCommisionActive && is_buyer_tax_payer) // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Покупатель)
        ) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero && // В ЗД Покупателем не указаны конечные потребители
                isNewVolume //  в ЗД появился новый объем АГ
            ) {
                return <VolcanoBlock text={`Указать потребителей до ${convertDate(pay_before)}`} />
            }

            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                consumersNotZero && // В ЗД Покупателем указаны конечные потребители
                seller_fee_status === FeeStatus.NotPayed // Комиссии “Передача АГ по ЗД“  еще не оплачен Продавцом
            ) {
                return <GrennBlock text="Потребители указаны" />
            }
        }

        // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Покупатель
        if (isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero && // В ЗД Покупателем не указаны конечные потребители
                isNewVolume //  в ЗД появился новый объем АГ
            ) {
                return <VolcanoBlock text={`Указать потребителей до ${convertDate(pay_before)}`} />
            }

            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                consumersNotZero && // В ЗД Покупателем указаны конечные потребители
                [FeeStatus.Reserved, FeeStatus.Payed].includes(buyer_fee_status) && // Покупателем оплачена коммиссия за реализацию АГ
                seller_fee_status === FeeStatus.NotPayed // Комиссии “Передача АГ по ЗД“  еще не оплачен Продавцом
            ) {
                return <GrennBlock text="Потребители указаны" />
            }
        }

        // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Покупатель)
        if (!isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero && // В ЗД Покупателем не указаны конечные потребители
                isNewVolume && //  в ЗД появился новый объем АГ
                buyer_fee_status === FeeStatus.NotPayed // Покупатель НЕ оплатил комиссию “Реализация АГ по ЗД”
            ) {
                return (
                    <VolcanoBlock
                        text={`Указать потребителей и оплатить до ${convertDate(pay_before)}`}
                    />
                )
            }

            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                consumersNotZero && // В ЗД Покупателем указаны конечные потребители
                [FeeStatus.Reserved, FeeStatus.Payed].includes(buyer_fee_status) && // Покупателем оплачена коммиссия за реализацию АГ
                seller_fee_status === FeeStatus.NotPayed // Комиссии “Передача АГ по ЗД“  еще не оплачен Продавцом
            ) {
                return <GrennBlock text="Потребители указаны" />
            }
        }

        if (
            (!isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Продавец)
            (isTransferCommisionActive && !isRealisationCommisionActive && !is_buyer_tax_payer) // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Продавец)
        ) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero && // В ЗД Покупателем не указаны конечные потребители
                isNewVolume //  в ЗД появился новый объем АГ
            ) {
                return <VolcanoBlock text={`Указать потребителей до ${convertDate(pay_before)}`} />
            }
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                consumersNotZero && // В ЗД Покупателем указаны конечные потребители
                buyer_fee_status === FeeStatus.NotPayed && // Комиссии Реализация АГ по ЗД“  еще не оплачен Продавцом
                seller_fee_status === FeeStatus.NotPayed // Комиссии “Передача АГ по ЗД“  еще не оплачен Продавцом
            ) {
                return <GrennBlock text="Потребители указаны" />
            }
        }
    }

    return <></>
}

export default AGHeader
