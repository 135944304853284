// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notificationTitle_X67BY {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.notificationMessage_RtEIQ {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ","sourcesContent":[".notificationTitle {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.notificationMessage {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationTitle": "notificationTitle_X67BY",
	"notificationMessage": "notificationMessage_RtEIQ"
};
export default ___CSS_LOADER_EXPORT___;
