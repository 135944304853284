// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editBlock_AHfeI {\n  display: flex;\n  align-items: center;\n}\n.editBlockMargin_lXNkE {\n  display: flex;\n  margin-top: 16px;\n  align-items: center;\n}\n.label_lSWKA {\n  display: flex;\n  text-align: right;\n  align-items: center;\n  justify-content: flex-end;\n  width: 200px;\n}\n.star_H7Ovr {\n  margin-left: 4px;\n  display: flex;\n  align-items: center;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Edit/components/CommonBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,gBAAA;EACA,mBAAA;AACR;AAGA;EACI,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;AADJ;AAIA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAFJ","sourcesContent":[".editBlock {\n    display: flex;\n    align-items: center;\n\n    &Margin {\n        display: flex;\n        margin-top: 16px;\n        align-items: center;\n    }\n}\n\n.label {\n    display: flex;\n    text-align: right;\n    align-items: center;\n    justify-content: flex-end;\n    width: 200px;\n}\n\n.star {\n    margin-left: 4px;\n    display: flex;\n    align-items: center;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editBlock": "editBlock_AHfeI",
	"editBlockMargin": "editBlockMargin_lXNkE",
	"label": "label_lSWKA",
	"star": "star_H7Ovr"
};
export default ___CSS_LOADER_EXPORT___;
