import { createAction, createSlice } from '@reduxjs/toolkit'

import { ILoginServiceParams, ICertificateLoginServiceParams } from '../../services/auth'

export interface IAuthState {
    isAuth: boolean
    isLoading: boolean
    error?: string
}

const initialState = { isAuth: false, isLoading: true } as IAuthState

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getAuthState: (state) => {
            return state
        },
        updateAuth(state, action) {
            const authData = action.payload
            return { ...state, ...authData }
        },
        setAuthError(state, action) {
            state.error = action.payload
        },
    },
})

export const login = createAction<ILoginServiceParams | undefined>('auth/login')
export const logout = createAction('auth/logout')
export const certificateLogin = createAction<ICertificateLoginServiceParams | undefined>(
    'auth/certificateLogin',
)
export const { updateAuth, getAuthState, setAuthError } = authSlice.actions

export default authSlice.reducer
