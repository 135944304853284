// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filtersMain_pL0_c {\n  width: 416px;\n  height: max-content;\n  padding: 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.resetButton_HgG7n {\n  margin-top: 32px;\n}\n.filterTitle_jxIIV {\n  margin-top: 24px;\n}\n.inputWrapper_lXBLp {\n  display: flex;\n  gap: 5px;\n  margin-top: 16px;\n}\n.input_eFNZ5 {\n  border: 1px solid #D3D5DA;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Owners/components/Filters/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,QAAA;EACA,gBAAA;AAFJ;AAKA;EACI,yBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".filtersMain {\n    width: 416px;\n    height: max-content;\n    padding: 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.resetButton {\n    margin-top: 32px;\n}\n\n.filterTitle {\n    margin-top: 24px;\n}\n\n.inputWrapper {\n    display: flex;\n    gap: 5px;\n    margin-top: 16px;\n}\n\n.input {\n    border: 1px solid #D3D5DA;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersMain": "filtersMain_pL0_c",
	"resetButton": "resetButton_HgG7n",
	"filterTitle": "filterTitle_jxIIV",
	"inputWrapper": "inputWrapper_lXBLp",
	"input": "input_eFNZ5"
};
export default ___CSS_LOADER_EXPORT___;
