import { useParams } from 'react-router-dom'
import { LoadingContainer } from '../ui'
import { ContinueRegistration } from '../ContinueRegistration'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'

const EditUser = () => {
    const { id } = useParams()

    const { fetch, result, errorMessage, isLoading } = useRequest({
        link: `/api/users/v1/profile-admin?uid=${id}`,
        method: RequestTypes.get,
    })

    useMount(() => {
        fetch({})
    })

    return (
        <LoadingContainer isLoading={isLoading}>
            <ContinueRegistration type="edit" user={{ data: result, isLoading }} />
        </LoadingContainer>
    )
}

export default EditUser
