// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_AJNv7 {\n  width: 1300px;\n  padding: 40px 10px 70px;\n  margin: 0 auto;\n}\n.titleWrapper_XaADW {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 32px;\n}\n.title_VBO0G {\n  font-family: 'Raleway';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  font-feature-settings: 'lnum';\n  color: #262626;\n  margin-bottom: 0;\n}\n.buttonWrapper_nYeHN {\n  display: flex;\n  gap: 16px;\n}\n.leftSide_tvEWP {\n  width: 200px;\n  color: #8c8c8c;\n}\n.rightSide_GlDHo {\n  font-weight: 600;\n}\n.wrapper_ofJfy {\n  margin-top: 8px;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ThirdPartyToolsOperations/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAGA;EACI,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,SAAA;AAFJ;AAKA;EACI,YAAA;EACA,cAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAOA;EACI,eAAA;EACA,aAAA;AALJ","sourcesContent":[".container {\n    width: 1300px;\n    padding: 40px 10px 70px;\n    margin: 0 auto;\n}\n\n.titleWrapper {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    margin-bottom: 32px;\n}\n\n.title {\n    font-family: 'Raleway';\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    font-feature-settings: 'lnum';\n    color: #262626;\n    margin-bottom: 0;\n}\n\n.buttonWrapper {\n    display: flex;\n    gap: 16px;\n}\n\n.leftSide {\n    width: 200px;\n    color: #8c8c8c;\n}\n\n.rightSide {\n    font-weight: 600;\n}\n\n.wrapper {\n    margin-top: 8px;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_AJNv7",
	"titleWrapper": "titleWrapper_XaADW",
	"title": "title_VBO0G",
	"buttonWrapper": "buttonWrapper_nYeHN",
	"leftSide": "leftSide_tvEWP",
	"rightSide": "rightSide_GlDHo",
	"wrapper": "wrapper_ofJfy"
};
export default ___CSS_LOADER_EXPORT___;
