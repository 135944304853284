import { FC } from 'react'
import { DatePicker, Divider, Input } from 'antd'

import style from './style.m.less'

const VolumeIndicators: FC = () => {
    return (
        <div className={style.volumeIndicators}>
            <Divider />
            <div className={style.inputWrapper}>
                <div className={style.label}>Дата</div>
                <DatePicker className={style.date} placeholder="Выберите дату" />
            </div>
            <p className={style.blockTitle}>Введите показатели объема (кВт*ч) за каждый час</p>
            <div className={style.row}>
                <div className={style.inputWrapper}>
                    <div className={style.label}>0-1</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>1-2</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>2-3</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>3-4</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>4-5</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>5-6</div>
                    <Input className={style.input} placeholder="0" value="3 998" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>6-7</div>
                    <Input className={style.input} placeholder="0" value="5 499" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>7-8</div>
                    <Input className={style.input} placeholder="0" value="10 989" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>8-9</div>
                    <Input className={style.input} placeholder="0" value="11 300" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>9-10</div>
                    <Input className={style.input} placeholder="0" value="5 397" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>10-11</div>
                    <Input className={style.input} placeholder="0" value="1 221" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>11-12</div>
                    <Input className={style.input} placeholder="0" value="99 000" />
                </div>
            </div>
            <div className={style.row}>
                <div className={style.inputWrapper}>
                    <div className={style.label}>12-13</div>
                    <Input className={style.input} placeholder="0" value="288 888" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>13-14</div>
                    <Input className={style.input} placeholder="0" value="18 423" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>14-15</div>
                    <Input className={style.input} placeholder="0" value="7 500" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>15-16</div>
                    <Input className={style.input} placeholder="0" value="5 400" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>16-17</div>
                    <Input className={style.input} placeholder="0" value="6 766" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>17-18</div>
                    <Input className={style.input} placeholder="0" value="9 206" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>18-19</div>
                    <Input className={style.input} placeholder="0" value="1 200" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>19-20</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>20-21</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>21-22</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>22-23</div>
                    <Input className={style.input} placeholder="0" />
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>23-24</div>
                    <Input className={style.input} placeholder="0" />
                </div>
            </div>
        </div>
    )
}

export default VolumeIndicators
