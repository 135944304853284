import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Down: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.52865 12.2975L2.65358 7.28347C2.3981 7.0207 2.39714 6.60264 2.65141 6.3387C2.91764 6.06236 3.35976 6.06124 3.62737 6.33624L8.00005 10.8295L12.3727 6.33624C12.6403 6.06124 13.0825 6.06236 13.3487 6.3387C13.603 6.60264 13.602 7.0207 13.3465 7.28347L8.47145 12.2975C8.2111 12.5677 7.78899 12.5677 7.52865 12.2975Z"
                    fill="#262626"
                />
            </svg>
        )}
    />
)
