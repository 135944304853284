import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChangeRoleModal } from '..'
import { ESignatureModal } from '..'
import { ChangeEmailModal } from '..'
import { GeneratorInfoFrame, IconButton } from '../../../ui'
import { Key, Edit, Stamp, Mail2 } from '../../../../assets/icons'
import { legalPersonInfoConfig } from './legalPersonInfoConfig'
import { DocumentsGrid } from '..'
import style from './style.m.less'
import { Alert, Divider } from 'antd'

const GeneralInfo: FC = () => {
    const { t } = useTranslation()

    const [isChangeRoleModalVisible, setIsChangeRoleModalVisible] = useState(false)
    const [isESignatureModalVisible, setIsESignatureModalVisible] = useState(false)
    const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false)

    const openESignatureModalModal = () => {
        setIsESignatureModalVisible(true)
    }

    const openChangeEmailModal = () => {
        setIsChangeEmailModalVisible(true)
    }

    return (
        <div className={style.generalInfo}>
            <GeneratorInfoFrame
                label={t('myProfile.totalInfo')}
                config={legalPersonInfoConfig}
                customStyle={{ margin: 0 }}
                isProfile
                // statusBlock={
                //     <Alert
                //         className={style.eSignatureModalAlert}
                //         message={
                //             <>
                //                 {t('myProfile.generalInfoAlert')}
                //                 <br />
                //                 {t('myProfile.generalInfoAlert2')}
                //             </>
                //         }
                //         type="info"
                //         showIcon
                //     />
                // }
                bottomChildren={
                    <>
                        <Divider type={'horizontal'} />
                        <div className={style.profileHeader}>{t('myProfile.eSignature')}</div>
                        <div className={style.stampBlock} onClick={openESignatureModalModal}>
                            <Stamp />
                            <div className={style.stampOwner}>Макаров Михаил (ОАО Лето)</div>
                        </div>
                        <Divider type={'horizontal'} />
                        <DocumentsGrid />
                        <Divider type={'horizontal'} />
                        <div className={style.configButtonsBlock}>
                            <Link to="/edit-my-profile" state={{ type: 'profile' }}>
                                <IconButton icon={<Edit />}>
                                    {t('myProfile.editProfile')}
                                </IconButton>
                            </Link>
                            <Link to="/password">
                                <IconButton icon={<Key />}>
                                    {t('myProfile.changePassword')}
                                </IconButton>
                            </Link>
                            <IconButton
                                onClick={openChangeEmailModal}
                                icon={<Mail2 />}
                                customIconStyle={{
                                    marginRight: '8px',
                                    marginTop: '3px',
                                    color: '#528EFF',
                                }}
                            >
                                {t('myProfile.changeEmail')}
                            </IconButton>
                        </div>
                    </>
                }
            />

            <ChangeRoleModal
                isModalVisible={isChangeRoleModalVisible}
                setIsModalVisible={setIsChangeRoleModalVisible}
            />
            <ESignatureModal
                isModalVisible={isESignatureModalVisible}
                setIsModalVisible={setIsESignatureModalVisible}
            />
            <ChangeEmailModal
                isModalVisible={isChangeEmailModalVisible}
                setIsModalVisible={setIsChangeEmailModalVisible}
            />
        </div>
    )
}

export default GeneralInfo
