import { FC } from 'react'
import { IconButton } from '../'
import { Sort1, Sort2 } from '../../../assets/icons'

import style from './style.m.less'

const activeStyles = {
    backgroundColor: '#E6F1FF',
}

export enum ESortDirection {
    ASC,
    DESC,
}

interface ISortSwitch {
    value?: ESortDirection
    onChange?(direction?: ESortDirection): void
}

const SortSwitch: FC<ISortSwitch> = ({ value, onChange }) => (
    <div className={style.sortSwitch}>
        <IconButton
            icon={<Sort2 />}
            customStyle={value === ESortDirection.ASC ? activeStyles : undefined}
            onClick={() => {
                let newSortDirection: ESortDirection | undefined
                if (value === ESortDirection.ASC) {
                    newSortDirection = undefined
                } else {
                    newSortDirection = ESortDirection.ASC
                }
                onChange?.(newSortDirection)
            }}
            className={style.sortButton}
        />
        <IconButton
            icon={<Sort1 />}
            customStyle={value === ESortDirection.DESC ? activeStyles : undefined}
            onClick={() => {
                let newSortDirection: ESortDirection | undefined
                if (value === ESortDirection.DESC) {
                    newSortDirection = undefined
                } else {
                    newSortDirection = ESortDirection.DESC
                }

                onChange?.(newSortDirection)
            }}
            className={style.sortButton}
        />
    </div>
)

interface ISortSwitchSimple {
    state: boolean
    disabled?: boolean
    setState: (s: any) => void
}

// Sort2 Восходящий ascending 123
// Sort1 нисходящий descending 321
export const SortToggle: FC<ISortSwitchSimple> = ({ state, disabled = false, setState }) => {
    return <div className={style.sortSwitch}>
        <IconButton
            icon={<Sort2 />}
            customStyle={!state ? activeStyles : undefined}
            onClick={() => {
                if(!state) return // если уже выбран не даем нажимать
                setState(false)
            }}
            className={style.sortButton}
            disabled={disabled}
        />
        
        <IconButton
            icon={<Sort1 />}
            customStyle={state ? activeStyles : undefined}
            onClick={() => {
                if(state) return
                setState(true)
            }}
            className={style.sortButton}
            disabled={disabled}
        />
    </div>
}

export default SortSwitch
