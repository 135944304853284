import { FC, useEffect, useState } from 'react'
import PageContainer from '../ui/PageElements/PageContainer/PageContainer'
import { Card, CenteredSpin } from '../ui'
import useRequest from '../hooks/useRequest'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonsBlock } from '../Edit/components'
import useMount from '../hooks/useMount'
import style from './style.m.less'
import { Box } from '../ui/Box'
import { InputNumber, Alert, Divider, message } from 'antd'
import { getSettings, postSettings } from './config'
import _ from 'lodash'

export const SystemDatesSettings: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const {
        fetch: fetchSettings,
        result: resultSettings,
        isLoading: isLoadingSettings,
    } = useRequest(getSettings)
    const { fetch } = useRequest(postSettings)

    useMount(() => {
        fetchSettings({})
    })

    const goBack = () => {
        navigate(-1)
    }

    const [lastSubmitDayValue, setLastSubmitDayValue] = useState()
    const [daysForCalcValue, setDaysForCalcValue] = useState()
    const [sellingDaysValue, setSellingDaysValue] = useState()

    const [errorCondition, setErrorCondition] = useState(false)
    const [overflowErrorCondition, setOverflowErrorCondition] = useState(false)

    const body = {
        last_submit_day: lastSubmitDayValue,
        days_for_calc: daysForCalcValue,
        selling_days: sellingDaysValue,
    }
    const isNotChanged = resultSettings && _.isEqual(resultSettings, body)

    const onSubmit = () => {
        if (!lastSubmitDayValue || !daysForCalcValue || !sellingDaysValue) {
            message.error('Заполните все обязательные поля')
        } else {
            if (isNotChanged) {
                message.error('Измените как минимум одно поле')
            } else {
                fetch({
                    body: body,
                    successMessage: {
                        title: t('detailedCard.success'),
                        description: 'Системные контрольные даты изменены',
                    },
                    errorCodeMessage: true,
                    onSuccess: () => navigate(-1),
                })
            }
        }
    }

    useEffect(() => {
        if (!resultSettings) {
            return
        }
        setLastSubmitDayValue(resultSettings.last_submit_day)
        setDaysForCalcValue(resultSettings.days_for_calc)
        setSellingDaysValue(resultSettings.selling_days)
    }, [resultSettings])

    useEffect(() => {
        if (lastSubmitDayValue && daysForCalcValue) {
            if (lastSubmitDayValue + daysForCalcValue > 27) {
                setErrorCondition(true)
                message.error(
                    <span className={style.message}>
                        {`Сумма по двум полям “Первая контрольная дата“ и “Вторая \nконтрольная дата“ не должна превышать “27“`}
                    </span>,
                )
            } else {
                setErrorCondition(false)
            }
        }
    }, [lastSubmitDayValue, daysForCalcValue])

    useEffect(() => {
        if (lastSubmitDayValue && daysForCalcValue && sellingDaysValue) {
            if (lastSubmitDayValue + daysForCalcValue + sellingDaysValue > 365) {
                setOverflowErrorCondition(true)
                message.error('Сумма по всем полям не должна превышать 365')
            } else {
                setOverflowErrorCondition(false)
            }
        }
    }, [lastSubmitDayValue, daysForCalcValue, sellingDaysValue])

    if (isLoadingSettings || !resultSettings) {
        return <CenteredSpin />
    }

    return (
        <PageContainer>
            <div className={style.header}>Установка системных контрольных дат</div>
            <Card className={style.mainCard}>
                <Box>
                    <div>Первая контрольная дата</div>
                    <InputNumber
                        placeholder="Введите значение"
                        className={errorCondition ? style.inputError : style.input}
                        maxLength={2}
                        min={1}
                        controls={false}
                        value={lastSubmitDayValue}
                        onChange={(value) => setLastSubmitDayValue(value)}
                    />
                    <div className={errorCondition ? style.explainError : style.explain}>
                        Укажите число в месяце (от 1 до 27)
                    </div>
                </Box>
                <Box style={{ marginTop: '18px' }}>
                    <div>Вторая контрольная дата</div>
                    <InputNumber
                        placeholder="Введите значение"
                        className={errorCondition ? style.inputError : style.input}
                        maxLength={2}
                        min={1}
                        controls={false}
                        value={daysForCalcValue}
                        onChange={(value) => setDaysForCalcValue(value)}
                    />
                    <div className={errorCondition ? style.explainError : style.explain}>
                        Укажите число от 1 до 26 - оно будет прибавлено к первой контрольной дате
                    </div>
                </Box>
                <Box style={{ marginTop: '18px' }}>
                    <div>Третья контрольная дата</div>
                    <InputNumber
                        placeholder="Введите значение"
                        className={style.input}
                        controls={false}
                        maxLength={3}
                        value={sellingDaysValue}
                        onChange={(value) =>
                            /^\d+$/.test(value as string) && setSellingDaysValue(value)
                        }
                    />
                    <div className={style.explain}>
                        Укажите число - оно будет прибавлено ко второй контрольной дате
                    </div>
                </Box>
                <Alert
                    className={style.alert}
                    message="Сумма по всем полям не должна превышать “365“"
                    type="warning"
                    showIcon
                />
                <Divider />
                <ButtonsBlock
                    confirmTitle={'Применить'}
                    confirmAction={onSubmit}
                    cancelAction={goBack}
                    disableCondition={isNotChanged || errorCondition || overflowErrorCondition}
                />
            </Card>
        </PageContainer>
    )
}

export default SystemDatesSettings
