import moment from 'moment'
import { Tag } from '../../../ui'

const render = (value: Date) => <div>{value}</div>

const renderDateWithFullMonth = (value: Date) => {
    let date = moment(value).format('MMMM, YYYY')
    date = date[0].toUpperCase() + date.substring(1, date.length)

    return <div>{date}</div>
}

const renderDate = (value: string) => <div>{moment(value).format('DD.MM.YYYY')}</div>

export const columns = (isBlockedShown: boolean) => [
    {
        title: 'Период выработки',
        dataIndex: 'period',
        key: 'period',
        render: renderDateWithFullMonth,
    },
    {
        title: 'Объем, кВт*ч',
        dataIndex: 'volume',
        key: 'volume',
        render,
    },
    {
        title: 'ЗГО',
        dataIndex: 'go_name',
        key: 'go_name',
        render,
    },

    {
        title: 'Дата занесения в реестр',
        dataIndex: 'registered_at',
        key: 'registered_at',
        render: renderDate,
    },
    {
        title: 'Срок действия АГ',
        dataIndex: 'expires_at',
        key: 'expires_at',
        render: renderDate,
    },
    ...(isBlockedShown
        ? [
              {
                  title: 'Дата блокировки',
                  dataIndex: 'blocked_at',
                  key: 'blocked_at',
                  renderDate,
              },
              {
                  title: 'Признак',
                  dataIndex: 'lock_reason',
                  key: 'lock_reason',
                  render: (value: number) => (
                      <Tag outline color={value !== 1 ? 'red' : 'black'}>
                          {value === 1 ? 'Не оплачено' : 'Заблокировано'}
                      </Tag>
                  ),
              },
          ]
        : []),
]
