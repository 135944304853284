import { FC } from 'react'
import { Table } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { Close } from '../../../../assets/icons'
import { columns } from './columns'
import { IData } from './data'
import { EStatus, statusMap } from '../../../../constants/approval'
import { useNavigate } from 'react-router-dom'

interface IItem {
    profile_id: number
    profile_id_previous: number
    profile_comment: string
    key: string
    ts_created: string
    profile_status: EStatus
    action_type: number
}

interface IHistoryModal {
    isModalVisible: boolean
    onClose: () => void
    history: any
    uid: string
    setIsModalVisible: any
}

const HistoryModal: FC<IHistoryModal> = ({
    isModalVisible,
    onClose,
    history,
    uid,
    setIsModalVisible,
}) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const getDataSource = (): IData[] =>
        history?.profiles_events
            ?.filter((item: IItem) => !!item.action_type)
            .map((item: IItem, index: number) => {
                return {
                    key: index,
                    date: item.ts_created,
                    status: item.profile_status ? statusMap.get(item.profile_status) : undefined,
                    comment: item.profile_comment,
                    id: item.profile_id,
                    prevId: item.profile_id_previous,
                }
            })

    const data = getDataSource()

    return (
        <Modal
            onCancel={onClose}
            className={style.modal}
            closable
            visible={isModalVisible}
            footer={null}
            centered
            title="История изменений Анкеты"
            closeIcon={<Close />}
        >
            <Table
                className={style.table}
                columns={columns}
                dataSource={data}
                onRow={(record) => ({
                    onClick: () => {
                        navigate(`/questionnaire-approval/${uid}/${record.id}/${record.prevId}`)
                        setIsModalVisible(false)
                    },
                })}
            />
        </Modal>
    )
}

export default HistoryModal
