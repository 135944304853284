import { FC, useMemo } from 'react'
import { Input } from 'antd'
import { EUserRole, userRoleMap } from '../../../../constants/approval'
import { DropdownSelector2 } from '../../../ui'
import { getFilters } from './filters'

import style from './style.m.less'
import { Filters } from '../../types'

const userTypeOptions = [
    {
        id: EUserRole.All,
        value: EUserRole.All,
        text: userRoleMap.get(EUserRole.All),
        label: EUserRole.All,
    },
    {
        id: EUserRole.UserWithouPA,
        value: EUserRole.UserWithouPA,
        text: userRoleMap.get(EUserRole.UserWithouPA),
        label: EUserRole.UserWithouPA,
    },
    {
        id: EUserRole.Client,
        value: EUserRole.Client,
        text: userRoleMap.get(EUserRole.Client),
        label: EUserRole.Client,
    },
    {
        id: EUserRole.GOOwner,
        value: EUserRole.GOOwner,
        text: userRoleMap.get(EUserRole.GOOwner),
        label: EUserRole.GOOwner,
    },
    {
        id: EUserRole.TSO,
        value: EUserRole.TSO,
        text: userRoleMap.get(EUserRole.TSO),
        label: EUserRole.TSO,
    },
]

const userSuperTypeOptions = [
    {
        id: EUserRole.All,
        value: EUserRole.All,
        text: userRoleMap.get(EUserRole.All),
        label: EUserRole.All,
    },
    {
        id: EUserRole.Operator,
        value: EUserRole.Operator,
        text: userRoleMap.get(EUserRole.Operator),
        label: EUserRole.Operator,
    },
    {
        id: EUserRole.Administrator,
        value: EUserRole.Administrator,
        text: userRoleMap.get(EUserRole.Administrator),
        label: EUserRole.Administrator,
    },
]

const physicUserTypeOptions = userTypeOptions.filter(
    (item) =>
        item.id === EUserRole.All ||
        item.id === EUserRole.UserWithouPA ||
        item.id === EUserRole.Client,
)

const individualUserTypeOptions = userTypeOptions.filter(
    (item) =>
        item.id === EUserRole.All ||
        item.id === EUserRole.UserWithouPA ||
        item.id === EUserRole.Client ||
        item.id === EUserRole.GOOwner,
)
const userRoles: any = [
    physicUserTypeOptions,
    individualUserTypeOptions,
    userTypeOptions,
    userSuperTypeOptions,
]

interface IFilter {
    onChange: (data: string | number, key: string, obj?: any) => void
    typeIndex: number
    filters: Filters
    isGeneric: boolean
}

const Filter: FC<IFilter> = ({ onChange, typeIndex, filters, isGeneric }) => {
    const updateFilters = (data: any, key: string) => {
        onChange(data, key)
    }
    const getIndex = isGeneric ? typeIndex : 3

    const getComponent = (
        type: 'input' | 'dropDown',
        key: string,
        placeholder?: string,
        maxLength?: number,
    ) => {
        const valueByKey = (filters[key as keyof Filters] as number) ?? 0
        const value = userRoles[getIndex].find((el: any) => el.value === valueByKey)?.text
        switch (type) {
            case 'input':
                return (
                    <Input
                        onChange={(event) => updateFilters(event.target.value, key)}
                        value={filters ? filters[key as keyof Filters] : undefined}
                        placeholder={`Введите ${placeholder}`}
                        className={style.input}
                        maxLength={maxLength}
                    />
                )
            case 'dropDown':
                return (
                    <DropdownSelector2
                        value={value}
                        onChange={(tid) => updateFilters(tid, key)}
                        options={userRoles[getIndex]}
                        customStyle={{ width: typeIndex === 1 ? '234px' : '192px' }}
                    />
                )
            default:
                break
        }
    }

    const filtersInternal = useMemo(() => getFilters(typeIndex, isGeneric), [typeIndex, isGeneric])

    return (
        <div className={style.filter}>
            {filtersInternal.map(({ label, type, key, placeholder, maxLength }) => (
                <div key={key} className={style.filterItem}>
                    <div className={style.label}>{label}</div>
                    {getComponent(type, key, placeholder, maxLength)}
                </div>
            ))}
        </div>
    )
}

export default Filter
