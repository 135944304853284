import { FC } from 'react'
import useRequest from '../../../hooks/useRequest'
import PageContainer from '../../../ui/PageElements/PageContainer/PageContainer'
import BackLink from '../../../ui/BackTitle/BackTitle'
import { useParams } from 'react-router-dom'
import Card from '../../../ui/Card/Card'
import { Box } from '../../../ui/Box'
import { LoadingContainer, PrimaryButton } from '../../../ui'
import style from './style.m.less'
import { monthPicker } from '../../../helpers'
import { getZdDocsRM, getZdDocsWM } from '../../config'
import useMount from '../../../hooks/useMount'
import DocumentsGrid from '../../../FuelApproval/components/FuelCard/DocumentsGrid/DocumentsGrid'
import moment from 'moment'
import FileSaver from 'file-saver'
import request from '../../../../services/request'
import { message } from 'antd'
import { AxiosResponse } from 'axios'

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const DocsCard: FC = () => {
    const { id, type } = useParams<{ id: string; type: string }>()
    const { fetch, result, isLoading } = useRequest(type === 'wm' ? getZdDocsWM : getZdDocsRM)

    useMount(() => {
        fetch({ getParams: { id } })
        scrollToTop()
    })

    const onDownloadFiles = async () => {
        const data: AxiosResponse<any, any> = await request
            .get('/api/generation_attributes/v1/wm_results_csv', {
                responseType: 'blob',
                params: {
                    period: `${result?.year}-${String(result?.month).padStart(2, '0')}`,
                },
            })
            .catch(() =>
                message.error({
                    content: 'Ошибка скачивания архива',
                    icon: <></>,
                }),
            )

        if (!data) {
            message.error({
                content: 'Ошибка скачивания архива',
                icon: <></>,
            })
            return
        }

        FileSaver.saveAs(new Blob([data.data], { type: 'application/zip' }))
    }

    const currentDate = `${monthPicker(result?.month)}, ${result?.year}`

    return (
        <LoadingContainer isLoading={isLoading}>
            <PageContainer>
                <>
                    <BackLink title={`Данные по ЗД за ${currentDate}`} />
                    <Card className={style.card}>
                        <Box direction="row">
                            {result?.documents?.length ? (
                                <DocumentsGrid documents={result?.documents} />
                            ) : (
                                '-'
                            )}
                        </Box>
                        {type === 'wm' && (
                            <div className={style.buttonContainer}>
                                <PrimaryButton
                                    onClick={onDownloadFiles}
                                    value={'Скачать результаты'}
                                />
                            </div>
                        )}
                    </Card>
                </>
            </PageContainer>
        </LoadingContainer>
    )
}

export default DocsCard
