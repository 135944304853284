import { FC } from 'react'
import { Collapse as AntCollapse, CollapseProps } from 'antd'

import style from './style.m.less'

const Collapse: FC<CollapseProps> = (props) => {
    return <AntCollapse ghost {...props} className={style.collapse} />
}

export default Collapse
