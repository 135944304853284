import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Calendar: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.60156 1.99951V3.79951"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.4004 1.99951V3.79951"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.90039 6.25391H13.1004"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.4016 5.8999V10.9999C13.4016 12.7999 12.5016 13.9999 10.4016 13.9999H5.60156C3.50156 13.9999 2.60156 12.7999 2.60156 10.9999V5.8999C2.60156 4.0999 3.50156 2.8999 5.60156 2.8999H10.4016C12.5016 2.8999 13.4016 4.0999 13.4016 5.8999Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.99788 9.01973H8.00327"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.77717 9.01973H5.78256"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.77717 10.82H5.78256"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
