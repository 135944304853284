import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const TickCircle: FC<IIconProps> = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="vuesax/linear/tick-circle">
                    <g id="tick-circle">
                        <path
                            id="Vector"
                            d="M7.9987 14.6673C11.6654 14.6673 14.6654 11.6673 14.6654 8.00065C14.6654 4.33398 11.6654 1.33398 7.9987 1.33398C4.33203 1.33398 1.33203 4.33398 1.33203 8.00065C1.33203 11.6673 4.33203 14.6673 7.9987 14.6673Z"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            id="Vector_2"
                            d="M5.16797 7.99995L7.05464 9.88661L10.8346 6.11328"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                </g>
            </svg>
        )}
    />
)
