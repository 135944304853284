import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { HeaderBlock, DropdownSelector, DatePicker, DocumentsBlockUI } from '../../../ui'
import { Divider, Input, Checkbox } from 'antd'
import { ButtonsBlock, CommonBlock, ThreePartsBlock } from '..'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const clientTypes = [
    { value: 'Клиент', id: '32177a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Не клиент', id: '7c23e945-c4c6-46dc-ad08-156c3456610d' },
]

const userTypes = [
    { value: 'Юридическое лицо', id: '55677a62-97a0-47d2-8b8f-d23abe52f591' },
    { value: 'Физическое лицо', id: '7c23e409-c4c6-46dc-ad08-194c3456610d' },
]

const ChangeRole: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const getBackToProfile = () => {
        navigate('/profile')
    }

    return (
        <div className={style.editMain}>
            <HeaderBlock label={t('editMyProfile.changeRole')} />
            <div className={style.editFrame}>
                <CommonBlock
                    label={t('editMyProfile.currentRole')}
                    select={
                        <Input
                            disabled
                            defaultValue={t('editMyProfile.ownerGO')}
                            className={style.input}
                        />
                    }
                />
                <CommonBlock
                    required
                    margin
                    label={t('editMyProfile.newRole')}
                    select={
                        <DropdownSelector
                            options={clientTypes}
                            customStyle={{ width: '400px', marginLeft: '16px' }}
                        />
                    }
                />
                <CommonBlock
                    required
                    margin
                    label={t('editMyProfile.userType')}
                    select={
                        <DropdownSelector
                            options={userTypes}
                            customStyle={{ width: '400px', marginLeft: '16px' }}
                        />
                    }
                />
                <Divider />
                <CommonBlock
                    required
                    margin
                    label={t('editMyProfile.fullName')}
                    select={<Input className={style.input} />}
                />
                <CommonBlock
                    required
                    margin
                    label={t('editMyProfile.shortName')}
                    select={<Input className={style.input} />}
                />
                <ThreePartsBlock
                    labels={[
                        t('editMyProfile.inn'),
                        t('editMyProfile.ogrn'),
                        t('editMyProfile.kpp'),
                    ]}
                />
                <CommonBlock
                    margin
                    label={t('editMyProfile.phoneFax')}
                    select={<Input className={style.input} />}
                />
                <CommonBlock margin label="Сайт" select={<Input className={style.input} />} />
                <Divider />
                <CommonBlock
                    required
                    label={t('editMyProfile.egrulCompName')}
                    select={<Input className={style.inputLong} />}
                />
                <CommonBlock
                    required
                    margin
                    label={t('editMyProfile.egrulDate')}
                    select={
                        <DatePicker
                            customStyle={{ marginLeft: '16px', width: '200px', height: '32px' }}
                        />
                    }
                />
                <Divider />
                <CommonBlock
                    required
                    label={t('editMyProfile.bossStatus')}
                    select={<Input className={style.input} />}
                />
                <ThreePartsBlock
                    labels={[
                        t('editMyProfile.surname'),
                        t('editMyProfile.userName'),
                        t('editMyProfile.lastName'),
                    ]}
                />
                <Divider />
                <CommonBlock
                    required
                    label={t('editMyProfile.urAddress')}
                    select={<Input className={style.inputLong} />}
                />
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('editMyProfile.region')}</div>
                        <Input className={style.addressInput} />
                    </div>
                    <div>
                        <div>{t('editMyProfile.city')}</div>
                        <Input className={style.addressInput} />
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('editMyProfile.street')}</div>
                        <Input className={style.addressInputLong} />
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('editMyProfile.index')}</div>
                        <Input className={style.addressInputShort} />
                    </div>
                    <div>
                        <div>{t('editMyProfile.house2')}</div>
                        <Input className={style.addressInputShort} />
                    </div>
                    <Checkbox className={style.addressCheckbox}>
                        {t('editMyProfile.noHome')}
                    </Checkbox>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('editMyProfile.block')}</div>
                        <Input className={style.addressInputTiny} />
                    </div>
                    <div>
                        <div>{t('editMyProfile.building')}</div>
                        <Input className={style.addressInputTiny} />
                    </div>
                    <div>
                        <div>{t('editMyProfile.appartment')}</div>
                        <Input className={style.addressInputTiny} />
                    </div>
                    <Checkbox checked className={style.addressCheckbox}>
                        {t('editMyProfile.noAppartment')}
                    </Checkbox>
                </div>
                <Divider />
                <CommonBlock
                    margin
                    label={t('editMyProfile.mailAddress')}
                    select={
                        <Checkbox checked className={style.checkbox}>
                            {t('editMyProfile.matchUr')}
                        </Checkbox>
                    }
                />
                <Divider />
                <div className={style.documentsBlockWrapper}>
                    <DocumentsBlockUI type={'profile'} />
                </div>
                <Divider />
                <ButtonsBlock
                    confirmTitle={t('editMyProfile.changeRole2')}
                    cancelAction={getBackToProfile}
                />
            </div>
        </div>
    )
}

export default ChangeRole
