import { CenteredSpin, DropdownSelector2, IconButton, TimeRangePicker } from '../../../ui'
import { FC, useEffect, useState } from 'react'

import style from './style.m.less'
import { Input } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { getDictionaries, selectDictionariesOptions } from '../../../../store/dictionaries'
import TagSelector from '../TagSelector/TagSelector'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import moment from 'moment'

export interface IValue {
    renewableEnergyType: number[]
    totalPowerFrom: number
    totalPowerTo: number
    qualificDate?: [moment.Moment, moment.Moment] | [null, null]
}

interface IFilters {
    values: IValue
    onChange(values: IValue): void
    onClose?: () => void
    names?: any
    titles?: any
    showQualificDate?: boolean
    disableOutside?: (state: boolean) => void
}

const unitTypes = [
    { value: 'МВт', id: 'МВт' },
    { value: 'КВт', id: 'КВт' },
]

const Filters: FC<IFilters> = ({
    values,
    onChange,
    onClose,
    names = {},
    titles = {},
    showQualificDate = false,
    disableOutside,
}) => {
    const { renewableEnergyType, totalPowerFrom, totalPowerTo, qualificDate } = values

    const isLoading = useAppSelector((state) => state.dictionaries.loading)

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [])

    const [powerMeasuremen, setPowerMeasuremen] = useState(unitTypes[0].id)

    const dictionaries = useAppSelector(selectDictionariesOptions)

    if (!dictionaries) {
        return <CenteredSpin />
    }

    const { renewableEnergyTypesOptions } = dictionaries

    const realTotalPowerFrom = powerMeasuremen === 'МВт' ? totalPowerFrom / 1000 : totalPowerFrom
    const realTotalPowerTo = powerMeasuremen === 'МВт' ? totalPowerTo / 1000 : totalPowerTo

    return (
        <div className={style.filtersMain}>
            <div>Вид энергии</div>
            <TagSelector
                value={renewableEnergyType}
                onChange={(renewableEnergyType) =>
                    onChange({
                        ...values,
                        renewableEnergyType,
                    })
                }
                customStyle={{ marginTop: 0 }}
                options={renewableEnergyTypesOptions}
                showSearch={false}
            />
            <div className={style.filterTitle}>{titles?.power || `Суммарная мощность`}</div>
            <DropdownSelector2
                value={powerMeasuremen}
                onChange={(e) => setPowerMeasuremen(e)}
                options={unitTypes}
                customStyle={{ width: '100%' }}
            />
            <div className={style.inputWrapper}>
                <Input
                    value={realTotalPowerFrom}
                    onChange={(e) => {
                        const value = Math.max(+e.target.value || 0, 0)
                        onChange({
                            ...values,
                            totalPowerFrom: powerMeasuremen === 'МВт' ? value * 1000 : value,
                        })
                    }}
                    className={style.input}
                />
                <Input
                    value={realTotalPowerTo}
                    onChange={(e) => {
                        const value = Math.max(+e.target.value || 0, 0)
                        onChange({
                            ...values,
                            totalPowerTo: powerMeasuremen === 'МВт' ? value * 1000 : value,
                        })
                    }}
                    className={style.input}
                />
            </div>
            {showQualificDate && (
                <>
                    <div className={style.filterTitle}>{names?.rangePickerTitle || ''}</div>
                    <div>
                        <TimeRangePicker
                            onFocus={() => disableOutside && disableOutside(true)}
                            onBlur={() => disableOutside && disableOutside(false)}
                            style={{ width: '100%' }}
                            value={qualificDate}
                            onlyBeforeDateAndToday={moment()}
                            onChange={(e) => {
                                onChange({
                                    ...values,
                                    qualificDate: e || [null, null],
                                })
                            }}
                        />
                    </div>
                </>
            )}
            <div className={style.resetButton}>
                <IconButton
                    onClick={() => {
                        onChange({
                            renewableEnergyType: [],
                            totalPowerFrom: 0,
                            totalPowerTo: 0,
                        })
                        setPowerMeasuremen('МВт')
                        onClose && onClose()
                    }}
                >
                    Сбросить фильтры
                </IconButton>
            </div>
        </div>
    )
}

export default Filters
