import { FC } from 'react'
import { Modal, PrimaryButton } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Warning } from '../../../../assets/icons'

interface IWarningModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const WarningModal: FC<IWarningModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <Warning />
                <div>
                    <div className={style.modalHeader}>Внимание!</div>
                    <div className={style.dataBlock}>
                        <div className={style.text}>
                            Вы заполнили данные не за весь месяц. Передаваемые вами данные в
                            незаполненные дни / часы будут заполнены значением ноль.
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    onClick={handleOk}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryButton
                    value="Подать данные"
                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                />
            </div>
        </Modal>
    )
}

export default WarningModal
