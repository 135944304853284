import { IOperationConfig } from '../../../ui/OperationsMapper/types'

export const historyConfig: IOperationConfig[] = [
    {
        day: 'Сегодня,',
        weekday: ' 17 июня, пятница',
        operations: [
            {
                type: 'Погашение сертификата происхождения',
                time: '12:24',
                value: 'Скачать запрос и подпись',
            },
            {
                type: 'Погашение сертификата происхождения',
                time: '08:36',
                value: 'Скачать запрос и подпись',
            },
        ],
    },
    {
        day: 'Вчера,',
        weekday: '16 июня, четверг',
        operations: [
            {
                type: 'Погашение сертификата происхождения',
                time: '20:39',
                value: 'Скачать запрос и подпись',
            },
        ],
    },
    {
        day: '1 июня,',
        weekday: 'вторник',
        operations: [
            {
                type: 'Погашение сертификата происхождения',
                time: '20:39',
                value: 'Скачать запрос и подпись',
            },
        ],
    },
]
