import { FC, useState } from 'react'
import { Form, Input, message } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { CenteredSpin, Modal, PrimaryButton, PrimaryIconButton } from '../../../ui'
import request from '../../../../services/request'

const MESSAGE = 'Поле обязательно для заполнения'

export interface ITSO {
    company_inn: string
    company_name_short: string
    kpp: string
    ogrn: string
    uid?: string
    isDraft: boolean
}

interface IAddEditTSO {
    isModalVisible: boolean
    onCancel(): void
    onChange(tso: ITSO): void
}

const AddEditTSO: FC<IAddEditTSO> = ({ isModalVisible, onCancel, onChange }) => {
    const { t } = useTranslation()

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const handleOk = async () => {
        try {
            setLoading(true)
            const validValues = await form.validateFields()
            const { data } = await request.post('/api/users/v1/create-draft', {
                service_id: 3, // 3 - go, 4 - gt - из какого сервиса черновик создается, чтобы потом в этот сервис слать уведомление о появлении пользователя с заапрувленной учеткой
                user_type: 2,
                company_name_short: validValues.company_name_short,
                company_inn: validValues.company_inn,
                kpp: validValues.kpp,
                ogrn: validValues.ogrn,
            })
            onChange({ ...validValues, uid: data.uid_draft, isDraft: true })
        } catch (e) {
            if (e.err_message) {
                message.error(e.err_message)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible} onCancel={onCancel}>
                {loading && <CenteredSpin />}
                <Form form={form}>
                    <div className={style.modalHeader}>Добавление ТСО</div>
                    <>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Название</div>
                            <Form.Item
                                name="company_name_short"
                                rules={[{ required: true, message: MESSAGE }]}
                            >
                                <Input placeholder="Введите Название" className={style.input} />
                            </Form.Item>
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ИНН</div>
                            <Form.Item
                                name="company_inn"
                                rules={[
                                    { required: true, message: MESSAGE },
                                    () => ({
                                        validator(_, value) {
                                            const strValue = String(value)
                                            if (strValue.length !== 10) {
                                                return Promise.reject(
                                                    new Error('ИНН должен содержать 10 цифр'),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    placeholder="Введите ИНН"
                                    className={style.input}
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ОГРН</div>
                            <Form.Item
                                name="ogrn"
                                rules={[
                                    { required: true, message: MESSAGE },
                                    () => ({
                                        validator(_, value) {
                                            const strValue = String(value)
                                            if (strValue.length !== 13) {
                                                return Promise.reject(
                                                    new Error('ОГРН должен содержать 13 цифр'),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    placeholder="Введите ОГРН"
                                    className={style.input}
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>КПП</div>
                            <Form.Item
                                name="kpp"
                                rules={[
                                    { required: true, message: MESSAGE },
                                    () => ({
                                        validator(_, value) {
                                            const strValue = String(value)
                                            if (strValue.length !== 9) {
                                                return Promise.reject(
                                                    new Error('КПП должен содержать 9 цифр'),
                                                )
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    placeholder="Введите КПП"
                                    className={style.input}
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                    </>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={onCancel}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryIconButton
                            size="large"
                            onClick={handleOk}
                            value="Добавить"
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default AddEditTSO
