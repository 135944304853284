export const repaymentData = [
    { date: '22.04.2022', name: 'ООО Зета Солар', power: '50 000 кВт*ч' },
    { date: '15.04.2022', name: 'ОАО Ромашковая ромашка', power: '12 166 кВт*ч' },
    { date: '31.03.2022', name: 'ИП Ускова Анна Владимировна', power: '84 500 кВт*ч' },
    { date: '27.03.2022', name: 'ОАО Российские железные дороги', power: '100 480 кВт*ч' },
    { date: '14.02.2022', name: 'ООО Moscow Digital Academy', power: '99 029 кВт*ч' },
    { date: '01.02.2022', name: 'ИП Селиванов Дмитрий Александро...', power: '100 000 кВт*ч' },
    { date: '14.02.2022', name: 'ООО Moscow Digital Academy', power: '15 000 кВт*ч' },
    { date: '14.02.2022', name: 'ООО Moscow Digital Academy', power: '2 000 кВт*ч' },
    { date: '14.02.2022', name: 'ООО Moscow Digital Academy', power: '1 029 кВт*ч' },
    { date: '31.03.2022', name: 'ИП Ускова Анна Владимировна', power: '84 000 кВт*ч' },
]
