// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_rCE_b {\n  margin-top: 24px;\n}\n.table_rCE_b td.ant-table-cell {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Tables/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAFA;EAKY,eAAA;AAAZ","sourcesContent":[".table {\n    margin-top: 24px;\n\n    :global {\n        td.ant-table-cell {\n            cursor: pointer;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table_rCE_b"
};
export default ___CSS_LOADER_EXPORT___;
