import { CSSProperties, FC, ReactNode, useRef } from 'react'
import classNames from 'classnames'
import { useClickOut } from '../../hooks/useClickOut'

import style from './style.m.less'

interface IPopover {
    open?: boolean
    content?: ReactNode
    children?: ReactNode
    contentStyle?: CSSProperties
    wrapStyle?: CSSProperties
    onClickOut?: (s: any) => void
}

const Popover: FC<IPopover> = ({ open, content, children, contentStyle = '', wrapStyle = '', onClickOut }) => {
    const ref = useRef()

    if (onClickOut) {
        useClickOut(ref, () => onClickOut(false));
    }

    return (
        <div ref={ref} className={classNames(style.popoverWrapper, wrapStyle)}>
            {children}
            {open && <div className={classNames(style.content, contentStyle)}>{content}</div>}
        </div>
    )
}

export default Popover
