export const qualificationCategories = [
    {
        categoryNumber: '1',
        description:
            'Генерирующий объект, функционирующий на основе использования фотоэлектрического преобразования энергии солнца',
    },
    {
        categoryNumber: '1а',
        description:
            'Генерирующий объект, функционирующий на основе использования фотоэлектрического преобразования энергии солнца со степенью локализации менее 50 процентов',
    },
    {
        categoryNumber: '1б',
        description:
            'Генерирующий объект, функционирующий на основе использования фотоэлектрического преобразования энергии солнца со степенью локализации не менее 50 процентов, но не более 70 процентов',
    },
    {
        categoryNumber: '1в',
        description:
            'Генерирующий объект, функционирующий на основе использования фотоэлектрического преобразования энергии солнца со степенью локализации более 70 процентов',
    },
    {
        categoryNumber: '2',
        description: 'Генерирующий объект, функционирующий на основе использования энергии ветра',
    },
    {
        categoryNumber: '2а',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии ветра со степенью локализации менее 35 процентов',
    },
    {
        categoryNumber: '2б',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии ветра со степенью локализации не менее 35 процентов, но не более 55 процентов ',
    },
    {
        categoryNumber: '2в',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии ветра со степенью локализации не менее 55 процентов, но не более 65 процентов',
    },
    {
        categoryNumber: '2г',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии ветра со степенью локализации более 65 процентов',
    },
    {
        categoryNumber: '3',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии потоков воды (в том числе энергии сточных вод), за исключением случаев использования электростанциях',
    },
    {
        categoryNumber: '3а',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии потоков воды (в том числе энергии сточных вод), за исключением случаев использования электростанциях со степенью локализации менее 20 процентов',
    },
    {
        categoryNumber: '3б',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии потоков воды (в том числе энергии сточных вод), за исключением случаев использования электростанциях со степенью локализации не менее 20 процентов, но не более 45 процентов',
    },
    {
        categoryNumber: '3в',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии потоков воды (в том числе энергии сточных вод), за исключением случаев использования электростанциях со степенью локализации не менее 45 процентов, но не более 65 процентов;',
    },
    {
        categoryNumber: '3г',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии потоков воды (в том числе энергии сточных вод), за исключением случаев использования электростанциях со степенью локализации более 65 процентов',
    },
    {
        categoryNumber: '4',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии приливов',
    },
    {
        categoryNumber: '5',
        description:
            'Генерирующий объект, функционирующий на основе использования энергии волн водных объектов, в том числе водоемов, рек, морей и океанов',
    },
    {
        categoryNumber: '6',
        description:
            'Генерирующий объект, функционирующий на основе использования геотермальной энергии с использованием природных подземных теплоносителей',
    },
    {
        categoryNumber: '7',
        description:
            'Генерирующий объект, функционирующий на основе использования низкопотенциальной тепловой энергии земли, воздуха и воды с использованием специальных теплоносителей',
    },
    {
        categoryNumber: '8',
        description:
            'Генерирующий объект, функционирующий на основе использования биомассы, включая специально выращенные для получения энергии растения, в том числе деревья, а также отходы производства и потребления, за исключением отходов, полученных в процессе использования углеводородного сырья и топлива',
    },
    {
        categoryNumber: '9',
        description: 'Генерирующий объект, функционирующий на основе использования биогаза',
    },
    {
        categoryNumber: '10',
        description:
            'Генерирующий объект, функционирующий на основе использования газа, выделяемого отходами производства и потребления на свалках таких отходов',
    },
    {
        categoryNumber: '11',
        description:
            'Генерирующий объект, функционирующий на основе использования газа, образующегося на угольных разработках',
    },
]
