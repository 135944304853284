import { StrictEffect, put, takeLatest } from 'redux-saga/effects'

import {
    ILoginServiceParams,
    ICertificateLoginServiceParams,
    certificateLoginService,
    logoutService,
} from '../../services/auth'
import { login as loginAction, logout as logoutAction, updateAuth, certificateLogin } from './index'
import { dropUserInfo } from '../../store/user'
import { errorHandler } from '../../components/helpers'
import { getRoleFromToken } from './token'
const setStorageSettings = (primaryStorage: any, otherStorage: any, data: any) => {
    primaryStorage.setItem('accessToken', data.access_token)
    primaryStorage.setItem('refreshToken', data.refresh_token)
    primaryStorage.setItem('userRole', getRoleFromToken(data.access_token))
    otherStorage.removeItem('accessToken')
    otherStorage.removeItem('refreshToken')
    otherStorage.removeItem('userRole')
    sessionStorage.removeItem('FIO')
    localStorage.removeItem('FIO')
}

function* handleLogin({ payload }: ReturnType<typeof loginAction>) {
    const { data, remember } = payload as ILoginServiceParams

    if (remember) {
        setStorageSettings(localStorage, sessionStorage, data)
    } else {
        setStorageSettings(sessionStorage, localStorage, data)
    }

    yield put(updateAuth({ error: '', isAuth: true, isLoading: false }))
    window.location.href = '/profile'
}

function* handleCertificateLogin({ payload }: ReturnType<typeof certificateLogin>) {
    try {
        const { thumbprint, goToProfile } = payload as ICertificateLoginServiceParams
        const { data, error } = yield certificateLoginService({ thumbprint, goToProfile })

        if (error) {
            throw new Error(errorHandler(error.err_code))
        }

        setStorageSettings(sessionStorage, localStorage, data)
        yield put(updateAuth({ error: '', isAuth: true, isLoading: false }))
        goToProfile()
    } catch (error: any) {
        yield put(updateAuth({ error: error.message, isAuth: false, isLoading: false }))
    }
}

function* handleLogout() {
    try {
        const { error } = yield logoutService()

        if (error) {
            throw new Error(errorHandler(error.err_code))
        }

        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userRole')
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
        sessionStorage.removeItem('userRole')
        sessionStorage.removeItem('FIO')
        localStorage.removeItem('FIO')
        yield put(updateAuth({ error: '', isAuth: false, isLoading: false }))
        yield put(dropUserInfo())
        window.location.href = '/auth'
    } catch (error: any) {
        yield put(updateAuth({ error: error.message, isAuth: false, isLoading: false }))
    }
}

export function* watchAuthLogin(): Generator<StrictEffect> {
    yield takeLatest(loginAction.type, handleLogin)
}

export function* watchCertificateLogin(): Generator<StrictEffect> {
    yield takeLatest(certificateLogin.type, handleCertificateLogin)
}

export function* watchAuthLogout(): Generator<StrictEffect> {
    yield takeLatest(logoutAction.type, handleLogout)
}
