// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonsBlock_gjSKM {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 24px;\n}\n.wrapper_jLaza {\n  display: flex;\n  gap: 16px;\n  width: 423px;\n}\n.header_EGqt1 {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  font-feature-settings: \"lnum\";\n  color: #303440;\n}\n.text_VOEp2 {\n  margin-top: 16px;\n  margin-left: 40px;\n  width: 280px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/CancelDeregisterModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AACJ;AAEA;EACI,aAAA;EACA,SAAA;EACA,YAAA;AAAJ;AAGA;EACI,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;AADJ;AAIA;EACI,gBAAA;EACA,iBAAA;EACA,YAAA;AAFJ","sourcesContent":[".buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 24px;\n}\n\n.wrapper {\n    display: flex;\n    gap: 16px;\n    width: 423px;\n}\n\n.header {\n    font-family: 'Raleway';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    font-feature-settings: \"lnum\";\n    color: #303440;\n}\n\n.text {\n    margin-top: 16px;\n    margin-left: 40px;\n    width: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsBlock": "buttonsBlock_gjSKM",
	"wrapper": "wrapper_jLaza",
	"header": "header_EGqt1",
	"text": "text_VOEp2"
};
export default ___CSS_LOADER_EXPORT___;
