import { FC, useState } from 'react'
import { message, Alert } from 'antd'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal, PrimaryButton, TimeRangePicker } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RotateRight, TickSquare } from '../../../../assets/icons'

enum ELoadStatus {
    None,
    Loading,
    Ready,
}

interface IUnloadingParamsModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const UnloadingParamsModal: FC<IUnloadingParamsModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const [status, setStatus] = useState(ELoadStatus.None)

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleConfirm = () => {
        setIsModalVisible(false)
        message.success({
            content: (
                <Alert
                    description="Данные отправлены на доработку"
                    message="Комментарий оператора о том, что пользователю нужно доработать"
                />
            ),
            icon: <></>,
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            {status === ELoadStatus.None ? (
                <>
                    <div className={style.modalHeader}>Параметры выгрузки</div>
                    <div className={style.dataBlock}>
                        <div className={style.label}>Период</div>
                        <TimeRangePicker />
                    </div>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={handleOk}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        {/* <Link to="/go-data-ku-period"> */}
                        <PrimaryButton
                            onClick={() => {
                                setStatus(ELoadStatus.Loading)
                                setTimeout(() => {
                                    setStatus(ELoadStatus.Ready)
                                }, 2000)
                            }}
                            // isDelete={isDelete}
                            value="Выгрузить"
                            customStyle={{ marginLeft: '16px', padding: '9px 16px' }}
                        />
                        {/* </Link> */}
                    </div>
                </>
            ) : status === ELoadStatus.Loading ? (
                <>
                    <div className={style.modalHeader}>Параметры выгрузки</div>
                    <div className={style.status}>
                        <span className={style.statusIconLoading}>
                            <RotateRight />
                        </span>
                        <div className={style.label}>Мы формируем файл для выгрузки</div>
                    </div>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={handleOk}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            // onClick={handleConfirm}
                            // isDelete={isDelete}
                            value="Скачать файл"
                            customStyle={{ marginLeft: '16px', padding: '9px 16px' }}
                        />
                    </div>
                </>
            ) : status === ELoadStatus.Ready ? (
                <>
                    <div className={style.modalHeader}>Все готово</div>
                    <div className={style.status}>
                        <span className={style.statusIconReady}>
                            <TickSquare />
                        </span>
                        <div className={style.label}>Файл сформирован</div>
                    </div>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={handleOk}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            // onClick={handleConfirm}
                            // isDelete={isDelete}
                            value="Скачать файл"
                            customStyle={{ marginLeft: '16px', padding: '9px 16px' }}
                        />
                    </div>
                </>
            ) : null}
        </Modal>
    )
}

export default UnloadingParamsModal
