// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_EOmUR {\n  margin-top: 24px;\n}\n.table_EOmUR th:nth-child(3) {\n  width: 480px;\n}\n.table_EOmUR th:nth-child(4) {\n  width: 160px;\n}\n.table_EOmUR td.ant-table-cell {\n  cursor: pointer;\n}\n.newCellDot_rpC_c {\n  position: relative;\n}\n.newCellDot_rpC_c::before {\n  content: '';\n  position: absolute;\n  top: 8px;\n  left: -15px;\n  width: 6px;\n  height: 6px;\n  background: #528EFF;\n  border-radius: 50%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOApproval/components/Tables/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAFA;EAGQ,YAAA;AAER;AALA;EAMQ,YAAA;AAER;AARA;EAUY,eAAA;AACZ;AAIA;EACI,kBAAA;AAFJ;AAII;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AAFR","sourcesContent":[".table {\n    margin-top: 24px;\n    th:nth-child(3) {\n        width: 480px;\n    }\n    th:nth-child(4) {\n        width: 160px;\n    }\n    :global {\n        td.ant-table-cell {\n            cursor: pointer;\n        }\n    }\n}\n\n.newCellDot {\n    position: relative;\n\n    &::before {\n        content: '';\n        position: absolute;\n        top: 8px;\n        left: -15px;\n        width: 6px;\n        height: 6px;\n        background: #528EFF;\n        border-radius: 50%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table_EOmUR",
	"newCellDot": "newCellDot_rpC_c"
};
export default ___CSS_LOADER_EXPORT___;
