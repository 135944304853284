import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../ui'
// import CloseEditModal from '../CloseEditModal/CloseEditModal'
import style from './style.m.less'

interface IButtonsBlock {
    confirmAction?: () => void
    cancelAction?: () => void
    confirmTitle?: string
    margin?: boolean
    isDelete?: boolean
}

const ButtonsBlock: FC<IButtonsBlock> = ({
    confirmTitle,
    confirmAction,
    isDelete,
    margin,
    cancelAction,
}) => {
    const { t } = useTranslation()
    return (
        <div className={margin ? style.buttonsBlockMargin : style.buttonsBlock}>
            <PrimaryButton
                onClick={cancelAction}
                value="Отмена"
                isCancel
                customStyle={{ padding: '8px 16px' }}
            />
            <PrimaryButton
                onClick={confirmAction}
                isDelete={isDelete}
                value={confirmTitle ? confirmTitle : t('editMyProfile.save')}
                customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
            />
        </div>
    )
}

export default ButtonsBlock
