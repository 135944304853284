// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_br7rB {\n  padding: 40px 188px 105px 10px;\n  width: 1300px;\n  margin: 0 auto;\n}\n.titleWrapper_Hs5w2 {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n  margin-bottom: 8px;\n}\n.title_SCW9A {\n  padding-top: 8px;\n}\n.statusTag_Sl80C {\n  border: 1px solid #D9D9D9;\n  border-radius: 12px;\n  background: transparent;\n}\n.card_zM50C {\n  margin-top: 24px;\n}\n.graph_mhRRi {\n  margin-top: 24px;\n  margin-bottom: 16px;\n}\n.backLink_dGFeH {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MyZGO/style.m.less"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,aAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,yBAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAKA;EACI,gBAAA;AAHJ;AAMA;EACI,gBAAA;EACA,mBAAA;AAJJ;AAOA;EACI,eAAA;AALJ","sourcesContent":[".container {\n    padding: 40px 188px 105px 10px;\n    width: 1300px;\n    margin: 0 auto;\n}\n\n.titleWrapper {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n.title {\n    padding-top: 8px;\n}\n\n.statusTag {\n    border: 1px solid #D9D9D9;\n    border-radius: 12px;\n    background: transparent;\n}\n\n.card {\n    margin-top: 24px;\n}\n\n.graph {\n    margin-top: 24px;\n    margin-bottom: 16px;\n}\n\n.backLink {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_br7rB",
	"titleWrapper": "titleWrapper_Hs5w2",
	"title": "title_SCW9A",
	"statusTag": "statusTag_Sl80C",
	"card": "card_zM50C",
	"graph": "graph_mhRRi",
	"backLink": "backLink_dGFeH"
};
export default ___CSS_LOADER_EXPORT___;
