import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import style from './style.m.less'
import classNames from 'classnames'

const ShowMoreLink: FC<{
    showMore: boolean
    onChange(showMore: boolean): void
    className?: string
    noMargin?: boolean
}> = ({ showMore, onChange, className, noMargin }) => {
    const { t } = useTranslation()

    const baseStyle = noMargin ? style.moreDocumentsNoMargin : style.moreDocuments

    return (
        <div className={classNames(baseStyle, className)} onClick={() => onChange(!showMore)}>
            {!showMore && <span>{t('showMoreLink.showMore')}</span>}
            {showMore && <span>{t('showMoreLink.collapse')}</span>}
            {!showMore && <DownOutlined className={style.icon} />}
            {showMore && <UpOutlined className={style.icon} />}
        </div>
    )
}

export default ShowMoreLink
