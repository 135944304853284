// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".backButton_iszkX {\n  padding-left: 0;\n  border: none;\n  cursor: pointer;\n  transition: all 0.2s ease-in;\n  background-color: transparent;\n}\n.backButton_iszkX:hover {\n  color: #296dff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/BackArrow/style.m.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,eAAA;EACA,4BAAA;EACA,6BAAA;AACJ;AACI;EACI,cAAA;AACR","sourcesContent":[".backButton {\n    padding-left: 0;\n    border: none;\n    cursor: pointer;\n    transition: all 0.2s ease-in;\n    background-color: transparent;\n\n    &:hover {\n        color: #296dff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "backButton_iszkX"
};
export default ___CSS_LOADER_EXPORT___;
