import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Checkbox, Input } from 'antd'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'
import style from './style.m.less'

interface IOpenFirstAccountModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const OpenFirstAccountModal: FC<IOpenFirstAccountModal> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const { t } = useTranslation()
    const handleOk = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.modalHeader}>{t('myProfile.openPersonalAccount')}</div>
                <div className={style.modalNumber}>{t('myProfile.accountNumber')}</div>
                <div>№ 055579</div>
                <div className={style.modalText}>
                    <div>{t('myProfile.accountName')}</div>
                    <Input />
                </div>
                <Checkbox className={style.checkbox}>{t('myProfile.showAccount')}</Checkbox>
                <Alert
                    className={style.modalAlert}
                    message={
                        <div className={style.modalAlertText}>
                            <div>
                                За операцию взымается оплата в размере <strong>3 500 ₽</strong>
                            </div>
                            <div>Баланс платежного счета: 230 000 ₽</div>
                        </div>
                    }
                    type="warning"
                    showIcon
                />
                <div className={style.buttonsBlock}>
                    <ButtonsBlock
                        confirmAction={handleOk}
                        cancelAction={handleOk}
                        confirmTitle={t('myProfile.openAccount')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default OpenFirstAccountModal
