import { useEffect, useRef } from 'react'

interface IClickAwayListener {
    onClickOutside: () => void
    children: JSX.Element
}

function useOutsideAlerter(ref: any, onClickOutside: (state?: string) => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            const targetEl = ref.current
            if (targetEl) {
                const clickedX = event.clientX
                const clickedY = event.clientY
                const rect = targetEl.getBoundingClientRect()
                const targetElTop = rect.top
                const targetElBottom = rect.top + rect.height
                const targetElLeft = rect.left
                const targetElRight = rect.left + rect.width

                if (
                    targetElLeft < clickedX &&
                    clickedX < targetElRight &&
                    targetElTop < clickedY &&
                    clickedY < targetElBottom
                ) {
                    return
                }

                onClickOutside('close')
            }
        }
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [ref, onClickOutside])
}

const ClickAwayListener = ({ onClickOutside, children }: IClickAwayListener) => {
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, onClickOutside)

    return <div ref={wrapperRef}>{children}</div>
}
export default ClickAwayListener
