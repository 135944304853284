import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FileSaver from 'file-saver'
import { Warning } from '../../../../assets/icons'
import { formatSize, documentIconPicker } from '../../../helpers'
import { CenteredSpin, ShowMoreLink } from '../../../ui'
import request from '../../../../services/request'
import { Tooltip, Space } from 'antd'

import style from './style.m.less'

interface IDocumentsGrid {
    documents: any
}

const DocumentsGrid: FC<IDocumentsGrid> = ({ documents }) => {
    const { t } = useTranslation()

    const [showHidden, setShowHidden] = useState(false)

    const toggleHidden = () => {
        setShowHidden(!showHidden)
    }

    const [loading, setLoading] = useState(false)
    const handleClickDownload = async (id: string, name: string) => {
        try {
            if (!id) {
                return
            }

            setLoading(true)
            const body = await request.get(`/api/documents/v1/file/${id}`, {
                responseType: 'blob',
            })

            const bodyBlob = new Blob([body?.data])

            FileSaver.saveAs(bodyBlob, decodeURI(name))
        } finally {
            setLoading(false)
        }
    }

    const file = (item: { file_name: string; file_size: number; comment: string; id: string }) => {
        const format = item.file_name.split('.')[1]
        const size = formatSize(item.file_size)
        const getNameFile = () => {
            try {
                const res = decodeURI(item.file_name)
                return res
            } catch (e) {
                return false
            }
        }
        const nameFile = getNameFile()

        return (
            <div
                key={item.id}
                className={style.documentsWrapper}
                onClick={() => handleClickDownload(item.id, item.file_name)}
            >
                <div className={style.file}>
                    {documentIconPicker(format)}
                    <div className={style.fileInfo}>
                        {nameFile !== false ? (
                            <div className={style.fileTitle}>
                                {/* {decodeURI(item.file_name).split('.')[0]} */}
                                <span>{nameFile}</span>
                                {format && <>.{format}</>}
                            </div>
                        ) : (
                            <Tooltip title="Ошибка в названии файла">
                                <Space>
                                    <span>{item.file_name}</span>
                                    <Warning />
                                </Space>
                            </Tooltip>
                        )}
                        <div className={style.fileSize}>{size}</div>
                    </div>
                </div>
                <div className={style.description}>{item.comment}</div>
            </div>
        )
    }

    return (
        <div className={style.main}>
            {loading && <CenteredSpin />}
            <div className={style.header}>Документы</div>
            <div className={style.documentsGrid}>{documents?.slice(0, 6).map(file)}</div>
            {showHidden && (
                <div className={style.documentsGrid}>{documents?.slice(6).map(file)}</div>
            )}
            {documents.length > 6 && (
                <ShowMoreLink
                    showMore={showHidden}
                    onChange={toggleHidden}
                    className={style.showMore}
                />
            )}
        </div>
    )
}

export default DocumentsGrid
