import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Search: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.40039 10.8999C8.60953 10.8999 10.4004 9.10904 10.4004 6.8999C10.4004 4.69076 8.60953 2.8999 6.40039 2.8999C4.19125 2.8999 2.40039 4.69076 2.40039 6.8999C2.40039 9.10904 4.19125 10.8999 6.40039 10.8999Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.8004 13.2999L10.4004 10.8999"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
