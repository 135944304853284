import { FC } from 'react'
import { IOperationConfig } from './types'
import { HistoryBlock } from './components'

interface IOperationsMapper {
    config: IOperationConfig[]
}

const OperationsMapper: FC<IOperationsMapper> = ({ config }) => {
    return (
        <>
            {config?.map((item, index) => {
                const { date, operations, flexStart, iconMargin } = item

                return (
                    <HistoryBlock
                        key={index}
                        date={date}
                        operations={operations}
                        flexStart={flexStart}
                        iconMargin={iconMargin}
                    />
                )
            })}
        </>
    )
}

export default OperationsMapper
