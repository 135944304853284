import { Box } from '../../ui/Box'
import { Divider } from 'antd'
import { DeleteModal, IconButton } from '../../ui'
import AskInformationModal from './AskInformationModal'
import { useState } from 'react'
import style from '../style.m.less'
import { useNavigate } from 'react-router-dom'
import { candelPaymentReq } from '../config'
import useRequest from '../../hooks/useRequest'

type Props = {
    inquire: any
    getAppeal: any
    payment: any
}

const AppealOperatorChat = ({ inquire, getAppeal, payment }: Props) => {
    const navigate = useNavigate()
    const { fetch: cancelPaymentRequest, dropState } = useRequest(candelPaymentReq)
    const [isCommentModalVisible, setIsCommentModalVisible] = useState<boolean>(false)
    const [approveType, setApproveType] = useState<number>(1)
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

    const openCommentModal = (value: boolean, type: number) => {
        setIsCommentModalVisible(value)
        setApproveType(type)
    }

    const deleteModalHandler = () => {
        setDeleteModalVisible(!deleteModalVisible)
    }

    const onDeleteHandler = () => {
        cancelPaymentRequest({
            getParams: {
                id: inquire.id,
            },
            onSuccess: () => {
                dropState()
                deleteModalHandler()
                getAppeal()
            },
        })
    }

    return (
        <>
            <AskInformationModal
                isModalVisible={isCommentModalVisible}
                setIsModalVisible={openCommentModal}
                approveType={approveType}
                id={inquire?.id}
                getAppeal={getAppeal}
            />
            <DeleteModal
                isModalVisible={deleteModalVisible}
                onCancel={deleteModalHandler}
                onDelete={onDeleteHandler}
                deleteTitle="Подтвердить"
                headerText="Отмена запроса на оплату"
                isDelete={false}
                messageText="Вы действительно хотите отменить запрос на оплату?"
            />
            <Divider />
            {payment?.payment_status === 1 ? (
                <Box padding="0px 32px 32px 32px" direction="row" justify="space-between">
                    <IconButton onClick={deleteModalHandler} className={style.buttonMargin}>
                        Отменить запрос на оплату
                    </IconButton>
                </Box>
            ) : (
                <Box padding="0px 32px 32px 32px" direction="row" justify="space-between">
                    <Box direction="row" width={'100%'} justify="flex-start" gap={16}>
                        <IconButton onClick={() => openCommentModal(true, 1)}>
                            Запросить информацию
                        </IconButton>
                        <IconButton onClick={() => openCommentModal(true, 4)}>
                            Запросить оплату
                        </IconButton>
                        <IconButton onClick={() => navigate(`/complete-appeal/${inquire.id}`)}>
                            Выполнить
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={() => openCommentModal(true, 3)} red>
                            Отказать
                        </IconButton>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default AppealOperatorChat
