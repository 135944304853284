import { Input } from 'antd'
import React from 'react'

type Props = {
    onChange: (value: number) => void
    value?: any
    placeholder?: string
    className?: string
    style?: React.CSSProperties
    maxLength?: number
}

const InputOnlyLetter = ({ onChange, value, placeholder, className, style, maxLength }: Props) => {
    return (
        <Input
            className={className}
            placeholder={placeholder}
            value={value}
            style={style}
            onKeyDown={(event: any) => {
                if (event.key === 'Backspace') {
                    return
                }
                if (maxLength && maxLength < event.target.value.length) {
                    event.preventDefault()
                }
                if (/[^a-zA-ZА-Яа-яЁё]/.test(event.key)) {
                    event.preventDefault()
                }
            }}
            onChange={(event: any) => onChange(event.target.value)}
        />
    )
}

export default InputOnlyLetter
