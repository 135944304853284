import { FC, useState } from 'react'
import { Import, Calendar, User, TickSquare } from '../../assets/icons'
import { IconButton, CertificateCard, BackArrow, ModalStatus } from '../ui'
import { Alert, Divider, Progress } from 'antd'
import { useCertificateData } from '../hooks/useCertificateData'
import { repaymentData } from './repaymentData'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const UserCertificate: FC = () => {
    const { t } = useTranslation()

    const certificateData = useCertificateData()
    const [modalStatus, setModalStatus] = useState<ModalStatus>(['divide', false])
    const { toolType, cardLabel, id, isUserType } = certificateData

    //temporarily defines certificate type
    type ICertificateType = 'active' | 'repaid' | 'blocked' | 'overdue'
    const certificateType: ICertificateType = 'active'
    const showChangeButton = certificateType === 'active' || certificateType === 'blocked'

    return (
        <div className={style.certificateMain}>
            <div className={style.headerBlock}>
                <div className={style.backBlock}>
                    <BackArrow />
                    <div className={style.mainHeader}>{cardLabel}</div>
                </div>
                <IconButton
                    icon={<Import />}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                >
                    {t('certificate.upload')}
                </IconButton>
            </div>
            <CertificateCard
                certificateType={certificateType}
                isDetailed
                toolType={toolType}
                id={id}
                isUserType={isUserType}
                modalStatus={modalStatus}
                setModalStatus={setModalStatus}
            />
            {toolType === 'Сертификаты происхождения' && certificateType === 'repaid' && (
                <div className={style.repaymentBlock}>
                    <div className={style.repaymentHeader}>{t('certificate.payment')}</div>
                    <div className={style.objectBlock}>
                        <div className={style.infoBlock}>{t('certificate.paymentDate')}</div>
                        <div className={style.dateBlock}>
                            <Calendar />
                            <div className={style.dateLabel}>22.04.2022</div>
                        </div>
                    </div>
                    <div className={style.objectBlock}>
                        <div className={style.infoBlock}>{t('certificate.consumer')}</div>
                        <div className={style.nameBlock}>ООО «АльтЭнерго»</div>
                    </div>
                </div>
            )}
            {toolType === 'Сертификаты происхождения' && (
                <div className={style.repaymentBlock}>
                    <div className={style.repaymentHeader}>{t('certificate.ownerInfo')}</div>
                    <div className={style.objectBlock}>
                        <div className={style.infoBlock}>{t('certificate.prevOwner')} ЗИ</div>
                        <div className={style.nameBlock}>ООО Владелец</div>
                    </div>
                    <div className={style.objectBlock}>
                        <div className={style.infoBlock}>{t('certificate.currentOwner')} ЗИ</div>
                        <div className={style.nameBlock}>Макаров М.А.</div>
                    </div>
                    {showChangeButton && (
                        <IconButton
                            icon={<User />}
                            customStyle={{
                                marginTop: '24px',
                            }}
                            customIconStyle={{ marginRight: '8px', marginTop: '5px' }}
                            disabled={certificateType === 'blocked'}
                            onClick={() => setModalStatus(['owner', true])}
                        >
                            {t('certificate.changeOwner')}
                        </IconButton>
                    )}
                </div>
            )}
            {toolType === 'Зеленые договоры' && (
                <div className={style.repaymentBlock}>
                    <div className={style.repaymentHeader}>{t('certificate.implementedAttrs')}</div>
                    <div
                        className={
                            certificateType === 'overdue'
                                ? style.progressBlockOverdue
                                : style.progressBlock
                        }
                    >
                        <div className={style.numbersRow}>
                            <div className={style.value}>
                                446 166 {t('certificate.scale')}{' '}
                                <span className={style.description}>
                                    {t('certificate.implemented')}
                                </span>
                            </div>
                            <div className={style.value}>
                                191 214 {t('certificate.scale')}{' '}
                                <span className={style.description}>{t('certificate.left')}</span>
                            </div>
                        </div>
                        <Progress
                            className={style.progressBar}
                            percent={70}
                            showInfo={false}
                            strokeColor={'#52D2A0'}
                        />
                        <Divider className={style.divider} />
                    </div>
                    {certificateType === 'repaid' && (
                        <Alert
                            className={style.repaymentAlert}
                            message={t('certificate.fullyPaid')}
                            type="success"
                            showIcon
                        />
                    )}
                    {certificateType === 'active' && (
                        <IconButton
                            customIconStyle={{ marginTop: '4px', marginRight: '10px' }}
                            icon={<TickSquare />}
                        >
                            {t('certificate.implement')}
                        </IconButton>
                    )}
                    {certificateType !== 'active' &&
                        repaymentData?.map((item) => {
                            const { date, name, power } = item

                            return (
                                <div key={power}>
                                    <div className={style.objectBlock}>
                                        <div className={style.dateBlock}>
                                            <Calendar />
                                            <div className={style.dateLabel}>{date}</div>
                                        </div>
                                        <div className={style.nameBlock}>{name}</div>
                                        <div className={style.powerBlock}>{power}</div>
                                    </div>
                                    <Divider className={style.divider} />
                                </div>
                            )
                        })}
                </div>
            )}
        </div>
    )
}

export default UserCertificate
