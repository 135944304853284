import { FC } from 'react'
import { Divider, Alert } from 'antd'
import { ArrowLeft, Calendar } from '../../assets/icons'
import { InfoBlock, DocumentsGrid, Tables, ButtonContainer } from './components/'
import { Card, CardInfoBlock, Tag } from '../ui'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import style from './style.m.less'

enum EStatus {
    UnderConsideration = 'На рассмотрении',
    NotAgreed = 'Не согласовано',
    Accepted = 'Принято',
}

const status = EStatus.UnderConsideration

const ZDApprovalItem: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div>
                    <h1 className={style.title}>
                        {t('zdApprovalItem.title')} 17.02.2022 - 30.02.2022
                    </h1>
                </div>
                <Tag
                    outline
                    transparent
                    color={
                        status === EStatus.NotAgreed
                            ? 'red'
                            : status === EStatus.Accepted
                            ? 'blue'
                            : undefined
                    }
                >
                    {status}
                </Tag>
            </div>
            <Card className={style.card}>
                {status === EStatus.NotAgreed && (
                    <div className={style.statusNotAgreedContainer}>
                        <div className={style.statusText}>Данные не приняты. Причина:</div>
                        <div className={style.statusReason}>Текст причины на 500 символов</div>
                    </div>
                )}
                <h4 className={style.docNumber}>0819141049197-KP-BUGULSES-RUSENSBI-E-SDD-19</h4>
                <Tag outline transparent color="blue" className={style.powerValue}>
                    637380,00 кВт*ч
                </Tag>
                <div className={style.infoBlockWrapper}>
                    <CardInfoBlock
                        label={t('zdApprovalItem.registrationDate')}
                        value={'20.04.2022'}
                        icon={<Calendar />}
                        gap={7}
                    />
                    <Divider className={style.divider} type={'vertical'} />
                    <CardInfoBlock
                        label={t('zdApprovalItem.contractValidityPeriod')}
                        value={'с 20.04.2022 по 30.07.2023 '}
                        icon={<Calendar />}
                        gap={7}
                    />
                </div>
                <Divider type={'horizontal'} />
                <InfoBlock />
                <Divider type={'horizontal'} />
                <div className={style.submittedApplicationWrapper}>
                    <div className={style.submittedApplicationSelected}>Владелец ГО</div>
                    <div className={style.submittedApplication}>ГП</div>
                </div>
                <DocumentsGrid />
                <Alert
                    message={t('zdApprovalItem.alert')}
                    type="warning"
                    showIcon
                    className={style.alert}
                />
                <Tables />
                <Divider type={'horizontal'} />
                {status === EStatus.UnderConsideration && <ButtonContainer />}
            </Card>
        </div>
    )
}

export default ZDApprovalItem
