import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '../../../../components/ui'
import { Input, Form } from 'antd'
import style from './style.m.less'
import TextArea from 'antd/lib/input/TextArea'

interface IFivePartsBlock {
    dateChange: (value: any) => void
    passDate: string
}

const FivePartsBlock: FC<IFivePartsBlock> = ({ dateChange, passDate }) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={style.inputsWrapperShort}>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.series')}
                        <div className={style.star}>*</div>
                    </div>
                    <Form.Item
                        name={'passport_series'}
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^\d+$/),
                                message: '',
                            },
                            { min: 4, message: 'Укажите серию паспорта (4 цифры)' },
                        ]}
                    >
                        <Input minLength={4} maxLength={4} className={style.inputSmall} />
                    </Form.Item>
                </div>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.number')}
                        <div className={style.star}>*</div>
                    </div>
                    <Form.Item
                        name={'passport_number'}
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^\d+$/),
                                message: '',
                            },
                            { min: 6, message: 'Укажите номер паспорта (6 цифр)' },
                        ]}
                    >
                        <Input minLength={6} maxLength={6} className={style.inputSmall} />
                    </Form.Item>
                </div>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.issueDate')}
                        <div className={style.star}>*</div>
                    </div>
                    <Form.Item
                        name={'passport_date'}
                        rules={[
                            {
                                required: true,
                                message: '',
                            },
                        ]}
                    >
                        <DatePicker
                            defaultValue={passDate}
                            onChange={dateChange}
                            className={style.datePicker}
                        />
                    </Form.Item>
                </div>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.divisionCode')}
                        <div className={style.star}>*</div>
                    </div>
                    <Form.Item
                        name={'passport_betrayed_code'}
                        rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^\d+$/),
                                message: '',
                            },
                            { min: 6, message: 'Укажите код подразделения (6 цифр)' },
                        ]}
                    >
                        <Input minLength={6} maxLength={6} className={style.inputMiddle} />
                    </Form.Item>
                </div>
            </div>
            <div className={style.textAreaBlock}>
                <div className={style.inputBlock}>
                    {t('editMyProfile.whomIssued')}
                    <div className={style.star}>*</div>
                </div>
                <Form.Item
                    name={'passport_betrayed_name'}
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                    ]}
                >
                    <TextArea className={style.textArea} />
                </Form.Item>
            </div>
        </>
    )
}

export default FivePartsBlock
