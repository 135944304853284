import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft } from '../../assets/icons'
import { Card, CenteredSpin, Tag } from '../ui'
import { DocumentsGrid, Questionnaire, ButtonContainer } from './components'
import { selectDictionariesMaps } from '../../store/dictionaries'
import PowerTypeTag from '../DetailedCard/components/PowerTypeTag/PowerTypeTag'
import request from '../../services/request'
import { IGeneratingObject } from '../../store/generators/interfaces'
import { ELoading } from '../../store/dictionaries/interfaces'

import style from './style.m.less'
import { useAppSelector } from '../../store/hooks'

enum EGeneratorStatus {
    New = 1,
    UnderConsideration = 2,
    Agreed = 3,
    NotAgreed = 4,
    Removed = 5,
}

const getStatusName = (status?: EGeneratorStatus) => {
    if (status === EGeneratorStatus.New) {
        return 'Новая'
    } else if (status === EGeneratorStatus.UnderConsideration) {
        return 'На рассмотрении'
    } else if (status === EGeneratorStatus.Agreed) {
        return 'Согласована'
    } else if (status === EGeneratorStatus.NotAgreed) {
        return 'Не согласована'
    } else if (status === EGeneratorStatus.Removed) {
        return 'Удален'
    }
}

const GOApprovalItem: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    const [generator, setGenerator] = useState<IGeneratingObject>()
    const [loading, setLoading] = useState(false)
    const isLoading = useAppSelector((state) => state.dictionaries.loading)

    useEffect(() => {
        const loadGenerator = async () => {
            try {
                setLoading(true)
                const res = await request.get(`/api/generators/v1/go?generator_id=${id}`)
                setGenerator(res.data)
            } finally {
                setLoading(false)
            }
        }
        loadGenerator()
    }, [id])

    const dictionaries = useAppSelector(selectDictionariesMaps)
    const { renewableEnergyTypesMap } = dictionaries

    return (
        <div className={style.container}>
            {(loading || isLoading === ELoading.Loading) && <CenteredSpin />}
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div>
                    <h1 className={style.title}>{generator?.name}</h1>
                </div>
                <PowerTypeTag
                    customStyle={{ marginLeft: '16px' }}
                    value={renewableEnergyTypesMap?.get(generator?.renewable_energy_type_id)}
                />
                <Tag
                    outline
                    transparent
                    color={
                        generator?.generator_status === EGeneratorStatus.NotAgreed
                            ? 'red'
                            : generator?.generator_status === EGeneratorStatus.Agreed
                            ? 'blue'
                            : undefined
                    }
                >
                    {getStatusName(generator?.generator_status)}
                </Tag>
            </div>
            <Card className={style.questionnaireCard}>
                {generator?.generator_status === EGeneratorStatus.NotAgreed && (
                    <div className={style.statusNotAgreedContainer}>
                        <div className={style.statusText}>Данные не приняты. Причина:</div>
                        <div className={style.statusReason}>{generator?.generator_comment}</div>
                    </div>
                )}

                <Questionnaire config={generator} />
                {generator?.documents && (
                    <>
                        <div className={style.verticalLine} />
                        <DocumentsGrid documents={generator?.documents} />
                    </>
                )}
                {generator?.generator_status === EGeneratorStatus.UnderConsideration && (
                    <>
                        <div className={style.verticalLine} />
                        <ButtonContainer generator={generator} />
                    </>
                )}
            </Card>
        </div>
    )
}

export default GOApprovalItem
