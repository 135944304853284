import { renderDayMonthYear } from '../../../../utils/formatter'
import { currencyFormat } from '../../../helpers'

export const KUInfoConfig = (data: any) => [
    {
        name: 'Владелец ЗГО',
        value:
            data.user.user_type === 1
                ? `ИП ${data.user.last_name} ${data.user.first_name} ${data.user.middle_name}`
                : data.user.company_name_short,
    },
    {
        name: 'Общий объем выработки',
        value: `${currencyFormat(data.volume)} кВт*ч`,
    },
    {
        name: 'Дата подачи',
        value: renderDayMonthYear(data.ts_created),
    },
]
