import { useState } from 'react'
import style from './style.m.less'
import { Tag } from '../../../ui'
import DeregisterTable from './DeregisterTable'
import LockedTable from './LockedTable'

enum ETab {
    Blocks,
    Deregister,
}

const OperationsHistory = () => {
    const [tab, setTab] = useState(ETab.Blocks)

    return (
        <div className={style.main}>
            <div className={style.header}>История операций</div>
            <div className={style.tagFilter}>
                <Tag
                    key={'1'}
                    outline
                    className={tab === ETab.Blocks ? style.clickable : style.clickable_unselect}
                    color={tab === ETab.Blocks ? 'blue' : undefined}
                    onClick={() => setTab(ETab.Blocks)}
                >
                    Блокировки
                </Tag>
                <Tag
                    key={'2'}
                    outline
                    className={tab === ETab.Deregister ? style.clickable : style.clickable_unselect}
                    color={tab === ETab.Deregister ? 'blue' : undefined}
                    onClick={() => setTab(ETab.Deregister)}
                >
                    Снятие с учета
                </Tag>
            </div>
            <div className={style.table}>{tab === ETab.Blocks && <LockedTable />}</div>
            <div className={style.table}>{tab === ETab.Deregister && <DeregisterTable />}</div>
        </div>
    )
}

export default OperationsHistory
