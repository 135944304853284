const T_WHITESPACE = 'whitespace'
const T_IDENTIFIER = 'identifier'
const T_OIDENTIFIER = 'oid'
const T_EQUALS_QUOTED = 'equalsQuoted'
const T_EQUALS_RAW = 'equalsRaw'
const T_COMMA = 'comma'
const T_EQUALS = 'equals'
const T_QUOTED = 'quoted'

const tokenizeAuth = tokenizer({
    [T_WHITESPACE]: /^\s+$/,
    [T_IDENTIFIER]: /^[a-z][a-z0-9]*$/i,
    [T_QUOTED]: /^"(\\.|[^"\\])*"?$/,
    [T_EQUALS]: /^=$/,
    [T_COMMA]: /^,$/,
})

const tokenizeSubjectName = tokenizer({
    [T_WHITESPACE]: /^\s+$/,
    [T_IDENTIFIER]: /^[a-zа-я0-9]+$/i,
    [T_OIDENTIFIER]: /^(OID\.|\d)[0-9.]*$/,
    [T_EQUALS_QUOTED]: /^="(\\.|""|[^\\"])*"?$/,
    [T_EQUALS_RAW]: /^=(\\.|[^,])*$/,
    [T_COMMA]: /^,$/,
})

const S_BEGIN = 0
const S_PARAM = 1
const S_IDENT = 2
const S_EQUAL = 3
const S_VALUE = 4

/**
 * Разбор строки вида
 *  Newauth realm="realm \"cbg\"", challenge="111,ffff,7f7f7"
 * Возвращает карту
 * { 'realm': 'realm "cbg"',
 *   'challenge': '111,ffff,7f7f7' }
 */
export function parseWwwAuthenticate(value, scheme = 'Newauth') {
    const params = new Map()

    let st = S_BEGIN
    let pName, pValue

    tokenizeAuth(value, function readToken(token, type) {
        if (st === S_BEGIN) {
            assertTokenType(T_IDENTIFIER)
            if (token !== scheme) {
                throw new Error(`unknown scheme: ${token}`)
            }
            st = S_PARAM
        } else if (st === S_PARAM) {
            assertTokenType(T_IDENTIFIER, T_WHITESPACE)
            if (type !== T_WHITESPACE) {
                st = S_IDENT
                pName = token
            }
        } else if (st === S_IDENT) {
            assertTokenType(T_EQUALS)
            st = S_EQUAL
        } else if (st === S_EQUAL) {
            assertTokenType(T_QUOTED)
            st = S_VALUE
            pValue = JSON.parse(token)
            params.set(pName, pValue)
        } else if (st === S_VALUE) {
            assertTokenType(T_COMMA)
            st = S_PARAM
        }
        function assertTokenType(...allowedTypes) {
            if (!allowedTypes.includes(type)) {
                throw new Error(`parse error: ${token} (${type})`)
            }
        }
    })

    return params
}

/**
 * Разбор строки вида
 *   E=a.potapov@test.com, 1.2.643.2.2.44.5="#0C0D34362E3138322E32352E313337", STREET="""4 Shlyuzovaya emb.""", CN=TestAdmin(1111122222333)
 * Возвращает карту
 * { 'E' => 'a.potapov@test.com',
 *   '1.2.643.2.2.44.5' => '#0C0D34362E3138322E32352E313337',
 *   'STREET' => '"4 Shlyuzovaya emb."',
 *   'CN' => 'TestAdmin(1111122222333)' }
 */
export function parseCertificateSubjectName(value) {
    const params = new Map()

    let st = S_BEGIN
    let pName, pValue

    tokenizeSubjectName(value, function readToken(token, type) {
        if (st === S_BEGIN) {
            assertTokenType(T_IDENTIFIER, T_OIDENTIFIER, T_WHITESPACE)
            if (type !== T_WHITESPACE) {
                st = S_IDENT
                pName = token
            }
        } else if (st === S_IDENT) {
            assertTokenType(T_EQUALS_QUOTED, T_EQUALS_RAW)
            st = S_VALUE
            if (type === T_EQUALS_QUOTED) {
                pValue = unquoteValue(token)
            } else {
                pValue = unquoteValueLite(token)
            }
            params.set(pName, pValue)
        } else if (st === S_VALUE) {
            assertTokenType(T_COMMA)
            st = S_BEGIN
        }
        function assertTokenType(...allowedTypes) {
            if (!allowedTypes.includes(type)) {
                throw new Error(`parse error: ${token} (${type})`)
            }
        }
    })

    return params
}

function unquoteValue(token) {
    return token
        .replace(/^="|"$/g, '')
        .replace(/""/g, '"')
        .replace(/\\(.)/g, '$1')
}

function unquoteValueLite(token) {
    return token.substring(1).replace(/\\(.)/g, '$1')
}

function tokenizer(rules) {
    return function tokenize(string, emit) {
        let buf = ''
        const step = 64
        for (let index = 0; index < string.length; index += step) {
            process(string.substr(index, step))
        }
        process('', true)
        function process(data, nobuffer) {
            data = buf + data
            buf = ''
            if (data.length > 0) {
                const maxIndex = disect(0, data.length, function (index) {
                    return matchRule(data.substring(0, index + 1)) === null
                })
                if (maxIndex === 0) {
                    throw new Error(`syntax error: ${data}`)
                } else if (maxIndex === data.length && !nobuffer) {
                    buf = data
                } else {
                    const token = data.substring(0, maxIndex)
                    emit(token, matchRule(token))
                    process(data.substring(maxIndex), nobuffer)
                }
            }
        }
    }
    function matchRule(str) {
        for (const rule of Object.keys(rules)) {
            if (rules[rule].test(str)) {
                return rule
            }
        }
        return null
    }
}

function disect(min, max, predicate) {
    for (let k = min; k < max; ++k) {
        if (predicate(k)) {
            return k
        }
    }
    return max
}
