import { FC, useState } from 'react'
import { Modal, PrimaryButton, LoadingContainer } from '../../../ui'
import { Checkbox, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useGetTariffRate } from '../../../hooks'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import style from './style.m.less'

interface IPaymentModal {
    isModalVisible: boolean
    onCancel: () => void
    deleteTitle?: string
    cancelTitle?: string
    goName?: string
    id?: string
}

const PaymentModal: FC<IPaymentModal> = ({
    isModalVisible,
    onCancel,
    goName,
    id,
    deleteTitle = 'Оплатить',
    cancelTitle = 'Отмена',
}) => {
    const [checked, setIsChecked] = useState(false)
    const navigate = useNavigate()

    const { fetch, isLoading } = useRequest({
        link: `/api/generators/v1/go-pay?generator_id=${id}`,
        method: RequestTypes.get,
    })

    const onConfirm = () => {
        fetch({
            successMessage: {
                title: 'Успешно',
                description: `ЗГО ${goName} успешно оплачен`,
            },
            onSuccess: () => navigate('/my-generators/Active'),
            errorCodeMessage: true,
        })
    }

    const { loadingTariffs, balance, rate } = useGetTariffRate({
        dependency: isModalVisible,
        id: 65,
    })

    const onChange = (e: any) => {
        setIsChecked(e.target.checked)
    }

    return (
        <LoadingContainer isLoading={loadingTariffs || isLoading}>
            <Modal isModalVisible={isModalVisible} onCancel={onCancel}>
                <div className={style.modalWrapper}>
                    <div className={style.header}>{'Оплата комиссии'}</div>
                </div>
                <Checkbox checked={checked} className={style.checkbox} onClick={onChange}>
                    {`Согласен оплатить услуги Системы по добавлению ГО ${goName}`}
                </Checkbox>
                <div className={style.payAlert}>
                    <div className={style.payHeader}>
                        <span className={style.alertMessage}>Сумма комиссии составила </span>
                        <span className={style.alertPrice}>{rate}</span>
                    </div>
                    <div className={style.payText}>
                        <span className={style.alertDetail}>Баланс платежного счета</span>
                        <span className={style.alertDetail}> {balance}</span>
                    </div>
                </div>
                <div className={style.buttonsBlock}>
                    <PrimaryButton
                        onClick={onCancel}
                        value={cancelTitle}
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryButton
                        onClick={onConfirm}
                        disabled={!checked}
                        value={deleteTitle}
                        customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </LoadingContainer>
    )
}

export default PaymentModal
