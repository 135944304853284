import { CSSProperties, FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { NewsItem } from '../ui'

import styles from './style.m.less'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { Spin } from 'antd'
import useGetFiles from '../hooks/useGetFiles'

interface IOtherNews {
    title?: string
    style?: CSSProperties
}

const OtherNews: FC<IOtherNews> = ({ title = 'newsAndEvents.otherNews', style = {} }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const {
        files: otherNewsFiles,
        downloadFileList: downloadOtherFileList,
        loadingIds: otherNewsFilesLoadingIds,
    } = useGetFiles()
    const handleClickOtherNews = (id: string) => {
        navigate(`/news/${id}`)
    }

    const {
        fetch,
        result: otherNews,
        isLoading: newsLoading,
    } = useRequest({
        link: `/api/content/v1/news`,
        method: RequestTypes.post,
    })

    const getOtherNews = () => {
        const req = {
            body: {
                limit: 3,
                // offset: 20,
                filters: {
                    text_filter: null, //Поиск текста по заголовку и контексту
                    ts_from: null, // "2021-09-07T00:00:00Z",
                    ts_to: '2100-01-01T00:00:00Z', // "2023-03-16T00:00:00Z",
                    news_type_id: 0, // Тип Все - 0, Новость - 1 (значение по умолчанию), Событие - 2
                },
                sort: {
                    type_id: 1, //Сортировка 1 - по Дате
                    is_descending: true, // false - ascending; true - descending
                },
            },
        }
        fetch(req)
    }

    useEffect(() => {
        getOtherNews()
    }, [])

    const dowloadAllDocs = () => {
        const imgIdsList = (otherNews?.news || []).map((el: any) => el.document.id)
        downloadOtherFileList(imgIdsList)
    }

    useEffect(() => {
        if (otherNews?.news) {
            dowloadAllDocs()
        }
    }, [otherNews])

    return (
        <div className={styles.newsOrEvent} style={style}>
            <div className={styles.container}>
                <Spin spinning={newsLoading}>
                    <div className={styles.otherNews}>
                        {title && <h2 className={styles.secondTitle}>{t(title)}</h2>}
                        <div className={styles.newsContainer}>
                            <div className={styles.grid}>
                                {!newsLoading &&
                                    otherNews?.news?.map((item: any) => (
                                        <NewsItem
                                            key={item.id}
                                            title={item.title}
                                            date={item.ts_created}
                                            isNews={item.news_type_id === 1}
                                            isEvent={item.news_type_id === 2}
                                            description={item.body}
                                            image={
                                                !otherNewsFiles[item.document.id] &&
                                                require('../../assets/images/newsBig.jpg')
                                            }
                                            fileLoading={otherNewsFilesLoadingIds[item.document.id]}
                                            file={
                                                item.document.id && otherNewsFiles[item.document.id]
                                            }
                                            onClick={() => handleClickOtherNews(item.id)}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default OtherNews
