import { FC, useState } from 'react'
import { Tabs } from 'antd'
import style from './style.m.less'
import { Link } from 'react-router-dom'
import { Search, Check } from './components'

const { TabPane } = Tabs

const tabsList = [
    { label: 'Поиск ЗИ', key: 0, content: <Search /> },
    { label: 'Проверка потребления по ЗИ', key: 1, content: <Check /> },
]

const UnregisteredLayout: FC = () => {
    const [currentOption, setCurrentOption] = useState(tabsList[0].label)

    return (
        <div className={style.mainWrapper}>
            <div className={style.registryInfoBlock}>
                Укажите данные о зеленом инструменте для ознакомления с доступной информацией.
                <br />
                <Link to="/register">Регистрация</Link> в СКИЗИ позволяет получить полный доступ к
                информации о зеленых инструментах.
            </div>
            <Tabs
                defaultActiveKey="0"
                onChange={(activeKey) => {
                    const activeNumber = Number(activeKey)
                    setCurrentOption(tabsList[activeNumber].label)
                }}
                className={style.tabs}
            >
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane tab={label} key={key}>
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
        </div>
    )
}

export default UnregisteredLayout
