import { FC } from 'react'
import { Alert, Input, notification } from 'antd'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const { TextArea } = Input

interface ICancelCoordinateModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const CancelCoordinateModal: FC<ICancelCoordinateModal> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleOk = () => {
        setIsModalVisible(false)
        navigate('/zd-approval')
        notification.success({
            message: 'Отказ отправлен',
            description:
                'Данные по ЗД за 17.02.2022 - 30.02.2022 по ЗГО “Абаканская СЭС” отклонены, комменатрий направлен пользователю',
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>{t('zdApprovalItem.cancelCoordinate')}</div>

            <div className={style.dataBlock}>
                <div>{t('zdApprovalItem.reason')}</div>
                <TextArea
                    className={style.textArea}
                    placeholder={t('zdApprovalItem.yourComment')}
                    rows={4}
                />
            </div>

            <Alert
                className={style.activationAlert}
                message={t('zdApprovalItem.userSeeComment')}
                type="warning"
                showIcon
            />

            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleOk}
                    cancelAction={handleOk}
                    confirmTitle={t('zdApprovalItem.approveButton')}
                />
            </div>
        </Modal>
    )
}

export default CancelCoordinateModal
