import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Mail2: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="14"
                viewBox="0 0 20 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.623 0.5H1.37811C1.01124 0.5 0.714844 0.764062 0.714844 1.09091V12.9091C0.714844 13.2359 1.01124 13.5 1.37811 13.5H18.623C18.9899 13.5 19.2863 13.2359 19.2863 12.9091V1.09091C19.2863 0.764062 18.9899 0.5 18.623 0.5ZM16.9483 2.51094L10.4089 7.04432C10.2472 7.15696 10.0213 7.15696 9.85961 7.04432L3.31816 2.51094C3.2935 2.49398 3.27541 2.47058 3.26644 2.44401C3.25746 2.41743 3.25804 2.38903 3.26809 2.36276C3.27815 2.3365 3.29717 2.31369 3.32251 2.29755C3.34784 2.2814 3.37821 2.27272 3.40936 2.27273H16.8571C16.8882 2.27272 16.9186 2.2814 16.9439 2.29755C16.9692 2.31369 16.9883 2.3365 16.9983 2.36276C17.0084 2.38903 17.009 2.41743 17 2.44401C16.991 2.47058 16.9729 2.49398 16.9483 2.51094Z"
                    fill="currentColor"
                />
            </svg>
        )}
    />
)
