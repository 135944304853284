import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../../ui'

import style from './style.m.less'

const TagFilter: FC = () => {
    const { t } = useTranslation()

    const [addingSelected, seAaddingelected] = useState(true)
    const [editingSelected, setEditingSelected] = useState(false)

    return (
        <div className={style.tagFilter}>
            <Tag
                outline
                className={style.clickable}
                color={addingSelected ? 'blue' : undefined}
                onClick={() => seAaddingelected((s) => !s)}
            >
                #{t('gOApprovalItem.addingZGO')}
            </Tag>
            <Tag
                outline
                className={style.clickable}
                color={editingSelected ? 'blue' : undefined}
                onClick={() => setEditingSelected((s) => !s)}
            >
                #{t('gOApprovalItem.editingZGO')}
            </Tag>
        </div>
    )
}

export default TagFilter
