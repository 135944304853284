import { FC } from 'react'
import { InputNumber } from 'antd'
import style from './style.m.less'
import moment from 'moment'
import { TimeRangePicker } from '../../../ui'

interface IFilter {
    onChange: (data: string | number | number[], key: string, obj?: any) => void
}

const Filter: FC<IFilter> = ({ onChange }) => {
    const updateFilters = (data: any, key: string) => {
        switch (key) {
            case 'rangeData':
                return onChange('', '', {
                    created_from: data ? moment(data[0]).startOf('day') : undefined,
                    created_to: data ? moment(data[1]).endOf('day') : undefined,
                })
            case 'rangeReport':
                return onChange('', '', {
                    period_from: data ? moment(data[0]).startOf('day') : undefined,
                    period_to: data ? moment(data[1]).endOf('day') : undefined,
                })
            default:
                return onChange(data, key)
        }
    }

    const filterWidth = { width: '364px' }

    return (
        <div className={style.filter}>
            <div className={style.filterItem}>
                <div className={style.label}>Дата подачи</div>
                <TimeRangePicker
                    style={filterWidth}
                    onChange={(value) => updateFilters(value, 'rangeData')}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Отчетный период</div>
                <TimeRangePicker
                    style={filterWidth}
                    onChange={(value) => updateFilters(value, 'rangeReport')}
                    picker="month"
                    format="MMMM, YYYY"
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Количество договоров в подаче</div>
                <InputNumber
                    className={style.input}
                    placeholder="Введите значение"
                    controls={false}
                    onChange={(value) => updateFilters(value, 'contracts')}
                />
            </div>
        </div>
    )
}

export default Filter
