import { FC, useState } from 'react'
import { Form, Alert, Input } from 'antd'
import { useSelector } from 'react-redux'
import IAppState from '../../../../store/states'
import { EUserRole } from '../../../../constants/profile'
import { Modal, PrimaryButton } from '../../../ui'
import { DocumentsBlock } from '../../../ContinueRegistration/components'

import style from './style.m.less'

// TODO ПЕРЕНЕСТИ ЛОГИКУ ЗАПРОСА ВНУТРЬ МОДАЛЬНОГО ОКНА !

interface IHandleBlockModal {
    isVisible: boolean
    onOk: (body: {
        need_block_add: boolean
        reason: string
        unblock_conditions: string
        documents: any[]
    }) => void
    onCancel: () => void
    isUnBlocking?: boolean
    blockingUserRole: EUserRole
}

interface IFile {
    id: string
    uid: string
    hash: string
    comment: string
    size: number
}

const { TextArea } = Input

const HandleBlockModal: FC<IHandleBlockModal> = ({
    isVisible,
    isUnBlocking,
    blockingUserRole,
    onOk,
    onCancel,
}) => {
    const [fileList, setFileList] = useState<{ list: IFile[]; total: number }>({
        list: [],
        total: 0,
    })
    const userRole = useSelector((state: IAppState) => state.user.data.user_role)
    const isSuperAdmin = userRole === EUserRole.SuperAdmin
    const isBlockingUserAdmin = [EUserRole.Administrator, EUserRole.Operator].includes(
        blockingUserRole,
    )

    const addFile = (file: IFile, id: string, hash: string, comment: string) => {
        file.id = id
        file.hash = hash
        file.comment = comment
        setFileList((currentList) => {
            return {
                list: [...currentList.list, file],
                total: currentList.total + file.size,
            }
        })
    }

    const deleteFile = (id: string) => {
        const filteredArray = fileList.list.filter((file) => file.uid !== id)
        setFileList({
            total: filteredArray.length,
            list: filteredArray,
        })
    }

    const closeModal = () => {
        fileList.total &&
            setFileList({
                list: [],
                total: 0,
            })
        onCancel()
    }

    const onFinish = (values: any) => {
        const body = { ...values }
        if (fileList.total) {
            body.documents = fileList.list
        }
        onOk(body)
    }

    return (
        <Modal className={style.modal} isModalVisible={isVisible}>
            <Form className={style.form} onFinish={onFinish}>
                <div className={style.title}>
                    {isUnBlocking ? 'Снятие блокировки' : 'Блокировка'} учетной записи{' '}
                    {isSuperAdmin && isBlockingUserAdmin && 'административного'} пользователя
                </div>
                {!isUnBlocking && (
                    <Alert
                        message={
                            isSuperAdmin && isBlockingUserAdmin
                                ? 'Укажите основание для блокировки. Административный пользователь увидит его в уведомлении о блокировке'
                                : 'Укажите основание для блокировки. Пользователь увидит его в уведомлении о блокировке'
                        }
                        type="info"
                        showIcon
                        className={style.alert}
                    />
                )}
                <Form.Item
                    rules={[{ required: true, message: 'Пожалуйста, укажите основание' }]}
                    name="reason"
                    label="Основание"
                    className={style.formItem}
                >
                    <TextArea
                        className={style.textArea}
                        placeholder={`Укажите основание для ${
                            isUnBlocking ? 'снятия ' : ''
                        }блокировки учетной записи`}
                        rows={3}
                        maxLength={1000}
                    />
                </Form.Item>
                {!isUnBlocking && isBlockingUserAdmin && (
                    <Form.Item
                        name="unblock_condition"
                        label="Условие снятия блокировки"
                        rules={[{ required: true, message: 'Пожалуйста, укажите условие' }]}
                        className={style.formItem}
                    >
                        <TextArea
                            className={style.textArea}
                            placeholder={`Укажите условие для снятия блокировки учетной записи`}
                            rows={3}
                            maxLength={2000}
                        />
                    </Form.Item>
                )}
                <DocumentsBlock
                    fileList={fileList}
                    updateFileList={addFile}
                    deleteFromFileList={deleteFile}
                    className={style.documentBlock}
                    maxFileSize={10}
                    withoutComment
                />
                <div className={style.buttonsBlock}>
                    <Form.Item>
                        <PrimaryButton
                            onClick={closeModal}
                            value="Отмена"
                            isCancel
                            type="reset"
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <PrimaryButton
                            isDelete={!isUnBlocking}
                            type="submit"
                            value={isUnBlocking ? 'Разблокировать' : 'Заблокировать'}
                            customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                        />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    )
}

export default HandleBlockModal
