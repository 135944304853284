import { Collapse } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft, BookSaved } from '../../assets/icons'
import { Card, CenteredSpin, IconButtonSmall, Tag } from '../ui'
import {
    DocumentsGrid,
    Documents,
    Questionnaire,
    ButtonContainer,
    privatePersonInfoConfig,
    CardPanel,
    Sign,
    HistoryModal,
    individualEntrepreneurInfoConfig,
    legalPersonInfoConfig,
} from './components/'
import request from '../../services/request'

import style from './style.m.less'
import { EStatus, statusMap } from '../../constants/approval'
import { IUserInfo } from 'store/user/interfaces'

const getConfig = (profile: IUserInfo, nextProfile?: IUserInfo) => {
    if (profile.user_type === 0) {
        return privatePersonInfoConfig(profile, nextProfile)
    } else if (profile.user_type === 1) {
        return individualEntrepreneurInfoConfig(profile, nextProfile)
    } else {
        return legalPersonInfoConfig(profile, nextProfile)
    }
}

export enum EAction {
    SignUp = 'Регистрация',
    ChangeRole = 'СменаРоли',
    Edit = 'Редактирование',
}

const QuestionnaireApprovalItem: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id, uid, prevId } = useParams<{ id: string; uid: string; prevId: string }>()

    const [isHistoryVisible, setIsHistoryVisible] = useState(false)
    const openHistory = () => setIsHistoryVisible(true)
    const closeHistory = () => setIsHistoryVisible(false)

    const [loading, setLoading] = useState(true)
    const [profile, setProfile] = useState<IUserInfo>()
    const [prevProfile, setPrevProfile] = useState<IUserInfo>()
    const [history, setHistory] = useState()

    useEffect(() => {
        const loadHistory = async () => {
            const res = await request.get(`/api/users/v1/profiles-events?uid=${uid}`)
            setHistory(res.data)
        }
        loadHistory()
    }, [uid])

    useEffect(() => {
        const loadProfile = async () => {
            const res = await request.get(`/api/users/v1/profile-admin?profile_id=${id}&uid=${uid}`)
            setProfile(res.data)
        }
        const loadPrevProfile = async () => {
            const res = await request.get(
                `/api/users/v1/profile-admin?profile_id=${prevId}&uid=${uid}`,
            )
            setPrevProfile(res.data)
        }
        const load = async () => {
            setLoading(true)
            try {
                const promises = [loadProfile()]
                if (prevId && +prevId) {
                    promises.push(loadPrevProfile())
                } else {
                    setPrevProfile(undefined)
                }
                await Promise.all(promises)
            } finally {
                setLoading(false)
            }
        }
        load()
    }, [id, prevId, uid])

    const action =
        !prevId || prevProfile?.profile_status === 0
            ? EAction.SignUp
            : profile?.user_role !== prevProfile?.user_role
            ? EAction.ChangeRole
            : EAction.Edit

    const showPrevProfile = prevProfile?.profile_status !== 0

    return (
        <div className={style.container}>
            {loading ? (
                <CenteredSpin />
            ) : (
                <>
                    <div className={style.inner}>
                        <div className={style.header}>
                            <div className={style.titleWrapper}>
                                <span
                                    className={style.backLink}
                                    onClick={() => navigate('/questionnaire-approval')}
                                >
                                    <ArrowLeft />
                                </span>
                                <div>
                                    <h1 className={style.title}>
                                        {action === EAction.SignUp
                                            ? 'Согласование Анкеты '
                                            : action === EAction.ChangeRole
                                            ? 'Согласование смены роли '
                                            : action === EAction.Edit
                                            ? 'Согласование редактирования профиля '
                                            : ''}
                                        {profile?.user_type === 0
                                            ? `${profile?.last_name} ${profile?.first_name} ${profile?.middle_name}`
                                            : profile?.company_name}
                                    </h1>
                                </div>
                                {profile?.profile_status ? (
                                    <Tag
                                        outline
                                        transparent
                                        color={
                                            profile?.profile_status === EStatus.Rejected
                                                ? 'red'
                                                : profile?.profile_status === EStatus.Agreed
                                                ? 'blue'
                                                : undefined
                                        }
                                    >
                                        {statusMap.get(profile?.profile_status)}
                                    </Tag>
                                ) : undefined}
                            </div>
                            <IconButtonSmall icon={<BookSaved />} onClick={openHistory}>
                                История изменений
                            </IconButtonSmall>
                        </div>
                        {prevProfile && showPrevProfile && (
                            <Collapse className={style.oldDataWrapper}>
                                <CardPanel header="Старые данные" key="1">
                                    <Questionnaire
                                        config={getConfig(prevProfile, profile)}
                                        className={style.commonInfo}
                                    />
                                    <div className={style.verticalLine} />
                                    <Sign value={prevProfile} />
                                    {prevProfile?.documents && (
                                        <>
                                            <div className={style.verticalLine} />
                                            <DocumentsGrid documents={prevProfile?.documents} />
                                        </>
                                    )}
                                </CardPanel>
                            </Collapse>
                        )}
                        <Card className={style.questionnaireCard}>
                            {(profile?.profile_status as EStatus) === EStatus.Rejected && (
                                <div className={style.statusNotAgreedContainer}>
                                    <div className={style.statusText}>
                                        Данные не приняты. Причина:
                                    </div>
                                    <div className={style.statusReason}>
                                        {profile?.profile_comment}
                                    </div>
                                </div>
                            )}
                            {profile?.profile_status === EStatus.UnderRevision && (
                                <div className={style.statusUnderRevisionContainer}>
                                    <div className={style.statusText}>
                                        Данные отправлены на доработку
                                    </div>
                                    <div className={style.statusReason}>
                                        {profile?.profile_comment}
                                    </div>
                                    {/* <Documents /> */}
                                </div>
                            )}
                            {/* {profile?.profile_status === EStatus.Agreed && (
                                <Alert
                                    className={style.agreedAlert}
                                    message="Согласована 20.04.2022 18:35. Оператор: Константинопольский К.В."
                                    type="info"
                                    showIcon
                                />
                            )} */}

                            <div className={style.newDataTitle}>Новые данные</div>
                            {profile && (
                                <Questionnaire
                                    config={getConfig(profile)}
                                    className={style.commonInfo}
                                />
                            )}
                            <div className={style.verticalLine} />
                            <Sign
                                requestId={profile?.request_id}
                                certificateData={profile?.certificate_data}
                                value={profile}
                            />
                            {profile?.documents && (
                                <>
                                    <div className={style.verticalLine} />
                                    <DocumentsGrid documents={profile?.documents} />
                                </>
                            )}
                            {(profile?.profile_status === EStatus.New ||
                                profile?.profile_status === EStatus.UnderConsideration) && (
                                <>
                                    <div className={style.verticalLine} />
                                    <ButtonContainer profile={profile} action={action} />
                                </>
                            )}
                        </Card>
                    </div>
                    <HistoryModal
                        isModalVisible={isHistoryVisible}
                        onClose={closeHistory}
                        history={history}
                        uid={uid}
                        setIsModalVisible={setIsHistoryVisible}
                    />
                </>
            )}
        </div>
    )
}

export default QuestionnaireApprovalItem
