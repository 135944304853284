import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import IAppState from '../../store/states'
import request from '../../services/request'

const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(''),
    )

    return JSON.parse(jsonPayload)
}

export const isTokenExpired = (token: string) => {
    const tkn = parseJwt(token)
    const now = Math.round(new Date().getTime() / 1000)
    return tkn.exp - now < 0
}

interface IuseWebSocket {
    socketUrl: string
    retry?: number
    retryInterval?: number
}

function useWebSocket({
    socketUrl,
    retry: defaultRetry = 10,
    retryInterval = 1500,
}: IuseWebSocket) {
    const { isAuth } = useSelector((store: IAppState) => store.auth)
    const storage = localStorage.getItem('refreshToken') ? localStorage : sessionStorage
    const accessToken = storage.getItem('accessToken')
    const refreshToken = storage.getItem('refreshToken')

    const [data, setData] = useState()
    const [send, setSend] = useState(() => () => undefined)
    const [retry, setRetry] = useState(defaultRetry)
    const [readyState, setReadyState] = useState(false)

    const [currentAccessToken, setCurrentAccessToken] = useState<string | null>(accessToken)

    const isToken = !!currentAccessToken

    useEffect(() => {
        const refresh = async () => {
            const { data } = await request.post('/api/auth/v1/token', {
                token_type: 'Bearer',
                refresh_token: refreshToken,
            })
            const { access_token } = data
            storage.setItem('accessToken', access_token)
            setCurrentAccessToken(access_token)
        }

        if (isToken) {
            if (isTokenExpired(currentAccessToken)) {
                refresh()
                return
            }

            const ws = new WebSocket(socketUrl)
            ws.onopen = () => {
                console.log('connected to socket')
                setReadyState(true)
                ws.send(JSON.stringify({ authorization: currentAccessToken }))

                // function to send messages
                setSend(() => {
                    return (data) => {
                        try {
                            const d = JSON.stringify(data)
                            ws.send(d)
                            return true
                        } catch (err) {
                            return false
                        }
                    }
                })

                // receive messages
                ws.onmessage = (event) => {
                    const msg = formatMessage(event.data)
                    setData({ message: msg, timestamp: getTimestamp() })
                }
            }

            ws.onerror = (error) => {
                console.log(error)
            }

            // on close we should update connection state
            // and retry connection
            ws.onclose = () => {
                console.log('socket closed')
                setReadyState(false)
                // retry logic
                if (retry > 0) {
                    setTimeout(() => {
                        setRetry((retry) => retry - 1)
                    }, retryInterval)
                }
            }
            // terminate connection on unmount
            return () => {
                ws.close()
            }
            // retry dependency here triggers the connection attempt
        }
    }, [retry, isAuth, currentAccessToken])

    return { send, data, readyState }
}

// small utilities that we need
// handle json messages
const formatMessage = (data) => {
    try {
        const parsed = JSON.parse(data)
        return parsed
    } catch (err) {
        return data
    }
}

// get epoch timestamp
function getTimestamp() {
    return new Date().getTime()
}

export default useWebSocket
