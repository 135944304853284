// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".generatorsMain_g6HQ7 {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 80px 100px 10px;\n  width: 1262px;\n  margin: 0 auto;\n}\n.mainHeader_tQrfR {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.mainWrapper_sfSu2 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n}\n.controlsWrapper_ysnvI {\n  display: flex;\n}\n.tabs_jHuiV {\n  margin-top: 32px;\n  font-weight: 600;\n  color: #595959;\n}\n.contentWrapper_rNdA_ {\n  padding: 24px 24px 16px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.controlsBlock_supz_ {\n  display: flex;\n  gap: 16px;\n}\n.cardsBlock_EGo1Y {\n  margin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/pages/MyGenerators/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,aAAA;EACA,cAAA;AACJ;AAEA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;AAFJ;AAKA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AAHJ;AAMA;EACI,uBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AAJJ;AAOA;EACI,aAAA;EACA,SAAA;AALJ;AAQA;EACI,gBAAA;AANJ","sourcesContent":[".generatorsMain {\n    display: flex;\n    flex-direction: column;\n    padding: 40px 80px 100px 10px;\n    width: 1262px;\n    margin: 0 auto;\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.mainWrapper {\n    display: flex;\n    flex-direction: column;\n    margin-top: 32px;\n}\n\n.controlsWrapper {\n    display: flex;\n}\n\n.tabs {\n    margin-top: 32px;\n    font-weight: 600;\n    color: #595959;\n}\n\n.contentWrapper {\n    padding: 24px 24px 16px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.controlsBlock {\n    display: flex;\n    gap: 16px;\n}\n\n.cardsBlock {\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generatorsMain": "generatorsMain_g6HQ7",
	"mainHeader": "mainHeader_tQrfR",
	"mainWrapper": "mainWrapper_sfSu2",
	"controlsWrapper": "controlsWrapper_ysnvI",
	"tabs": "tabs_jHuiV",
	"contentWrapper": "contentWrapper_rNdA_",
	"controlsBlock": "controlsBlock_supz_",
	"cardsBlock": "cardsBlock_EGo1Y"
};
export default ___CSS_LOADER_EXPORT___;
