import { FC } from 'react'
import { Select } from 'antd'
import { PowerTypeTag } from '../../..'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { DownMini } from '../../../../../assets/icons'
import { tagsList } from './tagsList'
import style from './style.m.less'

interface ITagSelector {
    customStyle?: React.CSSProperties
    onChange?: (value: any) => void
}

const tagRender = (props: CustomTagProps) => {
    const { label, value } = props

    return (
        <PowerTypeTag value={value} customStyle={{ marginRight: 8 }}>
            {label}
        </PowerTypeTag>
    )
}

const TagSelector: FC<ITagSelector> = ({ customStyle, onChange }) => (
    <Select
        mode="multiple"
        showArrow
        suffixIcon={
            <div style={{ marginTop: '4px', marginRight: '3px' }}>
                <DownMini />
            </div>
        }
        style={customStyle}
        tagRender={tagRender}
        defaultValue={['Энергия солнца', 'Энергия ветра']}
        className={style.typeSelector}
        options={tagsList}
        onChange={onChange}
    />
)

export default TagSelector
