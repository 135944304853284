import { IGeneratorsResponse } from './interfaces'

export const initialState = {
    data: {
        limit: 10,
        page: 1,
        sort: 'generating_object_id desc',
        total_rows: 1000,
        total_pages: 100,
        items: [
            {
                id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                sc_id: '1',
                name: 'Солнечная электростанция "Абаканская"',
                type_go_id: 0,
                retail: true,
                code_point_delivery: 'default',
                goid: '1',
                index: 0,
                country: 'РФ',
                region: 'Республика Хакасия',
                city: 'default',
                locality: 'default',
                street: 'default',
                house: 'default',
                flat: 'default',
                building: 'default',
                address_info: 'default',
                latitude: 0,
                longitude: 0,
                green_true: true,
                category_go_id: 0,
                type_renewable_energy_id: 0,
                qualification_category_id: 0,
                degree_localization: 0,
                power: 5.198,
                production_mode_id: 0,
                ownership_id: 0,
                user_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
                qualification_number: 'default',
                qualification_date: '01.20.2022',
                qualification_point: true,
                last_check: '05.04.2022',
                ordering: 'default',
                end_check: '1970-01-01',
                permission_exploitation: '1970-01-01',
                other_marks: 'default',
                is_active: true,
                is_qualified: true,
                generator_year: '1970-01-01',
                generator_date_end: '1970-01-01',
                green_technology_id: 0,
                created_at: '1970-01-01',
                deleted_at: '1970-01-01',
            },
            {
                id: '5axa85f64-4317-4713-wraf-2c9637trafa6',
                sc_id: '2',
                name: 'Ветроэлектрическая станция в Ульяновской Области/SVIE0478',
                type_go_id: 0,
                retail: true,
                code_point_delivery: 'default',
                goid: '2',
                index: 0,
                country: 'Россия',
                region: 'Ульяновская область',
                city: 'default',
                locality: 'default',
                street: 'default',
                house: 'default',
                flat: 'default',
                building: 'default',
                address_info: 'default',
                latitude: 0,
                longitude: 0,
                green_true: true,
                category_go_id: 0,
                type_renewable_energy_id: 0,
                qualification_category_id: 0,
                degree_localization: 0,
                power: 35,
                production_mode_id: 0,
                ownership_id: 0,
                user_id: '8td85f64-3647-4562-2yfc-2c963f66afa6',
                qualification_number: 'default',
                qualification_date: '01.20.2022',
                qualification_point: true,
                last_check: '05.04.2022',
                ordering: 'default',
                end_check: '1970-01-01',
                permission_exploitation: '1970-01-01',
                other_marks: 'default',
                is_active: true,
                is_qualified: true,
                generator_year: '1970-01-01',
                generator_date_end: '1970-01-01',
                green_technology_id: 0,
                created_at: '1970-01-01',
                deleted_at: '1970-01-01',
            },
        ],
    },
} as IGeneratorsResponse
