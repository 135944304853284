import { Clock, TickCircle } from '../../../assets/icons'
import style from './style.m.less'
import { currencyFormat } from '../../../components/helpers'

type Props = {
    title: string
    description: any
}

type TBlock = {
    text: string
}

type ComissionBlockType = {
    is_buyer_tax_payer: boolean
    isSeller: boolean
    isBuyer: boolean
    seller_fee: number
    buyer_fee: number
}

export const VolcanoBlock = ({ text }: TBlock) => (
    <div className={style.volcanoBlock}>
        <Clock />
        <div>{text}</div>
    </div>
)

export const GrennBlock = ({ text }: TBlock) => (
    <div className={style.greenBlock}>
        <TickCircle />
        <div>{text}</div>
    </div>
)

const ObjectBlock = ({ title, description }: Props) => {
    return (
        <div className={style.objectBlock}>
            <div className={style.infoBlock}>{title}</div>
            <div>{description}</div>
        </div>
    )
}

export const ComissionBlock = ({
    buyer_fee,
    seller_fee,
    isBuyer,
    isSeller,
    is_buyer_tax_payer,
}: ComissionBlockType) => {
    let feeSum = 0
    if (!is_buyer_tax_payer && isSeller) {
        feeSum = buyer_fee + seller_fee
    }

    if (is_buyer_tax_payer && isSeller) {
        feeSum = seller_fee
    }

    if (is_buyer_tax_payer && isBuyer) {
        feeSum = buyer_fee
    }

    if (feeSum > 0) {
        return <ObjectBlock title={'Сумма комиссии'} description={`${currencyFormat(feeSum)} Р`} />
    }

    return <></>
}

export default ObjectBlock
