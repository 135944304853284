import { Dispatch, SetStateAction, FC, useState, useEffect } from 'react'
import { Form, Alert } from 'antd'
import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import style from './style.m.less'

interface ITakePaymentBlock {
    config: string
    isSeparate?: boolean
    disabled?: boolean
    onlyOwner?: boolean
    ownerData: {
        has_role_go_owner: boolean
        has_role_client?: boolean
        has_role_tso_gp?: boolean
    }
    setDisableConfirm: Dispatch<SetStateAction<boolean>>
}

const TakePaymentBlock: FC<ITakePaymentBlock> = ({
    config,
    isSeparate,
    disabled,
    onlyOwner,
    ownerData,
    setDisableConfirm,
}) => {
    const [checkedList, setCheckedList] = useState(ownerData)

    const updateCheckedList = (e: CheckboxChangeEvent, name: string) => {
        setCheckedList((prev) => {
            return { ...prev, [name]: e.target.checked }
        })
    }

    const isEmptyRoles = Object.values(checkedList).every((item) => !item)

    useEffect(() => {
        isEmptyRoles ? setDisableConfirm(true) : setDisableConfirm(false)
    }, [isEmptyRoles])

    return (
        <>
            <div className={isSeparate ? style.settingBlock : undefined}>
                <div>
                    Взымать оплату с роли <span className={style.star}>*</span>
                </div>
                <div className={style.settingRowNoMarginGap24}>
                    {!onlyOwner && (
                        <div className={style.settingRowCheckboxFirst}>
                            <Form.Item name={`${config}-has_role_client`} valuePropName="checked">
                                <Checkbox
                                    disabled={disabled}
                                    onChange={(e) => {
                                        updateCheckedList(e, 'has_role_client')
                                    }}
                                />
                            </Form.Item>
                            <div className={style.checkboxText}>Клиент</div>
                        </div>
                    )}
                    <div className={style.settingRowCheckboxFirst}>
                        <Form.Item name={`${config}-has_role_go_owner`} valuePropName="checked">
                            <Checkbox
                                disabled={disabled || onlyOwner}
                                onChange={(e) => {
                                    updateCheckedList(e, 'has_role_go_owner')
                                }}
                            />
                        </Form.Item>
                        <div className={style.checkboxText}>Владелец ЗГО</div>
                    </div>
                    {!onlyOwner && (
                        <div className={style.settingRowCheckboxFirst}>
                            <Form.Item name={`${config}-has_role_tso_gp`} valuePropName="checked">
                                <Checkbox
                                    disabled={disabled}
                                    onChange={(e) => {
                                        updateCheckedList(e, 'has_role_tso_gp')
                                    }}
                                />
                            </Form.Item>
                            <div className={style.checkboxText}>ТСО/ГП</div>
                        </div>
                    )}
                </div>
            </div>
            {isEmptyRoles && (
                <Alert className={style.alert} message="Укажите роль" type="error" showIcon />
            )}
        </>
    )
}

export default TakePaymentBlock
