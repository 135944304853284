// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editMain_JUWZj {\n  padding: 40px 80px 100px 80px;\n  width: 1172px;\n}\n.editFrame_vrTG1 {\n  margin-top: 32px;\n  padding: 40px 32px 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EditMyProfile/components/AddDocuments/style.m.less"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,aAAA;AACJ;AAEA;EACI,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AAAJ","sourcesContent":[".editMain {\n    padding: 40px 80px 100px 80px;\n    width: 1172px;\n}\n\n.editFrame {\n    margin-top: 32px;\n    padding: 40px 32px 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editMain": "editMain_JUWZj",
	"editFrame": "editFrame_vrTG1"
};
export default ___CSS_LOADER_EXPORT___;
