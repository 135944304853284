import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment/moment'
import debounce from 'lodash/debounce'
import useMount from '../hooks/useMount'
import { HeaderBlock, Pagination, CenteredSpin, OperationHistoryMapper } from '../ui'
import { getCertTypes } from '../../services/dictionaries'
import OperationFilters from '../../components/Profile/pages/AllOperations/OperationFilters'
import { EOperationTypeID } from '../../components/Profile/pages/AllOperations/configurations'
import { EHistoryType, IOperationConfig } from '../../components/ui/OperationHistoryMapper/types'
import { getCertHistory } from '../../services/audit'
import style from './style.m.less'

interface ILoadCertHistory {
    id: string
    page: number
    pageSize: number
    eventDateFilter: [moment.Moment, moment.Moment] | null
    typeEventIDsFilter: number[]
    volumeMinFilter: number | null
    volumeMaxFilter: number | null
    operationTypeIDFilter: EOperationTypeID | undefined
}

const paidOnly = false
const PAGE_SIZE = 10
const debounceWaitMs = 300

const UserCertificateHistoryOperations: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string | undefined }>()

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const [historyLoading, setHistoryLoading] = useState(false)
    const [history, setHistory] = useState<IOperationConfig[]>([])

    const [typeEventIDs, setTypeEventIDs] = useState({
        options: [],
        dictionary: {},
    })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [total, setTotal] = useState(0)
    const [eventDateFilter, setEventDateFilter] = useState<[moment.Moment, moment.Moment] | null>(
        null,
    )
    const [typeEventIDsFilter, setTypeEventIDsFilter] = useState<number[]>([])
    const [volumeMinFilter, setVolumeMinFilter] = useState(0)
    const [volumeMaxFilter, setVolumeMaxFilter] = useState(0)
    const [operationTypeIDFilter, setOperationTypeIDFilter] = useState<
        EOperationTypeID | undefined
    >(EOperationTypeID.OpTypeAll)

    const getDict = async () => {
        const typeEventsResponse = await getCertTypes()
        const eventOptions = typeEventsResponse?.data?.map((item) => {
            return {
                id: item.id,
                value: item.id,
                text: item.name,
            }
        })

        setTypeEventIDs({
            dictionary: eventOptions?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.text })) ?? {},
            options: [...eventOptions],
        })
    }

    useMount(() => {
        getDict()
    })

    const today = moment().startOf(dayUnit)

    const loadCertHistory = useCallback(
        async ({
            id,
            page,
            pageSize,
            eventDateFilter,
            typeEventIDsFilter,
            volumeMinFilter,
            volumeMaxFilter,
            operationTypeIDFilter,
        }: ILoadCertHistory) => {
            try {
                setHistoryLoading(true)
                const historyResponse = await getCertHistory(id, pageSize, (page - 1) * pageSize, {
                    event_date_from: eventDateFilter
                        ? moment(eventDateFilter[0]).startOf(dayUnit)
                        : null,
                    event_date_to: eventDateFilter
                        ? moment(eventDateFilter[1]).endOf(dayUnit)
                        : null,
                    type_event_ids: typeEventIDsFilter.length > 0 ? typeEventIDsFilter : null,
                    volume_min: volumeMinFilter,
                    volume_max: volumeMaxFilter,
                    operation_type_id: operationTypeIDFilter,
                    paid_only: paidOnly,
                })

                const resp: {
                    events: []
                    total: number
                } = historyResponse.data

                setHistory(
                    resp?.events?.map((phe: { date: string; operations: any[] }) => {
                        const eventDay = moment(phe.date, shortFormat)

                        let title = eventDay.format(extraFormat)

                        if (eventDay.isSame(today)) {
                            title = `${t('history.today')}, ${title}`
                        } else if (eventDay.isSame(today.subtract(1, dayUnit))) {
                            title = `${t('history.yesterday')}, ${title}`
                        }

                        return {
                            flexStart: false,
                            iconMargin: false,
                            date: title,
                            operations: phe.operations,
                        }
                    }),
                )

                setTotal(resp?.total)
            } finally {
                setHistoryLoading(false)
            }
        },
        [],
    )

    const loadHistoryDebounced = useCallback(debounce(loadCertHistory, debounceWaitMs), [])

    useEffect(() => {
        loadHistoryDebounced({
            id,
            page,
            pageSize,
            eventDateFilter,
            typeEventIDsFilter,
            volumeMinFilter,
            volumeMaxFilter,
            operationTypeIDFilter,
        })
    }, [
        id,
        page,
        pageSize,
        eventDateFilter,
        typeEventIDsFilter,
        volumeMinFilter,
        volumeMaxFilter,
        operationTypeIDFilter,
    ])

    return (
        <div className={style.certificateMain}>
            <div className={style.editMain}>
                <div className={style.headerWrapper}>
                    <HeaderBlock label={t('myProfile.allOperations')} />
                </div>
                {historyLoading && !history && <CenteredSpin />}
                <div className={style.editFrame}>
                    <div className={style.controlsBlock}>
                        <OperationFilters
                            settings={{ historyType: EHistoryType.Certs }}
                            typeEventIDsOptions={typeEventIDs.options}
                            typeEventIDsFilter={typeEventIDsFilter}
                            onChangeTypeEventIDs={(values) => {
                                setTypeEventIDsFilter(values)
                                setOperationTypeIDFilter(undefined)
                            }}
                            onChangeAmountMin={(value) => {
                                setPage(1)
                                setVolumeMinFilter(value)
                            }}
                            onChangeAmountMax={(value) => {
                                setPage(1)
                                setVolumeMaxFilter(value)
                            }}
                            onChangeOperationTypeID={(value) => {
                                setPage(1)
                                setOperationTypeIDFilter(value)
                            }}
                            onChangeDate={(range) => {
                                setPage(1)
                                setEventDateFilter(range)
                            }}
                        />
                    </div>
                    <OperationHistoryMapper
                        settings={{ historyType: EHistoryType.Certs }}
                        config={history}
                    />
                    <Pagination
                        showSizeChanger
                        customStyle={{ padding: 0, marginTop: '32px', boxShadow: 'none' }}
                        current={page}
                        paginationChange={(receivedPage: number, receivedPageSize?: number) => {
                            setPage(receivedPage)
                            setPageSize(receivedPageSize ?? PAGE_SIZE)
                        }}
                        pageSize={pageSize}
                        total={total}
                    />
                </div>
            </div>
        </div>
    )
}

export default UserCertificateHistoryOperations
