import { FC, useState } from 'react'
import { ButtonsBlock } from '../../../../Edit/components'
import { Modal, DocumentsBlock, Box } from '../../..'
import { Alert, Input, Checkbox, Divider, message } from 'antd'
import style from './style.m.less'
import { useNavigate } from 'react-router-dom'
import { blockCertificate, unblockCertificate } from './config'
import useRequest from '../../../../hooks/useRequest'

const { TextArea } = Input

interface IBlockModal {
    modalStatus: { visible: boolean; isUnblock: boolean }
    setModalStatus: any
    certificateId?: string
}

const BlockModal: FC<IBlockModal> = ({ modalStatus, setModalStatus, certificateId }) => {
    const navigate = useNavigate()
    const { isUnblock } = modalStatus
    const { fetch: fetchBlock } = useRequest(blockCertificate)
    const { fetch: fetchUnblock } = useRequest(unblockCertificate)

    const [deposit, setDeposit] = useState(false)
    const [files, setFiles] = useState<any[]>([])
    const [reason, setReason] = useState('')

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const handleCancel = () => {
        setModalStatus({ visible: false, isUnblock: modalStatus.isUnblock })
    }

    const onChange = (e: any) => {
        setDeposit(e.target.checked)
    }

    const handleConfirm = async () => {
        if (!reason && !isUnblock) {
            message.error('Укажите основание')
        } else {
            const body = isUnblock
                ? {
                      certificate_id: certificateId,
                      reason,
                      documents: files,
                  }
                : {
                      certificate_id: certificateId,
                      is_deposit: deposit,
                      reason,
                      documents: files,
                  }

            if (isUnblock) {
                fetchUnblock({
                    body,
                    successMessage: {
                        title: 'Успешно',
                        description: `Сертификат успешно ${
                            isUnblock ? 'разблокирован' : 'заблокирован'
                        }`,
                    },
                    onSuccess: () => navigate('/green-tools'),
                    errorCodeMessage: true,
                })
            } else {
                fetchBlock({
                    body,
                    successMessage: {
                        title: 'Успешно',
                        description: `Сертификат успешно ${
                            isUnblock ? 'разблокирован' : 'заблокирован'
                        }`,
                    },
                    onSuccess: () => navigate('/green-tools'),
                    errorCodeMessage: true,
                })
            }
        }
    }

    return (
        <Modal isModalVisible={modalStatus.visible}>
            <div className={style.mainWrapper}>
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>
                        {isUnblock ? 'Снятие блокировки' : 'Блокировка сертификата происхождения'}
                    </div>
                    <Alert
                        className={style.modalAlert}
                        message={
                            isUnblock
                                ? 'Укажите основание для снятия блокировки, если это необходимо'
                                : 'Укажите основание для блокировки. Пользователь увидит его в уведомлении о блокировке'
                        }
                        type="info"
                        showIcon
                    />
                    <Box style={{ marginTop: '24px' }}>
                        <Box direction="row">
                            <div>Основание</div>
                            {!isUnblock && <div className={style.star}>*</div>}
                        </Box>
                        <TextArea
                            placeholder="Ваш комментарий"
                            className={style.textArea}
                            rows={4}
                            onChange={(event) => setReason(event.target.value)}
                        />
                    </Box>
                    {!isUnblock && (
                        <Checkbox onChange={onChange} className={style.checkbox} checked={deposit}>
                            Залог
                        </Checkbox>
                    )}
                    <Divider />
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={4}
                        description="Загрузите необходимые документы"
                    />
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            isDelete={!isUnblock}
                            confirmAction={handleConfirm}
                            cancelAction={handleCancel}
                            confirmTitle={isUnblock ? 'Разблокировать' : 'Заблокировать'}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BlockModal
