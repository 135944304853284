import { FC, useState } from 'react'
import { Alert, Input, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../../ui'
import { ButtonsBlock } from '../../../../Edit/components'
import useRequest from '../../../../hooks/useRequest'
import { postApprove } from '../../../config'
import style from './style.m.less'

const { TextArea } = Input

interface IRefuseModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    currentDate: string
    generatorName: string
    id?: string
}

const RefuseModal: FC<IRefuseModal> = ({
    isModalVisible,
    setIsModalVisible,
    currentDate,
    id,
    generatorName,
}) => {
    const navigate = useNavigate()
    const { fetch } = useRequest(postApprove)

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const onFinish = ({ reason }: { reason: string }) => {
        fetch({
            body: {
                fuel_application_id: Number(id),
                approve_type: 2,
                fuel_application_comment: reason,
            },
            successMessage: {
                title: 'Отказ отправлен',
                description: `Данные по топливу за ${currentDate} по ЗГО ${generatorName} отклонены, комментарий направлен пользователю`,
            },
            errorCodeMessage: true,
            onSuccess: () => navigate('/fuel-approval'),
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <Form onFinish={onFinish}>
                <div className={style.modalWrapper}>
                    <div className={style.header}>Отказ в согласовании данных</div>
                </div>
                <div className={style.text}>
                    <div>Причина отказа</div>
                    <Form.Item
                        name="reason"
                        rules={[
                            { required: true, message: 'Поле должно быть заполнено' },
                            {
                                max: 500,
                                message: 'Укажите комментарий длиной не больше 500 символов',
                            },
                        ]}
                    >
                        <TextArea
                            className={style.textArea}
                            rows={4}
                            placeholder="Ваш комментарий"
                        />
                    </Form.Item>
                </div>
                <Alert
                    className={style.modalAlert}
                    message="Указанный комментарий увидит пользователь"
                    type="warning"
                    showIcon
                />
                <div className={style.buttonsBlock}>
                    <ButtonsBlock cancelAction={handleOk} confirmTitle="Отправить" />
                </div>
            </Form>
        </Modal>
    )
}

export default RefuseModal
