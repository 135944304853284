// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".greenToolsMain_c5O1K {\n  display: flex;\n  flex-direction: column;\n  width: 1310px;\n  margin: 0 auto;\n  padding: 40px 15px 100px;\n}\n.mainHeader_QhTl8 {\n  display: flex;\n  justify-content: space-between;\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GreenTools/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,cAAA;EACA,wBAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ","sourcesContent":[".greenToolsMain {\n    display: flex;\n    flex-direction: column;\n    width: 1310px;\n    margin: 0 auto;\n    padding: 40px 15px 100px;\n}\n\n.mainHeader {\n    display: flex;\n    justify-content: space-between;\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greenToolsMain": "greenToolsMain_c5O1K",
	"mainHeader": "mainHeader_QhTl8"
};
export default ___CSS_LOADER_EXPORT___;
