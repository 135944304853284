// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonContainer_zdpBa {\n  display: flex;\n  gap: 16px;\n}\n.buttonContainerCancel_JXts7 {\n  margin-left: auto;\n  border: 1px solid #FF7875;\n  border-radius: 8px;\n  color: #FF4D4F;\n}\n.buttonContainerCancel_JXts7:hover,\n.buttonContainerCancel_JXts7:focus {\n  border: 1px solid #FF7875;\n  color: #FF4D4F;\n}\n.buttonContainerApprove__RTLt {\n  background: #E6F1FF;\n  border-radius: 8px;\n  color: #296DFF;\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/QuestionnaireApprovalItem/components/ButtonContainer/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ;AAEA;EACI,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,cAAA;AAAJ;AACI;;EACI,yBAAA;EACA,cAAA;AAER;AAEA;EACI,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AAAJ","sourcesContent":[".buttonContainer {\n    display: flex;\n    gap: 16px;\n}\n\n.buttonContainerCancel {\n    margin-left: auto;\n    border: 1px solid #FF7875;\n    border-radius: 8px;\n    color: #FF4D4F;\n    &:hover, &:focus {\n        border: 1px solid #FF7875;\n        color: #FF4D4F;\n    }\n}\n\n.buttonContainerApprove {\n    background: #E6F1FF;\n    border-radius: 8px;\n    color: #296DFF;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "buttonContainer_zdpBa",
	"buttonContainerCancel": "buttonContainerCancel_JXts7",
	"buttonContainerApprove": "buttonContainerApprove__RTLt"
};
export default ___CSS_LOADER_EXPORT___;
