import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import style from './style.m.less'

const routes = [
    { id: 1, to: 'main', title: 'header.main' },
    { id: 2, to: 'documents', title: 'header.documents' },
    { id: 3, to: 'green-tools', title: 'header.greenTools' },
    { id: 4, to: 'generators', title: 'header.generators' },
    { id: 5, to: 'owners', title: 'header.owners' },
    { id: 6, to: 'news', title: 'header.news' },
]

const FooterNav: FC = () => {
    const { t } = useTranslation()

    const navList = routes.map((route) => {
        const { id, to, title } = route
        return (
            <NavLink className={style.navItem} key={id} to={to}>
                {t(title)}
            </NavLink>
        )
    })

    return (
        <div className={style.footerNav}>
            <div className={style.footerHeader}>{t('footer.nav')}</div>
            <div className={style.navList}>{navList}</div>
        </div>
    )
}

export default FooterNav
