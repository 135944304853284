// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".titleWrapper_i9zFY {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  width: 375px;\n}\n.icon_gvcgr {\n  font-size: 24px;\n  width: 44px;\n  height: 44px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 12px;\n}\n.title_eKqgF {\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 26px;\n  color: #434343;\n  margin: 0;\n}\n.text_oPWzQ {\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: #434343;\n  margin: 12px 0 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Main/components/TextCard/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAEA;EACI,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,SAAA;AADJ;AAIA;EACI,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAFJ","sourcesContent":[".titleWrapper {\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    width: 375px;\n}\n\n.icon {\n    font-size: 24px;\n    width: 44px;\n    height: 44px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 12px;\n}\n\n.title {\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 26px;\n    color: #434343;\n    margin: 0;\n}\n\n.text {\n    font-family: 'SF Pro Display', sans-serif;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n    color: #434343;\n    margin: 12px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": "titleWrapper_i9zFY",
	"icon": "icon_gvcgr",
	"title": "title_eKqgF",
	"text": "text_oPWzQ"
};
export default ___CSS_LOADER_EXPORT___;
