import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const PdfIcon: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="21"
                height="28"
                viewBox="0 0 21 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.6 0H1.4C0.626801 0 0 0.626801 0 1.4V26.6C0 27.3732 0.626801 28 1.4 28H19.6C20.3732 28 21 27.3732 21 26.6V1.4C21 0.626801 20.3732 0 19.6 0Z"
                    fill="#FF7875"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.35 3C16.2433 3 16.1567 3.03 16.09 3.09C16.03 3.14333 16 3.21333 16 3.3V4H17V5H16V8H17V9H14V8H15V5H14V4H15V3.1C15 2.76 15.0967 2.49333 15.29 2.3C15.4833 2.1 15.7333 2 16.04 2H17.5V3L16.35 3ZM4.8 4V4.24C5.00667 4.08 5.27333 4 5.6 4H6.3C6.88667 4 7.31667 4.12667 7.59 4.38C7.86333 4.62667 8 5.03333 8 5.6V7.4C8 7.96 7.86 8.36667 7.58 8.62C7.30667 8.87333 6.88 9 6.3 9H5.01V11H4V4H4.8ZM5.65 5C5.43 5 5.26667 5.06 5.16 5.18C5.05333 5.29333 5 5.41667 5 5.55L5.01 8H6.25C6.49667 8 6.68333 7.94333 6.81 7.83C6.93667 7.71667 7 7.55667 7 7.35V5.65C7 5.44333 6.93667 5.28333 6.81 5.17C6.68333 5.05667 6.49667 5 6.25 5H5.65ZM11.2 9C11.5133 9 11.78 8.92333 12 8.77V9H13V2H12V4H10.76C10.18 4 9.69 4.12667 9.41 4.38C9.13667 4.63333 9 5.04 9 5.6V7.4C9 7.96667 9.13667 8.37667 9.41 8.63C9.68333 8.87667 10.1133 9 10.7 9H11.2ZM11.84 7.83C11.7333 7.94333 11.57 8 11.35 8H10.75C10.5033 8 10.3167 7.94333 10.19 7.83C10.0633 7.71667 10 7.55667 10 7.35V5.65C10 5.44333 10.0633 5.28333 10.19 5.17C10.3167 5.05667 10.5033 5 10.75 5H12V7.45C12 7.58333 11.9467 7.71 11.84 7.83Z"
                    fill="white"
                />
                <path
                    opacity="0.7"
                    d="M11.0344 13.1466C11.3264 13.2794 11.2657 13.4514 11.1759 14.264C11.0841 15.1021 10.7766 16.6332 10.1842 18.1393C9.59119 19.643 8.71132 21.1205 7.94449 22.2141C7.17832 23.3083 6.52261 24.0193 6.02259 24.43C5.52064 24.8407 5.17373 24.9503 4.94698 24.9865C4.72216 25.0226 4.62009 24.9865 4.55937 24.8762C4.49864 24.7665 4.47732 24.5858 4.52965 24.3652C4.58068 24.1471 4.70278 23.8916 5.04905 23.5644C5.39854 23.236 5.97027 22.8341 6.7681 22.4609C7.56593 22.087 8.58793 21.7405 9.4271 21.5031C10.2663 21.2669 10.92 21.1385 11.4924 21.0382C12.0661 20.9373 12.5564 20.8656 13.037 20.8282C13.5177 20.7933 13.9893 20.7933 14.4492 20.8282C14.9092 20.8656 15.3588 20.9373 15.7484 21.0295C16.136 21.1205 16.4642 21.2295 16.7504 21.404C17.0359 21.576 17.282 21.8134 17.4041 22.0789C17.5282 22.3425 17.5282 22.6353 17.4254 22.8441C17.3234 23.0541 17.1179 23.1812 16.8834 23.2554C16.6483 23.327 16.3828 23.3451 16.0649 23.2554C15.7484 23.1631 15.3789 22.9625 14.9912 22.6983C14.603 22.4334 14.1934 22.105 13.6624 21.5947C13.1301 21.0843 12.475 20.392 11.9233 19.7171C11.3716 19.0423 10.92 18.3861 10.6132 17.8296C10.3076 17.2731 10.1435 16.8176 10.0105 16.3621C9.87738 15.9053 9.77531 15.4498 9.73461 15.0398C9.69391 14.6297 9.71264 14.264 9.75399 13.9717C9.79469 13.6813 9.85735 13.4607 9.95878 13.3143C10.0608 13.1709 10.2049 13.0962 10.307 13.06C10.409 13.0239 10.4711 13.0239 10.5318 13.0145C10.5925 13.0052 10.6532 12.9871 10.7469 13.0145C10.8361 13.0407 10.9543 13.1105 11.0725 13.1815"
                    stroke="white"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
