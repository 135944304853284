// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".typeSelector_nQXhD {\n  width: 100%;\n  font-weight: normal;\n}\n.typeTag_WJwVc {\n  border: 0;\n  border-radius: 12px;\n  height: 22px;\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Owners/components/TagSelector/style.m.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;AACJ;AAEA;EACI,SAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AAAJ","sourcesContent":[".typeSelector {\n    width: 100%;\n    font-weight: normal;\n}\n\n.typeTag {\n    border: 0;\n    border-radius: 12px;\n    height: 22px;\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeSelector": "typeSelector_nQXhD",
	"typeTag": "typeTag_WJwVc"
};
export default ___CSS_LOADER_EXPORT___;
