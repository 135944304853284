import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Import,
    Calendar,
    User,
    TickSquare,
    EmptyWallet,
    DoubleSave,
    Stamp,
} from '../../assets/icons'
import {
    HeaderBlock,
    TimeRangePicker,
    DropdownSelector,
    ImportButton,
    OperationsMapper,
    Pagination,
} from '../ui'
import moment from 'moment'
import { Alert, Divider, Progress } from 'antd'
import { useCertificateData } from '../hooks/useCertificateData'
import { repaymentData } from './repaymentData'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { historyConfig } from './configurations'
import { DownloadOutlined } from '@ant-design/icons'

const UserHistoryOperations: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.certificateMain}>
            <div className={style.editMain}>
                <HeaderBlock label={'История операций'} />
                <div className={style.editFrame}>
                    {historyConfig.map((item) => (
                        <div>
                            <div className={style.date}>
                                <div className={style.dateLabel}>
                                    <div>{item.day}</div>
                                    <div className={style.current}>{item.weekday}</div>
                                </div>
                                <Divider
                                    style={{ minWidth: 'auto', margin: '12px 0', width: '80%' }}
                                />
                            </div>
                            {item.operations.map((operationItem) => (
                                <div className={style.operationMain}>
                                    <div className={style.operationRow}>
                                        <div className={style.iconBlockBlue}>
                                            <DoubleSave size={15} />
                                        </div>
                                        <div className={style.infoBlock}>
                                            <div className={style.label}>{operationItem.type}</div>
                                            <div className={style.time}>
                                                {moment(operationItem.time).format('HH:mm')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.stampBlock}>
                                        <Stamp />
                                        <div className={style.stampOwner}>
                                            {operationItem.value}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default UserHistoryOperations
