import { FC, useEffect, useState } from 'react'
import { Progress, Spin, Tooltip } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import useRequest from '../../../hooks/useRequest'
import { useParams } from 'react-router-dom'
import { currencyFormat } from '../../../helpers'
import { getGoOwnerStatistics, getRenewableEnergyTypesDictionaries } from '../../config'
import { colorPickerForStatistics } from '../../../helpers'
import round from 'lodash/round'

interface energyTypeStat {
    id: string
}

const Statistics: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<{ id?: string }>()
    const [dictType, setDictType] = useState({})

    const {
        fetch: getDict,
        result: responseDict,
        isLoading: isLoadingDict,
    } = useRequest(getRenewableEnergyTypesDictionaries)

    useEffect(() => {
        getDict({
            body: {},
            errorCodeMessage: true,
        })
    }, [id])

    useEffect(() => {
        setDictType(setDict(responseDict))
    }, [responseDict])

    const {
        fetch: getOwnerStat,
        result: responseOwnerStat,
        isLoading: isLoadingOwnerStat,
    } = useRequest(getGoOwnerStatistics)

    useEffect(() => {
        getOwnerStat({
            addToLink: `?id=${id}`,
            body: {},
            errorCodeMessage: true,
        })
    }, [id])

    const setDict = (dict: any[]) => {
        const res = {};
        (dict || []).forEach((el: energyTypeStat) => {
            res[el.id] = el
        })
        return res
    }

    return (
        <div className={style.statisticsMain}>
            <div className={style.headerPrimary}>{t('ownerCard.statsAbout')}</div>
            <Spin spinning={isLoadingDict || isLoadingOwnerStat}>
                <div className={style.contentWrapper}>
                    <div className={style.progressWrapper}>
                        {dictType[1] &&
                            (responseOwnerStat?.renewable_energy_types || [])
                            .sort((a: any, b: any) => b.go_amount - a.go_amount)
                            .map((item: any, ind: number) => {
                                    return (
                                        <div className={style.progressBlock} key={ind}>
                                            <div className={style.progressType}>
                                                {dictType[item.renewable_energy_type_id].short_name}
                                            </div>
                                            <div className={style.progressQuantity}>{`${
                                                item.go_amount
                                            }  ${t('ownerCard.numberOfSites')}`}</div>
                                            <Tooltip title={round((item.go_amount /
                                                responseOwnerStat?.total_gos) *
                                                100, 1) + '%'}
                                            >
                                                <Progress
                                                    className={style.progressBar}
                                                    percent={
                                                        (item.go_amount /
                                                        responseOwnerStat?.total_gos) *
                                                        100
                                                    }
                                                    showInfo={false}
                                                    strokeColor={colorPickerForStatistics(dictType[item.renewable_energy_type_id].short_name as string)}
                                                />
                                            </Tooltip>
                                        </div>
                                    )
                                })}
                    </div>
                    <div className={style.totalInfoWrapper}>
                        <div className={style.totalInfoBlock}>
                            <div>Суммарная установленная мощность</div>
                            <div className={style.totalValue}>
                                {currencyFormat(responseOwnerStat?.total_power && (responseOwnerStat?.total_power / 1000) || '')}{' '}
                                <span className={style.units}>МВт</span>
                            </div>
                        </div>
                        <div className={style.totalInfoBlock}>
                            <div>Всего генерирующих объектов</div>
                            <div className={style.totalValue}>
                                {responseOwnerStat?.total_gos || ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default Statistics
