// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".historyAlert_GKDdt {\n  width: max-content;\n  margin-top: 24px;\n  background: #e6f7ff;\n  border: 1px solid #bae7ff;\n  border-radius: 12px;\n}\n.error_MaseJ {\n  margin-top: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.text_L3dSi {\n  color: #bfbfbf;\n  font-weight: 400;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/OperationHistoryMapper/style.m.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AAGA;EACI,cAAA;EACA,gBAAA;AADJ","sourcesContent":[".historyAlert {\n    width: max-content;\n    margin-top: 24px;\n    background: #e6f7ff;\n    border: 1px solid #bae7ff;\n    border-radius: 12px;\n}\n\n.error {\n    margin-top: 24px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.text {\n    color: #bfbfbf;\n    font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"historyAlert": "historyAlert_GKDdt",
	"error": "error_MaseJ",
	"text": "text_L3dSi"
};
export default ___CSS_LOADER_EXPORT___;
