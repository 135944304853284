import { message } from 'antd'
import { ChangeEvent, useCallback, useState } from 'react'
import SignUtils from '../../utils/sign/SignUtils'
import request from '../../services/request'

const MAX_FILES_SIZE = 52428800

const showError = () => {
    message.error('Суммарный объем прикрепленных файлов не должен превышать размер 50 Мб.')
}

const useUploadFile = (
    serviceId: number,
    is_public: number | undefined,
    maxFiles: number | undefined,
) => {
    const [files, setFiles] = useState<any[]>([])
    const [uploading, setUploading] = useState<boolean>(false)

    const addFile = (newFile: any) => {
        if (maxFiles) {
            setFiles((arr) => {
                const newArr = [newFile, ...arr]
                newArr.length = maxFiles
                return newArr
            })
        } else {
            setFiles((arr) => [...arr, newFile])
        }
    }

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const totalSelectedFilesSize =
                files.reduce((sizeSum, item) => sizeSum + item.size, 0) + file.size
            if (totalSelectedFilesSize > MAX_FILES_SIZE) {
                showError()
                return
            }

            setUploading(true)

            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = async () => {
                try {
                    const { data } = await request.post(
                        `/api/documents/v1/file${is_public ? `?is_public=${is_public}` : ''}`,
                        fileReader.result,
                        {
                            headers: {
                                Filename: encodeURI(file.name),
                                'Service-ID': serviceId,
                            },
                        },
                    )

                    const hash = await SignUtils.hashBytes(fileReader.result)
                    addFile({
                        name: file.name,
                        size: file.size,
                        id: data.id,
                        type: file.name.split('.').pop(),
                        description: '',
                        hash: hash,
                    })
                } catch (err) {
                    console.log('Error: ', err)
                } finally {
                    setUploading(false)
                }
            }
            fileReader.onerror = () => {
                setUploading(false)
            }
        }
    }

    const uploadFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        files,
        uploading,
        uploadFile,
        setFiles,
    }
}

export default useUploadFile
