import { createAction, createSelector, createSlice } from '@reduxjs/toolkit'
import IAppState from '../states'
import { IDictionariesResponse, ELoading } from './interfaces'

const initialState = { loading: ELoading.None } as IDictionariesResponse

const dictionaries = createSlice({
    name: 'dictionaries',
    initialState,
    reducers: {
        updateDictionaries: (state, action) => {
            return { ...state, ...action.payload }
        },
    },
})

export const getDictionaries = createAction('dictionaries/getDictionaries')

export const { updateDictionaries } = dictionaries.actions

export const selectDictionariesOptions = createSelector(
    (state: IAppState) => state.dictionaries,
    ({ data }) => {
        if (!data) {
            return null
        }
        return {
            regionsOptions: data.regions.map((r) => ({
                id: r.id,
                value: r.id,
                text: r.name,
                label: r.name,
            })),
            renewableEnergyTypesOptions: data.renewableEnergyTypes.map((r) => ({
                id: r.id,
                value: r.id,
                text: r.short_name,
                label: r.short_name,
            })),
            fullRenewableEnergyTypesOptions: data.renewableEnergyTypes.map((r) => ({
                id: r.id,
                value: r.id,
                text: r.full_name,
                label: r.full_name,
            })),
            fullGenCodeOptions: data.renewableEnergyTypes.map((r) => ({
                id: r.id,
                value: r.id,
                text: r.full_gen_code,
                label: r.full_gen_code,
            })),
            qualificationCategoriesOptions: data.qualificationCategories.map((q) => ({
                id: q.id,
                value: q.id,
                text: q.name,
                label: q.name,
            })),
            categoriesGoOptions: data.categoriesGo.map((c) => ({
                id: c.id,
                value: c.id,
                text: c.short_name,
                label: c.short_name,
            })),
            productionModesOptions: data.productionModes.map((p) => ({
                id: p.id,
                value: p.id,
                text: p.name,
                label: p.name,
            })),
            ownershipsOptions: data.ownerships.map((o) => ({
                id: o.id,
                value: o.id,
                text: o.name,
                label: o.name,
            })),
            aidsOptions: data.aids.map((a) => ({
                id: a.id,
                value: a.id,
                text: a.name,
                label: a.name,
            })),
            fuelOptions: data.fuel
                .filter((f) => f.is_for_renewable_energy)
                .map((f) => ({
                    id: f.id,
                    value: f.id,
                    text: f.is_for_renewable_energy ? f.short_name : f.name,
                    label: f.is_for_renewable_energy ? f.short_name : f.name,
                    thermal_conductivity: f.thermal_conductivity,
                    unit: f.unit,
                })),
            nonrenewableFuelOptions: data.fuel
                .filter((f) => !f.is_for_renewable_energy)
                .map((f) => ({
                    id: f.id,
                    value: f.id,
                    text: f.is_for_renewable_energy ? f.short_name : f.name,
                    label: f.is_for_renewable_energy ? f.short_name : f.name,
                    thermal_conductivity: f.thermal_conductivity,
                    unit: f.unit,
                })),
            fullFuelOptions: data.fuel.map((f) => ({
                id: f.id,
                value: f.id,
                text: f.is_for_renewable_energy ? f.short_name : f.name,
                label: f.is_for_renewable_energy ? f.short_name : f.name,
                thermal_conductivity: f.thermal_conductivity,
                unit: f.unit,
            })),
        }
    },
)

export const selectDictionariesMaps = createSelector(
    (state: IAppState) => state.dictionaries,
    ({ data }) => {
        return {
            regionsMap: new Map(data?.regions.map((r) => [r.id, r.name])),
            renewableEnergyTypesMap: new Map(
                data?.renewableEnergyTypes.map((r) => [r.id, r.short_name]),
            ),
            fullRenewableEnergyTypesMap: new Map(
                data?.renewableEnergyTypes.map((r) => [r.id, r.full_name]),
            ),
            fullGenCodeOptions: new Map(
                data?.renewableEnergyTypes.map((r) => [r.id, r.full_gen_code]),
            ),
            attributeTypesMap: new Map(data?.renewableEnergyTypes.map((r) => [r.id, r.full_name])),
            qualificationCategoriesMap: new Map(
                data?.qualificationCategories.map((q) => [q.id, q.name]),
            ),
            categoriesGoMap: new Map(data?.categoriesGo.map((c) => [c.id, c.short_name])),
            productionModesMap: new Map(data?.productionModes.map((p) => [p.id, p.name])),
            ownershipsMap: new Map(data?.ownerships.map((o) => [o.id, o.name])),
            aidsMap: new Map(data?.aids.map((a) => [a.id, a.name])),
            fuelMap: new Map(
                data?.fuel.map((f) => [f.id, f.is_for_renewable_energy ? f.short_name : f.name]),
            ),
        }
    },
)

export default dictionaries.reducer
