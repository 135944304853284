import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../../ui'
import { ButtonsBlock } from '../../../../Edit/components'
import useRequest from '../../../../hooks/useRequest'
import { postApprove } from '../../../config'

import style from './style.m.less'
import { QuestionCircle } from '../../../../../assets/icons'

interface IApproveModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    currentDate: string
    id?: string
}

const ApproveModal: FC<IApproveModal> = ({
    isModalVisible,
    setIsModalVisible,
    currentDate,
    id,
}) => {
    const navigate = useNavigate()
    const { fetch } = useRequest(postApprove)

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const getBack = () => {
        fetch({
            body: {
                id: Number(id),
                action_type_id: 1,
            },
            successMessage: {
                title: 'Успешно',
                description: `Данные КУ за ${currentDate.toLowerCase()} согласованы`,
            },
            errorCodeMessage: true,
            onSuccess: () => navigate(-1),
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <QuestionCircle />
                    <div className={style.header}>Согласовать данные</div>
                </div>
            </div>
            <div
                className={style.text}
            >{`Вы уверены, что хотите согласовать данные КУ за ${currentDate.toLowerCase()}?`}</div>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={getBack}
                    cancelAction={handleOk}
                    confirmTitle="Согласовать"
                />
            </div>
        </Modal>
    )
}

export default ApproveModal
