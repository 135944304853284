import { RequestTypes } from '../hooks/useRequest'
import { renderDayMonthYear } from '../../utils/formatter'
import { currencyFormat } from '../helpers'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_LIST = 'ei/new/list'
export const GREEN_TOOLS_APPROVE = 'ei/new/approve'
export const GREEN_TOOLS_DECLINE = 'ei/new/decline'

export const getList = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_LIST}`,
    method: RequestTypes.post,
}

export const fetchApprove = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_APPROVE}`,
    method: RequestTypes.post,
}

export const fetchDecline = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_DECLINE}`,
    method: RequestTypes.post,
}

export const infoConfig = (data: any) => [
    {
        name: 'Дата заведения в систему',
        value: renderDayMonthYear(data.ts_created),
    },
    {
        name: 'Количество инструментов',
        value: data.count,
    },
    {
        name: 'Общий объем, кВт*ч',
        value: currencyFormat(data.volume),
    },
]
