import { FC, useState } from 'react'
import { Input, Divider, message, Alert } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    Card,
    DropdownSelector2,
    PrimaryButton,
    PrimaryIconButton,
    CenteredSpin,
    DocumentsBlock,
} from '../ui'
import { getDictionaries } from '../../store/dictionaries'
import { ELoading } from '../../store/dictionaries/interfaces'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectDictionariesOptions } from '../../store/dictionaries'
import { getGenerators, postFuel } from './config'
import { encode, btoa } from 'js-base64'
import { v4 as uuidv4 } from 'uuid'
import { hexToBinary, monthIdPicker } from '../helpers'
import SignUtils from '../../utils/sign/SignUtils'
import IAppState from 'store/states'
import useMount from '../hooks/useMount'
import useRequest from '../hooks/useRequest'
import moment from 'moment'

import style from './style.m.less'
import { FuelSelect } from './components/FuelSelect'
import { TFuelItem } from './types'

const GoKuSubmitFuel: FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { fetch, result, isLoading } = useRequest(getGenerators)
    const { fetch: sendFuel } = useRequest(postFuel)
    const [currentGenerator, setCurrentGenerator] = useState()
    const [files, setFiles] = useState<any[]>([])

    const fileHandler = (list: any) => {
        if (files.length <= 3) {
            return setFiles(list)
        }
    }

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo
    const [selectedFuelsRenew, setSelectedFuelsRenew] = useState<TFuelItem[]>([])
    const [selectedFuelsNonrenew, setSelectedFuelsNonrenew] = useState<TFuelItem[]>([])

    const isLoadingDict = useAppSelector((state) => state.dictionaries.loading)
    const prevMonth = moment().subtract(1, 'months').format('MMMM, YYYY')

    useMount(() => {
        dispatch(getDictionaries())
        fetch({})
    })

    const dictionaries = useAppSelector(selectDictionariesOptions)
    const fuelOptions = dictionaries?.fuelOptions
    const nonrenewableFuelOptions = dictionaries?.nonrenewableFuelOptions

    const checkSended = () => {
        const found = result?.gos?.find((item: any) => item.generator_id === currentGenerator)
        return found?.has_been_sent
    }

    const handleConfirm = async () => {
        const dateArr = prevMonth.split(', ')
        const renewArray = [...selectedFuelsRenew].filter(
            (fuel) => fuel.fuel_id !== 0 && fuel.volume,
        )
        const nonrenewArray = [...selectedFuelsNonrenew].filter(
            (fuel) => fuel.fuel_id !== 0 && fuel.volume,
        )
        const fuelsData = [...renewArray, ...nonrenewArray]
        const seen = new Set()
        const hasDuplicates = fuelsData.some(
            (currentObject) => seen.size === seen.add(currentObject.fuel_id).size,
        )
        if (renewArray.length && nonrenewArray.length && !hasDuplicates && files.length) {
            const body = {
                generator_id: currentGenerator,
                month: monthIdPicker(dateArr[0]),
                year: Number(dateArr[1]),
                fuels_data: nonrenewArray,
                renewable_fuels_data: renewArray,
                documents: files,
            }
            const request_id = uuidv4()
            const thumbprint = await SignUtils.getCertificateThumbprint(
                data.certificate_data.certificate,
            )
            const encodedInfo = encode(JSON.stringify(body))
            const encoded = request_id + encodedInfo
            const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
            const signature = btoa(hexToBinary(preSignature))

            sendFuel({
                body,
                getParams: {
                    request_id,
                },
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                errorCodeMessage: true,
                successMessage: {
                    title: 'Успешно',
                    description: 'Данные по топливу успешно поданы',
                },
                onSuccess: () => navigate('/fuel-registry'),
            })
        } else {
            if (hasDuplicates) {
                message.error('Невозможно подать данные, выбран одинаковый тип топлива')
            } else if (!renewArray.length) {
                message.error('Пожалуйста, выберите хотя бы один возобновляемый источник энергии')
            } else if (!nonrenewArray.length) {
                message.error('Пожалуйста, выберите хотя бы один невозобновляемый источник энергии')
            } else if (!files.length) {
                message.error('Невозможно подать данные - выберите хотя бы один файл')
            } else {
                message.error('Пожалуйста, введите корректные данные')
            }
        }
    }

    if (isLoadingDict !== ELoading.Loaded || !dictionaries || isLoading || !result) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <div className={style.mainHeader}>Подача данных по топливу</div>
            <Card className={style.card}>
                <div className={style.formItem}>
                    <div className={style.label}>ЗГО</div>
                    <DropdownSelector2
                        noDefaultValue
                        options={result?.gos?.map((item: any) => {
                            return {
                                text: item.name,
                                value: item.generator_id,
                                id: item.generator_id,
                            }
                        })}
                        className={style.select}
                        placeholder="Выберите из списка"
                        onChange={(value) => {
                            setCurrentGenerator(value)
                        }}
                    />
                </div>
                <div className={style.formItem}>
                    <div className={style.label}>Отчетный период</div>
                    <Input value={prevMonth} className={style.monthInput} disabled />
                </div>
                {checkSended() && (
                    <Alert
                        className={style.warningAlert}
                        message={
                            'Данные за этот период уже поданы. При отправке новых данных старые будут удалены'
                        }
                        type="warning"
                        showIcon
                    />
                )}
                <Divider />
                <Alert
                    className={style.infoAlert}
                    message={
                        <>
                            <div>Внесите данные по топливу в следующих единицах измерения:</div>
                            <div>Объем - м3, масса - кг</div>
                        </>
                    }
                    type="info"
                    showIcon
                />
                <div className={style.subTitle}>Возобновляемые источники энергии</div>
                <FuelSelect name="renew" setData={setSelectedFuelsRenew} options={fuelOptions} />
                <Divider />
                <div className={style.subTitle}>Невозобновляемые источники энергии</div>
                <FuelSelect
                    name="nonrenew"
                    setData={setSelectedFuelsNonrenew}
                    options={nonrenewableFuelOptions}
                />
                <Divider />
                <DocumentsBlock
                    value={files}
                    onChange={fileHandler}
                    hideComment
                    serviceId={8}
                    description="Прикрепите необходимые документы (не более 3)"
                    customStyle={{ width: '540px' }}
                    disabled={files.length >= 3}
                />
                <Divider />
                <div className={style.buttonWrapper}>
                    <PrimaryButton
                        onClick={() => navigate(-1)}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryIconButton
                        size="large"
                        disabled={
                            !currentGenerator ||
                            (!selectedFuelsRenew.length && !selectedFuelsNonrenew.length)
                        }
                        onClick={handleConfirm}
                        value="Подписать и подать данные"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Card>
        </div>
    )
}

export default GoKuSubmitFuel
