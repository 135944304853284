import { EStatus } from '../../../../constants/approval'

interface IData {
    prevId: number
    uid: string
    key: number
    date: Date
    user: string
    action: string
    userRole: string
    inn: string
    ogrn: string
    status: EStatus
    isNew?: boolean
}

const getDataSource = (): IData[] => [
    {
        key: '1',
        date: new Date(2022, 7, 22),
        user: 'ОАО Ромашка',
        action: 'Смена роли',
        userRole: 'Владелец ГО (новая: Клиент)',
        inn: '123456789',
        ogrn: '1098700342516',
        status: EStatus.New,
        isNew: true,
    },
    {
        key: '2',
        date: new Date(2022, 7, 19),
        user: 'ИП Селиванов Дмитрий Александрович',
        action: 'Регистрация',
        userRole: 'Клиент',
        inn: '123456789',
        ogrn: '1098700342516',
        status: EStatus.Fixed,
        isNew: true,
    },
    {
        key: '3',
        date: new Date(2022, 7, 22),
        user: 'ОАО “РЖД”',
        action: 'Смена роли',
        userRole: 'ТСО/ГП (новая: пользователь без ЛС)',
        inn: '123456789',
        ogrn: '1098700342516',
        status: EStatus.New,
        isNew: true,
    },
    {
        key: '4',
        date: new Date(2022, 7, 22),
        user: 'ООО “Кабан”',
        action: 'Регистрация',
        userRole: 'Владелец ГО',
        inn: '123456789',
        ogrn: '1098700342516',
        status: EStatus.Fixed,
        isNew: true,
    },
    {
        key: '5',
        date: new Date(2022, 7, 22),
        user: 'Ускова Анна Владимировна',
        action: 'Регистрация',
        userRole: 'Клиент',
        inn: '123456789',
        ogrn: '',
        status: EStatus.New,
        isNew: true,
    },
    {
        key: '6',
        date: new Date(2022, 7, 22),
        user: 'ООО Ховер',
        action: 'Редактирование',
        userRole: 'Клиент',
        inn: '123456789',
        ogrn: '1098700342516',
        status: EStatus.UnderRevision,
    },
    {
        key: '7',
        date: new Date(2022, 7, 22),
        user: 'ИП Маяковский Владимир Владимирович',
        action: 'Редактирование',
        userRole: 'ГП',
        inn: '123456789',
        ogrn: '1098700342516',
        status: EStatus.UnderConsideration,
    },
    {
        key: '8',
        date: new Date(2022, 7, 22),
        user: 'ОАО Машинка',
        action: 'Смена роли',
        userRole: 'Владелец ГО (новая: Клиент)',
        inn: '123456789',
        ogrn: '1098700342516',
        status: EStatus.UnderConsideration,
    },
    {
        key: '9',
        date: new Date(2022, 7, 22),
        user: 'Макарова Александра Семеновна',
        action: 'Смена роли',
        userRole: 'Владелец ГО (новая: Клиент)',
        inn: '123456789',
        ogrn: '',
        status: EStatus.UnderRevision,
    },
    {
        key: '10',
        date: new Date(2022, 7, 22),
        user: 'Николаев Артем Витальевич',
        action: 'Регистрация',
        userRole: 'Клиент',
        inn: '123456789',
        ogrn: '',
        status: EStatus.UnderConsideration,
    },
]

const openedDataSource = getDataSource()

for (let i = 0; i < 10; i++) {
    openedDataSource.push(...openedDataSource)
}

const closedDataSource = getDataSource()
closedDataSource[0].status = EStatus.Agreed
closedDataSource[1].status = EStatus.Agreed
closedDataSource[2].status = EStatus.Agreed
closedDataSource[3].status = EStatus.Agreed
closedDataSource[4].status = EStatus.NotAgreed
closedDataSource[5].status = EStatus.Agreed
closedDataSource[6].status = EStatus.Agreed
closedDataSource[7].status = EStatus.NotAgreed
closedDataSource[8].status = EStatus.Agreed
closedDataSource[9].status = EStatus.NotAgreed
for (let i = 0; i < 4; i++) {
    closedDataSource.push(...closedDataSource)
}

export { openedDataSource, closedDataSource }
export type { IData }
