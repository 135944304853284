import { FC } from 'react'
import { TagSelector, PowerSlider } from './components'
import { IconButton } from '../'
import ValueSelector from '../ValueSelector/ValueSelector'
import DatePicker from '../DatePicker/DatePicker'
import DropdownSelector from '../DropdownSelector/DropdownSelector'
import { PageType } from '../interfaces'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

interface IFilters {
    type: PageType
}

const unitTypes = [
    { value: 'МВт', id: '84e78cd0-5bc0-44d7-90e4-bf08ae0a3294' },
    { value: 'КВт', id: 'f22a7267-d07e-405c-99e5-99adf9eea56b' },
]

const generatorsFilterList = [
    {
        value: 'filters.powerType',
        component: () => <TagSelector />,
        id: '9be2c361-ef9e-4c13-8f88-216e952caf92',
    },
    {
        value: 'filters.power',
        component: () => (
            <DropdownSelector
                options={unitTypes}
                customStyle={{ marginTop: '12px', width: '100%' }}
            />
        ),
        id: '18b563db-8871-4c90-8d05-2d8e53e415e1',
    },
    { component: () => <PowerSlider />, id: '362b1ffc-200d-445a-bacd-1a23e43fcd2e' },
    {
        value: 'filters.qualifyDate',
        component: () => (
            <DatePicker customStyle={{ marginTop: '12px', width: '100%', height: '32px' }} />
        ),
        id: '3e560776-dd1a-4870-a322-d5dab7657b9c',
    },
    {
        value: 'filters.lastCheckDate',
        component: () => (
            <DatePicker customStyle={{ marginTop: '12px', width: '100%', height: '32px' }} />
        ),
        id: '0547ad45-2bee-4db4-b79c-923730fb6a9d',
    },
    {
        value: 'filters.region',
        component: (t: (str: string) => string) => (
            <ValueSelector placeholder={t('filters.chooseRegion')} />
        ),
        id: '87438773-bdcd-4e88-a12d-d3dc3e84a4f6',
    },
]

const ownersFilterList = [
    {
        value: 'filters.powerType',
        component: () => <TagSelector />,
        id: '8e3a72d5-c3e3-4a57-afc2-472e45ca1e19',
    },
    {
        value: 'filters.name',
        component: (t: (str: string) => string) => (
            <ValueSelector placeholder={t('filters.inputName')} />
        ),
        id: 'c81380ee-22d1-45f8-9ba6-bfc9cf227cfc',
    },
    {
        value: 'filters.power',
        component: () => (
            <DropdownSelector
                options={unitTypes}
                customStyle={{ marginTop: '12px', width: '100%' }}
            />
        ),
        id: 'e8813d8a-83f0-428c-9683-ccbfeab039d4',
    },
    { component: () => <PowerSlider />, id: '973e6b25-0a99-4d7b-b8ee-7235faffdfac' },
]

const Filters: FC<IFilters> = ({ type }) => {
    const { t } = useTranslation()

    const chosenList = type === 'generators' ? generatorsFilterList : ownersFilterList

    return (
        <div className={style.filtersMain}>
            {chosenList?.map((item, index) => {
                const { value, component, id } = item

                return (
                    <div key={id} className={index === 0 ? '' : style.filtersBlock}>
                        <div>{value && t(value)}</div>
                        {component(t)}
                    </div>
                )
            })}
            <div className={style.resetButton}>
                <IconButton>Сбросить фильтры</IconButton>
            </div>
        </div>
    )
}

export default Filters
