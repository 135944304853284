import { FC, useState } from 'react'
import { Divider } from 'antd'
import style from './style.m.less'
import { ShowMoreLink, PowerTypeTag } from '../../../ui'

interface IInfoBlock {
    name?: string
    value?: string
    isTag?: boolean
    isOwner?: boolean
    isDivider?: boolean
    noMargin?: boolean
}

interface IGeneratorInfoFrame {
    label: string
    statusBlock: React.ReactNode
    config: {
        visibleInfo: IInfoBlock[]
        hiddenInfo?: IInfoBlock[]
    }
    isProfile?: boolean
    customStyle?: React.CSSProperties
    bottomChildren?: React.ReactNode
}

const createInfoBlocks = (config: IInfoBlock[], isVisible?: boolean) => {
    return config?.map((item, index) => {
        const { name, value, isTag, isDivider, noMargin } = item

        const chooseBlockClass = () => {
            if (index === 0 && isVisible && !noMargin) {
                return style.infoBlockFirst
            } else if (noMargin) {
                return style.infoBlockNoMargin
            } else {
                return style.infoBlock
            }
        }

        const chooseBlockType = () => {
            if (isTag && value) {
                return (
                    <PowerTypeTag value={value} customStyle={{ marginLeft: '24px' }}>
                        {value}
                    </PowerTypeTag>
                )
            } else if (isDivider) {
                return <Divider />
            } else {
                return <div className={style.infoValue}>{value}</div>
            }
        }

        return (
            <div className={chooseBlockClass()} key={name}>
                <div className={style.infoName}>{name}</div>
                {chooseBlockType()}
            </div>
        )
    })
}

const GeneratorInfoFrame: FC<IGeneratorInfoFrame> = ({
    label,
    statusBlock,
    config,
    customStyle,
    children,
    bottomChildren,
}) => {
    const [showHidden, setShowHidden] = useState(false)

    const toggleHidden = () => {
        setShowHidden(!showHidden)
    }
    const { visibleInfo, hiddenInfo } = config

    return (
        <div className={style.generatorInfoFrameMain} style={customStyle}>
            <div className={style.headerPrimary}>{label}</div>
            {children}
            {statusBlock}
            {createInfoBlocks(visibleInfo, true)}
            {!showHidden && hiddenInfo && <ShowMoreLink showMore={false} onChange={toggleHidden} />}
            {showHidden && hiddenInfo && (
                <>
                    <Divider />
                    {createInfoBlocks(hiddenInfo)}
                    <ShowMoreLink showMore onChange={toggleHidden} />
                </>
            )}
            {bottomChildren && bottomChildren}
        </div>
    )
}

export default GeneratorInfoFrame
