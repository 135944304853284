import { Alert, Divider } from 'antd'
import { DropdownInput, IconButton, IconLink, PrimaryButton } from '../../../../components/ui'
import { FC, useState } from 'react'
import { AddCircle, Stamp } from '../../../../assets/icons'

import style from './style.m.less'
import { Export } from '../../../../assets/icons'
import WarningModal from '../WarningModal/WarningModal'
import VolumeIndicators from '../VolumeIndicators/VolumeIndicators'

const kgoTypes = ['КГО 1', 'КГО 2']

const AddManually: FC = () => {
    const [isWarningModalVisible, setIsWarningModalVisible] = useState(false)
    const openWarningModal = () => {
        setIsWarningModalVisible(true)
    }

    return (
        <div className={style.opened}>
            <div className={style.inputWrapper}>
                <div className={style.label}>КГО</div>
                <DropdownInput
                    placeholder="Введите название КГО или выберите из списка"
                    options={kgoTypes}
                    customStyle={{ width: '400px' }}
                />
            </div>
            <VolumeIndicators />
            <VolumeIndicators />
            <div className={style.addDataButton}>
                <IconLink onClick={() => {}} icon={<AddCircle />}>
                    Добавить дату
                </IconLink>
            </div>
            <Divider />
            <Alert
                message={
                    <div className={style.uploadDocumentFrame}>
                        <IconButton
                            icon={<Export />}
                            customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                        >
                            Загрузить файл
                        </IconButton>
                        <div className={style.info}>Прикрепите необходимые документы</div>
                    </div>
                }
                className={style.alert}
                type="info"
            />
            <Divider />
            {/* <IconButton onClick={openWarningModal} disabled className={style.disabledButton}>
                Подать данные
            </IconButton> */}
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    // onClick={handleOk}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryButton
                    value="Подать данные"
                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                />
            </div>
            <WarningModal
                isModalVisible={isWarningModalVisible}
                setIsModalVisible={setIsWarningModalVisible}
            />
        </div>
    )
}

export default AddManually
