// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox_kGNht .ant-checkbox-wrapper {\n  display: flex;\n  align-items: baseline;\n}\n.checkbox_kGNht .ant-checkbox-inner {\n  border-radius: 2px;\n}\n.checkbox_kGNht .ant-checkbox-input:focus + .ant-checkbox-inner {\n  border-color: #007EF2;\n}\n.checkbox_kGNht .ant-checkbox-checked .ant-checkbox-inner {\n  background-color: #007EF2;\n  border-color: #007EF2;\n}\n.checkbox_kGNht .ant-checkbox-checked .ant-checkbox-inner::after {\n  top: 6px;\n}\n.checkbox_kGNht .ant-checkbox-disabled .ant-checkbox-inner {\n  border-color: #D3D5DA;\n  background-color: #F8F8FA;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Checkbox/style.m.less"],"names":[],"mappings":"AAAA;EAGY,aAAA;EACA,qBAAA;AADZ;AAHA;EAOY,kBAAA;AADZ;AANA;EAUY,qBAAA;AADZ;AATA;EAaY,yBAAA;EACA,qBAAA;AADZ;AAbA;EAiBY,QAAA;AADZ;AAhBA;EAoBY,qBAAA;EACA,yBAAA;AADZ","sourcesContent":[".checkbox {\n    :global {\n        .ant-checkbox-wrapper {\n            display: flex;\n            align-items: baseline;\n        }\n        .ant-checkbox-inner {\n            border-radius: 2px;\n        }\n        .ant-checkbox-input:focus + .ant-checkbox-inner {\n            border-color: #007EF2;\n        }\n        .ant-checkbox-checked .ant-checkbox-inner {\n            background-color: #007EF2;\n            border-color: #007EF2;\n        }\n        .ant-checkbox-checked .ant-checkbox-inner::after {\n            top: 6px;\n        }\n        .ant-checkbox-disabled .ant-checkbox-inner {\n            border-color: #D3D5DA;\n            background-color: #F8F8FA;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "checkbox_kGNht"
};
export default ___CSS_LOADER_EXPORT___;
