import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Alert, Button, message } from 'antd'
import { monthPicker, signBody } from '../../../helpers'
import { Modal } from '../../../ui'
import useRequest from '../../../hooks/useRequest'
import { balanceConfig, paymentConfig } from './requestConfigs'
import { moneyAmountFormatter } from '../../../helpers/moneyAmountFormatter'
import IAppState from '../../../../store/states'
import style from '../../style.m.less'
import moment from 'moment'

interface IPaymentModal {
    contract_number: string
    contract_id: string
    isVisible: boolean
    status?: 0 | 2
    volume?: number
    consume_period?: string
    buyer_uid: string
    buyer_name: string
    seller_fee: number
    buyer_fee: number
    commission_assign?: number
    is_buyer_tax_payer?: boolean
    onOk: () => void
    onCancel: () => void
    openSubModal?: () => void
    comission_on: boolean
}

const PaymentModal: FC<IPaymentModal> = ({
    contract_number,
    contract_id,
    isVisible,
    buyer_uid,
    volume,
    seller_fee,
    buyer_fee,
    consume_period,
    commission_assign,
    is_buyer_tax_payer,
    buyer_name,
    onOk,
    onCancel,
    openSubModal,
    comission_on,
}) => {
    const infoRows = [
        { name: 'Номер договора', value: contract_number },
        { name: 'Покупатель', value: buyer_name },
        {
            name: 'Период поставки',
            value: `${monthPicker(moment(consume_period).month() + 1)}, ${moment(
                consume_period,
            ).year()}`,
        },
        { name: 'Объем', value: `${volume?.toLocaleString().replace(',', ' ')} кВт*ч` },
    ]

    const { result: balance, fetch: fetchBalance } = useRequest(balanceConfig)
    const { fetch: fetchPayment, isLoading: isLoadingPayment } = useRequest(paymentConfig)

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo

    useEffect(() => {
        if (isVisible) {
            fetchBalance({})
        }
    }, [isVisible])

    const formattedBalance = moneyAmountFormatter.format(balance?.available_balance)
    const formattedBuyerFee = moneyAmountFormatter.format(buyer_fee)
    const formattedSellerFee = moneyAmountFormatter.format(seller_fee)
    const formattedFinalFee = moneyAmountFormatter.format(
        seller_fee + (is_buyer_tax_payer ? 0 : buyer_fee),
    )

    const handleConfirm = async () => {
        if (commission_assign && +balance?.available_balance < commission_assign) {
            onCancel()
            openSubModal?.()
            return
        }

        const body = {
            contract_number,
            period_date: consume_period,
            period_id: contract_id,
            buyer_uid,
            volume,
            seller_fee,
            buyer_fee,
        }
        const { request_id, signature } = await signBody(body, data)

        fetchPayment({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            onSuccess: () => {
                message.success({
                    content: `${!comission_on ? 'Передача' : 'Оплата'} успешно прошла`,
                    icon: <></>,
                })
                onOk?.()
            },
            showError: true,
        })
    }

    return (
        <Modal isModalVisible={isVisible} onCancel={onCancel}>
            <div className={style.modalWrapper}>
                <div className={style.repaymentHeader}>
                    {!comission_on ? 'Подтвердить передачу' : 'Оплата комиссии'}
                </div>
                <div className={style.info}>
                    {infoRows.map(({ name, value }) => (
                        <div className={style.infoRow} key={name}>
                            <div className={style.infoRowName}>{name}</div>
                            <div className={style.infoRowValue}>{value}</div>
                        </div>
                    ))}
                </div>
                {comission_on && (
                    <>
                        <Divider className={style.divider} />
                        <div style={{ marginTop: 0 }} className={style.info}>
                            <div className={style.infoRow}>
                                <div className={style.infoRowName}>
                                    Комиссия за присвоение АГ признака ЗД
                                </div>
                                <div className={style.infoRowValue}>{formattedSellerFee}</div>
                            </div>
                            {!is_buyer_tax_payer && (
                                <div className={style.infoRow}>
                                    <div className={style.infoRowName}>
                                        Комиссия за реализацию АГ по ЗД
                                    </div>
                                    <div className={style.infoRowValue}>{formattedBuyerFee}</div>
                                </div>
                            )}
                        </div>
                        <Alert
                            className={style.greyAlert}
                            message={
                                <div className={style.alertMessage}>
                                    Сумма комиссии составила {formattedFinalFee}
                                </div>
                            }
                            description={
                                <div className={style.alertDescription}>
                                    Баланс платежного счета: {formattedBalance}
                                </div>
                            }
                        />

                        <Divider className={style.divider} />
                        <Alert
                            type="info"
                            showIcon
                            className={style.blueAlert}
                            message={
                                <div>
                                    При нажатии кнопки “Оплатить” денежные средства на Вашем
                                    платежном <br /> счете будут заблокированы до момента указания
                                    Покупателем всех <br /> Потребителей, после чего они будут
                                    списаны. Если Покупатель не укажет <br />
                                    Потребителей в установленный срок, денежные средства на Вашем{' '}
                                    <br /> платежном счете будут разблокированы, а АГ станут
                                    свободными.
                                </div>
                            }
                        />
                    </>
                )}
            </div>
            <div className={style.buttonsBlock}>
                <Button onClick={onCancel} className={style.cancelButton}>
                    Отмена
                </Button>
                <Button
                    className={style.confirmButton}
                    onClick={handleConfirm}
                    loading={isLoadingPayment}
                >
                    {` Подписать и ${!comission_on ? 'подтвердить' : 'оплатить'}`}
                </Button>
            </div>
        </Modal>
    )
}

export default PaymentModal
