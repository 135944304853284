import { FC } from 'react'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

interface IIsDeregisteredProps {
    date?: string
}

const IsDeregistered: FC<IIsDeregisteredProps> = ({ date }) => {
    const { t } = useTranslation()

    return (
        <div className={style.main}>
            <div className={style.statusIsDeregistered}>
                <div className={style.statusText}>Генерирующий объект снят с учета</div>
                <div className={style.statusReason}>
                    Дата снятия с учета: {moment(date).format('DD.MM.YYYY')}
                    <br />
                    Чтобы вернуть ГО на учет необходимо написать оператору системы в разделе “Мои
                    заявки”
                </div>
            </div>
        </div>
    )
}

export default IsDeregistered
