// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper_KzEdQ {\n  display: flex;\n}\n.contentWrapper_cTOGn {\n  margin-left: 18px;\n  width: 319px;\n}\n.modalHeader_Gs42x {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.buttonsBlock_iZ0XB {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.inputBlock_oSQHl {\n  margin-top: 24px;\n}\n.input_ZI7Q8 {\n  border-radius: 8px;\n}\n.text_ujJtk {\n  width: 279px;\n  margin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOProfile/components/PersonalAccount/components/AccountModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;EACI,iBAAA;EACA,YAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,gBAAA;AAHJ;AAMA;EACI,kBAAA;AAJJ;AAOA;EACI,YAAA;EACA,gBAAA;AALJ","sourcesContent":[".mainWrapper {\n    display: flex;\n}\n\n.contentWrapper {\n    margin-left: 18px;\n    width: 319px;\n}\n\n.modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #303440;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.inputBlock {\n    margin-top: 24px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.text {\n    width: 279px;\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": "mainWrapper_KzEdQ",
	"contentWrapper": "contentWrapper_cTOGn",
	"modalHeader": "modalHeader_Gs42x",
	"buttonsBlock": "buttonsBlock_iZ0XB",
	"inputBlock": "inputBlock_oSQHl",
	"input": "input_ZI7Q8",
	"text": "text_ujJtk"
};
export default ___CSS_LOADER_EXPORT___;
