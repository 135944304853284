import { FC, useRef, useState } from 'react'
import { Input } from 'antd'

import style from './style.m.less'
import { TimeRangePicker, DropdownSelector2 } from '../../../ui'
import { EUserRole, userRoleMap } from '../../../../constants/approval'

const userTypeOptions = [
    { id: EUserRole.All, value: EUserRole.All, text: userRoleMap.get(EUserRole.All) },
    {
        id: EUserRole.UserWithouPA,
        value: EUserRole.UserWithouPA,
        text: userRoleMap.get(EUserRole.UserWithouPA),
    },
    {
        id: EUserRole.Client,
        value: EUserRole.Client,
        text: userRoleMap.get(EUserRole.Client),
    },
    {
        id: EUserRole.GOOwner,
        value: EUserRole.GOOwner,
        text: userRoleMap.get(EUserRole.GOOwner),
    },
    {
        id: EUserRole.TSO,
        value: EUserRole.TSO,
        text: userRoleMap.get(EUserRole.TSO),
    },
]

interface IFilter {
    eventTypesOptions: any
    onChangeEventId: (id: number[]) => void
    onChangeNameCreator: (name: string) => void
    onChangeRoleCreator: (role: number) => void
    onChangeNameRecipient: (inn: string) => void
    onChangeDate: (date: [moment.Moment, moment.Moment] | null) => void
}

const Filter: FC<IFilter> = ({
    eventTypesOptions,
    onChangeEventId,
    onChangeNameCreator,
    onChangeRoleCreator,
    onChangeNameRecipient,
    onChangeDate,
}) => {
    const divBlock: any = useRef(null)
    const marginSpace = 50
    const itemWidth = `${((divBlock?.current?.offsetWidth || 0) - marginSpace) / 5}px`
    const itemStyle = { width: itemWidth }

    const handleChange = (value: number[]) => {
        onChangeEventId(value)
    }

    return (
        <div className={style.filter} ref={divBlock}>
            <div className={style.filterItem}>
                <div className={style.label}>Дата события</div>
                <TimeRangePicker onChange={onChangeDate} />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Тип события</div>
                <DropdownSelector2
                    onChange={handleChange}
                    options={eventTypesOptions}
                    customStyle={itemStyle}
                    placeholder={'Все'}
                    allowClear
                    noDefaultValue
                    isMultiple
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Исполнитель</div>
                <Input
                    onChange={(event) => onChangeNameCreator(event.target.value)}
                    placeholder="Введите ФИО / название"
                    className={style.input}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Роль пользователя (исполнителя)</div>
                <DropdownSelector2
                    onChange={onChangeRoleCreator}
                    options={userTypeOptions}
                    customStyle={itemStyle}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Адресат действия</div>
                <Input
                    onChange={(event) => onChangeNameRecipient(event.target.value)}
                    placeholder="Введите ФИО / название"
                    className={style.input}
                />
            </div>
        </div>
    )
}

export default Filter
