import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../../ui'
import style from './style.m.less'
import { CloseOutlined } from '@ant-design/icons'

interface IOperationDetailsModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const OperationDetailsModal: FC<IOperationDetailsModal> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const { t } = useTranslation()
    const closeModal = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeaderWrapper}>
                <div className={style.modalHeader}>{t('myProfile.operationDetails')}</div>
                <CloseOutlined className={style.modalCloseIcon} onClick={closeModal} />
            </div>
            <div className={style.modalWrapper}>
                <div className={style.modalName}>{t('myProfile.operationType')}</div>
                <div className={style.modalValue}>Погашение зеленого инструмента</div>
            </div>
            <div className={style.modalWrapper}>
                <div className={style.modalName}>{t('myProfile.certNumber')}</div>
                <div className={style.modalCertificateValue}>
                    kjagsdkkvjgug8368r73-r-4209wf8v9yui
                </div>
            </div>
            <div className={style.modalWrapper}>
                <div className={style.modalName}>{t('myProfile.certVolume')}</div>
                <div className={style.modalValue}>600 784 300 кВт*ч</div>
            </div>
            <div className={style.modalWrapper}>
                <div className={style.modalName}>{t('myProfile.repaymentVolume')}</div>
                <div className={style.modalValue}>931 000 кВт*ч</div>
            </div>
            <div className={style.modalWrapper}>
                <div className={style.modalName}>{t('myProfile.date')}</div>
                <div className={style.modalValue}>16.06.2022</div>
            </div>
            <div className={style.modalWrapper}>
                <div className={style.modalName}>{t('myProfile.operationPayment')}</div>
                <div className={style.modalValue}>2 500 ₽ </div>
            </div>
        </Modal>
    )
}

export default OperationDetailsModal
