import { FC } from 'react'
import { message } from 'antd'
import { SuccessMessage } from '../../../Edit/components'
import { Modal, PrimaryButton, PrimaryIconButton } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import request from '../../../../services/request'
import { QuestionCircle } from '../../../../assets/icons'
import { useParams } from 'react-router-dom'

interface IReturnRegisterModal {
    isModalVisible: boolean
    goName?: string
    onClose: () => void
    onReload: () => void
}

const ReturnRegisterModal: FC<IReturnRegisterModal> = ({
    isModalVisible,
    goName,
    onClose,
    onReload,
}) => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()

    const handleConfirm = async () => {
        await request.get(`/api/generators/v1/go-deregister-cancel?generator_id=${id}`)
        onClose()
        message.success({
            content: (
                <SuccessMessage
                    header={t('detailedCard.success')}
                    message="ГО успешно возвращен на учет"
                />
            ),
            icon: <></>,
        })
        onReload()
    }

    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            <div className={style.wrapper}>
                <QuestionCircle />
                <div className={style.header}>Вернуть на учет</div>
            </div>
            <div className={style.text}>Вы действительно хотите вернуть на учет ГО {goName}?</div>
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    onClick={onClose}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryIconButton
                    size="large"
                    onClick={handleConfirm}
                    value="Да"
                    customStyle={{ padding: '8px 16px', marginLeft: '12px' }}
                />
            </div>
        </Modal>
    )
}

export default ReturnRegisterModal
