// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_q_fGC {\n  width: 1300px;\n  padding: 40px 10px 100px;\n  margin: 0 auto;\n}\n.title_SXEGW {\n  font-family: 'Raleway';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  font-feature-settings: \"lnum\";\n  color: #262626;\n  letter-spacing: 0.5px;\n}\n.tabs_kXdie {\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/PageElements/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,wBAAA;EACA,cAAA;AACJ;AAEA;EACI,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;EACA,qBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ","sourcesContent":[".container {\n    width: 1300px;\n    padding: 40px 10px 100px;\n    margin: 0 auto;\n}\n\n.title {\n    font-family: 'Raleway';\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    font-feature-settings: \"lnum\";\n    color: #262626;\n    letter-spacing: 0.5px;\n}\n\n.tabs {\n    margin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_q_fGC",
	"title": "title_SXEGW",
	"tabs": "tabs_kXdie"
};
export default ___CSS_LOADER_EXPORT___;
