import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Divider, Input } from 'antd'
import { AddCircle, CloseCircle, NoteAdd, Trash } from '../../../assets/icons'
// import { documentIconPicker } from '../../../ui/'
import { IconButton, PrimaryIconButton } from '..'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { documentIconPicker } from '../../helpers'

const { TextArea } = Input

const documentsList = [
    {
        name: 'Устав Ассоциации...',
        size: '183 Мб',
        description: 'Описание документа на 100-500 символов',
        type: 'pdf',
    },
    {
        name: 'Положение о порядке...',
        size: '146 Кб',
        description: 'Описание документа на 100-500 символов',
        type: 'doc',
    },
    {
        name: 'Атрибуты генерации',
        size: '183 Мб',
        description: 'Описание документа на 100-500 символов',
        type: 'xls',
    },
]

interface IDocumentsList {
    isAdding?: boolean
}

const DocumentsListUI: FC<IDocumentsList> = ({ isAdding }) => {
    const { t } = useTranslation()

    return (
        <>
            {documentsList.map((item, index) => {
                const { name, size, description, type } = item
                return (
                    <div key={name}>
                        <div className={style.documentBlock}>
                            <div className={style.mainInfoWrapper}>
                                <div className={style.iconWrapper}>
                                    {documentIconPicker(type)}
                                    <div className={style.infoBlock}>
                                        <div className={style.documentName}>{name}</div>
                                        <div className={style.documentSize}>{size}</div>
                                    </div>
                                </div>
                                {isAdding && (
                                    <div className={style.iconWrapper}>
                                        <PrimaryIconButton
                                            icon={index === 2 ? <CloseCircle /> : <NoteAdd />}
                                        />
                                        <PrimaryIconButton
                                            isCancel
                                            customStyle={{ marginLeft: '8px' }}
                                            icon={<Trash />}
                                        />
                                    </div>
                                )}
                            </div>
                            {!isAdding && (
                                <div className={style.documentDescription}>{description}</div>
                            )}
                        </div>
                        {!isAdding && index !== documentsList.length - 1 ? (
                            <Divider />
                        ) : (
                            <div className={style.emptyDivider}></div>
                        )}
                    </div>
                )
            })}
            {isAdding && (
                <TextArea
                    className={style.textArea}
                    placeholder={t('documentsList.inputDescr')}
                    rows={4}
                />
            )}
            {!isAdding && (
                <Link to={'/add-documents'}>
                    <IconButton
                        customStyle={{ marginTop: '32px' }}
                        icon={<AddCircle />}
                        customIconStyle={{ marginRight: '8px', marginTop: '5px' }}
                    >
                        {t('documentsList.add')}
                    </IconButton>
                </Link>
            )}
        </>
    )
}

export default DocumentsListUI
