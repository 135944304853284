import { FC } from 'react'
import { Divider } from 'antd'
import { CardInfoBlock, Box } from '../../../ui'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Flash, Calendar, Location } from '../../../../assets/icons'
import { selectDictionariesMaps } from '../../../../store/dictionaries'
import { useAppSelector } from '../../../../store/hooks'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import PowerTypeTag from '../PowerTypeTag/PowerTypeTag'

export const getStatus = (option: number) => {
    switch (option) {
        case 1:
            return <div className={style.statusRepay}>Новая</div>
        case 2:
            return <div className={style.statusRepay}>На рассмотрении</div>
        case 3:
            return <div className={style.statusTag}>Согласована</div>
        case 4:
            return <div className={style.statusBlocked}>Не согласована</div>
        case 5:
            return <div className={style.statusBlocked}>Удален</div>
        case 6:
            return <div className={style.statusRepay}>Ожидает оплаты</div>
        default:
            return <div className={style.statusRepay} />
    }
}

export interface IGeneratorFiled {
    generator_id: string
    go_name: string
    power: number
    last_check_date: string | null
    region_id: number
    renewable_energy_type_id: number
    generator_status: number
}

interface IGeneratorCard {
    generatorData: IGeneratorFiled
}

const GeneratorCardFiled: FC<IGeneratorCard> = ({ generatorData }) => {
    const {
        generator_id,
        go_name,
        power,
        last_check_date,
        region_id,
        renewable_energy_type_id,
        generator_status,
    } = generatorData

    const { t } = useTranslation()

    const dictionaries = useAppSelector(selectDictionariesMaps)

    const { regionsMap, renewableEnergyTypesMap } = dictionaries

    const energyType = renewableEnergyTypesMap.get(renewable_energy_type_id)

    return (
        <div className={style.cardMain}>
            <div>
                <Link to={`/generator-card/${generator_id}`} className={style.cardHeader}>
                    {go_name}
                </Link>
                <div className={style.infoRow}>
                    <CardInfoBlock
                        label={t('generatorCard.power')}
                        value={`${(power / 1000).toLocaleString()} ${t('generatorCard.scale')}`}
                        icon={<Flash />}
                        gap={7}
                    />
                    <Divider className={style.divider} type={'vertical'} />
                    <CardInfoBlock
                        label={t('generatorCard.lastCheckDate')}
                        value={last_check_date ? moment(last_check_date).format('DD.MM.YYYY') : '-'}
                        icon={<Calendar />}
                        gap={6}
                    />
                </div>
                <div className={style.locationBlock}>
                    <CardInfoBlock
                        value={`Россия, ${regionsMap.get(region_id)}`}
                        icon={<Location />}
                        gap={11}
                    />
                </div>
            </div>
            <Box direction="row" gap={'12px'}>
                {generator_status ? getStatus(generator_status) : undefined}
                <PowerTypeTag value={energyType} />
            </Box>
        </div>
    )
}

export default GeneratorCardFiled
