import { FC, useEffect, useState } from 'react'
import { Card, Alert, CenteredSpin } from '../ui'
import { Divider } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft } from '../../assets/icons'
import SearchTSO from './components/SearchTSO/SearchTSO'
import SearchGP from './components/SearchGP/SearchGP'
import AddEditTSO from './components/AddEditTSO/AddEditTSO'
import AddEditGP from './components/AddEditGP/AddEditGP'
import ButtonsBlock from './components/ButtonsBlock/ButtonsBlock'
import { IGP } from './components/AddEditGP/AddEditGP'
import { ITSO } from './components/AddEditTSO/AddEditTSO'
import { SelectedTsoGp2 } from '../../components/AddEditGO/components'
import { hexToBinary } from '../helpers'
import SignUtils from '../../utils/sign/SignUtils'
import { v4 as uuidv4 } from 'uuid'
import { encode, btoa } from 'js-base64'
import { useAppSelector } from '../../store/hooks'
import { EUserRole } from '../../constants/approval'
import { useGetTariffRate } from '../hooks'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { IGeneratingObject } from 'src/store/generators/IGeneratingObject'

const GOActivation: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    const [selectedTso, setSelectedTso] = useState<ITSO>()
    const [openAddEditTso, setOpenAddEditTso] = useState(false)

    const [selectedGp, setSelectedGp] = useState<IGP>()
    const [openAddEditGp, setOpenAddEditGp] = useState(false)

    const [isSearchTSOModalVisible, setIsSearchTSOModalVisible] = useState(false)
    const openSearchTSOModal = () => {
        setIsSearchTSOModalVisible(true)
    }

    const [isSearchGPModalVisible, setIsSearchGPModalVisible] = useState(false)
    const openSearchGPModal = () => {
        setIsSearchGPModalVisible(true)
    }

    const userInfo = useAppSelector((store) => store.user)
    const { data } = userInfo
    const userRole = data.user_role
    const isUserGOOwner = userRole === EUserRole.GOOwner

    const { fetch } = useRequest({
        link: '/api/generators/v1/go-activate',
        method: RequestTypes.post,
    })

    const {
        fetch: getGenerator,
        isLoading: loading,
        result,
    } = useRequest({
        link: '/api/generators/v1/go',
        method: RequestTypes.get,
    })

    const generator: IGeneratingObject = result

    useEffect(() => {
        getGenerator({
            getParams: {
                generator_id: id,
            },
            onSuccess(data) {
                if (data?.tso) {
                    setSelectedTso({
                        ...data.tso,
                        isDraft: data.tso.is_draft,
                    })
                }
                if (data?.gp) {
                    setSelectedGp({
                        ...data.gp,
                        isDraft: data.gp.is_draft,
                    })
                }
            },
        })
    }, [id])

    const handleConfirm = async () => {
        const params = {
            generator_id: id,
        }
        if (selectedTso) {
            params.uid_tso = selectedTso.uid
            params.is_uid_tso_draft = selectedTso.isDraft
        }
        if (selectedGp) {
            params.uid_gp = selectedGp.uid
            params.is_uid_gp_draft = selectedGp.isDraft
        }
        if (isUserGOOwner) {
            const requestId = uuidv4()
            const thumbprint = await SignUtils.getCertificateThumbprint(
                data?.certificate_data?.certificate,
            )
            const encodedInfo = encode(JSON.stringify(params))
            const encoded = requestId + encodedInfo
            const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
            const Signature = btoa(hexToBinary(preSignature))
            fetch({
                getParams: {
                    request_id: requestId,
                },
                body: params,
                showError: true,
                config: {
                    headers: {
                        Signature,
                    },
                },
                successMessage: {
                    title: t('detailedCard.success'),
                    description: `ЗГО ${generator?.name} ${t('detailedCard.activated')}`,
                },
                onSuccess() {
                    navigate(-1)
                },
            })
        } else {
            fetch({
                body: params,
                showError: true,
                successMessage: {
                    title: t('detailedCard.success'),
                    description: `ЗГО ${generator?.name} ${t('detailedCard.activated')}`,
                },
                onSuccess() {
                    navigate(-1)
                },
            })
        }
    }

    const { loadingTariffs, balance, rate } = useGetTariffRate({
        id: 37,
    })

    const handleCancel = () => {
        navigate(-1)
    }

    if (loadingTariffs) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div className={style.mainHeader}>Зарегистрировать ГО</div>
            </div>

            <Card className={style.repaymentCard}>
                {loading ? (
                    <CenteredSpin />
                ) : generator?.is_for_retail ? (
                    <>
                        <SelectedTsoGp2
                            label="ТСО"
                            selectedTsoGp={selectedTso}
                            onEditClick={() => setOpenAddEditTso(true)}
                            onRemoveClick={() => setSelectedTso(undefined)}
                            onSearchClick={openSearchTSOModal}
                            onManualEditClick={() => setOpenAddEditTso(true)}
                        />
                        <SelectedTsoGp2
                            label="ГП"
                            selectedTsoGp={selectedGp}
                            onEditClick={() => setOpenAddEditGp(true)}
                            onRemoveClick={() => setSelectedGp(undefined)}
                            onSearchClick={openSearchGPModal}
                            onManualEditClick={() => setOpenAddEditGp(true)}
                        />
                        <Divider />
                    </>
                ) : null}
                <Alert
                    className={style.activationAlert}
                    message={
                        <>
                            <span className={style.alertMessage}>
                                За регистрацию ГО взымается оплата в размере{' '}
                            </span>
                            <span className={style.alertPrice}>{rate}</span>
                        </>
                    }
                    description={
                        <>
                            <span className={style.alertDetail}>Баланс платежного счета</span>
                            <span className={style.alertDetail}> {balance}</span>
                        </>
                    }
                    type="warning"
                    showIcon
                />
                <Divider />
                <div className={style.buttonsBlock}>
                    <ButtonsBlock
                        confirmAction={handleConfirm}
                        cancelAction={handleCancel}
                        confirmTitle={
                            isUserGOOwner
                                ? 'Подписать и зарегистрировать'
                                : t('detailedCard.activate2')
                        }
                    />
                </div>
            </Card>
            <SearchTSO
                isModalVisible={isSearchTSOModalVisible}
                onCancel={() => setIsSearchTSOModalVisible(false)}
                onSelect={(tso) => {
                    setSelectedTso(tso)
                    setIsSearchTSOModalVisible(false)
                }}
            />
            <SearchGP
                isModalVisible={isSearchGPModalVisible}
                onCancel={() => setIsSearchGPModalVisible(false)}
                onSelect={(gp) => {
                    setSelectedGp(gp)
                    setIsSearchGPModalVisible(false)
                }}
            />
            <AddEditTSO
                isModalVisible={openAddEditTso}
                onCancel={() => setOpenAddEditTso(false)}
                onChange={(tso) => {
                    setOpenAddEditTso(false)
                    setSelectedTso(tso)
                }}
            />
            <AddEditGP
                isModalVisible={openAddEditGp}
                onCancel={() => setOpenAddEditGp(false)}
                onChange={(gp) => {
                    setOpenAddEditGp(false)
                    setSelectedGp(gp)
                }}
            />
        </div>
    )
}

export default GOActivation
