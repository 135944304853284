import { FC, ReactNode } from 'react'

import style from '../style.m.less'

type PageTabsProps = {
    children: ReactNode
    className?: any
}

const PageTabs: FC<PageTabsProps> = ({ children, className }) => (
    <div className={className || style.tabs}>{children}</div>
)

export default PageTabs
