import { FC } from 'react'
import { Modal } from '..'
import style from './style.m.less'
import { ButtonsBlock } from '../../Edit/components'

interface IReturnRegisterModal {
    isModalVisible: boolean
    onClose?: () => void
    onConfirm?: () => void
    children?: any
    params: any
    styleWrapper?: any
    styleButtonsBlock?: any
    cancelDisabled?: boolean
    confirmDisabled?: boolean
    closeAfterConfirm?: boolean
    confirmTitle?: string
    cancelTitle?: string
}

const CustomModal: FC<IReturnRegisterModal> = ({
    isModalVisible,
    onClose,
    onConfirm,
    children,
    params,
    styleWrapper,
    styleButtonsBlock,
    cancelDisabled,
    confirmDisabled,
    closeAfterConfirm = true,
    confirmTitle = undefined,
    cancelTitle = undefined,
}) => {
    const handleConfirm = async () => {
        if(onConfirm) {
            onConfirm()
        }
        if(closeAfterConfirm && onClose) {
            onClose()
        }
    }

    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            <div className={style.wrapper} style={styleWrapper}>
                <div className={style.header}>{params.title}</div>
            </div>
            {params.text && <div className={style.text}>{params.text}</div>}
            {children && <div>
                {children}
                </div>}
            <div className={style.buttonsBlock} style={styleButtonsBlock}>
                <ButtonsBlock
                    confirmTitle={confirmTitle}
                    cancelTitle={cancelTitle}
                    margin
                    cancelDisabled={cancelDisabled}
                    confirmDisabled={confirmDisabled}
                    confirmAction={handleConfirm}
                    cancelAction={onClose}
                />
            </div>
        </Modal>
    )
}

export default CustomModal