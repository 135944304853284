// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper_E_SbZ {\n  margin-bottom: 16px;\n}\n.header_fMzpI {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.text_Hnaej {\n  width: 360px;\n}\n.buttonsBlock_TyTj1 {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 24px;\n}\n.payAlert_HwVhG {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 12px 24px;\n  margin-top: 19px;\n  background: #fafafa;\n  border: 1px solid #f0f0f0;\n  border-radius: 16px;\n}\n.payHeader_ZJhFa {\n  font-weight: 600;\n}\n.payText_c5_M9 {\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/PaymentModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,YAAA;AADJ;AAIA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAOA;EACI,eAAA;AALJ","sourcesContent":[".modalWrapper {\n    margin-bottom: 16px;\n}\n\n.header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.text {\n    width: 360px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 24px;\n}\n\n.payAlert {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    padding: 12px 24px;\n    margin-top: 19px;\n    background: #fafafa;\n    border: 1px solid #f0f0f0;\n    border-radius: 16px;\n}\n\n.payHeader {\n    font-weight: 600;\n}\n\n.payText {\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper_E_SbZ",
	"header": "header_fMzpI",
	"text": "text_Hnaej",
	"buttonsBlock": "buttonsBlock_TyTj1",
	"payAlert": "payAlert_HwVhG",
	"payHeader": "payHeader_ZJhFa",
	"payText": "payText_c5_M9"
};
export default ___CSS_LOADER_EXPORT___;
