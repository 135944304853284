import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Flash: FC<IIconProps> = ({ size = 16 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.10186 8.81923H6.14009V13.4268C6.14009 14.5019 6.74034 14.7195 7.47252 13.9132L12.4659 8.40966C13.0793 7.73772 12.822 7.18097 11.892 7.18097H9.85375V2.57337C9.85375 1.49827 9.2535 1.28068 8.52132 2.08701L3.52799 7.59053C2.92114 8.26887 3.17839 8.81923 4.10186 8.81923Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
