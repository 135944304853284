import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Notification: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.01339 2.43945C5.80673 2.43945 4.01339 4.23279 4.01339 6.43945V8.36612C4.01339 8.77279 3.84006 9.39279 3.63339 9.73945L2.86673 11.0128C2.39339 11.7995 2.72006 12.6728 3.58673 12.9661C6.46006 13.9261 9.56006 13.9261 12.4334 12.9661C13.2401 12.6995 13.5934 11.7461 13.1534 11.0128L12.3867 9.73945C12.1867 9.39279 12.0134 8.77279 12.0134 8.36612V6.43945C12.0134 4.23945 10.2134 2.43945 8.01339 2.43945Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M9.24694 2.63297C9.04027 2.57297 8.82694 2.5263 8.60694 2.49964C7.96694 2.41964 7.35361 2.4663 6.78027 2.63297C6.97361 2.13964 7.45361 1.79297 8.01361 1.79297C8.57361 1.79297 9.05361 2.13964 9.24694 2.63297Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0137 13.207C10.0137 14.307 9.11367 15.207 8.01367 15.207C7.46701 15.207 6.96034 14.9804 6.60034 14.6204C6.24034 14.2604 6.01367 13.7537 6.01367 13.207"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                />
            </svg>
        )}
    />
)
