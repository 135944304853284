import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const More: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9 15.5612C9 16.1914 9.51563 16.707 10.1458 16.707C10.776 16.707 11.2917 16.1914 11.2917 15.5612C11.2917 14.931 10.776 14.4154 10.1458 14.4154C9.51562 14.4154 9 14.931 9 15.5612Z"
                    fill="#434343"
                />
                <path
                    d="M9 4.86784C9 5.49805 9.51563 6.01367 10.1458 6.01367C10.776 6.01367 11.2917 5.49805 11.2917 4.86784C11.2917 4.23763 10.776 3.722 10.1458 3.722C9.51562 3.722 9 4.23763 9 4.86784Z"
                    fill="#434343"
                />
                <path
                    d="M9 10.2155C9 10.8457 9.51563 11.3613 10.1458 11.3613C10.776 11.3613 11.2917 10.8457 11.2917 10.2155C11.2917 9.58529 10.776 9.06966 10.1458 9.06966C9.51562 9.06966 9 9.58529 9 10.2155Z"
                    fill="#434343"
                />
            </svg>
        )}
    />
)
