import { useCallback, useState } from 'react'
import FileSaver from 'file-saver'
import { getSignatureService } from '../../services/user'
import { message } from 'antd'

const useDownloadSignedRequest = () => {
    const [loadingDownLoad, setLoadingDownLoad] = useState<boolean>(false)

    const handleClickDownload = async (requestId: string) => {
        try {
            if (!requestId) {
                return
            }

            setLoadingDownLoad(true)

            const [body, signature] = await Promise.all([
                getSignatureService(requestId, true, (error) => {
                    message.error('Ошибка скачивания запроса')
                    throw new Error('login error', error.message)
                }),
                getSignatureService(requestId, false, (error) => {
                    message.error('Ошибка скачивания подписи')
                    throw new Error('login error', error.message)
                }),
            ])

            const bodyBlob = new Blob([body?.data], { type: 'application/octet-stream' })
            const signatureBlob = new Blob([signature?.data], {
                type: 'application/octet-stream',
            })

            FileSaver.saveAs(bodyBlob, body?.headers.filename)
            FileSaver.saveAs(signatureBlob, signature?.headers.filename)
        } finally {
            setLoadingDownLoad(false)
        }
    }

    const downloadSignedRequest = useCallback((requestId: string) => {
        handleClickDownload(requestId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        downloadSignedRequest,
        loadingDownLoad,
    }
}

export default useDownloadSignedRequest
