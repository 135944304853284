import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const NewNotification: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.01242 2.43994C5.80575 2.43994 4.01242 4.23327 4.01242 6.43994V8.36661C4.01242 8.77327 3.83908 9.39327 3.63242 9.73994L2.86575 11.0133C2.39242 11.7999 2.71908 12.6733 3.58575 12.9666C6.45908 13.9266 9.55908 13.9266 12.4324 12.9666C13.2391 12.6999 13.5924 11.7466 13.1524 11.0133L12.3857 9.73994C12.1857 9.39327 12.0124 8.77327 12.0124 8.36661V6.43994C12.0124 4.23994 10.2124 2.43994 8.01242 2.43994Z"
                    stroke="#262626"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M9.24792 2.63346C9.04125 2.57346 8.82792 2.52679 8.60792 2.50012C7.96792 2.42012 7.35458 2.46679 6.78125 2.63346C6.97458 2.14012 7.45458 1.79346 8.01458 1.79346C8.57458 1.79346 9.05458 2.14012 9.24792 2.63346Z"
                    stroke="#262626"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0117 13.2065C10.0117 14.3065 9.11172 15.2065 8.01172 15.2065C7.46505 15.2065 6.95838 14.9799 6.59838 14.6199C6.23838 14.2599 6.01172 13.7532 6.01172 13.2065"
                    stroke="#262626"
                    strokeMiterlimit="10"
                />
                <circle cx="12.5" cy="4.5" r="2.5" fill="#FF4D4F" />
            </svg>
        )}
    />
)
