import { FC } from 'react'
import { Tag } from 'antd'
import { colorPicker2 } from '../../../helpers'
import style from './style.m.less'

interface IPowerTypeTag {
    value: number
    customStyle?: React.CSSProperties
}

const PowerTypeTag: FC<IPowerTypeTag> = ({ children, value, customStyle }) => {
    return (
        <Tag color={colorPicker2(value)} style={customStyle} className={style.typeTag}>
            {children}
        </Tag>
    )
}

export default PowerTypeTag
