import { Dispatch, FC, SetStateAction } from 'react'
import { Form, InputNumber } from 'antd'
import { DropdownSelector2, IconLink, PrimaryIconButton } from '../../ui'
import { AddCircle, Trash } from '../../../assets/icons'
import style from '../style.m.less'

interface IFuelSelect {
    name: string
    options: any
    setData: Dispatch<SetStateAction<{ fuel_id: number; volume: number }[]>>
}

export const FuelSelect: FC<IFuelSelect> = ({ name, options, setData }) => {
    const [form] = Form.useForm()
    return (
        <Form
            form={form}
            autoComplete="off"
            onValuesChange={() => setData(form.getFieldValue(name))}
            initialValues={{ [name]: [undefined] }}
        >
            <Form.List name={name}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <div className={style.grid}>
                                <div className={style.column}>
                                    <div className={style.formItem}>
                                        <div className={style.label}>Тип топлива</div>
                                        <Form.Item name={[field.name, 'fuel_id']} noStyle>
                                            <DropdownSelector2
                                                noDefaultValue
                                                options={options}
                                                className={style.select}
                                                placeholder="Выберите из списка"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={style.column}>
                                    <div className={style.formItem}>
                                        <div className={style.label}>Объем/масса</div>
                                        <Form.Item name={[field.name, 'volume']} noStyle>
                                            <InputNumber
                                                placeholder="Укажите объем топлива"
                                                className={style.inputNumber}
                                                precision={3}
                                                min={0.001}
                                                controls={false}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                {fields.length > 1 ? (
                                    <PrimaryIconButton
                                        isCancel
                                        icon={<Trash />}
                                        onClick={() => {
                                            remove(field.name)
                                        }}
                                    />
                                ) : null}
                            </div>
                        ))}
                        <div className={style.addButton}>
                            <IconLink
                                icon={<AddCircle />}
                                disabled={fields.length >= options.length}
                                onClick={() => add()}
                            >
                                Добавить источник энергии
                            </IconLink>
                        </div>
                    </>
                )}
            </Form.List>
        </Form>
    )
}
