import { FC, useMemo } from 'react'

import style from './style.m.less'
import { DropdownSelector2 } from '../../../ui'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../store/hooks'
import { selectDictionariesOptions } from '../../../../store/dictionaries'

export enum EGoFiledStatusType {
    All = 0,
    UnderRevision = 1,
    Canceled = 2,
}

const goStatusTypeOptions = [
    {
        id: EGoFiledStatusType.All,
        value: EGoFiledStatusType.All,
        text: 'Все',
    },
    {
        id: EGoFiledStatusType.UnderRevision,
        value: EGoFiledStatusType.UnderRevision,
        text: 'На проверке',
    },
    {
        id: EGoFiledStatusType.Canceled,
        value: EGoFiledStatusType.Canceled,
        text: 'Отказано',
    },
]

interface IFilter {
    onChangeEnergyType: (type: number) => void
    onChangeRegionType: (region: number) => void
    onChangeStatus: (status: EGoFiledStatusType) => void
}

const FilterSubmitted: FC<IFilter> = (props) => {
    const { t } = useTranslation()
    const { regionsOptions, renewableEnergyTypesOptions } =
        useAppSelector(selectDictionariesOptions) ?? {}

    const realRegionsOptions = useMemo(
        () =>
            regionsOptions
                ? [{ id: 0, value: 0, text: 'Любой', label: 'Любой' }, ...regionsOptions]
                : [{ id: 0, value: 0, text: 'Любой', label: 'Любой' }],
        [regionsOptions],
    )

    const realRenewableEnergyTypesOptions = useMemo(
        () =>
            renewableEnergyTypesOptions
                ? [{ id: 0, value: 0, text: 'Все', label: 'Все' }, ...renewableEnergyTypesOptions]
                : [{ id: 0, value: 0, text: 'Все', label: 'Все' }],
        [renewableEnergyTypesOptions],
    )

    return (
        <div className={style.controlsBlock}>
            <div className={style.leftGapBlock}>
                <div>Вид ВИЭ</div>
                <div className={style.inputBlock}>
                    <DropdownSelector2
                        onChange={(value) => {
                            props.onChangeEnergyType(value)
                        }}
                        customStyle={{ width: '364px' }}
                        options={realRenewableEnergyTypesOptions}
                    />
                </div>
            </div>
            <div className={style.leftGapBlock}>
                <div>Субъект РФ (регион)</div>
                <div className={style.inputBlock}>
                    <DropdownSelector2
                        customStyle={{ width: '364px' }}
                        options={realRegionsOptions}
                        onChange={props.onChangeRegionType}
                    />
                </div>
            </div>
            <div className={style.leftGapBlock}>
                <div>Статус </div>
                <div className={style.inputBlock}>
                    <DropdownSelector2
                        customStyle={{ width: '364px' }}
                        options={goStatusTypeOptions}
                        onChange={props.onChangeStatus}
                    />
                </div>
            </div>
        </div>
    )
}

export default FilterSubmitted
