import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Box } from '../../../ui'
import { Close, CloseCircle } from '../../../../assets/icons'
import style from './style.m.less'

interface IReasonModal {
    isModalVisible: boolean
    closeReasonModal: () => void
    reason?: string
}

const ReasonModal: FC<IReasonModal> = ({ isModalVisible, closeReasonModal, reason }) => {
    const { t } = useTranslation()

    return (
        <Modal isModalVisible={isModalVisible} bodyStyle={{ padding: '16px 24px' }}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <Box direction="row" align="center" gap={20}>
                        <div className={style.close}>
                            <CloseCircle size={20} />
                        </div>
                        <div className={style.header}>Причина блокировки объема АГ</div>
                    </Box>
                    <div className={style.closeButton} onClick={closeReasonModal}>
                        <Close />
                    </div>
                </div>
            </div>
            <div className={style.text}>{reason}</div>
        </Modal>
    )
}

export default ReasonModal
