// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_AT3E_ {\n  display: flex;\n  gap: 16px;\n  justify-content: space-between;\n  width: 100%;\n}\n.filterItem_e70ig {\n  flex: 1;\n}\n.label_J4i18 {\n  margin-bottom: 2px;\n}\n.input_yC_ph {\n  border-radius: 8px;\n  width: 296px;\n}\n.select_fPW14 {\n  width: 100%;\n}\n.datePicker_ALkzU {\n  height: 32px;\n  width: 100%;\n}\n.inputShort_o9ihA {\n  margin-top: 2px;\n  width: 144px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Registry/components/Filter/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,8BAAA;EACA,WAAA;AACJ;AAEA;EACI,OAAA;AAAJ;AAGA;EACI,kBAAA;AADJ;AAIA;EACI,kBAAA;EACA,YAAA;AAFJ;AAKA;EACI,WAAA;AAHJ;AAMA;EACI,YAAA;EACA,WAAA;AAJJ;AAOA;EACI,eAAA;EACA,YAAA;EACA,kBAAA;AALJ","sourcesContent":[".filter {\n    display: flex;\n    gap: 16px;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.filterItem {\n    flex: 1;\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.input {\n    border-radius: 8px;\n    width: 296px;\n}\n\n.select {\n    width: 100%;\n}\n\n.datePicker {\n    height: 32px;\n    width: 100%;\n}\n\n.inputShort {\n    margin-top: 2px;\n    width: 144px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "filter_AT3E_",
	"filterItem": "filterItem_e70ig",
	"label": "label_J4i18",
	"input": "input_yC_ph",
	"select": "select_fPW14",
	"datePicker": "datePicker_ALkzU",
	"inputShort": "inputShort_o9ihA"
};
export default ___CSS_LOADER_EXPORT___;
