// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tagFilter_Zbwl8 {\n  display: flex;\n  gap: 16px;\n  margin-top: 24px;\n}\n.clickable_dKxS0 {\n  cursor: pointer;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  border-radius: 8px;\n  padding: 4px 12px;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOSubmitDataKU/components/TagTabs/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,gBAAA;AACJ;AAEA;EACI,eAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,SAAA;AAAJ","sourcesContent":[".tagFilter {\n    display: flex;\n    gap: 16px;\n    margin-top: 24px;\n}\n\n.clickable {\n    cursor: pointer;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    border-radius: 8px;\n    padding: 4px 12px;\n    align-items: center;\n    justify-content: center;\n    height: 32px;\n    margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagFilter": "tagFilter_Zbwl8",
	"clickable": "clickable_dKxS0"
};
export default ___CSS_LOADER_EXPORT___;
