// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".certWrapper_UamFg {\n  margin-top: 16px;\n  width: 1172px;\n}\n.certTitle_nydeC {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n}\n.certNumber_fqwuX {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n  color: #296dff;\n}\n.certsTotal_Ww5pc {\n  color: #bfbfbf;\n}\n.iconDown_hRylN {\n  margin-top: -3px;\n}\n.iconDownRotated_Dxpom {\n  margin-bottom: -2px;\n  transform: rotate(180deg);\n}\n.wrapperBlock_zsD9K {\n  display: flex;\n  margin-top: 16px;\n}\n.leftPart_ibx5X {\n  width: 220px;\n  color: #8c8c8c;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Audit/components/DividedBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;AACJ;AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,cAAA;AAFJ;AAKA;EACI,gBAAA;AAHJ;AAKI;EACI,mBAAA;EACA,yBAAA;AAHR;AAOA;EACI,aAAA;EACA,gBAAA;AALJ;AAQA;EACI,YAAA;EACA,cAAA;AANJ","sourcesContent":[".certWrapper {\n    margin-top: 16px;\n    width: 1172px;\n}\n\n.certTitle {\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n}\n\n.certNumber {\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n    color: #296dff;\n}\n\n.certsTotal {\n    color: #bfbfbf;\n}\n\n.iconDown {\n    margin-top: -3px;\n\n    &Rotated {\n        margin-bottom: -2px;\n        transform: rotate(180deg);\n    }\n}\n\n.wrapperBlock {\n    display: flex;\n    margin-top: 16px;\n}\n\n.leftPart {\n    width: 220px;\n    color: #8c8c8c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"certWrapper": "certWrapper_UamFg",
	"certTitle": "certTitle_nydeC",
	"certNumber": "certNumber_fqwuX",
	"certsTotal": "certsTotal_Ww5pc",
	"iconDown": "iconDown_hRylN",
	"iconDownRotated": "iconDownRotated_Dxpom",
	"wrapperBlock": "wrapperBlock_zsD9K",
	"leftPart": "leftPart_ibx5X"
};
export default ___CSS_LOADER_EXPORT___;
