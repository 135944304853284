// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerWrapper_Ie2uu {\n  display: flex;\n  align-items: flex-end;\n}\n.loginHeader_cigR5 {\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.steps_R_IEL {\n  margin-left: 16px;\n  font-weight: 600;\n  font-size: 20px;\n  line-height: 26px;\n  color: #bfbfbf;\n}\n.formBlock_Omsji {\n  margin-top: 32px;\n}\n.responseError_R231I {\n  margin-top: 16px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Login/components/Register/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,gBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".headerWrapper {\n    display: flex;\n    align-items: flex-end;\n}\n\n.loginHeader {\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.steps {\n    margin-left: 16px;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 26px;\n    color: #bfbfbf;\n}\n\n.formBlock {\n    margin-top: 32px;\n}\n\n.responseError {\n    margin-top: 16px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": "headerWrapper_Ie2uu",
	"loginHeader": "loginHeader_cigR5",
	"steps": "steps_R_IEL",
	"formBlock": "formBlock_Omsji",
	"responseError": "responseError_R231I"
};
export default ___CSS_LOADER_EXPORT___;
