import { FC, useState } from 'react'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { certificateLogin } from '../../../../store/auth'
import SignUtils from '../../../../utils/sign/SignUtils'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

export type ICertificates = {
    algorithmName: string
    checkAlgorithmValue: boolean
    checkIsValid: boolean
    checkKeyUsage: boolean
    checkNotAfterDateTo: boolean
    checkNotBeforeDateFrom: boolean
    checkPrivateKey: boolean
    text: string
    thumbprint: string
}[]

type ICertificatesModal = {
    allCertificates: ICertificates
    clearCertificates: () => void
    setCertificate?: (certificate: any) => void
    type?: string
}

const CertificatesModal: FC<ICertificatesModal> = ({
    allCertificates,
    clearCertificates,
    setCertificate,
    type,
}) => {
    const { t } = useTranslation()
    const reduxDispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(true)

    const goToProfile = () => {
        // полная перезагрузка, а не перерендер, так как нужно обновить все креденшны и данные пользователя
        window.location.href = '/profile'
    }

    const handleOk = async (thumbprint: string) => {
        setIsModalVisible(false)
        clearCertificates()
        if (type === 'addCertificate') {
            const certificate = await SignUtils.exportCertificate(thumbprint)
            const certificateData = await SignUtils.getCertificateData(thumbprint)
            const textArr = certificateData.subjectName.split(', ')
            const name = `${textArr[1]?.split('SN=')[1]} ${textArr[0]?.split('CN=')[1]}`
            setCertificate({ certificate, thumbprint, name })
        } else {
            reduxDispatch(certificateLogin({ thumbprint, goToProfile }))
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        clearCertificates()
    }

    return (
        <Modal
            bodyStyle={{
                boxShadow: '0px 4px 18px rgba(10, 19, 52, 0.15)',
                borderRadius: '24px',
                padding: '32px',
                width: '680px',
            }}
            closable={false}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            centered
        >
            <div className={style.certificateList}>
                <div className={style.modalHeader}>{t('login.chooseElSign')}</div>
                {allCertificates?.map((item, index) => {
                    const { text, thumbprint } = item
                    const textArr = text.split('; ')
                    const name = textArr[0].split('CN=')[1]
                    const issued = textArr[1].split(`${t('login.given')}: `)[1]
                    return (
                        <div
                            className={index === 0 ? style.certificateFirst : style.certificate}
                            key={thumbprint}
                            onClick={() => handleOk(thumbprint)}
                        >
                            <div className={style.certificateHeader}>{name}</div>
                            <div className={style.certificateDate}>{`${t(
                                'login.given',
                            )} ${issued}`}</div>
                        </div>
                    )
                })}
            </div>
        </Modal>
    )
}

export default CertificatesModal
