import { FC, useEffect, useState } from 'react'
import { Select, SelectProps } from 'antd'
import { DownMini } from '../../../assets/icons'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

import style from './style.m.less'

const { Option } = Select

export type DropdownSelectorOption = {
    value: string | number | boolean | null
    id: string | number | boolean | null
    disabled?: boolean
    text?: string
    description?: string
}

type DropdownSelector2Props = {
    options: DropdownSelectorOption[]
    customStyle?: React.CSSProperties
    onChange?: (value: any) => void
    value?: any
    placeholder?: string
    className?: string
    defaultValue?: string
    size?: SizeType
    disabled?: boolean
    noDefaultValue?: boolean
    allowClear?: boolean
    isMultiple?: boolean
} & SelectProps

const DropdownSelector2: FC<DropdownSelector2Props> = ({
    options,
    customStyle,
    placeholder,
    onChange,
    className,
    defaultValue,
    value,
    size,
    disabled,
    noDefaultValue,
    allowClear,
    isMultiple,
    searchValue,
    showSearch = true,
    ...rest
}) => {
    const [searchValueInternal, setSeachValueInternal] = useState<string | undefined>(searchValue)

    useEffect(() => setSeachValueInternal(searchValue), [searchValue])

    const selectDefaultValue = () => {
        if (noDefaultValue) {
            return undefined
        } else if (defaultValue) {
            return defaultValue
        } else if (options) {
            return options[0]?.value
        } else {
            return undefined
        }
    }

    return (
        <Select
            suffixIcon={
                <div style={{ marginTop: '4px', marginRight: '3px' }}>
                    <DownMini />
                </div>
            }
            mode={isMultiple ? 'multiple' : undefined}
            onChange={onChange ? (e) => onChange(e) : undefined}
            placeholder={placeholder}
            defaultValue={selectDefaultValue}
            style={customStyle}
            className={className}
            size={size}
            disabled={disabled}
            allowClear={allowClear}
            value={value}
            searchValue={searchValueInternal}
            onSearch={setSeachValueInternal}
            showSearch={showSearch}
            optionFilterProp="children"
            {...rest}
        >
            {(options || []).map((item) => (
                <Option
                    key={item.id as string}
                    value={item.value}
                    disabled={item.disabled}
                    title={item.text || item.value}
                    label={item.value}
                >
                    {item.text || item.value}
                    {item.description && (
                        <div className={style.description}>{item.description}</div>
                    )}
                </Option>
            ))}
        </Select>
    )
}

export default DropdownSelector2
