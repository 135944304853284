import { ConfigProvider, Table } from 'antd'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import BulletDot from '../../../../../ui/BulletDot/BulletDot'
import style from './style.m.less'
import { renderDayMonthYear } from '../../../../../../utils/formatter'
import { currencyFormat } from '../../../../../helpers'

type PaginationType = {
    page: number
    pageSize: number
    filters?: any
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

const COLUMNS = () => [
    {
        title: '',
        dataIndex: 'is_read',
        key: 'is_read',
        width: 20,
        render: (is_read: boolean) => (is_read ? <></> : <BulletDot color="#528EFF" />),
    },
    {
        title: 'Дата заведения в систему',
        dataIndex: 'ts_created',
        key: 'ts_created',
        render: (date: Date) => renderDayMonthYear(date),
    },
    {
        title: 'Количество инструментов',
        dataIndex: 'count',
        key: 'count',
    },
    {
        title: 'Общий объем, кВт*ч',
        dataIndex: 'volume',
        key: 'volume',
        render: (volume: number) => currencyFormat(volume),
    },
]

interface IDefault {
    data: {
        ext_instruments: { count: number; is_read: false; ts_created: string; volume: number }[]
        total: number
    }
}

const Default: FC<IDefault> = ({ data }) => {
    const navigate = useNavigate()
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)

    return (
        <div className={style.opened}>
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <Table
                        dataSource={data?.ext_instruments || []}
                        columns={COLUMNS()}
                        rowClassName={(record) => (!record.is_read ? style.rowBold : '')}
                        pagination={{
                            position: ['bottomLeft'],
                            onChange: (page, pageSize) => {
                                setPagination({ page, pageSize })
                            },
                            total: data?.total,
                            current: pagination.page,
                            pageSize: pagination.pageSize,
                            showSizeChanger: true,
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                navigate('/third-party-tools-approval', {
                                    state: { record },
                                })
                            },
                        })}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Default
