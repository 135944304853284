import BackLink from '../../ui/BackTitle/BackTitle'
import PageContainer from '../../ui/PageElements/PageContainer/PageContainer'
import { FC, useState } from 'react'
import Card from '../../ui/Card/Card'
import { Divider, Form, Input, Select } from 'antd'
import { CommonBlock } from '../../Edit/components'
import useRequest from '../../hooks/useRequest'
import { getDictHelpdesk, sendAppealCfg } from '../config'
import useMount from '../../hooks/useMount'
import { DocumentsBlock, PrimaryButton } from '../../ui'
import { IFile } from '../../ui/DocumentsList/DocumentsList'
import { useSelector } from 'react-redux'
import IAppState from 'store/states'
import SignUtils from '../../../utils/sign/SignUtils'
import { Box } from '../../ui/Box'
import { encode, btoa } from 'js-base64'
import { hexToBinary } from '../../helpers'
import { v4 as uuidv4 } from 'uuid'
import { useForm } from 'antd/es/form/Form'
import { useNavigate } from 'react-router-dom'

export const CreateAppeal: FC = () => {
    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo
    const [form] = useForm()
    const navigate = useNavigate()
    const { fetch, result, isLoading } = useRequest(getDictHelpdesk)
    const { fetch: sendAppeal } = useRequest(sendAppealCfg)
    const [files, setFiles] = useState<IFile[]>([])
    const [currentCertificate, setCurrentCertificate] = useState<any | null>(null)

    const getTP = async () => {
        const thumbprint = await SignUtils.getCertificateThumbprint(
            data.certificate_data.certificate,
        )
        setCurrentCertificate({ ...data.certificate_data, thumbprint })
    }

    useMount(() => {
        fetch({})
        getTP()
    })

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const onFinish = async (values: any) => {
        const body = values
        if (files.length) {
            body.documents = files.map((el: any) => ({ id: el.id, hash: el.hash }))
        }

        if (data.certificate_data) {
            await getTP()
        }
        const request_id = uuidv4()
        const encodedInfo = encode(JSON.stringify(body))
        const encoded = request_id + encodedInfo
        const preSignature = await SignUtils.SignCreate(currentCertificate.thumbprint, encoded)
        const signature = btoa(hexToBinary(preSignature))

        sendAppeal({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            successMessage: {
                title: '',
                description: 'Заявка успешно сформирована',
            },
            onSuccess: () => navigate('/my-appeals'),
        })
    }

    const handleConfirm = () => {
        form.submit()
    }

    const options =
        (result || [])
            .filter((el: any) => !el.ts_deleted)
            .map((el: any) => ({ label: el.topic, value: el.id })) || []

    return (
        <PageContainer>
            <BackLink title="Создать заявку" link="/my-appeals" />
            <Card>
                <Form onFinish={onFinish} form={form} name="createAppeal">
                    <CommonBlock
                        required
                        margin
                        label="Тема заявки"
                        select={
                            <Form.Item
                                name="topic_id"
                                style={{ marginLeft: '16px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Не указана тема заявки',
                                    },
                                ]}
                            >
                                <Select
                                    options={options}
                                    loading={isLoading}
                                    placeholder="Выберите тему заявки"
                                    style={{ width: '400px' }}
                                />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        required
                        label="Текст заявки"
                        margin
                        customStyle={{ display: 'flex', alignItems: 'flex-start' }}
                        select={
                            <Form.Item
                                name="message"
                                style={{ marginLeft: '16px' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Не указан текст заявки',
                                    },
                                    { max: 500, message: 'Введено больше 500 символов' },
                                ]}
                            >
                                <Input.TextArea
                                    placeholder="Введите запрос"
                                    style={{
                                        width: '608px',
                                        borderRadius: '8px',
                                        minHeight: '100px',
                                    }}
                                />
                            </Form.Item>
                        }
                    />
                </Form>
                <Divider />
                <Box flex={'row'} width={608} margin={'0px 210px'}>
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={5}
                    />
                </Box>
                <Divider />
                <div style={{ display: 'flex' }}>
                    <PrimaryButton
                        onClick={() => navigate('/my-appeals')}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px', width: 'auto' }}
                    />
                    <PrimaryButton
                        onClick={handleConfirm}
                        value="Подписать и отправить"
                        customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                    />
                </div>
            </Card>
        </PageContainer>
    )
}
