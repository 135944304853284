import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, Input } from 'antd'
import style from './style.m.less'
import TextArea from 'antd/lib/input/TextArea'

const FivePartsBlock: FC = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className={style.inputsWrapperShort}>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.series')}
                        <div className={style.star}>*</div>
                    </div>
                    <Input className={style.inputSmall} />
                </div>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.number')}
                        <div className={style.star}>*</div>
                    </div>
                    <Input className={style.inputSmall} />
                </div>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.issueDate')}
                        <div className={style.star}>*</div>
                    </div>
                    <DatePicker className={style.datePicker} />
                </div>
                <div>
                    <div className={style.inputBlock}>
                        {t('editMyProfile.divisionCode')}
                        <div className={style.star}>*</div>
                    </div>
                    <Input className={style.inputMiddle} />
                </div>
            </div>
            <div className={style.textAreaBlock}>
                <div className={style.inputBlock}>
                    {t('editMyProfile.whomIssued')}
                    <div className={style.star}>*</div>
                </div>
                <TextArea className={style.textArea} />
            </div>
        </>
    )
}

export default FivePartsBlock
