import { FC, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PageContainer from '../ui/PageElements/PageContainer/PageContainer'
import Card from '../ui/Card/Card'
import { Alert, Divider, Form, InputNumber, message, Typography } from 'antd'
import { PrimaryButton, CenteredSpin, DropdownSelector2 } from '../ui'
import PageTitle from '../ui/PageElements/PageTitle/PageTitle'
import { Box } from '../ui/Box'
import useRequest from '../hooks/useRequest'
import moment from 'moment'
import { encode, btoa } from 'js-base64'
import { hexToBinary, currencyFormat } from '../helpers'
import { getGeneratorsPeriods, getAccounts, issueCertificate, getListGO } from './config'
import { v4 as uuidv4 } from 'uuid'
import IAppState from 'store/states'
import SignUtils from '../../utils/sign/SignUtils'
import style from './style.m.less'
import useMount from '../hooks/useMount'
import { useGetTariffRate } from '../hooks'
import capitalize from 'lodash/capitalize'

export const IssueCertificate: FC = () => {
    const { t } = useTranslation()
    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo

    const [currentGenerator, setCurrentGenerator] = useState(null)
    const [currentAccount, setCurrentAccount] = useState(null)
    const [currentPeriod, setCurrentPeriod] = useState<string | null>(null)
    const [currentPeriodVolume, setCurrentPeriodVolume] = useState<number>()
    const [disableSend, setDisableSend] = useState(false)
    const [volume, setVolume] = useState()
    const {
        fetch: fetchGenerators,
        result: userGenerators,
        isLoading: isLoadingGenerators,
    } = useRequest(getListGO)
    const {
        fetch: fetchPeriods,
        result: generatorsPeriods,
        isLoading: isLoadingPeriods,
    } = useRequest(getGeneratorsPeriods)
    const {
        fetch: fetchAccounts,
        result: accounts,
        isLoading: isLoadingAccounts,
    } = useRequest(getAccounts)
    const { fetch: sendCertificate } = useRequest(issueCertificate)

    const [form] = Form.useForm()
    const navigate = useNavigate()

    useMount(() => {
        fetchGenerators({
            errorCodeMessage: true,
        })
        fetchAccounts({
            errorCodeMessage: true,
        })
    })

    useEffect(() => {
        if (!userGenerators?.gos_ga) {
            return
        }
        setCurrentGenerator(userGenerators?.gos_ga[0])
    }, [userGenerators])

    useEffect(() => {
        if (!accounts?.accounts) {
            return
        }
        setCurrentAccount(accounts?.accounts[0].id)
    }, [accounts])

    useEffect(() => {
        if (currentGenerator) {
            fetchPeriods({ addToLink: currentGenerator?.generator_id })
        }
    }, [currentGenerator])

    useEffect(() => {
        if (!generatorsPeriods) {
            return
        }
        const firstPeriod = generatorsPeriods?.list?.[0]
        if (firstPeriod) {
            const dataMoment = moment(firstPeriod.period, 'YYYY-MM-DD')
            const month = dataMoment.format('M')
            const year = dataMoment.format('YYYY')
            setCurrentPeriod(`${month},${year},${moment(firstPeriod.expires_at).format()}`)
            // setCurrentPeriod(`${moment(firstPeriod.period, 'YYYY-MM-DD')}`)
            setCurrentPeriodVolume(firstPeriod.volume)
        }
    }, [generatorsPeriods])

    const onSuccess = ({
        certificate_id,
        certificate_number,
    }: {
        certificate_id: string
        certificate_number: string
    }) => {
        navigate(`/user-certificate-card/${certificate_number}`, {
            state: {
                toolType: 'Сертификаты происхождения',
                cardLabel: 'Сертификат происхождения',
                isUserType: true,
                id: certificate_id,
                isIssuedCert: true,
                isOwner: true,
            },
        })
    }

    const { balance, rate } = useGetTariffRate({
        id: 15,
        volume,
        dependency: volume,
    })

    if (
        isLoadingGenerators ||
        userGenerators === undefined ||
        isLoadingAccounts ||
        accounts === undefined ||
        isLoadingPeriods
    ) {
        return <CenteredSpin />
    }

    const onFinish = async (values: any) => {
        setDisableSend(true)
        const dateArr = currentPeriod.split(',')
        const body = {
            generator_id: currentGenerator?.generator_id,
            account_id: currentAccount,
            month: Number(dateArr[0]),
            year: Number(dateArr[1]),
            capacity: Number(values.capacity),
        }
        const request_id = uuidv4()
        const thumbprint = await SignUtils.getCertificateThumbprint(
            data.certificate_data.certificate,
        )
        const encodedInfo = encode(JSON.stringify(body))
        const encoded = request_id + encodedInfo
        const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
        const signature = btoa(hexToBinary(preSignature))

        sendCertificate({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            onSuccess,
            errorCodeMessage: true,
        })
        setDisableSend(false)
    }

    return (
        <PageContainer>
            <PageTitle title={'Выпуск сертификата'} />
            <Card>
                <Form form={form} onFinish={onFinish} name="issueCertificate" layout="vertical">
                    <Form.Item name="generatorName" className={style.dropdownWrapper} label="ГО">
                        <DropdownSelector2
                            placeholder={'Введите название ГО или выберите из списка'}
                            customStyle={{ width: '400px' }}
                            options={userGenerators?.gos_ga?.map((item: any) => {
                                return {
                                    text: item.name,
                                    value: item.generator_id,
                                    id: item.generator_id,
                                }
                            })}
                            onChange={(value) =>
                                setCurrentGenerator(
                                    userGenerators?.gos_ga?.find(
                                        (item: any) => item.generator_id === value,
                                    ),
                                )
                            }
                            value={currentGenerator}
                        />
                    </Form.Item>
                    {userGenerators?.gos_ga && (
                        <Box style={{ marginTop: '16px' }}>
                            <div className={style.attributesTitle}>
                                Свободные атрибуты генерации
                            </div>
                            <div>{`${currencyFormat(currentGenerator?.ga_available)} кВт*ч`}</div>
                            {/* <div>{`${responseTotalAG?.free_volume} кВт*ч`}</div> */}
                        </Box>
                    )}
                    <Divider />
                    <Form.Item name="period" label="Период производства АГ">
                        <DropdownSelector2
                            placeholder="Выберите период производства"
                            customStyle={{ width: '400px' }}
                            options={(generatorsPeriods?.list || []).map(
                                (item: any, index: number) => {
                                    const dataMoment = moment(item.period, 'YYYY-MM-DD')
                                    return {
                                        text: `${capitalize(
                                            dataMoment.format('MMMM, YYYY'),
                                        )} (${currencyFormat(item.volume)} кВт*ч)`,
                                        // value: `${item.month},${item.year},${item.ts_end}`,
                                        value: index,
                                        id: index,
                                    }
                                },
                            )}
                            onChange={(idx) => {
                                const selectedPeriod = generatorsPeriods?.list[idx]
                                const dataMoment = moment(selectedPeriod.period, 'YYYY-MM-DD')
                                const period = `${dataMoment.format('M,YYYY')},${moment(
                                    selectedPeriod.expires_at,
                                ).format()}`
                                setCurrentPeriod(period)
                                setCurrentPeriodVolume(selectedPeriod.volume)
                                form.validateFields(['capacity'])
                            }}
                            value={currentPeriod}
                            disabled={!userGenerators?.gos_ga}
                        />
                    </Form.Item>
                    {currentPeriod && (
                        <Alert
                            message={`Срок действия АГ будет завершен ${moment(
                                currentPeriod.split(',')[2],
                            ).format('DD.MM.YYYY')}`}
                            className={style.alert}
                            type="info"
                            showIcon
                        />
                    )}
                    <Form.Item
                        name="capacity"
                        className={style.dropdownWrapper}
                        validateFirst={true}
                        rules={[
                            {
                                required: true,
                                message: 'Введите номинал',
                            },
                            () => ({
                                validator(_, value) {
                                    if (value > currentPeriodVolume) {
                                        return Promise.reject(
                                            new Error(
                                                `Номинал не должен превышать свободные АГ: ${currencyFormat(
                                                    Number(currentPeriodVolume),
                                                )}  кВт*ч`,
                                            ),
                                        )
                                    }

                                    if (Number(value) <= 0) {
                                        return Promise.reject(new Error('Введите номинал'))
                                    }

                                    return Promise.resolve()
                                },
                            }),
                        ]}
                        required={true}
                        label="Номинал, кВт*ч"
                    >
                        <InputNumber
                            placeholder="Введите номинал"
                            className={style.input}
                            value={volume}
                            onChange={(value) => setVolume(value)}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            controls={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="account"
                        label="Лицевой счет"
                        className={style.dropdownWrapper}
                    >
                        <DropdownSelector2
                            placeholder="Выберите лицевой счет"
                            customStyle={{ width: '400px' }}
                            options={accounts?.accounts?.map((item: any) => {
                                return {
                                    text: `№${item.number} (${item.name})`,
                                    value: item.id,
                                    id: item.id,
                                }
                            })}
                            onChange={(value) => {
                                setCurrentAccount(value)
                            }}
                            value={currentAccount}
                        />
                    </Form.Item>
                </Form>
                <Alert
                    className={style.modalAlert}
                    message={
                        <div className={style.modalAlertText}>
                            <div>
                                За операцию взымается оплата в размере{' '}
                                <Typography.Text strong>{rate}</Typography.Text>
                            </div>
                            <div>Баланс платежного счета: {balance}</div>
                        </div>
                    }
                    type="warning"
                    showIcon
                />
                <Divider />
                <Box direction="row" justify="flex-start" margin="20px 0px 0px 0px">
                    <PrimaryButton
                        onClick={() => navigate(-1)}
                        value={'Отмена'}
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryButton
                        onClick={() => {
                            if (Number(form.getFieldValue('capacity')) <= 0) {
                                form.scrollToField('capacity')
                                form.validateFields(['capacity'])

                                return
                            }

                            form.submit()
                        }}
                        disabled={!accounts?.accounts || disableSend}
                        value={'Подписать и выпустить'}
                        customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                    />
                </Box>
            </Card>
        </PageContainer>
    )
}

export default IssueCertificate
