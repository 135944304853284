import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DocIcon, PdfIcon, XlsIcon } from '../../../../assets/icons'

import style from './style.m.less'

const Documents: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.documentsGrid}>
            <div className={style.file}>
                <PdfIcon />
                <div className={style.fileInfo}>
                    <div className={style.fileTitle}>
                        {t('questionnaireApprovalItem.charterAssociation')}
                    </div>
                    <div className={style.fileSize}>183 {t('questionnaireApprovalItem.mb')}</div>
                </div>
            </div>
            <div className={style.documentsWrapper}>
                <div className={style.file}>
                    <DocIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>
                            {t('questionnaireApprovalItem.charterAssociation')}
                        </div>
                        <div className={style.fileSize}>
                            146 {t('questionnaireApprovalItem.kb')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Documents
