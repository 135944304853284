// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerMain_CLmCX {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  width: 100%;\n  height: 430px;\n  padding: 48px 250px 40px 280px;\n  background: #161c23;\n  font-family: 'SF Pro Display';\n  color: #fff;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n}\n.footerTop_HfdnK {\n  display: flex;\n  justify-content: space-between;\n  height: 257px;\n  width: 70%;\n  margin-top: 8px;\n}\n.footerBottom_mlC9g {\n  display: flex;\n  color: #8c8c8c;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,6BAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,aAAA;EACA,UAAA;EACA,eAAA;AAAJ;AAGA;EACI,aAAA;EACA,cAAA;EACA,uBAAA;AADJ","sourcesContent":[".footerMain {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    width: 100%;\n    height: 430px;\n    padding: 48px 250px 40px 280px;\n    background: #161c23;\n    font-family: 'SF Pro Display';\n    color: #fff;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n}\n\n.footerTop {\n    display: flex;\n    justify-content: space-between;\n    height: 257px;\n    width: 70%;\n    margin-top: 8px;\n}\n\n.footerBottom {\n    display: flex;\n    color: #8c8c8c;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerMain": "footerMain_CLmCX",
	"footerTop": "footerTop_HfdnK",
	"footerBottom": "footerBottom_mlC9g"
};
export default ___CSS_LOADER_EXPORT___;
