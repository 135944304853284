// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputsWrapperShort_fiKz9 {\n  width: 608px;\n  margin-top: 16px;\n  margin-left: 216px;\n  display: flex;\n  justify-content: space-between;\n}\n.star_mA2aG {\n  margin-left: 4px;\n  display: flex;\n  align-items: center;\n  color: #f5222d;\n}\n.inputBlock_jrqUu {\n  display: flex;\n}\n.inputMiddle_yqHys {\n  width: 192px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EditMyProfile/components/ThreePartsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;AACJ;AAEA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;AADJ;AAIA;EACI,YAAA;EACA,kBAAA;AAFJ","sourcesContent":[".inputsWrapperShort {\n    width: 608px;\n    margin-top: 16px;\n    margin-left: 216px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.star {\n    margin-left: 4px;\n    display: flex;\n    align-items: center;\n    color: #f5222d;\n}\n\n.inputBlock {\n    display: flex;\n}\n\n.inputMiddle {\n    width: 192px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsWrapperShort": "inputsWrapperShort_fiKz9",
	"star": "star_mA2aG",
	"inputBlock": "inputBlock_jrqUu",
	"inputMiddle": "inputMiddle_yqHys"
};
export default ___CSS_LOADER_EXPORT___;
