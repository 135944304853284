import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const User: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.9987 8.49984C9.47146 8.49984 10.6654 7.30593 10.6654 5.83317C10.6654 4.36041 9.47146 3.1665 7.9987 3.1665C6.52594 3.1665 5.33203 4.36041 5.33203 5.83317C5.33203 7.30593 6.52594 8.49984 7.9987 8.49984Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5806 13.8334C12.5806 11.7694 10.5273 10.1001 7.9993 10.1001C5.4713 10.1001 3.41797 11.7694 3.41797 13.8334"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
