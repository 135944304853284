import { FC, useEffect, useState } from 'react'
import { message } from 'antd'
import { SuccessMessage } from '../../../Edit/components'
import {
    DatePicker,
    Modal,
    Alert,
    PrimaryButton,
    PrimaryIconButton,
    CenteredSpin,
} from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import SignUtils from '../../../../utils/sign/SignUtils'
import { encode, btoa } from 'js-base64'
import request from '../../../../services/request'
import { hexToBinary } from '../../../helpers'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { EUserRole } from '../../../../constants/approval'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { getUserInfo } from '../../../../store/user'

interface IDeregisterModal {
    isModalVisible: boolean
    goName?: string
    onClose: () => void
    onReload: () => void
}

const DeregisterModal: FC<IDeregisterModal> = ({ isModalVisible, goName, onClose, onReload }) => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()

    const [deregisterDate, setDeregisterDate] = useState<moment.Moment>()

    const isUserLoading = useAppSelector((state) => state.user.isLoading)

    const userData = useAppSelector((state) => state.user.data)
    const userRole = userData.user_role
    const isGOOwner = userRole === EUserRole.GOOwner

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(getUserInfo())
    }, [])

    const handleConfirm = async () => {
        if (!deregisterDate) {
            return
        }
        const params = {
            generator_id: id,
            ts_deregister_add: deregisterDate.startOf('day'),
        }
        if (isGOOwner) {
            const requestId = uuidv4()
            const thumbprint = await SignUtils.getCertificateThumbprint(
                userData.certificate_data.certificate,
            )
            const encodedInfo = encode(JSON.stringify(params))
            const encoded = requestId + encodedInfo
            const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
            const Signature = btoa(hexToBinary(preSignature))
            await request.post(
                `/api/generators/v1/go-deregister-add?request_id=${requestId}`,
                params,
                {
                    headers: {
                        Signature,
                    },
                },
            )
        } else {
            await request.post('/api/generators/v1/go-deregister-add', params)
        }
        onClose()
        message.success({
            content: (
                <SuccessMessage
                    header={t('detailedCard.success')}
                    message={`${t('detailedCard.withdrawn')} ${moment(deregisterDate).format(
                        'DD.MM.YYYY',
                    )}`}
                />
            ),
            icon: <></>,
        })
        onReload()
    }

    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            {isUserLoading && <CenteredSpin />}
            <div className={style.modalHeader}>{t('detailedCard.withdraw')}</div>

            <div className={style.dataBlock}>
                <div>Вы действительно хотите снять ГО {goName} с учета?</div>
            </div>

            <div className={style.dataBlock}>
                <div>Дата снятия с учета</div>
                <DatePicker
                    onChange={setDeregisterDate}
                    customStyle={{ width: '436px', height: '32px', marginTop: '2px' }}
                    format="DD.MM.YYYY"
                    disabledDate={(date) => !date.isAfter(moment())}
                />
            </div>
            <Alert
                message={
                    <div>
                        После снятия с учета Генерирующий объект не сможет фиксировать атрибуты
                        генерации. Ранее зафиксированные атрибуты генерации будет доступны, пока у
                        них не закончится срок действия.
                    </div>
                }
                className={style.alert}
                type="warning"
                showIcon
            />
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    onClick={onClose}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryIconButton
                    size="large"
                    disabled={!deregisterDate}
                    onClick={handleConfirm}
                    value={isGOOwner ? t('detailedCard.confirmAndSign') : t('detailedCard.confirm')}
                    customStyle={{ padding: '8px 16px', marginLeft: '12px' }}
                />
            </div>
        </Modal>
    )
}

export default DeregisterModal
