// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editMain_FcL7H {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 80px 100px 80px;\n  width: 1330px;\n  margin: 0 auto;\n}\n.editFrame_Boe22 {\n  padding: 24px 32px;\n  background: #fff;\n  box-shadow: 0 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.controlsBlock_XWjLq {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n.dateBlock_OBsVm {\n  margin-left: 16px;\n}\n.headerWrapper_BPUlY {\n  display: flex;\n  justify-content: space-between;\n}\n.tabs__Urx6 {\n  margin-top: 32px;\n  font-weight: 600;\n  color: #595959;\n}\n.inputShort_buGiK {\n  width: 144px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/pages/AllOperations/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,aAAA;EACA,cAAA;AACJ;AAEA;EACI,kBAAA;EACA,gBAAA;EACA,4CAAA;EACA,mBAAA;AAAJ;AAGA;EACI,aAAA;EACA,8BAAA;EACA,qBAAA;AADJ;AAIA;EACI,iBAAA;AAFJ;AAKA;EACI,aAAA;EACA,8BAAA;AAHJ;AAMA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AAJJ;AAOA;EACI,YAAA;EACA,kBAAA;AALJ","sourcesContent":[".editMain {\n    display: flex;\n    flex-direction: column;\n    padding: 40px 80px 100px 80px;\n    width: 1330px;\n    margin: 0 auto;\n}\n\n.editFrame {\n    padding: 24px 32px;\n    background: #fff;\n    box-shadow: 0 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.controlsBlock {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n}\n\n.dateBlock {\n    margin-left: 16px;\n}\n\n.headerWrapper {\n    display: flex;\n    justify-content: space-between;\n}\n\n.tabs {\n    margin-top: 32px;\n    font-weight: 600;\n    color: #595959;\n}\n\n.inputShort {\n    width: 144px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editMain": "editMain_FcL7H",
	"editFrame": "editFrame_Boe22",
	"controlsBlock": "controlsBlock_XWjLq",
	"dateBlock": "dateBlock_OBsVm",
	"headerWrapper": "headerWrapper_BPUlY",
	"tabs": "tabs__Urx6",
	"inputShort": "inputShort_buGiK"
};
export default ___CSS_LOADER_EXPORT___;
