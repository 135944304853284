// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_w_GyA {\n  display: flex;\n  flex-direction: column;\n  width: 1280px;\n  padding: 40px 80px 100px 0;\n  margin: 0 auto;\n}\n.header_OP3NS {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  font-feature-settings: 'lnum';\n}\n.content_J1oDl {\n  margin-top: 32px;\n  padding: 24px 32px;\n  background: #FFFFFF;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.infoBlock_xqXis {\n  width: 400px;\n}\n.buttonWrapper_DQn1i {\n  display: flex;\n  gap: 16px;\n}\n.alert_F_jpa {\n  margin-top: 16px;\n}\n.input_p4XxT {\n  border-radius: 8px;\n  margin-top: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/CertificateValidityPeriod/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,0BAAA;EACA,cAAA;AACJ;AAEA;EACI,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;AAAJ;AAGA;EACI,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,8CAAA;EACA,mBAAA;AADJ;AAIA;EACI,YAAA;AAFJ;AAKA;EACI,aAAA;EACA,SAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAOA;EACI,kBAAA;EACA,eAAA;AALJ","sourcesContent":[".main {\n    display: flex;\n    flex-direction: column;\n    width: 1280px;\n    padding: 40px 80px 100px 0;\n    margin: 0 auto;\n}\n\n.header {\n    font-family: 'Raleway';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    font-feature-settings: 'lnum';\n}\n\n.content {\n    margin-top: 32px;\n    padding: 24px 32px;\n    background: #FFFFFF;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.infoBlock {\n    width: 400px;\n}\n\n.buttonWrapper {\n    display: flex;\n    gap: 16px;\n}\n\n.alert {\n    margin-top: 16px;\n}\n\n.input {\n    border-radius: 8px;\n    margin-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "main_w_GyA",
	"header": "header_OP3NS",
	"content": "content_J1oDl",
	"infoBlock": "infoBlock_xqXis",
	"buttonWrapper": "buttonWrapper_DQn1i",
	"alert": "alert_F_jpa",
	"input": "input_p4XxT"
};
export default ___CSS_LOADER_EXPORT___;
