// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".attributesTitle_iN8O2 {\n  font-size: 12px;\n  line-height: 16px;\n  color: #868991;\n}\n.alert_G96o_ {\n  margin-top: 16px;\n  width: 400px;\n  border-radius: 12px;\n  border: 1px solid #bae7ff;\n}\n.dropdownWrapper_KiF7l {\n  margin-top: 16px;\n}\n.input_Lk0Fm {\n  width: 400px;\n  border-radius: 8px;\n}\n.modalAlert_Klrii {\n  width: 400px;\n  display: flex;\n  margin-top: 24px;\n  align-items: flex-start;\n  padding: 8px 24px 8px 16px;\n  background: #fffbe6;\n  border: 1px solid #fff1b8;\n  border-radius: 12px;\n}\n.modalAlertText_H2QXu {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/IssueCertificate/style.m.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEA;EACI,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,YAAA;EACA,kBAAA;AAFJ;AAKA;EACI,YAAA;EACA,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,0BAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAHJ;AAMA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAJJ","sourcesContent":[".attributesTitle {\n    font-size: 12px;\n    line-height: 16px;\n    color: #868991;\n}\n\n.alert {\n    margin-top: 16px;\n    width: 400px;\n    border-radius: 12px;\n    border: 1px solid #bae7ff;\n}\n\n.dropdownWrapper {\n    margin-top: 16px;\n}\n\n.input {\n    width: 400px;\n    border-radius: 8px;\n}\n\n.modalAlert {\n    width: 400px;\n    display: flex;\n    margin-top: 24px;\n    align-items: flex-start;\n    padding: 8px 24px 8px 16px;\n    background: #fffbe6;\n    border: 1px solid #fff1b8;\n    border-radius: 12px;\n}\n\n.modalAlertText {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attributesTitle": "attributesTitle_iN8O2",
	"alert": "alert_G96o_",
	"dropdownWrapper": "dropdownWrapper_KiF7l",
	"input": "input_Lk0Fm",
	"modalAlert": "modalAlert_Klrii",
	"modalAlertText": "modalAlertText_H2QXu"
};
export default ___CSS_LOADER_EXPORT___;
