// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_liqQD {\n  width: 1300px;\n  padding: 40px 10px 100px;\n  margin: 0 auto;\n}\n.tabs_fX2lP {\n  margin-top: 32px;\n  font-weight: 600;\n  color: #595959;\n}\n.content_Bw06Y {\n  margin-top: 32px;\n}\n.title_fCp_8 {\n  font-family: 'Raleway';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  font-feature-settings: 'lnum';\n  color: #262626;\n  letter-spacing: 0.5px;\n}\n.fullNameRow_yJBnu {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.popover_gdpIY {\n  color: #ffffff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Users/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,wBAAA;EACA,cAAA;AACJ;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;EACA,qBAAA;AAFJ;AAKA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AAHJ;AAMA;EACI,cAAA;AAJJ","sourcesContent":[".container {\n    width: 1300px;\n    padding: 40px 10px 100px;\n    margin: 0 auto;\n}\n\n.tabs {\n    margin-top: 32px;\n    font-weight: 600;\n    color: #595959;\n}\n\n.content {\n    margin-top: 32px;\n}\n\n.title {\n    font-family: 'Raleway';\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    font-feature-settings: 'lnum';\n    color: #262626;\n    letter-spacing: 0.5px;\n}\n\n.fullNameRow {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n}\n\n.popover {\n    color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_liqQD",
	"tabs": "tabs_fX2lP",
	"content": "content_Bw06Y",
	"title": "title_fCp_8",
	"fullNameRow": "fullNameRow_yJBnu",
	"popover": "popover_gdpIY"
};
export default ___CSS_LOADER_EXPORT___;
