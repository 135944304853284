import { Link } from 'react-router-dom'
import { IconButton } from '../../../components/ui/'
import { FeeStatus } from './AGHeader'
import { TariffsActiveType } from './RealisationCard'

type Props = {
    isAdmin: boolean
    isSeller: boolean
    isBuyer: boolean
    is_buyer_tax_payer: boolean
    consumersNotZero: boolean
    isPaymentOn?: boolean
    isNewVolume: boolean
    seller_fee_status: number
    buyer_fee_status: number
    handlePaymentModal: () => void
    auditNavigatge: () => void
    lowerTheThirdControlDate: boolean
    consumptionState: any
    tariffsActive: TariffsActiveType
    rateNumber: number
    buyer_fee: number
}

const AGButton = ({
    isAdmin,
    isSeller,
    isBuyer,
    is_buyer_tax_payer,
    consumersNotZero,
    isNewVolume,
    seller_fee_status,
    buyer_fee_status,
    handlePaymentModal,
    auditNavigatge,
    lowerTheThirdControlDate,
    consumptionState,
    tariffsActive,
    rateNumber,
    buyer_fee,
}: Props) => {
    const isTransferCommisionActive = tariffsActive.transfer
    const isRealisationCommisionActive = tariffsActive.realisation

    const isComissionoOFF = !isTransferCommisionActive && !isRealisationCommisionActive

    const complited =
        [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && // “Передача АГ по ЗД“ “Реализация АГ по ЗД“ еще оплачен Продавцом
        [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && // “Реализация АГ по ЗД“ еще оплачен Продавцом
        consumersNotZero // Покупатель указал конечных потребителей по ЗД

    if (complited && (isSeller || isBuyer || isAdmin)) {
        return (
            <IconButton onClick={auditNavigatge} customStyle={{ marginTop: '24px' }}>
                Подробнее о потреблении
            </IconButton>
        )
    }

    if (isSeller) {
        if (
            isComissionoOFF ||
            (!isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer)
        ) {
            if (
                seller_fee_status === FeeStatus.NotPayed &&
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                (!isTransferCommisionActive || rateNumber === 0) // Комиссия “Передача АГ по ЗД“ = 0 или “выкл”
            ) {
                return (
                    <IconButton onClick={handlePaymentModal} customStyle={{ marginTop: '24px' }}>
                        Подтвердить передачу
                    </IconButton>
                )
            }
        }

        if (
            (isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Продавец
            (isTransferCommisionActive && !isRealisationCommisionActive && is_buyer_tax_payer) || // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Покупатель)
            (isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) || //  Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Покупатель
            (isTransferCommisionActive && !isRealisationCommisionActive && !is_buyer_tax_payer) // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Продавец)
        ) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                isNewVolume && // в ЗД появился новый объем АГ
                seller_fee_status === FeeStatus.NotPayed // “Передача АГ по ЗД“ “Реализация АГ по ЗД“ еще не оплачен Продавцом
            ) {
                return (
                    <IconButton onClick={handlePaymentModal} customStyle={{ marginTop: '24px' }}>
                        Перейти к оплате
                    </IconButton>
                )
            }
        }

        // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Продавец)
        if (!isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                isNewVolume && // в ЗД появился новый объем АГ
                buyer_fee_status === FeeStatus.NotPayed // “Передача АГ по ЗД“ “Реализация АГ по ЗД“ еще не оплачен Продавцом
            ) {
                return (
                    <IconButton onClick={handlePaymentModal} customStyle={{ marginTop: '24px' }}>
                        Перейти к оплате
                    </IconButton>
                )
            }
        }
    }

    if (isBuyer) {
        if (isComissionoOFF) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero // Покупатель НЕ указал конечных потребителей по ЗД
            ) {
                const state = {
                    ...consumptionState,
                    fee: buyer_fee,
                }
                return (
                    <Link to={`/realization-ag`} state={state}>
                        <IconButton customStyle={{ marginTop: '24px' }}>
                            Перейти к реализации
                        </IconButton>
                    </Link>
                )
            }
        }

        if (
            (isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Продавец
            (isTransferCommisionActive && !isRealisationCommisionActive && is_buyer_tax_payer) || // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Покупатель)
            (!isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Продавец)
            (isTransferCommisionActive && !isRealisationCommisionActive && !is_buyer_tax_payer) // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Продавец)
        ) {
            if (!consumersNotZero && lowerTheThirdControlDate) {
                const state = {
                    ...consumptionState,
                    fee: buyer_fee,
                }
                return (
                    <Link to={`/realization-ag`} state={state}>
                        <IconButton customStyle={{ marginTop: '24px' }}>
                            Указать потребителей
                        </IconButton>
                    </Link>
                )
            }
        }

        if (
            (isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) || //  Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Покупатель
            (!isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Покупатель)
        ) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                isRealisationCommisionActive && // Включена комиссия “Реализация АГ по ЗД“
                !consumersNotZero && // Покупатель НЕ указал конечных потребителей по ЗД
                buyer_fee_status === FeeStatus.NotPayed // Покупателем НЕ оплачена комиссия “Реализация АГ по ЗД“
            ) {
                const state = {
                    ...consumptionState,
                    fee: buyer_fee,
                }
                return (
                    <Link to={`/payment-realization-ag`} state={state}>
                        <IconButton customStyle={{ marginTop: '24px' }}>
                            Перейти к реализации
                        </IconButton>
                    </Link>
                )
            }
        }
    }

    return <></>
}

export default AGButton
