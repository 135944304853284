// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controlsInput_yNDP5 {\n  width: 448px;\n  background: rgba(0, 0, 0, 0);\n}\n.controlsInputLong_Hi3yj {\n  width: 534px;\n  background: rgba(0, 0, 0, 0);\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/InputSearch/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,4BAAA;AACJ;AACI;EACI,YAAA;EACA,4BAAA;AACR","sourcesContent":[".controlsInput {\n    width: 448px;\n    background: rgba(0, 0, 0, 0);\n\n    &Long {\n        width: 534px;\n        background: rgba(0, 0, 0, 0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlsInput": "controlsInput_yNDP5",
	"controlsInputLong": "controlsInputLong_Hi3yj"
};
export default ___CSS_LOADER_EXPORT___;
