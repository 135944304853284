import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Notification, NewNotification } from '../../../../assets/icons'
import UserEntry from './UserEntry/UserEntry'
import style from './style.m.less'

interface IUserPanel {
    isUnread: boolean
    isAdminOrOperator: boolean
}

const UserPanel: FC<IUserPanel> = ({ isUnread, isAdminOrOperator }) => {
    const token = localStorage.getItem('accessToken')
        ? localStorage.getItem('accessToken')
        : sessionStorage.getItem('accessToken')

    const isAuth = !!token

    return (
        <div className={style.userPanel}>
            {isAuth ? (
                !isAdminOrOperator ? (
                    <>
                        <NavLink
                            to="/notifications"
                            className={(navData) =>
                                navData.isActive ? style.linkActive : style.link
                            }
                            // className={style.disabled}
                        >
                            {isUnread ? <NewNotification /> : <Notification />}
                        </NavLink>
                        <div className={style.separator} />
                    </>
                ) : (
                    <></>
                )
            ) : null}
            <UserEntry isAuth={isAuth} />
        </div>
    )
}

export default UserPanel
