import { FC, useState } from 'react'
import moment from 'moment'
import { Divider, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { Calendar, EmptyWallet, Flash } from '../../../assets/icons'
import { PowerTypeTag, GreenToolsModal, Box, IconButton } from '..'
import { energyTypePicker } from '../../helpers/generatorHelpers'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../store/hooks'
import { EUserRole } from '../../../constants/profile'
import { currencyFormat, monthPicker } from '../../helpers'

import style from './style.m.less'
import { EUserCardTabs } from '../../UserCard/types'

interface ITool {
    isDetailed?: boolean
    isRegistered?: boolean
    isOwner: boolean
    refreshData?: () => void
    tool: {
        id: string
        number: string
        owner_account_id: string
        owner: string
        renewable_energy_type_id: number
        status_id: number
        total_volume: number
        generator_id: string
        generator_name: string
        month: number
        year: number
        created_at: string
        expires_at: string
        external_registry_name: string
        external_registry_authority: string
        redeem_date: string
        owner_account_number: string
    }
}

const ThirdPartyToolsCard: FC<ITool> = ({
    isDetailed,
    isRegistered,
    isOwner,
    refreshData,
    tool: {
        number,
        owner_account_number,
        renewable_energy_type_id,
        status_id,
        total_volume,
        generator_name,
        generator_id,
        month,
        year,
        created_at,
        id,
        owner,
    },
}) => {
    const { t } = useTranslation()

    const productionPeriod = `${monthPicker(month)} ${year}`
    const [isGreenToolsModalVisible, setGreenToolsModalVisible] = useState(false)
    const convertDate = (date: string) =>
        date ? moment(date).format('DD.MM.YYYY') : 'Не определена'

    const role = useAppSelector((state) => state?.user?.data?.user_role)
    const isAdminType = [
        EUserRole.Operator,
        EUserRole.Administrator,
        EUserRole.SuperAdmin,
    ].includes(role)
    const isOwnerOrAdmin = (isOwner && isDetailed) || (isAdminType && isDetailed)
    // const user_id = useAppSelector((state) => state?.user?.data?.uid)

    const dateBlocks = [
        { title: t('certificateCard.manufPeriod'), value: productionPeriod },
        { title: 'divider' },
        { title: 'Дата создания', value: convertDate(created_at) },
    ]

    const cardLabel = 'Сторонний инструмент'

    return (
        <div className={isDetailed ? style.certificateCardMainFull : style.certificateCardMain}>
            <div
                className={
                    isDetailed ? style.certificateHeaderWrapperFull : style.certificateHeaderWrapper
                }
            >
                {isRegistered ? (
                    <Link
                        to={{
                            pathname: `/user-tools-card/${id}`,
                        }}
                        state={{
                            cardLabel,
                            id,
                            isOwner,
                        }}
                        className={style.certificateHeader}
                    >
                        {cardLabel}
                    </Link>
                ) : (
                    <div className={style.certificateHeader}>{isDetailed ? number : cardLabel}</div>
                )}
                <div className={style.tagsWrapper}>
                    <PowerTypeTag value={energyTypePicker(renewable_energy_type_id)}>
                        {energyTypePicker(renewable_energy_type_id)}
                    </PowerTypeTag>
                    <div
                        className={`${style.wrapper} ${
                            status_id === 1 ? style.activated : style.blocked
                        }`}
                    >
                        {status_id === 1 ? 'Активен' : 'Погашен'}
                    </div>
                </div>
                {isOwnerOrAdmin && (
                    <Link
                        to={{
                            pathname: isAdminType ? `/user/${owner}` : `/profile`,
                        }}
                        state={{
                            owner_account_number,
                            currentTab: EUserCardTabs.PERSONAL,
                        }}
                        className={style.personalAccount}
                    >
                        <div>
                            {t('certificateCard.persAccount')}
                            {owner_account_number}
                        </div>
                    </Link>
                )}
            </div>
            {!isDetailed && <div className={style.greyText}>{number}</div>}
            <div className={style.volume}>
                {(total_volume || total_volume === 0) && (
                    <Tooltip
                        placement="top"
                        color="#434343"
                        overlayInnerStyle={{ color: 'white' }}
                        title="Всего реализовано"
                    >
                        <div
                            className={`${style.volumeBadge} ${style.volumeConsume}`}
                        >{`${currencyFormat(total_volume)} кВт*ч`}</div>
                    </Tooltip>
                )}
            </div>
            <Box>
                {isRegistered ? (
                    <Link to={`/generator-card/${generator_id}`} className={style.link}>
                        <Box direction="row" align="center">
                            <Flash />
                            <Box margin="0px 5px">{generator_name}</Box>
                        </Box>
                    </Link>
                ) : (
                    <Box direction="row" align="center">
                        <Flash />
                        <Box style={{ fontSize: '12px', marginLeft: '5px' }}>{generator_name}</Box>
                    </Box>
                )}
            </Box>
            <div className={style.dates}>
                {dateBlocks.map(({ title, value, hideIcon }, index) =>
                    title === 'divider' ? (
                        <Divider
                            key={`divider-${index}`}
                            type="vertical"
                            className={style.divider}
                        />
                    ) : (
                        <div key={title} className={style.dateBlock}>
                            <div className={style.lightGreyText}>{title}</div>
                            <div className={style.dateBlockValue}>
                                {!hideIcon && <Calendar />}
                                <div>{value}</div>
                            </div>
                        </div>
                    ),
                )}
            </div>
            {isDetailed && isOwnerOrAdmin && (
                <>
                    <Box direction="row" justify="space-between" align="flex-end">
                        <div className={style.buttonsBlock}>
                            <IconButton
                                customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                                icon={<EmptyWallet />}
                                onClick={() => setGreenToolsModalVisible(true)}
                            >
                                {t('certificateCard.changePersAccount')}
                            </IconButton>
                        </div>
                    </Box>
                </>
            )}
            <GreenToolsModal
                isModalVisible={isGreenToolsModalVisible}
                setIsModalVisible={setGreenToolsModalVisible}
                certificateId={id}
                refreshData={refreshData}
                user_id={owner}
                isOther
            />
        </div>
    )
}

export default ThirdPartyToolsCard
