import { FC, useState } from 'react'
import { Input } from 'antd'
import { CloseCircle, NoteAdd, Trash } from '../../../../assets/icons'
import { documentIconPicker, formatSize } from '../../../helpers'
import { PrimaryIconButton } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

export interface IFile {
    name: string
    size: number
    hash: string
    type?: string
    description?: string
}

interface IDocument {
    file: IFile
    onChange(file: IFile): void
    onRemove(): void
}

const Document: FC<IDocument> = ({ file, onChange, onRemove }) => {
    const { t } = useTranslation()
    const { name, size, description, type } = file

    const [showDescription, setShowDescription] = useState(false)
    const toggleShowDescription = () => {
        if (showDescription) {
            setShowDescription(false)
            onChange({
                ...file,
                description: undefined,
            })
        } else {
            setShowDescription(true)
        }
    }

    return (
        <>
            <div>
                <div className={style.documentBlock}>
                    <div className={style.mainInfoWrapper}>
                        <div className={style.iconWrapper}>
                            {documentIconPicker(type)}
                            <div className={style.infoBlock}>
                                <div className={style.documentName}>{name}</div>
                                <div className={style.documentSize}>{formatSize(size)}</div>
                            </div>
                        </div>
                        <div className={style.iconWrapperRight}>
                            <PrimaryIconButton
                                icon={showDescription ? <CloseCircle /> : <NoteAdd />}
                                onClick={toggleShowDescription}
                            />
                            <PrimaryIconButton
                                isCancel
                                customStyle={{ marginLeft: '8px' }}
                                icon={<Trash />}
                                onClick={onRemove}
                            />
                        </div>
                    </div>
                </div>
                {showDescription && (
                    <TextArea
                        className={style.textArea}
                        placeholder={t('documentsList.inputDescr')}
                        rows={4}
                        value={description}
                        onChange={(event) => {
                            onChange({
                                ...file,
                                description: event.target.value,
                            })
                        }}
                    />
                )}
            </div>
        </>
    )
}

export default Document
