import { Table } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Filter from '../Filter/Filter'

import style from './style.m.less'

import { closedDataSource } from './data'
import { closedColumns } from './columns'
import MoreFilters from '../MoreFilters/MoreFilters'

const Closed: FC = () => {
    const navigate = useNavigate()

    return (
        <div className={style.opened}>
            <Filter />
            <MoreFilters />
            <div className={style.table}>
                <Table
                    dataSource={closedDataSource}
                    columns={closedColumns}
                    pagination={{
                        position: ['bottomLeft'],
                    }}
                    onRow={(record) => ({
                        onClick: () => {
                            navigate('/questionnaire-approval/' + record.key)
                        },
                    })}
                />
            </div>
        </div>
    )
}

export default Closed
