import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const NotVerify: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.93203 1.33301H6.06537C5.61204 1.33301 4.97203 1.59968 4.65203 1.91968L1.9187 4.65302C1.5987 4.97302 1.33203 5.61301 1.33203 6.06635V9.933C1.33203 10.3863 1.5987 11.0263 1.9187 11.3463L4.65203 14.0797C4.97203 14.3997 5.61204 14.6663 6.06537 14.6663H9.93203C10.3854 14.6663 11.0254 14.3997 11.3454 14.0797L14.0787 11.3463C14.3987 11.0263 14.6654 10.3863 14.6654 9.933V6.06635C14.6654 5.61301 14.3987 4.97302 14.0787 4.65302L11.3454 1.91968C11.0254 1.59968 10.3854 1.33301 9.93203 1.33301Z"
                    stroke="#595959"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.66797 10.3337L10.3346 5.66699"
                    stroke="#595959"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.3346 10.3337L5.66797 5.66699"
                    stroke="#595959"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
