import { FC, useState } from 'react'
import { Alert, Divider } from 'antd'
import { Export, XlsIcon } from '../../../../assets/icons'
import { IconButton, PrimaryButton } from '../../../../components/ui'
import { Tables } from '../../components/'

import style from './style.m.less'

const AddFromFile: FC = () => {
    const [uploadData, setUploadData] = useState(true)
    const [dataUploaded, setDataUploaded] = useState(false)

    return (
        <div className={style.addFromFile}>
            {uploadData && (
                <Alert
                    message={
                        <div className={style.uploadDocumentFrame}>
                            <IconButton
                                icon={<Export />}
                                customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                                onClick={() => {
                                    setDataUploaded(true)
                                    setUploadData(false)
                                }}
                            >
                                Загрузить файл
                            </IconButton>
                            <div className={style.info}>
                                Загрузите файл, из которого хотите экспортировать данные
                            </div>
                        </div>
                    }
                    className={style.alert}
                    type="info"
                />
            )}
            {dataUploaded && (
                <>
                    <Alert
                        message={
                            <div>
                                <div className={style.file}>
                                    <XlsIcon />
                                    <div className={style.fileInfo}>
                                        <div className={style.fileTitle}>
                                            Файл с данными по КУ от 17.02.2022.msg
                                        </div>
                                        <div className={style.fileSize}>183 Мб</div>
                                    </div>
                                </div>
                                <IconButton icon={<Export />} customStyle={{ marginTop: '24px' }}>
                                    Загрузить другой файл
                                </IconButton>
                            </div>
                        }
                        className={style.alert}
                        type="info"
                    />
                    <div className={style.tables}>
                        <Tables />
                    </div>
                    <Divider type={'horizontal'} />
                    <div className={style.buttonWrapper}>
                        <PrimaryButton
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            value="Подать данные"
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default AddFromFile
