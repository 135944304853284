import { Divider, Typography } from 'antd'
import { renderDateMonth, renderTime } from '../../../utils/formatter'
import { Box } from '../Box'

type Props = {
    date: Date
}

const DateTimeLine = ({ date }: Props) => {
    return (
        <Divider orientation="left" orientationMargin="0">
            <Box direction="row" align="center">
                <Typography.Text strong>{renderDateMonth(date)},</Typography.Text>
                <Typography.Text type="secondary">{renderTime(date)}</Typography.Text>
            </Box>
        </Divider>
    )
}

export default DateTimeLine
