import { FC } from 'react'

import style from './style.m.less'
import classNames from 'classnames'

interface IItem {
    isDivider?: boolean
    name?: string
    value?: string
    blur?: boolean
    code?: boolean
}

interface IQuestionnaireProps {
    config: IItem[]
    className?: string
}

const Questionnaire: FC<IQuestionnaireProps> = ({ config, className }) => {
    return (
        <div className={classNames(className, style.questionnaire)}>
            {config?.map((item) => {
                if (item.isDivider) {
                    return <div className={style.verticalLine} />
                } else {
                    if (item.code) {
                        return (
                            <div className={style.questionnaireItemWrapper}>
                                <div className={style.questionnaireItemName}>{item.name}</div>
                                <div
                                    className={classNames(
                                        style.infoWithHide,
                                        item.blur ? style.questionnaireGreyText : undefined,
                                    )}
                                >
                                    {(item.value || []).join('; ')}
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className={style.questionnaireItemWrapper}>
                            <div className={style.questionnaireItemName}>{item.name}</div>
                            <div className={item.blur ? style.questionnaireGreyText : undefined}>
                                {item.value}
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default Questionnaire
