import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Checkbox, Divider, Form, Input, Row } from 'antd'
import { HeaderBlock, PrimaryButton } from '../ui'
import { CommonBlock } from '../Edit/components'
import IAppState from '../../store/states'
import { getUser } from '../../store/user'

import style from './style.m.less'
import useRequest, { RequestTypes } from '../hooks/useRequest'

const SuperAdminEditProfile: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [form] = Form.useForm<{
        first_name: string
        last_name: string
        middle_name: string
        middle_name_absent: boolean
    }>()
    const [isMiddleNameRequired, setIsMiddleNameRequired] = useState<boolean>(false)
    const { getFieldsValue } = form

    const userInfo = useSelector((store: IAppState) => store.user)
    const { fetch } = useRequest({
        link: '/api/users/v1/profile-short',
        method: RequestTypes.post,
    })

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUser())
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            ...userInfo.data,
            middle_name: userInfo.data.middle_name_absent ? '' : userInfo.data.middle_name,
        })
        setIsMiddleNameRequired(userInfo.data.middle_name_absent)
    }, [userInfo])

    const handleChangeMiddleNameRequirement = (e: any) => {
        if (e.target.checked) {
            form.setFieldsValue({
                ...form.getFieldsValue(),
                middle_name: '',
            })
        }
        setIsMiddleNameRequired(e.target.checked)
    }

    const handleFinish = async () => {
        fetch({
            body: {
                ...getFieldsValue(),
                middle_name_absent: isMiddleNameRequired,
            },
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Изменения сохранены',
            },
            onSuccess: () => navigate('/profile'),
        })
    }

    const isMiddleNameRequiredValidator = (state: boolean) => ({
        validator: (_: any, value: string) =>
            !state
                ? value.length > 1
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('login.pleaseLastName')))
                : Promise.resolve(),
    })

    return (
        <div className={style.editMain}>
            <HeaderBlock label={t('editMyProfile.editProfile')} />
            <div className={style.editFrame}>
                <Form form={form} onFinish={handleFinish} name="continueRegistrationForm">
                    <CommonBlock
                        required
                        label={t('editMyProfile.surname')}
                        select={
                            <Form.Item
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('login.pleaseSurname'),
                                    },
                                ]}
                            >
                                <Input minLength={1} maxLength={30} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.userName')}
                        select={
                            <Form.Item
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('login.pleaseName'),
                                    },
                                ]}
                            >
                                <Input minLength={1} maxLength={30} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <Row align="middle">
                        <CommonBlock
                            required={!isMiddleNameRequired}
                            margin
                            label={t('editMyProfile.lastName')}
                            select={
                                <Form.Item
                                    name="middle_name"
                                    rules={[isMiddleNameRequiredValidator(isMiddleNameRequired)]}
                                >
                                    <Input
                                        minLength={1}
                                        maxLength={30}
                                        className={style.input}
                                        disabled={isMiddleNameRequired}
                                    />
                                </Form.Item>
                            }
                        ></CommonBlock>
                        <Checkbox
                            checked={isMiddleNameRequired}
                            className={style.checkbox}
                            onClick={handleChangeMiddleNameRequirement}
                        >
                            {t('editMyProfile.noLastName')}
                        </Checkbox>
                    </Row>
                    <Divider />
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                            onClick={() => navigate(-1)}
                        />
                        <PrimaryButton
                            value="Сохранить"
                            customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                            type="submit"
                        />
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default SuperAdminEditProfile
