import { FC, useState } from 'react'
import { Checkbox, Form, Input, message } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import {
    CenteredSpin,
    DropdownSelector2,
    Modal,
    PrimaryButton,
    PrimaryIconButton,
} from '../../../ui'
import request from '../../../../services/request'
import { EUserType } from '../../../../constants/approval'

const MESSAGE = 'Поле обязательно для заполнения'

const userTypes = [
    { text: 'Индивидуальный предприниматель', value: EUserType.IP, id: EUserType.IP },
    { text: 'Юридическое лицо', value: EUserType.UrLico, id: EUserType.UrLico },
]

export interface IGO {
    company_inn: string
    company_name_short: string
    kpp: string
    ogrn: string
    uid?: string
    user_type: EUserType
    isDraft: boolean
}

interface IAddEditGO {
    isModalVisible: boolean
    onCancel(): void
    onChange(go: IGO): void
}

const AddEditGO: FC<IAddEditGO> = ({ isModalVisible, onCancel, onChange }) => {
    const { t } = useTranslation()

    const [form] = Form.useForm()
    const [userType, setUserType] = useState(userTypes[0].value)
    const [middleNameAbsent, setMiddleNameAbsent] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleOk = async () => {
        try {
            const validValues = await form.validateFields()
            const realNewValidValues = {
                ...validValues,
                middle_name: validValues.middle_name_absent ? undefined : validValues.middle_name,
            }
            const { data } = await request.post('/api/users/v1/create-draft', {
                service_id: 3, // 3 - go, 4 - gt - из какого сервиса черновик создается, чтобы потом в этот сервис слать уведомление о появлении пользователя с заапрувленной учеткой
                ...realNewValidValues,
            })
            onChange({ ...realNewValidValues, uid: data.uid_draft, isDraft: true })
        } catch (e) {
            if (e.err_message) {
                message.error(e.err_message)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible} onCancel={onCancel}>
                {loading && <CenteredSpin />}
                <Form
                    form={form}
                    initialValues={{
                        user_type: userType,
                        middle_name_absent: middleNameAbsent,
                    }}
                >
                    <div className={style.modalHeader}>Добавление Владельца ГО</div>
                    <>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Тип пользователя</div>
                            <Form.Item name="user_type">
                                <DropdownSelector2
                                    options={userTypes}
                                    value={userType}
                                    onChange={(value) => setUserType(value)}
                                />
                            </Form.Item>
                        </div>
                        {userType === EUserType.UrLico && (
                            <>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Название</div>
                                    <Form.Item
                                        name="company_name_short"
                                        rules={[{ required: true, message: MESSAGE }]}
                                    >
                                        <Input
                                            placeholder="Введите Название"
                                            className={style.input}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ИНН</div>
                                    <Form.Item
                                        name="company_inn"
                                        rules={[
                                            { required: true, message: MESSAGE },
                                            () => ({
                                                validator(_, value) {
                                                    const strValue = String(value)
                                                    if (strValue.length !== 10) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'ИНН должен содержать 10 цифр',
                                                            ),
                                                        )
                                                    }
                                                    return Promise.resolve()
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="Введите ИНН"
                                            className={style.input}
                                            type="number"
                                        />
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ОГРН</div>
                                    <Form.Item
                                        name="ogrn"
                                        rules={[
                                            { required: true, message: MESSAGE },
                                            () => ({
                                                validator(_, value) {
                                                    const strValue = String(value)
                                                    if (strValue.length !== 13) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'ОГРН должен содержать 13 цифр',
                                                            ),
                                                        )
                                                    }
                                                    return Promise.resolve()
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="Введите ОГРН"
                                            className={style.input}
                                            type="number"
                                        />
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>КПП</div>
                                    <Form.Item
                                        name="kpp"
                                        rules={[
                                            { required: true, message: MESSAGE },
                                            () => ({
                                                validator(_, value) {
                                                    const strValue = String(value)
                                                    if (strValue.length !== 9) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'КПП должен содержать 9 цифр',
                                                            ),
                                                        )
                                                    }
                                                    return Promise.resolve()
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="Введите КПП"
                                            className={style.input}
                                            type="number"
                                        />
                                    </Form.Item>
                                </div>
                            </>
                        )}
                        {userType === EUserType.IP && (
                            <>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Фамилия</div>
                                    <Form.Item
                                        name="last_name"
                                        rules={[{ required: true, message: MESSAGE }]}
                                    >
                                        <Input className={style.input} />
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Имя</div>
                                    <Form.Item
                                        name="first_name"
                                        rules={[{ required: true, message: MESSAGE }]}
                                    >
                                        <Input className={style.input} />
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Отчество</div>
                                    <Form.Item
                                        name="middle_name"
                                        rules={[{ required: !middleNameAbsent, message: MESSAGE }]}
                                    >
                                        <Input
                                            className={style.input}
                                            disabled={middleNameAbsent}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <Form.Item
                                        name="middle_name_absent"
                                        getValueFromEvent={(event) => {
                                            const { checked } = event.target
                                            form.validateFields()
                                            setMiddleNameAbsent(checked)
                                            return checked
                                        }}
                                    >
                                        <Checkbox>{t('edit.noLastName')}</Checkbox>
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ИНН</div>
                                    <Form.Item
                                        name="personal_inn"
                                        rules={[
                                            { required: true, message: MESSAGE },
                                            () => ({
                                                validator(_, value) {
                                                    const strValue = String(value)
                                                    if (strValue.length !== 12) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'ИНН должен содержать 12 цифр',
                                                            ),
                                                        )
                                                    }
                                                    return Promise.resolve()
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="Введите ИНН"
                                            className={style.input}
                                            type="number"
                                        />
                                    </Form.Item>
                                </div>
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ОГРНИП</div>
                                    <Form.Item
                                        name="ogrn"
                                        rules={[
                                            { required: true, message: MESSAGE },
                                            () => ({
                                                validator(_, value) {
                                                    const strValue = String(value)
                                                    if (strValue.length !== 15) {
                                                        return Promise.reject(
                                                            new Error(
                                                                'ОГРН должен содержать 15 цифр',
                                                            ),
                                                        )
                                                    }
                                                    return Promise.resolve()
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            placeholder="Введите ОГРНИП"
                                            className={style.input}
                                            type="number"
                                        />
                                    </Form.Item>
                                </div>
                            </>
                        )}
                    </>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={onCancel}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryIconButton
                            size="large"
                            onClick={handleOk}
                            value="Добавить"
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default AddEditGO
