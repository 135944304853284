import { FC } from 'react'
import { Modal, ModalStatus } from '../../..'
import { DivideModal } from '../'
import style from './style.m.less'

interface ICertificateCardModal {
    modalStatus: ModalStatus
    setModalStatus: (value: ModalStatus) => void
    capacity: number
    certificateId: string
}

const CertificateCardModal: FC<ICertificateCardModal> = ({
    modalStatus,
    setModalStatus,
    capacity,
    certificateId,
}) => {
    const isModalVisible = modalStatus[1]

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.main}>
                <DivideModal
                    capacity={capacity}
                    modalStatus={modalStatus}
                    setModalStatus={setModalStatus}
                    certificateId={certificateId}
                />
            </div>
        </Modal>
    )
}

export default CertificateCardModal
