import { userRolePicker } from '../../../helpers'
import { IUserInfo } from '../../../../store/user/interfaces'

export const profileInfoConfig = (userData: IUserInfo) => {
    const { user_role, first_name, last_name, middle_name, login, email } = userData

    return {
        visibleInfo: [
            {
                name: 'Роль в системе',
                value: userRolePicker(user_role),
            },
            {
                name: 'ФИО',
                value: `${last_name} ${first_name} ${middle_name}`,
            },
            {
                name: 'Логин',
                value: login,
            },
            {
                name: 'E-mail',
                value: email,
            },
        ],
    }
}
