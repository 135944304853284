import { FC } from 'react'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal, CenteredSpin } from '../../../ui'
import { Alert, Typography } from 'antd'
import style from './style.m.less'
import { buyPackage } from './config'
import { signBody } from '../../../helpers'
import useRequest from '../../../hooks/useRequest'
import { useAppSelector } from '../../../../store/hooks'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { moneyAmountFormatter } from '../../../helpers/moneyAmountFormatter'

interface IBuyPackageModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    fee: number
    userBalance: number
}

const BuyPackageModal: FC<IBuyPackageModal> = ({
    isModalVisible,
    setIsModalVisible,
    fee,
    userBalance,
}) => {
    const { fetch } = useRequest(buyPackage)

    const { isUserAdmin } = useCurrentUser()
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const userInfo = useAppSelector((store) => store.user)
    const { data } = userInfo

    const handleConfirm = async () => {
        const body = { service_type_id: 86 }
        const { request_id, signature } = await signBody(body, data)
        fetch({
            body,
            config: {
                headers: {
                    Signature: signature,
                },
            },
            getParams: {
                request_id,
            },
            successMessage: {
                title: 'Успешно',
                description: 'Пакет услуг успешно приобретен',
            },
            onSuccess: () => handleCancel(),
            errorCodeMessage: true,
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.mainWrapper}>
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>
                        {'Купить пакет услуг по внесению записей'}
                    </div>
                    <Alert
                        message={
                            <>
                                <div>
                                    Тариф начинает действовать со следующего месяца после покупки.
                                </div>
                                <div>Срок действия пакета услуг 3 месяца.</div>
                                <div>
                                    Подключение следующего пакета услуг будет доступно за 14 дней до
                                    окончания текущего пакета услуг.
                                </div>
                            </>
                        }
                        className={style.infoAlert}
                        type="info"
                        showIcon
                    />
                    <Alert
                        className={style.modalAlert}
                        message={
                            <div className={style.modalAlertText}>
                                <div>
                                    За операцию взымается оплата в размере{' '}
                                    <Typography.Text strong>{`${fee} ₽`}</Typography.Text>
                                </div>
                                <div>
                                    Баланс платежного счета:{' '}
                                    {moneyAmountFormatter.format(userBalance)}
                                </div>
                            </div>
                        }
                        type="warning"
                        showIcon
                    />
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            confirmAction={handleConfirm}
                            cancelAction={handleCancel}
                            confirmTitle={'Подписать и купить'}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BuyPackageModal
