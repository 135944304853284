// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navBar_QMgsJ {\n  display: flex;\n  justify-content: space-between;\n  gap: 28px;\n  font-size: 11px;\n  line-height: 24px;\n  margin-top: 2px;\n}\n.navLink_Utjqn {\n  text-transform: uppercase;\n  color: #303440;\n  transition: 0.2s;\n}\n.navLink_Utjqn:hover {\n  color: #108061;\n}\n.navLinkActive__SgJi {\n  color: #108061;\n  text-transform: uppercase;\n  border-bottom: 1px solid currentColor;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/components/Navigation/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AACJ;AAEA;EACI,yBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAEI;EACI,cAAA;AAAR;AAIA;EACI,cAAA;EACA,yBAAA;EACA,qCAAA;AAFJ","sourcesContent":[".navBar {\n    display: flex;\n    justify-content: space-between;\n    gap: 28px;\n    font-size: 11px;\n    line-height: 24px;\n    margin-top: 2px;\n}\n\n.navLink {\n    text-transform: uppercase;\n    color: #303440;\n    transition: 0.2s;\n\n    &:hover {\n        color: #108061;\n    }\n}\n\n.navLinkActive {\n    color: #108061;\n    text-transform: uppercase;\n    border-bottom: 1px solid currentColor;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBar": "navBar_QMgsJ",
	"navLink": "navLink_Utjqn",
	"navLinkActive": "navLinkActive__SgJi"
};
export default ___CSS_LOADER_EXPORT___;
