import { FC, ReactNode } from 'react'
import { CenteredSpin } from '../'

type LoadingContainerProps = {
    children: ReactNode
    isLoading: boolean
}

const LoadingContainer: FC<LoadingContainerProps> = ({ children, isLoading }) => (
    <>{isLoading ? <CenteredSpin /> : <>{children}</>}</>
)

export default LoadingContainer
