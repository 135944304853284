import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const RectangleListMarked: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.3335 5.6665H9.00016"
                    strokeMiterlimit="10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4 11H5.33333"
                    strokeMiterlimit="10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 11H9.66667"
                    strokeMiterlimit="10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.6668 7.3535V10.7402C14.6668 13.0802 14.0735 13.6668 11.7068 13.6668H4.2935C1.92683 13.6668 1.3335 13.0802 1.3335 10.7402V5.26016C1.3335 2.92016 1.92683 2.3335 4.2935 2.3335H9.00016"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11 4.00016L12 5.00016L14.6667 2.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
