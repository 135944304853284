import { FC, useState } from 'react'
import { Divider } from 'antd'
import FileSaver from 'file-saver'
import request from '../../../../services/request'
import { Modal, CenteredSpin } from '../../../ui'
import { IHistoryItem } from '../OperationsHistory/lockedColumns'
import moment from 'moment'
import { formatSize, documentIconPicker } from '../../../helpers'

import style from './style.m.less'

interface IInfo {
    label: string
    text: string
}

const Info: FC<IInfo> = ({ label, text }) => (
    <div className={style.infoBlock}>
        <div className={style.infoName}>{label}</div>
        <div className={style.infoValue}>{text}</div>
    </div>
)

interface IOperationHistoryDetailsModal {
    isModalVisible: boolean
    onClose: () => void
    historyItem: IHistoryItem
}

const OperationHistoryDetailsModal: FC<IOperationHistoryDetailsModal> = ({
    isModalVisible,
    onClose,
    historyItem,
}) => {
    const [loading, setLoading] = useState(false)
    const handleClickDownload = async (id: string, name: string) => {
        try {
            if (!id) {
                return
            }

            setLoading(true)
            const body = await request.get(`/api/documents/v1/file/${id}`, {
                responseType: 'blob',
            })

            const bodyBlob = new Blob([body?.data])

            FileSaver.saveAs(bodyBlob, name)
        } finally {
            setLoading(false)
        }
    }

    const file = (item: { file_name: string; file_size: number; id: string }) => {
        const format = item.file_name.split('.')[1]
        const size = formatSize(item.file_size)
        const name = item.file_name
        return (
            <div
                key={item.id}
                className={style.documentWrapper}
                onClick={() => handleClickDownload(item.id, name)}
            >
                <div className={style.file}>
                    {documentIconPicker(format)}
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>{name.split('.')[0]}</div>
                        <div className={style.fileSize}>{size}</div>
                    </div>
                </div>
            </div>
        )
    }

    const functionNames = historyItem?.function_ids?.map((item) =>
        item === 1
            ? 'Фиксация Атрибутов генерации'
            : item === 2
            ? 'Выпуск ЗИ'
            : item === 3
            ? 'Передача АГ по ЗД'
            : item === 4
            ? 'Собственное погашение АГ'
            : null,
    )

    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            {loading && <CenteredSpin />}
            <div className={style.modal}>
                <div className={style.header}>Детали операции</div>
                <Info
                    label="Дата операции"
                    text={moment(historyItem.ts_created).format('DD.MM.YYYY')}
                />
                <Info
                    label="Действие"
                    text={historyItem.need_block_add ? 'Блокировка' : 'Снятие блокировки'}
                />
                <Info label="Функционал" text={functionNames.join(', ')} />
                <Divider className={style.devider} />
                {historyItem.comment && (
                    <>
                        <div className={style.subtitle}>Основание</div>
                        <div>{historyItem.comment}</div>
                    </>
                )}
                <div className={style.documentsWrapper}>{historyItem?.documents?.map(file)}</div>
            </div>
        </Modal>
    )
}

export default OperationHistoryDetailsModal
