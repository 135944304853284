import { EUserRole } from '../../constants/profile'
import { useAppSelector } from '../../store/hooks'

export const useCurrentUser = () => {
    const { data, isLoading } = useAppSelector((state) => state.user)

    const { user_role, ...rest } = data

    return {
        isUserAdmin:
            user_role === EUserRole.Administrator ||
            user_role === EUserRole.SuperAdmin ||
            user_role === EUserRole.Operator,
        isAdmin: user_role === EUserRole.Administrator,
        isSuperAdmin: user_role === EUserRole.SuperAdmin,
        isTSO: user_role === EUserRole.TSO,
        isClient: user_role === EUserRole.Client,
        isGOOwner: user_role === EUserRole.GOOwner,
        isOperator: user_role === EUserRole.Operator,
        isUserWithoutPA: user_role === EUserRole.UserWithoutPA,
        isLoading,
        userInfo: data,
        data,
        ...rest,
    }
}
