import { FC, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Divider } from 'antd'
import { ImportButton } from '../../../ui'
import { DoubleSave, Settings, TickSquare, BlueLock } from '../../../../assets/icons'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import Correct from '../ModalGenerationAttributes/Correct'
import { BlockModalGenerationAttributes } from '../BlockModalGenerationAttributes/index'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../store/hooks'
import { getUserInfo } from '../../../../store/user'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

interface IFreeGenerationAttributesProps {
    isModalVisible?: boolean
    isBlockingModalVisible?: boolean
    setIsModalVisible: (value: boolean) => void
    setIsBlockingModalVisible: (value: boolean) => void
    tsActivation: string
    blockStatus: {
        ga_fixation: boolean
        gt_emission: boolean
        ga_transfer: boolean
        ga_redemption: boolean
    }
}

const FreeGenerationAttributes: FC<IFreeGenerationAttributesProps> = ({
    isModalVisible = false,
    isBlockingModalVisible,
    setIsBlockingModalVisible,
    setIsModalVisible,
    blockStatus,
    tsActivation,
}) => {
    const { t } = useTranslation()

    const { id } = useParams<{ id: string }>()

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getUserInfo())
    }, [])

    const { isOperator, isSuperAdmin, isAdmin, isGOOwner } = useCurrentUser()

    const { fetch: getTotalAG, result: responseTotalAG } = useRequest({
        link: `/api/generation_attributes/v1/gas/ga/total`,
        method: RequestTypes.get,
    })

    useEffect(() => {
        reNewTotalAG()
    }, [id])

    const reNewTotalAG = () => {
        getTotalAG({
            body: {},
            addToLink: `?generator_id=${id}`,
        })
    }

    return (
        <div className={style.main}>
            <div className={style.header}>{t('detailedCard.freeAttrs')}</div>
            <div className={style.freeGenerationAttributes}>
                {responseTotalAG?.free_volume?.toLocaleString()} {t('detailedCard.scale')}
            </div>
            <Correct
                tsActivation={tsActivation}
                reNewTotalAG={reNewTotalAG}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />

            <BlockModalGenerationAttributes
                isModalVisible={isBlockingModalVisible}
                setIsModalVisible={setIsBlockingModalVisible}
                refreshTotalAG={reNewTotalAG}
            />

            <div className={style.buttonsBlock}>
                {isGOOwner && (
                    <>
                        <Link to={'/issue-certificate'} state={{ type: 'generator' }}>
                            <ImportButton
                                value={t('detailedCard.issueCerf')}
                                icon={<DoubleSave size={16} />}
                            />
                        </Link>
                        <Divider type={'vertical'} className={style.divider} />
                    </>
                )}
                <Link to={'/settings'}>
                    <ImportButton value={t('detailedCard.setIssue')} icon={<Settings />} />
                </Link>
                <Divider type={'vertical'} className={style.divider} />
                <Link
                    to="/own-repayment-ga"
                    state={{ generatorId: id }}
                    style={blockStatus?.ga_redemption ? { pointerEvents: 'none' } : undefined}
                >
                    <ImportButton
                        value="Собственное погашение"
                        icon={<TickSquare />}
                        isDisabled={blockStatus?.ga_redemption}
                    />
                </Link>
                {(isSuperAdmin || isAdmin || isOperator) && (
                    <>
                        <Divider type={'vertical'} className={style.divider} />
                        <ImportButton
                            value={'Скорректировать АГ'}
                            icon={<Settings />}
                            onClick={() => setIsModalVisible(true)}
                        />
                    </>
                )}
                {(isSuperAdmin || isAdmin || isOperator) && (
                    <>
                        <Divider type={'vertical'} className={style.divider} />
                        <ImportButton
                            value={'Заблокировать объем АГ'}
                            icon={<BlueLock />}
                            onClick={() => setIsBlockingModalVisible(true)}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default FreeGenerationAttributes
