import { FC, useState } from 'react'

import style from './style.m.less'
import { DropdownSelector2 } from '../../../ui'
import { useTranslation } from 'react-i18next'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { EUserRole, userRoleMap } from '../../../../constants/approval'

const userTypeOptions = [
    { id: EUserRole.All, value: EUserRole.All, text: userRoleMap.get(EUserRole.All) },
    {
        id: EUserRole.UserWithouPA,
        value: EUserRole.UserWithouPA,
        text: userRoleMap.get(EUserRole.UserWithouPA),
    },
    {
        id: EUserRole.Client,
        value: EUserRole.Client,
        text: userRoleMap.get(EUserRole.Client),
    },
    {
        id: EUserRole.GOOwner,
        value: EUserRole.GOOwner,
        text: userRoleMap.get(EUserRole.GOOwner),
    },
    {
        id: EUserRole.TSO,
        value: EUserRole.TSO,
        text: userRoleMap.get(EUserRole.TSO),
    },
]

interface IMoreFilter {
    onChangeRoleRecipient: (actionType: EStatus) => void
}

const MoreFilters: FC<IMoreFilter> = (props) => {
    const { t } = useTranslation()

    const [isMoreFilterVisible, setIsMoreFilterVisible] = useState(false)
    const showMoreFilter = () => {
        setIsMoreFilterVisible(!isMoreFilterVisible)
    }

    return (
        <>
            {isMoreFilterVisible && (
                <div className={style.filter}>
                    <div className={style.moreFilterItem}>
                        <div className={style.label}>Роль пользователя (адресата)</div>
                        <DropdownSelector2
                            onChange={props.onChangeRoleRecipient}
                            options={userTypeOptions}
                            className={style.select}
                        />
                    </div>
                </div>
            )}
            <div className={style.moreFilter} onClick={showMoreFilter}>
                {!isMoreFilterVisible && <span>{t('questionnaireApproval.moreFilters')}</span>}
                {isMoreFilterVisible && <span>{t('questionnaireApproval.fewerFilters')}</span>}
                {!isMoreFilterVisible && <DownOutlined className={style.icon} />}
                {isMoreFilterVisible && <UpOutlined className={style.icon} />}
            </div>
        </>
    )
}

export default MoreFilters
