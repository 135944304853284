import { RequestTypes } from '../hooks/useRequest'

export const GT_API_PREFIX = '/api/green-tools/v1/'
export const GT_SUBMISSION_WM = 'wm/submission'
export const GT_SUBMISSION_RM = 'rm/submission'

export const postZDWm = {
    link: `${GT_API_PREFIX}${GT_SUBMISSION_WM}`,
    method: RequestTypes.post,
}

export const postZDRm = {
    link: `${GT_API_PREFIX}${GT_SUBMISSION_RM}`,
    method: RequestTypes.post,
}
