import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Input, message } from 'antd'
import { Alert, CenteredSpin, PrimaryButton, PrimaryIconButton } from '../../components/ui'
import request from '../../services/request'

import style from './style.m.less'

const CertificateValidityPeriod: FC = () => {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)
    const [currentPeriod, setCurrentPeriod] = useState<number>()
    const [period, setPeriod] = useState<number>()

    const getValidityPeriod = useCallback(async () => {
        try {
            setLoading(true)
            const res = await request.get('/api/green-tools/v1/certificate-ttl')
            setPeriod(res.data.certificate_ttl)
            setCurrentPeriod(res.data.certificate_ttl)
        } finally {
            setLoading(false)
        }
    }, [])

    const setValidityPeriod = async () => {
        if (!period) {
            return
        }
        try {
            setLoading(true)
            const { status } = await request.post(`/api/green-tools/v1/certificate-ttl`, {
                certificate_ttl: period,
            })
            if (status === 200) {
                message.success('Изменения успешно сохранены')
            }
            await getValidityPeriod()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getValidityPeriod()
    }, [])

    return (
        <div className={style.main}>
            {loading && <CenteredSpin />}
            <div className={style.header}>Срок действия сертификата происхождения</div>
            <div className={style.content}>
                <div className={style.infoBlock}>
                    <div>Срок действия СП (в днях)</div>
                    <Input
                        value={period}
                        onChange={(event) => {
                            const value = parseInt(event.target.value, 10)
                            setPeriod(Number.isNaN(value) ? undefined : value)
                        }}
                        className={style.input}
                    />
                    <Alert
                        className={style.alert}
                        type="info"
                        message="Для установки срока действия СП необходимо ввести значение больше “0“"
                    />
                </div>
                <Divider />
                <div className={style.buttonWrapper}>
                    <PrimaryButton
                        onClick={() => setPeriod(currentPeriod)}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryIconButton
                        size="large"
                        disabled={period === undefined || period <= 0 || period === currentPeriod}
                        onClick={setValidityPeriod}
                        value="Применить"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </div>
        </div>
    )
}

export default CertificateValidityPeriod
