import { FC, useState } from 'react'
import { Input, Checkbox, Divider } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const DivideModal: FC = () => {
    const { t } = useTranslation()

    const [isCheckboxChecked, setCheckboxChecked] = useState(false)

    return (
        <>
            <div className={style.modalHeader}>{t('certificateCard.split')}</div>

            <div className={style.inputBlockWrapper}>
                <div>{t('certificateCard.count')}</div>
                <Input className={style.inputBlock} />
                <Checkbox
                    className={style.checkbox}
                    onChange={(e) => {
                        setCheckboxChecked(e.target.checked)
                    }}
                >
                    {t('certificateCard.issue')}
                </Checkbox>
            </div>
            <Divider />
            {isCheckboxChecked ? (
                <>
                    <div>
                        <div>{t('certificateCard.nominal')}</div>
                        <Input className={style.inputBlock} />
                    </div>
                </>
            ) : (
                <>
                    <div className={style.ratingHeader}>{t('certificateCard.chooseNominal')}</div>
                    <div className={style.certificateBlockFirst}>
                        <div className={style.certificateName}>
                            1 {t('certificateCard.certificate')}
                        </div>
                        <Input className={style.certificateInput} />
                    </div>
                    <div className={style.certificateBlock}>
                        <div className={style.certificateName}>
                            2 {t('certificateCard.certificate')}
                        </div>
                        <Input className={style.certificateInput} />
                    </div>
                    <div className={style.certificateBlock}>
                        <div className={style.certificateName}>
                            3 {t('certificateCard.certificate')}
                        </div>
                        <Input className={style.certificateInput} />
                    </div>
                </>
            )}
        </>
    )
}

export default DivideModal
