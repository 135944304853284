import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Export: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.25 8.75L16.25 3.75"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.25 7.5V3.75H12.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9 5H8.5C6 5 5 6 5 8.5V11.5C5 14 6 15 8.5 15H11.5C14 15 15 14 15 11.5V11"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
