import { FC, useState } from 'react'
import FileSaver from 'file-saver'
import { formatSize, documentIconPicker } from '../../../helpers'
import request from '../../../../services/request'
import { CenteredSpin } from '../../../ui'

import style from './style.m.less'

interface IDocumentsGrid {
    documents: any
}

const DocumentsGrid: FC<IDocumentsGrid> = ({ documents }) => {
    const [loading, setLoading] = useState(false)
    const handleClickDownload = async (id: string, name: string) => {
        try {
            if (!id) {
                return
            }

            setLoading(true)
            const body = await request.get(`/api/documents/v1/file/${id}`, {
                responseType: 'blob',
            })

            const bodyBlob = new Blob([body?.data])

            FileSaver.saveAs(bodyBlob, name)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={style.documentsGrid}>
            {loading && <CenteredSpin />}
            {documents?.map(
                (item: { file_name: string; file_size: number; comment: string; id: string }) => {
                    const format = item.file_name.split('.')[1]
                    const size = formatSize(item.file_size)
                    return (
                        <div className={style.documentsWrapper}>
                            <div
                                className={style.file}
                                onClick={() => handleClickDownload(item.id, item.file_name)}
                            >
                                {documentIconPicker(format)}
                                <div className={style.fileInfo}>
                                    <div className={style.fileTitle}>
                                        {item.file_name.split('.')[0]}
                                    </div>
                                    <div className={style.fileSize}>{size}</div>
                                </div>
                            </div>
                            <div className={style.description}>{item.comment}</div>
                        </div>
                    )
                },
            )}
        </div>
    )
}

export default DocumentsGrid
