import { FC, useEffect } from 'react'
import { ELoading } from '../../store/dictionaries/interfaces'
import { getDictionaries } from '../../store/dictionaries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getUserInfo } from '../../store/user'
import { GeneratorLayout, GeneratorLayoutAdmin } from './pages'
import style from './style.m.less'
import { HeaderBlock, LoadingContainer } from '../ui'
import { EUserRole } from '../../constants/approval'
import { useParams } from 'react-router-dom'

const AddEditGO: FC = () => {
    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const isUserLoading = useAppSelector((state) => state.user.isLoading)

    const userRole = useAppSelector((state) => state.user.data.user_role)

    const isAdminOrOperator =
        userRole === EUserRole.SuperAdmin ||
        userRole === EUserRole.Administrator ||
        userRole === EUserRole.Operator
    const isGOOwner = userRole === EUserRole.GOOwner

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
        dispatch(getUserInfo())
    }, [])

    const { id } = useParams<{ id?: string }>()

    return (
        <LoadingContainer isLoading={isLoading !== ELoading.Loaded || isUserLoading}>
            <div className={style.editMain}>
                <div className={style.header}>
                    {id ? <HeaderBlock label="Редактирование ГО" /> : 'Добавление ГО'}
                </div>
                {isGOOwner ? (
                    <GeneratorLayout type="create" />
                ) : isAdminOrOperator ? (
                    <GeneratorLayoutAdmin type="create" />
                ) : null}
            </div>
        </LoadingContainer>
    )
}

export default AddEditGO
