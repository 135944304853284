import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_CREDENTIALS = 'certificate-repayment'
export const GREEN_TOOLS_WITH_FILE = 'certificate-repayment-file'
export const GREEN_TOOLS_FILE_PRICE = 'certificate-repayment-file-check'

export const sendRepayment = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CREDENTIALS}`,
    method: RequestTypes.post,
}

export const sendWithFile = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_WITH_FILE}`,
    method: RequestTypes.post,
}

export const getFilePrice = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_FILE_PRICE}`,
    method: RequestTypes.post,
}
