import { RequestTypes } from '../hooks/useRequest'

export const BILLING_API_PREFIX = '/api/billing/v1/'
export const BILLING_SUBMISSION = 'upload-deposits-approve'
export const BILLING_VIEW = 'upload-deposits-view'

export const postUpload = {
    link: `${BILLING_API_PREFIX}${BILLING_SUBMISSION}`,
    method: RequestTypes.post,
}

export const viewData = {
    link: `${BILLING_API_PREFIX}${BILLING_VIEW}`,
    method: RequestTypes.post,
}
