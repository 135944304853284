import { RequestTypes } from '../hooks/useRequest'

export const GT_API_PREFIX = '/api/green-tools/v1/'
export const GT_AUTO = 'certificate/settings-cert-auto-create'
export const GT_HIDE = 'settings'

export const getAutoSettings = {
    link: `${GT_API_PREFIX}${GT_AUTO}`,
    method: RequestTypes.get,
}

export const postAutoSettings = {
    link: `${GT_API_PREFIX}${GT_AUTO}`,
    method: RequestTypes.post,
}

export const getHideSettings = {
    link: `${GT_API_PREFIX}${GT_HIDE}`,
    method: RequestTypes.get,
}

export const postHideSettings = {
    link: `${GT_API_PREFIX}${GT_HIDE}`,
    method: RequestTypes.post,
}
