import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: true,
    error: '',
    accounts: [],
}

const accounts = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        getAccountsData: (state) => {
            return state
        },
        updateAccounts: (state, action) => {
            return { ...state, ...action.payload }
        },
        dropAccounts: () => {
            return { ...initialState }
        },
    },
})

export const getAccounts = createAction('accounts/getAccountsData')

export const { getAccountsData, updateAccounts, dropAccounts } = accounts.actions

export default accounts.reducer
