import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    AdminGeneralInfo,
    GeneralInfo,
    OperatorGeneralInfo,
    PaymentAccount,
    PersonalAccount,
    SuperAdminGeneralInfo,
} from './components'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import IAppState from '../../store/states'
import { useLocation, useNavigate } from 'react-router-dom'
import { CenteredSpin } from '../ui'
import { EUserRole } from '../../constants/approval'
import { getUser } from '../../store/user'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import style from './style.m.less'

const { TabPane } = Tabs

const Profile: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data, actualData, error, isLoading } = userInfo

    const { fetch: getAccounts, result: responseAccounts } = useRequest({
        link: '/api/green-tools/v1/accounts',
        method: RequestTypes.get,
    })
    const [isLoadingPersAcc, setLoadingPresAcc] = useState(false)

    const { state } = useLocation()
    const [accountId, setAccountID] = useState<string>(state?.accountId)
    const toPaymentAccount = state?.toPaymentAccount || false

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUser())
    }, [])

    if (error === 'no refresh token') {
        navigate('/auth')
        window.location.reload()
    }

    if (isLoading) {
        return <CenteredSpin />
    }

    const { user_role } = data

    if (
        !accountId &&
        ![
            EUserRole.Operator,
            EUserRole.Administrator,
            EUserRole.SuperAdmin,
            EUserRole.UserWithouPA,
        ].includes(user_role) &&
        !isLoadingPersAcc
    ) {
        setLoadingPresAcc(true)
        try {
            getAccounts({
                showError: false,
            })
        } catch (error) {
            console.log(error)
        }
    }

    let content: any

    if (user_role === EUserRole.Operator) {
        content = <OperatorGeneralInfo />
    } else if (user_role === EUserRole.Administrator) {
        content = <AdminGeneralInfo />
    } else if (user_role === EUserRole.SuperAdmin) {
        content = <SuperAdminGeneralInfo />
    } else {
        const accounts = responseAccounts?.accounts ?? []
        if (!accountId && accounts.length) {
            setAccountID(accounts.filter((acc: { is_main: boolean }) => acc.is_main)[0]?.id)
        }

        const tabsList = [
            { label: t('profile.profile'), key: 1, content: <GeneralInfo /> },
            { label: t('profile.bill'), key: 2, content: <PaymentAccount /> },
            {
                label: t('profile.persAccount'),
                key: 3,
                content: <PersonalAccount accountId={accountId} />,
            },
        ]

        content = (
            <Tabs
                destroyInactiveTabPane
                defaultActiveKey={state?.accountId ? '3' : toPaymentAccount ? '2' : '1'}
                className={style.tabs}
            >
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane
                            tab={t(label)}
                            key={key}
                            disabled={
                                !accountId &&
                                actualData?.profile_status !== 5 &&
                                [2, 3].includes(key)
                            }
                        >
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
        )
    }

    return (
        <div className={style.profileMain}>
            <div className={style.mainHeader}>{t('profile.title')}</div>
            {content}
        </div>
    )
}

export default Profile
