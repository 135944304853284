import { FC, useEffect, useState } from 'react'
import { Alert, Form, Input } from 'antd'
import { Modal, PrimaryButton } from '../../ui'
import { DocumentsBlock } from '../../ui'
import { Box } from '../../ui/Box'
import CurrInput from '../../ui/CurrencyInput/CurrInput'
import useRequest from '../../hooks/useRequest'
import { approveAppeal } from '../config'

import style from '../style.m.less'
import { useForm } from 'antd/lib/form/Form'

const APPROVE_TYPE: any = {
    1: 'Запрос информации',
    3: 'Отказать в выполнении заявки',
    4: 'Запрос оплаты по заявке',
}

interface IChangeRoleModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean, type: number) => void
    approveType: number
    id: number
    getAppeal: any
}

const AskInformationModal: FC<IChangeRoleModal> = ({
    isModalVisible,
    setIsModalVisible,
    approveType = 1,
    id,
    getAppeal,
}) => {
    const { fetch, isSuccess, isLoading } = useRequest(approveAppeal)
    const [amount, setAmount] = useState<number | null>(null)

    const [form] = useForm()

    const [files, setFiles] = useState<any[]>([])

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const sendApprove = (values: any) => {
        fetch({
            body: {
                approve_type: approveType,
                amount: Number(String(amount).replace(',', '.')), // TODO refact
                message: values.message,
                documents: files,
            },
            getParams: {
                id,
            },
        })
    }

    useEffect(() => {
        if (!isSuccess) {
            return
        }

        getAppeal()
        setIsModalVisible(false, 0)
    }, [isSuccess])

    useEffect(() => {
        if (!isModalVisible) {
            form.setFieldsValue({
                message: '',
            })
            setAmount(null)
        }
    }, [isModalVisible])

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>{APPROVE_TYPE[approveType]}</div>
            <div className={style.inputBlock}>
                <Form onFinish={sendApprove} form={form} name="completeAppeal" layout="vertical">
                    {approveType === 4 ? <CurrInput onChange={setAmount} value={amount} /> : null}
                    <Form.Item
                        name="message"
                        label="Комментарий"
                        rules={[
                            {
                                required: true,
                                message: 'Не указан текст комментария',
                            },
                            {
                                max: 500,
                                message: 'Введено больше 500 символов',
                            },
                        ]}
                    >
                        <Input.TextArea style={{ minHeight: 95, maxWidth: 600 }} />
                    </Form.Item>
                </Form>
            </div>
            <Box flex={'row'} width={436} margin={'20px 0px'}>
                <DocumentsBlock value={files} onChange={fileHandler} hideComment serviceId={5} />
            </Box>
            <Alert
                message="Указанный комментарий и прикрепленные документы увидит пользователь"
                type="warning"
                showIcon
                className={style.alert}
            />
            <Box direction="row" justify="flex-end" margin="20px 0px 0px 0px">
                <PrimaryButton
                    onClick={() => setIsModalVisible(false, 0)}
                    value={'Отмена'}
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryButton
                    onClick={() => form.submit()}
                    disabled={isLoading}
                    value={'Отправить'}
                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                />
            </Box>
        </Modal>
    )
}

export default AskInformationModal
