import { FC } from 'react'
import { Modal } from '../../../ui'
import { DocumentsGrid } from '../'

import style from './style.m.less'

interface IBlockReasonModal {
    isVisible: boolean
    onCancel: () => void
    reason: string
    documents?: any[]
}

const BlockReasonModal: FC<IBlockReasonModal> = ({ isVisible, reason, documents, onCancel }) => (
    <Modal closable isModalVisible={isVisible} className={style.modal} onCancel={onCancel}>
        <div className={style.title}>Причина блокировки</div>
        <div className={style.content}>{reason}</div>
        <DocumentsGrid className={style.documents} documents={documents} />
    </Modal>
)

export default BlockReasonModal
