import { ConfigProvider, Table } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import Filter from '../Filter/Filter'
import { CenteredSpin } from '../../../ui'
import style from './style.m.less'
import useMount from '../../../hooks/useMount'
import { getFuelRegistry, getGosDict } from '../../config'
import { renderDayMonthYear } from '../../../../utils/formatter'
import { monthPicker } from '../../../helpers'

import useRequest from '../../../hooks/useRequest'

export const getStatus = (option: number) => {
    switch (option) {
        case 1:
            return <div className={style.statusNew}>Новая</div>
        case 2:
            return <div className={style.statusNew}>На рассмотрении</div>
        case 3:
            return <div className={style.statusAgreed}>Согласована</div>
        case 4:
            return <div className={style.statusCancel}>Отказана</div>
        default:
            return <></>
    }
}

type PaginationType = {
    page: number
    pageSize: number
    filters?: any
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

const COLUMNS = () => [
    {
        title: 'Дата подачи',
        dataIndex: 'ts_created',
        key: 'ts_created',
        render: (date: Date) => renderDayMonthYear(date),
    },
    {
        title: 'Отчетный период',
        dataIndex: 'month',
        key: 'month',
        render: (month: number, record: any) => `${monthPicker(month)}, ${record.year}`,
    },
    {
        title: 'ЗГО',
        dataIndex: 'generator',
        key: 'generator',
        render: (generator: any) => generator.name,
    },
    {
        title: 'Статус',
        dataIndex: 'fuel_application_status_id',
        key: 'fuel_application_status_id',
        render: (fuel_application_status_id: number) => getStatus(fuel_application_status_id),
    },
]

const Default: FC = () => {
    const navigate = useNavigate()
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>()
    const { fetch: fetchDict, result: dict, isLoading: isLoadingDict } = useRequest(getGosDict)
    const { fetch, result, isLoading } = useRequest(getFuelRegistry)

    const getData = ({ page = 1, pageSize = 10, filters }: PaginationType) => {
        fetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters,
            },
        })
    }

    useMount(() => {
        fetchDict({})
    })

    useEffect(() => {
        if (!dict) {
            return
        }
        getData({ ...DEFAULT_PAG, filters })
    }, [dict])

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        const updatedFilter = {
            ...filters,
            ...(obj ? { ...obj } : { [key]: data }),
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        getData({ page: 1, pageSize: pagination.pageSize, filters: updatedFilter })
    }

    if (isLoadingDict || !dict) {
        return <CenteredSpin />
    }

    return (
        <div className={style.opened}>
            <Filter onChange={updateFilters} dict={dict} />
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <Table
                        dataSource={result?.applications || []}
                        columns={COLUMNS(dict)}
                        rowClassName={(record) => (record.has_update ? style.rowBold : '')}
                        pagination={{
                            position: ['bottomLeft'],
                            onChange: (page, pageSize) => {
                                getData({ page, pageSize, filters })
                                setPagination({ page, pageSize })
                            },
                            total: result?.total,
                            current: pagination.page,
                            pageSize: pagination.pageSize,
                            showSizeChanger: true,
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                navigate('/fuel-card/' + record.id)
                            },
                        })}
                        loading={isLoading}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Default
