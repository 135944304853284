import { FC, useState } from 'react'
import { CenteredSpin, IconButton, Box, PrimaryButton } from '../../../ui'
import { RefuseToApproveModal } from '../'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import request from '../../../../services/request'
import { message } from 'antd'
import { SuccessMessage } from '../../../Edit/components'
import { useNavigate } from 'react-router-dom'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'

export enum EApproveType {
    Approve = 1,
    Canceled = 2,
}

const ButtonContainer: FC = ({ generator }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { fetch: fetchApprove, isLoading } = useRequest({
        link: '/api/generators/v1/go-approve',
        method: RequestTypes.post,
    })

    const [isRefuseModalVisible, setIsRefuseModalVisible] = useState(false)
    const openRefuseModal = () => {
        setIsRefuseModalVisible(true)
    }

    const getBack = () => {
        navigate('/go-approval')
    }

    const sendApprove = async (approveType: EApproveType, comment = '') => {
        const body = {
            generator_id: generator?.generator_id,
            approve_type: approveType,
            generator_comment: comment,
        }
        fetchApprove({
            body,
            errorCodeMessage: true,
            onSuccess: () => {
                message.success({
                    content: (
                        <SuccessMessage
                            header={t('questionnaireApprovalItem.success')}
                            message={`Согласовано добавление генерирующего объекта`}
                        />
                    ),
                    icon: <></>,
                })
                navigate('/go-approval')
            },
            onError: () => {
                message.error({
                    content: `Добавление генерирующего объекта не согласовано`,
                })
            },
        })
    }

    const handleConfirm = async () => {
        await sendApprove(EApproveType.Approve)
    }

    return (
        <div className={style.buttonContainer}>
            {isLoading && <CenteredSpin />}
            <Box direction="row" gap="16px">
                <PrimaryButton
                    onClick={getBack}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <IconButton
                    size="large"
                    className={style.buttonContainerApprove}
                    onClick={handleConfirm}
                >
                    {t('gOApprovalItem.approveButton')}
                </IconButton>
            </Box>
            <IconButton
                size="large"
                className={style.buttonContainerCancel}
                onClick={openRefuseModal}
            >
                {t('gOApprovalItem.cancelButton')}
            </IconButton>
            <RefuseToApproveModal
                isModalVisible={isRefuseModalVisible}
                onConfirm={async (text) => {
                    await sendApprove(EApproveType.Canceled, text)
                    message.success({
                        content: (
                            <SuccessMessage
                                header={t('questionnaireApprovalItem.success')}
                                message="Отказано в добавлении ГО"
                            />
                        ),
                        icon: <></>,
                    })
                    setIsRefuseModalVisible(false)
                    navigate('/go-approval')
                }}
                onCancel={() => setIsRefuseModalVisible(false)}
            />
        </div>
    )
}

export default ButtonContainer
