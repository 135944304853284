import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const ArrowLeft: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.57 6.43018L3.5 12.5002L9.57 18.5702"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.5019 12.5H3.67188"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
