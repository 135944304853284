import { FC } from 'react'
import { ConfigProvider, Pagination as AntdPagination } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import './style.less'
import style from './style.m.less'
import classNames from 'classnames'

interface IPagination {
    current?: number
    className?: string
    customStyle?: React.CSSProperties
    total?: number
    showSizeChanger?: boolean
    pageSize?: number
    paginationChange?: (page: number, limit: number | undefined) => void
}

const Pagination: FC<IPagination> = ({
    customStyle,
    className,
    paginationChange,
    current,
    showSizeChanger,
    total = 100,
    pageSize = 10,
}) => {
    return (
        <div className={classNames(className, style.paginationMain)} style={customStyle}>
            <ConfigProvider locale={ruRU}>
                <AntdPagination
                    current={current}
                    total={total}
                    onChange={paginationChange}
                    showSizeChanger={showSizeChanger}
                    pageSize={pageSize}
                />
            </ConfigProvider>
        </div>
    )
}

export default Pagination
