import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const FileSwap: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="8" fill="#F5F5F5" />
                <path
                    d="M15.2 19.9998L16.8 18.3998L15.2 16.7998L16.8 18.3998H12"
                    stroke="#595959"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M22.4001 14.7201V17.9201C22.4001 21.1201 21.1201 22.4001 17.9201 22.4001H14.0801C10.8801 22.4001 9.6001 21.1201 9.6001 17.9201V14.0801C9.6001 10.8801 10.8801 9.6001 14.0801 9.6001H17.2801"
                    stroke="#595959"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M22.4001 14.4001H19.7819C17.8183 14.4001 17.6001 14.1819 17.6001 12.2183V9.6001L22.4001 14.4001Z"
                    stroke="#595959"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )}
    />
)
