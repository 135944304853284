import ShowMoreLink from '../../../../ui/ShowMoreLink/ShowMoreLink'
import FileSaver from 'file-saver'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { iconSelector } from '../../../../../components/helpers'
import { formatBytes } from '../../../../../utils/bytes/bytes'
import request from '../../../../../services/request'
import { CenteredSpin } from '../../../../ui'

import style from './style.m.less'

interface IDocumentsGrid {
    documents: any
}

const DocumentsGrid: FC<IDocumentsGrid> = ({ documents }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const handleClickDownload = async (id: string, name: string) => {
        try {
            if (!id) {
                return
            }

            setLoading(true)
            const body = await request.get(`/api/documents/v1/file/${id}`, {
                responseType: 'blob',
            })

            FileSaver.saveAs(new Blob([body.data]), name)
        } finally {
            setLoading(false)
        }
    }

    const [isMoreDocumentsVisible, setIsMoreDocumentsVisible] = useState(false)
    const showMoreDocuments = () => {
        setIsMoreDocumentsVisible(!isMoreDocumentsVisible)
    }

    let documentsFirstPart
    let documentsSecondPart

    if (documents && documents.length > 6) {
        documentsFirstPart = [...documents].splice(0, 6)
        documentsSecondPart = [...documents].splice(-4)
    } else {
        documentsFirstPart = documents
        documentsSecondPart = null
    }

    return (
        <>
            <div className={style.documentsGrid}>
                {loading && <CenteredSpin />}
                {documents &&
                    documents.length !== 0 &&
                    documentsFirstPart.map(
                        (item: {
                            file_name: string
                            file_size: number
                            comment: string
                            id: string
                        }) => {
                            return (
                                <div
                                    className={style.documentsWrapper}
                                    key={decodeURI(item.file_name)}
                                >
                                    <div
                                        className={style.file}
                                        onClick={() =>
                                            handleClickDownload(item.id, decodeURI(item.file_name))
                                        }
                                    >
                                        {iconSelector(item, 'file_name')}
                                        <div className={style.fileInfo}>
                                            <div className={style.fileTitle}>
                                                {decodeURI(item.file_name)}
                                            </div>
                                            <div className={style.fileSize}>
                                                {formatBytes(item.file_size)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.description}>{item.comment}</div>
                                </div>
                            )
                        },
                    )}
                {documents &&
                    documents.length > 6 &&
                    isMoreDocumentsVisible &&
                    documentsSecondPart?.map(
                        (item: {
                            file_name: string
                            file_size: number
                            comment: string
                            id: string
                        }) => {
                            return (
                                <div
                                    className={style.documentsWrapper}
                                    key={decodeURI(item.file_name)}
                                >
                                    <div
                                        className={style.file}
                                        onClick={() =>
                                            handleClickDownload(item.id, decodeURI(item.file_name))
                                        }
                                    >
                                        {iconSelector(item, 'file_name')}
                                        <div className={style.fileInfo}>
                                            <div className={style.fileTitle}>
                                                {decodeURI(item.file_name)}
                                            </div>
                                            <div className={style.fileSize}>
                                                {formatBytes(item.file_size)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.description}>{item.comment}</div>
                                </div>
                            )
                        },
                    )}
            </div>
            {documents && documents.length > 6 && (
                <ShowMoreLink showMore={isMoreDocumentsVisible} onChange={showMoreDocuments} />
            )}
        </>
    )
}

export default DocumentsGrid
