import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Copy: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.3346 10.7493V14.2493C13.3346 17.166 12.168 18.3327 9.2513 18.3327H5.7513C2.83464 18.3327 1.66797 17.166 1.66797 14.2493V10.7493C1.66797 7.83268 2.83464 6.66602 5.7513 6.66602H9.2513C12.168 6.66602 13.3346 7.83268 13.3346 10.7493Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3346 5.74935V9.24935C18.3346 12.166 17.168 13.3327 14.2513 13.3327H13.3346V10.7493C13.3346 7.83268 12.168 6.66602 9.2513 6.66602H6.66797V5.74935C6.66797 2.83268 7.83464 1.66602 10.7513 1.66602H14.2513C17.168 1.66602 18.3346 2.83268 18.3346 5.74935Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
