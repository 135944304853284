import request from '../request'
import { IRequest } from '../types'

export interface ICurrentAccountParams {
    accountId: string
}

const errorHandler = (error: any, handler: any) => {
    if (error.response) {
        handler(error.response)
    } else if (error.request) {
        handler(error.request)
    } else {
        handler(error)
    }
}

const openAccount = (accountInfo: any, uuid: string, sign: any, userId: string) => {
    return request.post(
        `/api/green-tools/v1/accounts?request_id=${uuid}&user_uid=${userId}`,
        accountInfo,
        {
            headers: {
                Signature: sign,
            },
        },
    )
}

export const getAccountsList = async (): Promise<IRequest> => {
    try {
        const { data, status } = await request.get(`/api/green-tools/v1/accounts`)

        if (status !== 200) {
            throw new Error()
        }

        return { data, error: '' }
    } catch (error: any) {
        return { error }
    }
}

export const openAccountService = async ({
    userInfo,
    uuid,
    sign,
    userId,
    onFinish,
    onError,
}: {
    userInfo: any
    uuid: any
    sign: string
    userId?: string
    onFinish: () => void
    onError: (error: any) => void
}) => {
    try {
        const response = await openAccount(userInfo, uuid, sign, userId)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

const getAccountData = (accountId: string, userId?: string) => {
    return request.get(`/api/green-tools/v1/accounts?account_id=${accountId}&user_uid=${userId}`)
}

export const getAccountsDataService = async (
    accountId: string,
    userId?: string,
): Promise<IRequest> => {
    try {
        const { data, status } = await getAccountData(accountId, userId)

        if (status !== 200) {
            throw new Error()
        }

        return { data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}

export const setAccountMainService = async ({
    accountId,
    userId,
    onFinish,
    onError,
}: {
    accountId: string
    userId?: string
    onFinish: () => void
    onError: (error: any) => void
}) => {
    try {
        const response = await request.get(
            `/api/green-tools/v1/accounts/set-main?account_id=${accountId}&user_uid=${userId}`,
        )
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const changeAccountNameService = async ({
    accountId,
    newName,
    userId,
    onFinish,
    onError,
}: {
    accountId: string
    newName: string
    userId?: string
    onFinish: () => void
    onError: (error: any) => void
}) => {
    try {
        const response = await request.post(
            `/api/green-tools/v1/accounts/rename?user_uid=${userId}`,
            {
                account_id: accountId,
                new_name: newName,
            },
        )
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const closeAccountService = async ({
    accountId,
    userId,
    onFinish,
    onError,
}: {
    accountId: string
    userId?: string
    onFinish: () => void
    onError: (error: any) => void
}) => {
    try {
        const response = await request.get(
            `/api/green-tools/v1/accounts/close?account_id=${accountId}&user_uid=${userId}`,
        )
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}
