import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from 'antd'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'

import style from './style.m.less'

interface IESignatureModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const ESignatureModal: FC<IESignatureModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleOk = () => {
        setIsModalVisible(false)
    }
    const goToRoleChange = () => {
        navigate('/change-role')
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.eSignatureModalWrapper}>
                <div className={style.modalHeader}>{t('myProfile.eSignature')}</div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.adress')}</div>
                    <div>Текст</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.name')}</div>
                    <div>Михаил</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.surname')}</div>
                    <div>Макаров</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>
                        {t('myProfile.organization')}
                    </div>
                    <div>ОАО Лето</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.s/n')}</div>
                    <div>ldkjghfsdacgjklAD</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.publisher')}</div>
                    <div>Текст</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.subject')}</div>
                    <div>Текст</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.issued')}</div>
                    <div>16.06.2022 15:59:07</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.expires')}</div>
                    <div>15.06.2023 15:59:07</div>
                </div>
                <div className={style.eSignatureModalItemWrapper}>
                    <div className={style.eSignatureModalItemName}>{t('myProfile.keyID')}</div>
                    <div>Текст</div>
                </div>
            </div>

            <Alert
                className={style.eSignatureModalAlert}
                message={t('myProfile.eSignatureAlert')}
                type="info"
                showIcon
            />
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={goToRoleChange}
                    cancelAction={handleOk}
                    confirmTitle={t('myProfile.downloadESignature')}
                />
            </div>
        </Modal>
    )
}

export default ESignatureModal
