import { FC, ReactNode } from 'react'

import Card, { ESize } from '../../../ui/Card/Card'

import style from './style.m.less'

interface ITextCard {
    title: string
    text: string
    icon: ReactNode
    iconColor: string
    iconBGColor: string
    size?: ESize
}

const TextCard: FC<ITextCard> = ({ title, text, icon, iconColor, iconBGColor, size = ESize.M }) => (
    <Card size={size}>
        <div className={style.titleWrapper}>
            <div className={style.icon} style={{ color: iconColor, backgroundColor: iconBGColor }}>
                {icon}
            </div>
            <h3 className={style.title}>{title}</h3>
        </div>
        <p className={style.text}>{text}</p>
    </Card>
)

export default TextCard
