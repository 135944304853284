// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainInfoWrapper_p_aAQ {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 292px;\n}\n.infoBlock_fnCp4 {\n  margin-left: 8px;\n}\n.iconWrapper_cp0tY {\n  display: flex;\n}\n.documentName_AZwiO {\n  font-size: 13px;\n  line-height: 16px;\n  color: #262626;\n}\n.documentSize_XD2wy {\n  color: #bfbfbf;\n  font-size: 12px;\n}\n.documentDescription_xaeXr {\n  margin-top: 8px;\n  font-size: 12px;\n}\n.emptyDivider_XsSig {\n  height: 20px;\n}\n.textArea_YrfT7 {\n  width: 400px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/DocumentsListUI/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,aAAA;AADJ;AAIA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKA;EACI,cAAA;EACA,eAAA;AAHJ;AAMA;EACI,eAAA;EACA,eAAA;AAJJ;AAOA;EACI,YAAA;AALJ;AAQA;EACI,YAAA;EACA,kBAAA;AANJ","sourcesContent":[".mainInfoWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 292px;\n}\n\n.infoBlock {\n    margin-left: 8px;\n}\n\n.iconWrapper {\n    display: flex;\n}\n\n.documentName {\n    font-size: 13px;\n    line-height: 16px;\n    color: #262626;\n}\n\n.documentSize {\n    color: #bfbfbf;\n    font-size: 12px;\n}\n\n.documentDescription {\n    margin-top: 8px;\n    font-size: 12px;\n}\n\n.emptyDivider {\n    height: 20px;\n}\n\n.textArea {\n    width: 400px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainInfoWrapper": "mainInfoWrapper_p_aAQ",
	"infoBlock": "infoBlock_fnCp4",
	"iconWrapper": "iconWrapper_cp0tY",
	"documentName": "documentName_AZwiO",
	"documentSize": "documentSize_XD2wy",
	"documentDescription": "documentDescription_xaeXr",
	"emptyDivider": "emptyDivider_XsSig",
	"textArea": "textArea_YrfT7"
};
export default ___CSS_LOADER_EXPORT___;
