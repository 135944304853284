import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Tile: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.6077 8.875H3.39102C2.51602 8.875 2.16602 9.24833 2.16602 10.1758V12.5325C2.16602 13.46 2.51602 13.8333 3.39102 13.8333H12.6077C13.4827 13.8333 13.8327 13.46 13.8327 12.5325V10.1758C13.8327 9.24833 13.4827 8.875 12.6077 8.875Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.6077 2.1665H3.39102C2.51602 2.1665 2.16602 2.53984 2.16602 3.46734V5.824C2.16602 6.7515 2.51602 7.12484 3.39102 7.12484H12.6077C13.4827 7.12484 13.8327 6.7515 13.8327 5.824V3.46734C13.8327 2.53984 13.4827 2.1665 12.6077 2.1665Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
