import { FC } from 'react'
import { Divider } from 'antd'
import { EHistoryType, IOperation, IHistoryBlock } from '../../types'
import {
    AddCircleHistory,
    ArrowSwap,
    BookmarkMarked,
    BookmarkPlus,
    CircleRubIn,
    CircleRubOut,
    CloseCircle,
    EmptyWallet,
    Export,
    Key,
    Lock,
    RectangleListMarked,
    Trash,
    FileSwap,
    DoubleBookmark,
} from '../../../../../assets/icons'
import { currencyFormat } from '../../../../helpers'
import { moneyAmountFormatter } from '../../../../helpers/moneyAmountFormatter'
import classNames from 'classnames'
import style from './style.m.less'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'

const HistoryBlock: FC<IHistoryBlock> = ({
    settings,
    date,
    operations,
    flexStart,
    iconMargin,
    isShort,
    isContract,
    isThirdParty,
    fullScreen,
}) => {
    const dateArr = date.split(/,(.*)/s)
    const navigate = useNavigate()

    function getOperation(item: IOperation) {
        let icon: JSX.Element
        const isPositive: boolean | undefined = false
        let eventTitle: string
        let amount: string
        let isPending: boolean
        let prefixType: string | undefined

        switch (settings.historyType) {
            case EHistoryType.Payment:
                amount = item.value ? moneyAmountFormatter.format(item.value) : ''
                break
            case EHistoryType.Certs:
            case EHistoryType.Personal:
                amount = item.value ? currencyFormat(item.value) + ' кВт*ч' : ''
                break
            default:
                amount = String(item.value)
        }

        switch (item.id) {
            case 10:
                eventTitle = 'Открытие лицевого счета'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <AddCircleHistory />
                    </div>
                )
                break
            case 14:
                eventTitle = 'Закрытие лицевого счета'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <CloseCircle />
                    </div>
                )
                break
            case 15:
                eventTitle = 'Выпуск сертификата происхождения'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <BookmarkPlus />
                    </div>
                )
                break
            case 16:
                eventTitle = 'Создание ЗД'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <BookmarkPlus />
                    </div>
                )
                break
            case 17:
                eventTitle = 'Смена лицевого счета СП'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <ArrowSwap />
                    </div>
                )
                break
            case 18:
                eventTitle = 'Передача АГ по ЗД'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 19:
                eventTitle = 'Удаление сертификата происхождения'
                icon = (
                    <div className={style.iconBlockRed}>
                        <Trash />
                    </div>
                )
                break
            case 20:
                eventTitle = 'Деление сертификата происхождения'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <DoubleBookmark />
                    </div>
                )
                break
            case 21:
                eventTitle = 'Блокировка сертификата происхождения'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Lock color="grey" />
                    </div>
                )
                break
            case 22:
                eventTitle = 'Блокировка зеленого договора'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Lock />
                    </div>
                )
                break
            case 23:
                eventTitle = 'Снятие блокировки с сертификата происхождения'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Key />
                    </div>
                )
                break
            case 24:
                eventTitle = 'Снятие блокировки с зеленого договора'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Key />
                    </div>
                )
                break
            case 25:
                eventTitle = 'Блокировка СП при передаче на ЭТП'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Key />
                    </div>
                )
                break
            case 26:
                eventTitle = 'Разблокировка СП при передаче на ЭТП'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Key />
                    </div>
                )
                break
            case 27:
                eventTitle = 'Блокировка АГ для передачи по ЗД'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Key />
                    </div>
                )
                break
            case 28:
                eventTitle = 'Разблокировка АГ для передачи по ЗД'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <Key />
                    </div>
                )
                break
            case 29:
                eventTitle = 'Передача сертификата происхождения' //Смена владельца СП
                icon = (
                    <div className={style.iconBlockGrey}>
                        <FileSwap />
                    </div>
                )
                break
            case 30:
                eventTitle = 'Погашение сертификата происхождения'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 31:
                eventTitle = 'Реализация АГ по ЗД'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 32:
                eventTitle = 'Отправка СП на ЭТП'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <Export />
                    </div>
                )
                break
            case 33:
                eventTitle = 'Загрузка СП в АС «СКИЗИ»'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <Export />
                    </div>
                )
                break
            case 37:
                eventTitle = 'Регистрация ЗГО'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 41:
                eventTitle = 'Прекращение регистрации ЗГО'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 45:
                eventTitle = 'Возникновение АГ'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 46:
                eventTitle = 'Обременение и ограничение АГ'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 50:
                eventTitle = 'Обслуживание лицевого счета'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <RectangleListMarked />
                    </div>
                )
                break
            case 56:
                eventTitle = 'Пополнение платежного счета'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <EmptyWallet />
                    </div>
                )
                break
            case 58:
                eventTitle = 'Резервирование денежных средств для оплаты реализации АГ по ЗД'
                isPending = true
                prefixType = 'contract'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <EmptyWallet />
                    </div>
                )
                break
            case 59:
                eventTitle = 'Отмена резервирования денежных средств для оплаты реализации АГ по ЗД'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <EmptyWallet />
                    </div>
                )
                break
            case 60:
                eventTitle = 'Резервирование денежных средств для оплаты передачи СП через ЭТП'
                isPending = true
                icon = (
                    <div className={style.iconBlockGreen}>
                        <EmptyWallet />
                    </div>
                )
                break
            case 61:
                eventTitle =
                    'Отмена резервирования денежных средств для оплаты передачи СП через ЭТП'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <EmptyWallet />
                    </div>
                )
                break
            case 62:
                eventTitle = 'Пополнение платежного счета (ручное)'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <EmptyWallet />
                    </div>
                )
                break
            case 63:
                eventTitle = 'Прочие поступления денежных средств'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <CircleRubIn />
                    </div>
                )
                break
            case 64:
                eventTitle = 'Прочие списания'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <CircleRubOut />
                    </div>
                )
                break
            case 65:
                eventTitle = 'Добавление ГО'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 66:
                eventTitle = 'Реализация АГ'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 67:
                eventTitle = 'Реализация атрибутов генерации по Зеленому договору'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 70:
                eventTitle = 'Оплата по заявке'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <CircleRubOut />
                    </div>
                )
                break
            case 71:
                eventTitle = 'Подтверждение статуса ЗГО'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 72:
                eventTitle = 'Резервирование денежных средств для оплаты передачи АГ по ЗД'
                isPending = true
                prefixType = 'contract'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 73:
                eventTitle = 'Отмена резервирования денежных средств для оплаты передачи АГ по ЗД '
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 74:
                eventTitle = 'Указание потребителей по ЗД'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 75:
                eventTitle = 'Изменение объема СП'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 76:
                eventTitle = 'Оплата добавления ГО'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 79:
                eventTitle = 'Удостоверение атрибутов генерации в иной системе'
                icon = (
                    <div className={style.iconBlockGreen}>
                        <BookmarkPlus />
                    </div>
                )
                break
            case 80:
                eventTitle =
                    'Передача атрибутов генерации, удостоверенных в иной системе, конечному владельцу'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 82:
                eventTitle =
                    'Возникновение прав в связи с владением атрибутами генерации в целях учета обеспечения собственных производственных нужд зарегистрированного генерирующего объекта'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break
            case 83:
                eventTitle = 'Резервирования денежных средств для оплаты Погашения СП'
                isPending = true
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break

            case 85:
                eventTitle = 'Передача АГ третьему лицу при погашении СП'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break

            case 86:
                eventTitle = 'Резервирование по приобретению пакета услуг по внесению записей'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break

            case 87:
                eventTitle = 'Оплата пакета услуг по внесению записей'
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
                break

            case 88:
                eventTitle = 'Смена ЛС у ЗД'
                icon = (
                    <div className={style.iconBlockGrey}>
                        <ArrowSwap />
                    </div>
                )
                break

            default:
                eventTitle = `Операция ID ${item.id}, требуется добавить описание из аудита!`
                icon = (
                    <div className={style.iconBlockBlue}>
                        <BookmarkMarked />
                    </div>
                )
        }

        return { isPositive, eventTitle, isPending, icon, amount, prefixType }
    }

    return (
        <div className={style.historyBlockMain}>
            <div className={style.date}>
                <div className={style.dateLabel} style={{ whiteSpace: 'nowrap' }}>
                    {`${dateArr[0]}`}
                    <div className={style.current}>{` ,${dateArr[1]}`}</div>
                </div>
                <Divider style={{ minWidth: 'auto', margin: '12px 0 0 22px', width: '100%' }} />
            </div>
            {operations?.map((item, index) => {
                const { isPositive, eventTitle, icon, amount, isPending, prefixType } =
                    getOperation(item)
                const linkPrefix =
                    isContract || prefixType === 'contract'
                        ? '/user-contract-card/'
                        : isThirdParty
                        ? '/user-tools-card/'
                        : '/user-certificate-card/'
                const state =
                    isContract || prefixType === 'contract'
                        ? {
                              cardLabel: 'Зеленый договор',
                              id: item.certificate_number,
                              isOwner: true,
                          }
                        : {
                              toolType: 'Сертификаты происхождения',
                              cardLabel: 'Сертификат происхождения',
                              isUserType: true,
                              id: item.certificate_number,
                              isIssuedCert: true,
                              isOwner: true,
                          }

                return (
                    <div
                        className={classNames(
                            style.historyBlock,
                            flexStart && style.myOperationMain,
                            !flexStart && style.operationMain,
                            settings.navigate && style.navigate,
                        )}
                        key={index}
                        style={{
                            width: fullScreen ? '60%' : '100%',
                            justifyContent: 'space-between',
                        }}
                        onClick={() =>
                            settings.navigate ? navigate(`/audit/${item.event_id}`) : null
                        }
                    >
                        <div
                            className={flexStart ? style.myOperationRow : style.operationRow}
                            style={{ maxWidth: '80%' }}
                        >
                            <div>{icon}</div>
                            <div className={iconMargin ? style.myInfoBlock : style.infoBlock}>
                                <div className={style.eventTitle}>{eventTitle}</div>
                                <div className={style.time}>
                                    {moment(item.time).format('HH:mm')}
                                </div>
                            </div>
                        </div>
                        {isPending && !isShort ? (
                            <>
                                <div className={style.pendingAmount}>- {amount}</div>
                                <div className={style.pendingGT}>
                                    <div className={style.pendingGTNumberLabel}>Номер ЗИ</div>
                                    <Link
                                        to={linkPrefix + item.certificate_number}
                                        className={style.pendingGTNumber}
                                        state={state}
                                    >
                                        {item.certificate_number}
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div
                                className={classNames(
                                    isPositive && style.valueGreen,
                                    !isPositive && style.value,
                                )}
                            >
                                {amount}
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default HistoryBlock
