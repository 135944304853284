import { FC, useEffect, useState } from 'react'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import Card from './Card'
import { ICardInfo } from './Card'
import { useAppSelector } from '../../../../store/hooks'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import request from '../../../../services/request'
import { CenteredSpin, Pagination } from '../../../ui'

interface IGenaratorData {
    gos_tso_gp: ICardInfo[]
    total: number
}

const PAGE_SIZE = 10

const NotAgreedCards: FC = () => {
    const { t } = useTranslation()
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)

    const [generators, setGenerators] = useState<IGenaratorData>()
    const [loading, setLoading] = useState(true)

    const dictionariesLoading = useAppSelector((state) => state.dictionaries.loading)

    const realLoading = dictionariesLoading !== ELoading.Loaded || loading || !generators

    useEffect(() => {
        const loadGenerators = async () => {
            try {
                setLoading(true)
                const res = await request.post('/api/generators/v1/gos-tso-gp', {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters: {
                        is_confirmed: false,
                    },
                })
                setGenerators(res.data)
            } finally {
                setLoading(false)
            }
        }
        loadGenerators()
    }, [pageSize, page])

    return (
        <>
            <div className={style.cardsBlock}>
                {realLoading && <CenteredSpin />}
                {generators?.gos_tso_gp?.map((card, index) => (
                    <>
                        <Card cardInfo={card} />
                        {index !== generators.gos_tso_gp.length - 1 && <Divider />}
                    </>
                ))}
            </div>

            {!realLoading && (
                <Pagination
                    customStyle={{ width: '100%' }}
                    current={page}
                    paginationChange={(receivedPage: number, pageSize?: number) => {
                        setPage(receivedPage)
                        if (pageSize) {
                            setPageSize(pageSize)
                        }
                    }}
                    total={generators.total}
                    showSizeChanger
                    pageSize={pageSize}
                />
            )}
        </>
    )
}

export default NotAgreedCards
