import { FC, useEffect, useState } from 'react'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Alert, CenteredSpin, Checkbox, PrimaryButton } from '../../../ui'
import { Modal } from 'antd'
import { IGeneratingObject } from '../../../../store/generators/IGeneratingObject'
import { useAppSelector } from '../../../../store/hooks'
import request from '../../../../services/request'

interface IConfirmConnectionModal {
    isModalVisible: boolean
    generator: IGeneratingObject
    onClose(): void
    onCloseAndReload(): void
}

const ConfirmConnectionModal: FC<IConfirmConnectionModal> = ({
    isModalVisible,
    generator,
    onClose,
    onCloseAndReload,
}) => {
    const { t } = useTranslation()
    const userId = useAppSelector((state) => state.user.data.uid)

    const canConfirmTso = generator?.tso?.uid === userId
    const canConfirmGp = generator?.gp?.uid === userId

    const tsoNotConfirmed = canConfirmTso && !generator?.tso?.is_confirmed
    const gpNotConfirmed = canConfirmGp && !generator?.gp?.is_confirmed

    const [tsoChecked, setTsoChecked] = useState(tsoNotConfirmed)
    useEffect(() => {
        setTsoChecked(tsoNotConfirmed)
    }, [tsoNotConfirmed])

    const [gpChecked, setGpChecked] = useState(gpNotConfirmed)
    useEffect(() => {
        setGpChecked(gpNotConfirmed)
    }, [gpNotConfirmed])

    const [loading, setLoading] = useState(false)
    const handleOk = async () => {
        try {
            setLoading(true)
            await request.post(`/api/generators/v1/go-confirm`, {
                generator_id: generator.generator_id,
                confirm_tso: canConfirmTso ? tsoChecked : undefined,
                confirm_gp: canConfirmGp ? gpChecked : undefined,
            })
        } finally {
            setLoading(false)
            onCloseAndReload()
        }
    }

    const handleReject = async () => {
        try {
            setLoading(true)
            await request.post(`/api/generators/v1/go-confirm`, {
                generator_id: generator.generator_id,
                confirm_tso: canConfirmTso ? false : undefined,
                confirm_gp: canConfirmGp ? false : undefined,
            })
        } finally {
            setLoading(false)
            onCloseAndReload()
        }
    }

    return (
        <>
            {loading && <CenteredSpin />}
            <Modal
                className={style.modal}
                closable={true}
                footer={null}
                centered
                visible={isModalVisible}
                onCancel={onClose}
            >
                <div className={style.modalHeader}>Подтверждение принадлежности к ЗГО</div>
                <div className={style.itemWrapper}>
                    <div className={style.itemName}>ЗГО</div>
                    <div className={style.itemValue}>{generator?.name}</div>
                </div>
                <div className={style.itemWrapper}>
                    <div className={style.itemName}>Ваша роль</div>
                    <div className={style.itemValue}>
                        {canConfirmTso && (
                            <Checkbox
                                checked={tsoChecked}
                                className={style.checkbox}
                                onChange={(event) => setTsoChecked(event.target.checked)}
                                disabled={!canConfirmGp}
                            >
                                ТСО
                            </Checkbox>
                        )}
                        {canConfirmGp && (
                            <Checkbox
                                checked={gpChecked}
                                className={style.checkbox}
                                onChange={(event) => setGpChecked(event.target.checked)}
                                disabled={!canConfirmTso}
                            >
                                ГП
                            </Checkbox>
                        )}
                    </div>
                </div>
                {canConfirmTso && canConfirmGp && (
                    <Alert
                        className={style.alert}
                        message="Укажите принадлежность ЗГО к вашей учетной записи. Для отклонения принадлежности ЗГО к ТСО/ГП уберите обе отметки и подтвердите действие."
                        type="info"
                    />
                )}
                <div className={style.buttonsBlock}>
                    {canConfirmTso && canConfirmGp ? (
                        <PrimaryButton
                            onClick={onClose}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                    ) : (
                        <PrimaryButton
                            onClick={handleReject}
                            value="Отклонить"
                            isCancel
                            customStyle={{
                                padding: '8px 16px',
                                color: '#FF4D4F',
                                border: '1px solid #FF7875',
                            }}
                        />
                    )}
                    <PrimaryButton
                        onClick={handleOk}
                        value="Подтвердить"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default ConfirmConnectionModal
