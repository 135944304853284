import { FC, useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import { AddCircle } from '../../assets/icons'
import { useAppSelector } from '../../store/hooks'
import { EUserRole } from '../../constants/approval'
import PageTitle from '../ui/PageElements/PageTitle/PageTitle'
import PageContainer from '../ui/PageElements/PageContainer/PageContainer'
import { Card, CenteredSpin, IconButtonSmall } from '../ui'
import { Table } from './components'
import { TableRef } from './types'
import { getTabs } from './helpers'

import style from './style.m.less'
import { useNavigate } from 'react-router-dom'

const { TabPane } = Tabs

interface IUsers {
    tabKey?: string
    subTabKey?: string
}

const Users: FC<IUsers> = ({ tabKey, subTabKey }) => {
    const { t } = useTranslation()
    const {
        data: { user_role },
        isLoading,
    } = useAppSelector((state) => state.user)
    const navigate = useNavigate()
    const location = useLocation()

    const isSuper = user_role === EUserRole.SuperAdmin

    const tabs = getTabs(isSuper)

    const [currentUsers, setCurrentUsers] = useState<string>('')

    useEffect(() => {
        if (isSuper) {
            if (location.pathname === '/users/admins') {
                ref.current?.updateFilters('1')
            }
        } else {
            if (location.pathname === '/users/legal') {
                ref.current?.updateFilters('2')
            } else if (location.pathname === '/users/individual') {
                ref.current?.updateFilters('1')
            }
        }
    }, [])

    useEffect(() => {
        if (isSuper) {
            if (location.pathname === '/users/admins') {
                setCurrentUsers(tabs[1].label)
            } else {
                setCurrentUsers(tabs[0].label)
            }
        }
    }, [isSuper])

    const ref = useRef<TableRef>(null)

    const onChange = (activeKey: string) => {
        if (isSuper) {
            const activeNumber = Number(activeKey)
            setCurrentUsers(tabs[activeNumber].label)
            if (activeKey === '1') {
                navigate('/users/admins')
            } else {
                navigate('/users/admins/physical')
            }
        } else {
            ref.current?.updateFilters(activeKey as '0' | '1' | '2')
            if (activeKey === '1') {
                navigate('/users/individual')
            } else if (activeKey === '2') {
                navigate('/users/legal')
            } else {
                navigate('/users')
            }
        }
    }

    if (isLoading) {
        return <CenteredSpin />
    }

    return (
        <PageContainer>
            <PageTitle
                title={t('users.title')}
                extra={
                    isSuper && (
                        <IconButtonSmall
                            customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                            icon={<AddCircle />}
                            onClick={() => navigate('/add-user')}
                        >
                            Добавить пользователя
                        </IconButtonSmall>
                    )
                }
            />
            <Tabs
                activeKey={tabKey}
                defaultActiveKey="0"
                onChange={onChange}
                className={style.tabs}
            >
                {tabs.map(({ label, key }) => (
                    <TabPane tab={label} key={key} />
                ))}
            </Tabs>
            <Card>
                <Table subTabKey={subTabKey} currentUsers={currentUsers} ref={ref} />
            </Card>
        </PageContainer>
    )
}

export default Users
