import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './style.m.less'
import { Card, IconButton, CenteredSpin } from '../ui'
import { Drop } from '../../assets/icons'
import { Default } from './components'
import useMount from '../hooks/useMount'
import useRequest from '../hooks/useRequest'
import { getGenerators } from './config'

const FuelRegistry: FC = () => {
    const navigate = useNavigate()
    const { fetch, result, isLoading } = useRequest(getGenerators)

    useMount(() => {
        fetch({})
    })

    if (isLoading || !result) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <h1 className={style.title}>{'Данные по топливу'}</h1>
            <IconButton
                customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                customStyle={{ marginTop: '32px' }}
                icon={<Drop />}
                onClick={() => navigate('/go-ku-submit-fuel')}
                disabled={!result?.gos}
            >
                Подать данные по топливу
            </IconButton>
            <Card style={{ marginTop: '24px' }}>
                <Default />
            </Card>
        </div>
    )
}

export default FuelRegistry
