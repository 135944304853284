import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, IconButtonSmall } from '../'
import { Settings } from '../../../assets/icons'

const SortButton: FC<{ onClick(): void, type?: undefined | string }> = ({ onClick, type = undefined }) => {
    const { t } = useTranslation()

    if(type === 'small') {
        return (
            <IconButtonSmall
            icon={<Settings />}
            onClick={onClick}
            >
            {t('sortButton.sort')}
        </IconButtonSmall>
    )
    }

    return (
        <IconButton
            icon={<Settings />}
            customIconStyle={{ marginRight: '7px', marginTop: '3px' }}
            onClick={onClick}
        >
            {t('sortButton.sort')}
        </IconButton>
    )
}

export default SortButton
