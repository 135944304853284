import { FC, useState } from 'react'
import { DownMini } from '../../../../../../assets/icons'
import style from './style.m.less'

interface IComment {
    comments: any
}

const Comment: FC<IComment> = ({ comments }) => {
    const [hiddenInfo, setHiddenInfo] = useState(false)

    const toggleHidden = () => {
        setHiddenInfo(!hiddenInfo)
    }

    return (
        <div className={style.mainWrapper}>
            <div className={style.fullRow} onClick={toggleHidden}>
                <div className={style.bold}>Сопутствующие изменения функционала</div>
                <DownMini />
            </div>
            {hiddenInfo && (
                <div className={style.commentsMain}>
                    {comments?.map((item: any) => {
                        const { title, operations } = item
                        return (
                            <>
                                <div>{title}</div>
                                {operations?.map((operation: string, index: number) => (
                                    <div>{`${index + 1}. ${operation}`}</div>
                                ))}
                            </>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default Comment
