import { FC, ReactNode } from 'react'
import { CloseCircle, CheckCircle } from '../../../assets/icons'
import style from './style.m.less'

interface IMessageComponent {
    header?: string
    message?: string
    icon?: ReactNode
    isError?: boolean
}

const MessageComponent: FC<IMessageComponent> = ({ header, message, icon, isError }) => {
    return (
        <div className={style.mainWrapper}>
            {icon ? icon : isError ? <CloseCircle /> : <CheckCircle />}
            <div className={style.messageWrapper}>
                <div className={style.messageHeader}>{header}</div>
                <div className={style.messageContent}>{message ? message : ''}</div>
            </div>
        </div>
    )
}

export default MessageComponent
