import { FC } from 'react'
import { InfoCircle } from '../../../../../../assets/icons'
import { useNavigate } from 'react-router-dom'
import { Modal, PrimaryButton } from '../../../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

interface ICheckMailModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const CheckMailModal: FC<ICheckMailModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleOk = () => {
        setIsModalVisible(false)
        navigate('/main')
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.mainWrapper}>
                <InfoCircle />
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>{t('login.checkMail')}</div>
                    <div className={style.modalText}>{t('login.checkMailText')}</div>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={handleOk}
                            customStyle={{ padding: '8px 16px' }}
                            value={t('login.ok')}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CheckMailModal
