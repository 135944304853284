import { IOperationConfig } from '../../../ui/OperationsMapper/types'

export const historyConfig: IOperationConfig[] = [
    {
        day: 'Сегодня,',
        weekday: ' 17 июня, пятница',
        operations: [
            {
                type: 'Погашение сертификата происхождения',
                time: '12:24',
                value: 'Скачать запрос и подпись',
            },
            {
                type: 'Деление сертификата происхождения',
                time: '08:36',
                value: 'Скачать запрос и подпись',
            },
        ],
    },
    {
        day: 'Вчера,',
        weekday: '16 июня, четверг',
        operations: [
            {
                type: 'Деление сертификата происхождения',
                time: '20:39',
                value: 'Скачать запрос и подпись',
            },
        ],
    },
]

export const historyContractsConfig: IOperationConfig[] = [
    {
        day: 'Сегодня,',
        weekday: ' 17 июня, пятница',
        operations: [
            {
                type: 'Передача данных по Зеленому договору',
                time: '12:24',
                value: 'Скачать запрос и подпись',
            },
            {
                type: 'Реализация атрибутов генерации',
                time: '08:36',
                value: 'Скачать запрос и подпись',
            },
        ],
    },
    {
        day: 'Вчера,',
        weekday: '16 июня, четверг',
        operations: [
            {
                type: 'Создание Зеленого договора',
                time: '20:39',
                value: 'Скачать запрос и подпись',
            },
        ],
    },
]
