// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper_VAPhz {\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n}\n.registryInfoBlock_kz6bA {\n  width: 700px;\n  font-size: 16px;\n  line-height: 24px;\n}\n.tabs_y6VQk {\n  margin-top: 32px;\n  font-weight: 600;\n  color: #595959;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GreenTools/components/UnregisteredLayout/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AACJ;AAEA;EACI,YAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AADJ","sourcesContent":[".mainWrapper {\n    display: flex;\n    flex-direction: column;\n    margin-top: 32px;\n}\n\n.registryInfoBlock {\n    width: 700px;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.tabs {\n    margin-top: 32px;\n    font-weight: 600;\n    color: #595959;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": "mainWrapper_VAPhz",
	"registryInfoBlock": "registryInfoBlock_kz6bA",
	"tabs": "tabs_y6VQk"
};
export default ___CSS_LOADER_EXPORT___;
