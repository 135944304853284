import { FC, useState, useEffect, useCallback } from 'react'
import moment, { Moment } from 'moment'
import { DatePicker, DropdownSelector2, TimeRangePicker } from '../../../../../ui'
import { useTranslation } from 'react-i18next'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import style from './style.m.less'
import { useDebouncedRequest } from '../../../../../../components/hooks'

const options = [
    { id: 'a851024a-58ae-48dc-b085-178e9966f180', value: 'Все' },
    { id: '9bc31bb4-8ec3-4520-8a18-9eba6400dd57', value: 'В залоге' },
]

interface IMoreFilters {
    toolType: string
    updateFilters: any
    generators: any
    isAdminType: boolean
    onTimeRangePickerChange: (keys: string[]) => (dates: [Moment, Moment] | null) => void
}

const MoreFilters: FC<IMoreFilters> = ({
    toolType,
    updateFilters,
    generators,
    isAdminType,
    onTimeRangePickerChange,
}) => {
    const { t } = useTranslation()

    const isCertificate = toolType === 'Сертификаты происхождения'
    const isThirdParty = toolType === 'Сторонние инструменты'

    const [isMoreFilterVisible, setIsMoreFilterVisible] = useState(false)
    const showMoreFilter = () => {
        setIsMoreFilterVisible(!isMoreFilterVisible)
    }

    const [currentGeneratorValue, setCurrentGeneratorValue] = useState<string>()

    useEffect(() => {
        setCurrentGeneratorValue(undefined)
    }, [generators])

    const debouncedGetGeneratorByName = useDebouncedRequest(
        (value: string) => updateFilters(value, 'generator_name'),
        500,
    )

    const generatorsOptions = generators
        ? [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
              ...generators,
          ]
        : [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
          ]

    return (
        <>
            {isMoreFilterVisible && (
                <div className={style.filter}>
                    {isAdminType && isCertificate && (
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>Залог</div>
                            <DropdownSelector2
                                customStyle={{ width: '296px' }}
                                options={options}
                                onChange={(value) => {
                                    const returnedValue = value === 'Все' ? null : true
                                    updateFilters(returnedValue, 'is_deposit')
                                }}
                                key={'c1155cc1-3763-4112-8736-2b7b8998a7fe'}
                            />
                        </div>
                    )}
                    {isCertificate ? (
                        <>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>ЗГО</div>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={generatorsOptions?.map((item: any) => ({
                                        text: item.name,
                                        value: item.id,
                                        id: item.id,
                                    }))}
                                    placeholder="Выберите ЗГО"
                                    value={currentGeneratorValue}
                                    searchValue={currentGeneratorValue}
                                    onKeyUp={(event) => {
                                        setCurrentGeneratorValue(event.target.value)
                                        debouncedGetGeneratorByName(event.target.value)
                                    }}
                                    onChange={(value) => {
                                        setCurrentGeneratorValue(value)
                                        updateFilters('', 'generator_name')
                                        updateFilters(value, 'generator_id')
                                    }}
                                    key={'dbbb790c-cb63-456f-a59c-5a44d28cd7d7'}
                                />
                            </div>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Дата выдачи</div>
                                <TimeRangePicker
                                    style={{ width: '296px' }}
                                    onChange={(value) => updateFilters(value, 'rangeDate')}
                                    key={'d755d5b4-0d67-463f-a904-69fc5307061c'}
                                />
                            </div>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Период производства</div>
                                <DatePicker
                                    className={style.select}
                                    picker="month"
                                    format="MMMM YYYY"
                                    disabledDate={(date) => date.isAfter(moment().endOf('day'))}
                                    onChange={(value: any) => {
                                        updateFilters(
                                            {
                                                month: moment(value).month() + 1,
                                                year: moment(value).year(),
                                            },
                                            'date',
                                        )
                                    }}
                                    key={'d715b269-be31-43c7-b191-6d7d3f6cc1b3'}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Период производства</div>
                                <DatePicker
                                    className={style.select}
                                    onChange={(value) => {
                                        updateFilters(
                                            {
                                                month: moment(value).month() + 1,
                                                year: moment(value).year(),
                                            },
                                            'date',
                                        )
                                    }}
                                    picker="month"
                                    disabledDate={(date) => date.isAfter(moment().endOf('day'))}
                                    key={'2ac0941d-fbaf-4935-a7d2-ae37f3be1b98'}
                                />
                            </div>
                            <div className={style.leftGapBlock}>
                                <div>Дата создания</div>
                                <div className={style.inputBlock}>
                                    <TimeRangePicker
                                        onlyBeforeDateAndToday={moment()}
                                        style={{ width: '296px' }}
                                        onChange={onTimeRangePickerChange(
                                            isThirdParty
                                                ? ['created_from', 'created_to']
                                                : ['created_ts_from', 'created_ts_to'],
                                        )}
                                        key={'218219a1-3cb4-469f-91a7-acf86cdfa86f'}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className={style.moreFilter} onClick={showMoreFilter}>
                {!isMoreFilterVisible && <span>{t('questionnaireApproval.moreFilters')}</span>}
                {isMoreFilterVisible && <span>{t('questionnaireApproval.fewerFilters')}</span>}
                {!isMoreFilterVisible && <DownOutlined className={style.icon} />}
                {isMoreFilterVisible && <UpOutlined className={style.icon} />}
            </div>
        </>
    )
}

export default MoreFilters
