import { FC, useState } from 'react'
import { Tabs } from 'antd'
import { Import } from '../../assets/icons'
import style from './style.m.less'
import { Card, Box, IconButton } from '../ui'
import { Closed, Opened, DownloadModal } from './components'
import { useTranslation } from 'react-i18next'

const QuestionnaireApproval: FC = () => {
    const { t } = useTranslation()
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false)

    return (
        <div className={style.container}>
            <Box direction="row" justify="space-between" align="center">
                <h1 className={style.title}>{t('questionnaireApproval.title')}</h1>
                <IconButton
                    icon={<Import size={20} />}
                    customIconStyle={{ marginTop: '5px', marginRight: '8px' }}
                    onClick={() => setIsDownloadModalVisible(true)}
                >
                    Выгрузить анкеты пользователей
                </IconButton>
            </Box>
            <div className={style.tabs}>
                <Card>
                    <Opened />
                </Card>
            </div>
            <DownloadModal
                isModalVisible={isDownloadModalVisible}
                setIsModalVisible={setIsDownloadModalVisible}
            />
        </div>
    )
}

export default QuestionnaireApproval
