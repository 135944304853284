// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper_dn0q8 {\n  margin-top: 16px;\n  padding: 12px 24px;\n  background: rgba(230, 241, 255, 0.35);\n  border: 1px solid #e6f1ff;\n  border-radius: 16px;\n}\n.bold_gLW_i {\n  font-weight: 600;\n}\n.fullRow_FSbYf {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n}\n.commentsMain_ymhTy {\n  margin-top: 8px;\n  line-height: 22px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContinueRegistration/components/TermsModal/components/Comment/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;EACA,qCAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AADJ;AAIA;EACI,eAAA;EACA,iBAAA;AAFJ","sourcesContent":[".mainWrapper {\n    margin-top: 16px;\n    padding: 12px 24px;\n    background: rgba(230, 241, 255, 0.35);\n    border: 1px solid #e6f1ff;\n    border-radius: 16px;\n}\n\n.bold {\n    font-weight: 600;\n}\n\n.fullRow {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n}\n\n.commentsMain {\n    margin-top: 8px;\n    line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": "mainWrapper_dn0q8",
	"bold": "bold_gLW_i",
	"fullRow": "fullRow_FSbYf",
	"commentsMain": "commentsMain_ymhTy"
};
export default ___CSS_LOADER_EXPORT___;
