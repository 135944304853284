import ShowMoreLink from '../../../ui/ShowMoreLink/ShowMoreLink'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DocIcon, PdfIcon, XlsIcon } from '../../../../assets/icons'

import style from './style.m.less'

const DocumentsGrid: FC = () => {
    const { t } = useTranslation()

    const [isMoreDocumentsVisible, setIsMoreDocumentsVisible] = useState(false)
    const showMoreDocuments = () => {
        setIsMoreDocumentsVisible(!isMoreDocumentsVisible)
    }

    return (
        <>
            <div className={style.documentsGrid}>
                <div className={style.documentsWrapper}>
                    <div className={style.file}>
                        <PdfIcon />
                        <div className={style.fileInfo}>
                            <div className={style.fileTitle}>
                                {t('myProfile.regulationAssociation')}
                            </div>
                            <div className={style.fileSize}>183 {t('myProfile.mb')}</div>
                        </div>
                    </div>
                    <div className={style.description}>{t('myProfile.documentDescription')}</div>
                </div>
                <div className={style.documentsWrapper}>
                    <div className={style.file}>
                        <DocIcon />
                        <div className={style.fileInfo}>
                            <div className={style.fileTitle}>
                                {t('myProfile.regulationProcedure')}
                            </div>
                            <div className={style.fileSize}>146 {t('myProfile.kb')}</div>
                        </div>
                    </div>
                    <div className={style.description}>{t('myProfile.documentDescription')}</div>
                </div>
                <div className={style.documentsWrapper}>
                    <div className={style.file}>
                        <DocIcon />
                        <div className={style.fileInfo}>
                            <div className={style.fileTitle}>{t('myProfile.newDoc')}</div>
                            <div className={style.fileSize}>146 {t('myProfile.kb')}</div>
                        </div>
                    </div>
                    <div className={style.description}>{t('myProfile.documentDescription')}</div>
                </div>
                <div className={style.documentsWrapper}>
                    <div className={style.file}>
                        <DocIcon />
                        <div className={style.fileInfo}>
                            <div className={style.fileTitle}>{t('myProfile.newDoc2')}</div>
                            <div className={style.fileSize}>146 {t('myProfile.kb')}</div>
                        </div>
                    </div>
                    <div className={style.description}>{t('myProfile.documentDescription')}</div>
                </div>
                <div className={style.documentsWrapper}>
                    <div className={style.file}>
                        <XlsIcon />
                        <div className={style.fileInfo}>
                            <div className={style.fileTitle}>
                                {t('myProfile.generationAttributes')}
                            </div>
                            <div className={style.fileSize}>183 {t('myProfile.mb')}</div>
                        </div>
                    </div>
                    <div className={style.description}>{t('myProfile.documentDescription')}</div>
                </div>
                <div className={style.documentsWrapper}>
                    <div className={style.file}>
                        <DocIcon />
                        <div className={style.fileInfo}>
                            <div className={style.fileTitle}>{t('myProfile.newDoc3')}</div>
                            <div className={style.fileSize}>146 {t('myProfile.kb')}</div>
                        </div>
                    </div>
                    <div className={style.description}>{t('myProfile.documentDescription')}</div>
                </div>
                {isMoreDocumentsVisible && (
                    <>
                        <div className={style.documentsWrapper}>
                            <div className={style.file}>
                                <DocIcon />
                                <div className={style.fileInfo}>
                                    <div className={style.fileTitle}>{t('myProfile.newDoc7')}</div>
                                    <div className={style.fileSize}>146 {t('myProfile.kb')}</div>
                                </div>
                            </div>
                            <div className={style.description}>
                                {t('myProfile.documentDescription')}
                            </div>
                        </div>
                        <div className={style.documentsWrapper}>
                            <div className={style.file}>
                                <PdfIcon />
                                <div className={style.fileInfo}>
                                    <div className={style.fileTitle}>
                                        {t('myProfile.regulationAssociation')}
                                    </div>
                                    <div className={style.fileSize}>183 {t('myProfile.mb')}</div>
                                </div>
                            </div>
                            <div className={style.description}>
                                {t('myProfile.documentDescription')}
                            </div>
                        </div>
                        <div className={style.documentsWrapper}>
                            <div className={style.file}>
                                <DocIcon />
                                <div className={style.fileInfo}>
                                    <div className={style.fileTitle}>{t('myProfile.newDoc9')}</div>
                                    <div className={style.fileSize}>146 {t('myProfile.kb')}</div>
                                </div>
                            </div>
                            <div className={style.description}>
                                {t('myProfile.documentDescription')}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <ShowMoreLink showMore={isMoreDocumentsVisible} onChange={showMoreDocuments} />
        </>
    )
}

export default DocumentsGrid
