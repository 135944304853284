import { FC, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import { Comment } from './components'
import { Modal } from '../../../ui'
import { Close, Lock, Tick } from '../../../../assets/icons'
import { getRoleTermsService } from '../../../../services/user'
import { userRolePicker } from '../../../../components/helpers'
import useMount from '../../../hooks/useMount'
import { rolesConfig } from './config'

import style from './style.m.less'

interface ITermsModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    userType: number
    userRole: number
    isRegistration: boolean
}

const TermsModal: FC<ITermsModal> = ({
    isModalVisible,
    setIsModalVisible,
    userType,
    userRole,
    isRegistration,
}) => {
    const { t } = useTranslation()

    const [roleTerms, setRoleTerms] = useState<any>([])

    const getRoleTerms = async () => {
        const res = await getRoleTermsService(userType, (error) => {
            console.log(error)
        })
        const termsArr = res?.data.role_terms
        const actualTerms = termsArr.filter((item: any) => item.user_role_actual === userRole)
        setRoleTerms(actualTerms)
    }

    useMount(() => {
        if (!isRegistration) {
            getRoleTerms()
        }
    })

    const getBack = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal
            isModalVisible={isModalVisible}
            bodyStyle={isRegistration ? { width: '500px' } : undefined}
        >
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>
                        {isRegistration ? 'Доступные роли' : t('editMyProfile.roleTerms')}
                    </div>
                    <div className={style.closeButton} onClick={getBack}>
                        <Close />
                    </div>
                </div>
            </div>
            {isRegistration &&
                rolesConfig?.map((item: any, index: number) => {
                    const { name, values } = item
                    return (
                        <Fragment key={name}>
                            {index === 0 ? <div className={style.emptyDivider} /> : <Divider />}
                            <div className={style.termsBlock}>
                                <div className={style.topTermsRow}>
                                    <div className={style.newRoleBlock}>{name}</div>
                                    <div>
                                        {values?.map((term: any, index: number) => {
                                            return (
                                                <div
                                                    key={`$id-${index}`}
                                                    className={style.termsRow}
                                                >
                                                    {`${index + 1}. ${term}`}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                })}
            {!isRegistration &&
                roleTerms &&
                roleTerms.length !== 0 &&
                roleTerms.map((item: any, index: number) => {
                    const { user_role_new, terms, comments } = item
                    return (
                        <Fragment key={user_role_new}>
                            {index === 0 ? <div className={style.emptyDivider} /> : <Divider />}
                            <div className={style.termsBlock}>
                                <div className={style.topTermsRow}>
                                    <div className={style.newRoleBlock}>
                                        {terms.some((item: any) => !item.status) && (
                                            <div className={style.lock}>
                                                <Lock />
                                            </div>
                                        )}
                                        {userRolePicker(user_role_new)}
                                    </div>
                                    <div>
                                        {terms?.map((term: any, index: number) => {
                                            return (
                                                <div
                                                    key={`$id-${index}`}
                                                    className={style.termsRow}
                                                >
                                                    {`${index + 1}. ${term.title}`}
                                                    {term.status && (
                                                        <div className={style.tick}>
                                                            <Tick size={16} />
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <Comment comments={comments} />
                            </div>
                        </Fragment>
                    )
                })}
        </Modal>
    )
}

export default TermsModal
