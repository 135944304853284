// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_y5964 {\n  display: flex;\n  gap: 16px;\n  justify-content: space-between;\n  width: 100%;\n}\n.filterItem_hwCi4 {\n  flex: 1;\n}\n.label_FwgVr {\n  margin-bottom: 2px;\n}\n.input_ulr1L {\n  border-radius: 8px;\n}\n.select_TPRIS {\n  width: 100%;\n}\n.datePicker_JtFMp {\n  height: 32px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Audit/components/Filter/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,8BAAA;EACA,WAAA;AACJ;AAEA;EACI,OAAA;AAAJ;AAGA;EACI,kBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;EACI,WAAA;AAHJ;AAMA;EACI,YAAA;EACA,WAAA;AAJJ","sourcesContent":[".filter {\n    display: flex;\n    gap: 16px;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.filterItem {\n    flex: 1;\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.select {\n    width: 100%;\n}\n\n.datePicker {\n    height: 32px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "filter_y5964",
	"filterItem": "filterItem_hwCi4",
	"label": "label_FwgVr",
	"input": "input_ulr1L",
	"select": "select_TPRIS",
	"datePicker": "datePicker_JtFMp"
};
export default ___CSS_LOADER_EXPORT___;
