// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".moreDocuments_eIKGf {\n  display: flex;\n  gap: 4px;\n  margin-top: 32px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: #296dff;\n  cursor: pointer;\n}\n.moreDocumentsNoMargin_b5GZx {\n  display: flex;\n  gap: 4px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: #296dff;\n  cursor: pointer;\n}\n.icon_GbdG_ {\n  font-size: 10px;\n  margin-top: 7px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/ShowMoreLink/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,QAAA;EACA,gBAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AACJ;AAEA;EACI,aAAA;EACA,QAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAAJ;AAGA;EACI,eAAA;EACA,eAAA;AADJ","sourcesContent":[".moreDocuments {\n    display: flex;\n    gap: 4px;\n    margin-top: 32px;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    color: #296dff;\n    cursor: pointer;\n}\n\n.moreDocumentsNoMargin {\n    display: flex;\n    gap: 4px;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    color: #296dff;\n    cursor: pointer;\n}\n\n.icon {\n    font-size: 10px;\n    margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreDocuments": "moreDocuments_eIKGf",
	"moreDocumentsNoMargin": "moreDocumentsNoMargin_b5GZx",
	"icon": "icon_GbdG_"
};
export default ___CSS_LOADER_EXPORT___;
