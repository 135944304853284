import { FC, useEffect, useState } from 'react'
import {
    Alert,
    Card,
    CenteredSpin,
    // IconButton,
} from '../ui'
// import { useGeneratorData } from '../hooks/useGeneratorData'
import {
    GraphFrame,
    ControlsBlock,
    FreeGenerationAttributes,
    BlockedGenerationAttributes,
    GeneratorInfoFrame,
    DocumentsGrid,
    HeaderBlock,
    OperationsHistory,
    IsDeregistered,
} from './components'
import { MainInfoConfig } from './generatorInfoConfig'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { getDictionaries, selectDictionariesMaps } from '../../store/dictionaries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ELoading } from '../../store/dictionaries/interfaces'
import moment from 'moment'
import { EUserRole, GeneratorStatus } from '../../constants/approval'
import { getUserInfo } from '../../store/user'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'

interface IDetailedCard {
    isAuth: boolean
}

const DetailedCard: FC<IDetailedCard> = ({ isAuth }) => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()
    const {
        fetch,
        result: generator,
        isLoading: loading,
    } = useRequest({
        link: '/api/generators/v1/go',
        method: RequestTypes.get,
    })

    const { fetch: getTotalAG, result: responseTotalAG } = useRequest({
        link: `/api/generation_attributes/v1/gas/ga/total`,
        method: RequestTypes.get,
    })

    const { fetch: getGeneratorAG, result: responseGeneratorAG } = useRequest({
        link: `/api/generation_attributes/v1/gas/ga/get-without-period`,
        method: RequestTypes.get,
    })

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isBlockingModalVisible, setIsBlockingModalVisible] = useState(false)

    const dispatch = useAppDispatch()
    const isLoading = useAppSelector((state) => state.dictionaries.loading)

    const getGenerators = () => {
        fetch({
            getParams: {
                generator_id: id,
            },
        })
    }

    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [isLoading])

    useEffect(() => {
        if (isAuth) {
            dispatch(getUserInfo())
        }
    }, [isAuth])
    const userData = useAppSelector((state) => state.user.data)
    const userId = userData.uid
    const userRole = userData.user_role
    const dictionaries = useAppSelector(selectDictionariesMaps)

    const [refreshHistory, setRefreshHistory] = useState(Date.now())
    const updateHistory = () => setRefreshHistory(Date.now())
    const waitingForPayment = generator?.generator_status === GeneratorStatus.WaitForPayment

    const isCurrentOwnerCreateGO =
        userId === generator?.go_owner.uid && userRole === EUserRole.GOOwner

    const showHistoryBlock =
        (userRole === EUserRole.GOOwner && generator?.go_owner.uid === userId) ||
        userRole === EUserRole.Operator ||
        userRole === EUserRole.Administrator ||
        userRole === EUserRole.SuperAdmin

    const { renewableEnergyTypesMap } = dictionaries

    useMount(() => getGenerators())

    const isFiled = [
        GeneratorStatus.Canceled,
        GeneratorStatus.UnderConsideration,
        GeneratorStatus.New,
        GeneratorStatus.WaitForPayment,
    ].includes(generator?.generator_status)

    // в ФТ  Отказано (на бэке - Не согласовано) Canceled
    const isCanceled = generator?.generator_status === GeneratorStatus.Canceled
    const isUnderConsideration = generator?.generator_status === GeneratorStatus.UnderConsideration

    const isDeregistered =
        !!generator?.ts_deregistered && moment(generator.ts_deregistered).isSameOrBefore(moment())

    const tsoConfirmed = generator?.tso?.uid === userId && generator?.tso?.is_confirmed
    const gpConfirmed = generator?.gp?.uid === userId && generator?.gp?.is_confirmed
    const isOperator = userRole === EUserRole.Operator
    const isTSOGPUser = userRole === EUserRole.TSO
    const isUserWithouPA = userRole === EUserRole.UserWithouPA
    const showBlocksCondition =
        isAuth &&
        !isFiled &&
        userRole !== undefined &&
        !isTSOGPUser &&
        !isUserWithouPA &&
        !isOperator &&
        showHistoryBlock

    const blockedFunctionality = [] as string[]

    const fetchTotalAG = () => {
        getTotalAG({ getParams: { generator_id: id } })
    }

    useEffect(() => {
        reNewTotalAG()
        fetchTotalAG()
    }, [])

    useEffect(() => {
        if (responseTotalAG?.locked_volume) {
            getGeneratorAG({ addToLink: `?generator_id=${id}`, showError: false })
        }
    }, [responseTotalAG])

    const reNewTotalAG = () => {
        if (showHistoryBlock) {
            fetchTotalAG()
        }
    }

    if (!loading && generator) {
        Object.entries(generator.blocks).forEach(
            ([key, value]) =>
                value &&
                blockedFunctionality.push(
                    key === 'ga_fixation'
                        ? 'Фиксация Атрибутов генерации'
                        : key === 'ga_redemption'
                        ? 'Собственное погашение АГ'
                        : key === 'ga_transfer'
                        ? 'Передача АГ по ЗД'
                        : key === 'gt_emission'
                        ? 'Выпуск ЗИ'
                        : '',
                ),
        )
    }

    return (
        <div className={style.detailedCardMain}>
            {(loading || isLoading === ELoading.Loading) && <CenteredSpin />}
            <HeaderBlock
                label={generator?.name}
                energyType={
                    generator && renewableEnergyTypesMap?.get(generator?.renewable_energy_type_id)
                }
                status={generator?.generator_status}
                showConfirmedTag={isTSOGPUser}
                tsoConfirmed={tsoConfirmed}
                gpConfirmed={gpConfirmed}
            />
            {
                // isFiled ? (
                //     <ControlsBlockFiled
                //         id={id}
                //         name={generator?.name}
                //         showDelete={generator?.generator_status === GeneratorStatus.Canceled}
                //         idDeleted={generator?.generator_status === GeneratorStatus.Deleted}
                //     />
                // ) : (
                isAuth && showHistoryBlock ? (
                    <ControlsBlock
                        id={id}
                        isActive={generator?.is_active}
                        requestId={generator?.request_id}
                        showDeregister={
                            !generator?.ts_deregistered &&
                            generator?.generator_status === GeneratorStatus.Approved
                        }
                        showCancelDeregister={
                            !!generator?.ts_deregistered &&
                            moment(generator.ts_deregistered).isAfter(moment())
                        }
                        showReturnRegister={isDeregistered}
                        goName={generator?.name}
                        blocks={generator?.blocks}
                        status={generator?.generator_status}
                        onReload={() => {
                            getGenerators()
                            updateHistory()
                        }}
                        tsoNotConfirmed={!tsoConfirmed}
                        gpNotConfirmed={!gpConfirmed}
                        generator={generator}
                        showDelete={isCanceled && isCurrentOwnerCreateGO}
                        idDeleted={isCanceled && isCurrentOwnerCreateGO}
                        isCanceled={isCanceled}
                        isUnderConsideration={isUnderConsideration}
                    />
                ) : (
                    <></>
                )
                // )
            }
            {Boolean(blockedFunctionality.length) && (
                <Card className={style.card}>
                    <Alert
                        message={
                            <>
                                <div className={style.alertHeader}>
                                    На генерирующий объект наложена блокировка
                                </div>
                                <div>Заблокированный функционал:</div>
                                {blockedFunctionality.map((item) => (
                                    <div>- {item}</div>
                                ))}
                            </>
                        }
                        type="error"
                        showIcon={false}
                    />
                </Card>
            )}
            {isDeregistered && <IsDeregistered date={generator.ts_deregistered} />}
            {!isFiled && isAuth && <GraphFrame />}
            {showBlocksCondition && (
                <FreeGenerationAttributes
                    tsActivation={generator?.ts_activation}
                    blockStatus={generator?.blocks}
                    isModalVisible={isModalVisible}
                    isBlockingModalVisible={isBlockingModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    setIsBlockingModalVisible={setIsBlockingModalVisible}
                />
            )}
            {showBlocksCondition && (
                <BlockedGenerationAttributes
                    refreshTotalAG={fetchTotalAG}
                    reason={responseGeneratorAG?.lock_description}
                    value={responseTotalAG?.locked_volume}
                />
            )}
            {!!generator && dictionaries && (
                <GeneratorInfoFrame
                    label={t('detailedCard.commonInfo')}
                    config={MainInfoConfig(generator, dictionaries)}
                    statusBlock={
                        <>
                            {/* <Alert
                             className={style.alert}
                             message={
                                 <>
                                     Данные находятся в обработке.
                                     <br />
                                     После проверки Оператором Системы Вы снова сможете редактировать
                                     данные по ГО.
                                 </>
                             }
                             type="info"
                             showIcon
                         /> */}
                            {generator?.generator_status === GeneratorStatus.Canceled &&
                                generator?.generator_comment && (
                                    <div className={style.statusNotAgreedContainer}>
                                        <div className={style.statusText}>Данные не приняты.</div>
                                        <div className={style.statusReason}>
                                            {generator.generator_comment}
                                        </div>
                                    </div>
                                )}
                        </>
                    }
                />
            )}
            {isAuth &&
                !!generator?.documents &&
                userRole !== undefined &&
                !isTSOGPUser &&
                !isUserWithouPA &&
                showHistoryBlock && <DocumentsGrid documents={generator.documents} />}
            {/* {type !== 'userGenerators' && (
                <GeneratorInfoFrame label={t('detailedCard.ownerInfo')} config={ownerInfoConfig} />
            )} */}
            {showHistoryBlock && !waitingForPayment && <OperationsHistory key={refreshHistory} />}
        </div>
    )
}

export default DetailedCard
