import { FC } from 'react'
import moment from 'moment'
import useRequest from '../../../hooks/useRequest'
import PageContainer from '../../../ui/PageElements/PageContainer/PageContainer'
import BackLink from '../../../ui/BackTitle/BackTitle'
import { useParams } from 'react-router-dom'
import Card from '../../../ui/Card/Card'
import { Divider, Alert } from 'antd'
import { Box } from '../../../ui/Box'
import { CenteredSpin, ImportButton } from '../../../ui'
import { getDictionaries } from '../../../../store/dictionaries'
import { selectDictionariesOptions } from '../../../../store/dictionaries'
import style from './style.m.less'
import { Stamp } from '../../../../assets/icons'
import { monthPicker } from '../../../helpers'
import { getFuelDetails } from '../../config'
import useMount from '../../../hooks/useMount'
import { getStatus } from '../Tables/Default'
import { fuelInfoConfig } from './fuelInfoConfig'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import useDownloadSignedRequest from '../../../hooks/useDownloadSignedRequest'
import DocumentsGrid from './DocumentsGrid/DocumentsGrid'

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const FuelCard: FC = () => {
    const dispatch = useAppDispatch()
    const { downloadSignedRequest } = useDownloadSignedRequest()
    const isLoadingDict = useAppSelector((state) => state.dictionaries.loading)
    const { fetch, result, isLoading } = useRequest(getFuelDetails)

    const { id } = useParams<{ id: string }>()

    useMount(() => {
        dispatch(getDictionaries())
        fetch({ addToLink: id })
        scrollToTop()
    })

    const dictionaries = useAppSelector(selectDictionariesOptions)
    const fuelOptions = dictionaries?.fuelOptions
    const nonrenewableFuelOptions = dictionaries?.nonrenewableFuelOptions

    if (isLoadingDict !== ELoading.Loaded || !dictionaries || !result || isLoading) {
        return <CenteredSpin />
    }

    const renewArray = []
    const nonrenewArray = []
    const mergedFuelsArray = [...result.fuels_data, ...result.renewable_fuels]
    mergedFuelsArray.forEach((item: any) => {
        if (fuelOptions?.some((option) => option.id === item.fuel_id)) {
            const found = fuelOptions.find((option) => option.id === item.fuel_id)
            renewArray.push({ ...found, ...item })
        } else if (nonrenewableFuelOptions?.some((option) => option.id === item.fuel_id)) {
            const found = nonrenewableFuelOptions.find((option) => option.id === item.fuel_id)
            nonrenewArray.push({ ...found, ...item })
        }
    })

    const statusId = result?.fuel_application_status_id

    return (
        <PageContainer>
            <>
                <BackLink
                    title={`Данные по топливу за ${monthPicker(result?.month)}, ${result?.year}`}
                    extra={getStatus(statusId)}
                />
                <Card className={style.card}>
                    <Box direction="row" justify="space-between">
                        <div className={style.infoWrapper}>
                            {statusId === 2 && (
                                <Alert
                                    message={'Данные находятся на проверке у Оператора Системы'}
                                    className={style.infoAlert}
                                    type="info"
                                    showIcon
                                />
                            )}
                            {statusId === 4 && (
                                <Alert
                                    message={
                                        <>
                                            <div className={style.alertHeader}>
                                                {`Данные не приняты. Переподача данных возможна до
                                                ${moment(result.ts_resubmission_end).format(
                                                    'DD.MM.YYYY',
                                                )}. Причина:`}
                                            </div>
                                            <div>{result.fuel_application_comment}</div>
                                        </>
                                    }
                                    className={style.errorAlert}
                                    type="error"
                                    showIcon={false}
                                />
                            )}
                            {fuelInfoConfig(result)?.map((item: any) => (
                                <Box direction="row">
                                    <div className={style.greyBlock}>{item.name}</div>
                                    <div>{item.value}</div>
                                </Box>
                            ))}
                        </div>
                        <ImportButton
                            customStyle={{ width: '200px', height: '20px' }}
                            icon={<Stamp />}
                            value={'Скачать запрос и подпись'}
                            onClick={() => downloadSignedRequest(result.request_id)}
                        />
                    </Box>
                    <Divider />
                    {renewArray.length !== 0 && (
                        <div className={style.energyBlock}>
                            <div className={style.energyHeader}>
                                Возобновляемые источники энергии
                            </div>
                            <div className={style.energyWrapper}>
                                <div className={style.energyTop}>Тип топлива</div>
                                <div className={style.energyTop}>Объем</div>
                            </div>
                            {renewArray.map((item: any, index: number) => {
                                return (
                                    <div
                                        className={
                                            index === renewArray.length - 1
                                                ? style.energyItemWrapperBottom
                                                : style.energyItemWrapper
                                        }
                                    >
                                        <div className={style.energyItem}>{item.text}</div>
                                        <div
                                            className={style.energyItem}
                                        >{`${item.volume} ${item.unit}`}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    {renewArray.length !== 0 && nonrenewArray.length !== 0 && (
                        <div className={style.overBlock} />
                    )}
                    {nonrenewArray.length !== 0 && (
                        <div className={style.energyBlock}>
                            <div className={style.energyHeader}>
                                Невозобновляемые источники энергии
                            </div>
                            <div className={style.energyWrapper}>
                                <div className={style.energyTop}>Тип топлива</div>
                                <div className={style.energyTop}>Объем</div>
                            </div>
                            {nonrenewArray.map((item: any, index: number) => {
                                return (
                                    <div
                                        className={
                                            index === nonrenewArray.length - 1
                                                ? style.energyItemWrapperBottom
                                                : style.energyItemWrapper
                                        }
                                    >
                                        <div className={style.energyItem}>{item.text}</div>
                                        <div
                                            className={style.energyItem}
                                        >{`${item.volume} ${item.unit}`}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    <Divider />
                    <Box direction="row">
                        <div className={style.filesTitle}>Дополнительные файлы</div>
                        {result?.documents?.length ? (
                            <DocumentsGrid documents={result?.documents} />
                        ) : (
                            '-'
                        )}
                    </Box>
                </Card>
            </>
        </PageContainer>
    )
}

export default FuelCard
