import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '../../../ui'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import truncate from 'lodash/truncate'
import style from './style.m.less'

interface IBlockingBlock {
    blockingInfo: { ts_created: string; comment: string }
    id: string
}

const BlockingBlock: FC<IBlockingBlock> = ({ blockingInfo, id }) => {
    const { t } = useTranslation()

    return (
        <div className={style.repaymentBlock}>
            <div className={style.repaymentHeader}>{`Блокировка Зеленого договора`}</div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Дата блокировки</div>
                <div className={style.nameBlock}>
                    {moment(blockingInfo?.ts_created).format('DD.MM.YYYY')}
                </div>
            </div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Основание</div>
                <div className={style.nameBlock}>
                    {truncate(blockingInfo?.comment, {
                        length: 100,
                    })}
                </div>
            </div>
            <Link to={`/audit/${id}`}>
                <IconButton customStyle={{ marginTop: '24px' }}>Подробнее о блокировке</IconButton>
            </Link>
        </div>
    )
}

export default BlockingBlock
