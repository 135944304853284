// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader_nrdYM {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 24px;\n  width: 386px;\n}\n.text_QE6_d {\n  width: 306px;\n}\n.buttonsBlock_fcvq4 {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.textArea_cbBHk {\n  width: 436px;\n  margin-bottom: 24px;\n  border-radius: 12px;\n  margin-top: 3px;\n}\n.activationAlert_yFhS5 {\n  border-radius: 8px;\n}\n.label_yrhaT {\n  margin-bottom: 2px;\n}\n.rangePicker_Wh2XL {\n  width: 100%;\n}\n.dataBlock_qV4io .ant-picker-range {\n  width: 100%;\n}\n.modalWrapper_fFoGH {\n  display: flex;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOSubmitDataKU/components/WarningModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAEA;EACI,YAAA;AAAJ;AAGA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AADJ;AAIA;EACI,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAKA;EACI,kBAAA;AAHJ;AAMA;EACI,kBAAA;AAJJ;AAOA;EACI,WAAA;AALJ;AAQA;EAEQ,WAAA;AAPR;AAWA;EACI,aAAA;EACA,SAAA;AATJ","sourcesContent":[".modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 24px;\n    width: 386px;\n}\n\n.text {\n    width: 306px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.textArea {\n    width: 436px;\n    margin-bottom: 24px;\n    border-radius: 12px;\n    margin-top: 3px;\n}\n\n.activationAlert {\n    border-radius: 8px;\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.rangePicker {\n    width: 100%;\n}\n\n.dataBlock {\n    :global(.ant-picker-range) {\n        width: 100%;\n    }\n}\n\n.modalWrapper {\n    display: flex;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "modalHeader_nrdYM",
	"text": "text_QE6_d",
	"buttonsBlock": "buttonsBlock_fcvq4",
	"textArea": "textArea_cbBHk",
	"activationAlert": "activationAlert_yFhS5",
	"label": "label_yrhaT",
	"rangePicker": "rangePicker_Wh2XL",
	"dataBlock": "dataBlock_qV4io",
	"modalWrapper": "modalWrapper_fFoGH"
};
export default ___CSS_LOADER_EXPORT___;
