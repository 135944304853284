import { FC, useState } from 'react'
import { Alert, Input, message } from 'antd'
import { useDispatch } from 'react-redux'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import { Modal, CloseModal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'
import { getUser } from '../../../../store/user'

import style from './style.m.less'

interface IChangeRoleModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    currentEmail: string
    userId?: string
}

const ChangeEmailModal: FC<IChangeRoleModal> = ({
    isModalVisible,
    setIsModalVisible,
    currentEmail,
    userId,
}) => {
    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [isCloseModalVisible, setIsCloseModalVisible] = useState(false)

    const { fetch } = useRequest({
        link: `/api/auth/v1/email-${userId ? 'change' : 'update'}`,
        method: RequestTypes.post,
    })

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const confirmCloseModal = () => {
        setEmail('')
        setIsCloseModalVisible(false)
        setTimeout(() => {
            setIsModalVisible(false)
        }, 0)
    }

    const onChange = (e: any) => {
        setEmail(e.target.value)
    }

    const openCloseModal = () => {
        if (email !== '') {
            setIsCloseModalVisible(true)
        } else {
            handleOk()
        }
    }

    const onFinish = async () => {
        if (email) {
            const body = userId
                ? {
                      uid: userId,
                      email_new: email,
                  }
                : {
                      email,
                  }
            fetch({
                body,
                errorCodeMessage: true,
                onSuccess: () => {
                    setEmail('')
                    setIsModalVisible(false)
                    if (!userId) {
                        dispatch(getUser())
                    }
                },
                successMessage: { title: 'Успешно', description: 'E-mail успешно изменен' },
            })
        } else {
            message.error('Введите корректный адрес электронной почты')
        }
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>Смена e-mail</div>
            <div className={style.inputBlock}>
                <div className={style.currentEmail}>Текущий e-mail</div>
                <div>{currentEmail}</div>
            </div>
            <div className={style.inputBlock}>
                <div>Новый e-mail</div>
                <Input type={'email'} value={email} className={style.input} onChange={onChange} />
            </div>
            <Alert
                message="На указанный e-mail мы вышлем письмо со ссылкой для подтверждения нового электронного адреса. 
                После перехода по ссылке - в профиле будет сохранен новый e-mail."
                type="info"
                showIcon
                className={style.alert}
            />
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={onFinish}
                    cancelAction={openCloseModal}
                    confirmTitle="Сменить"
                />
            </div>
            <CloseModal
                isModalVisible={isCloseModalVisible}
                setIsModalVisible={setIsCloseModalVisible}
                headerText={'Отменить изменения?'}
                messageText={'Новый e-mail не будет сохранен'}
                confirmTitle={'Да'}
                cancelTitle={'Нет'}
                confirmAction={confirmCloseModal}
            />
        </Modal>
    )
}

export default ChangeEmailModal
