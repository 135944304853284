import { FC } from 'react'
import style from './style.m.less'

interface ICommonBlock {
    label: string | React.ReactNode
    select: React.ReactNode
    customStyle?: React.CSSProperties
    margin?: boolean
    required?: boolean
    labelStyle?: React.CSSProperties
}

const CommonBlock: FC<ICommonBlock> = ({
    label,
    select,
    customStyle,
    margin,
    required,
    children,
    labelStyle,
}) => {
    return (
        <div className={margin ? style.editBlockMargin : style.editBlock} style={customStyle}>
            <div className={style.label} style={labelStyle}>
                {label}
                {required && <div className={style.star}>*</div>}
            </div>
            {select}
            {children}
        </div>
    )
}

export default CommonBlock
