import { FC, useState } from 'react'
import { Input, Checkbox, Form } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Modal, PrimaryButton } from '../../../ui'

interface IAddConsumer {
    isModalVisible: boolean
    setIsModalVisible: () => void
    userType?: number
    selectHandle: (consumer: any) => void
}

const AddConsumer: FC<IAddConsumer> = ({
    isModalVisible,
    setIsModalVisible,
    userType,
    selectHandle,
}) => {
    const { t } = useTranslation()

    const handleCancel = () => {
        setIsModalVisible()
    }

    const [form] = Form.useForm()

    const [hasPatronymic, setHasPatronymic] = useState(true)
    const showPatronymic = () => {
        setHasPatronymic(!hasPatronymic)
    }

    const onFinish = (values: any) => {
        selectHandle(values)
        handleCancel()
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible}>
                <div className={style.modalHeader}>Добавить потребителя</div>
                <Form form={form} onFinish={onFinish} className={style.form}>
                    {userType === 0 && (
                        <>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите фамилию',
                                    },
                                ]}
                                name="last_name"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Фамилия</div>
                                    <Input placeholder="Введите фамилию" className={style.input} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите имя',
                                    },
                                ]}
                                name="first_name"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Имя</div>
                                    <Input placeholder="Введите имя" className={style.input} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: hasPatronymic,
                                        message: 'Укажите отчество',
                                    },
                                ]}
                                name="middle_name"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Отчество</div>
                                    <div className={style.checkboxWrapper}>
                                        <Input
                                            placeholder="Введите отчество"
                                            className={style.input}
                                            disabled={!hasPatronymic}
                                        />
                                        <Checkbox
                                            className={style.checkbox}
                                            onClick={showPatronymic}
                                        >
                                            {t('editMyProfile.noLastName')}
                                        </Checkbox>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите ИНН',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '',
                                    },
                                ]}
                                name="inn"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ИНН</div>
                                    <Input
                                        minLength={12}
                                        maxLength={12}
                                        placeholder="Введите ИНН"
                                        className={style.input}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите СНИЛС',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '',
                                    },
                                ]}
                                name="snils"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>СНИЛС</div>
                                    <Input
                                        minLength={11}
                                        maxLength={11}
                                        placeholder="Введите СНИЛС"
                                        className={style.input}
                                    />
                                </div>
                            </Form.Item>
                        </>
                    )}
                    {userType === 2 && (
                        <>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите название',
                                    },
                                ]}
                                name="company_name_short"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Название</div>
                                    <Input placeholder="Введите название" className={style.input} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите ИНН',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '',
                                    },
                                ]}
                                name="inn"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ИНН</div>
                                    <Input
                                        minLength={10}
                                        maxLength={10}
                                        placeholder="Введите ИНН"
                                        className={style.input}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите ОГРН',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '',
                                    },
                                ]}
                                name="ogrn"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ОГРН</div>
                                    <Input
                                        minLength={13}
                                        maxLength={13}
                                        placeholder="Введите ОГРН"
                                        className={style.input}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите КПП',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '',
                                    },
                                ]}
                                name="kpp"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>КПП</div>
                                    <Input
                                        minLength={9}
                                        maxLength={9}
                                        placeholder="Введите КПП"
                                        className={style.input}
                                    />
                                </div>
                            </Form.Item>
                        </>
                    )}
                    {userType === 1 && (
                        <>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите фамилию',
                                    },
                                ]}
                                name="last_name"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Фамилия</div>
                                    <Input placeholder="Введите фамилию" className={style.input} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите имя',
                                    },
                                ]}
                                name="first_name"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Имя</div>
                                    <Input placeholder="Введите имя" className={style.input} />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: hasPatronymic,
                                        message: 'Укажите отчество',
                                    },
                                ]}
                                name="middle_name"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>Отчество</div>
                                    <div className={style.checkboxWrapper}>
                                        <Input
                                            placeholder="Введите отчество"
                                            className={style.input}
                                            disabled={!hasPatronymic}
                                        />
                                        <Checkbox
                                            className={style.checkbox}
                                            onClick={showPatronymic}
                                        >
                                            {t('editMyProfile.noLastName')}
                                        </Checkbox>
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите ИНН',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '',
                                    },
                                ]}
                                name="inn"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ИНН</div>
                                    <Input
                                        minLength={12}
                                        maxLength={12}
                                        placeholder="Введите ИНН"
                                        className={style.input}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: 'Укажите ОГРНИП',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '',
                                    },
                                ]}
                                name="ogrnip"
                            >
                                <div className={style.inputWrapper}>
                                    <div className={style.label}>ОГРНИП</div>
                                    <Input
                                        minLength={15}
                                        maxLength={15}
                                        placeholder="Введите ОГРНИП"
                                        className={style.input}
                                    />
                                </div>
                            </Form.Item>
                        </>
                    )}
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={handleCancel}
                            value="Отмена"
                            type="button"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            type="submit"
                            value="Добавить"
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default AddConsumer
