import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, Divider } from 'antd'
import IAppState from '../../../../store/states'
import { getGenerators } from '../../../../store/generators'
import { useSelector, useDispatch } from 'react-redux'
import { GeneratorCard, DropdownSelector, DatePicker, IconButton, Pagination } from '../../../ui'
import { Receipt, Drop, DoubleSave, AddCircle } from '../../../../assets/icons'
import style from './style.m.less'
import { Link } from 'react-router-dom'

const { TabPane } = Tabs

const USER_GENERATORS = 'userGenerators'

const energyTypes = [
    { value: 'Все', id: 'f416c913-a16a-4bb8-93a6-081a89cc036a' },
    { value: 'Не все', id: 'f26a2c6a-2840-470c-922a-289fca5017ad' },
]

const objectTypes = [
    { value: 'Все', id: '86b99feb-b23e-4a89-961a-472bbaf81da0' },
    { value: 'Не все', id: 'b7140a33-0730-45aa-b435-9edb4393012c' },
]

const tabsList = [
    { label: 'Активированные', key: 1, content: <></> },
    { label: 'На проверке', key: 2, content: <></> },
    { label: 'Неактивированные', key: 3, content: <></> },
]

const MyGenerators: FC = () => {
    const { t } = useTranslation()

    const [page, setPage] = useState<number>(1)

    const list = useSelector((store: IAppState) => store.generatorsList)
    const dispatch = useDispatch()
    const { items } = list.data

    useEffect(() => {
        dispatch(getGenerators({ page }))
    }, [page])

    return (
        <div className={style.generatorsMain}>
            <div className={style.mainHeader}>{t('myProfile.myGeneratingObjects')}</div>
            <div className={style.mainWrapper}>
                <div className={style.controlsWrapper}>
                    <IconButton
                        customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                        icon={<Receipt />}
                    >
                        {t('myProfile.submitKU')}
                    </IconButton>
                    <IconButton
                        customStyle={{ marginLeft: '16px' }}
                        customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                        icon={<Drop />}
                    >
                        {t('myProfile.submitFuel')}
                    </IconButton>
                    <IconButton
                        customStyle={{ marginLeft: '16px' }}
                        customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                        icon={<DoubleSave />}
                    >
                        {t('myProfile.submitZD')}
                    </IconButton>
                    <Link to="/adding-go">
                        <IconButton
                            customStyle={{ marginLeft: '16px' }}
                            customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                            icon={<AddCircle />}
                        >
                            {t('myProfile.addGO')}
                        </IconButton>
                    </Link>
                </div>
                <Tabs defaultActiveKey="1" onChange={() => {}} className={style.tabs}>
                    {tabsList.map((item) => {
                        const { label, key, content } = item

                        return (
                            <TabPane tab={label} key={key}>
                                {content}
                            </TabPane>
                        )
                    })}
                </Tabs>
                <div className={style.controlsBlock}>
                    <div className={style.leftGapBlockFirst}>
                        <div>{t('myProfile.typeVIE')}</div>
                        <div className={style.inputBlock}>
                            <DropdownSelector
                                customStyle={{ width: '290px' }}
                                options={energyTypes}
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <div>{t('myProfile.typeGO')}</div>
                        <div className={style.inputBlock}>
                            <DropdownSelector
                                customStyle={{ width: '290px' }}
                                options={objectTypes}
                            />
                        </div>
                    </div>
                    <div className={style.leftGapBlock}>
                        <div>{t('myProfile.qualificationDate')}</div>
                        <div className={style.inputBlock}>
                            <DatePicker customStyle={{ width: '290px', height: '32px' }} />
                        </div>
                    </div>
                </div>
                <div className={style.cardsBlock}>
                    {items?.map((item, index) => {
                        const { id } = item

                        return (
                            <div key={id}>
                                <GeneratorCard generatorData={item} type={USER_GENERATORS} />
                                {index !== items.length - 1 && <Divider />}
                            </div>
                        )
                    })}
                </div>
                <Pagination
                    current={page}
                    paginationChange={(receivedPage: number) => {
                        setPage(receivedPage)
                    }}
                />
            </div>
        </div>
    )
}

export default MyGenerators
