import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'

import style from './style.m.less'
import { QuestionCircle } from '../../../../assets/icons'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

interface IConfirmRoleChangeModal {
    isModalVisible: boolean
    confirmAction: () => void
}

const ConfirmRoleChangeModal: FC<IConfirmRoleChangeModal> = ({ isModalVisible, confirmAction }) => {
    const { t } = useTranslation()

    const { isUserAdmin } = useCurrentUser()

    const navigate = useNavigate()

    const handleOk = () => {
        confirmAction()
    }

    const getBack = () => {
        navigate(-1)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <QuestionCircle />
                    <div className={style.header}>{t('editMyProfile.changeRoleHeader')}?</div>
                </div>
            </div>
            <div className={style.text}>{t('editMyProfile.changeRoleConfirm')}</div>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleOk}
                    cancelAction={getBack}
                    confirmTitle={'Подтвердить'}
                />
            </div>
        </Modal>
    )
}

export default ConfirmRoleChangeModal
