import { Saga } from '@redux-saga/types'
import { all, fork } from 'redux-saga/effects'
import * as authSagas from './auth/sagas'
import * as generatorsSagas from './generators/sagas'
import * as userSagas from './user/sagas'
import * as dictionaries from './dictionaries/sagas'
import * as notifications from './notifications/sagas'
import * as accounts from './accounts/sagas'

function* sagas(): Generator<unknown, any, unknown> {
    const watchers = Object.values({
        ...authSagas,
        ...generatorsSagas,
        ...userSagas,
        ...dictionaries,
        ...notifications,
        ...accounts,
    }) as Saga[]
    yield all(watchers.map((watcher) => fork(watcher)))
}

export default sagas
