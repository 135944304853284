import { useEffect, useState } from 'react'
import { Table } from 'antd'
import request from '../../../../services/request'

import { columns, IHistoryItem } from './columns'
import { useParams } from 'react-router-dom'

const PAGE_SIZE = 10

const DeregisterTable = () => {
    const { id } = useParams<{ id: string }>()

    const [data, setData] = useState<IHistoryItem[]>()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(1)
    const [total, setTotal] = useState<number>()
    const [pageSize, setPageSize] = useState(PAGE_SIZE)

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true)
                const res = await request.post<{
                    deregister_events: IHistoryItem[]
                    total: number
                }>(`/api/generators/v1/go-deregister-history?generator_id=${id}`, {
                    limit: pageSize,
                    offset: (pagination - 1) * pageSize,
                })
                const dataWithKeys = (res.data.deregister_events || []).map((row, index) => ({
                    ...row,
                    key: index,
                }))
                setData(dataWithKeys)
                setTotal(res.data.total)
            } finally {
                setLoading(false)
            }
        }
        load()
    }, [pageSize, pagination])

    return (
        <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            pagination={{
                position: ['bottomLeft'],
                onChange: (page, pageSize) => {
                    setPagination(page)
                    setPageSize(pageSize)
                },
                total: total,
                current: pagination,
                pageSize: pageSize,
                showSizeChanger: true,
            }}
        />
    )
}

export default DeregisterTable
