// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/auth.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginMain_XgN5S {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 830px;\n}\n.loginBlock_y4OOE {\n  margin-top: 120px;\n  display: flex;\n}\n.loginForm_VC4yx {\n  width: 532px;\n  padding: 40px 48px;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 24px 0px 0px 24px;\n  background: #fff;\n}\n.formBlockInfo_nN05z {\n  margin-top: 32px;\n  display: flex;\n  justify-content: space-between;\n}\n.linkRegister_fA8Z1 {\n  color: #296dff;\n  font-weight: 600;\n  margin-left: 12px;\n}\n.authImg_xzJXv {\n  width: 216px;\n  height: 100%;\n  border-radius: 0px 24px 24px 0px;\n  background: no-repeat top url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/components/Login/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AAEA;EACI,iBAAA;EACA,aAAA;AAAJ;AAGA;EACI,YAAA;EACA,kBAAA;EACA,8CAAA;EACA,gCAAA;EACA,gBAAA;AADJ;AAIA;EACI,gBAAA;EACA,aAAA;EACA,8BAAA;AAFJ;AAKA;EACI,cAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAMA;EACI,YAAA;EACA,YAAA;EACA,gCAAA;EACA,iEAAA;AAJJ","sourcesContent":[".loginMain {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    height: 830px;\n}\n\n.loginBlock {\n    margin-top: 120px;\n    display: flex;\n}\n\n.loginForm {\n    width: 532px;\n    padding: 40px 48px;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 24px 0px 0px 24px;\n    background: #fff;\n}\n\n.formBlockInfo {\n    margin-top: 32px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.linkRegister {\n    color: #296dff;\n    font-weight: 600;\n    margin-left: 12px;\n}\n\n.authImg {\n    width: 216px;\n    height: 100%;\n    border-radius: 0px 24px 24px 0px;\n    background: no-repeat top url('../../assets/images/auth.png');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginMain": "loginMain_XgN5S",
	"loginBlock": "loginBlock_y4OOE",
	"loginForm": "loginForm_VC4yx",
	"formBlockInfo": "formBlockInfo_nN05z",
	"linkRegister": "linkRegister_fA8Z1",
	"authImg": "authImg_xzJXv"
};
export default ___CSS_LOADER_EXPORT___;
