import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeft } from '../../../assets/icons'
import style from './style.m.less'

type BackLinkProps = {
    title: string
    extra?: ReactNode
    link: any
}

const BackLinkArrowButton: FC<BackLinkProps> = ({ title, extra, link }) => {
    return (
        <div className={style.titleWrapper}>
            <Link className={style.backLink} to={link}>
                <ArrowLeft />
            </Link>
            <div>
                <h1 className={style.title}>{title}</h1>
            </div>
            {extra && extra}
        </div>
    )
}

export default BackLinkArrowButton
