// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_BYm3m {\n  display: flex;\n  gap: 16px;\n  justify-content: space-between;\n  width: 100%;\n}\n.filterItem_IBN10 {\n  flex: 1;\n}\n.label_zYFlE {\n  margin-bottom: 2px;\n}\n.input_ofMJF {\n  border-radius: 8px;\n}\n.select_G_reV {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOApproval/components/Filter/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,8BAAA;EACA,WAAA;AACJ;AAEA;EACI,OAAA;AAAJ;AAGA;EACI,kBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;EACI,WAAA;AAHJ","sourcesContent":[".filter {\n    display: flex;\n    gap: 16px;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.filterItem {\n    flex: 1;\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.select {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "filter_BYm3m",
	"filterItem": "filterItem_IBN10",
	"label": "label_zYFlE",
	"input": "input_ofMJF",
	"select": "select_G_reV"
};
export default ___CSS_LOADER_EXPORT___;
