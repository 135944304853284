// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controlsBlock_gl8FW {\n  display: flex;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/components/FilterActivated/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ","sourcesContent":[".controlsBlock {\n    display: flex;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlsBlock": "controlsBlock_gl8FW"
};
export default ___CSS_LOADER_EXPORT___;
