// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag_LUSvF {\n  border-radius: 12px;\n  height: 22px;\n  line-height: 21px;\n}\n.tag_LUSvF:not(.outline_xtgvN) {\n  border: 0;\n}\n.transparent_CbSfv {\n  background-color: transparent;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Tag/style.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,iBAAA;AACJ;AACI;EACI,SAAA;AACR;AAGA;EACI,6BAAA;AADJ","sourcesContent":[".tag {\n    border-radius: 12px;\n    height: 22px;\n    line-height: 21px;\n    \n    &:not(.outline) {\n        border: 0;\n    }\n}\n\n.transparent {\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "tag_LUSvF",
	"outline": "outline_xtgvN",
	"transparent": "transparent_CbSfv"
};
export default ___CSS_LOADER_EXPORT___;
