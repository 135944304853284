import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const RotateRight: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.70862 2.28667C9.12862 2.11333 8.48862 2 7.78195 2C4.58862 2 2.00195 4.58667 2.00195 7.78C2.00195 10.98 4.58862 13.5667 7.78195 13.5667C10.9753 13.5667 13.562 10.98 13.562 7.78667"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
