import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Alert, Divider, Tooltip } from 'antd'
import { ModalType } from '../../types'
import IAppState from '../../../../store/states'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import { getAccounts } from '../../../../store/accounts'
import { AccountChart, AccountModal, PaymentModal } from './components'
import OpenAccountModal from '../OpenAccountModal/OpenAccountModal'
import { getAccountsDataService } from '../../../../services/accounts'
import { EHistoryType, IOperationConfig } from '../../../ui/OperationHistoryMapper/types'
import useDownloadSignedRequest from '../../../hooks/useDownloadSignedRequest'
import OpenFirstAccountModal from '../OpenFirstAccountModal/OpenFirstAccountModal'
import { dataSet, ownerDataSet, getPersAccHistory, valuePicker, getDiagram } from './configurations'
import { AddCircle, CloseCircle, Edit, SimpleStar, Stamp, Blocks } from '../../../../assets/icons'
import {
    CenteredSpin,
    DropdownSelector2,
    IconButton,
    OperationHistoryMapper,
    Box,
} from '../../../ui'
import { currencyFormat } from '../../../helpers'
import style from './style.m.less'
import { UserAccountFull, UserAccountShort } from 'src/api/gt/types'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

interface IPersonalAccount {
    accountId?: string
    userId?: string
}

const PersonalAccount: FC<IPersonalAccount> = ({ accountId, userId }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)
    const [modalType, setModalType] = useState<ModalType>('rename')

    const [currentAccount, setCurrentAccount] = useState<UserAccountFull>()
    const [totalDiagram, setTotalDiagram] = useState(0)
    const { downloadSignedRequest } = useDownloadSignedRequest()

    const dispatch = useDispatch()
    const { isUserAdmin } = useCurrentUser()

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const { fetch: fetchPersAccHistory, result: persAccHistory } = useRequest(getPersAccHistory)

    const {
        fetch: fetchDiagram,
        result: resultDiagram,
        isLoading: isLoadingDiagram,
    } = useRequest(getDiagram)

    const {
        fetch: getUserAccounts,
        result: userAccounts,
        isLoading: isGetUserAccountsLoading,
    } = useRequest<{ accounts: UserAccountShort[] }>({
        link: `/api/green-tools/v1/accounts`,
        method: RequestTypes.get,
    })

    const { fetch: getTotalAG, result: totalAGResult } = useRequest({
        link: `/api/generation_attributes/v1/gas/ga/total`,
        method: RequestTypes.get,
    })

    const getCurrentAccount = async (accountId: string, userId = '') => {
        const { data, error } = await getAccountsDataService(accountId, userId)
        if (error) {
            throw error
        }
        setCurrentAccount(data)

        fetchPersAccHistory({
            getParams: { id: data.number },
            errorCodeMessage: true,
        })
    }

    useEffect(() => {
        dispatch(getAccounts())
        if (isOwner) {
            getTotalAG({})
        }
    }, [])

    useEffect(() => {
        getUserAccounts({
            getParams: {
                user_uid: userId,
            },
        })
    }, [userId])

    const accountsInfo = useSelector((store: IAppState) => store.accounts)
    const accounts = isUserAdmin ? userAccounts?.accounts : accountsInfo.accounts
    const isLoading = isGetUserAccountsLoading || accountsInfo.isLoading

    const userInfo = useSelector((store: IAppState) => store.user)
    const userRole = userInfo.data.user_role
    const isOwner = userRole === 3

    useEffect(() => {
        if (currentAccount) {
            fetchDiagram({
                addToLink: `?type=${
                    isOwner ? 'my_attr_gen_green_tools' : 'my_green_tools'
                }&account_id=${currentAccount?.id}`,
                onSuccess: (data) => {
                    const totalReduce = Object.values(data.sum).reduce((acc, val) => acc + val, 0)
                    setTotalDiagram(totalReduce)
                },
            })
        }
    }, [currentAccount])

    const handleAccountChange = (accountId: string, userId?: string) =>
        getCurrentAccount(accountId, userId)

    useEffect(() => {
        if (accountId) {
            handleAccountChange(accountId, userId)
        } else if (accounts && accounts.length) {
            const firstAccount = accounts[0]
            getCurrentAccount(firstAccount.id, userId)
        }
    }, [accounts, accountId, userId])

    const openModal = (type: ModalType) => {
        setModalType(type)
        setIsModalVisible(true)
    }

    const [isOpenFirstAccountModalVisible, setIsOpenFirstAccountModalVisible] = useState(false)

    const openOpenFirstAccountModal = () => {
        setIsOpenFirstAccountModalVisible(true)
    }

    const [isOpenAccountModalVisible, setIsOpenAccountModalVisible] = useState(false)

    const openOpenAccountModal = () => {
        setIsOpenAccountModalVisible(true)
    }

    if ([1, 5, 6].includes(userRole) && !userId) {
        return null
    }

    const currentDataSet = isOwner ? ownerDataSet : dataSet

    if (isLoading || isLoadingDiagram) {
        return <CenteredSpin />
    }

    const persAccHistoryParsed: IOperationConfig[] = []

    if (persAccHistory?.total) {
        let recordsInSet = 5

        const today = moment().startOf(dayUnit)

        const ph = persAccHistory?.events
            ?.map((phe: { date: string; operations: any[] }) => {
                const eventDay = moment(phe.date, shortFormat)

                let title = eventDay.format(extraFormat)

                if (eventDay.isSame(today)) {
                    title = `${t('history.today')}, ${title}`
                } else if (eventDay.isSame(today.subtract(1, dayUnit))) {
                    title = `${t('history.yesterday')}, ${title}`
                }

                if (recordsInSet > 0 && phe.operations?.length > 0) {
                    const delta = Math.min(phe.operations.length, recordsInSet)
                    phe.operations = phe.operations.slice(0, delta)
                    recordsInSet -= delta
                } else {
                    phe.operations = []
                }

                return {
                    flexStart: false,
                    iconMargin: false,
                    date: title,
                    operations: phe.operations,
                }
            })
            .filter((phe: { date: string; operations: [] }) => phe.operations.length > 0)

        persAccHistoryParsed.push(...ph)
    }

    const noAccountYet = (
        <>
            <div className={style.withoutAccountBlock}>
                <Alert
                    message={
                        <div>
                            <div className={style.alertHeader}>
                                {t('myProfile.personalAccountAlertHeader')}
                            </div>
                            <div className={style.alertText}>
                                {t('myProfile.personalAccountAlert')}
                            </div>
                        </div>
                    }
                    className={style.alert}
                    type="info"
                    showIcon
                />
                <IconButton
                    customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                    icon={<AddCircle />}
                    onClick={openOpenFirstAccountModal}
                >
                    {t('myProfile.openNewAccount')}
                </IconButton>
            </div>
        </>
    )

    if (!accounts || !currentAccount) {
        return (
            <div className={style.personalAccount}>
                {!accounts && noAccountYet}
                <OpenFirstAccountModal
                    isModalVisible={isOpenFirstAccountModalVisible}
                    setIsModalVisible={setIsOpenFirstAccountModalVisible}
                />
            </div>
        )
    }

    const isBlocked = currentAccount.ts_closed !== null

    const paymentAvailable = currentAccount?.is_payment_available
    const paymentEnds = currentAccount?.ts_payed_account
    const paymentExpires = moment().isBetween(
        moment(paymentEnds).subtract(10, 'd'),
        moment(paymentEnds),
    )
    const isCurrentDate = moment().isSameOrAfter(moment(paymentEnds))

    const personalAccountTab = (
        <>
            <div className={style.accountNumberBlock}>
                <div className={style.accountNumberRow}>
                    <div>
                        <div>{t('myProfile.accountNumber')}</div>
                        <DropdownSelector2
                            options={accounts?.map((item: any) => {
                                return {
                                    text: `${item.name}`,
                                    value: item.id,
                                    id: item.id,
                                    description: `${item.number}`,
                                }
                            })}
                            value={`${currentAccount?.name} (№${currentAccount?.number})`}
                            onChange={(accountId) => handleAccountChange(accountId, userId || '')}
                            customStyle={{
                                width: '300px',
                                marginTop: '2px',
                            }}
                        />
                        {currentAccount?.is_main && (
                            <div className={style.mainCount}>
                                <Tooltip
                                    placement="bottomLeft"
                                    color="#434343"
                                    overlayInnerStyle={{ color: 'white' }}
                                    title={t('myProfile.mainCountTooltip')}
                                    // visible
                                >
                                    <span className={style.star}>
                                        <SimpleStar />
                                    </span>
                                    <span className={style.mainCountText}>
                                        {t('myProfile.mainCount')}
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                    <IconButton
                        customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                        icon={<AddCircle />}
                        onClick={openOpenAccountModal}
                    >
                        {t('myProfile.openNewAccount')}
                    </IconButton>
                </div>

                {isBlocked && (
                    <Alert
                        className={style.closedAlert}
                        message={
                            <div className={style.modalAlertText}>
                                <div>{`Счет закрыт ${moment(currentAccount?.ts_closed).format(
                                    'DD.MM.YYYY',
                                )}`}</div>
                            </div>
                        }
                        type="error"
                        showIcon
                    />
                )}

                {(paymentExpires || isCurrentDate) && paymentAvailable && (
                    <Alert
                        className={style.warningAlert}
                        message={
                            <div className={style.modalAlertText}>
                                {paymentExpires ? (
                                    <>
                                        <div>{`Период обслуживания Лицевого счета истекает ${moment(
                                            paymentEnds,
                                        ).format('DD.MM.YYYY')}. Внесите плату за `}</div>
                                        <div>{`обслуживание для возможности использования функционала АС “СКИЗИ”`}</div>
                                    </>
                                ) : (
                                    <>
                                        <div>{`Внесите плату за обслуживание для возможности использования функционала`}</div>
                                        <div>{`АС “СКИЗИ”`}</div>
                                    </>
                                )}

                                <div
                                    className={style.payButton}
                                    onClick={() => setIsPaymentModalVisible(true)}
                                >
                                    Оплатить
                                </div>
                            </div>
                        }
                        type="warning"
                        showIcon
                    />
                )}

                <div className={style.infoWrapper}>
                    {isOwner ? (
                        <Box direction="row">
                            <div>
                                <div className={style.infoTitle}>Выпущенные атрибуты генерации</div>
                                <div className={style.info}>{`${currencyFormat(
                                    totalAGResult?.total_volume,
                                )} кВт*ч`}</div>
                            </div>
                            <Divider className={style.topDivider} type="vertical" />
                            <div>
                                <div className={style.infoTitle}>Свободные атрибуты генерации</div>
                                <div className={style.info}>{`${currencyFormat(
                                    totalAGResult?.free_volume,
                                )} кВт*ч`}</div>
                            </div>
                        </Box>
                    ) : (
                        <div>
                            <div className={style.infoTitle}>
                                {userId
                                    ? 'Выпущенные атрибуты генерации'
                                    : 'Атрибутов генерации на счету'}
                            </div>
                            <div className={style.info}>{`${currencyFormat(
                                currentAccount?.capacity_ag,
                            )} кВт*ч`}</div>
                        </div>
                    )}
                    {!!(userId && currentAccount?.free_ag) && (
                        <>
                            <Divider style={{ height: '30px' }} type="vertical" />
                            <div>
                                <div className={style.infoTitle}>Свободные атрибуты генерации</div>
                                <div className={style.info}>{`${currencyFormat(
                                    currentAccount?.free_ag,
                                )} кВт*ч`}</div>
                            </div>
                        </>
                    )}
                </div>

                <div className={style.buttonsRow}>
                    <div className={style.buttonsRowBlock}>
                        {!isBlocked && (
                            <>
                                <IconButton
                                    icon={<Edit size={16} />}
                                    onClick={() => openModal('rename')}
                                >
                                    {t('myProfile.renameCount')}
                                </IconButton>
                                <IconButton
                                    icon={<CloseCircle size={20} />}
                                    onClick={() => openModal('closeWithZI')}
                                    disabled
                                >
                                    {t('myProfile.closeCount')}
                                </IconButton>
                            </>
                        )}
                        {!isBlocked && !currentAccount?.is_main && (
                            <IconButton icon={<SimpleStar />} onClick={() => openModal('setMain')}>
                                {t('myProfile.assignMain')}
                            </IconButton>
                        )}
                        {accounts?.length > 0 && (
                            <IconButton
                                icon={<Stamp />}
                                onClick={() => downloadSignedRequest(currentAccount?.request_id)}
                            >
                                {t('myProfile.downloadESignature')}
                            </IconButton>
                        )}
                        {isOwner && (
                            <IconButton
                                icon={<Blocks />}
                                customIconStyle={{ marginTop: '5px', marginRight: '8px' }}
                                onClick={() => navigate('/generation-attributes')}
                            >
                                {'Перейти к моим АГ'}
                            </IconButton>
                        )}
                    </div>
                </div>
            </div>

            <div className={style.chartAndHistoryRow}>
                <div className={style.chartBlock}>
                    <div className={style.header}>
                        {isOwner
                            ? 'Мои атрибуты генерации и зеленые инструменты'
                            : 'ЗИ пользователя'}
                    </div>
                    <div className={style.chartRow}>
                        <div className={style.graph}>
                            <AccountChart
                                isOwner={isOwner}
                                certificates_free={resultDiagram?.sum?.free_certs}
                                other_free={resultDiagram?.sum?.free_green_tools}
                                blocked={resultDiagram?.sum?.blocked_green_tools}
                                attributes={resultDiagram?.sum?.free_attr_generation}
                                total={totalDiagram}
                            />
                        </div>
                        <div className={style.legendMain}>
                            {currentDataSet.map((item) => (
                                <div id={item.id} key={item.id}>
                                    <div className={style.legendRow}>
                                        <div
                                            style={{ background: item.color }}
                                            className={style.legendColor}
                                        ></div>
                                        <div className={style.legendName}>{item.name}</div>
                                    </div>
                                    <div className={style.legendValue}>{`${currencyFormat(
                                        resultDiagram?.sum[valuePicker(item.name)],
                                    )} кВт*ч`}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {!isUserAdmin && (
                        <IconButton
                            onClick={() => navigate('/user-my-green-tools')}
                            className={style.chartRow}
                        >
                            {'Перейти к моим ЗИ'}
                        </IconButton>
                    )}
                </div>

                <div className={style.historyBlock}>
                    <div className={style.header}>{t('myProfile.operationHistory')}</div>
                    {!persAccHistory?.total && (
                        <Alert
                            className={style.historyAlert}
                            message={
                                <div className={style.modalAlertText}>
                                    <div>{t('myProfile.historyAlert')}</div>
                                </div>
                            }
                            type="info"
                            showIcon
                        />
                    )}
                    {persAccHistory?.total > 0 && (
                        <>
                            <OperationHistoryMapper
                                config={persAccHistoryParsed}
                                settings={{ historyType: EHistoryType.Personal, navigate: true }}
                                isShort
                            />
                            <Link to={`/go-personal-operations/${currentAccount?.number}`}>
                                <IconButton customStyle={{ marginTop: '40px' }}>
                                    {t('myProfile.allOperations')}
                                </IconButton>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </>
    )

    return (
        <div className={style.personalAccount}>
            {accounts && personalAccountTab}
            <AccountModal
                accountData={currentAccount}
                onDataChanged={() => {
                    // @todo: find a way to target updates to DropdownSelector2 options without dispatch?
                    dispatch(getAccounts())
                }}
                type={modalType}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                getCurrentAccount={getCurrentAccount}
                userId={userId}
            />
            <OpenAccountModal
                isModalVisible={isOpenAccountModalVisible}
                setIsModalVisible={setIsOpenAccountModalVisible}
                userId={userId}
            />
            <PaymentModal
                isModalVisible={isPaymentModalVisible}
                data={currentAccount}
                onCancel={(reload: boolean | undefined) => {
                    setIsPaymentModalVisible(false)
                    if (reload) {
                        dispatch(getAccounts())
                    }
                }}
            />
        </div>
    )
}

export default PersonalAccount
