// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_z1AIP .ant-table-thead > tr > th {\n  font-weight: 600;\n}\n.table_z1AIP td.ant-table-cell {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GODataKU/components/KUTable/style.m.less"],"names":[],"mappings":"AAAA;EAGY,gBAAA;AADZ;AAFA;EAOY,eAAA;AAFZ","sourcesContent":[".table {\n    :global {\n        .ant-table-thead > tr > th {\n            font-weight: 600;\n        }\n\n        td.ant-table-cell {\n            cursor: pointer;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table_z1AIP"
};
export default ___CSS_LOADER_EXPORT___;
