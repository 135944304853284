import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Divider, Alert } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import IAppState from '../../../../store/states'
import { GeneratorInfoFrame, IconButton, CenteredSpin } from '../../../ui'
import { Key, Stamp, Email } from '../../../../assets/icons'
import { profileInfoConfig } from './profileInfoConfig'
import SignUtils from '../../../../utils/sign/SignUtils'
import style from './style.m.less'
import moment from 'moment'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import { hexToBinary } from '../../../helpers'
import { encode, btoa } from 'js-base64'
import { v4 as uuidv4 } from 'uuid'
import { ESignatureModal } from '../'
import ChangeEmailModal from '../ChangeEmailModal/ChangeEmailModal'
import { CertificatesModal } from '../../../Login/components'
import { getUser } from '../../../../store/user'

const AdminGeneralInfo: FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [certificateData, setCertificateData] = useState({
        allCertificates: [],
        currentCertificate: null,
    })
    const [isESignatureModalVisible, setIsESignatureModalVisible] = useState(false)

    const dispatch = useDispatch()
    const { fetch: fetchSign } = useRequest({
        link: '/api/users/v1/profile',
        method: RequestTypes.post,
    })

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data, error, isLoading } = userInfo

    if (error === 'no refresh token') {
        navigate('/auth')
        window.location.reload()
    }

    const openESignatureModalModal = () => {
        setIsESignatureModalVisible(true)
    }

    const onAddCertificate = async () => {
        const allCertificates = await SignUtils.getAllCertificates()
        setCertificateData((prev) => ({ ...prev, allCertificates }))
    }

    const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)

    const openEmailModal = () => {
        setIsEmailModalVisible(true)
    }

    const sendSign = async () => {
        const body = {
            certificate: certificateData?.currentCertificate?.certificate,
        }
        const request_id = uuidv4()
        const thumbprint = await SignUtils.getCertificateThumbprint(
            certificateData?.currentCertificate?.certificate,
        )
        const encodedInfo = encode(JSON.stringify(body))
        const encoded = request_id + encodedInfo
        const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
        const signature = btoa(hexToBinary(preSignature))

        fetchSign({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Данные о ЭП успешно изменены',
            },
            onSuccess: () => dispatch(getUser()),
            errorCodeMessage: true,
        })
    }

    useEffect(() => {
        if (certificateData.currentCertificate) {
            sendSign()
        }
    }, [certificateData])

    if (isLoading) {
        return <CenteredSpin />
    }

    return (
        <div className={style.generalInfo}>
            <GeneratorInfoFrame
                label={'Общая информация'}
                config={profileInfoConfig(data)}
                customStyle={{ marginTop: 0 }}
                bottomChildren={
                    <>
                        <Divider />
                        <div className={style.authorityTitle}>
                            Документ, подтверждающий полномочия административного пользователя
                        </div>
                        <div className={style.authorityWrapper}>
                            <div>
                                <div className={style.authorityRow}>
                                    <div className={style.authorityName}>
                                        Наименование документа
                                    </div>
                                    <div>{data.document_custom_name}</div>
                                </div>
                            </div>
                            <div>
                                <div className={style.authorityRow}>
                                    <div className={style.authorityName}>Номер</div>
                                    <div>{data.document_custom_number}</div>
                                </div>
                            </div>
                            <div>
                                <div className={style.authorityRow}>
                                    <div className={style.authorityName}>Дата выдачи</div>
                                    <div>
                                        {moment(data.document_custom_betrayed_date).format(
                                            'DD.MM.YYYY',
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={style.authorityRow}>
                                    <div className={style.authorityName}>
                                        Дата истечения срока действия
                                    </div>
                                    <div>
                                        {moment(data.document_custom_end_date).format('DD.MM.YYYY')}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={style.authorityRow}>
                                    <div className={style.authorityName}>Кем выдан</div>
                                    <div>{data.document_custom_betrayed_name}</div>
                                </div>
                            </div>
                        </div>
                        <Divider type={'horizontal'} />
                        {data?.certificate_data && (
                            <>
                                <div className={style.profileHeader}>
                                    {t('myProfile.eSignature')}
                                </div>
                                <div
                                    className={
                                        moment(data?.certificate_data.token_expiration).isAfter()
                                            ? style.stampBlock
                                            : style.expiredStampBlock
                                    }
                                    onClick={openESignatureModalModal}
                                >
                                    <Stamp />
                                    <div className={style.stampOwner}>
                                        {data.certificate_data !== null
                                            ? `${data.certificate_data.sur_name} ${data.certificate_data.common_name}`
                                            : ''}
                                    </div>
                                </div>
                                {!moment(data?.certificate_data.token_expiration).isAfter() && (
                                    <Alert
                                        className={style.responseError}
                                        message={'Истек срок действия ЭП'}
                                        type="error"
                                        showIcon
                                    />
                                )}
                                <Divider type={'horizontal'} />
                            </>
                        )}
                        <div className={style.configButtonsBlock}>
                            <IconButton
                                onClick={onAddCertificate}
                                icon={<Stamp />}
                                customIconStyle={{ marginRight: '8px' }}
                            >
                                {data?.certificate_data ? 'Сменить ЭП' : 'Добавить ЭП'}
                            </IconButton>
                            <Link to="/change-password">
                                <IconButton
                                    icon={<Key />}
                                    customIconStyle={{ marginRight: '8px', marginTop: '4px' }}
                                >
                                    Сменить пароль
                                </IconButton>
                            </Link>
                            <IconButton
                                onClick={openEmailModal}
                                icon={<Email />}
                                customIconStyle={{ marginRight: '8px', marginTop: '4px' }}
                            >
                                Сменить e-mail
                            </IconButton>
                        </div>
                    </>
                }
                isProfile
            />
            <ChangeEmailModal
                isModalVisible={isEmailModalVisible}
                setIsModalVisible={setIsEmailModalVisible}
                currentEmail={data.email}
            />
            <ESignatureModal
                certificateData={data.certificate_data}
                requestId={data.request_id}
                isModalVisible={isESignatureModalVisible}
                setIsModalVisible={setIsESignatureModalVisible}
            />
            {!!certificateData.allCertificates?.length && (
                <CertificatesModal
                    type={'addCertificate'}
                    setCertificate={(certificate) => {
                        setCertificateData((prev) => ({ ...prev, currentCertificate: certificate }))
                    }}
                    allCertificates={certificateData.allCertificates}
                    clearCertificates={() =>
                        setCertificateData((prev) => ({ ...prev, allCertificates: [] }))
                    }
                />
            )}
        </div>
    )
}

export default AdminGeneralInfo
