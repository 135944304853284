import { Slider } from 'antd'
import React, { useState } from 'react'
import style from './style.m.less'

const AntdSlider = () => {
    const [inputValue, setInputValue] = useState<[number, number]>([0, 15])

    const onChange = (value: [number, number]) => {
        setInputValue(value)
    }

    const formatter = (value: number | undefined) => `${value} МВт`

    return (
        <div className={style.slider}>
            <Slider
                range
                defaultValue={inputValue}
                onChange={onChange}
                tooltipPlacement={'bottom'}
                tipFormatter={formatter}
                trackStyle={[{ background: '#528EFF' }]}
                handleStyle={[{ border: '2px solid #528EFF' }, { border: '2px solid #528EFF' }]}
                // с этим пропсом тултип отрисовывается внутри слайдера и можно закаскадировать стили
                getTooltipPopupContainer={(node) => node}
            />
            <div className={style.fieldsWrapper}>
                <div className={style.valueField}>{inputValue[0]}</div>
                <div className={style.valueField}>{inputValue[1]}</div>
            </div>
        </div>
    )
}

const PowerSlider: React.FC = () => <AntdSlider />

export default PowerSlider
