import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useMount from './useMount'
import useRequest from './useRequest'
import { RequestTypes } from './useRequest'

interface ICertificateData {
    toolType: string
    cardLabel: string
    id: string
    isUserType: boolean
    certificateNumber: string
    option: number
    energyType: number
    isIssuedCert: boolean
    isOwner: boolean
}

export const useCertificateData = (pubCertNumber?: string) => {
    const location = useLocation()
    const state = location.state as ICertificateData
    const { fetch } = useRequest({
        link: `/api/green-tools/v1/public-certificate`,
        method: RequestTypes.post,
    })

    const [certData, setSertData] = useState<any>()

    useMount(() => {
        if (!state && pubCertNumber) {
            fetch({
                body: {
                    certificate_number: pubCertNumber,
                },
                errorCodeMessage: true,
                onSuccess: (data) => {
                    setSertData({
                        ...data,
                        cardLabel: 'Сертификат происхождения',
                        id: data.id,
                        isOwner: true,
                        isUserType: true,
                        toolType: 'Сертификаты происхождения',
                    })
                },
            })
        } else {
            setSertData(state)
        }
    })

    if (certData !== undefined) {
        return certData
    }
}
