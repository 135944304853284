import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Settings: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.666 4.3335H10.666"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.00065 4.3335H1.33398"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66732 6.66667C7.95598 6.66667 9.00065 5.622 9.00065 4.33333C9.00065 3.04467 7.95598 2 6.66732 2C5.37865 2 4.33398 3.04467 4.33398 4.33333C4.33398 5.622 5.37865 6.66667 6.66732 6.66667Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.6667 11.6665H12"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.33398 11.6665H1.33398"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.33333 14.0002C10.622 14.0002 11.6667 12.9555 11.6667 11.6668C11.6667 10.3782 10.622 9.3335 9.33333 9.3335C8.04467 9.3335 7 10.3782 7 11.6668C7 12.9555 8.04467 14.0002 9.33333 14.0002Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
