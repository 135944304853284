import { FC, useRef } from 'react'
import { Input } from 'antd'
import style from './style.m.less'
import { Box } from '../Box/Box'

interface IFilter {
    onChange: (data: string | number, key: string, obj?: any) => void
    userType: number
}

const fieldNamer = (ut: number) => {
    switch (ut) {
        case 0:
            return {
                l: 'ИНН',
                ln: 'personal_inn',
                r: 'СНИЛС',
                rn: 'snils',
            }
        case 1:
            return {
                l: 'ИНН',
                ln: 'personal_inn',
                r: 'ОГРНИП',
                rn: 'ogrn',
            }
        case 2:
            return {
                l: 'КПП',
                ln: 'kpp',
                r: 'ОГРН',
                rn: 'ogrn',
            }
        default:
            return {
                l: '',
                ln: '',
                r: '',
                rn: '',
            }
    }
}

const UserFilters: FC<IFilter> = ({ onChange, userType }: IFilter) => {
    const { l, ln, r, rn } = fieldNamer(userType)
    const divBlock: any = useRef(null)
    const isLegal = userType === 2
    const marginSpaceFirstLine = (isLegal ? 2 : 3) * 10
    const itemWidth = `${(650 - marginSpaceFirstLine) / (isLegal ? 2 : 3)}px`
    const itemStyle = { width: itemWidth }
    const itemStyleSecond = { width: 315 }

    const updateFilters = (data: any, key: string) => {
        onChange(data, key)
    }

    return (
        <div className={style.filter} ref={divBlock}>
            <Box direction="row" justify="space-between" margin="15px 0px 0px 0px">
                {isLegal ? (
                    <>
                        <div className={style.filterItem}>
                            <div className={style.label}>Сокращенное название организации</div>
                            <Input
                                placeholder="Введите cокращенное название организации"
                                className={style.input}
                                style={itemStyle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'company_name_short')
                                }
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>ИНН</div>
                            <Input
                                placeholder="Введите ФИО или название"
                                className={style.input}
                                style={itemStyle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'company_inn')
                                }
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.filterItem}>
                            <div className={style.label}>Фамилия</div>
                            <Input
                                placeholder="Введите фамилию"
                                className={style.input}
                                style={itemStyle}
                                onChange={(event) => updateFilters(event.target.value, 'last_name')}
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>Имя</div>
                            <Input
                                placeholder="Введите имя"
                                className={style.input}
                                style={itemStyle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'first_name')
                                }
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>Отчество</div>
                            <Input
                                placeholder="Введите отчество"
                                className={style.input}
                                style={itemStyle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'middle_name')
                                }
                            />
                        </div>
                    </>
                )}
            </Box>
            <Box direction="row" justify="space-between" margin="15px 0px 0px 0px">
                <div className={style.filterItem}>
                    <div className={style.label}>{l}</div>
                    <Input
                        placeholder={`Введите ${l}`}
                        className={style.input}
                        style={itemStyleSecond}
                        onChange={(event) => updateFilters(event.target.value, ln)}
                    />
                </div>
                <div className={style.filterItem}>
                    <div className={style.label}>{r}</div>
                    <Input
                        placeholder={`Введите ${r}`}
                        className={style.input}
                        style={itemStyleSecond}
                        onChange={(event) => updateFilters(event.target.value, rn)}
                    />
                </div>
            </Box>
        </div>
    )
}

export default UserFilters
