import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const BookmarkPlus: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.66683 7.1001H6.3335"
                    strokeMiterlimit="10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 5.47314V8.80648"
                    strokeMiterlimit="10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.2134 1.3335H4.78671C3.36671 1.3335 2.21338 2.4935 2.21338 3.90683V13.3002C2.21338 14.5002 3.07338 15.0068 4.12671 14.4268L7.38005 12.6202C7.72671 12.4268 8.28671 12.4268 8.62671 12.6202L11.88 14.4268C12.9334 15.0135 13.7934 14.5068 13.7934 13.3002V3.90683C13.7867 2.4935 12.6334 1.3335 11.2134 1.3335Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
