import { FC, ReactElement } from 'react'
import moment from 'moment'

import style from './style.m.less'

import { IData } from './data'
import { Tag } from '../../../ui'
import { EStatus, statusMap } from '../../../../constants/approval'

const DateCell: FC<{ date: Date }> = ({ date }) => {
    return <>{moment(date).format('DD.MM.YYYY')}</>
}

const IsNewCell: FC<{ isNew?: boolean; children: ReactElement }> = ({ isNew, children }) =>
    isNew ? <strong>{children}</strong> : children

const render = (value: any, record: IData) => (
    <IsNewCell isNew={record.isNew}>{value || '-'}</IsNewCell>
)

const renderWithDot = (value: any, record: IData) => (
    <div className={record.isNew ? style.newCellDot : undefined}>
        <IsNewCell isNew={record.isNew}>{value || '-'}</IsNewCell>
    </div>
)

export const columns = [
    {
        title: 'Дата подачи',
        dataIndex: 'date',
        key: 'date',
        render: (date: Date, record: IData) => renderWithDot(<DateCell date={date} />, record),
    },
    {
        title: 'Пользователь',
        dataIndex: 'user',
        key: 'user',
        render: render,
    },
    {
        title: 'Действие',
        dataIndex: 'action',
        key: 'action',
        render,
    },
    {
        title: 'Роль пользователя',
        dataIndex: 'userRole',
        key: 'userRole',
        render,
    },
    {
        title: 'ИНН',
        dataIndex: 'inn',
        key: 'inn',
        render,
    },
    {
        title: 'ОГРН/ОГРНИП',
        dataIndex: 'ogrn',
        key: 'ogrn',
        render,
    },
]

export const openedColumns = [
    ...columns,
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (status: EStatus) => (
            <Tag outline transparent>
                {statusMap.get(status)}
            </Tag>
        ),
    },
]

export const closedColumns = [
    ...columns,
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (status: EStatus) => (
            <Tag
                outline
                transparent
                color={
                    status === EStatus.Rejected
                        ? 'red'
                        : status === EStatus.Agreed
                        ? 'blue'
                        : undefined
                }
            >
                {status}
            </Tag>
        ),
    },
]
