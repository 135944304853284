import {
    Card,
    IconButton,
    HeaderBlock,
    DatePicker,
    DeleteModal,
    FileView
} from '../ui'
import { FC, useState, useRef, useEffect } from 'react'
import style from './style.m.less'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Export } from '../../assets/icons'
import { useParams } from 'react-router-dom'
import useRequest, { RequestTypes } from '../hooks/useRequest'

import { ButtonsBlock, CommonBlock } from '../Edit/components'
import { Form, Input, Radio, Divider, Space, Spin } from 'antd'
import moment from 'moment'
import useUploadFile from '../hooks/useUploadFile'
import { Trash } from '../../assets/icons'
import { EUserRole } from '../../constants/approval'
import { useAppSelector } from '../../store/hooks'


const NewsChangeCreate: FC = () => {

    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isAdmin =
    userRole === EUserRole.SuperAdmin ||
    userRole === EUserRole.Administrator

    if (!isAdmin) {
        return <div></div>
    }

    const fileInput = useRef<HTMLInputElement>(null)

    const { id, type } = useParams<{ type: string, id?: string }>()
    const isChange = type === 'change'

    // для получения новости для редактирования
    const { fetch: getNews, result: newsToChange, isLoading: isLoadingNewsToChange } = useRequest({
        link: `/api/content/v1/news`,
        method: RequestTypes.get,
    })

    const { t } = useTranslation()

    const navigate = useNavigate()

    // в сервис контента (6) подгружаем список из 1го файла    (редактирование)
    // is_public=1 для публичного доступа к картинтам
    const { uploadFile, uploading, files, setFiles } = useUploadFile(6, 1, 1)

    // нет удаления файла в сервисе доков
    // просто не показывать/посылать при создании/редактировании
    const [noShowFile, setNoShowFile] = useState(false)

    // или у нас на форме подгужен файл или файл подгруженый для редактирования
    const isFile = files?.[0]?.id || newsToChange?.document?.id
    const file = {
        // когда "удалили" (скрыли noShowFile) картинку то не должно быть file.id
        id: !noShowFile ? files?.[0]?.id || newsToChange?.document?.id : '',
        name: files?.[0]?.name || newsToChange?.document?.file_name,
        size: files?.[0]?.size || newsToChange?.document?.file_size,
    }

    // модалка на кнопку удаления
    const[show, setShow] = useState(false)

    // const { fetch: createNews, result: responseCreateNews, isLoading: isLoadingCreate } = useRequest({
    //     link: `/api/content/v1/news/create`,
    //     method: RequestTypes.post,
    // })

    // создание/редактирование новости
    const { fetch: postNews, result: responsePostNews, isLoading: isLoadingPost } = useRequest({
        link: `/api/content/v1/news`,
        method: RequestTypes.post,
    })

    useEffect(() => {
        if(files?.[0]?.id) {
            // если добавили/поменяли картинку   files
            // то нужно показать файл, если он был скрыт при нажатии на кнопу удалить
            setNoShowFile(false)
        }
    }, [files])

    useEffect(() => {
        if (isChange) {
            getNews({
                addToLink: `?id=${id}`,
                body: {},
                errorCodeMessage: true
            })
        }
        if (type !== 'change') return
    }, [id])

    const [form] = Form.useForm()

    const goBack = () => {
        navigate(`/news${id ? '/' + id : ''}`)
    }

    const onSubmit = () => {
        form.submit()
    }

    const onFinish = async (values: any) => {
        const body = {
            ...values,
            document: {
                id: file.id
            },
            ...(isChange && { id })
        }
        postNews({
            addToLink: `/${type}`,
            body,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Сохранение прошло успешно',
            },
            onSuccess: () => navigate('/news'),
            errorCodeMessage: true,
        })
    }
    
    const handleClickInput = () => {
        fileInput.current?.click()
    }


    const handleClickDelete = async () => {
        // пока нет апи удаления файлов
        // await delFile({
        //     body: {},
        //     successMessage: {
        //         title: t('detailedCard.success'),
        //         description: 'Успешное удаление картинки',
        //     },
        //     errorCodeMessage: true,
        //     onSuccess: () => {
        //         setFiles([])
        //         setNoShowFile(true)
        //     },
        // })
        setFiles([])
        setNoShowFile(true)
        setShow(false)
    }


    const showFile = isFile && !noShowFile

    return (
        <div>
            <div className={style.newsAndEvents}>
                <div className={style.content}>
                    <div className={style.container}>
                        <HeaderBlock label={t(isChange ? 'NewsChangeCreate.editTitle' : 'NewsChangeCreate.createTitle')} />
                        <Spin spinning={isLoadingPost || isLoadingNewsToChange}>
                        <Card style={{ marginTop: '24px' }}>
                            {(type === 'create' || newsToChange) && <Form
                                form={form}
                                initialValues={isChange ? {
                                    ts_created: moment(newsToChange?.ts_created),
                                    title: newsToChange?.title,
                                    news_type_id: newsToChange?.news_type_id,
                                    body: newsToChange?.body,
                                } : {}}
                                onFinish={onFinish}
                                name="formNewsChangeCreate"
                            >
                                <CommonBlock
                                    label={t('NewsChangeCreate.newsDate')}
                                    labelStyle={{ paddingRight: '20px' }}
                                    select={
                                        <Form.Item
                                            style={{ width: '60%' }}
                                            name="ts_created"
                                            rules={[
                                                { required: true, message: t('rules.required') },
                                            ]}
                                        >
                                            <DatePicker
                                                defaultValue={newsToChange?.ts_created ? moment(newsToChange?.ts_created) : ''}
                                                format={'DD.MM.YYYY'}
                                                disabled={isChange}
                                                customStyle={{ width: '30%' }}
                                            />
                                        </Form.Item>}
                                />
                                <CommonBlock
                                    label={t('NewsChangeCreate.newsType')}
                                    customStyle={{ marginTop: '20px' }}
                                    labelStyle={{ paddingRight: '20px' }}
                                    select={
                                        <Form.Item
                                            style={{ width: '60%' }}
                                            name="news_type_id"
                                            rules={[
                                                { required: true, message: t('rules.required') },
                                            ]}
                                        >
                                            <Radio.Group className={style.radio} style={{ width: '50%' }}>
                                                <Radio value={1}>{t('NewsChangeCreate.news')}</Radio>
                                                <Radio value={2}>{t('NewsChangeCreate.events')}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    }
                                />
                                <Divider />
                                <CommonBlock
                                    customStyle={{ alignItems: 'start' }}
                                    label={t('NewsChangeCreate.newsTitle')}
                                    labelStyle={{ paddingRight: '20px', alignItems: 'start' }}
                                    select={
                                        <Form.Item
                                            style={{ width: '50%'}}
                                            name="title"
                                            rules={[
                                                { required: true, message: t('rules.required') },
                                            ]}
                                        >
                                        <Input.TextArea
                                            style={{ minHeight: 45, width: '100%', borderRadius: '8px' }}
                                    />
                                        </Form.Item>
                                    }
                                />
                                <CommonBlock
                                    customStyle={{ marginTop: '24px', alignItems: 'start' }}
                                    label={t('NewsChangeCreate.newsContent')}
                                    labelStyle={{ paddingRight: '20px', alignItems: 'start' }}
                                    select={
                                        <Form.Item
                                            style={{ width: '50%' }}
                                            name="body"
                                            rules={[
                                                { required: true, message: t('rules.required') },
                                            ]}
                                        >
                                    <Input.TextArea
                                        style={{ minHeight: 400, width: '100%', borderRadius: '8px' }}
                                    />
                                        </Form.Item>
                                    }
                                />
                                <div className={style.uploadDocumentFrame}>
                                <input
                                    id="fileInputId"
                                    name="fileId"
                                    type="file"
                                    accept="image/png, image/bpm, image/jpeg"
                                    onChange={uploadFile}
                                    onClick={event => {
                                        // при создании новости после "удаления" файла  не выбирался тотже файл
                                        event.target.value = null
                                    }}
                                    ref={fileInput}
                                    style={{ display: 'none' }}
                                />
                                <div style={{ marginTop: '24px', minWidth: '200px', marginLeft: '200px' }}>
                                <Space>
                                    {showFile && <FileView
                                        style={{ }}
                                        iconName='PngIcon'
                                        title={file.name}
                                        size={file.size}
                                        >
                                    </FileView>}
                                    <Spin style={{ maxWidth: '200px' }} spinning={uploading}>
                                    <Space>
                                        <IconButton
                                            icon={<Export />}
                                            customIconStyle={{ marginTop: '4px', marginRight: '5px' }}
                                            onClick={handleClickInput}
                                            >
                                            {t(isFile ? 'NewsChangeCreate.changeDoc' : 'NewsChangeCreate.downloadDoc')}
                                        </IconButton>
                                        {showFile && <IconButton
                                            customStyle={{ marginLeft: '8px' }}
                                            icon={<Trash />}
                                            onClick={() => setShow(true)}
                                        />}
                                    </Space>
                                </Spin>
                                </Space>
                                </div>
                                <DeleteModal
                                    isModalVisible={show}
                                    headerText='Удалить картинку'
                                    messageText='Вы уверены, что хотите удалить картинку?'
                                    onCancel={() => setShow(false)}
                                    onDelete={() => handleClickDelete()}
                                />
                            </div>
                        </Form>}
                        <Divider />
                        <ButtonsBlock margin confirmAction={onSubmit} cancelAction={goBack} confirmDisabled ={uploading}/>
                        </Card>
                        </Spin>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsChangeCreate
