import { useParams } from 'react-router-dom'
import { AllOperations } from '../Profile'

export const UserOperationsHistory = () => {
    const { id } = useParams()

    return <AllOperations paymentAccoundId={id} />
}

export default UserOperationsHistory
