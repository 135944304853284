// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filtersMain_wBWxQ {\n  width: 416px;\n  height: max-content;\n  padding: 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n  font-weight: 600;\n}\n.filtersBlock__GMez {\n  margin-top: 24px;\n}\n.resetButton_a0cNd {\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Filters/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ","sourcesContent":[".filtersMain {\n    width: 416px;\n    height: max-content;\n    padding: 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n    font-weight: 600;\n}\n\n.filtersBlock {\n    margin-top: 24px;\n}\n\n.resetButton {\n    margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersMain": "filtersMain_wBWxQ",
	"filtersBlock": "filtersBlock__GMez",
	"resetButton": "resetButton_a0cNd"
};
export default ___CSS_LOADER_EXPORT___;
