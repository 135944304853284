import { FC, useState } from 'react'
import moment, { Moment } from 'moment'
import { DatePicker, DropdownSelector2, TimeRangePicker } from '../../../../../ui'
import { useTranslation } from 'react-i18next'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import style from './style.m.less'

const options = [
    { id: 'a851024a-58ae-48dc-b085-178e9966f180', value: 'Все' },
    { id: '9bc31bb4-8ec3-4520-8a18-9eba6400dd57', value: 'В залоге' },
]

interface IMoreFilters {
    toolType: string
    updateFilters: any
    filters: any
    generators: any
    onTimeRangePickerChange: (keys: string[]) => (dates: [Moment, Moment] | null) => void
}

const MoreFilters: FC<IMoreFilters> = ({
    toolType,
    filters,
    updateFilters,
    generators,
    onTimeRangePickerChange,
}) => {
    const { t } = useTranslation()

    const isCertificate = toolType === 'Сертификаты происхождения'
    const isContract = toolType === 'Зеленые договоры'
    const isThirdParty = toolType === 'Сторонние инструменты'

    const [isMoreFilterVisible, setIsMoreFilterVisible] = useState(false)
    const showMoreFilter = () => {
        setIsMoreFilterVisible(!isMoreFilterVisible)
    }

    const generatorsOptions = generators
        ? [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
              ...generators,
          ]
        : [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
          ]

    return (
        <>
            {isMoreFilterVisible && (
                <div className={style.filter}>
                    {isContract ? (
                        <>
                            <div className={style.leftGapBlock}>
                                <div>Дата создания в системе</div>
                                <div className={style.inputBlock}>
                                    <TimeRangePicker
                                        style={{ width: '296px' }}
                                        onChange={onTimeRangePickerChange(
                                            isThirdParty
                                                ? ['created_from', 'created_to']
                                                : ['created_ts_from', 'created_ts_to'],
                                        )}
                                        key={'49a099cf-f442-43d1-8bac-d2d17c5ceae9'}
                                    />
                                </div>
                            </div>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Дата начала поставки по ЗД</div>
                                <TimeRangePicker
                                    style={{ width: '296px' }}
                                    onChange={onTimeRangePickerChange([
                                        'delivery_ts_from',
                                        'delivery_ts_to',
                                    ])}
                                    key={'eb21a730-9489-4baf-b337-fee6c2f9c55e'}
                                />
                            </div>
                        </>
                    ) : isCertificate ? (
                        <>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Залог</div>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={options}
                                    onChange={(value) => {
                                        const returnedValue = value === 'Все' ? null : true
                                        updateFilters(returnedValue, 'is_deposit')
                                    }}
                                    key={'6a644eeb-e1fa-4518-9e6d-52dd5dd52fb1'}
                                />
                            </div>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>ЗГО</div>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    value={filters.generator_id}
                                    options={generatorsOptions?.map((item: any) => {
                                        return {
                                            text: item.name,
                                            value: item.id,
                                            id: item.id,
                                        }
                                    })}
                                    placeholder="Выберите ЗГО"
                                    onChange={(value) => updateFilters(value, 'generator_id')}
                                    key={'58a6c56b-3162-49f0-8d54-23ee21ef4bc5'}
                                />
                            </div>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Дата выдачи</div>
                                <TimeRangePicker
                                    style={{ width: '296px' }}
                                    onChange={(value) => updateFilters(value, 'rangeDate')}
                                    key={'3b6d6af3-5a7a-4e0e-b8a0-a6dd0dbf0d99'}
                                />
                            </div>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Период производства</div>
                                <DatePicker
                                    className={style.select}
                                    picker="month"
                                    disabledDate={(date) => date.isAfter(moment().endOf('day'))}
                                    onChange={(value) => {
                                        updateFilters(
                                            {
                                                month: moment(value).month() + 1,
                                                year: moment(value).year(),
                                            },
                                            'date',
                                        )
                                    }}
                                    key={'86420682-0f38-4657-8ffc-966185418fc2'}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={style.leftGapBlock}>
                                <div className={style.label}>Период производства</div>
                                <DatePicker
                                    className={style.select}
                                    picker="month"
                                    disabledDate={(date) => date.isAfter(moment().endOf('day'))}
                                    onChange={(value) => {
                                        updateFilters(
                                            {
                                                month: moment(value).month() + 1,
                                                year: moment(value).year(),
                                            },
                                            'date',
                                        )
                                    }}
                                    key={'5bcadb1a-3a37-4606-a7e8-0088f9f64ecc'}
                                />
                            </div>
                            <div className={style.leftGapBlock}>
                                <div>Дата создания</div>
                                <div className={style.inputBlock}>
                                    <TimeRangePicker
                                        style={{ width: '296px' }}
                                        onlyBeforeDateAndToday={moment()}
                                        onChange={onTimeRangePickerChange(
                                            isThirdParty
                                                ? ['created_from', 'created_to']
                                                : ['created_ts_from', 'created_ts_to'],
                                        )}
                                        key={'85e78acb-e594-4298-87d5-2e31c2e8ec5e'}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className={style.moreFilter} onClick={showMoreFilter}>
                {!isMoreFilterVisible && <span>{t('questionnaireApproval.moreFilters')}</span>}
                {isMoreFilterVisible && <span>{t('questionnaireApproval.fewerFilters')}</span>}
                {!isMoreFilterVisible && <DownOutlined className={style.icon} />}
                {isMoreFilterVisible && <UpOutlined className={style.icon} />}
            </div>
        </>
    )
}

export default MoreFilters
