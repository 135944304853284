import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton, PrimaryIconButton } from '../../../ui'
import style from './style.m.less'

interface IButtonsBlock {
    confirmAction?: () => void
    cancelAction?: () => void
    confirmTitle?: string
    cancelTitle?: string
    margin?: boolean
    isDelete?: boolean
    disabled?: boolean
}

const ButtonsBlock: FC<IButtonsBlock> = ({
    confirmTitle,
    cancelTitle,
    confirmAction,
    cancelAction,
    margin,
    disabled,
}) => {
    const { t } = useTranslation()

    return (
        <div className={margin ? style.buttonsBlockMargin : style.buttonsBlock}>
            <PrimaryButton
                onClick={cancelAction}
                value={cancelTitle ? cancelTitle : 'Отмена'}
                isCancel
                customStyle={{ padding: '8px 16px' }}
            />
            <PrimaryIconButton
                size="large"
                onClick={confirmAction}
                value={confirmTitle ? confirmTitle : t('edit.save')}
                customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                disabled={disabled}
            />
        </div>
    )
}

export default ButtonsBlock
