import { FC } from 'react'
import { Doughnut } from 'react-chartjs-2/dist'

const data = {
    labels: ['Активные', 'Погашенные', 'Заблокированные', 'Истек срок действия'],
    datasets: [
        {
            borderWidth: 1,
            data: [300, 50, 100, 200],
            backgroundColor: ['#EFDBFF', '#52D2A0', '#BAE7FF', '#F4FFB8'],
            offset: 4,
        },
        {
            responsive: true,
        },
    ],
}

const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
    maintainAspectRatio: false,
}

const AccountChart: FC = () => {
    const myText = '809 458 000 кВт*ч'

    const plugins = [
        {
            beforeDraw: function (chart) {
                const width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx
                ctx.restore()
                const fontSize = (height / 180).toFixed(2)
                ctx.font = fontSize + 'em sans-serif'
                ctx.fontWeight = 600
                ctx.textBaseline = 'top'
                const text = myText,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2.2
                ctx.fillText(text, textX, textY)
                ctx.save()
            },
        },
    ]

    return <Doughnut data={data} options={options} plugins={plugins} />
}

export default AccountChart
