import { FC, useEffect, useState } from 'react'
import { Tag } from 'antd'
import { IconButton, CenteredSpin } from '../ui'
import { ArrowLeft } from '../../assets/icons'
import style from './style.m.less'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmConnectionModal, GeneratorInfoFrame, DocumentsGrid } from './components'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ELoading } from '../../store/dictionaries/interfaces'
import { getDictionaries, selectDictionariesMaps } from '../../store/dictionaries'
import { MainInfoConfig } from '../DetailedCard/generatorInfoConfig'
import PowerTypeTag from '../DetailedCard/components/PowerTypeTag/PowerTypeTag'
import { getUserInfo } from '../../store/user'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import GraphFrame from '../DetailedCard/components/GraphFrame/GraphFrame'
import { GeneratorStatus } from '../../constants/approval'

const MyZGO: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const {
        fetch: loadGenerator,
        isLoading: loading,
        result: generator,
    } = useRequest({
        link: '/api/generators/v1/go',
        method: RequestTypes.get,
    })

    const [isConfirmConnectionModalVisible, setIsConfirmConnectionModalVisible] = useState(false)
    const openConfirmConnectionModal = () => {
        setIsConfirmConnectionModalVisible(true)
    }

    const dispatch = useAppDispatch()
    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
        dispatch(getUserInfo())
    }, [isLoading])

    const dictionaries = useAppSelector(selectDictionariesMaps)
    const userId = useAppSelector((state) => state.user.data.uid)

    const { renewableEnergyTypesMap } = dictionaries

    const [refreshData, setRefreshData] = useState(Date.now())
    useEffect(() => {
        loadGenerator({
            getParams: {
                generator_id: id,
            },
        })
    }, [id, refreshData])

    const isFiled = [
        GeneratorStatus.Canceled,
        GeneratorStatus.UnderConsideration,
        GeneratorStatus.New,
        GeneratorStatus.WaitForPayment,
    ].includes(generator?.generator_status)

    const tsIsMy = generator?.tso?.uid === userId
    const tsoConfirmed = tsIsMy && generator?.tso?.is_confirmed
    const gpIsMy = generator?.gp?.uid === userId
    const gpConfirmed = gpIsMy && generator?.gp?.is_confirmed
    const energyType = renewableEnergyTypesMap?.get(generator?.renewable_energy_type_id)

    return (
        <div className={style.container}>
            {(loading || isLoading === ELoading.Loading) && <CenteredSpin />}
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div>
                    <h1 className={style.title}>{generator?.name}</h1>
                </div>
                {generator?.renewable_energy_type_id && (
                    <PowerTypeTag value={energyType} customStyle={{ marginLeft: '16px' }}>
                        {energyType}
                    </PowerTypeTag>
                )}
                <Tag className={style.statusTag}>
                    {(tsIsMy && tsoConfirmed) || (gpIsMy && gpConfirmed)
                        ? 'Связь подтверждена'
                        : 'Связь не подтверждена'}
                </Tag>
            </div>
            {((tsIsMy && !tsoConfirmed) || (gpIsMy && !gpConfirmed)) && (
                <IconButton onClick={openConfirmConnectionModal}>Подтвердить связь</IconButton>
            )}
            {!isFiled && <GraphFrame />}
            {!!generator && dictionaries && (
                <GeneratorInfoFrame
                    label={'Общая информация'}
                    config={MainInfoConfig(generator, dictionaries)}
                />
            )}
            {!!generator?.documents && <DocumentsGrid documents={generator.documents} />}
            {generator && (
                <ConfirmConnectionModal
                    isModalVisible={isConfirmConnectionModalVisible}
                    generator={generator}
                    onClose={() => setIsConfirmConnectionModalVisible(false)}
                    onCloseAndReload={() => {
                        setRefreshData(Date.now())
                        setIsConfirmConnectionModalVisible(false)
                    }}
                />
            )}
        </div>
    )
}

export default MyZGO
