import { FC, useState } from 'react'
import { Input, Divider, Alert, Empty } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { CenteredSpin, IconButton, Modal, PrimaryButton, PrimaryIconButton } from '../../../ui'
import request from '../../../../services/request'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'
import { EUserType } from '../../../../constants/approval'

export interface IGO {
    company_inn: string
    company_name_short: string
    first_name: string
    last_name: string
    middle_name: string
    middle_name_absent: boolean
    personal_inn: string
    kpp: string
    ogrn: string
    uid: string
    user_type: number
    isDraft: boolean
}

interface ISearchGOOwner {
    isModalVisible: boolean
    onClose(): void
    onReload(): void
    id: string
}

const SearchGOOwner: FC<ISearchGOOwner> = ({ isModalVisible, onClose, onReload, id }) => {
    const { t } = useTranslation()

    const [shortName, setShortName] = useState('')
    const [inn, setInn] = useState('')
    const [ogrn, setOgrn] = useState('')
    const [kpp, setKpp] = useState('')

    const searchIsDisabled = !shortName && !inn && !ogrn && !kpp

    const [goLoading, setGoLoading] = useState(false)
    const [gos, setGos] = useState<IGO[]>([])
    const [selectedGo, setSelectedGo] = useState<IGO>()

    const handleOk = async () => {
        if (!selectedGo) {
            return
        }
        try {
            setGoLoading(true)
            const params = {
                uid_go_owner: selectedGo.uid,
            }
            await request.post(`/api/generators/v1/go-owner-change?generator_id=${id}`, params)
            onReload()
            onClose()
        } finally {
            setGoLoading(false)
        }
    }

    const showSearchResult = async () => {
        try {
            setGoLoading(true)
            const res = await request.post<any, AxiosResponse<{ total: number; go_owners: IGO[] }>>(
                '/api/users/v1/go-owner/find',
                {
                    filters: {
                        name: shortName,
                        inn: inn,
                        ogrn: ogrn,
                        kpp: kpp,
                    },
                    limit: 0,
                    offset: 0,
                },
            )
            setGos(res.data.go_owners)
        } finally {
            setGoLoading(false)
        }
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible} onCancel={onClose}>
                <div className={style.modalHeader}>Поиск Владельца ГО в системе</div>
                <Alert
                    className={style.resultAlert}
                    message="Поиск осуществляется по любому из заполненных полей"
                    type="info"
                    showIcon
                />
                <>
                    <div className={style.lineWrapper}>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>
                                Сокращенное название организации / ФИО
                            </div>
                            <Input
                                placeholder="Введите cокращенное название организации / ФИО"
                                className={style.input}
                                value={shortName}
                                onChange={(e) => setShortName(e.target.value)}
                            />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ИНН</div>
                            <Input
                                placeholder="Введите ИНН"
                                className={style.input}
                                value={inn}
                                onChange={(e) => setInn(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={style.lineWrapper}>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ОГРН / ОГРНИП</div>
                            <Input
                                placeholder="Введите ОГРН/ОГРНИП"
                                className={style.input}
                                value={ogrn}
                                onChange={(e) => setOgrn(e.target.value)}
                            />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>КПП</div>
                            <Input
                                placeholder="Введите КПП"
                                className={style.input}
                                value={kpp}
                                onChange={(e) => setKpp(e.target.value)}
                            />
                        </div>
                    </div>
                </>
                <IconButton
                    customStyle={{ marginTop: '16px' }}
                    onClick={showSearchResult}
                    disabled={searchIsDisabled}
                >
                    Найти
                </IconButton>
                {goLoading ? (
                    <CenteredSpin />
                ) : gos?.length > 0 ? (
                    <>
                        {gos.map((go) => (
                            <div
                                className={classNames(style.resultBlock, {
                                    [style.resultAlert]: selectedGo === go,
                                })}
                                onClick={() => setSelectedGo(go)}
                            >
                                {go.user_type === EUserType.UrLico ? (
                                    <>
                                        <div className={style.alertTitle}>
                                            {go.company_name_short}
                                        </div>
                                        <div>
                                            <span className={style.alertDetail}>
                                                ИНН - {go.company_inn}
                                            </span>
                                            <Divider type="vertical" />
                                            <span className={style.alertDetail}>
                                                ОГРН - {go.ogrn}
                                            </span>
                                            <Divider type="vertical" />
                                            <span className={style.alertDetail}>
                                                КПП - {go.kpp}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={style.alertTitle}>
                                            ИП {go.last_name} {go.first_name} {go.middle_name}
                                        </div>
                                        <div>
                                            <span className={style.alertDetail}>
                                                ИНН - {go.personal_inn}
                                            </span>
                                            <Divider type="vertical" />
                                            <span className={style.alertDetail}>
                                                ОГРНИП - {go.ogrn}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                <Divider />
                <div className={style.buttonsBlock}>
                    <PrimaryButton
                        onClick={onClose}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryIconButton
                        size="large"
                        disabled={!selectedGo}
                        onClick={handleOk}
                        value="Добавить"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default SearchGOOwner
