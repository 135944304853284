import { RequestTypes } from '../hooks/useRequest'

export const CA_API_PREFIX = '/api/ca/v1/'
export const CA_REGISTRY = 'rm/submissions-admin'
export const CA_OWNERS = 'rm/go-owners'
export const CA_DETAILS = 'rm/submission?id='
export const CA_APPROVE = 'rm/submission-confirm'

export const getCaRegistry = {
    link: `${CA_API_PREFIX}${CA_REGISTRY}`,
    method: RequestTypes.post,
}

export const getCaDetails = {
    link: `${CA_API_PREFIX}${CA_DETAILS}`,
    method: RequestTypes.get,
}

export const getGosOwners = {
    link: `${CA_API_PREFIX}${CA_OWNERS}`,
    method: RequestTypes.get,
}

export const postApprove = {
    link: `${CA_API_PREFIX}${CA_APPROVE}`,
    method: RequestTypes.post,
}
