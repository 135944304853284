import { FC } from 'react'
import { ScriptableContext } from 'chart.js'
import { Line } from 'react-chartjs-2/dist'

const data = () => {
    return {
        labels: ['ЯНВ', 'ФЕВ', 'MАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ'],
        datasets: [
            {
                data: [1000, 1500, 1300, 2100, 2300, 3000, 2400],
                fill: true,
                backgroundColor: (context: ScriptableContext<'line'>) => {
                    const ctx = context.chart.ctx
                    const gradient = ctx.createLinearGradient(0, 0, 0, 230)
                    gradient.addColorStop(0, 'rgba(82, 210, 160,0.5)')
                    gradient.addColorStop(1, 'rgba(82, 210, 160,0.07)')
                    return gradient
                },
                borderColor: '#52D2A0',
                borderWidth: 2,
                tension: 0.5,
            },
        ],
    }
}

const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            position: 'right',
            grid: {
                color: '#E6F1FF',
            },
            min: 0,
            max: 4000,
            ticks: {
                color: '#8C8C8C',
                font: {
                    size: 14,
                    weight: '400',
                },
                stepSize: 1000,
            },
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
                color: '#8C8C8C',
                font: {
                    size: 14,
                    weight: '400',
                },
            },
        },
    },
}

const Graph: FC = () => {
    return <Line data={data()} options={options} />
}

export default Graph
