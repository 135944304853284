import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Input, message } from 'antd'
import { Alert, CenteredSpin, LoadingContainer, PrimaryButton, PrimaryIconButton } from '../ui'

import style from './style.m.less'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { AGValidityPeriodResponse } from './types'
import { SuccessMessage } from '../Edit/components'

const AGValidityPeriod: FC = () => {
    const { t } = useTranslation()

    const [currentPeriod, setCurrentPeriod] = useState<number>()
    const [period, setPeriod] = useState<number>()

    const { fetch: getPeriod, isLoading } = useRequest({
        link: '/api/settings/v1/settings/ga-ttl',
        method: RequestTypes.get,
    })

    const { fetch: setPeriodQuery } = useRequest({
        link: '/api/settings/v1/settings/ga-ttl',
        method: RequestTypes.post,
    })

    const onApplyClick = () => {
        setPeriodQuery({
            body: {
                ga_ttl: period,
            },
            onSuccess: () => {
                message.success({
                    content: (
                        <SuccessMessage
                            header={t('detailedCard.success')}
                            message={'Срок успешно изменен'}
                        />
                    ),
                    icon: <></>,
                })
                setCurrentPeriod(period)
            },
        })
    }

    useEffect(() => {
        getPeriod({
            onSuccess: (result: AGValidityPeriodResponse) => {
                setCurrentPeriod(result.ga_ttl)
                setPeriod(result.ga_ttl)
            },
        })
    }, [])

    return (
        <div className={style.main}>
            <LoadingContainer isLoading={isLoading}>
                <div className={style.header}>Срок действия атрибутов генерации</div>
                <div className={style.content}>
                    <div className={style.infoBlock}>
                        <div>Срок действия АГ (в месяцах)</div>
                        <Input
                            value={period}
                            onChange={(event) => {
                                const value = parseInt(event.target.value, 10)
                                setPeriod(Number.isNaN(value) ? undefined : value)
                            }}
                            className={style.input}
                        />
                        <div className={style.label}>Число от 1 до 12</div>
                        <Alert
                            className={style.alert}
                            type="info"
                            message="Обращаем внимание, что срок действия АГ может быть сокращен до конца марта следующего года, если экспирация АГ наступит в следующем году начиная с апреля"
                        />
                    </div>
                    <Divider />
                    <div className={style.buttonWrapper}>
                        <PrimaryButton
                            onClick={() => setPeriod(currentPeriod)}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryIconButton
                            size="large"
                            disabled={
                                period === undefined || period <= 0 || period === currentPeriod
                            }
                            onClick={onApplyClick}
                            value="Применить"
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </div>
            </LoadingContainer>
        </div>
    )
}

export default AGValidityPeriod
