import { FC } from 'react'
import { Tag as AntdTag, TagProps } from 'antd'
import classNames from 'classnames'
import style from './style.m.less'

const Tag: FC<TagProps & { outline?: boolean; transparent?: boolean }> = ({
    outline,
    className,
    transparent,
    ...props
}) => {
    return (
        <AntdTag
            {...props}
            className={classNames(style.tag, className, {
                [style.outline]: outline,
                [style.transparent]: transparent,
            })}
        />
    )
}

export default Tag
