// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader_sFCOd {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.modalText_yyrOK {\n  margin-top: 24px;\n}\n.checkbox_huedm {\n  margin-top: 16px;\n}\n.buttonsBlock_A5D_L {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/TSOProfile/components/ChangeRoleModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.modalText {\n    margin-top: 24px;\n}\n\n.checkbox {\n    margin-top: 16px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "modalHeader_sFCOd",
	"modalText": "modalText_yyrOK",
	"checkbox": "checkbox_huedm",
	"buttonsBlock": "buttonsBlock_A5D_L"
};
export default ___CSS_LOADER_EXPORT___;
