import { FC } from 'react'

import style from './style.m.less'

interface IStatusTag {
    statusId: number
}

const StatusTag: FC<IStatusTag> = ({ statusId }) => {
    const isActivated = statusId === 1

    return (
        <div className={`${style.wrapper} ${isActivated ? style.activated : style.blocked}`}>
            {isActivated ? 'Активен' : 'Заблокирован'}
        </div>
    )
}

export default StatusTag
