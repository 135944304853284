import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import { IconButton, OperationsMapper } from '../../../ui'
import { EmptyWallet } from '../../../../assets/icons'
import { historyConfig } from './configurations'
import style from './style.m.less'

const requisitesArray = [
    { label: 'ИНН Банка получателя', value: '7710140679' },
    { label: 'КПП Банка получателя', value: '771301001' },
    { label: 'БИК Банка получателя', value: '771301000000000001' },
    { label: 'К/С Банка получателя', value: '7713010013434866' },
    { label: 'Получатель', value: 'Организация' },
    { label: 'Банк получателя', value: 'Тинькофф банк' },
    { label: 'Счет получателя в банке получателя', value: '7713010013434866' },
]

const PaymentAccount: FC = () => {
    const { t } = useTranslation()
    return (
        <div className={style.paymentAccount}>
            <div className={style.commonInfoBlock}>
                <div className={style.header}>{t('myProfile.totalInfo')}</div>
                <div className={style.commonInfoRow}>
                    <div className={style.infoBlockFirst}>
                        <div className={style.commonLabel}>{t('myProfile.accountNumber')}</div>
                        <div className={style.commonInfo}>№ 234567876543212345</div>
                    </div>
                    <Divider type="vertical" className={style.verticalDivider} />
                    <div>
                        <div className={style.commonLabel}>{t('myProfile.balance')}</div>
                        <div className={style.commonInfo}>6 000 358 Р</div>
                    </div>
                </div>
                <IconButton
                    customStyle={{ marginTop: '24px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<EmptyWallet />}
                >
                    {t('myProfile.onlineReplenish')}
                </IconButton>
            </div>
            <div className={style.requisitesAndHistoryRow}>
                <div className={style.requisitesBlock}>
                    <div className={style.header}>{t('myProfile.requisite')}</div>
                    {requisitesArray.map((item, index) => {
                        const { label, value } = item
                        return (
                            <div
                                className={
                                    index === 0 ? style.requisiteRowFirst : style.requisiteRow
                                }
                            >
                                <div className={style.requisiteLabel}>{label}</div>
                                <div>{value}</div>
                            </div>
                        )
                    })}
                    <IconButton customStyle={{ marginTop: '32px' }}>
                        {t('myProfile.uploadRequisite')}
                    </IconButton>
                </div>
                <div className={style.historyBlock}>
                    <div className={style.header}>{t('myProfile.operationHistory')}</div>
                    <OperationsMapper config={historyConfig} />
                    <Link to="/go-payment-operations">
                        <IconButton customStyle={{ marginTop: '40px' }}>
                            {t('myProfile.allOperations')}
                        </IconButton>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default PaymentAccount
