import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Form, Input, Space, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { CustomModal, Alert, DropdownSelector } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { DocumentsBlock } from '../../../AddEditGO/components'
import useRequest from '../../../hooks/useRequest'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import { correctAG, getListAG, getListAGOwners } from './config'

const options1 = [
    { id: 1, value: 'Добавить' },
    { id: 2, value: 'Отнять' },
]

const defaultList = (generator_id, tsActivation) => {
    const getAbsoluteMonths = (momentDate) => {
        const months = Number(momentDate.format('MM'))
        const years = Number(momentDate.format('YYYY'))
        return months + years * 12
    }

    const startMonths = getAbsoluteMonths(moment(tsActivation))
    const endMonths = getAbsoluteMonths(moment())

    const monthDiff = endMonths - startMonths

    const res: Array<any> = []
    const arr = monthDiff ? Array(monthDiff).fill(0) : []
    res.push({
        generator_id,
        period: moment().startOf('month').add(0, 'months').format('YYYY-MM-DD'),
        volume: 0,
    })
    arr.forEach((_, i) => {
        res.push({
            generator_id,
            // пустые периоды не приходят с бека
            period: moment()
                .startOf('month')
                .add(-1 - i, 'months')
                .format('YYYY-MM-DD'),
            volume: 0,
        })
    })
    return res
}
interface IFreeGenerationAttributesProps {
    tsActivation: string
    value?: string
    isModalVisible?: boolean
    setIsModalVisible: (s: boolean) => void
    reNewTotalAG: () => void
}

const dateFormat = (date) => {
    return moment(date, 'YYYY-MM-DD').format('MMMM, YYYY')
}

const dateDeFormat = (date) => {
    return moment(date, 'MMMM, YYYY').format('YYYY-MM-DD')
}

const Correct: FC<IFreeGenerationAttributesProps> = ({
    isModalVisible = false,
    setIsModalVisible,
    reNewTotalAG,
    tsActivation,
}) => {
    const { t } = useTranslation()
    const [period, setPeriod] = useState()
    const { id: generator_id } = useParams<{ id: string }>()
    const [files, setFiles] = useState<any[]>([])
    const [form] = useForm()

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const { fetch: getOwners, result: responseOwners } = useRequest(getListAGOwners)

    // Изменение объёма АГ
    const {
        fetch: postAG,
        result: responsePostAG,
        isLoading: isLoadingPostAG,
    } = useRequest(correctAG)

    // Список АГ с фильтрацией
    const {
        fetch: getList,
        result: responseGetList,
        isLoading: isLoadingGetList,
    } = useRequest(getListAG)

    useEffect(() => {
        reNewList()
    }, [])

    const reNewList = () => {
        getList({ body: {}, addToLink: `?generator_id=${generator_id}` })
    }

    useEffect(() => {
        if (period) {
            getOwners({ addToLink: `?generator_id=${generator_id}&period=${period}` })
        }
    }, [period])

    const onFinishAG = async (values: any) => {
        const body = {
            ...values,
            generator_id,
            owner_id: values.owner.value,
            ...(values.type === 'Отнять' && { volume: Number(values.volume * -1) }),
            ...(values.type === 'Добавить' && { volume: Number(values.volume) }),
            period: dateDeFormat(values.period.value),
            documents: (values?.documents || []).map((doc: any) => doc.id),
        }
        delete body.type
        delete body.owner
        postAG({
            body,
            successMessage: {
                title: '',
                description: 'Объем АГ успешно скорректирован',
            },
            onSuccess: () => {
                reNewTotalAG()
                setIsModalVisible(false)
                reNewList()
                form.resetFields()
            },
            errorCodeMessage: true,
        })
    }

    const ownersOptions = responseOwners?.owners.map((item, index: number) => {
        return {
            key: item.id || index,
            id: item.id || index,
            value: item.id,
            label: item.name,
        }
    })

    const options = defaultList(generator_id, tsActivation)?.map((item: any, index: number) => {
        // пробегаемся по списку 12ти месяцев defaultList с пустыми данными
        // если с бека пришли какието данные по месяцу, то отображаем их     иначе пустое значение
        const inList = responseGetList?.list?.find((el: any) => el.period === item.period)
        const monthData = inList || item
        return {
            key: monthData.id || index,
            id: monthData.id || index,
            value: `${capitalize(dateFormat(monthData.period))}`,
            label: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{`${capitalize(dateFormat(monthData.period))}`}</span>
                    <span className={style.greyText}>{`(${monthData.volume} кВт*ч)`}</span>
                </div>
            ),
        }
    })

    const disableCondition = !files.length

    return (
        <CustomModal
            isModalVisible={isModalVisible}
            styleWrapper={{ marginBottom: '24px' }}
            styleButtonsBlock={{ display: 'flex' }}
            params={{
                title: 'Корректировка объема АГ',
                cancelTextButton: t('documents.cancel'),
                confirmTextButton: t('documents.confirm'),
            }}
            children={
                <div className={style.modalAG}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinishAG}
                        name="formAG"
                        className={style.form}
                        validateTrigger={['onSubmit', 'onBlur', 'onChange']}
                    >
                        <Form.Item>
                            <Alert
                                className={style.documentsAlert}
                                message={
                                    'Укажите основание для корректировки атрибутов генерации. Пользователь увидит его в уведомлении о корректировке'
                                }
                                type="info"
                                showIcon
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    {t('Период производства АГ')}
                                    <span className={style.star}>*</span>
                                </>
                            }
                            name="period"
                            rules={[{ required: true, message: t('rules.required') }]}
                        >
                            <Select
                                onChange={(value) => setPeriod(dateDeFormat(value.value))}
                                labelInValue
                                placeholder={'Выберите период производства'}
                                options={options}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    {t('Владелец ЗГО')}
                                    <span className={style.star}>*</span>
                                </>
                            }
                            name="owner"
                            rules={[{ required: true, message: t('rules.required') }]}
                        >
                            <Select
                                labelInValue
                                placeholder={'Выберите Владельца ЗГО'}
                                options={ownersOptions}
                            />
                        </Form.Item>
                        <Space>
                            <Form.Item
                                style={{ width: '200px' }}
                                label={
                                    <>
                                        {t('Добавить/Отнять')} <span className={style.star}>*</span>
                                    </>
                                }
                                name="type"
                                rules={[{ required: true, message: t('rules.required') }]}
                            >
                                <DropdownSelector
                                    placeholder={'Выберите опцию'}
                                    noDefaultValue={true}
                                    options={options1}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{ width: '320px' }}
                                label={
                                    <>
                                        {t('Объем АГ, кВт*ч')} <span className={style.star}>*</span>
                                    </>
                                }
                                name="volume"
                                rules={[
                                    {
                                        required: true,
                                        message: t('rules.required'),
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: t('Введите целое число'),
                                    },
                                ]}
                            >
                                <Input
                                    className={style.inputMiddle}
                                    placeholder={'Укажите объем корректировки'}
                                    type="number"
                                />
                            </Form.Item>
                        </Space>
                        <Form.Item
                            label={
                                <>
                                    {t('Основание')} <span className={style.star}>*</span>
                                </>
                            }
                            name="reason"
                            rules={[
                                { required: true, message: t('rules.required') },
                                {
                                    max: 500,
                                    message: 'Не допустим ввод более 500 символов',
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder={'Ваш комментарий'}
                                style={{ minHeight: 45 }}
                                rows={3}
                                maxLength={501}
                            />
                        </Form.Item>
                        <Divider />
                        <Form.Item name="documents">
                            <DocumentsBlock
                                value={files}
                                onChange={fileHandler}
                                required
                                description="Загрузите необходимые документы"
                            />
                        </Form.Item>
                    </Form>
                </div>
            }
            onClose={() => setIsModalVisible(false)}
            closeAfterConfirm={false}
            confirmTitle={'Подтвердить'}
            confirmDisabled={disableCondition}
            onConfirm={() => {
                form.submit()
            }}
        />
    )
}

export default Correct
