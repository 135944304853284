import { put, StrictEffect, takeLatest } from 'redux-saga/effects'
import { getGenerators, updateGeneratorsList } from './index'
import { getGeneratorsList } from '../../services/generators'

function* handleLoadGenerators({ payload }: ReturnType<typeof getGenerators>) {
    try {
        const limit = payload.limit || 10
        const page = payload.page || 1

        yield put(updateGeneratorsList({ error: '', isLoading: true }))
        const { data, error } = yield getGeneratorsList(page, limit)

        if (error) {
            throw error
        }

        yield put(updateGeneratorsList({ isLoading: false, data: data }))
    } catch (error) {
        console.log(error)
        yield put(updateGeneratorsList({ error, isLoading: false, data: [] }))
    }
}

export function* watchLoadGenerators(): Generator<StrictEffect> {
    yield takeLatest(getGenerators, handleLoadGenerators)
}
