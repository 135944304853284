import { FC } from 'react'
import moment from 'moment'
import { userRolePicker } from '../../../helpers'
import { Stamp } from '../../../../assets/icons'
import { Box, ImportButton } from '../../../ui'
import useDownloadSignedRequest from '../../../hooks/useDownloadSignedRequest'
import style from './style.m.less'

interface IAccountTopBlock {
    eventDetails: any
    noSign?: boolean
}

const AccountTopBlock: FC<IAccountTopBlock> = ({ eventDetails, noSign }) => {
    const { downloadSignedRequest } = useDownloadSignedRequest()

    return (
        <div className={style.accountTopBlockMain}>
            <Box direction="row" justify="space-between">
                <div className={style.infoBlockNoMargin}>
                    <div className={style.infoName}>Дата и время операции</div>
                    <div className={style.infoValue}>
                        {moment(eventDetails.ts_event).format('DD.MM.YYYY HH:mm')}
                    </div>
                </div>
                {!noSign && (
                    <ImportButton
                        customStyle={{ width: '200px' }}
                        icon={<Stamp />}
                        value={'Скачать запрос и подпись'}
                        onClick={() => downloadSignedRequest(eventDetails.payload.request_id)}
                    />
                )}
            </Box>
            <div className={style.infoBlock}>
                <div className={style.infoName}>Исполнитель</div>
                <div>
                    <span className={style.owner}>
                        {eventDetails.name_creator ? eventDetails.name_creator : '-'}
                    </span>
                    <span className={style.role}>
                        ({userRolePicker(eventDetails.role_creator)})
                    </span>
                </div>
            </div>
            <div className={style.infoBlock}>
                <div className={style.infoName}>Адресат действия</div>
                <div>
                    <span className={style.owner}>
                        {eventDetails.name_recipient ? eventDetails.name_recipient : '-'}
                    </span>
                    <span className={style.role}>
                        ({userRolePicker(eventDetails.role_recipient)})
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AccountTopBlock
