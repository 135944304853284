import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const XlsIcon: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="21"
                height="28"
                viewBox="0 0 21 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.6 0H1.4C0.626801 0 0 0.626801 0 1.4V26.6C0 27.3732 0.626801 28 1.4 28H19.6C20.3732 28 21 27.3732 21 26.6V1.4C21 0.626801 20.3732 0 19.6 0Z"
                    fill="#52C41A"
                />
                <path
                    opacity="0.5"
                    d="M16 20H13V22H16V20ZM5 20V22H8V20H5ZM9 20V22H12V20H9ZM16 19V17H13V19H16ZM5 19H8V17H5V19ZM9 19H12V17H9V19ZM16 16V14H5V16H8V14H9V16H12V14H13V16H16ZM4 13H17V23H4V13Z"
                    fill="white"
                />
                <path
                    d="M5.5 9H4.5V7H5.5V6H4.5V4H5.5V5.5L6.5 6L7.5 5.5V4H8.5V6H7.5V7H8.5V9H7.5V7.5L6.5 7L5.5 7.5V9Z"
                    fill="white"
                />
                <path
                    d="M11.42 9C11.1933 9 10.8931 8.92 10.7198 8.76C10.5531 8.6 10.5 8.34667 10.5 8V3H9.5V2H11.5V7.7C11.5 7.80667 11.5267 7.88333 11.58 7.93C11.6333 7.97667 11.7233 8 11.85 8H12.5V9H11.42Z"
                    fill="white"
                />
                <path
                    d="M16.5 7.75C16.5 8.17 16.37 8.48333 16.11 8.69C15.8567 8.89667 15.4867 9 15 9H13.5V8H14.95C15.13 8 15.2667 7.96333 15.36 7.89C15.4533 7.81 15.5 7.69667 15.5 7.55V7.45C15.5 7.15 15.3567 7 15.07 7H14.7C14.2333 7 14.0333 6.9 13.8 6.7C13.5733 6.49333 13.5 6.17 13.5 5.73V5.26C13.5 4.81333 13.5867 4.49333 13.84 4.3C14.0933 4.1 14.5067 4 15 4H16.5V5H15.05C14.8833 5 14.75 5.04 14.65 5.12C14.55 5.19333 14.5 5.30333 14.5 5.45V5.56C14.5 5.71333 14.5433 5.82667 14.63 5.9C14.7233 5.97333 14.84 6 14.98 6H15.13C15.5767 6 15.9167 6.12 16.15 6.34C16.3833 6.55333 16.5 6.87333 16.5 7.3V7.75Z"
                    fill="white"
                />
            </svg>
        )}
    />
)
