import { FC, ReactElement } from 'react'

import style from './style.m.less'

import { EStatus, IData } from './data'
import { Tag } from '../../../ui'
import moment from 'moment'

const DateRangeCell: FC<{ range: [Date, Date] }> = ({ range }) => {
    return (
        <>
            {moment(range[0]).format('DD.MM.YYYY')} - {moment(range[1]).format('DD.MM.YYYY')}
        </>
    )
}

const DateCell: FC<{ date: Date }> = ({ date }) => {
    return <>{moment(date).format('DD.MM.YYYY')}</>
}

const IsNewCell: FC<{ isNew?: boolean; children: ReactElement }> = ({ isNew, children }) =>
    isNew ? <strong>{children}</strong> : children

const render = (value: any, record: IData) => (
    <IsNewCell isNew={record.isNew}>{value || '-'}</IsNewCell>
)

const renderWithDot = (value: any, record: IData) => (
    <div className={record.isNew ? style.newCellDot : undefined}>
        <IsNewCell isNew={record.isNew}>{value || '-'}</IsNewCell>
    </div>
)

export const columns = [
    {
        title: 'Номер договора',
        dataIndex: 'docNo',
        key: 'docNo',
        render: renderWithDot,
    },
    {
        title: 'Отчетный период',
        dataIndex: 'period',
        key: 'period',
        render: (range: [Date, Date], record: IData) =>
            render(<DateRangeCell range={range} />, record),
    },
    {
        title: 'Переданный объем, МВт*ч',
        dataIndex: 'volume',
        key: 'volume',
        render: (volume: number, record: IData) => render(volume.toLocaleString(), record),
    },
    {
        title: 'ЗГО',
        dataIndex: 'zgo',
        key: 'zgo',
        render,
    },
    {
        title: 'Дата подачи',
        dataIndex: 'date',
        key: 'date',
        render: (date: Date, record: IData) => render(<DateCell date={date} />, record),
    },
    {
        title: 'Подал заявку',
        dataIndex: 'applied',
        key: 'applied',
        width: 140,
        render,
    },
]

export const openedColumns = [
    ...columns,
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (status: EStatus) => (
            <Tag outline transparent>
                {status}
            </Tag>
        ),
    },
]

export const closedColumns = [
    ...columns,
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (status: EStatus) => (
            <Tag
                outline
                transparent
                color={
                    status === EStatus.NotAgreed
                        ? 'red'
                        : status === EStatus.Accepted
                        ? 'blue'
                        : undefined
                }
            >
                {status}
            </Tag>
        ),
    },
]
