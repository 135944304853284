export const tagsList = [
    { value: 'Энергия солнца' },
    { value: 'Энергия ветра' },
    { value: 'Энергия воды' },
    { value: 'Энергия приливов' },
    { value: 'ВолнЭС' },
    { value: 'ГеоТЭС' },
    { value: 'НпТЭС' },
    { value: 'Биомасса' },
    { value: 'Биогаз' },
    { value: 'Свгаз' },
    { value: 'Уггаз' },
    { value: 'МС3' },
    { value: 'Энергия атома' },
    { value: 'Уголь' },
    { value: 'Мазут' },
    { value: 'Дизель' },
    { value: 'Природный газ' },
]
