import React, { FC } from 'react'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import Document from './Document'

export interface IFile {
    id: string
    name: string
    size: number
    type?: string
    description: string
    hash: string
}

interface IDocumentsList {
    files: IFile[]
    onChange(file: IFile): void
    onRemove(file: IFile): void
}

const DocumentsList: FC<IDocumentsList> = ({ files, onRemove, onChange }) => {
    const { t } = useTranslation()

    return (
        <>
            {files?.map((item, i) => {
                return (
                    <React.Fragment key={item.hash + i}>
                        <Document
                            key={item.name}
                            file={item}
                            onRemove={() => onRemove(item)}
                            onChange={onChange}
                        />
                        <div className={style.emptyDivider}></div>
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default DocumentsList
