import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import { IconButton } from '../ui'
import { Receipt, DoubleSave } from '../../assets/icons'
import style from './style.m.less'
import { AgreedCards, NotAgreedCards } from './components'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ELoading } from '../../store/dictionaries/interfaces'
import { getDictionaries } from '../../store/dictionaries'
const { TabPane } = Tabs

const tabsList = [
    { label: 'Подтвержденные', key: 1, content: <AgreedCards /> },
    { label: 'Требуют подтверждения', key: 2, content: <NotAgreedCards /> },
]

const RelatedZGO: FC = () => {
    const { t } = useTranslation()

    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [isLoading])

    return (
        <div className={style.generatorsMain}>
            <div className={style.mainHeader}>Связанные ЗГО</div>
            <div className={style.mainWrapper}>
                <div className={style.controlsWrapper}>
                    <IconButton
                        customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                        icon={<Receipt />}
                    >
                        {t('myProfile.submitKU')}
                    </IconButton>
                    <IconButton
                        customStyle={{ marginLeft: '16px' }}
                        customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                        icon={<DoubleSave />}
                    >
                        {t('myProfile.submitZD')}
                    </IconButton>
                </div>
                <Tabs defaultActiveKey="1" className={style.tabs}>
                    {tabsList.map((item) => {
                        const { label, key, content } = item

                        return (
                            <TabPane tab={label} key={key}>
                                {content}
                            </TabPane>
                        )
                    })}
                </Tabs>
            </div>
        </div>
    )
}

export default RelatedZGO
