import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const DoubleBookmark: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="8" fill="#E6F7FF" />
                <path
                    d="M16.5935 11.9199H11.4068C10.2668 11.9199 9.3335 12.8532 9.3335 13.9932V21.5666C9.3335 22.5333 10.0268 22.9466 10.8735 22.4732L13.4935 21.0132C13.7735 20.8599 14.2268 20.8599 14.5002 21.0132L17.1202 22.4732C17.9668 22.9466 18.6602 22.5333 18.6602 21.5666V13.9932C18.6668 12.8532 17.7335 11.9199 16.5935 11.9199Z"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M18.6668 13.9932V21.5666C18.6668 22.5333 17.9735 22.9399 17.1268 22.4732L14.5068 21.0132C14.2268 20.8599 13.7735 20.8599 13.4935 21.0132L10.8735 22.4732C10.0268 22.9399 9.3335 22.5333 9.3335 21.5666V13.9932C9.3335 12.8532 10.2668 11.9199 11.4068 11.9199H16.5935C17.7335 11.9199 18.6668 12.8532 18.6668 13.9932Z"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M22.6668 11.4068V18.9802C22.6668 19.9468 21.9735 20.3535 21.1268 19.8868L18.6668 18.5135V13.9935C18.6668 12.8535 17.7335 11.9202 16.5935 11.9202H13.3335V11.4068C13.3335 10.2668 14.2668 9.3335 15.4068 9.3335H20.5935C21.7335 9.3335 22.6668 10.2668 22.6668 11.4068Z"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )}
    />
)
