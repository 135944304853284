// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popoverWrapper_CQvuD {\n  position: relative;\n}\n.content_PesX4 {\n  width: 350px;\n  position: absolute;\n  top: calc(100% + 8px);\n  left: 0;\n  background: #FFFFFF;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.1);\n  border-radius: 16px;\n  padding: 16px 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Popover/style.m.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAEA;EACI,YAAA;EACA,kBAAA;EACA,qBAAA;EACA,OAAA;EACA,mBAAA;EACA,4EAAA;EACA,mBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".popoverWrapper {\n    position: relative;\n}\n\n.content {\n    width: 350px;\n    position: absolute;\n    top: calc(100% + 8px);\n    left: 0;\n    background: #FFFFFF;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.1);\n    border-radius: 16px;\n    padding: 16px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverWrapper": "popoverWrapper_CQvuD",
	"content": "content_PesX4"
};
export default ___CSS_LOADER_EXPORT___;
