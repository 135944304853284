// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card_r9lVn {\n  background: #FFFFFF;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.cardSection_ivGEv:not(:last-child) {\n  border-bottom: 1px solid #E6F1FF;\n}\n.small_vG7aI {\n  padding: 16px;\n}\n.middle_Pym50 {\n  padding: 24px;\n}\n.large_G_A9D {\n  padding: 32px;\n}\n.zeroSidePadding_bJyr6 {\n  padding: 0px;\n}\n.clickable_zrfgX {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Card/style.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,8CAAA;EACA,mBAAA;AACJ;AAEA;EACI,gCAAA;AAAJ;AAGA;EACI,aAAA;AADJ;AAIA;EACI,aAAA;AAFJ;AAKA;EACI,aAAA;AAHJ;AAMA;EACI,YAAA;AAJJ;AAOA;EACI,eAAA;AALJ","sourcesContent":[".card {\n    background: #FFFFFF;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.cardSection:not(:last-child) {\n    border-bottom: 1px solid #E6F1FF;\n}\n\n.small {\n    padding: 16px;\n}\n\n.middle {\n    padding: 24px;\n}\n\n.large {\n    padding: 32px;\n}\n\n.zeroSidePadding{\n    padding: 0px;\n}\n\n.clickable {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "card_r9lVn",
	"cardSection": "cardSection_ivGEv",
	"small": "small_vG7aI",
	"middle": "middle_Pym50",
	"large": "large_G_A9D",
	"zeroSidePadding": "zeroSidePadding_bJyr6",
	"clickable": "clickable_zrfgX"
};
export default ___CSS_LOADER_EXPORT___;
