// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap_ojD6u {\n  width: 500px;\n}\n.title_IEWqH {\n  font-family: 'Raleway', sans-serif;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.buttonsBlock_cmboF {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 36px;\n}\n.email_peMkp {\n  margin-top: 24px;\n}\n.inputBlock_woRur {\n  margin-top: 2px;\n}\n.input_GnGUj {\n  border-radius: 8px;\n}\n.checkboxes_C9GrB {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/NewsAndEvents/components/SubscribeModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,kCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,eAAA;AAHJ;AAMA;EACI,kBAAA;AAJJ;AAOA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AALJ","sourcesContent":[".wrap {\n    width: 500px;\n}\n\n.title {\n    font-family: 'Raleway', sans-serif;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #303440;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 36px;\n}\n\n.email {\n    margin-top: 24px;\n}\n\n.inputBlock {\n    margin-top: 2px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.checkboxes {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap_ojD6u",
	"title": "title_IEWqH",
	"buttonsBlock": "buttonsBlock_cmboF",
	"email": "email_peMkp",
	"inputBlock": "inputBlock_woRur",
	"input": "input_GnGUj",
	"checkboxes": "checkboxes_C9GrB"
};
export default ___CSS_LOADER_EXPORT___;
