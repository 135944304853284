import { RequestTypes } from '../hooks/useRequest'

export const FUEL_GENERATORS = 'gos'
export const FUEL_API_PREFIX = '/api/fuel/v1/'
export const FUEL_APPLICATION = 'application-create'

export const getGenerators = {
    link: `${FUEL_API_PREFIX}${FUEL_GENERATORS}`,
    method: RequestTypes.get,
}

export const postFuel = {
    link: `${FUEL_API_PREFIX}${FUEL_APPLICATION}`,
    method: RequestTypes.post,
}
