import { FC, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Form, Divider, Input, Checkbox } from 'antd'
import { CommonBlock, ButtonsBlock } from '../../components'
import { useSelector, useDispatch } from 'react-redux'
import { userTypePicker, userTypeIdPicker, lengthConfig, errorHandler } from '../../../helpers'
import IAppState from '../../../../store/states'
import { DropdownSelector, CloseModal, ValueSelector } from '../../../ui'
import { editProfileService, getCompanyService } from '../../../../services/auth'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const userTypes = [
    { value: 'Физическое лицо', id: '60df8c96-6af7-448f-9308-2c0f815a1e37' },
    { value: 'Юридическое лицо', id: '8212377f-4b14-4e93-b2d1-fe76307b7eec' },
    { value: 'Индивидуальный предприниматель', id: '34ce7be6-b338-4e5b-951c-ef2ecade5da0' },
]

const ProfileLayout: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo

    const [responseError, setResponseError] = useState('')
    const [isCloseModalVisible, setIsCloseModalVisible] = useState(false)
    const [isFormChanged, setIsFormChanged] = useState(false)
    const [userType, setUserType] = useState<string>(userTypePicker(data.user_type))
    const [isMiddleName, setIsMiddleName] = useState<boolean>(data.middle_name_absent)
    const [companyName, setCompanyName] = useState<string>(data.company_name)
    const [companies, setCompanies] = useState<any[]>([])
    const [entrepreneurCompanyName, setEntrepreneurCompanyName] = useState(
        `ИП ${data.last_name} ${data.first_name} ${data.middle_name}`,
    )

    const isEntity = userType === 'Юридическое лицо'
    const isEntrepreneur = userType === 'Индивидуальный предприниматель'
    const isEntityOrEntrepreneur =
        userType === 'Юридическое лицо' || userType === 'Индивидуальный предприниматель'
    const isIndividualOrEntity = userType === 'Физическое лицо' || userType === 'Юридическое лицо'

    const onFormLayoutChange = () => {
        setIsFormChanged(true)
        setResponseError('')
    }

    const openCloseModal = () => {
        if (isFormChanged) {
            setIsCloseModalVisible(true)
        } else {
            navigate('/profile')
        }
    }

    const handleSearch = async (value: string) => {
        if (value) {
            const res = await getCompanyService(value, (error) => {
                console.log(error)
            })
            setCompanies(res)
        } else {
            setCompanies([])
        }
    }

    const refreshCompanyName = () => {
        setEntrepreneurCompanyName(
            `ИП ${form.getFieldValue('last_name')} ${form.getFieldValue(
                'first_name',
            )} ${form.getFieldValue('middle_name')}`,
        )
    }

    const onChange = (e: any) => {
        if (!isMiddleName) {
            form.setFieldsValue({
                middle_name: '',
            })
        }
        setIsMiddleName(e.target.checked)
    }

    const handleTypeChange = (value: any) => {
        setUserType(value)
        form.setFieldsValue({ userType: value })
    }

    const onFinish = async (values: any) => {
        if (!isCloseModalVisible) {
            values.user_type = userTypeIdPicker(values.userType)
            if (userType !== 'Индивидуальный предприниматель') {
                values.company_name = companyName
            }
            values.middle_name_absent = isMiddleName
            await editProfileService(
                values,
                () => {
                    navigate('/profile')
                },
                (error) => {
                    setResponseError(errorHandler(error.err_code))
                },
            )
        }
    }

    const getBack = () => {
        navigate('/profile')
    }

    const onSubmit = () => {
        form.submit()
    }

    useEffect(() => {
        form.setFieldsValue(data)
    }, [form, data])

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                name="editProfileForm"
                onValuesChange={onFormLayoutChange}
                initialValues={{
                    userType: userType,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    middle_name: data.middle_name,
                    middle_name_absent: isMiddleName,
                    company_name: data.company_name,
                    personal_inn: data.personal_inn,
                    company_inn: data.company_inn,
                    ogrn: data.ogrn,
                    company_position: data.company_position,
                    phone: data.phone,
                }}
            >
                <CommonBlock
                    required
                    label={t('edit.userType')}
                    select={
                        <Form.Item name="userType">
                            <DropdownSelector
                                defaultValue={userType}
                                options={userTypes}
                                onChange={handleTypeChange}
                                customStyle={{ width: '400px', marginLeft: '16px' }}
                            />
                        </Form.Item>
                    }
                />
                <Divider />
                <CommonBlock
                    required={true}
                    label={t('edit.surname')}
                    select={
                        <Form.Item
                            name="last_name"
                            rules={[
                                {
                                    required: true,
                                    message: t('login.pleaseSurname'),
                                },
                            ]}
                        >
                            <Input
                                onBlur={() => refreshCompanyName()}
                                minLength={1}
                                maxLength={30}
                                className={style.input}
                            />
                        </Form.Item>
                    }
                />
                <CommonBlock
                    required={true}
                    margin
                    label={t('edit.userName')}
                    select={
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    required: true,
                                    message: t('login.pleaseName'),
                                },
                            ]}
                        >
                            <Input
                                onBlur={() => refreshCompanyName()}
                                minLength={1}
                                maxLength={30}
                                className={style.input}
                            />
                        </Form.Item>
                    }
                />
                <CommonBlock
                    required={!isMiddleName ? true : false}
                    margin
                    label={t('edit.lastName')}
                    select={
                        <Form.Item
                            name="middle_name"
                            noStyle={isMiddleName}
                            rules={[
                                {
                                    required: !isMiddleName ? true : false,
                                    message: t('login.pleaseLastName'),
                                },
                            ]}
                        >
                            <Input
                                onBlur={() => refreshCompanyName()}
                                disabled={isMiddleName}
                                minLength={1}
                                maxLength={30}
                                className={style.input}
                            />
                        </Form.Item>
                    }
                >
                    <Checkbox onChange={onChange} className={style.checkbox} checked={isMiddleName}>
                        {t('edit.noLastName')}
                    </Checkbox>
                </CommonBlock>
                <Divider />
                <CommonBlock
                    required={isEntity}
                    label={t('edit.fullName')}
                    select={
                        <Form.Item className={style.dropdownWrapper}>
                            <ValueSelector
                                initValue={
                                    isEntrepreneur ? entrepreneurCompanyName : data.company_name
                                }
                                value={companyName}
                                setValue={setCompanyName}
                                onSearch={handleSearch}
                                disabled={isEntrepreneur}
                                options={companies}
                                validate={isEntity && companyName.length < 100}
                                validationMessage={t('login.pleaseCompany')}
                                placeholder={'Начните вводить название организации'}
                            />
                        </Form.Item>
                    }
                />
                <div className={style.inputsWrapperShort}>
                    <div>
                        <div className={style.inputBlock}>
                            {t('edit.inn')}
                            {isEntityOrEntrepreneur && <div className={style.star}>*</div>}
                        </div>
                        <Form.Item
                            name={isIndividualOrEntity ? 'company_inn' : 'personal_inn'}
                            rules={[
                                {
                                    required: true,
                                    message: t('login.pleaseINN'),
                                },
                            ]}
                        >
                            <Input
                                className={style.inputMiddle}
                                maxLength={lengthConfig[userType].maxInn}
                                minLength={lengthConfig[userType].minInn}
                            />
                        </Form.Item>
                    </div>
                    <div>
                        <div className={style.inputBlock}>
                            {t('edit.ogrn')}
                            {isEntityOrEntrepreneur && <div className={style.star}>*</div>}
                        </div>
                        <Form.Item
                            name="ogrn"
                            rules={[
                                {
                                    required: isEntityOrEntrepreneur,
                                    message: t('login.pleaseOGRN'),
                                },
                            ]}
                        >
                            <Input
                                className={style.inputMiddle}
                                maxLength={lengthConfig[userType].maxOgrn}
                                minLength={lengthConfig[userType].minOgrn}
                            />
                        </Form.Item>
                    </div>
                </div>
                <CommonBlock
                    margin
                    label={t('edit.status')}
                    select={
                        <Form.Item name="company_position">
                            <Input className={style.input} minLength={1} maxLength={50} />
                        </Form.Item>
                    }
                />
                <CommonBlock
                    margin
                    label={t('edit.phoneFax')}
                    select={
                        <Form.Item name="phone">
                            <Input className={style.input} minLength={1} maxLength={50} />
                        </Form.Item>
                    }
                />
                {responseError && (
                    <Alert
                        className={style.responseError}
                        message={responseError}
                        type="error"
                        showIcon
                    />
                )}
            </Form>
            <ButtonsBlock margin confirmAction={onSubmit} cancelAction={openCloseModal} />
            <CloseModal
                isModalVisible={isCloseModalVisible}
                setIsModalVisible={setIsCloseModalVisible}
                headerText={'Отменить редактирование?'}
                messageText={'Все несохраненные данные будут потеряны'}
                confirmTitle={'Да'}
                cancelTitle={'Нет'}
                confirmAction={getBack}
            />
        </>
    )
}

export default ProfileLayout
