import { FC } from 'react'
import { Modal } from '../../..'
import { ButtonsBlock, SuccessMessage } from '../../../../Edit/components'
import useRequest, { RequestTypes } from '../../../../hooks/useRequest'
import { postApprove } from '../../../config'

import style from './style.m.less'
import { QuestionCircle } from '../../../../../assets/icons'
import { ETPOffer } from 'src/api/etp/types'
import { message } from 'antd'

interface ReturnFromMarketModalProps {
    isVisible: boolean
    certificateId?: string
    setIsModalVisible: (value: boolean) => void
    onOk: () => void
}

const ReturnFromMarketModal: FC<ReturnFromMarketModalProps> = ({
    isVisible,
    certificateId,
    setIsModalVisible,
    onOk,
}) => {
    const { fetch: deleteOffer } = useRequest<ETPOffer>({
        link: `/api/green-tools/v1/etp/gc-offers/${certificateId}`,
        method: RequestTypes.delete,
    })

    const handleAction = () => {
        deleteOffer({
            onSuccess: () => {
                message.success({
                    content: <SuccessMessage header="Возвращено с ЭТП" />,
                    icon: <></>,
                })
                onOk()
            },
        })
    }

    return (
        <Modal isModalVisible={isVisible}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <QuestionCircle />
                    <div className={style.header}>Вернуть СП с ЭТП</div>
                </div>
            </div>
            <div className={style.text}>
                Вы уверены, что хотите вернуть сертификат происхождения с ЭТП?
            </div>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleAction}
                    cancelAction={() => setIsModalVisible(false)}
                    confirmTitle="Подтвердить"
                />
            </div>
        </Modal>
    )
}

export default ReturnFromMarketModal
