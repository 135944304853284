import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'
import { IconButton, PrimaryIconButton } from '../../../ui'
import {
    CloseCircle,
    DocIcon,
    Export,
    NoteAdd,
    PdfIcon,
    Trash,
    XlsIcon,
} from '../../../../assets/icons'
import TextArea from 'antd/lib/input/TextArea'

const DocumentsBlock: FC = () => {
    const { t } = useTranslation()

    const [isDescriptionVisible, setIsDescriptionVisible] = useState(true)
    const showDescription = () => {
        setIsDescriptionVisible(!isDescriptionVisible)
    }

    const [isTextAreaVisible, setIsTextAreaVisible] = useState(true)
    const showTextArea = () => {
        setIsTextAreaVisible(!isTextAreaVisible)
    }

    return (
        <div className={style.documentsBlock}>
            <div className={style.documentsListWrapper}>
                <div className={style.documentWrapper}>
                    <PdfIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>
                            {t('editMyProfile.charterAssociation')}
                        </div>
                        <div className={style.fileSize}>183 {t('editMyProfile.mb')}</div>
                    </div>
                </div>
                <div className={style.iconWrapper}>
                    <PrimaryIconButton icon={<NoteAdd />} />
                    <PrimaryIconButton
                        isCancel
                        customStyle={{ marginLeft: '8px' }}
                        icon={<Trash />}
                    />
                </div>
            </div>
            <div className={style.documentsListWrapper}>
                <div className={style.documentWrapper}>
                    <XlsIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>
                            {t('editMyProfile.firstQuarterReport')}
                        </div>
                        <div className={style.fileSize}>183 {t('editMyProfile.mb')}</div>
                    </div>
                </div>
                <div className={style.iconWrapper}>
                    <PrimaryIconButton
                        onClick={showDescription}
                        icon={isDescriptionVisible ? <CloseCircle /> : <NoteAdd />}
                    />
                    {/* <PrimaryIconButton icon={index === 2 ? <CloseCircle /> : <NoteAdd />} /> */}
                    <PrimaryIconButton
                        isCancel
                        customStyle={{ marginLeft: '8px' }}
                        icon={<Trash />}
                    />
                </div>
            </div>
            {isDescriptionVisible && (
                <TextArea
                    className={style.textArea}
                    value={t('editMyProfile.reportDescription')}
                />
            )}
            <div className={style.documentsListWrapper}>
                <div className={style.documentWrapper}>
                    <DocIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>
                            {t('editMyProfile.regulationProcedure')}
                        </div>
                        <div className={style.fileSize}>183 {t('editMyProfile.mb')}</div>
                    </div>
                </div>
                <div className={style.iconWrapper}>
                    <PrimaryIconButton icon={<NoteAdd />} />
                    <PrimaryIconButton
                        isCancel
                        customStyle={{ marginLeft: '8px' }}
                        icon={<Trash />}
                    />
                </div>
            </div>
            <div className={style.documentsListWrapper}>
                <div className={style.documentWrapper}>
                    <XlsIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>
                            {t('editMyProfile.generationAttributes')}
                        </div>
                        <div className={style.fileSize}>183 {t('editMyProfile.mb')}</div>
                    </div>
                </div>
                <div className={style.iconWrapper}>
                    <PrimaryIconButton
                        onClick={showTextArea}
                        icon={isTextAreaVisible ? <CloseCircle /> : <NoteAdd />}
                    />
                    <PrimaryIconButton
                        isCancel
                        customStyle={{ marginLeft: '8px' }}
                        icon={<Trash />}
                    />
                </div>
            </div>
            {isTextAreaVisible && <TextArea className={style.textArea} />}

            <div className={style.uploadDocumentFrame}>
                <IconButton
                    icon={<Export />}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                >
                    {t('editMyProfile.uploadDoc')}
                </IconButton>
                <div className={style.info}>{t('editMyProfile.uploadNecessaryDocs')}</div>
            </div>
        </div>
    )
}

export default DocumentsBlock
