// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paginationMain_vDM1W {\n  height: 64px;\n  width: 848px;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n  background: #fff;\n  margin-top: 16px;\n  padding: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Pagination/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,8CAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;AACJ","sourcesContent":[".paginationMain {\n    height: 64px;\n    width: 848px;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n    background: #fff;\n    margin-top: 16px;\n    padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationMain": "paginationMain_vDM1W"
};
export default ___CSS_LOADER_EXPORT___;
