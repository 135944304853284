import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Flag: FC<IIconProps> = ({ size = 16 }) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.43359 1.3335V14.6668"
                    stroke="#595959"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.43359 2.6665H10.9003C12.7003 2.6665 13.1003 3.6665 11.8336 4.93317L11.0336 5.73317C10.5003 6.2665 10.5003 7.13317 11.0336 7.59984L11.8336 8.39984C13.1003 9.6665 12.6336 10.6665 10.9003 10.6665H3.43359"
                    stroke="#595959"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
