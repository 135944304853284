import { FC, useEffect, useState } from 'react'
import { IconButton, Modal, PrimaryButton, CenteredSpin } from '../../../../ui'
import { Link, useNavigate } from 'react-router-dom'
import { Box } from '../../../../ui/Box'
import { v4 as uuidv4 } from 'uuid'
import { encode, btoa } from 'js-base64'
import { hexToBinary } from '../../../../helpers'
import SignUtils from '../../../../../utils/sign/SignUtils'
import { useAppSelector } from '../../../../../store/hooks'
import style from './style.m.less'
import { postChangeOwner, getFindUsers } from './config'
import useRequest from '../../../../hooks/useRequest'
import { Pagination, Alert, Divider, message, Typography } from 'antd'
import { useGetTariffRate } from '../../../../hooks'
import OwnerCard from './OwnerCard'
import OwnerFilters from './OwnerFilters'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'
import { NoData } from '../../../../../assets/icons'

interface IChangeOwner {
    isModalVisible: boolean
    setIsModalVisible: () => void
    userType?: number
    prevSelected?: string[]
    prevSelectedKey?: string
    uneditable?: boolean
    certificateId?: string
    capacity: number
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
    f?: any
}

const ChangeOwner: FC<IChangeOwner> = ({
    isModalVisible,
    setIsModalVisible,
    prevSelected,
    prevSelectedKey,
    certificateId,
    capacity,
}) => {
    const { isUserAdmin } = useCurrentUser()
    const { fetch: userFetch, result: userList, dropState } = useRequest(getFindUsers)
    const { fetch: changeOwnerRequest } = useRequest(postChangeOwner)
    const [userType, setUserType] = useState(0)
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>({})
    const [selected, setSelected] = useState<any>(undefined)

    const navigate = useNavigate()

    const userInfo = useAppSelector((store) => store.user)
    const { data } = userInfo

    const selectHandle = (d: any) => {
        setSelected(d)
    }

    const changeUserType = (value: number) => {
        setUserType(value)
    }

    const { loadingTariffs, balance, rate } = useGetTariffRate({
        dependency: isModalVisible && !isUserAdmin,
        id: 29,
        volume: capacity,
    })

    const getData = ({ page = 1, pageSize = 10, f = filters }: PaginationType) => {
        userFetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    ...f,
                    user_type: userType,
                },
            },
            errorCodeMessage: true,
        })
    }

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        const updatedFilter =
            key === 'user_type'
                ? { ...(obj ? { ...obj } : { [key]: data }) }
                : {
                      ...filters,
                      ...(obj ? { ...obj } : { [key]: data }),
                  }
        if (key === 'user_type') {
            dropState()
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
    }

    const getUpselected = async () => {
        if (selected) {
            const body = {
                certificate_id: certificateId,
                user_id: selected.uid,
                account_id: selected.personal_account_id,
            }
            if (isUserAdmin) {
                const onSuccess = () => {
                    closeModal()
                    navigate('/green-tools')
                }

                changeOwnerRequest({
                    body,
                    successMessage: {
                        title: 'Успешно',
                        description: 'Владелец сертификата успешно изменен',
                    },
                    onSuccess,
                    errorCodeMessage: true,
                })
            } else {
                const request_id = uuidv4()
                const thumbprint = await SignUtils.getCertificateThumbprint(
                    data.certificate_data.certificate,
                )
                const encodedInfo = encode(JSON.stringify(body))
                const encoded = request_id + encodedInfo
                const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
                const signature = btoa(hexToBinary(preSignature))
                const onSuccess = () => {
                    closeModal()
                    navigate('/user-my-green-tools')
                }

                changeOwnerRequest({
                    body,
                    getParams: {
                        request_id,
                    },
                    config: {
                        headers: {
                            Signature: signature,
                        },
                    },
                    successMessage: {
                        title: 'Успешно',
                        description: 'Владелец сертификата успешно изменен',
                    },
                    onSuccess,
                    errorCodeMessage: true,
                })
            }
        } else {
            message.error('Новый владелец не выбран')
        }
    }

    const find = () => {
        getData({ page: 1, pageSize: pagination.pageSize, f: filters })
    }

    const closeModal = () => {
        dropState()
        setIsModalVisible()
    }

    useEffect(() => {
        if (!isModalVisible) {
            setUserType(0)
            setSelected(undefined)
            setFilters({})
        }
    }, [isModalVisible])

    if (loadingTariffs) {
        return <CenteredSpin />
    }

    return (
        <Modal
            isModalVisible={isModalVisible}
            onCancel={closeModal}
            boxShadow={false}
            bodyStyle={{ width: '713px' }}
            footer={
                <Box width={'100%'}>
                    <Box
                        direction="row"
                        justify="space-between"
                        align="center"
                        margin="10px 0px 10px 0px"
                    >
                        <Box width={'100%'} align="flex-start">
                            {userList?.total > 10 && (
                                <Pagination
                                    pageSize={pagination.pageSize}
                                    current={pagination.page}
                                    total={userList?.total || 0}
                                    onChange={(page, pageSize) => {
                                        getData({ page, pageSize, f: filters })
                                        setPagination({ page, pageSize })
                                    }}
                                    showSizeChanger
                                />
                            )}
                            {!isUserAdmin && (
                                <Alert
                                    className={style.modalAlert}
                                    message={
                                        <div className={style.modalAlertText}>
                                            <div>
                                                За операцию взымается оплата в размере{' '}
                                                <Typography.Text strong>{rate}</Typography.Text>
                                            </div>
                                            <div>Баланс платежного счета: {balance}</div>
                                        </div>
                                    }
                                    type="warning"
                                    showIcon
                                />
                            )}
                            <Divider />
                            <Box direction="row" width={'100%'} justify="flex-end">
                                <PrimaryButton
                                    onClick={closeModal}
                                    value={'Отмена'}
                                    isCancel
                                    customStyle={{ padding: '8px 16px' }}
                                />
                                <PrimaryButton
                                    onClick={getUpselected}
                                    value={isUserAdmin ? 'Подтвердить' : 'Подписать и подтвердить'}
                                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        >
            <Box>
                <Box>
                    <div className={style.modalHeader}>
                        Смена владельца сертификата происхождения
                    </div>
                    <OwnerFilters
                        setUserType={changeUserType}
                        userType={userType}
                        onChange={updateFilters}
                    />
                    <Box width={100} margin="20px 0px 20px -10px">
                        <IconButton
                            onClick={find}
                            className={style.buttonMargin}
                            disabled={!Object.keys(filters).length}
                        >
                            Найти
                        </IconButton>
                    </Box>
                </Box>
                <Box width={650} padding="10px 0px 0px 0px">
                    {!userList?.users && (
                        <div className="ant-empty ant-empty-normal" style={{ margin: '12px 0' }}>
                            <div className="ant-empty-image">
                                <NoData />
                            </div>
                            <div className="ant-empty-description">Нет данных</div>
                        </div>
                    )}
                    {(userList?.users?.length > 0 || userList || userList?.users) &&
                        (userList?.users || []).map((el: any) => (
                            <OwnerCard
                                key={el.personal_account_id}
                                selected={el.personal_account_id === selected?.personal_account_id}
                                disabled={
                                    prevSelected?.includes(el[prevSelectedKey || 'uid']) || false
                                }
                                onClick={selectHandle}
                                user={el}
                            />
                        ))}
                </Box>
            </Box>
        </Modal>
    )
}

export default ChangeOwner
