import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Telegram2: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.57388 6.10443C5.78322 4.27638 8.58544 3.06168 9.99256 2.47238C13.9974 0.800667 14.8393 0.512027 15.3805 0.5C15.5008 0.5 15.7654 0.524054 15.9458 0.668374C16.0901 0.78864 16.1262 0.944988 16.1502 1.06525C16.1743 1.18552 16.1983 1.43808 16.1743 1.63051C15.9578 3.91558 15.0197 9.45988 14.5386 12.0095C14.3342 13.0919 13.9373 13.4527 13.5525 13.4888C12.7106 13.561 12.0732 12.9356 11.2674 12.4064C9.99256 11.5766 9.28298 11.0594 8.04424 10.2416C6.61306 9.30353 7.53911 8.78638 8.35693 7.94451C8.57341 7.72803 12.2656 4.36056 12.3378 4.0599C12.3498 4.02382 12.3498 3.8795 12.2656 3.80734C12.1814 3.73518 12.0611 3.75923 11.9649 3.78328C11.8326 3.80734 9.81216 5.15432 5.87943 7.81222C5.30215 8.2091 4.785 8.40153 4.31596 8.3895C3.79882 8.37747 2.81263 8.10086 2.06697 7.86033C1.16497 7.57169 0.443371 7.41534 0.503504 6.91022C0.539584 6.64563 0.900385 6.38105 1.57388 6.10443Z"
                    fill="currentColor"
                />
            </svg>
        )}
    />
)
