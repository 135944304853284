import { FC, useEffect, useState } from 'react'
import { IconButton, Modal, PrimaryButton } from '../../../ui'
import { Box } from '../../../ui/Box'
import style from './style.m.less'
import { getFindUsers } from './config'
import useRequest from '../../../hooks/useRequest'
import { Pagination, Alert, message } from 'antd'
import ConsumerCard from './ConsumerCard'
import ConsumerFilters from './ConsumerFilters'

interface ISearchConsumer {
    isModalVisible: boolean
    setIsModalVisible: () => void
    userType?: number
    prevSelected?: string[]
    prevSelectedKey?: string
    uneditable?: boolean
    selectHandle: (consumer: any) => void
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
    f?: any
}

const SearchConsumer: FC<ISearchConsumer> = ({
    isModalVisible,
    setIsModalVisible,
    prevSelected,
    prevSelectedKey,
    userType,
    selectHandle,
}) => {
    const { fetch: userFetch, result: userList, dropState } = useRequest(getFindUsers)
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>({})
    const [currentConsumer, setCurrentConsumer] = useState<any>(undefined)

    const selectConsumer = (consumer: any) => {
        setCurrentConsumer(consumer)
    }

    const getData = ({ page = 1, pageSize = 10, f = filters }: PaginationType) => {
        userFetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    ...f,
                    user_type: userType,
                },
            },
            errorCodeMessage: true,
        })
    }

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        const updatedFilter =
            key === 'user_type'
                ? { ...(obj ? { ...obj } : { [key]: data }) }
                : {
                      ...filters,
                      ...(obj ? { ...obj } : { [key]: data }),
                  }
        if (key === 'user_type') {
            dropState()
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
    }

    const getUpselected = () => {
        selectHandle(currentConsumer)
        closeModal()
    }

    const find = () => {
        getData({ page: 1, pageSize: pagination.pageSize, f: filters })
    }

    const closeModal = () => {
        dropState()
        setIsModalVisible()
    }

    useEffect(() => {
        if (!isModalVisible) {
            setCurrentConsumer(undefined)
            setFilters({})
        }
    }, [isModalVisible])

    useEffect(() => {
        if (userList?.users === null) {
            message.error('Потребители с такими данными не найдены')
        }
    }, [userList])

    return (
        <Modal
            isModalVisible={isModalVisible}
            onCancel={closeModal}
            boxShadow={false}
            bodyStyle={{ width: '713px' }}
            footer={
                <Box width={'100%'}>
                    <Box
                        direction="row"
                        justify="space-between"
                        align="center"
                        margin="10px 0px 10px 0px"
                    >
                        <Box width={'100%'} align="flex-start">
                            {userList?.total > 10 && (
                                <Pagination
                                    pageSize={pagination.pageSize}
                                    current={pagination.page}
                                    total={userList?.total || 0}
                                    onChange={(page, pageSize) => {
                                        getData({ page, pageSize, f: filters })
                                        setPagination({ page, pageSize })
                                    }}
                                    showSizeChanger
                                />
                            )}
                            <Box direction="row" width={'100%'} justify="flex-end">
                                <PrimaryButton
                                    onClick={closeModal}
                                    value={'Отмена'}
                                    isCancel
                                    customStyle={{ padding: '8px 16px' }}
                                />
                                <PrimaryButton
                                    onClick={getUpselected}
                                    value={'Выбрать'}
                                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        >
            <Box>
                <Box>
                    <div className={style.modalHeader}>Поиск потребителя в Системе</div>
                    {userType !== 0 && (
                        <Alert
                            className={style.resultAlert}
                            message="Поиск осуществляется по любому из заполненных полей"
                            type="info"
                            showIcon
                        />
                    )}
                    <ConsumerFilters userType={userType} onChange={updateFilters} />
                    <Box width={100} margin="20px 0px 20px -10px">
                        <IconButton
                            onClick={find}
                            className={style.buttonMargin}
                            disabled={!Object.keys(filters).length}
                        >
                            Найти
                        </IconButton>
                    </Box>
                </Box>
                <Box width={650} padding="10px 0px 0px 0px">
                    {(userList?.users?.length > 0 || userList || userList?.users) &&
                        (userList?.users || []).map((el: any) => (
                            <ConsumerCard
                                selected={el.uid === currentConsumer?.uid}
                                disabled={
                                    prevSelected?.includes(el[prevSelectedKey || 'uid']) || false
                                }
                                onClick={selectConsumer}
                                user={el}
                                key={el.uid}
                            />
                        ))}
                </Box>
            </Box>
        </Modal>
    )
}

export default SearchConsumer
