import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'
import { fetchDecline } from '../../config'
import style from './style.m.less'
import useRequest from '../../../hooks/useRequest'
import { QuestionCircle } from '../../../../assets/icons'

interface ICloseEditModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    data: any
}

const CloseEditModal: FC<ICloseEditModal> = ({ isModalVisible, setIsModalVisible, data }) => {
    const { t } = useTranslation()
    const { ts_created } = data
    const navigate = useNavigate()
    const { fetch } = useRequest(fetchDecline)

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const getBack = () => {
        fetch({
            body: {
                ts_created,
            },
            errorCodeMessage: true,
            onSuccess: () => navigate('/user-my-green-tools'),
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <QuestionCircle />
                    <div className={style.header}>Отказаться от согласования</div>
                </div>
            </div>
            <div className={style.text}>
                Вы уверены, что хотите отказаться от подтверждения добавления сторонних
                инструментов?
            </div>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    isDelete
                    confirmAction={getBack}
                    cancelAction={handleOk}
                    confirmTitle={'Отказаться'}
                />
            </div>
        </Modal>
    )
}

export default CloseEditModal
