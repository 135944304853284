import { FC, useEffect, useState } from 'react'
import PageContainer from '../../ui/PageElements/PageContainer/PageContainer'
import TableContainer from '../../ui/Tables/TableContainer/TableContainer'
import { getInquiresUser, getDictHelpdesk, getInquiresAdmin, APPEAL_STATUS } from '..'
import { userTypePicker, userRolePicker } from '../../helpers/profileHelpers'
import PageTitle from '../../ui/PageElements/PageTitle/PageTitle'
import { Alert, Card, IconButton, IconButtonSmall, Tag } from '../../ui'
import { renderDate } from '../../../utils/formatter'
import BulletDot from '../../ui/BulletDot/BulletDot'
import { AddCircle, BookSaved } from '../../../assets/icons'
import useRequest from '../../hooks/useRequest'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useMount from '../../hooks/useMount'
import Filter from '../Filter/Filter'
import style from '../style.m.less'
import { Box } from '../../ui/Box'
import { Menu, Table } from 'antd'
import TopicEdit from './TopicEdit'

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page: number
    pageSize: number
    filters?: any
}

export const UserStatusTag = ({ status }: any) => (
    <Tag outline transparent color={status === 7 ? 'red' : status === 254 ? 'blue' : undefined}>
        {APPEAL_STATUS[status] || 'Неизвестно'}
    </Tag>
)

export const Helpdesk: FC<{ title: string }> = ({ title }) => {
    const location = useLocation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const isClient = location.pathname === '/my-appeals'
    const { fetch: fetchDict, result: dict } = useRequest(getDictHelpdesk)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const { fetch, result, isLoading, isSuccess } = useRequest(
        isClient ? getInquiresUser : getInquiresAdmin,
    )
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [firstLoad, setFirstLoad] = useState<boolean>(true)
    const [filters, setFilters] = useState<any>(
        isClient
            ? {}
            : {
                  is_opened: true,
              },
    )
    const isFiltred = Object.keys(filters).length > (isClient ? 0 : 1)

    const getData = ({ page = 1, pageSize = 10, filters }: PaginationType) => {
        fetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters,
            },
        })
    }

    const updateFilters = (data: string | number | boolean, key: string, obj?: any) => {
        const updatedFilter = {
            ...filters,
            ...(obj ? { ...obj } : { [key]: data }),
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        getData({ page: 1, pageSize: pagination.pageSize, filters: updatedFilter })
    }

    const fetchByType = (state: string) => {
        updateFilters('', '', {
            is_opened: state === 'open',
            status_id: 0,
        })
    }

    const fetchDictionaries = () => fetchDict({})

    useMount(() => {
        fetchDictionaries()
    })

    useEffect(() => {
        if (!dict) {
            return
        }
        getData({ ...DEFAULT_PAG, filters })
    }, [dict])

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false)
        }
    }, [isSuccess])

    const NewAppealButton = () => (
        <IconButton
            icon={<AddCircle />}
            onClick={() => navigate(`/new-appeal`)}
            customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
        >
            {t('approval.create')}
        </IconButton>
    )
    const getAdminCols = (client: boolean) =>
        client
            ? []
            : [
                  {
                      title: 'ФИО / Организация',
                      dataIndex: 'user',
                      elipsis: true,
                      key: 'user',
                      render: (_: any, row: any) =>
                          row.user.user_type === 2
                              ? row.user.company_name_short
                              : `${row.user.last_name} ${row.user.first_name} ${row.user.middle_name}`,
                  },
                  {
                      title: 'Тип пользователя',
                      dataIndex: 'user_type',
                      key: 'user_type',
                      render: (_: any, row: any) => userTypePicker(row.user.user_type),
                  },
                  {
                      title: 'Роль пользователя',
                      dataIndex: 'user_role',
                      key: 'user_role',
                      render: (_: any, row: any) => userRolePicker(row.user.user_role),
                  },
              ]

    const COLUMNS = [
        {
            title: '',
            dataIndex: 'has_update',
            key: 'has_update',
            width: 20,
            render: (has: boolean) => (has ? <BulletDot color="#528EFF" /> : <></>),
        },
        {
            title: 'Номер заявки',
            dataIndex: 'id',
            key: 'id',
        },
        ...getAdminCols(isClient),
        {
            title: 'Тема заявки',
            dataIndex: 'topic_id',
            key: 'topic_id',
            render: (id: number) => (
                <div>{dict.find((l: any) => l.id === id)?.topic || 'Неизвестно'}</div>
            ),
        },
        {
            title: 'Дата и время подачи',
            dataIndex: 'ts_created',
            key: 'ts_created',
            render: (date: Date) => renderDate(date),
        },
        {
            title: 'Статус',
            dataIndex: 'status_id',
            key: 'status_id',
            render: (status: number) => <UserStatusTag status={status} />,
        },
    ]

    const visibleHandler = () => setIsModalVisible(!isModalVisible)

    return (
        <PageContainer>
            <PageTitle
                classNames={style.title}
                title={title}
                extra={
                    !isClient && (
                        <IconButtonSmall
                            style={{ alignSelf: 'self-start' }}
                            icon={<BookSaved />}
                            onClick={visibleHandler}
                        >
                            Справочник тем заявок
                        </IconButtonSmall>
                    )
                }
            />
            <TopicEdit
                setIsModalVisible={visibleHandler}
                topics={dict}
                isModalVisible={isModalVisible}
                getTopics={fetchDictionaries}
            />
            {isClient && (
                <>
                    <NewAppealButton />
                    <Box height={20} />
                </>
            )}
            {/* TODO OUT COMPONENT */}
            {!isClient && (
                <Menu
                    mode="horizontal"
                    inlineIndent={24}
                    onClick={(s) => fetchByType(s.key)}
                    defaultSelectedKeys={['open']}
                    // selectedKeys={menuState}
                    style={{
                        fontWeight: 600,
                        color: '#595959',
                        borderBottom: 'none',
                    }}
                >
                    <Menu.Item className={style.tab} key={'open'}>
                        Открытые
                    </Menu.Item>
                    <Menu.Item className={style.tab} key={'closed'}>
                        Закрытые
                    </Menu.Item>
                </Menu>
            )}

            {result?.total > 0 || isFiltred ? (
                <Card>
                    <Filter
                        onChange={updateFilters}
                        dict={dict}
                        filters={filters}
                        isClient={isClient}
                        isOpend={filters.is_opened}
                    />
                    <TableContainer>
                        <Table
                            dataSource={result?.inquires || []}
                            columns={COLUMNS}
                            rowClassName={(record) => (record.has_update ? style.rowBold : '')}
                            pagination={{
                                position: ['bottomLeft'],
                                onChange: (page, pageSize) => {
                                    getData({ page, pageSize, filters })
                                    setPagination({ page, pageSize })
                                },
                                total: result?.total,
                                current: pagination.page,
                                pageSize: pagination.pageSize,
                                showSizeChanger: true,
                            }}
                            onRow={(record) => ({
                                onClick: () => {
                                    navigate(`/appeal/${record.id}`)
                                },
                            })}
                            loading={isLoading}
                        />
                    </TableContainer>
                </Card>
            ) : (
                <Card>
                    <Box width={260}>
                        <Alert message="У вас пока нет поданных заявок" type="info" />
                    </Box>
                    <Box margin="30px 0px 0px 0px" width={155}>
                        <NewAppealButton />
                    </Box>
                </Card>
            )}
        </PageContainer>
    )
}
