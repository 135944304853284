import { FC } from 'react'
import { Input } from 'antd'
import { InputIcon } from '../../../assets/icons'
import style from './style.m.less'
import './style.less'
import { useTranslation } from 'react-i18next'

interface IInputSearch {
    isLong?: boolean
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputSearch: FC<IInputSearch> = ({ isLong, ...rest }) => {
    const { t } = useTranslation()

    return (
        <Input
            className={isLong ? style.controlsInputLong : style.controlsInput}
            placeholder={t('inputSearch.search')}
            prefix={<InputIcon />}
            {...rest}
        />
    )
}

export default InputSearch
