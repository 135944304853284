import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Warning: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.8756 16.875C10.8756 17.1734 10.9941 17.4595 11.2051 17.6705C11.4161 17.8815 11.7022 18 12.0006 18C12.299 18 12.5851 17.8815 12.7961 17.6705C13.0071 17.4595 13.1256 17.1734 13.1256 16.875C13.1256 16.5766 13.0071 16.2905 12.7961 16.0795C12.5851 15.8685 12.299 15.75 12.0006 15.75C11.7022 15.75 11.4161 15.8685 11.2051 16.0795C10.9941 16.2905 10.8756 16.5766 10.8756 16.875ZM11.2506 9.75V14.0625C11.2506 14.1656 11.335 14.25 11.4381 14.25H12.5631C12.6662 14.25 12.7506 14.1656 12.7506 14.0625V9.75C12.7506 9.64687 12.6662 9.5625 12.5631 9.5625H11.4381C11.335 9.5625 11.2506 9.64687 11.2506 9.75ZM22.3998 20.0625L12.6498 3.1875C12.5045 2.93672 12.2537 2.8125 12.0006 2.8125C11.7475 2.8125 11.4943 2.93672 11.3514 3.1875L1.60138 20.0625C1.31309 20.5641 1.67403 21.1875 2.25059 21.1875H21.7506C22.3272 21.1875 22.6881 20.5641 22.3998 20.0625ZM4.03653 19.4086L12.0006 5.62266L19.9647 19.4086H4.03653Z"
                    fill="#BFBFBF"
                />
            </svg>
        )}
    />
)
