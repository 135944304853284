import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'

interface IErrorScreen {
    type?: 'common' | 'invalidLink'
}

const ErrorScreen: FC<IErrorScreen> = ({ type = 'common' }) => {
    const { t } = useTranslation()

    return (
        <div className={style.errorBoundaryWrapper}>
            <div className={style.errorRow}>
                <div className={style.errorInfo}>
                    <div className={style.errorHeader}>
                        {type === 'common'
                            ? t('errorBoundary.title')
                            : t('errorBoundary.invalidLinkTitle')}
                    </div>
                    <div className={style.errorText}>
                        {type === 'common'
                            ? t('errorBoundary.text')
                            : t('errorBoundary.invalidLinkText')}
                    </div>
                    <a href="/main">
                        <button className={style.errorButton}>{t('errorBoundary.link')}</button>
                    </a>
                </div>
                <img
                    alt="error-img"
                    width={728}
                    height={417}
                    src={require('../../../../assets/images/error-img.png')}
                />
            </div>
        </div>
    )
}

export default ErrorScreen
