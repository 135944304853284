import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Receipt: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.334 5.00033V7.01699C18.334 8.33366 17.5006 9.16699 16.184 9.16699H13.334V3.34199C13.334 2.41699 14.0923 1.66699 15.0173 1.66699C15.9257 1.67533 16.759 2.04199 17.359 2.64199C17.959 3.25033 18.334 4.08366 18.334 5.00033Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.66602 5.83366V17.5003C1.66602 18.192 2.44933 18.5837 2.99933 18.167L4.42435 17.1003C4.75768 16.8503 5.22435 16.8837 5.52435 17.1837L6.90766 18.5753C7.23266 18.9003 7.76603 18.9003 8.09103 18.5753L9.49103 17.1753C9.78269 16.8837 10.2494 16.8503 10.5744 17.1003L11.9994 18.167C12.5494 18.5753 13.3327 18.1837 13.3327 17.5003V3.33366C13.3327 2.41699 14.0827 1.66699 14.9993 1.66699H5.83268H4.99935C2.49935 1.66699 1.66602 3.15866 1.66602 5.00033V5.83366Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5 7.5H10"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.625 10.833H9.375"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
