import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../../ui'

import style from './style.m.less'

export enum ETab {
    Manually,
    FromFile,
}

interface ITagTabs {
    tab: ETab
    onChange(tab: ETab): void
}

const TagTabs: FC<ITagTabs> = ({ tab, onChange }) => {
    const { t } = useTranslation()

    return (
        <div className={style.tagFilter}>
            <Tag
                outline
                className={style.clickable}
                color={tab === ETab.Manually ? 'blue' : undefined}
                onClick={() => onChange(ETab.Manually)}
            >
                Вручную
            </Tag>
            <Tag
                outline
                className={style.clickable}
                color={tab === ETab.FromFile ? 'blue' : undefined}
                onClick={() => onChange(ETab.FromFile)}
            >
                Из файла
            </Tag>
        </div>
    )
}

export default TagTabs
