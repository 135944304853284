import { FC } from 'react'
import { Modal, PrimaryButton, LoadingContainer, Box } from '../../../ui'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import { useNavigate } from 'react-router-dom'
import { signBody, monthPicker, currencyFormat } from '../../../helpers'
import { useGetTariffRate } from '../../../hooks'
import useRequest from '../../../hooks/useRequest'
import IAppState from '../../../../store/states'
import { payRealization } from '../../config'
import style from './style.m.less'

interface IPaymentModal {
    isModalVisible: boolean
    onCancel: () => void
    consumers: { user_id: string; volume: number }[]
    onOpenSubModal: () => void
    data: any
}

const PaymentModal: FC<IPaymentModal> = ({
    isModalVisible,
    onCancel,
    data,
    consumers,
    onOpenSubModal,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userInfo = useSelector((store: IAppState) => store.user)
    const userData = userInfo.data

    const { fetch, isLoading } = useRequest(payRealization)
    const { contract_number, contract_id, period_date, fee, seller_name, volume, buyer_uid } = data

    const { loadingTariffs, balance, balanceNumber, rate } = useGetTariffRate({
        dependency: isModalVisible,
        id: 67,
        volume,
    })

    const onConfirm = async () => {
        if (Number(balanceNumber) < fee) {
            onOpenSubModal()
            return
        }

        const body = {
            contract_number,
            period_id: contract_id,
            period_date,
            buyer_uid,
            volume,
            buyer_fee: fee,
            consumers,
        }
        const { request_id, signature } = await signBody(body, userData)

        fetch({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Реализация АГ по ЗД прошла успешно',
            },
            onSuccess: () => {
                navigate(-1)
                onCancel()
            },
            errorCodeMessage: true,
        })
    }

    return (
        <LoadingContainer isLoading={loadingTariffs || isLoading}>
            <Modal isModalVisible={isModalVisible} onCancel={onCancel}>
                <div className={style.modal}>
                    <div className={style.modalWrapper}>
                        <div className={style.header}>{'Оплата комиссии'}</div>
                    </div>
                    <Box style={{ marginTop: '16px' }}>
                        <Box direction="row">
                            <div className={style.leftSide}>Номер договора</div>
                            <div className={style.rightSide}>{contract_number}</div>
                        </Box>
                        <div className={style.wrapper}>
                            <div className={style.leftSide}>Продавец (ЗГО)</div>
                            <div className={style.rightSide}>{seller_name}</div>
                        </div>
                        <div className={style.wrapper}>
                            <div className={style.leftSide}>Период поставки</div>
                            <div className={style.rightSide}>
                                {`${monthPicker(moment(period_date).month() + 1)}, ${moment(
                                    period_date,
                                ).year()}`}
                            </div>
                        </div>
                        <div className={style.wrapper}>
                            <div className={style.leftSide}>Объем</div>
                            <div className={style.rightSide}>{`${currencyFormat(
                                volume,
                            )} кВт*ч`}</div>
                        </div>
                        <Divider style={{ margin: '12px 0' }} />
                        <Box direction="row">
                            <div className={style.leftSide}>
                                <div>Комиссия за реализацию</div> АГ по ЗД
                            </div>
                            <div className={style.rightSide}>{`${currencyFormat(fee)} ₽`}</div>
                        </Box>
                    </Box>
                    <div className={style.payAlert}>
                        <div className={style.payHeader}>
                            <span className={style.alertMessage}>Сумма комиссии составила </span>
                            <span className={style.alertPrice}>{`${currencyFormat(fee)} ₽`}</span>
                        </div>
                        <div className={style.payText}>
                            <span className={style.alertDetail}>Баланс платежного счета</span>
                            <span className={style.alertDetail}>: {balance}</span>
                        </div>
                    </div>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={onCancel}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            onClick={onConfirm}
                            value="Оплатить"
                            customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                        />
                    </div>
                </div>
            </Modal>
        </LoadingContainer>
    )
}

export default PaymentModal
