import { FC } from 'react'

import style from './style.m.less'
import { Card } from '../ui'
import { Default } from './components'
import { useTranslation } from 'react-i18next'

const Audit: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.container}>
            <h1 className={style.title}>{t('audit.title')}</h1>
            <div className={style.tabs}>
                <Card>
                    <Default />
                </Card>
            </div>
        </div>
    )
}

export default Audit
