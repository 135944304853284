// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_D5lP5 {\n  margin-top: 24px;\n}\n.newCellDot_GUC8r {\n  position: relative;\n}\n.newCellDot_GUC8r::before {\n  content: '';\n  position: absolute;\n  top: 8px;\n  left: -15px;\n  width: 6px;\n  height: 6px;\n  background: #528EFF;\n  border-radius: 50%;\n}\n.alert_ZXLHx {\n  border-radius: 12px;\n  border: 1px solid #BAE7FF;\n  color: #555861;\n  width: max-content;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ZDApproval/components/Tables/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,kBAAA;AAAJ;AAEI;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AAIA;EACI,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AAFJ","sourcesContent":[".table {\n    margin-top: 24px;\n}\n\n.newCellDot {\n    position: relative;\n\n    &::before {\n        content: '';\n        position: absolute;\n        top: 8px;\n        left: -15px;\n        width: 6px;\n        height: 6px;\n        background: #528EFF;\n        border-radius: 50%;\n    }\n}\n \n.alert {\n    border-radius: 12px;\n    border: 1px solid #BAE7FF;\n    color: #555861;\n    width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table_D5lP5",
	"newCellDot": "newCellDot_GUC8r",
	"alert": "alert_ZXLHx"
};
export default ___CSS_LOADER_EXPORT___;
