import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const AGHistoryChanged: FC = () => (
    <AntIcon
        component={() => (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
            >
                <rect width="32" height="32" rx="8" fill="#E6F7FF" />
                <path
                    d="M19.2148 9.33301H12.7882C11.3682 9.33301 10.2148 10.493 10.2148 11.9063V21.2997C10.2148 22.4997 11.0748 23.0063 12.1282 22.4263L15.3815 20.6197C15.7282 20.4263 16.2882 20.4263 16.6282 20.6197L19.8815 22.4263C20.9348 23.013 21.7948 22.5063 21.7948 21.2997V11.9063C21.7882 10.493 20.6348 9.33301 19.2148 9.33301Z"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M14.3945 15.3337L15.3945 16.3337L18.0612 13.667"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )}
    />
)
