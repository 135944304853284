import { RequestTypes } from '../hooks/useRequest'

export const CA_API_PREFIX = '/api/ca/v1/'
export const CA_SUBMISSION = '/rm/submission'
export const CA_OPERATION_STATUS = 'wm/status-operation'

export const postKU = {
    link: `${CA_API_PREFIX}${CA_SUBMISSION}`,
    method: RequestTypes.post,
}

export const getOperationStatus = {
    link: `${CA_API_PREFIX}${CA_OPERATION_STATUS}`,
    method: RequestTypes.get,
}
