import { FC, useEffect } from 'react'
import { Divider, Tooltip } from 'antd'
import { CardInfoBlock } from '../../../ui'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Flash, Calendar, Location, NotVerify, Lock } from '../../../../assets/icons'
import { selectDictionariesMaps } from '../../../../store/dictionaries'
import { useAppSelector } from '../../../../store/hooks'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import PowerTypeTag from '../PowerTypeTag/PowerTypeTag'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'

export interface IGenerator {
    generator_id: string
    go_name: string
    ga_available: number
    power: number
    go_type_id: number
    qualification_date: string | null
    last_check_date: string | null
    region_id: number
    renewable_energy_type_id: number
    blocks: {
        ga_fixation: boolean
        gt_emission: boolean
        ga_transfer: boolean
        ga_redemption: boolean
    }
    ts_deregistered: string
}

interface IGeneratorCard {
    generatorData: IGenerator
}

const GeneratorCard: FC<IGeneratorCard> = ({ generatorData }) => {
    // Получение информации по АГ
    const {
        fetch: getTotalAG,
        result: responseTotalAG,
        isLoading: isLoadingTotalAG,
    } = useRequest({
        link: `/api/generation_attributes/v1/gas/ga/total`,
        method: RequestTypes.get,
    })

    useEffect(() => {
        reNewTotalAG()
    }, [])

    const reNewTotalAG = () => {
        getTotalAG({
            body: {},
            addToLink: `?generator_id=${generatorData.generator_id}`,
        })
    }

    const {
        generator_id,
        go_name,
        power,
        qualification_date,
        last_check_date,
        region_id,
        renewable_energy_type_id,
        // ga_available, должно приходить с бека TODO
        go_type_id,
        ts_deregistered,
        blocks,
    } = generatorData

    const { t } = useTranslation()

    const dictionaries = useAppSelector(selectDictionariesMaps)

    const { regionsMap, renewableEnergyTypesMap } = dictionaries

    const energyType = renewableEnergyTypesMap.get(renewable_energy_type_id)

    const hasBlock = blocks && Object.values(blocks).filter((block) => block).length > 0

    return (
        <div className={style.cardMain}>
            <div>
                <Link to={`/generator-card/${generator_id}`} className={style.cardHeader}>
                    <div className={style.title}>
                        {ts_deregistered && moment(ts_deregistered).isBefore(moment()) && (
                            <Tooltip
                                placement="top"
                                color="#434343"
                                overlayInnerStyle={{ color: 'white' }}
                                title="Снято с учета"
                            >
                                <div className={style.notVerify}>
                                    <NotVerify />
                                </div>
                            </Tooltip>
                        )}{' '}
                        {hasBlock && (
                            <div className={style.block}>
                                <Lock />
                            </div>
                        )}
                        {go_name}
                    </div>
                </Link>
                <Tooltip
                    placement="top"
                    color="#434343"
                    overlayInnerStyle={{ color: 'white' }}
                    title="Свободно атрибутов генерации"
                >
                    <div className={style.freeGenerationAttributes}>
                        {/* {ga_available.toLocaleString()} {t('generatorCard.scale2')} */}
                        {responseTotalAG?.free_volume?.toLocaleString()} {t('generatorCard.scale2')}
                    </div>
                </Tooltip>
                <div className={style.infoRow}>
                    <CardInfoBlock
                        label={t('generatorCard.power')}
                        value={`${(power / 1000).toLocaleString()} ${t('generatorCard.scale')}`}
                        icon={<Flash />}
                        gap={7}
                    />
                    <Divider className={style.divider} type={'vertical'} />
                    <CardInfoBlock
                        label="Тип ГО"
                        value={go_type_id === 1 ? 'КГО' : 'ЗГО'}
                        gap={6}
                    />
                    <Divider className={style.divider} type={'vertical'} />
                    <CardInfoBlock
                        label={t('generatorCard.qualifyDate')}
                        value={
                            qualification_date
                                ? moment(qualification_date).format('DD.MM.YYYY')
                                : '-'
                        }
                        icon={<Calendar />}
                        gap={6}
                    />
                    <Divider className={style.divider} type={'vertical'} />
                    <CardInfoBlock
                        label={t('generatorCard.lastCheckDate')}
                        value={last_check_date ? moment(last_check_date).format('DD.MM.YYYY') : '-'}
                        icon={<Calendar />}
                        gap={6}
                    />
                </div>
                <div className={style.locationBlock}>
                    <CardInfoBlock
                        value={`Россия, ${regionsMap.get(region_id)}`}
                        icon={<Location />}
                        gap={11}
                    />
                </div>
            </div>
            {energyType && <PowerTypeTag value={energyType} />}
        </div>
    )
}

export default GeneratorCard
