import { FC } from 'react'
import { Spin } from 'antd'

import style from './style.m.less'

const CenteredSpin: FC = () => (
    <div className={style.spin}>
        <Spin size="large" />
    </div>
)

export default CenteredSpin
