// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper_W8mIT {\n  width: 500px;\n}\n.modalHeader_NcAMT {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.modalText_deIdn {\n  margin: 24px 0;\n}\n.checkbox_h1dWA {\n  margin-top: 16px;\n}\n.buttonsBlock_H4nD3 {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.modalAlert_s8_Vs {\n  display: flex;\n  align-items: flex-start;\n  padding: 8px 24px 8px 16px;\n}\n.modalAlertText_cpIDx {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MyProfile/components/ChangeEmailModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,cAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAHJ;AAMA;EACI,aAAA;EACA,uBAAA;EACA,0BAAA;AAJJ;AAOA;EACI,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AALJ","sourcesContent":[".modalWrapper {\n    width: 500px;\n}\n\n.modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.modalText {\n    margin: 24px 0;\n}\n\n.checkbox {\n    margin-top: 16px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.modalAlert {\n    display: flex;\n    align-items: flex-start;\n    padding: 8px 24px 8px 16px;\n}\n\n.modalAlertText {\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper_W8mIT",
	"modalHeader": "modalHeader_NcAMT",
	"modalText": "modalText_deIdn",
	"checkbox": "checkbox_h1dWA",
	"buttonsBlock": "buttonsBlock_H4nD3",
	"modalAlert": "modalAlert_s8_Vs",
	"modalAlertText": "modalAlertText_cpIDx"
};
export default ___CSS_LOADER_EXPORT___;
