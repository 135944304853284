import { RequestTypes } from '../../../hooks'

const USERS_PREFIX = '/api/users/v1/'
const AUTH_PREFIX = '/api/auth/v1/'
const BLOCK = 'block'
const RESET_PASSWORD = 'password-reset'

export const requestResetPasswordConfig = {
    link: `${AUTH_PREFIX}${RESET_PASSWORD}`,
    method: RequestTypes.get,
}

export const requestBlockConfig = {
    link: `${USERS_PREFIX}${BLOCK}`,
    method: RequestTypes.post,
}
