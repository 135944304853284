import { renderDayMonthYear } from '../../../../utils/formatter'

export const fuelInfoConfig = (data: any) => [
    {
        name: 'ЗГО',
        value: data.generator.name,
    },
    {
        name: 'Дата подачи',
        value: renderDayMonthYear(data.ts_created),
    },
]
