import { FC, useState, useEffect } from 'react'
import { Divider, Alert, InputNumber, Form, Typography, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ModalStatus, LoadingContainer, Box } from '../../..'
import { divideCertificate, dividePreview, divideAuto } from './config'
import style from './style.m.less'
import { ButtonsBlock } from '../../../../Edit/components'
import { currencyFormat } from '../../../../helpers'
import useRequest from '../../../../hooks/useRequest'
import { signBody } from '../../../../helpers'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../../store/hooks'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'
import { useGetTariffRate, useDebouncedRequest } from '../../../../hooks'

interface IAddingOptions {
    label: 'Вручную' | 'Автоматически'
    id: string
}

const addingOptions: IAddingOptions[] = [
    { label: 'Вручную', id: '8q7f77bf-a3ec-4441-9b13-e46eabc3c027' },
    { label: 'Автоматически', id: 'jr324494-8d32-423a-a7d8-b038ece4a70b' },
]

const AUTO_NOT_ALLOWED_CAPACITY_LIMIT = 2000
const MIN_AUTO_DIVIDE_PART = 1000

interface IDivideModal {
    modalStatus: ModalStatus
    setModalStatus: (value: ModalStatus) => void
    ownerId?: string
    isAdminType?: boolean
    capacity: number
    balances?: any
    certificateId: string
}

const DivideModal: FC<IDivideModal> = ({
    capacity,
    modalStatus,
    setModalStatus,
    certificateId,
}) => {
    const { isUserAdmin } = useCurrentUser()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [option, setOption] = useState<'Вручную' | 'Автоматически'>(addingOptions[0].label)
    const [count, setCount] = useState()
    const [certValue, setCertValue] = useState<number | undefined>()
    const [childArray, setChildArray] = useState([])
    const [form] = Form.useForm()
    const { fetch } = useRequest(divideCertificate)
    const { fetch: autoDivideFetch } = useRequest(divideAuto)
    const { fetch: previewFetch, result: preview } = useRequest(dividePreview)

    const isManual = option === 'Вручную'

    const autoNotAllowed = capacity < AUTO_NOT_ALLOWED_CAPACITY_LIMIT
    const isCountValid = count && count !== 0 && count > 1 && count <= 20
    const isCertValueValid = certValue && certValue >= MIN_AUTO_DIVIDE_PART && certValue <= capacity

    const [modalType] = modalStatus

    const onChange = (value: 'Вручную' | 'Автоматически') => {
        setOption(value)
    }

    const handleCancel = () => {
        setModalStatus([modalType, false])
    }

    const handleConfirm = () => {
        form.submit()
    }

    const handlerCertValue = (val: number) => {
        setCertValue(Number(val.toFixed(0)))
    }

    const handlePreview = () => {
        if (certValue && certValue >= capacity) {
            message.error(`Номинал не должен превышать ${currencyFormat(capacity)} кВт*ч`)
            return
        }

        if (certValue && isCertValueValid) {
            const body = { certificate_id: certificateId, volume: certValue }
            previewFetch({
                body,
                errorCodeMessage: true,
            })
        }
    }

    const debouncedRequest = useDebouncedRequest(() => {
        handlePreview()
    }, 500)

    const userInfo = useAppSelector((store) => store.user)
    const { data } = userInfo

    const onFinish = async (values: any) => {
        const body = { certificate_id: certificateId, volumes: Object.values(values) }
        if (isUserAdmin) {
            fetch({
                body,
                successMessage: {
                    title: 'Сертификат успешно поделен.',
                    description:
                        'Новые сертификаты доступны в разделе “Мои зеленые инструменты”. Поделенный сертификат находится в “Удаленных”',
                },
                onSuccess: () => navigate(isUserAdmin ? '/green-tools' : '/user-my-green-tools'),
                errorCodeMessage: true,
            })
        } else {
            const { request_id, signature } = await signBody(body, data)
            fetch({
                body,
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                getParams: {
                    request_id,
                },
                successMessage: {
                    title: 'Сертификат успешно поделен.',
                    description:
                        'Новые сертификаты доступны в разделе “Мои зеленые инструменты”. Поделенный сертификат находится в “Удаленных”',
                },
                onSuccess: () => navigate(isUserAdmin ? '/green-tools' : '/user-my-green-tools'),
                errorCodeMessage: true,
            })
        }
    }

    const handleAutoDivide = async () => {
        const body = { certificate_id: certificateId, volume: certValue }
        if (isUserAdmin) {
            autoDivideFetch({
                body,
                successMessage: {
                    title: 'Сертификат успешно поделен.',
                    description:
                        'Новые сертификаты доступны в разделе “Мои зеленые инструменты”. Поделенный сертификат находится в “Удаленных”',
                },
                onSuccess: () => navigate(isUserAdmin ? '/green-tools' : '/user-my-green-tools'),
                errorCodeMessage: true,
            })
        } else {
            const { request_id, signature } = await signBody(body, data)
            autoDivideFetch({
                body,
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                getParams: {
                    request_id,
                },
                successMessage: {
                    title: 'Сертификат успешно поделен.',
                    description:
                        'Новые сертификаты доступны в разделе “Мои зеленые инструменты”. Поделенный сертификат находится в “Удаленных”',
                },
                onSuccess: () => navigate(isUserAdmin ? '/green-tools' : '/user-my-green-tools'),
                errorCodeMessage: true,
            })
        }
    }

    const { loadingTariffs, balance, rate, rateNumber } = useGetTariffRate({
        dependency: modalStatus[1] && !isUserAdmin,
        id: 20,
        volume: capacity,
    })

    useEffect(() => {
        if (isCountValid) {
            const newChildArray = []
            for (let i = 1; i <= count; i++) {
                newChildArray.push(i)
            }
            setChildArray(newChildArray)
        } else {
            setChildArray([])
        }
    }, [count])

    return (
        <LoadingContainer isLoading={loadingTariffs}>
            <div className={style.modalHeader}>{t('certificateCard.split')}</div>

            <Box direction="row" gap={'16px'} margin="22px 0 0 0 ">
                {addingOptions.map(({ label, id }) => {
                    return (
                        <>
                            <div
                                className={
                                    option === label ? style.greenToolSelected : style.greenTool
                                }
                                key={id}
                                onClick={() => onChange(label)}
                            >
                                {label}
                            </div>
                        </>
                    )
                })}
            </Box>

            {isManual ? (
                <>
                    <div className={style.inputBlockWrapper}>
                        <div>{t('certificateCard.count')}</div>
                        <InputNumber
                            placeholder="Введите количество"
                            className={style.inputBlock}
                            controls={false}
                            type="number"
                            style={count > 20 || count < 2 ? { borderColor: '#ff4d4f' } : undefined}
                            onChange={(value) => setCount(value)}
                        />
                        <div
                            className={style.prompt}
                            style={count > 20 || count < 2 ? { color: '#ff4d4f' } : undefined}
                        >
                            Не менее 2х и не более 20
                        </div>
                    </div>
                    <Divider />
                    {isCountValid ? (
                        <>
                            <div className={style.ratingHeader}>
                                {t('certificateCard.chooseNominal')}
                            </div>
                            <Alert
                                message={
                                    <div>
                                        Сумма дочерних сертификатов должна составлять <br />
                                        <strong>{`${currencyFormat(capacity)} кВт*ч`}</strong>
                                    </div>
                                }
                                className={style.alert}
                                type="info"
                                showIcon
                            />
                            <Form form={form} name="childForm" onFinish={onFinish}>
                                {childArray.map((item: number) => (
                                    <div
                                        className={
                                            item === 1
                                                ? style.certificateBlockFirst
                                                : style.certificateBlock
                                        }
                                        key={item}
                                    >
                                        <div className={style.certificateName}>
                                            {item} {t('certificateCard.certificate')}
                                        </div>
                                        <Form.Item
                                            name={`child-${item}`}
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (value > capacity) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Значение сертификата не должно превышать сумму',
                                                                ),
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                }),
                                                () => ({
                                                    validator(_, value) {
                                                        if (value < 1) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Значение должно быть равным или больше 1',
                                                                ),
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                }),
                                                () => ({
                                                    validator(_, value) {
                                                        if (
                                                            value &&
                                                            (value.toString().includes(',') ||
                                                                value.toString().includes('.'))
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Доступен ввод только целых чисел',
                                                                ),
                                                            )
                                                        }
                                                        return Promise.resolve()
                                                    },
                                                }),
                                            ]}
                                        >
                                            <InputNumber
                                                type="number"
                                                placeholder="Введите объем"
                                                precision={0}
                                                controls={false}
                                                className={style.certificateInput}
                                            />
                                        </Form.Item>
                                    </div>
                                ))}
                            </Form>
                            {!isUserAdmin && rateNumber > 0 && (
                                <Alert
                                    className={style.modalAlert}
                                    message={
                                        <div className={style.modalAlertText}>
                                            <div>
                                                За операцию взымается оплата в размере{' '}
                                                <Typography.Text strong>{rate}</Typography.Text>
                                            </div>
                                            <div>Баланс платежного счета: {balance}</div>
                                        </div>
                                    }
                                    type="warning"
                                    showIcon
                                />
                            )}
                            <Divider />
                        </>
                    ) : null}
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            disableCondition={!isCountValid}
                            confirmAction={handleConfirm}
                            cancelAction={handleCancel}
                            confirmTitle={isUserAdmin ? 'Поделить' : t('certificateCard.confirm')}
                        />
                    </div>
                </>
            ) : (
                <>
                    {autoNotAllowed ? (
                        <Alert
                            message={
                                <div>
                                    {`Невозможно произвести автоматическое деление сертификата т.к.
                                    его объем меньше ${AUTO_NOT_ALLOWED_CAPACITY_LIMIT} кВт*ч`}
                                </div>
                            }
                            className={style.alertError}
                            type="error"
                            showIcon
                        />
                    ) : (
                        <>
                            <Alert
                                message={
                                    <div>
                                        Сертификаты будут выпущены одним номиналом. Остаток от
                                        деления будет записан на последний сертификат, номинал
                                        которого будет отличаться
                                    </div>
                                }
                                className={style.alert}
                                type="info"
                                showIcon
                            />
                            <div className={style.inputBlockWrapper}>
                                <div>Номинал сертификатов (кВт*ч)</div>
                                <InputNumber
                                    placeholder="Введите номинал"
                                    className={style.inputBlock}
                                    controls={false}
                                    type="number"
                                    min={1}
                                    precision={0}
                                    value={certValue}
                                    style={
                                        certValue < MIN_AUTO_DIVIDE_PART
                                            ? { borderColor: '#ff4d4f' }
                                            : undefined
                                    }
                                    onChange={(value) => {
                                        handlerCertValue(value)
                                        debouncedRequest()
                                    }}
                                />
                                <div
                                    className={style.prompt}
                                    style={
                                        certValue < MIN_AUTO_DIVIDE_PART
                                            ? { color: '#ff4d4f' }
                                            : undefined
                                    }
                                >
                                    {`Не менее ${MIN_AUTO_DIVIDE_PART} кВт*ч`}
                                </div>
                            </div>
                            <Box direction="row" margin="22px 0 0 0">
                                <div className={style.leftPart}>Будет выпущено СП</div>
                                <div>{preview ? preview?.number_of_certificates : '-'}</div>
                            </Box>
                            <Box direction="row" margin="8px 0 0 0">
                                <div className={style.leftPart}>Номинал последнего СП</div>
                                <div>
                                    {preview ? `${preview?.last_certificate_value} кВт*ч` : '-'}
                                </div>
                            </Box>
                            {!isUserAdmin && (
                                <Alert
                                    className={style.modalAlert}
                                    message={
                                        <div className={style.modalAlertText}>
                                            <div>
                                                За операцию взымается оплата в размере
                                                <Typography.Text
                                                    strong
                                                >{` ${rate}`}</Typography.Text>
                                            </div>
                                            <div>Баланс платежного счета: {balance}</div>
                                        </div>
                                    }
                                    type="warning"
                                    showIcon
                                />
                            )}
                        </>
                    )}
                    <Divider />
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            disableCondition={!isCertValueValid || !preview}
                            confirmAction={handleAutoDivide}
                            cancelAction={handleCancel}
                            confirmTitle={isUserAdmin ? 'Поделить' : t('certificateCard.confirm')}
                        />
                    </div>
                </>
            )}
        </LoadingContainer>
    )
}

export default DivideModal
