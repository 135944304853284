import { CSSProperties, FC, ReactNode } from 'react'
import { Modal as AntdModal } from 'antd'

interface IActivateGeneratorModal {
    isModalVisible: boolean
    className?: string
    onCancel?(): void
    closable?: boolean
    bodyStyle?: CSSProperties
    footer?: ReactNode
    boxShadow?: boolean
}

const Modal: FC<IActivateGeneratorModal> = ({
    isModalVisible,
    children,
    onCancel,
    className,
    closable = false,
    bodyStyle = {},
    footer = null,
    boxShadow = true,
}) => {
    return (
        <AntdModal
            onCancel={onCancel}
            bodyStyle={{
                ...(boxShadow && { boxShadow: '0px 4px 18px rgba(10, 19, 52, 0.15)' }),
                borderRadius: '24px',
                padding: '32px',
                ...bodyStyle,
            }}
            closable={closable}
            destroyOnClose
            visible={isModalVisible}
            footer={footer}
            centered
            className={className}
        >
            {children}
        </AntdModal>
    )
}

export default Modal
