// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ownerCardMain_I5D8P {\n  display: flex;\n  flex-direction: column;\n  width: 1300px;\n  padding: 40px 10px 100px;\n  margin: 0 auto;\n}\n.infoFrame_LYHZL {\n  margin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/OwnerCard/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,wBAAA;EACA,cAAA;AACJ;AAEA;EACI,gBAAA;AAAJ","sourcesContent":[".ownerCardMain {\n    display: flex;\n    flex-direction: column;\n    width: 1300px;\n    padding: 40px 10px 100px;\n    margin: 0 auto;\n}\n\n.infoFrame {\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ownerCardMain": "ownerCardMain_I5D8P",
	"infoFrame": "infoFrame_LYHZL"
};
export default ___CSS_LOADER_EXPORT___;
