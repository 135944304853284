import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Import } from '../../../../assets/icons'
import {
    HeaderBlock,
    TimeRangePicker,
    DropdownSelector,
    ImportButton,
    OperationsMapper,
    Pagination,
} from '../../../ui'
import { historyConfig } from './configurations'
import { TagFilter, OperationDetailsModal } from '../../components/'
import style from './style.m.less'

const types = [
    { value: 'Все', id: 'f245c913-a16a-4bb8-93a6-081a89cc036a' },
    { value: 'Не все', id: 'faw12c6a-2840-470c-922a-289fca5017ad' },
]

const GOPaymentOperations: FC = () => {
    const { t } = useTranslation()

    const [isOperationDetailsModalVisible, setIsOperationDetailsModalVisible] = useState(false)

    const openOperationDetailsModal = () => {
        setIsOperationDetailsModalVisible(true)
    }

    return (
        <div className={style.editMain}>
            <HeaderBlock label={t('myProfile.historyOperations')} />
            <div className={style.editFrame}>
                <div className={style.controlsBlock}>
                    <div className={style.controlsBlock}>
                        <div>
                            <div>{t('myProfile.operationType')}</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector
                                    customStyle={{ width: '256px' }}
                                    options={types}
                                />
                            </div>
                        </div>
                        <div className={style.dateBlock}>
                            <div>{t('myProfile.operationDate')}</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker />
                            </div>
                        </div>
                        <TagFilter />
                    </div>
                    <ImportButton
                        value={t('myProfile.upload')}
                        icon={<Import />}
                        customIconStyle={{ marginTop: '3px' }}
                        onClick={openOperationDetailsModal}
                    />
                </div>
                <OperationsMapper config={historyConfig} />
                <Pagination customStyle={{ padding: 0, marginTop: '32px' }} />
            </div>
            <OperationDetailsModal
                isModalVisible={isOperationDetailsModalVisible}
                setIsModalVisible={setIsOperationDetailsModalVisible}
            />
        </div>
    )
}

export default GOPaymentOperations
