import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Lock: FC<IIconProps> = ({ color = '#FF7875' }) => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4 6.66634V5.33301C4 3.12634 4.66667 1.33301 8 1.33301C11.3333 1.33301 12 3.12634 12 5.33301V6.66634"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.00065 12.3333C8.92113 12.3333 9.66732 11.5871 9.66732 10.6667C9.66732 9.74619 8.92113 9 8.00065 9C7.08018 9 6.33398 9.74619 6.33398 10.6667C6.33398 11.5871 7.08018 12.3333 8.00065 12.3333Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.334 14.667H4.66732C2.00065 14.667 1.33398 14.0003 1.33398 11.3337V10.0003C1.33398 7.33366 2.00065 6.66699 4.66732 6.66699H11.334C14.0007 6.66699 14.6673 7.33366 14.6673 10.0003V11.3337C14.6673 14.0003 14.0007 14.667 11.334 14.667Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
