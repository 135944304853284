import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, message } from 'antd'
import { Modal } from '../../../../ui'

import style from './style.m.less'
import { SuccessMessage } from '../../../../Edit/components'
import useRequest, { RequestTypes } from '../../../../hooks/useRequest'
import { useAppSelector } from '../../../../../store/hooks'
import { EUserRole } from '../../../../../constants/profile'
import { Balance } from 'src/api/audit/types'
import { ETPOffer } from 'src/api/etp/types'
import { useGetTariffRate } from '../../../../../components/hooks'
import { signBody } from '../../../../helpers'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'

interface SendToMarketModalProps {
    isVisible: boolean
    certificateNumber?: string
    certificateVolume: number
    onOk: () => void
    onCancel: () => void
}

const SendToMarketModal: FC<SendToMarketModalProps> = ({
    isVisible,
    certificateNumber,
    certificateVolume,
    onOk,
    onCancel,
}) => {
    const { user_role } = useAppSelector((state) => state.user.data)

    const { fetch: fetchBalances, result: balances } = useRequest<Balance>({
        link: '/api/billing/v1/account-balances',
        method: RequestTypes.get,
    })

    const { fetch: createOffer } = useRequest<ETPOffer>({
        link: '/api/green-tools/v1/etp/gc-offers',
        method: RequestTypes.post,
    })

    const { rate: sendingToMarketTax } = useGetTariffRate({
        id: 32,
        dependency: isVisible,
        volume: certificateVolume,
    })

    const { rate: redeemCertificateTax } = useGetTariffRate({
        id: 30,
        dependency: isVisible,
        volume: certificateVolume,
    })

    const { data, isAdmin, isOperator, isSuperAdmin } = useCurrentUser()

    const handleConfirm = async () => {
        const body = {
            cert_number: certificateNumber,
        }
        const { request_id, signature } = await signBody(body, data)
        createOffer({
            body,
            getParams: {
                request_id,
            },
            config: {
                headers: {
                    Signature: signature,
                },
            },
            onSuccess: () => {
                message.success({
                    content: <SuccessMessage header="Отправлено на ЭТП" />,
                    icon: <></>,
                })
                onOk?.()
            },
        })
    }

    useEffect(() => {
        if (isVisible && !isAdmin && !isSuperAdmin && !isOperator) {
            fetchBalances({})
        }
    }, [user_role, isVisible])

    return (
        <Modal isModalVisible={isVisible} onCancel={onCancel}>
            <div className={style.modalWrapper}>
                <div className={style.repaymentHeader}>Отправка СП на ЭТП</div>
                <div className={style.info}>
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Номер СП</div>
                        <div className={style.infoRowValue}>{certificateNumber}</div>
                    </div>
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Объем</div>
                        <div className={style.infoRowValue}>{certificateVolume} кВт*ч</div>
                    </div>
                </div>
                {balances && (
                    <Alert
                        className={style.greyAlert}
                        message={
                            <>
                                <div className={style.alertMessage}>
                                    Сумма комиссии за отправку на ЭТП составила {sendingToMarketTax}{' '}
                                </div>
                                <div className={style.alertMessage}>
                                    Сумма комиссии за погашение СП составила {redeemCertificateTax}{' '}
                                </div>
                            </>
                        }
                        description={
                            <div className={style.alertDescription}>
                                Баланс платежного счета: {balances.available_balance} ₽
                            </div>
                        }
                    />
                )}
            </div>
            <div className={style.buttonsBlock}>
                <Button onClick={onCancel} className={style.cancelButton}>
                    Отмена
                </Button>
                <Button className={style.confirmButton} onClick={handleConfirm} loading={false}>
                    Подписать и отправить
                </Button>
            </div>
        </Modal>
    )
}

export default SendToMarketModal
