import { RequestTypes } from '../hooks/useRequest'

export const GENERATOR_PREFIX = '/api/generators/v1/'
const GET_GO = 'go'
const GO_EDIT = 'go-edit'
const GO_CREATE = 'go-create'

export const getGO = {
    link: `${GENERATOR_PREFIX}${GET_GO}`,
    method: RequestTypes.get,
}

export const goEdit = {
    link: `${GENERATOR_PREFIX}${GO_EDIT}`,
    method: RequestTypes.post,
}

export const goCreate = {
    link: `${GENERATOR_PREFIX}${GO_CREATE}`,
    method: RequestTypes.post,
}
