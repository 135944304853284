import { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, PrimaryButton } from '../../../ui'
import { Form, Input, Checkbox } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { login } from '../../../../store/auth'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../../../../components/hooks/useRequest'
import { validatorConstructor } from '../../../../components/helpers/inputValidators'

interface IAuth {
    onCertificateLogin: () => void
}

const Auth: FC<IAuth> = ({ onCertificateLogin }) => {
    const { t } = useTranslation()
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()
    const [componentDisabled, setComponentDisabled] = useState<boolean>(true)

    const { fetch, isError, isLoading, dropState } = useRequest({
        link: '/api/auth/v1/login',
        method: RequestTypes.post,
    })

    useEffect(() => {
        if (isError) {
            form.validateFields(['username', 'password'])
        }
    }, [isError, form])

    const onFormLayoutChange = ({ username, password }: any) => {
        if (username) {
            form.validateFields(['username'])
        }

        if (password) {
            form.validateFields(['password'])
        }

        if (username || password) {
            setComponentDisabled(false)
            dropState()
        }
    }

    const onFinish = (values: any) => {
        const { username, password, remember } = values
        fetch({
            body: {
                login: username,
                password,
            },
            onSuccess(data) {
                reduxDispatch(login({ data, remember }))
            },
            showError: true,
        })
    }

    return (
        <>
            <div className={style.loginHeader}>{t('login.auth')}</div>
            <Form
                initialValues={{ remember: false }}
                name="loginForm"
                validateTrigger={['onSubmit']}
                form={form}
                onFinish={onFinish}
                className={style.form}
                onValuesChange={onFormLayoutChange}
            >
                <Form.Item
                    name="username"
                    rules={[
                        { required: true, message: t('login.required') },
                        {
                            pattern: new RegExp(/^[a-zA-Z0-9_]+$/),
                            message:
                                'Логин должен содержать только латинские буквы, цифры и подчеркивания',
                        },
                        validatorConstructor(isError, ''),
                    ]}
                >
                    <div>
                        <div>{t('login.login')}</div>
                        <div className={style.inputBlock}>
                            <Input maxLength={50} className={style.input} />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: t('login.required') },
                        validatorConstructor(isError, ''),
                    ]}
                >
                    <div className={style.formBlock}>
                        <div>{t('login.password')}</div>
                        <div className={style.inputBlock}>
                            <Input.Password
                                minLength={8}
                                maxLength={20}
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                            />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item>
                    <div className={style.formBlockInfo}>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>{t('login.rememberMe')}</Checkbox>
                        </Form.Item>

                        <Link to="/restore-password" className={style.link}>
                            {t('login.forgetPass')}
                        </Link>
                    </div>
                </Form.Item>

                <div className={style.buttonsBlock}>
                    <Form.Item>
                        <PrimaryButton
                            customStyle={{
                                padding: '10px 20px',
                                fontSize: '16px',
                                lineHeight: '24px',
                            }}
                            disabled={componentDisabled || isLoading}
                            type="submit"
                            value={t('login.signIn')}
                        />
                    </Form.Item>
                    <IconButton
                        customStyle={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            lineHeight: '24px',
                            marginLeft: '16px',
                            height: '44px',
                        }}
                        onClick={onCertificateLogin}
                    >
                        {t('login.signIn2')}
                    </IconButton>
                </div>
            </Form>
        </>
    )
}

export default Auth
