import { Button } from 'antd'
import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../../../store/auth'
import IAppState from '../../../../store/states'

const Form: FC = () => {
    const reduxDispatch = useDispatch()
    const authStore = useSelector((store: IAppState) => store.auth)

    const onClickHandler = () => {
        reduxDispatch(login({ login: '', password: '' }))
    }
    return (
        <div className={'testClass'}>
            <a href="/main">link</a>
            <p className={'testClass'}>{`${authStore.isAuth}`}</p>
            <Button onClick={onClickHandler}> Default Button</Button>
        </div>
    )
}

export default Form
