import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_CERTIFICATE = 'public-certificate'
export const GREEN_TOOLS_CONTRACT = 'contracts/search'
export const GREEN_TOOLS_PUBLIC_OTHER = 'ei/public-list'
export const GREEN_TOOLS_CERTIFICATES = 'certificates'
export const GREEN_TOOLS_CONSUMING = 'public-green-tools-consumption'
export const GREEN_GENERATORS_ACCOUNT = 'generators-by-account'
export const GREEN_TOOLS_CONTRACTS = 'contracts/get-list-contracts'
export const GREEN_TOOLS_OTHER = 'ei/ext-instruments'

export const getCertificate = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CERTIFICATE}`,
    method: RequestTypes.post,
}

export const getCertificates = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CERTIFICATES}`,
    method: RequestTypes.post,
}

export const getGeneratorsForAccount = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_GENERATORS_ACCOUNT}`,
    method: RequestTypes.get,
}

export const getConsuming = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CONSUMING}`,
    method: RequestTypes.post,
}

export const getGreenContracts = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CONTRACTS}`,
    method: RequestTypes.post,
}

export const getOther = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_OTHER}`,
    method: RequestTypes.post,
}

export const getContract = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CONTRACT}`,
    method: RequestTypes.post,
}

export const getOtherPublic = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_PUBLIC_OTHER}`,
    method: RequestTypes.post,
}
