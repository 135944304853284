const Kb = 1024
const Mb = 1024 * 1024
const Gb = 1024 * 1024 * 1024

export const formatSize = (size: number) => {
    return size > Gb
        ? `${Math.round(size / Gb)} Гб`
        : size > Mb
        ? `${Math.round(size / Mb)} Мб`
        : size > Kb
        ? `${Math.round(size / Kb)} Кб`
        : `${size} Б`
}
