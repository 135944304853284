import { RequestTypes } from '../../../hooks/useRequest'

export const GENERATION_ATTRIBUTES_API_PREFIX = '/api/generation_attributes/v1/gas/'
export const GENERATION_ATTRIBUTES_BLOCK = 'actions/block'
export const GENERATION_ATTRIBUTES_UNBLOCK = 'actions/unblock'
export const GENERATION_ATTRIBUTES_LIST = 'ga/list'
export const GENERATION_ATTRIBUTES_OWNERS = 'ga/ownersGO'

export const blockAGQuery = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_BLOCK}`,
    method: RequestTypes.post,
}

export const unblockAGQuery = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_UNBLOCK}`,
    method: RequestTypes.post,
}

export const getListAGQuery = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_LIST}`,
    method: RequestTypes.get,
}

export const getListAGOwners = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_OWNERS}`,
    method: RequestTypes.get,
}
