import { FC } from 'react'
import { GeneralInfo, PersonalAccount, PaymentAccount } from './components'
import { Tabs } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

const MyProfile: FC = () => {
    const { t } = useTranslation()

    const tabsList = [
        { label: t('myProfile.profile'), key: 1, content: <GeneralInfo /> },
        { label: t('myProfile.bill'), key: 2, content: <PaymentAccount /> },
        { label: t('myProfile.persAccount'), key: 3, content: <PersonalAccount /> },
    ]

    return (
        <div className={style.container}>
            <div className={style.mainHeader}>{t('myProfile.title')}</div>
            <Tabs defaultActiveKey="1" onChange={() => {}} className={style.tabs}>
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane tab={t(label)} key={key}>
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
        </div>
    )
}

export default MyProfile
