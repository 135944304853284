import { FC, useState } from 'react'
import { HeaderBlock, CloseModal, Alert } from '../../../ui'
import { useNavigate } from 'react-router-dom'
import { ButtonsBlock, CommonBlock } from '../'
import { Form, Input, message } from 'antd'
import { SuccessMessage } from '../../../Edit/components'
import { changePasswordService } from '../../../../services/auth'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { errorHandler } from '../../../helpers'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const list = [
    {
        title: 'login.oneNumber',
    },
    {
        title: 'login.oneUpperChar',
    },
    {
        title: 'login.oneLowerChar',
    },
    {
        title: 'login.eightChars',
    },
]

const Password: FC = () => {
    const { t } = useTranslation()

    const [responseError, setResponseError] = useState('')
    const [isCloseModalVisible, setIsCloseModalVisible] = useState(false)
    const [isFormChanged, setIsFormChanged] = useState(false)

    const navigate = useNavigate()
    const [form] = Form.useForm()

    const getBackToProfile = () => {
        navigate('/profile')
    }

    const onFormLayoutChange = () => {
        setIsFormChanged(true)
    }

    const openCloseModal = () => {
        if (isFormChanged) {
            setIsCloseModalVisible(true)
        } else {
            getBackToProfile()
        }
    }

    const onSubmit = () => {
        form.submit()
    }

    const onFinish = async (values: any) => {
        const { newPassword, oldPassword } = values
        if (newPassword !== oldPassword) {
            await changePasswordService(
                oldPassword,
                newPassword,
                () => {
                    navigate('/profile')
                    message.success({
                        content: <SuccessMessage header={t('login.passwordChangeSuccess')} />,
                        icon: <></>,
                    })
                },
                (error) => {
                    setResponseError(errorHandler(error.err_code))
                },
            )
        }
    }

    return (
        <div className={style.editMain}>
            <div className={style.container}>
                <HeaderBlock label={t('edit.changePass')} />
                <Form
                    form={form}
                    onValuesChange={onFormLayoutChange}
                    onFinish={onFinish}
                    name="changePasswordForm"
                >
                    <div className={style.editFrame}>
                        <CommonBlock
                            required
                            label={t('edit.oldPass')}
                            select={
                                <Form.Item
                                    name="oldPassword"
                                    rules={[
                                        { required: true, message: t('login.checkPass') },
                                        {
                                            pattern: new RegExp(
                                                /^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё])(?=.*\d).*$/,
                                            ),
                                            message: t('login.correctPass'),
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                        className={style.input}
                                        minLength={8}
                                        maxLength={20}
                                    />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            required
                            margin
                            label={t('edit.newPass')}
                            select={
                                <Form.Item
                                    name="newPassword"
                                    dependencies={['oldPassword']}
                                    rules={[
                                        { required: true, message: t('login.checkPass') },
                                        {
                                            pattern: new RegExp(
                                                /^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё])(?=.*\d).*$/,
                                            ),
                                            message: t('login.correctPass'),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue('oldPassword') !== value
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'Новый пароль не должен совпадать с текущим!',
                                                    ),
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                        className={style.input}
                                        minLength={8}
                                        maxLength={20}
                                    />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            required
                            margin
                            label={t('edit.repeatNewPass')}
                            select={
                                <Form.Item
                                    name="repeatPassword"
                                    dependencies={['newPassword']}
                                    rules={[
                                        { required: true, message: t('login.repeatPass') },
                                        {
                                            pattern: new RegExp(
                                                /^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё])(?=.*\d).*$/,
                                            ),
                                            message: t('login.correctPass'),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue('newPassword') === value
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    new Error(t('login.passwordsNotMatch')),
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                        className={style.input}
                                        minLength={8}
                                        maxLength={20}
                                    />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            margin
                            label=""
                            select={
                                <Alert
                                    className={style.alert}
                                    message={
                                        <div className={style.listWrapper}>
                                            <div>{t('login.mustNotMatch')}</div>
                                            <div>{t('login.passMustHave')}:</div>
                                            {list?.map((item, index) => {
                                                return (
                                                    <div
                                                        key={item.title}
                                                        className={
                                                            index === 0
                                                                ? style.listItemFirst
                                                                : style.listItem
                                                        }
                                                    >
                                                        <div className={style.bullet}>•</div>
                                                        <div className={style.itemTitle}>
                                                            {t(item.title)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                    type="info"
                                    showIcon
                                />
                            }
                        />

                        {responseError && (
                            <Alert
                                className={style.responseError}
                                message={responseError}
                                type="error"
                                showIcon
                            />
                        )}
                        <ButtonsBlock
                            margin
                            confirmAction={onSubmit}
                            cancelAction={openCloseModal}
                        />
                    </div>
                </Form>
                <CloseModal
                    isModalVisible={isCloseModalVisible}
                    setIsModalVisible={setIsCloseModalVisible}
                    headerText={'Отменить изменения?'}
                    messageText={'Новый пароль не будет сохранен'}
                    confirmTitle={'Да'}
                    cancelTitle={'Нет'}
                    confirmAction={getBackToProfile}
                />
            </div>
        </div>
    )
}

export default Password
