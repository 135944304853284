// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_kx2gR {\n  display: flex;\n  justify-content: start;\n  width: calc(100% + 16px);\n  margin-top: 24px;\n  margin-left: -8px;\n  margin-right: -8px;\n}\n.moreFilterItem_vEbVY {\n  width: 20%;\n  padding: 0 8px;\n}\n.label_pn5HW {\n  margin-bottom: 2px;\n}\n.input_u7ZY_ {\n  border-radius: 8px;\n}\n.select_Wlls3 {\n  width: 100%;\n}\n.moreFilter_z8zGg {\n  display: flex;\n  gap: 4px;\n  margin-top: 12px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: #296DFF;\n  cursor: pointer;\n}\n.icon_HpAOV {\n  font-size: 10px;\n  margin-top: 7px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Audit/components/MoreFilters/style.m.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EAEA,sBAAA;EACA,wBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AAFJ;AAKA;EACI,UAAA;EACA,cAAA;AAHJ;AAMA;EACI,kBAAA;AAJJ;AAOA;EACI,kBAAA;AALJ;AAQA;EACI,WAAA;AANJ;AASA;EACI,aAAA;EACA,QAAA;EACA,gBAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAPJ;AAUA;EACI,eAAA;EACA,eAAA;AARJ","sourcesContent":["@gap: 16px;\n\n.filter {\n    display: flex;\n    // gap: @gap;\n    justify-content: start;\n    width: calc(100% + @gap);\n    margin-top: 24px;\n    margin-left: -(@gap/2);\n    margin-right: -(@gap/2);\n}\n\n.moreFilterItem {\n    width: 20%;\n    padding: 0 (@gap/2)\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.select {\n    width: 100%;\n}\n\n.moreFilter {\n    display: flex;\n    gap: 4px;\n    margin-top: 12px;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    color: #296DFF;\n    cursor: pointer;\n}\n\n.icon {\n    font-size: 10px;\n    margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "filter_kx2gR",
	"moreFilterItem": "moreFilterItem_vEbVY",
	"label": "label_pn5HW",
	"input": "input_u7ZY_",
	"select": "select_Wlls3",
	"moreFilter": "moreFilter_z8zGg",
	"icon": "icon_HpAOV"
};
export default ___CSS_LOADER_EXPORT___;
