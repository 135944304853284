import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../../ui'

import style from './style.m.less'

const TagFilter: FC = () => {
    const { t } = useTranslation()

    const [eventsSelected, setEventSelected] = useState(true)
    const [newsSelected, setNewsSelected] = useState(true)

    return (
        <div className={style.tagFilter}>
            <Tag
                className={style.clickable}
                color={eventsSelected ? 'green' : undefined}
                onClick={() => setEventSelected((s) => !s)}
            >
                {t('myProfile.replenishment')}
            </Tag>
            <Tag
                className={style.clickable}
                color={newsSelected ? 'blue' : undefined}
                onClick={() => setNewsSelected((s) => !s)}
            >
                {t('myProfile.withdrawals')}
            </Tag>
        </div>
    )
}

export default TagFilter
