export const legalPersonInfoConfig = {
    visibleInfo: [
        {
            name: 'Роль в системе',
            value: 'ТСО',
        },
        {
            name: 'Тип пользователя',
            value: 'Юридическое лицо',
        },
        {
            name: 'ФИО',
            value: 'Макаров Михаил Алексеевич',
        },
        {
            name: 'Логин',
            value: 'MakaRoff575',
        },
        {
            name: 'E-mail',
            value: 'makarovv@powerservices.ru',
        },
    ],
    hiddenInfo: [
        {
            name: 'Полное название организации',
            value: 'Открытое акционерное общество Лето',
            noMargin: true,
        },
        {
            name: 'Сокращенное название организации',
            value: 'ОАО Лето',
        },
        {
            name: 'ИНН',
            value: '493804883',
        },
        {
            name: 'КПП',
            value: '88673514104',
        },
        {
            name: 'ОГРН',
            value: '0074001772434',
        },
        {
            name: 'Наименование гос. органа, осуществившего регистрацию в ЕГРЮЛ',
            value: 'Инспекция Федеральной налоговой службы по г. Санкт-Петербургу',
        },
        {
            name: 'Дата регистрации в ЕГРЮЛ',
            value: '20.05.2020',
        },
        {
            name: 'Должность',
            value: 'Директор',
        },
        {
            name: 'Телефон / факс',
            value: '+ 7 999 764 55 55',
        },
        {
            name: 'Сайт',
            value: 'www.letooo.ru',
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'Юридический адрес',
            value: '648201, г. Санкт-Петербург, Василеостровский район, Малый проспект, д.28, корпус 1',
            noMargin: true,
        },
        {
            name: 'Почтовый адрес',
            value: '648201, г. Санкт-Петербург, Василеостровский район, Малый проспект, д.28, корпус 1',
        },
    ],
}
