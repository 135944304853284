import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { Export } from '../../../../assets/icons'
import { CenteredSpin, IconButton, Alert } from '../../../ui'
import { DocumentsList } from '../'
import { IFile } from '../DocumentsList/DocumentsList'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import request from '../../../../services/request'
import SignUtils from '../../../../utils/sign/SignUtils'

export const MAX_FILES_SIZE = 52428800

interface IDocumentsBlock {
    customStyle?: React.CSSProperties
    value?: IFile[]
    onChange?(files: IFile[]): void
    description?: string
    required?: boolean
}

const DocumentsBlock: FC<IDocumentsBlock> = ({
    customStyle,
    value: files = [],
    description,
    onChange,
    required,
}) => {
    const { t } = useTranslation()
    const fileInput = useRef<HTMLInputElement>(null)
    const [fileUploading, setFileUploading] = useState(false)
    const [showError, setShowError] = useState(false)

    const timeout = useRef<any>()
    useEffect(() => {
        return () => clearTimeout(timeout.current)
    }, [])

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const totalSelectedFilesSize =
                files.reduce((sizeSum, item) => sizeSum + item.size, 0) + file.size
            if (totalSelectedFilesSize > MAX_FILES_SIZE) {
                setShowError(true)
                timeout.current = setTimeout(() => {
                    setShowError(false)
                }, 5000)
                return
            }

            setFileUploading(true)

            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = async () => {
                try {
                    const { data, status } = await request.post(
                        '/api/documents/v1/file',
                        fileReader.result,
                        {
                            headers: {
                                Filename: encodeURI(file.name),
                                'Service-ID': 4,
                            },
                        },
                    )

                    if (status === 200) {
                        const hash = await SignUtils.hashBytes(fileReader.result)
                        onChange?.([
                            ...files,
                            {
                                name: file.name,
                                size: file.size,
                                id: data.id,
                                type: file.name.split('.').pop(),
                                description: '',
                                hash: hash,
                            },
                        ])
                    }
                } catch (err) {
                    console.log('Error: ', err)
                } finally {
                    setFileUploading(false)
                }
            }
            fileReader.onerror = () => {
                setFileUploading(false)
            }
        }
    }

    const handleClick = () => {
        fileInput.current?.click()
    }

    const inputFileKey = useMemo(
        () => files?.map((f) => f.hash).join('') + showError,
        [files, showError],
    )

    return (
        <>
            <div style={customStyle}>
                {fileUploading && <CenteredSpin />}
                <div className={style.documentsListWrapper}>
                    <DocumentsList
                        files={files}
                        onRemove={(file) => {
                            const newSelectedFiles = files.filter((f) => f.id !== file.id)
                            onChange?.(newSelectedFiles)
                        }}
                        onChange={(file) => {
                            const fileIndex = files.findIndex((f) => f.id === file.id)
                            onChange?.([
                                ...files.slice(0, fileIndex),
                                file,
                                ...files.slice(fileIndex + 1),
                            ])
                        }}
                    />
                </div>
                <div className={style.uploadDocumentFrame}>
                    <input
                        type="file"
                        className={style.file}
                        onChange={handleChange}
                        ref={fileInput}
                        key={inputFileKey}
                    />
                    <IconButton
                        icon={<Export />}
                        customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                        onClick={handleClick}
                    >
                        {t('documentsBlock.downloadDoc')}
                    </IconButton>
                    <div className={style.info}>
                        {description
                            ? description
                            : 'Прикрепите необходимые документы (общий объем файлов не должен превышать 50 Мб)'}
                        {required && <span className={style.star}>*</span>}
                    </div>
                </div>
            </div>

            {showError && (
                <Alert
                    className={style.documentsAlert}
                    message={
                        'Суммарный объем прикрепленных файлов не должен превышать размер 50 Мб.'
                    }
                    type="error"
                    showIcon
                />
            )}
        </>
    )
}

export default DocumentsBlock
