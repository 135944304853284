import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Blocks: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M16.2917 6.46217V3.15176C16.2917 2.12363 15.825 1.70801 14.6656 1.70801H11.7198C10.5604 1.70801 10.0938 2.12363 10.0938 3.15176V6.45488C10.0938 7.4903 10.5604 7.89863 11.7198 7.89863H14.6656C15.825 7.90592 16.2917 7.4903 16.2917 6.46217Z"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.2917 14.6656V11.7198C16.2917 10.5604 15.825 10.0938 14.6656 10.0938H11.7198C10.5604 10.0938 10.0938 10.5604 10.0938 11.7198V14.6656C10.0938 15.825 10.5604 16.2917 11.7198 16.2917H14.6656C15.825 16.2917 16.2917 15.825 16.2917 14.6656Z"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.90495 6.46217V3.15176C7.90495 2.12363 7.43828 1.70801 6.27891 1.70801H3.33307C2.1737 1.70801 1.70703 2.12363 1.70703 3.15176V6.45488C1.70703 7.4903 2.1737 7.89863 3.33307 7.89863H6.27891C7.43828 7.90592 7.90495 7.4903 7.90495 6.46217Z"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.90495 14.6656V11.7198C7.90495 10.5604 7.43828 10.0938 6.27891 10.0938H3.33307C2.1737 10.0938 1.70703 10.5604 1.70703 11.7198V14.6656C1.70703 15.825 2.1737 16.2917 3.33307 16.2917H6.27891C7.43828 16.2917 7.90495 15.825 7.90495 14.6656Z"
                    stroke="#296DFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
