import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Import: FC<IIconProps> = ({ size = 16 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.21289 7.78662L7.91956 9.49329L9.62622 7.78662"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.91992 2.6665V9.4465"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3327 8.12012C13.3327 11.0668 11.3327 13.4535 7.99935 13.4535C4.66602 13.4535 2.66602 11.0668 2.66602 8.12012"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
