import { FC } from 'react'
import { Modal } from '..'
import { ButtonsBlock } from '../../Edit/components'

import style from './style.m.less'
import { QuestionCircle } from '../../../assets/icons'

interface ICloseEditModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    confirmAction: () => void
    headerText: string
    messageText: string
    confirmTitle: string
    cancelTitle: string
}

const CloseEditModal: FC<ICloseEditModal> = ({
    isModalVisible,
    setIsModalVisible,
    confirmAction,
    headerText,
    messageText,
    confirmTitle,
    cancelTitle,
}) => {
    const handleCancel = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <QuestionCircle />
                    <div className={style.header}>{headerText}</div>
                </div>
            </div>
            <div className={style.text}>{messageText}</div>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={confirmAction}
                    cancelAction={handleCancel}
                    confirmTitle={confirmTitle}
                    cancelTitle={cancelTitle}
                />
            </div>
        </Modal>
    )
}

export default CloseEditModal
