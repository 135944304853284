// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_nlvdM {\n  width: 436px;\n}\n.activationAlert_eHw9y {\n  margin-top: 24px;\n  border-radius: 12px;\n  border: 1px solid #fff1b8;\n  color: #555861;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/CertificateCard/components/CertificateCardModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;AAAJ","sourcesContent":[".main {\n    width: 436px;\n}\n\n.activationAlert {\n    margin-top: 24px;\n    border-radius: 12px;\n    border: 1px solid #fff1b8;\n    color: #555861;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "main_nlvdM",
	"activationAlert": "activationAlert_eHw9y"
};
export default ___CSS_LOADER_EXPORT___;
