import { FC } from 'react'
import { Bar } from 'react-chartjs-2/dist'
import { roundUp } from '../../utils'

const data = (graphData, toolType) => {
    const labels = []
    const certSet = [
        {
            label: 'Сертификаты происхождения',
            data: [],
            backgroundColor: '#52D2A0',
            barThickness: 56,
        },
    ]
    const othersSet = [
        {
            label: 'Сторонние инструменты',
            data: [],
            backgroundColor: '#F4FFB8',
            barThickness: 56,
        },
        {
            label: 'Зеленые договоры',
            data: [],
            backgroundColor: '#EFDBFF',
            barThickness: 56,
        },
    ]
    const datasets = toolType === 'Истек срок действия' ? [...certSet] : [...othersSet, ...certSet]
    graphData.forEach((item) => {
        labels.push(item.year)
        if (toolType === 'Выпущенные') {
            datasets[0].data.push(Math.round(item.extInstrumentsTotalVolume / 1000))
            datasets[1].data.push(Math.round(item.contractTotalVolume / 1000))
            datasets[2].data.push(Math.round(item.certificatesIssueVolume / 1000))
        } else if (toolType === 'Погашенные') {
            datasets[0].data.push(Math.round(item.extInstrumentsUsedVolume / 1000))
            datasets[1].data.push(Math.round(item.contractUsedVolume / 1000))
            datasets[2].data.push(Math.round(item.certificatesUsedVolume / 1000))
        } else if (toolType === 'Истек срок действия') {
            datasets[0].data.push(Math.round(item.certificatesExpiredVolume / 1000))
        }
    })
    return {
        labels,
        datasets,
    }
}

const options = (graphData, toolType) => {
    const values = []
    graphData.forEach((item) => {
        if (toolType === 'Выпущенные') {
            values.push(item.extInstrumentsTotalVolume)
            values.push(item.contractTotalVolume)
            values.push(item.certificatesIssueVolume)
        } else if (toolType === 'Погашенные') {
            values.push(item.extInstrumentsUsedVolume)
            values.push(item.contractUsedVolume)
            values.push(item.certificatesUsedVolume)
        } else if (toolType === 'Истек срок действия') {
            values.push(item.certificatesExpiredVolume)
        }
    })
    const preparedValues = values?.map((item) => Math.round(item / 1000)) || []

    const maxValue = preparedValues.length ? Math.max(...preparedValues) : 100000
    const cleanMaxValue = roundUp(maxValue, 1000)
    const stepSize = Math.round(cleanMaxValue / 5)
    const max = cleanMaxValue + stepSize
    return {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 15,
                    padding: 24,
                },
                reverse: true,
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#8C8C8C',
                    font: {
                        size: 14,
                        weight: '400',
                    },
                },
            },
            y: {
                stacked: true,
                position: 'right',
                grid: {
                    color: '#E6F1FF',
                },
                min: 0,
                max,
                ticks: {
                    color: '#8C8C8C',
                    font: {
                        size: 14,
                        weight: '400',
                    },
                    stepSize,
                },
            },
        },
    }
}

interface IStackedBarChart {
    toolType: string
    graphData: any
}

const StackedBarChart: FC<IStackedBarChart> = ({ toolType, graphData }) => {
    return graphData ? (
        <Bar data={data(graphData, toolType)} options={options(graphData, toolType)} />
    ) : (
        <></>
    )
}

export default StackedBarChart
