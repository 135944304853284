import { useNavigate, useParams } from 'react-router-dom'
import PageContainer from '../../ui/PageElements/PageContainer/PageContainer'
import BackLink from '../../ui/BackTitle/BackTitle'
import Card from '../../ui/Card/Card'
import { Alert, Divider, Form, Input } from 'antd'
import { DocumentsBlock, PrimaryButton } from '../../ui'
import { Box } from '../../ui/Box'
import { useEffect, useState } from 'react'
import AddNotification from './AddNotification'
import useRequest from '../../hooks/useRequest'
import { approveAppeal } from '../config'
import useMount from '../../hooks/useMount'
import { useForm } from 'antd/es/form/Form'

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

export const CompleteAppeal = () => {
    const { id } = useParams<{ id: string }>()
    const { fetch, isSuccess, isLoading } = useRequest(approveAppeal)
    const [form] = useForm()

    const navigate = useNavigate()
    const [comment, setComment] = useState<string>('')
    const [files, setFiles] = useState<any[]>([])
    const [notifications, setNotifications] = useState<any[]>([])

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    useMount(scrollToTop)

    const notificatioLength = () => {
        let isFalse = false
        for (let i = 0; i < notifications.length; i++) {
            if (notifications[i].message.length > 500) {
                isFalse = true
            }
        }
        return isFalse
    }

    const sendApprove = (values: any) => {
        if (notificatioLength()) {
            return
        }
        fetch({
            body: {
                approve_type: 2,
                message: values.message,
                documents: files,
                notify: notifications
                    ?.filter((el: any) => el.uid.length > 0)
                    .map((el: any) => ({ uid: el.uid, message: el.message })),
            },
            getParams: {
                id,
            },
        })
    }

    useEffect(() => {
        if (!isSuccess) {
            return
        }
        navigate(`/appeal/${id}`)
    }, [isSuccess])
    return (
        <PageContainer>
            <>
                <BackLink title={`Выполнить заявку №${id}`} link={`/appeal/${id}`} />
                <Card>
                    <Form
                        onFinish={sendApprove}
                        form={form}
                        name="completeAppeal"
                        layout="vertical"
                    >
                        <Form.Item
                            name="message"
                            label="Комментарий"
                            rules={[
                                {
                                    required: true,
                                    message: 'Не указан текст комментария',
                                },
                                {
                                    max: 500,
                                    message: 'Введено больше 500 символов',
                                },
                            ]}
                        >
                            <Input.TextArea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                style={{ minHeight: 95, maxWidth: 600 }}
                            />
                        </Form.Item>
                    </Form>
                    <Box flex={'row'} width={600} margin={'20px 0px'}>
                        <DocumentsBlock
                            value={files}
                            onChange={fileHandler}
                            hideComment
                            serviceId={5}
                        />
                    </Box>
                    <Alert
                        message="Указанный комментарий и прикрепленные документы увидит пользователь"
                        type="warning"
                        showIcon
                        style={{ width: 600 }}
                    />
                    <Divider />
                    <AddNotification setNotifications={setNotifications} />
                    <Divider />
                    <Box direction="row" justify="flex-start" margin="20px 0px 0px 0px">
                        <PrimaryButton
                            onClick={() => navigate(`/appeal/${id}`)}
                            value={'Отмена'}
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            onClick={() => form.submit()}
                            value={'Выполнить'}
                            disabled={isLoading}
                            customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                        />
                    </Box>
                </Card>
            </>
        </PageContainer>
    )
}
