import { Box } from '../../ui/Box/Box'
import { DeleteModal, IconButton, PrimaryButton, SoftModal } from '../../ui'
import { Divider, Input, Spin, Typography } from 'antd'
import { PaperClipOutlined, SendOutlined } from '@ant-design/icons'
import style from '../style.m.less'
import useUploadFile from '../../hooks/useUploadFile'
import { useEffect, useRef, useState } from 'react'
import useRequest from '../../hooks/useRequest'
import { sendMessage, confirmPayment } from '../config'
import Document from '../../ui/Document/Document'
import { useNavigate } from 'react-router-dom'
import { getAppealBalance } from '../config'
import useMount from '../../hooks/useMount'
import { Warning } from '../../../assets/icons'
import { currencyFormatRu } from '../../helpers/format'

type Props = {
    id: string
    getAppeal: any
    isPayWait: number
    payment: any
}

const AppealMessage = ({ id, getAppeal, isPayWait, payment }: Props) => {
    const navigate = useNavigate()

    const { uploadFile, uploading, files } = useUploadFile(5)
    const { fetch, isSuccess, isLoading } = useRequest(sendMessage)
    const { fetch: sendPaymentFetch, dropState } = useRequest(confirmPayment)
    const { fetch: fetchBalance, result } = useRequest(getAppealBalance)
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [lowAmountModalVisible, setLowAmountModalVisible] = useState<boolean>(false)
    const [payModalVisible, setPayModalVisible] = useState<boolean>(false)

    const [message, setMessage] = useState<string>('')
    const fileInput = useRef<HTMLInputElement>(null)

    // getBalances
    const handleClick = () => {
        fileInput.current?.click()
    }
    const send = () => {
        if (!message.length) {
            return
        }
        fetch({
            body: {
                message,
                documents: files,
            },
            getParams: {
                id,
            },
        })
    }

    const deleteModalHandler = () => {
        setDeleteModalVisible(!deleteModalVisible)
    }

    const lowAmountModalHandler = () => {
        setLowAmountModalVisible(!lowAmountModalVisible)
    }

    const payModalHandler = () => {
        setPayModalVisible(!payModalVisible)
    }

    const paymentHandle = (action_type_id: number) => {
        sendPaymentFetch({
            getParams: {
                id,
            },
            body: {
                action_type_id,
            },
            onSuccess: () => {
                dropState()
                payModalHandler()
                getAppeal()
                fetchBalance({ getParams: { id } })
            },
        })
    }

    useEffect(() => {
        if (!isSuccess) {
            return
        }

        getAppeal()
    }, [isSuccess])

    useMount(() => fetchBalance({ getParams: { id } }))

    return (
        <>
            {isPayWait === 1 ? (
                <>
                    <Divider />
                    <DeleteModal
                        isModalVisible={deleteModalVisible}
                        onCancel={deleteModalHandler}
                        onDelete={() => paymentHandle(2)}
                        deleteTitle="Отказаться от оплаты"
                        headerText="Отказ от оплаты по заявке"
                        messageText={`Вы действительно хотите отказаться от оплаты по заявке №${id}? Заявка будет закрыта.`}
                    />
                    <DeleteModal
                        isModalVisible={lowAmountModalVisible}
                        onCancel={lowAmountModalHandler}
                        onDelete={() => navigate('/profile')}
                        isDelete={false}
                        icon={<Warning />}
                        cancelTitle="Закрыть"
                        deleteTitle="Перейти в платежный счет"
                        headerText="Недостаточно средств на счете"
                        messageText="На платежном счете недостаточно средств. Пожалуйста, пополните баланс и попробуйте еще раз."
                    />
                    <SoftModal
                        isModalVisible={payModalVisible}
                        onCancel={payModalHandler}
                        onOk={() => paymentHandle(1)}
                        buttonTitle="Оплатить"
                        bodyStyle={{ width: 500 }}
                        headerText="Оплата по заявке"
                    >
                        <Box>
                            <Typography.Text>
                                {`Вы действительно хотите внести оплату по заявке № ${id}? Денежные средства будут списаны с Вашего платежного счета.`}
                            </Typography.Text>
                            <Box
                                className={style.greyBox}
                                padding="12px 24px"
                                justify="space-between"
                                margin="16px 0px"
                            >
                                <Typography.Text strong>
                                    {`Сумма к оплате составила  ${currencyFormatRu(
                                        payment.amount,
                                    )}`}
                                </Typography.Text>
                                <Typography>
                                    <Typography.Text type="secondary">
                                        Баланс платежного счета
                                    </Typography.Text>
                                    <Typography.Text type="secondary">{`: ${Number(
                                        result?.available_balance || 0,
                                    ).toLocaleString('ru')} ₽`}</Typography.Text>
                                </Typography>
                            </Box>
                        </Box>
                    </SoftModal>
                    <Box padding="0px 32px 32px 32px" direction="row" justify="space-between">
                        <Box direction="row" width={'100%'} justify="flex-start">
                            <PrimaryButton
                                onClick={deleteModalHandler}
                                isCancel
                                value="Отказаться от оплаты"
                            ></PrimaryButton>
                            <IconButton
                                onClick={() =>
                                    Number(payment.amount) > (result?.available_balance || 0)
                                        ? lowAmountModalHandler()
                                        : payModalHandler()
                                }
                                className={style.buttonMargin}
                            >
                                Оплатить
                            </IconButton>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box direction="row" className={style.messageEditor}>
                    <Box width={'100%'}>
                        <Input
                            bordered={false}
                            style={{ padding: '20px 32px' }}
                            placeholder="Введите сообщение..."
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Box direction="row" flexWrap="wrap">
                            {files?.map((item) => (
                                <Box margin="20px 0px 10px 20px">
                                    <Document document={item} nameMaxLength={50} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={uploadFile}
                        ref={fileInput}
                    />
                    <Box className={style.buttons} width={88} onClick={handleClick}>
                        {uploading ? (
                            <Spin />
                        ) : (
                            <PaperClipOutlined style={{ color: '#528EFF', fontSize: 20 }} />
                        )}
                    </Box>
                    <Box className={style.buttons} width={88}>
                        {isLoading ? (
                            <Spin />
                        ) : (
                            <SendOutlined
                                style={{
                                    color: !message.length ? '#BFBFBF' : '#528EFF',
                                    fontSize: 20,
                                }}
                                onClick={send}
                            />
                        )}
                    </Box>
                </Box>
            )}
        </>
    )
}

export default AppealMessage
