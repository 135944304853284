import { FC } from 'react'

import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const InfoBlock: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.infoBlock}>
            <div className={style.infoBlockItemWrapper}>
                <div className={style.infoBlockItemName}>{t('zdApprovalItem.seller')}</div>
                <div>СЭС Абаканская</div>
            </div>
            <div className={style.infoBlockItemWrapper}>
                <div className={style.infoBlockItemName}>
                    {t('zdApprovalItem.supplierIdentificationCode')}
                </div>
                <div>23456789</div>
            </div>
            <div className={style.infoBlockItemWrapper}>
                <div className={style.infoBlockItemName}>{t('zdApprovalItem.buyer')}</div>
                <div>ОАО Ромашка</div>
            </div>
            <div className={style.infoBlockItemWrapper}>
                <div className={style.infoBlockItemName}>
                    {t('zdApprovalItem.buyerIdentificationCode')}
                </div>
                <div>12345678</div>
            </div>
            <div className={style.infoBlockItemWrapper}>
                <div className={style.infoBlockItemName}>{t('zdApprovalItem.codeGO')}</div>
                <div>1234571526</div>
            </div>
        </div>
    )
}

export default InfoBlock
