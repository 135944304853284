import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from '../../../assets/icons'
import style from './style.m.less'

type BackLinkProps = {
    title: string
    extra?: ReactNode
    link?: any
}

const BackLink: FC<BackLinkProps> = ({ title, extra, link = -1 }) => {
    const navigate = useNavigate()

    return (
        <div className={style.titleWrapper}>
            <span className={style.backLink} onClick={() => navigate(link)}>
                <ArrowLeft />
            </span>
            <div>
                <h1 className={style.title}>{title}</h1>
            </div>
            {extra && extra}
        </div>
    )
}

export default BackLink
