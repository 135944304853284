import { FC, useState } from 'react'
// import { ValueSelector } from '../../../'
import { Input, Divider, Alert } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { DropdownSelector, IconButton, Modal, PrimaryButton } from '../../../ui'
import { WarningOutlined } from '@ant-design/icons'

const options = [
    { id: 'Физическое лицо', value: 'Физическое лицо' },
    { id: 'Юридическое лицо', value: 'Юридическое лицо' },
    { id: 'Индивидуальный предприниматель', value: 'Индивидуальный предприниматель' },
]

const ownerType = 'Индивидуальный предприниматель'

const SearchConsumer: FC = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const [isSearchResultVisible, setIsSearchResultVisible] = useState(false)

    const showSearchResult = () => {
        setIsSearchResultVisible(true)
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible}>
                <div className={style.modalHeader}>Поиск потребителя в системе</div>
                <Alert
                    className={style.resultAlert}
                    message="Поиск осуществляется по любому из заполненных полей"
                    type="info"
                    showIcon
                />
                {ownerType === 'Физическое лицо' && (
                    <>
                        <div className={style.lineWrapper}>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>Фамилия</div>
                                <Input placeholder="Введите фамилию" className={style.input} />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>Имя</div>
                                <Input placeholder="Введите имя" className={style.input} />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>Отчество</div>
                                <Input placeholder="Введите отчество" className={style.input} />
                            </div>
                        </div>
                        <div className={style.lineWrapper}>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>ИНН</div>
                                <Input placeholder="Введите ИНН" className={style.input} />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>СНИЛС</div>
                                <Input placeholder="Введите СНИЛС" className={style.input} />
                            </div>
                        </div>
                    </>
                )}
                {ownerType === 'Юридическое лицо' && (
                    <>
                        <div className={style.lineWrapper}>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>Сокращенное название организации </div>
                                <Input
                                    placeholder="Введите cокращенное название организации "
                                    className={style.input}
                                />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>ИНН</div>
                                <Input placeholder="Введите ИНН" className={style.input} />
                            </div>
                        </div>
                        <div className={style.lineWrapper}>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>КПП</div>
                                <Input placeholder="Введите КПП" className={style.input} />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>ОГРН</div>
                                <Input placeholder="Введите ОГРН" className={style.input} />
                            </div>
                        </div>
                    </>
                )}
                {ownerType === 'Индивидуальный предприниматель' && (
                    <>
                        <div className={style.lineWrapper}>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>Фамилия</div>
                                <Input placeholder="Введите фамилию" className={style.input} />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>Имя</div>
                                <Input placeholder="Введите имя" className={style.input} />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>Отчество</div>
                                <Input placeholder="Введите отчество" className={style.input} />
                            </div>
                        </div>
                        <div className={style.lineWrapper}>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>ИНН</div>
                                <Input placeholder="Введите ИНН" className={style.input} />
                            </div>
                            <div className={style.inputWrapper}>
                                <div className={style.label}>ОГРНИП</div>
                                <Input placeholder="Введите ОГРНИП" className={style.input} />
                            </div>
                        </div>
                    </>
                )}
                <IconButton customStyle={{ marginTop: '16px' }} onClick={showSearchResult}>
                    Найти
                </IconButton>
                {isSearchResultVisible && ownerType === 'Физическое лицо' && (
                    <>
                        <Alert
                            className={style.resultAlert}
                            message={
                                <span className={style.alertTitle}>Ильин Макар Владимирович</span>
                            }
                            description={
                                <>
                                    <span className={style.alertDetail}>ИНН - 9462618934</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>
                                        СНИЛС - 126-975-800 54
                                    </span>
                                </>
                            }
                            type="info"
                        />
                        <div className={style.resultBlock}>
                            <div className={style.alertTitle}>Ильин Владимир Иосифович</div>
                            <div>
                                <span className={style.alertDetail}>ИНН - 8765678022</span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>СНИЛС - 116-019-358 14</span>
                            </div>
                        </div>
                        <div className={style.resultBlock}>
                            <div className={style.alertTitle}>Ильин Александр Семенович</div>
                            <div>
                                <span className={style.alertDetail}>ИНН - 2345620073</span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>СНИЛС - 007-346-177 98</span>
                            </div>
                        </div>
                    </>
                )}
                {isSearchResultVisible && ownerType === 'Юридическое лицо' && (
                    <>
                        <Alert
                            className={style.resultAlert}
                            message={<span className={style.alertTitle}>ООО Ромашка</span>}
                            description={
                                <>
                                    <span className={style.alertDetail}>ИНН - 9462618934</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>ОГРН - 1230563001975</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>КПП - 1234567890987</span>
                                </>
                            }
                            type="info"
                        />
                        <div className={style.resultBlock}>
                            <div className={style.alertTitle}>ОАО Ромашковая Ромашка</div>
                            <div>
                                <span className={style.alertDetail}>ИНН - 8765678022</span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>ОГРН - 3880457163840</span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>КПП - 1234567890987</span>
                            </div>
                        </div>
                    </>
                )}
                {isSearchResultVisible && ownerType === 'Индивидуальный предприниматель' && (
                    <>
                        <Alert
                            className={style.resultAlert}
                            message={<span className={style.alertTitle}>ИП Дмитрий Селиванов</span>}
                            description={
                                <>
                                    <span className={style.alertDetail}>ИНН - 9462618934</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>
                                        ОГРНИП - 1230563001975
                                    </span>
                                </>
                            }
                            type="info"
                        />
                        <div className={style.resultBlock}>
                            <div className={style.alertTitle}>ИП Александр Селиванов</div>
                            <div>
                                <span className={style.alertDetail}>ИНН - 8765678022</span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>ОГРНИП - 3880457163840</span>
                            </div>
                        </div>
                    </>
                )}
                <Divider />
                <div className={style.buttonsBlock}>
                    <PrimaryButton
                        onClick={handleOk}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryButton
                        onClick={handleOk}
                        value="Добавить"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default SearchConsumer
