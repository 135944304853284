import { FC } from 'react'
import style from './style.m.less'
import { Link } from 'react-router-dom'
import { Card, IconButton, LoadingContainer } from '../ui'
import { DoubleSave } from '../../assets/icons'
import { Default } from './components'
import useMount from '../hooks/useMount'
import useRequest from '../hooks/useRequest'
import { getSubmissionAvailable } from './config'

const ZDData: FC = () => {
    const {
        fetch: fetchAvailable,
        result: availability,
        isLoading: isLoadingAvailable,
    } = useRequest(getSubmissionAvailable)

    useMount(() => {
        fetchAvailable({ errorCodeMessage: true })
    })

    return (
        <LoadingContainer isLoading={isLoadingAvailable || !availability}>
            <div className={style.container}>
                <h1 className={style.title}>Данные ЗД</h1>
                <div className={style.buttonWrapper}>
                    <Link to="/zd-submit-data">
                        <IconButton icon={<DoubleSave />} disabled={!availability?.is_available}>
                            Подать данные по ЗД
                        </IconButton>
                    </Link>
                </div>
                <Card style={{ marginTop: '24px' }}>
                    <Default />
                </Card>
            </div>
        </LoadingContainer>
    )
}

export default ZDData
