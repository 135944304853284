import { IOperationConfig } from '../../../ui/OperationsMapper/types'

export const historyConfig: IOperationConfig[] = [
    {
        date: 'Сегодня, 17 июня, пятница',
        operations: [
            { type: 'repayment', time: '12:24', value: '- 200 450 ₽' },
            { type: 'repayment', time: '12:00', value: '- 2 450 ₽' },
            { type: 'refill', time: '9:40', value: '+ 3 800 000 ₽' },
        ],
        flexStart: false,
        iconMargin: true,
    },
    {
        date: 'Вчера, 16 июня, четверг',
        operations: [{ type: 'refill', time: '15:59', value: '+ 3 800 000 ₽' }],
        flexStart: false,
        iconMargin: true,
    },
]
