// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploadDocumentFrame_UYO1r {\n  background: rgba(230, 241, 255, 0.35);\n  border-radius: 16px;\n  padding: 20px 24px;\n}\n.ant-form-item-has-error .uploadDocumentFrame_UYO1r {\n  outline: 1px solid #ff4d4f;\n}\n.info_g1S48 {\n  margin-top: 12px;\n}\n.documentsListWrapper_Q2BcK {\n  margin-left: 8px;\n}\n.file_p3pDD {\n  display: none !important;\n}\n.documentsAlert_LBeZQ {\n  width: 400px;\n  margin-left: 215px;\n  margin-top: 16px;\n  border-radius: 16px;\n  padding: 8px 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/DocumentsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACI;EACI,0BAAA;AACR;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,wBAAA;AAHJ;AAMA;EACI,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAJJ","sourcesContent":[".uploadDocumentFrame {\n    background: rgba(230, 241, 255, 0.35);\n    border-radius: 16px;\n    padding: 20px 24px;\n\n    :global(.ant-form-item-has-error) & {\n        outline: 1px solid #ff4d4f;\n    }\n}\n\n.info {\n    margin-top: 12px;\n}\n\n.documentsListWrapper {\n    margin-left: 8px;\n}\n\n.file {\n    display: none !important;\n}\n\n.documentsAlert {\n    width: 400px;\n    margin-left: 215px;\n    margin-top: 16px;\n    border-radius: 16px;\n    padding: 8px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadDocumentFrame": "uploadDocumentFrame_UYO1r",
	"info": "info_g1S48",
	"documentsListWrapper": "documentsListWrapper_Q2BcK",
	"file": "file_p3pDD",
	"documentsAlert": "documentsAlert_LBeZQ"
};
export default ___CSS_LOADER_EXPORT___;
