// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader_Qc7Z9 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 24px;\n}\n.modalHeaderWrapper_uzYBv {\n  display: flex;\n  justify-content: space-between;\n}\n.modalCloseIcon_GI0Tt {\n  padding-top: 8px;\n  cursor: pointer;\n}\n.modalWrapper_WVnRU {\n  display: flex;\n  gap: 24px;\n}\n.modalName_K3io5 {\n  width: 150px;\n  color: #8C8C8C;\n  margin-bottom: 16px;\n}\n.modalCertificateValue_yFSqj {\n  color: #296DFF;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MyProfile/components/OperationDetailsModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;AADJ;AAIA;EACI,aAAA;EACA,SAAA;AAFJ;AAKA;EACI,YAAA;EACA,cAAA;EACA,mBAAA;AAHJ;AAMA;EACI,cAAA;AAJJ","sourcesContent":[".modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 24px;\n}\n\n.modalHeaderWrapper {\n    display: flex;\n    justify-content: space-between;\n}\n\n.modalCloseIcon {\n    padding-top: 8px;\n    cursor: pointer;\n}\n\n.modalWrapper {\n    display: flex;\n    gap: 24px\n}\n\n.modalName {\n    width: 150px;\n    color: #8C8C8C;\n    margin-bottom: 16px;\n}\n\n.modalCertificateValue {\n    color: #296DFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "modalHeader_Qc7Z9",
	"modalHeaderWrapper": "modalHeaderWrapper_uzYBv",
	"modalCloseIcon": "modalCloseIcon_GI0Tt",
	"modalWrapper": "modalWrapper_WVnRU",
	"modalName": "modalName_K3io5",
	"modalCertificateValue": "modalCertificateValue_yFSqj"
};
export default ___CSS_LOADER_EXPORT___;
