import { FC, useState } from 'react'
import { DeleteModal, Modal, PrimaryIconButton } from '../../ui'
import useRequest from '../../hooks/useRequest'
import { addTopicCfg, deleteTopicCfg, editTopicCfg } from '../config'

import style from '../style.m.less'
import { Divider, Input, Spin, Typography } from 'antd'
import { Box } from '../../ui/Box/Box'
import { CheckOutlined, CloseOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Edit, Trash } from '../../../assets/icons'

interface ITopicEditl {
    isModalVisible: boolean
    setIsModalVisible: () => void
    topics: any[]
    getTopics: any
}

const DEF = {
    id: 0,
    topic: '',
}

const TopicEdit: FC<ITopicEditl> = ({
    isModalVisible,
    setIsModalVisible,
    getTopics,
    topics = [],
}) => {
    const { fetch: addTopic, isLoading: isLoadingCreate } = useRequest(addTopicCfg)
    const { fetch: editTopic, isLoading: isLoadingEdit } = useRequest(editTopicCfg)
    const { fetch: deleteTopic, isLoading: isLoadingDelete } = useRequest(deleteTopicCfg)

    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)
    const [addMode, setAddMode] = useState<boolean>(false)
    const [topic, setTopic] = useState<any>(DEF)

    const deleteModalHandler = (t?: any) => {
        setDeleteModalVisible(!deleteModalVisible)
        setTopic(t || DEF)
    }
    const dropState = () => {
        getTopics()
        setTopic({ id: 0, topic: '' })
    }

    const setAddTopic = () => {
        addTopic({
            body: {
                topic: topic.topic,
            },
            onSuccess: () => {
                dropState()
                setAddMode(false)
            },
        })
    }

    const setEditTopic = () => {
        editTopic({
            body: {
                topic: topic.topic,
            },
            getParams: {
                id: topic.id,
            },
            onSuccess: dropState,
        })
    }

    const setDeleteTopic = () => {
        deleteTopic({
            getParams: {
                id: topic.id,
            },
            onSuccess: () => {
                dropState()
                deleteModalHandler()
            },
        })
    }

    const editHandler = (el: any = { id: 0, topic: '' }, add: boolean) => {
        setTopic(el)
        setAddMode(add)
    }

    const deleteText = `Вы уверены, что хотите удалить тему “${topic.topic}”? Созданные на эту тему заявки останутся в системе.`

    return (
        <>
            <DeleteModal
                isModalVisible={deleteModalVisible}
                onCancel={deleteModalHandler}
                onDelete={setDeleteTopic}
                headerText="Удалить тему"
                messageText={deleteText}
            />
            <Modal isModalVisible={isModalVisible} closable onCancel={setIsModalVisible}>
                <div className={style.modalHeader}>Справочник тем заявок</div>
                <Box height={20} />
                {(topics || [])
                    .filter((el: any) => !el.ts_deleted)
                    .map((el: any) => (
                        <Box width={436} key={el.id}>
                            <Box justify="space-between" direction="row" align="center">
                                {topic.id === el.id && !deleteModalVisible ? (
                                    <Box width={'80%'}>
                                        <Input
                                            value={topic.topic}
                                            style={{ borderRadius: '8px' }}
                                            onChange={(e) =>
                                                setTopic((prev: any) => ({
                                                    id: prev.id,
                                                    topic: e.target.value,
                                                }))
                                            }
                                        />
                                    </Box>
                                ) : (
                                    <Typography.Text>{el.topic}</Typography.Text>
                                )}
                                {topic.id === el.id && !deleteModalVisible ? (
                                    <Box direction="row">
                                        <PrimaryIconButton
                                            icon={
                                                isLoadingEdit && topic.id === el.id ? (
                                                    <Spin />
                                                ) : (
                                                    <CheckOutlined />
                                                )
                                            }
                                            onClick={setEditTopic}
                                        ></PrimaryIconButton>
                                        <PrimaryIconButton
                                            icon={<CloseOutlined />}
                                            isCancel
                                            onClick={() => editHandler(undefined, false)}
                                            customStyle={{ marginLeft: '10px' }}
                                        ></PrimaryIconButton>
                                    </Box>
                                ) : (
                                    <Box direction="row">
                                        <PrimaryIconButton
                                            icon={<Edit />}
                                            onClick={() => editHandler(el, false)}
                                        ></PrimaryIconButton>
                                        <PrimaryIconButton
                                            icon={
                                                isLoadingDelete && topic.id === el.id ? (
                                                    <Spin />
                                                ) : (
                                                    <Trash />
                                                )
                                            }
                                            isCancel
                                            onClick={() => deleteModalHandler(el)}
                                            customStyle={{ marginLeft: '10px' }}
                                        ></PrimaryIconButton>
                                    </Box>
                                )}
                            </Box>
                            <Divider />
                        </Box>
                    ))}
                {addMode ? (
                    <Box width={436} direction="row" justify="space-between">
                        <Box width={'80%'}>
                            <Input
                                value={topic.topic}
                                style={{ borderRadius: '8px' }}
                                onChange={(e) =>
                                    setTopic((prev: any) => ({
                                        id: prev.id,
                                        topic: e.target.value,
                                    }))
                                }
                            />
                        </Box>
                        <Box direction="row">
                            <PrimaryIconButton
                                icon={isLoadingCreate ? <Spin /> : <CheckOutlined />}
                                onClick={setAddTopic}
                            ></PrimaryIconButton>
                            <PrimaryIconButton
                                icon={<CloseOutlined />}
                                isCancel
                                onClick={() => editHandler(undefined, false)}
                                customStyle={{ marginLeft: '10px' }}
                            ></PrimaryIconButton>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        direction="row"
                        align="center"
                        onClick={() => editHandler(undefined, true)}
                    >
                        <PlusCircleOutlined style={{ color: '#296DFF' }} />
                        <Typography.Link
                            strong
                            style={{
                                color: '#296DFF',
                                marginLeft: '18px',
                            }}
                        >
                            Добавить тему
                        </Typography.Link>
                    </Box>
                )}
            </Modal>
        </>
    )
}

export default TopicEdit
