import { FC } from 'react'
import { Button } from 'antd'
import { Warning } from '../../../../assets/icons'
import { Modal } from '../../../ui'

import style from '../../style.m.less'
import { Link } from 'react-router-dom'

interface ISubModal {
    isVisible: boolean
    onClose: () => void
}

const SubModal: FC<ISubModal> = ({ isVisible, onClose }) => {
    return (
        <Modal isModalVisible={isVisible}>
            <div>
                <div className={style.subModalWrapper}>
                    <Warning />
                    <div>
                        <div className={style.subModalHeader}>
                            <div>Недостаточно денежных средств</div>
                        </div>
                        <div className={style.subModalContent}>
                            Операция не выполнена, так как на Вашем <br /> балансе недостаточно
                            средств, чтобы <br />
                            произвести оплату комиссии.
                        </div>
                    </div>
                </div>
                <div className={style.buttonsBlock}>
                    <Button onClick={onClose} className={style.cancelButton}>
                        Отмена
                    </Button>
                    <Link to="/profile" state={{ toPaymentAccount: true }}>
                        <Button className={style.confirmButton}>Перейти к платежному счету</Button>
                    </Link>
                </div>
            </div>
        </Modal>
    )
}

export default SubModal
