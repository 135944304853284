import { FC, useState, useEffect } from 'react'
import { Divider } from 'antd'
import { Card, PrimaryButton, IFile, LoadingContainer, Box } from '../ui'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import DocumentsBlock from './components/DocumentsBlock/DocumentsBlock'
import { postUpload, viewData } from './config'

import style from './style.m.less'

const AdminUploading: FC = () => {
    const navigate = useNavigate()
    const [files, setFiles] = useState<IFile[]>([])
    const { fetch: sendUpload, isLoading } = useRequest(postUpload)
    const { fetch: fetchView, result, isLoading: isLoadingView } = useRequest(viewData)

    const fileHandler = (list: any) => {
        if (!files.length) {
            return setFiles(list)
        }
    }

    const doc = files[0]

    const handleConfirm = async () => {
        const body = {
            document: doc,
        }
        sendUpload({
            body,
            successMessage: {
                title: 'Успешно',
                description: 'Данные о пополнении платежных счетов успешно записаны',
            },
            onSuccess: () => navigate('/registry'),
            errorCodeMessage: true,
        })
    }

    useEffect(() => {
        if (files.length) {
            const body = {
                limit: 10,
                offset: 0,
                document: doc,
            }
            fetchView({
                body,
                errorCodeMessage: true,
            })
        }
    }, [files])

    return (
        <LoadingContainer isLoading={isLoading || isLoadingView}>
            <div className={style.container}>
                <div className={style.titleWrapper}>
                    <div>
                        <h1 className={style.title}>
                            Загрузка данных о пополнении платежных счетов
                        </h1>
                    </div>
                </div>
                <Card>
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={7}
                        description={
                            files.length >= 1
                                ? ''
                                : 'Загрузите файл, из которого хотите импортировать данные'
                        }
                        customStyle={{ width: '440px' }}
                        format="xml"
                    />
                    {result && (
                        <Box style={{ marginTop: '16px' }}>
                            <Box direction="row">
                                <div className={style.leftSide}>Общая сумма пополнения</div>
                                <div
                                    className={style.rightSide}
                                >{`${result.deposits_amount} Р`}</div>
                            </Box>
                            <div className={style.wrapper}>
                                <div className={style.leftSide}>Общее количество операций</div>
                                <div className={style.rightSide}>{result.total}</div>
                            </div>
                        </Box>
                    )}
                    <Divider />
                    <div className={style.buttonWrapper}>
                        <PrimaryButton
                            onClick={() => navigate('/registry')}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            onClick={handleConfirm}
                            value="Записать"
                            disabled={!files.length}
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Card>
            </div>
        </LoadingContainer>
    )
}

export default AdminUploading
