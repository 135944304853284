import { FC, useState } from 'react'
import { Input, Divider, Alert, Empty } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { CenteredSpin, IconButton, Modal, PrimaryButton, PrimaryIconButton } from '../../../ui'
import request from '../../../../services/request'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'

export interface ITSO {
    company_inn: string
    company_name_short: string
    kpp: string
    ogrn: string
    uid: string
    isDraft: boolean
}

interface ISearchTSO {
    isModalVisible: boolean
    onCancel(): void
    onSelect(tso: ITSO): void
}

const SearchTSO: FC<ISearchTSO> = ({ isModalVisible, onCancel, onSelect }) => {
    const { t } = useTranslation()

    const [shortName, setShortName] = useState('')
    const [inn, setInn] = useState('')
    const [ogrn, setOgrn] = useState('')
    const [kpp, setKpp] = useState('')

    const searchIsDisabled = !shortName && !inn && !ogrn && !kpp

    const [tsoLoading, setTsoLoading] = useState(false)
    const [tsos, setTsos] = useState<ITSO[]>([])
    const [selectedTso, setSelectedTso] = useState<ITSO>()

    const handleOk = () => {
        if (!selectedTso) {
            return
        }
        onSelect({ ...selectedTso, isDraft: false })
    }

    const showSearchResult = async () => {
        try {
            setTsoLoading(true)
            const res = await request.post<
                any,
                AxiosResponse<{ total: number; companies: ITSO[] }>
            >('/api/users/v1/tso-gp/find', {
                filters: {
                    company_name: shortName,
                    company_inn: inn,
                    ogrn: ogrn,
                    kpp: kpp,
                },
                limit: 0,
                offset: 0,
            })
            setTsos(res.data.companies)
        } finally {
            setTsoLoading(false)
        }
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible} onCancel={onCancel}>
                <div className={style.modalHeader}>Поиск ТСО в системе</div>
                <Alert
                    className={style.resultAlert}
                    message="Поиск осуществляется по любому из заполненных полей"
                    type="info"
                    showIcon
                />
                <>
                    <div className={style.lineWrapper}>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Сокращенное название организации </div>
                            <Input
                                placeholder="Введите cокращенное название организации "
                                className={style.input}
                                value={shortName}
                                onChange={(e) => setShortName(e.target.value)}
                            />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ИНН</div>
                            <Input
                                placeholder="Введите ИНН"
                                className={style.input}
                                value={inn}
                                onChange={(e) => setInn(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={style.lineWrapper}>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>КПП</div>
                            <Input
                                placeholder="Введите КПП"
                                className={style.input}
                                value={kpp}
                                onChange={(e) => setKpp(e.target.value)}
                            />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ОГРН</div>
                            <Input
                                placeholder="Введите ОГРН"
                                className={style.input}
                                value={ogrn}
                                onChange={(e) => setOgrn(e.target.value)}
                            />
                        </div>
                    </div>
                </>
                <IconButton
                    customStyle={{ marginTop: '16px' }}
                    onClick={showSearchResult}
                    disabled={searchIsDisabled}
                >
                    Найти
                </IconButton>
                {tsoLoading ? (
                    <CenteredSpin />
                ) : tsos?.length > 0 ? (
                    <>
                        {tsos.map((tso) => (
                            <div
                                className={classNames(style.resultBlock, {
                                    [style.resultAlert]: selectedTso === tso,
                                })}
                                onClick={() => setSelectedTso(tso)}
                            >
                                <div className={style.alertTitle}>{tso.company_name_short}</div>
                                <div>
                                    <span className={style.alertDetail}>
                                        ИНН - {tso.company_inn}
                                    </span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>ОГРН - {tso.ogrn}</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>КПП - {tso.kpp}</span>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                <Divider />
                <div className={style.buttonsBlock}>
                    <PrimaryButton
                        onClick={onCancel}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryIconButton
                        size="large"
                        disabled={!selectedTso}
                        onClick={handleOk}
                        value="Добавить"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default SearchTSO
