// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_bLPNO {\n  margin-top: 24px;\n}\n.table_OSOAI {\n  border-radius: 16px;\n  border: 1px solid var(--gray-gray-4, #F0F0F0);\n  overflow: hidden;\n  width: 50%;\n}\n.header_kPCGf {\n  background: var(--dark-blue-darkblue-1, #F0F5FF);\n  padding: 6px 16px;\n  font-weight: 600;\n  font-size: 14px;\n}\n.row_r92Wy {\n  padding: 6px 16px;\n  display: flex;\n  font-size: 14px;\n}\n.column_U09lp {\n  flex: 1;\n}\n.row_r92Wy:not(:last-child) {\n  border-bottom: 1px solid var(--gray-gray-4, #F0F0F0);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Audit/components/SeparateConsumersTable/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,mBAAA;EACA,6CAAA;EACA,gBAAA;EACA,UAAA;AAAJ;AAGA;EACI,gDAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;AADJ;AAIA;EACI,iBAAA;EACA,aAAA;EACA,eAAA;AAFJ;AAKA;EACI,OAAA;AAHJ;AAMA;EACI,oDAAA;AAJJ","sourcesContent":[".wrapper {\n    margin-top: 24px;\n}\n\n.table {\n    border-radius: 16px;\n    border: 1px solid var(--gray-gray-4, #F0F0F0);\n    overflow: hidden;\n    width: 50%;\n}\n\n.header {\n    background: var(--dark-blue-darkblue-1, #F0F5FF);\n    padding: 6px 16px;\n    font-weight: 600;\n    font-size: 14px;\n}\n\n.row {\n    padding: 6px 16px;\n    display: flex;\n    font-size: 14px;\n}\n\n.column {\n    flex: 1;\n}\n\n.row:not(:last-child) {\n    border-bottom: 1px solid var(--gray-gray-4, #F0F0F0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_bLPNO",
	"table": "table_OSOAI",
	"header": "header_kPCGf",
	"row": "row_r92Wy",
	"column": "column_U09lp"
};
export default ___CSS_LOADER_EXPORT___;
