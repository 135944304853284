// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploadDocumentFrame_nc0BJ {\n  background: rgba(230, 241, 255, 0.35);\n  border-radius: 16px;\n  padding: 20px 24px;\n}\n.ant-form-item-has-error .uploadDocumentFrame_nc0BJ {\n  outline: 1px solid #ff4d4f;\n}\n.info_B6Nk1 {\n  margin-top: 12px;\n}\n.documentsListWrapper_leowv {\n  margin-left: 8px;\n}\n.file_OfyRW {\n  display: none !important;\n}\n.documentsAlert_C2p1o {\n  width: 400px;\n  margin-top: 16px;\n  border-radius: 16px;\n  padding: 8px 16px;\n}\n.star_auq4Q {\n  margin-left: 4px;\n  display: inline;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AddEditGO/components/DocumentsBlock/style.m.less"],"names":[],"mappings":"AAAA;EAEI,qCAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,0BAAA;AAAR;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,gBAAA;AAHJ;AAMA;EACI,wBAAA;AAJJ;AAOA;EACI,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AALJ;AAQA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AANJ","sourcesContent":[".uploadDocumentFrame {\n    // height: 112px;\n    background: rgba(230, 241, 255, 0.35);\n    border-radius: 16px;\n    padding: 20px 24px;\n\n    :global(.ant-form-item-has-error) & {\n        outline: 1px solid #ff4d4f;\n    }\n}\n\n.info {\n    margin-top: 12px;\n}\n\n.documentsListWrapper {\n    margin-left: 8px;\n}\n\n.file {\n    display: none !important;\n}\n\n.documentsAlert {\n    width: 400px;\n    margin-top: 16px;\n    border-radius: 16px;\n    padding: 8px 16px;\n}\n\n.star {\n    margin-left: 4px;\n    display: inline;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadDocumentFrame": "uploadDocumentFrame_nc0BJ",
	"info": "info_B6Nk1",
	"documentsListWrapper": "documentsListWrapper_leowv",
	"file": "file_OfyRW",
	"documentsAlert": "documentsAlert_C2p1o",
	"star": "star_auq4Q"
};
export default ___CSS_LOADER_EXPORT___;
