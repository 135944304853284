interface IData {
    key: string
    title: string
    one: { value: number; conflict: boolean }
    two: { value: number; conflict: boolean }
    three: { value: number; conflict: boolean }
    four: { value: number; conflict: boolean }
    five: { value: number; conflict: boolean }
    six: { value: number; conflict: boolean }
    seven: { value: number; conflict: boolean }
    eight: { value: number; conflict: boolean }
    nine: { value: number; conflict: boolean }
    ten: { value: number; conflict: boolean }
}

const getDataSource = (): IData[] => [
    {
        key: '1',
        title: '0-1',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '2',
        title: '1-2',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '3',
        title: '2-3',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '4',
        title: '3-4',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '5',
        title: '4-5',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '6',
        title: '5-6',
        one: { value: 10887, conflict: false },
        two: { value: 6338, conflict: true },
        three: { value: 1290, conflict: true },
        four: { value: 10650, conflict: false },
        five: { value: 2096, conflict: false },
        six: { value: 10887, conflict: false },
        seven: { value: 1577, conflict: false },
        eight: { value: 9703, conflict: false },
        nine: { value: 8532, conflict: false },
        ten: { value: 3105, conflict: false },
    },
    {
        key: '7',
        title: '6-7',
        one: { value: 8653, conflict: false },
        two: { value: 7409, conflict: true },
        three: { value: 3105, conflict: false },
        four: { value: 6021, conflict: false },
        five: { value: 3707, conflict: false },
        six: { value: 5400, conflict: false },
        seven: { value: 6098, conflict: false },
        eight: { value: 6338, conflict: false },
        nine: { value: 7409, conflict: false },
        ten: { value: 10887, conflict: false },
    },
    {
        key: '8',
        title: '7-8',
        one: { value: 4300, conflict: false },
        two: { value: 8532, conflict: false },
        three: { value: 10887, conflict: false },
        four: { value: 8653, conflict: false },
        five: { value: 6338, conflict: false },
        six: { value: 980, conflict: false },
        seven: { value: 10887, conflict: false },
        eight: { value: 2096, conflict: false },
        nine: { value: 1290, conflict: false },
        ten: { value: 4300, conflict: false },
    },
    {
        key: '9',
        title: '8-9',
        one: { value: 9703, conflict: false },
        two: { value: 6338, conflict: true },
        three: { value: 1290, conflict: false },
        four: { value: 4300, conflict: true },
        five: { value: 771, conflict: false },
        six: { value: 3707, conflict: false },
        seven: { value: 8532, conflict: false },
        eight: { value: 6338, conflict: false },
        nine: { value: 8653, conflict: false },
        ten: { value: 9703, conflict: false },
    },
    {
        key: '10',
        title: '9-10',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: true },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '11',
        title: '10-11',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: true },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '12',
        title: '11-12',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '13',
        title: '12-13',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '14',
        title: '13-14',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '15',
        title: '14-15',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '16',
        title: '15-17',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '17',
        title: '16-17',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '18',
        title: '17-18',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '19',
        title: '18-19',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: true },
        ten: { value: 299, conflict: false },
    },
    {
        key: '20',
        title: '19-20',
        one: { value: 6021, conflict: false },
        two: { value: 1577, conflict: false },
        three: { value: 1600, conflict: false },
        four: { value: 822, conflict: false },
        five: { value: 2700, conflict: false },
        six: { value: 3200, conflict: false },
        seven: { value: 4300, conflict: false },
        eight: { value: 980, conflict: false },
        nine: { value: 602, conflict: false },
        ten: { value: 299, conflict: false },
    },
    {
        key: '21',
        title: '20-21',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '22',
        title: '21-22',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '23',
        title: '22-23',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
    {
        key: '24',
        title: '23-0',
        one: { value: 0, conflict: false },
        two: { value: 0, conflict: false },
        three: { value: 0, conflict: false },
        four: { value: 0, conflict: false },
        five: { value: 0, conflict: false },
        six: { value: 0, conflict: false },
        seven: { value: 0, conflict: false },
        eight: { value: 0, conflict: false },
        nine: { value: 0, conflict: false },
        ten: { value: 0, conflict: false },
    },
]

const dataSource = getDataSource()
export { dataSource }
export type { IData }
