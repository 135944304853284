import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const BookSaved: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.6668 3.1133V11.16C14.6668 11.8 14.1468 12.4 13.5068 12.48L13.2868 12.5066C11.8335 12.7 9.59348 13.44 8.31348 14.1466C8.14014 14.2466 7.8535 14.2466 7.6735 14.1466L7.64681 14.1333C6.36681 13.4333 4.13352 12.7 2.68685 12.5066L2.49349 12.48C1.85349 12.4 1.3335 11.8 1.3335 11.16V3.10662C1.3335 2.31329 1.98014 1.7133 2.77348 1.77996C4.17348 1.8933 6.29348 2.59998 7.48014 3.33998L7.64681 3.43996C7.84014 3.55996 8.16018 3.55996 8.35352 3.43996L8.46684 3.36663C8.88684 3.10663 9.42016 2.84663 10.0002 2.6133V5.33331L11.3335 4.44663L12.6668 5.33331V1.85333C12.8468 1.81999 13.0202 1.79997 13.1802 1.78664H13.2202C14.0135 1.71997 14.6668 2.3133 14.6668 3.1133Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 3.66003V13.66"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.6667 1.85339V5.33337L11.3333 4.4467L10 5.33337V2.61336C10.8733 2.2667 11.8467 1.98673 12.6667 1.85339Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
