import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from '../../../assets/icons'
import style from './style.m.less'

interface IBackArrow {
    link?: string | number
}

const BackArrow: FC<IBackArrow> = ({ link = -1 }) => {
    const navigate = useNavigate()
    return (
        <button onClick={() => navigate(link)} className={style.backButton}>
            <ArrowLeft />
        </button>
    )
}

export default BackArrow
