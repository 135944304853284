import { FC, useState } from 'react'
import { Divider, Input, message } from 'antd'
import {
    Card,
    DocumentsBlock,
    PrimaryButton,
    PrimaryIconButton,
    IFile,
    LoadingContainer,
    DatePicker,
} from '../ui'
import { useSelector } from 'react-redux'
import IAppState from 'store/states'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import { getOperationStatus, postKU } from './config'
import { v4 as uuidv4 } from 'uuid'
import SignUtils from '../../utils/sign/SignUtils'
import { encode, btoa } from 'js-base64'
import { hexToBinary } from '../helpers'
import moment from 'moment'

import style from './style.m.less'
import { OperationStatusResponse } from './types'

const MAX_FILES_LENGTH = 20
const MAX_DOCS_LENGTH = 3

const KUSubmitOwner: FC = () => {
    const navigate = useNavigate()
    const [files, setFiles] = useState<IFile[]>([])
    const [docs, setDocs] = useState<IFile[]>([])
    const [date, setDate] = useState<string>()
    const { fetch: sendKU, isLoading } = useRequest(postKU)
    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo
    const [operationId, setOperationId] = useState('')
    const { fetch: checkOperationStatus } = useRequest<OperationStatusResponse>(getOperationStatus)

    const fileHandler = (list: any) => {
        if (files.length <= MAX_FILES_LENGTH) {
            return setFiles(list)
        }
    }

    const docsHandler = (list: any) => {
        if (files.length < MAX_DOCS_LENGTH) {
            return setDocs(list)
        }
    }

    const handleConfirm = async () => {
        const operation_id = uuidv4()
        setOperationId(operation_id)
        if (files.length) {
            const body = {
                ca_documents: files,
                documents: docs,
                period: moment(date).format('YYYY-MM'),
                operation_id,
            }
            const request_id = uuidv4()
            const thumbprint = await SignUtils.getCertificateThumbprint(
                data.certificate_data.certificate,
            )
            const encodedInfo = encode(JSON.stringify(body))
            const encoded = request_id + encodedInfo
            const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
            const signature = btoa(hexToBinary(preSignature))
            sendKU({
                body,
                successMessage: {
                    title: 'Успешно',
                    description: 'Данные по КУ успешно поданы',
                },
                onSuccess: (result) => {
                    if (result.id) {
                        navigate('/ku-owner')
                    }
                },
                onError: () => {
                    let intervalId: NodeJS.Timer
                    ;(function () {
                        intervalId = setInterval(() => {
                            checkOperationStatus({
                                getParams: {
                                    id: operationId,
                                },
                                showError: false,
                                onSuccess: (result) => {
                                    if (result.status_id === 1) {
                                        setOperationId('')
                                        clearInterval(intervalId)
                                        navigate('/ku-owner')
                                    } else if (result.status_id === 2) {
                                        setOperationId('')
                                        clearInterval(intervalId)
                                        message.error({
                                            content: 'Ошибка обработки файла. Повторите попытку.',
                                        })
                                    }
                                },
                            })
                        }, 3000)
                    })()
                },
                errorCodeMessage: true,
                getParams: {
                    request_id,
                },
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
            })
        } else {
            message.error('Невозможно подать данные - выберите хотя бы один файл')
        }
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <div className={style.container}>
                <div className={style.titleWrapper}>
                    <div>
                        <h1 className={style.title}>Подача данных по КУ</h1>
                    </div>
                </div>
                <Card>
                    <div className={style.formItem}>
                        <div className={style.label}>Отчетный период</div>
                        <DatePicker
                            defaultValue={date}
                            onChange={(value) => setDate(moment.utc(value).format())}
                            format="MMMM, YYYY"
                            className={style.monthInput}
                            picker="month"
                        />
                    </div>
                    <Divider />
                    <div className={style.header}>Данные по КУ</div>
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={9}
                        description={`Загрузите файлы, из которых хотите импортировать данные (не более ${MAX_FILES_LENGTH})`}
                        customStyle={{ width: '440px', marginTop: '24px' }}
                        disabled={files.length >= MAX_FILES_LENGTH}
                    />
                    <Divider />
                    <div className={style.header}>Дополнительные файлы</div>
                    <DocumentsBlock
                        value={docs}
                        onChange={docsHandler}
                        hideComment
                        serviceId={9}
                        description={`Прикрепите дополнительные документы при необходимости (не более ${MAX_DOCS_LENGTH})`}
                        customStyle={{ width: '440px', marginTop: '24px' }}
                        disabled={docs.length >= MAX_DOCS_LENGTH}
                    />
                    <Divider />
                    <div className={style.buttonWrapper}>
                        <PrimaryButton
                            onClick={() => navigate('/ku-owner')}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryIconButton
                            size="large"
                            onClick={handleConfirm}
                            value="Подписать и подать данные"
                            disabled={!files.length}
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Card>
            </div>
        </LoadingContainer>
    )
}

export default KUSubmitOwner
