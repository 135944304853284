import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../../ui'

import style from './style.m.less'

const TagFilter: FC = () => {
    const { t } = useTranslation()

    const [eventsSelected, setEventSelected] = useState(true)
    const [newsSelected, setNewsSelected] = useState(true)

    return (
        <div className={style.tagFilter}>
            <Tag
                className={style.clickable}
                color={eventsSelected ? 'green' : undefined}
                onClick={() => setEventSelected((s) => !s)}
            >
                #{t('newsAndEvents.events')}
            </Tag>
            <Tag
                className={style.clickable}
                color={newsSelected ? 'blue' : undefined}
                onClick={() => setNewsSelected((s) => !s)}
            >
                #{t('newsAndEvents.news')}
            </Tag>
        </div>
    )
}

interface TagToggle {
    text: string
    color?: string
    select: boolean
    setSelect: (s: any) => void
    needSetOtherSelect?: boolean | undefined
    setOtherSelect?: (s: any) => void
}

export const TagToggle: FC<TagToggle> = ({ color, select, setSelect, text, needSetOtherSelect, setOtherSelect }) => {
    const { t } = useTranslation()

    return (
        <div className={style.tagFilter}>
            <Tag
                className={style.clickable}
                color={select ? color : undefined}
                onClick={() => {
                    if(needSetOtherSelect && setOtherSelect) {
                        setOtherSelect((s: boolean | null | undefined) => !s)
                    }
                    setSelect((s: boolean | null | undefined) => !s)
                }}
            >
                #{t(text)}
            </Tag>
        </div>
    )
}

export default TagFilter
