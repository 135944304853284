// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploadDocumentFrame_N3htj {\n  background: rgba(230, 241, 255, 0.35);\n  border-radius: 16px;\n  padding: 20px 24px;\n}\n.ant-form-item-has-error .uploadDocumentFrame_N3htj {\n  outline: 1px solid #ff4d4f;\n}\n.info_O8WKo {\n  margin-top: 12px;\n  white-space: pre-wrap;\n}\n.documentsListWrapper_ObH59 {\n  margin-left: 8px;\n}\n.file_aQG6H {\n  display: none !important;\n}\n.documentsAlert_thKTA {\n  width: 400px;\n  margin-left: 215px;\n  margin-top: 16px;\n  border-radius: 16px;\n  padding: 8px 16px;\n}\n.star_pkoBz {\n  margin-left: 4px;\n  display: inline;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AdminUploading/components/DocumentsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,qCAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AACI;EACI,0BAAA;AACR;AAGA;EACI,gBAAA;EACA,qBAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,wBAAA;AAHJ;AAMA;EACI,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;AAJJ;AAOA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AALJ","sourcesContent":[".uploadDocumentFrame {\n    background: rgba(230, 241, 255, 0.35);\n    border-radius: 16px;\n    padding: 20px 24px;\n\n    :global(.ant-form-item-has-error) & {\n        outline: 1px solid #ff4d4f;\n    }\n}\n\n.info {\n    margin-top: 12px;\n    white-space: pre-wrap;\n}\n\n.documentsListWrapper {\n    margin-left: 8px;\n}\n\n.file {\n    display: none !important;\n}\n\n.documentsAlert {\n    width: 400px;\n    margin-left: 215px;\n    margin-top: 16px;\n    border-radius: 16px;\n    padding: 8px 16px;\n}\n\n.star {\n    margin-left: 4px;\n    display: inline;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadDocumentFrame": "uploadDocumentFrame_N3htj",
	"info": "info_O8WKo",
	"documentsListWrapper": "documentsListWrapper_ObH59",
	"file": "file_aQG6H",
	"documentsAlert": "documentsAlert_thKTA",
	"star": "star_pkoBz"
};
export default ___CSS_LOADER_EXPORT___;
