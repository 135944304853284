import { FC, useMemo, useEffect, useState } from 'react'
import { Input } from 'antd'
import moment, { Moment } from 'moment'
import {
    Box,
    DropdownSelector2,
    TimeRangePicker,
    DatePicker,
    ImportButton,
} from '../../../../../ui'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectDictionariesOptions } from '../../../../../../store/dictionaries'
import { getGeneratorsForAccount } from '../../../../config'
import useMount from '../../../../../hooks/useMount'
import { statusIdPicker, downloadCsv } from '../../../../../helpers'
import useRequest from '../../../../../hooks/useRequest'
import MoreFilters from '../MoreFilters/MoreFilters'
import { usePrevious } from '../../../../../hooks'
import { useCurrentUser } from '../../../../../hooks/useCurrentUser'
import style from './style.m.less'
import { Import } from '../../../../../../assets/icons'

const depositOptions = [
    { id: 'a851024a-58ae-48dc-b085-178e9966f180', value: 'Все' },
    { id: '9bc31bb4-8ec3-4520-8a18-9eba6400dd57', value: 'В залоге' },
]

interface IControlsBlock {
    toolType: string
    onChange: (value: string) => void
    onChangeFilters: (data: string | number, key: string, obj?: any) => void
    currentOption: string
    isUserType?: boolean
    total?: number
    options: { label: string; id: string }[]
    isDisabled?: boolean
    isAdminType: boolean
    filters?: any
}

const ControlsBlock: FC<IControlsBlock> = ({
    toolType,
    options,
    currentOption,
    total,
    onChange,
    onChangeFilters,
    isDisabled,
    filters,
    isAdminType,
}) => {
    const { renewableEnergyTypesOptions } = useAppSelector(selectDictionariesOptions) ?? {}
    const { fetch: fetchGenerators, result: generatorsResult } = useRequest(getGeneratorsForAccount)
    const [accountValue, setAccountValue] = useState('')

    const generatorsOptions = generatorsResult?.generators
        ? [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
              ...generatorsResult?.generators,
          ]
        : [
              {
                  name: 'Все',
                  value: 'Все',
                  id: '',
              },
          ]

    const isCertificate = toolType === 'Сертификаты происхождения'
    const isContract = toolType === 'Зеленые договоры'
    const isThirdParty = toolType === 'Сторонние инструменты'

    const realRenewableEnergyTypesOptions = useMemo(
        () =>
            renewableEnergyTypesOptions
                ? [
                      { id: 0, value: null, text: 'Все', label: 'Все' },
                      ...renewableEnergyTypesOptions,
                  ]
                : [{ id: 0, value: null, text: 'Все', label: 'Все' }],
        [renewableEnergyTypesOptions],
    )

    const { isUserAdmin } = useCurrentUser()

    useMount(() => {
        fetchGenerators({
            errorCodeMessage: true,
        })
        onChangeFilters('', '', {
            status_id: statusIdPicker(currentOption),
        })
    })

    const prevTool = usePrevious(toolType)
    useEffect(() => {
        if (prevTool !== toolType) {
            setAccountValue('')
        }
    }, [toolType])

    const getFile = () => {
        const url = isCertificate
            ? '/api/green-tools/v1/certificates-to-csv'
            : isThirdParty
            ? '/api/green-tools/v1/ei/ext-instruments-to-csv'
            : '/api/green-tools/v1/contracts/get-list-contracts-to-csv'
        downloadCsv(url, 'GreenToolsData.csv')
    }

    const updateFilters = (data: any, key: string) => {
        switch (key) {
            case 'rangeDate':
                return onChangeFilters('', '', {
                    ts_created_from: data ? moment(data[0]).startOf('day') : undefined,
                    ts_created_to: data ? moment(data[1]).endOf('day') : undefined,
                })
            case 'date':
                return onChangeFilters('', '', {
                    month: data?.month,
                    year: data?.year,
                })
            default:
                return onChangeFilters(data, key)
        }
    }

    const onTimeRangePickerChange =
        ([from, to]: string[]) =>
        (dates: [Moment, Moment] | null) => {
            const isArray = Array.isArray(dates)
            onChangeFilters('', '', {
                [from]: isArray ? dates[0] : dates,
                [to]: isArray ? dates[1] : dates,
            })
        }

    return (
        <div className={style.controlsBlock}>
            <div className={style.inputsRow}>
                {isAdminType && !isContract && (
                    <div>
                        <div>Лицевой счет</div>
                        <div className={style.inputBlock}>
                            <Input
                                className={style.certificateNumberInput}
                                placeholder="Введите номер ЛС"
                                value={accountValue}
                                onChange={(event) => {
                                    setAccountValue(event.target.value)
                                    updateFilters(event.target.value, 'account_number')
                                    fetchGenerators({
                                        errorCodeMessage: true,
                                    })
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className={style.leftGapBlock}>
                    <div>
                        Номер {isCertificate ? 'сертификата' : isContract ? 'ЗД' : 'инструмента'}
                    </div>
                    <div className={style.inputBlock}>
                        <Input
                            className={
                                isContract || (isThirdParty && !isAdminType)
                                    ? style.certificateNumberInputContracts
                                    : style.certificateNumberInput
                            }
                            placeholder={`Введите номер ${
                                isCertificate ? 'СП' : isContract ? 'договора' : 'инструмента'
                            }`}
                            key={
                                isCertificate
                                    ? '232bc422-8cd5-4ccb-90e3-da00e5987634'
                                    : isContract
                                    ? 'd2549f08-1b08-45eb-8d93-f2f76df772fc'
                                    : 'aa89baf6-8103-4f1b-bb14-6619ad7c7ba5'
                            }
                            onChange={(event) =>
                                updateFilters(
                                    event.target.value,
                                    isCertificate
                                        ? 'certificate_number'
                                        : isContract
                                        ? 'contract_number'
                                        : 'ext_instrument_number',
                                )
                            }
                        />
                    </div>
                </div>
                {isContract ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '233px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                    key={'7e6e26b8-b806-4333-aa5f-3d9d282fd9c2'}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата регистрации</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange([
                                        'ts_registration_from',
                                        'ts_registration_to',
                                    ])}
                                    key={'53383552-ff2e-41d0-b8af-2e177ac67b2f'}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата создания в системе</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange([
                                        'ts_created_from',
                                        'ts_created_to',
                                    ])}
                                    key={'a23ccaa7-7873-4f16-a76c-2108d72a16d0'}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата начала поставки по ЗД</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange([
                                        'ts_delivery_from',
                                        'ts_delivery_to',
                                    ])}
                                    key={'e59933ad-9cb9-409d-ac1e-2785c3842622'}
                                />
                            </div>
                        </div>
                    </>
                ) : isCertificate ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Номинал сертификата, кВт*ч</div>
                            <Box direction="row" gap="8px">
                                <Input
                                    className={style.certificateNumberInputShort}
                                    placeholder="С"
                                    type="number"
                                    value={filters.volume_from || null}
                                    onChange={(event) =>
                                        updateFilters(
                                            Number(event.target.value.replace('-', '')),
                                            'volume_from',
                                        )
                                    }
                                    key={'51a1fbc7-7814-4c60-a2d5-82ccc56c4fcf'}
                                />
                                <Input
                                    className={style.certificateNumberInputShort}
                                    placeholder="По"
                                    type="number"
                                    value={filters.volume_to || null}
                                    min={0}
                                    onChange={(event) =>
                                        updateFilters(
                                            Number(event.target.value.replace('-', '')),
                                            'volume_to',
                                        )
                                    }
                                    key={'07f33959-f90a-4d73-91b2-16d6910f8746'}
                                />
                            </Box>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                    key={'a5ab9c40-c02f-4917-b24a-989630a92fc3'}
                                />
                            </div>
                        </div>
                    </>
                ) : isAdminType ? (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '296px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                    key={'233f5cee-3a4f-4eb1-adc0-0f2edf0d92a4'}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>ЗГО</div>
                            <DropdownSelector2
                                customStyle={{ width: '296px' }}
                                options={generatorsOptions?.map((item: any) => {
                                    return {
                                        text: item.name,
                                        value: item.id,
                                        id: item.id,
                                    }
                                })}
                                placeholder="Выберите ЗГО"
                                onChange={(value) => updateFilters(value, 'generator_id')}
                                key={'a70942dd-4f6d-4900-8b32-65c987abfa76'}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.leftGapBlock}>
                            <div>Вид ВИЭ</div>
                            <div className={style.inputBlock}>
                                <DropdownSelector2
                                    customStyle={{ width: '233px' }}
                                    options={realRenewableEnergyTypesOptions}
                                    onChange={(value) =>
                                        updateFilters(value, 'renewable_energy_type_id')
                                    }
                                    key={'139ff292-6940-4774-983c-b5816c505756'}
                                />
                            </div>
                        </div>
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>ЗГО</div>
                            <DropdownSelector2
                                customStyle={{ width: '233px', marginTop: '2px' }}
                                options={generatorsOptions?.map((item: any) => {
                                    return {
                                        text: item.name,
                                        value: item.id,
                                        id: item.id,
                                    }
                                })}
                                placeholder="Выберите ЗГО"
                                onChange={(value) => updateFilters(value, 'generator_id')}
                                key={'57efa0c6-6977-4d17-8963-22254763138b'}
                            />
                        </div>
                        <div className={style.leftGapBlock}>
                            <div className={style.label}>Период производства</div>
                            <DatePicker
                                className={style.select}
                                onChange={(value) => {
                                    updateFilters(
                                        {
                                            month: moment(value).month() + 1,
                                            year: moment(value).year(),
                                        },
                                        'date',
                                    )
                                }}
                                key={'10fe8ce1-26d5-4c3d-b655-354483c7d273'}
                            />
                        </div>
                        <div className={style.leftGapBlock}>
                            <div>Дата создания</div>
                            <div className={style.inputBlock}>
                                <TimeRangePicker
                                    onlyBeforeDateAndToday={moment()}
                                    style={{ width: '233px' }}
                                    onChange={onTimeRangePickerChange(
                                        isThirdParty
                                            ? ['created_from', 'created_to']
                                            : ['created_ts_from', 'created_ts_to'],
                                    )}
                                    key={'a4998fdb-045a-44bb-a5aa-5bd27c8a4c08'}
                                />
                            </div>
                        </div>
                    </>
                )}
                {!isAdminType && isCertificate && (
                    <div className={style.leftGapBlock}>
                        <div className={style.label}>Залог</div>
                        <DropdownSelector2
                            customStyle={{ width: '296px' }}
                            options={depositOptions}
                            onChange={(value) => {
                                const returnedValue = value === 'Все' ? null : true
                                updateFilters(returnedValue, 'is_deposit')
                            }}
                            key={'26bce180-f946-400f-96e7-2a15cbea6f38'}
                        />
                    </div>
                )}
            </div>
            {(isCertificate || (isThirdParty && isAdminType)) && (
                <MoreFilters
                    toolType={toolType}
                    updateFilters={updateFilters}
                    generators={generatorsResult?.generators}
                    isAdminType={isAdminType}
                    onTimeRangePickerChange={onTimeRangePickerChange}
                />
            )}

            <div className={style.selectorWrapper}>
                <div className={style.selector}>
                    {options?.map((item) => {
                        const { label, id } = item
                        return (
                            <Box direction="row" key={id}>
                                <div
                                    className={
                                        currentOption === label
                                            ? style.greenToolSelected
                                            : style.greenTool
                                    }
                                    onClick={() => onChange(label)}
                                >
                                    {label}
                                </div>
                                <div className={style.spaceBar}></div>
                            </Box>
                        )
                    })}
                    <div className={style.total}>{`Всего: ${total}`}</div>
                </div>
                {isUserAdmin && (
                    <ImportButton
                        value={'Выгрузить данные'}
                        icon={<Import />}
                        onClick={getFile}
                        customIconStyle={{ marginTop: '3px' }}
                        isDisabled
                    />
                )}
            </div>
        </div>
    )
}

export default ControlsBlock
