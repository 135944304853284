import { DatePicker } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Graph from './Graph'
import style from './style.m.less'

const { RangePicker } = DatePicker
const dateFormat = 'DD.MM.YYYY'

const GraphFrame: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.graphFrameMain}>
            <div className={style.headerRow}>
                <div>
                    <span className={style.headerPrimary}>{t('main.graphVolume')}</span>
                    <span className={style.headerSecondary}>({t('main.scale')})</span>
                </div>
                <div className={style.filters}>
                    <RangePicker
                        defaultValue={[
                            moment('01.01.2022', dateFormat),
                            moment('01.07.2022', dateFormat),
                        ]}
                        format={dateFormat}
                    />
                </div>
            </div>
            <div className={style.graph}>
                <Graph />
            </div>
        </div>
    )
}

export default GraphFrame
