import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from 'antd'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'
import style from './style.m.less'

interface IChangeRoleModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const ChangeRoleModal: FC<IChangeRoleModal> = ({ isModalVisible, setIsModalVisible }) => {
    const navigate = useNavigate()

    const handleOk = () => {
        setIsModalVisible(false)
    }
    const goToRoleChange = () => {
        navigate('/change-role')
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>Сменить роль</div>
            <div className={style.modalText}>
                Перед тем как сменить роль в Системе, пожалуйста,
                <br /> ознакомьтесь с Инструкцией
            </div>
            <Checkbox checked className={style.checkbox}>
                Ознакомлен с Инструкцией
            </Checkbox>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={goToRoleChange}
                    cancelAction={handleOk}
                    confirmTitle="Продолжить"
                />
            </div>
        </Modal>
    )
}

export default ChangeRoleModal
