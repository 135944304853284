// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editMain_f9mOx {\n  padding: 40px 80px 100px 80px;\n  width: 1172px;\n}\n.editFrame__ZnPe {\n  margin-top: 32px;\n  padding: 40px 32px 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.input_oc7Qt {\n  margin-left: 16px;\n  width: 400px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/TSOProfile/components/Password/style.m.less"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,aAAA;AACJ;AAEA;EACI,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AAAJ;AAGA;EACI,iBAAA;EACA,YAAA;AADJ","sourcesContent":[".editMain {\n    padding: 40px 80px 100px 80px;\n    width: 1172px;\n}\n\n.editFrame {\n    margin-top: 32px;\n    padding: 40px 32px 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.input {\n    margin-left: 16px;\n    width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editMain": "editMain_f9mOx",
	"editFrame": "editFrame__ZnPe",
	"input": "input_oc7Qt"
};
export default ___CSS_LOADER_EXPORT___;
