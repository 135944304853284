import style from './style.m.less'

type Props = { color?: string }

const BulletDot = ({ color }: Props) => (
    <div className={style.bullet} style={{ color: color ? color : undefined }}>
        •
    </div>
)

export default BulletDot
