import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../ui'
import style from './style.m.less'

interface IButtonsBlock {
    confirmAction?: () => void
    cancelAction?: () => void
    confirmTitle?: string
    cancelTitle?: string
    margin?: boolean
    isDelete?: boolean
    disableCondition?: boolean
    cancelDisabled?: boolean
    confirmDisabled?: boolean
}

const ButtonsBlock: FC<IButtonsBlock> = ({
    confirmTitle,
    cancelTitle,
    confirmAction,
    cancelAction,
    isDelete,
    margin,
    disableCondition,
    cancelDisabled,
    confirmDisabled,
}) => {
    const { t } = useTranslation()

    return (
        <div className={margin ? style.buttonsBlockMargin : style.buttonsBlock}>
            <PrimaryButton
                disabled={cancelDisabled}
                onClick={cancelAction}
                value={cancelTitle ? cancelTitle : 'Отмена'}
                isCancel
                customStyle={{ padding: '8px 16px' }}
            />
            <PrimaryButton
                disabled={confirmDisabled || disableCondition}
                onClick={confirmAction}
                isDelete={isDelete}
                value={confirmTitle ? confirmTitle : t('edit.save')}
                customStyle={{ marginLeft: '12px', padding: '8px 16px' }}
            />
        </div>
    )
}

export default ButtonsBlock
