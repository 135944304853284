// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".questionnaireTitle_F_3P8 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.verticalLine_ZLu1e {\n  width: 100%;\n  height: 1px;\n  background-color: #F0F0F0;\n  margin: 24px 0;\n}\n.questionnaireTitleWrapper_K7crD {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n  margin-bottom: 24px;\n}\n.questionnaireItem_FLlVa {\n  margin-bottom: 16px;\n}\n.questionnaireItemWrapper_bmh8D {\n  display: flex;\n  gap: 24px;\n  margin-bottom: 16px;\n}\n.questionnaireItemName_dV78E {\n  width: 220px;\n  color: #8C8C8C;\n}\n.questionnaireGreyText_mOmJF {\n  color: #8C8C8C;\n}\n.infoWithHide_YvOIc {\n  line-height: 28px;\n  white-space: pre-wrap;\n  width: 788px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/QuestionnaireApprovalItem/components/Questionnaire/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,WAAA;EACA,WAAA;EACA,yBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AADJ;AAIA;EACI,mBAAA;AAFJ;AAKA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AAHJ;AAMA;EACI,YAAA;EACA,cAAA;AAJJ;AAOA;EACI,cAAA;AALJ;AAQA;EACI,iBAAA;EACA,qBAAA;EACA,YAAA;AANJ","sourcesContent":[".questionnaireTitle {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.verticalLine {\n    width: 100%;\n    height: 1px;\n    background-color: #F0F0F0;\n    margin: 24px 0;\n}\n\n.questionnaireTitleWrapper {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n.questionnaireItem {\n    margin-bottom: 16px;\n}\n\n.questionnaireItemWrapper {\n    display: flex;\n    gap: 24px;\n    margin-bottom: 16px;\n}\n\n.questionnaireItemName {\n    width: 220px;\n    color: #8C8C8C;\n}\n\n.questionnaireGreyText {\n    color: #8C8C8C;\n}\n\n.infoWithHide {\n    line-height: 28px;\n    white-space: pre-wrap;\n    width: 788px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"questionnaireTitle": "questionnaireTitle_F_3P8",
	"verticalLine": "verticalLine_ZLu1e",
	"questionnaireTitleWrapper": "questionnaireTitleWrapper_K7crD",
	"questionnaireItem": "questionnaireItem_FLlVa",
	"questionnaireItemWrapper": "questionnaireItemWrapper_bmh8D",
	"questionnaireItemName": "questionnaireItemName_dV78E",
	"questionnaireGreyText": "questionnaireGreyText_mOmJF",
	"infoWithHide": "infoWithHide_YvOIc"
};
export default ___CSS_LOADER_EXPORT___;
