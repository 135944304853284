import { FC, useEffect } from 'react'
import { Tabs } from 'antd'
import { IconButton, CenteredSpin } from '../../../ui'
import { Receipt, Drop, DoubleSave, AddCircle } from '../../../../assets/icons'
import style from './style.m.less'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import ActiveGenerators from './ActiveGenerators'
import NonActiveGenerators from './NonActiveGenerators'
import FiledGenerators from './FiledGenerators'
import { getDictionaries } from '../../../../store/dictionaries'
import { EUserRole } from '../../../../constants/approval'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import useMount from '../../../hooks/useMount'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'

const { TabPane } = Tabs

export enum ETab {
    Active = 'Active',
    NonActive = 'NonActive',
    Filed = 'Filed',
}

const tabsList = [
    { label: 'Активированные', key: ETab.Active, content: <ActiveGenerators /> },
    { label: 'Неактивированные', key: ETab.NonActive, content: <NonActiveGenerators /> },
    { label: 'Поданные', key: ETab.Filed, content: <FiledGenerators /> },
]

const MyGenerators: FC = () => {
    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const dispatch = useAppDispatch()
    const {
        fetch,
        result,
        isLoading: isLoadingGenerators,
    } = useRequest({
        link: '/api/fuel/v1/gos',
        method: RequestTypes.get,
    })

    useMount(() => {
        fetch({})
    })

    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [isLoading])

    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isAdminOrGOOwner =
        userRole === EUserRole.SuperAdmin ||
        userRole === EUserRole.Administrator ||
        userRole === EUserRole.Operator ||
        userRole === EUserRole.GOOwner
    const isGOOwner = userRole === EUserRole.GOOwner
    const { tab = ETab.Active } = useParams<{ tab?: ETab }>()
    const navigate = useNavigate()

    if (isLoadingGenerators || !result) {
        return <CenteredSpin />
    }

    return (
        <div className={style.generatorsMain}>
            <div className={style.mainHeader}>Мои генерирующие объекты</div>
            <div className={style.mainWrapper}>
                <div className={style.controlsWrapper}>
                    <Link to="/ku-owner">
                        <IconButton
                            customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                            icon={<Receipt />}
                        >
                            Подать данные по КУ
                        </IconButton>
                    </Link>
                    {isAdminOrGOOwner && (
                        <Link to="/go-ku-submit-fuel">
                            <IconButton
                                customStyle={{ marginLeft: '16px' }}
                                customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                                icon={<Drop />}
                                disabled={!result?.gos}
                            >
                                Подать данные по топливу
                            </IconButton>
                        </Link>
                    )}
                    {!isGOOwner && (
                        <IconButton
                            customStyle={{ marginLeft: '16px' }}
                            customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                            icon={<DoubleSave />}
                        >
                            Подать данные по ЗД
                        </IconButton>
                    )}
                    {!isGOOwner && (
                        <Link to="/adding-go">
                            <IconButton
                                customStyle={{ marginLeft: '16px' }}
                                customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                                icon={<AddCircle />}
                            >
                                Добавить ГО
                            </IconButton>
                        </Link>
                    )}
                </div>
                <Tabs
                    activeKey={tab}
                    className={style.tabs}
                    onChange={(tab) => navigate(`/my-generators/${tab}`)}
                >
                    {tabsList.map((item) => {
                        const { label, key, content } = item

                        return (
                            <TabPane tab={label} key={key}>
                                {content}
                            </TabPane>
                        )
                    })}
                </Tabs>
            </div>
        </div>
    )
}

export default MyGenerators
