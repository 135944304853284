import { FC } from 'react'
import { Divider } from 'antd'
import { DoubleSave, EmptyWallet } from '../../../../../assets/icons'
import { IOperation } from '../../types'
import moment from 'moment'
import style from './style.m.less'

interface IHistoryBlock {
    iconMargin: boolean
    flexStart: boolean
    date: string
    operations: IOperation[]
}

const HistoryBlock: FC<IHistoryBlock> = ({ date, operations, flexStart, iconMargin }) => {
    const dateArr = date.split(/,(.*)/s)

    return (
        <div className={style.historyBlockMain}>
            <div className={style.date}>
                <div className={style.dateLabel} style={{ whiteSpace: 'nowrap' }}>
                    {`${dateArr[0]}`}
                    <div className={style.current}>{` ,${dateArr[1]}`}</div>
                </div>
                <Divider style={{ minWidth: 'auto', margin: '12px 0', width: '67%' }} />
            </div>
            {operations?.map((item, index) => {
                const { type, time, value } = item

                const operationName =
                    type === 'repayment'
                        ? 'Погашение сертификата происхождения'
                        : type === 'refill'
                        ? 'Пополнение платежного счета'
                        : 'Деление сертификата происхождения'
                return (
                    <div
                        className={flexStart ? style.myOperationMain : style.operationMain}
                        key={index}
                    >
                        <div className={flexStart ? style.myOperationRow : style.operationRow}>
                            {type === 'refill' ? (
                                <div className={style.iconBlockGreen}>
                                    <EmptyWallet size={16} />
                                </div>
                            ) : (
                                <div className={style.iconBlockBlue}>
                                    <DoubleSave size={15} />
                                </div>
                            )}

                            <div className={iconMargin ? style.myInfoBlock : style.infoBlock}>
                                <div>{operationName}</div>
                                <div className={style.time}>{moment(time).format('HH:mm')}</div>
                            </div>
                        </div>
                        <div className={type === 'refill' ? style.valueGreen : style.value}>
                            {value}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default HistoryBlock
