// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".typeTag_XAupo {\n  border: 0;\n  border-radius: 12px;\n  height: 22px;\n  font-weight: normal;\n}\n", "",{"version":3,"sources":["webpack://./src/components/RelatedZGO/components/PowerTypeTag/style.m.less"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AACJ","sourcesContent":[".typeTag {\n    border: 0;\n    border-radius: 12px;\n    height: 22px;\n    font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeTag": "typeTag_XAupo"
};
export default ___CSS_LOADER_EXPORT___;
