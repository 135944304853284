import { CenteredSpin, DropdownSelector2, IconButton, TimeRangePicker } from '../../../ui'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import style from './style.m.less'
import { Input } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { getDictionaries, selectDictionariesOptions } from '../../../../store/dictionaries'
import TagSelector from '../TagSelector/TagSelector'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import { EGoStatusType, EUserRole, goStatusTypeMap } from '../../../../constants/approval'
import classNames from 'classnames'

export interface IFilterValue {
    renewableEnergyType: number[]
    totalPowerFrom: number
    totalPowerTo: number
    lastCheckDate: [moment.Moment, moment.Moment] | null
    qualificationDate: [moment.Moment, moment.Moment] | null
    regionType: number
    statusType: EGoStatusType
}

interface IFilters {
    values: IFilterValue
    onChange(values: IFilterValue): void
    name: string
    onChangeUserName: (name: string) => void
    isAuth: boolean
}

const unitTypes = [
    { value: 'МВт', id: 'МВт' },
    { value: 'КВт', id: 'КВт' },
]

const goStatusTypeOptions = [
    {
        id: EGoStatusType.All,
        value: EGoStatusType.All,
        text: goStatusTypeMap.get(EGoStatusType.All),
    },
    {
        id: EGoStatusType.Blocked,
        value: EGoStatusType.Blocked,
        text: goStatusTypeMap.get(EGoStatusType.Blocked),
    },
    {
        id: EGoStatusType.NotBlocked,
        value: EGoStatusType.NotBlocked,
        text: goStatusTypeMap.get(EGoStatusType.NotBlocked),
    },
]

const Filters: FC<IFilters> = ({ values, onChange, name, onChangeUserName, isAuth }) => {
    const { t } = useTranslation()

    const userRole: EUserRole = useAppSelector((state) => state.user.data.user_role)

    const showNameSearch = [
        EUserRole.Administrator,
        EUserRole.Operator,
        EUserRole.SuperAdmin,
    ].includes(userRole)

    const {
        renewableEnergyType,
        totalPowerFrom,
        totalPowerTo,
        lastCheckDate,
        qualificationDate,
        regionType,
        statusType,
    } = values

    const isLoading = useAppSelector((state) => state.dictionaries.loading)

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [])

    const [powerMeasuremen, setPowerMeasuremen] = useState(unitTypes[0].id)

    const dictionaries = useAppSelector(selectDictionariesOptions)

    const { regionsOptions, renewableEnergyTypesOptions } = dictionaries ?? {}

    const realRegionsOptions = useMemo(
        () =>
            regionsOptions
                ? [{ id: 0, value: 0, text: 'Любой', label: 'Любой' }, ...regionsOptions]
                : [{ id: 0, value: 0, text: 'Любой', label: 'Любой' }],
        [regionsOptions],
    )

    if (!dictionaries) {
        return <CenteredSpin />
    }

    const realTotalPowerFrom = powerMeasuremen === 'МВт' ? totalPowerFrom / 1000 : totalPowerFrom
    const realTotalPowerTo = powerMeasuremen === 'МВт' ? totalPowerTo / 1000 : totalPowerTo

    return (
        <div className={style.filtersMain}>
            {showNameSearch && (
                <>
                    <div>Название ЗГО</div>
                    <Input
                        value={name}
                        onChange={(event) => onChangeUserName(event.target.value)}
                        className={style.input}
                        placeholder="Введите название ЗГО"
                    />
                </>
            )}
            <div className={classNames({ [style.filterTitle]: showNameSearch })}>Вид энергии</div>
            <TagSelector
                value={renewableEnergyType}
                onChange={(renewableEnergyType) =>
                    onChange({
                        ...values,
                        renewableEnergyType,
                    })
                }
                customStyle={{ marginTop: 0 }}
                options={renewableEnergyTypesOptions}
                showSearch={false}
            />
            <div className={style.filterTitle}>Мощность</div>
            <DropdownSelector2
                onChange={(e) => setPowerMeasuremen(e)}
                options={unitTypes}
                customStyle={{ width: '100%' }}
            />
            <div className={style.inputWrapper}>
                <Input
                    value={realTotalPowerFrom}
                    onChange={(e) => {
                        const value = Math.max(+e.target.value || 0, 0)
                        onChange({
                            ...values,
                            totalPowerFrom: powerMeasuremen === 'МВт' ? value * 1000 : value,
                        })
                    }}
                    className={style.input}
                />
                <Input
                    value={realTotalPowerTo}
                    onChange={(e) => {
                        const value = Math.max(+e.target.value || 0, 0)
                        onChange({
                            ...values,
                            totalPowerTo: powerMeasuremen === 'МВт' ? value * 1000 : value,
                        })
                    }}
                    className={style.input}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Дата квалификации</div>
                <TimeRangePicker
                    value={qualificationDate}
                    onChange={(qualificationDate) =>
                        onChange({
                            ...values,
                            qualificationDate,
                        })
                    }
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Дата последней проверки</div>
                <TimeRangePicker
                    value={lastCheckDate}
                    onChange={(lastCheckDate) =>
                        onChange({
                            ...values,
                            lastCheckDate,
                        })
                    }
                />
            </div>
            <div className={style.filterTitle}>Субъект РФ (регион)</div>
            <DropdownSelector2
                value={regionType}
                options={realRegionsOptions}
                placeholder={t('filters.chooseRegion')}
                customStyle={{ width: '100%' }}
                onChange={(regionType) => {
                    onChange({
                        ...values,
                        regionType,
                    })
                }}
            />
            {isAuth && (
                <>
                    <div className={style.filterTitle}>Статус блокировки</div>
                    <DropdownSelector2
                        value={statusType}
                        options={goStatusTypeOptions}
                        onChange={(statusType) => {
                            onChange({
                                ...values,
                                statusType,
                            })
                        }}
                        customStyle={{ width: '100%' }}
                    />
                </>
            )}

            <div className={style.resetButton}>
                <IconButton
                    onClick={() => {
                        onChange({
                            renewableEnergyType: [],
                            totalPowerFrom: 0,
                            totalPowerTo: 0,
                            lastCheckDate: null,
                            qualificationDate: null,
                            regionType: 0,
                            statusType: EGoStatusType.All,
                        })
                        onChangeUserName('')
                        setPowerMeasuremen('МВт')
                    }}
                >
                    Сбросить фильтры
                </IconButton>
            </div>
        </div>
    )
}

export default Filters
