// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main_XxVy9 {\n  margin-top: 16px;\n  background: #ffffff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n  padding: 24px 32px;\n  width: 1172px;\n}\n.header_ML9CL {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.freeGenerationAttributes_jAE_Y {\n  margin-top: 16px;\n  width: max-content;\n  padding: 6px 12px;\n  border: 1px solid #FFCCC7;\n  border-radius: 12px;\n  font-size: 16px;\n  line-height: 24px;\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n.buttonsBlock_rUx3K {\n  margin-top: 24px;\n  display: flex;\n  align-items: center;\n}\n.divider_PwvEC {\n  height: 22px;\n  margin: 0 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/BlockedGenerationAttributes/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,8CAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AADJ;AAIA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;AAFJ;AAKA;EACI,YAAA;EACA,cAAA;AAHJ","sourcesContent":[".main {\n    margin-top: 16px;\n    background: #ffffff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n    padding: 24px 32px;\n    width: 1172px;\n}\n\n.header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.freeGenerationAttributes {\n    margin-top: 16px;\n    width: max-content;\n    padding: 6px 12px;\n    border: 1px solid #FFCCC7;\n    border-radius: 12px;\n    font-size: 16px;\n    line-height: 24px;\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n.buttonsBlock {\n    margin-top: 24px;\n    display: flex;\n    align-items: center;\n}\n\n.divider {\n    height: 22px;\n    margin: 0 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "main_XxVy9",
	"header": "header_ML9CL",
	"freeGenerationAttributes": "freeGenerationAttributes_jAE_Y",
	"buttonsBlock": "buttonsBlock_rUx3K",
	"divider": "divider_PwvEC"
};
export default ___CSS_LOADER_EXPORT___;
