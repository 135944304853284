// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input_ucc2n {\n  border-radius: 8px;\n}\n.formBlock_Gp0gj {\n  margin-top: 32px;\n}\n.formBlockShort_bvtm7 {\n  margin-top: 24px;\n}\n.captcha_FhexQ {\n  margin-top: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Login/components/Register/components/StepOne/style.m.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAEI;EACI,gBAAA;AAAR;AAIA;EACI,eAAA;AAFJ","sourcesContent":[".input {\n    border-radius: 8px;\n}\n\n.formBlock {\n    margin-top: 32px;\n\n    &Short {\n        margin-top: 24px;\n    }\n}\n\n.captcha {\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input_ucc2n",
	"formBlock": "formBlock_Gp0gj",
	"formBlockShort": "formBlockShort_bvtm7",
	"captcha": "captcha_FhexQ"
};
export default ___CSS_LOADER_EXPORT___;
