import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

interface IOwnerBlock {
    ownerInfo: any
}

const OwnerBlock: FC<IOwnerBlock> = ({ ownerInfo }) => {
    const { t } = useTranslation()

    const { isUserAdmin } = useCurrentUser()
    const { user_type, company_name_short, first_name, last_name, middle_name, user_pub_id, uid } =
        ownerInfo
    const goOwnerName =
        user_type === 2
            ? company_name_short
            : user_type === 1
            ? `ИП ${last_name} ${first_name} ${middle_name}`
            : `${last_name} ${first_name} ${middle_name}`

    return (
        <div className={style.repaymentBlock}>
            <div className={style.repaymentHeader}>{t('certificate.ownerInfo')}</div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Текущий владелец ЗИ</div>
                {!isUserAdmin ? (
                    <div className={style.nameBlock}>{goOwnerName}</div>
                ) : (
                    <Link to={`/user/${uid}`} className={style.link}>
                        {goOwnerName}
                    </Link>
                )}
            </div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Уникальный номер пользователя</div>
                <div className={style.nameBlock}>{user_pub_id}</div>
            </div>
        </div>
    )
}

export default OwnerBlock
