import { FC } from 'react'
import { Modal, PrimaryButton, LoadingContainer, Box } from '../../../../../ui'
import moment from 'moment'
import { useGetTariffRate } from '../../../../../hooks'
import useRequest, { RequestTypes } from '../../../../../hooks/useRequest'
import style from './style.m.less'

interface IPaymentModal {
    isModalVisible: boolean
    onCancel: (reload?: boolean) => void
    data: any
}

const PaymentModal: FC<IPaymentModal> = ({ isModalVisible, onCancel, data }) => {
    const { fetch, isLoading } = useRequest({
        link: `/api/green-tools/v1/accounts/pay?account_id=${data.id}`,
        method: RequestTypes.get,
    })

    const onConfirm = () => {
        fetch({
            successMessage: {
                title: 'Успешно',
                description: `Комиссия за обслуживание ЛС успешно оплачена`,
            },
            onSuccess() {
                onCancel(true)
            },
            errorCodeMessage: true,
            onError() {
                onCancel(false)
            },
        })
    }

    const { loadingTariffs, balance, rate } = useGetTariffRate({
        dependency: isModalVisible,
        id: 50,
        tsPayed: moment(data.ts_payed_account).unix(),
    })

    return (
        <LoadingContainer isLoading={loadingTariffs || isLoading}>
            <Modal isModalVisible={isModalVisible} onCancel={onCancel}>
                <div className={style.modal}>
                    <div className={style.modalWrapper}>
                        <div className={style.header}>{'Оплата комиссии'}</div>
                    </div>
                    <Box style={{ marginTop: '16px' }}>
                        <Box direction="row">
                            <div className={style.leftSide}>Номер счета</div>
                            <div className={style.rightSide}>{`№ ${data.number}`}</div>
                        </Box>
                        <div className={style.wrapper}>
                            <div className={style.leftSide}>Название счета</div>
                            <div className={style.rightSide}>{data.name}</div>
                        </div>
                        <div className={style.wrapper}>
                            <div className={style.leftSide}>Период обслуживания</div>
                            <div className={style.rightSide}>
                                {`${moment(data.ts_paid_from).format('DD.MM.YYYY')} - ${moment(
                                    data.ts_payed_account,
                                ).format('DD.MM.YYYY')}`}
                            </div>
                        </div>
                    </Box>
                    <div className={style.payAlert}>
                        <div className={style.payHeader}>
                            <span className={style.alertMessage}>Сумма комиссии составила </span>
                            <span className={style.alertPrice}>{rate}</span>
                        </div>
                        <div className={style.payText}>
                            <span className={style.alertDetail}>Баланс платежного счета</span>
                            <span className={style.alertDetail}> {balance}</span>
                        </div>
                    </div>
                    <div className={style.buttonsBlock}>
                        <PrimaryButton
                            onClick={onCancel}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryButton
                            onClick={onConfirm}
                            value="Оплатить"
                            customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                        />
                    </div>
                </div>
            </Modal>
        </LoadingContainer>
    )
}

export default PaymentModal
