import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Close: FC<IIconProps> = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.75609 3.57757C4.43065 3.25214 3.90301 3.25214 3.57757 3.57757C3.25214 3.90301 3.25214 4.43065 3.57757 4.75609L8.82165 10.0002L3.57757 15.2442C3.25214 15.5697 3.25214 16.0973 3.57757 16.4228C3.90301 16.7482 4.43065 16.7482 4.75609 16.4228L10.0002 11.1787L15.2442 16.4228C15.5697 16.7482 16.0973 16.7482 16.4228 16.4228C16.7482 16.0973 16.7482 15.5697 16.4228 15.2442L11.1787 10.0002L16.4228 4.75609C16.7482 4.43065 16.7482 3.90301 16.4228 3.57757C16.0973 3.25214 15.5697 3.25214 15.2442 3.57757L10.0002 8.82165L4.75609 3.57757Z"
                    fill="currentColor"
                />
            </svg>
        )}
    />
)
