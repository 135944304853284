import { FC, useEffect, useState } from 'react'
import { Divider } from 'antd'
import { Pagination, CenteredSpin } from '../../../ui'
import style from './style.m.less'
import request from '../../../../services/request'
import { FilterSubmitted, GeneratorCardFiled } from '../../components'
import { IGeneratorFiled } from '../../components/GeneratorCard/GeneratorCardFiled'
import { EGoFiledStatusType } from '../../components/FilterSubmitted/FilterSubmitted'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import { useAppSelector } from '../../../../store/hooks'

interface IGenaratorFiledData {
    gos_filed: IGeneratorFiled[]
    total: number
}

const PAGE_SIZE = 10

const FiledGenerators: FC = () => {
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)

    const [generators, setGenerators] = useState<IGenaratorFiledData>()
    const [loading, setLoading] = useState(true)

    const [energyType, setEnergyType] = useState<number>()
    const [goStatus, setGoStatus] = useState<EGoFiledStatusType>(EGoFiledStatusType.All)
    const [region, setRegion] = useState<number>()

    useEffect(() => {
        const loadGenerators = async () => {
            try {
                setLoading(true)
                const res = await request.post('/api/generators/v1/gos-filed', {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters: {
                        renewable_energy_type_id: energyType,
                        region_id: region,
                        status: goStatus,
                    },
                })
                setGenerators(res.data)
            } finally {
                setLoading(false)
            }
        }
        loadGenerators()
    }, [energyType, goStatus, region, page, pageSize])

    const dictionariesLoading = useAppSelector((state) => state.dictionaries.loading)

    const realLoading = dictionariesLoading !== ELoading.Loaded || loading || !generators

    return (
        <>
            <div className={style.contentWrapper}>
                <FilterSubmitted
                    onChangeEnergyType={setEnergyType}
                    onChangeRegionType={setRegion}
                    onChangeStatus={setGoStatus}
                />
                <Divider />
                <div className={style.cardsBlock}>
                    {realLoading && <CenteredSpin />}
                    {generators?.gos_filed?.map((item, index) => {
                        const { generator_id } = item
                        return (
                            <div key={generator_id}>
                                <GeneratorCardFiled generatorData={item} />
                                {index !== generators.gos_filed.length - 1 && <Divider />}
                            </div>
                        )
                    })}
                </div>
            </div>
            <Pagination
                customStyle={{ width: '100%' }}
                current={page}
                paginationChange={(receivedPage: number, size?: number) => {
                    setPage(receivedPage)
                    setPageSize(size ?? PAGE_SIZE)
                }}
                total={generators?.total ?? 0}
                showSizeChanger
                pageSize={pageSize}
            />
        </>
    )
}

export default FiledGenerators
