// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".locationSelector_Bu26b {\n  margin-top: 12px;\n  width: 100%;\n}\n.selectorError_IuENg {\n  margin-top: 12px;\n  width: 100%;\n  border: 1px solid #ff4d4f;\n  border-radius: 8px;\n}\n.explain_l7xDb {\n  transition: all 0.2s ease-in;\n  margin-top: 2px;\n  color: #ff4d4f;\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/ValueSelector/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;AACJ;AAEA;EACI,gBAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;AAAJ;AAGA;EACI,4BAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;AADJ","sourcesContent":[".locationSelector {\n    margin-top: 12px;\n    width: 100%;\n}\n\n.selectorError {\n    margin-top: 12px;\n    width: 100%;\n    border: 1px solid #ff4d4f;\n    border-radius: 8px;\n}\n\n.explain {\n    transition: all 0.2s ease-in;\n    margin-top: 2px;\n    color: #ff4d4f;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"locationSelector": "locationSelector_Bu26b",
	"selectorError": "selectorError_IuENg",
	"explain": "explain_l7xDb"
};
export default ___CSS_LOADER_EXPORT___;
