export interface GenerationAttributeItem {
    id: number
    period: string
    volume: number
    owner_id: string
    generator_id: string
    registered_at: string
    expires_at: string
    lock_reason: number
}

export type PaginatedList<Result> = {
    total: number
    list: Result[]
}

export type PaginatedEvents<Result> = {
    total: number
    events: Result[]
}

export type PaymentTypes = {
    id: number
    name: string
}

export type HistoryEvent = {
    event_id: string
    ts_event: string
    type_event_id: number
    volume: number
}

export interface AGTotal {
    free_volume: number
    green_tools_volume: number
    locked_volume: number
    used_volume: number
    total_volume: number
}

export interface GOListItem {
    generator_id: string
    name: string
    ga_available: number
}

export interface GOList {
    gos_ga: GOListItem[]
}

export enum PaymentStatuses {
    Paid = 2,
    NotPaid = 1,
    Blocked = 0,
    Burnt = 3,
}

export interface AGGOCard {
    id: number
    free_volume: number
    green_tools_volume: number
    status: PaymentStatuses
    locked_volume: number
    used_volume: number
    total_volume: number
    period: string
    owner_id: string
    generator_id: string
    registered_at: string
    expires_at: string
    lock_reason: number
    fee: number
    go_name: string
}
