// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sortSwitch_BDuGH {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n.sortButton_HpNmP {\n  width: 32px;\n  height: 32px;\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/SortSwitch/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,QAAA;EACA,mBAAA;AACJ;AAEA;EACI,WAAA;EACA,YAAA;EACA,UAAA;AAAJ","sourcesContent":[".sortSwitch {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n\n.sortButton {\n    width: 32px;\n    height: 32px;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortSwitch": "sortSwitch_BDuGH",
	"sortButton": "sortButton_HpNmP"
};
export default ___CSS_LOADER_EXPORT___;
