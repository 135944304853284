import { FC } from 'react'
import classNames from 'classnames'
import style from './style.m.less'

type ButtonProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> & {
    value: string
    customStyle?: React.CSSProperties
    onClick?: () => void
    isCancel?: boolean
    isDelete?: boolean
    disabled?: boolean
    large?: boolean
}

const PrimaryButton: FC<ButtonProps> = ({
    value,
    customStyle,
    isCancel,
    isDelete,
    onClick,
    disabled,
    large,
    ...rest
}) => {
    return (
        <button
            className={classNames(
                isCancel
                    ? style.primaryButtonCancel
                    : disabled
                    ? style.primaryButtonDisabled
                    : isDelete
                    ? style.primaryButtonDelete
                    : style.primaryButton,
                { [style.large]: large },
            )}
            disabled={disabled}
            style={customStyle}
            type={isCancel ? 'button' : undefined}
            onClick={() => (disabled || !onClick ? null : onClick())}
            {...rest}
        >
            {value}
        </button>
    )
}

// кнопка в разделе Новости и события, и Документы и платежи
export const GreyButton: FC<ButtonProps> = ({
    value,
    customStyle,
    isCancel,
    isDelete,
    onClick,
    disabled,
    large,
    ...rest
}) => {
    return (
        <button
            className={style.greyButton}
            disabled={disabled}
            style={customStyle}
            onClick={() => (disabled || !onClick ? null : onClick())}
            {...rest}
        >
            {value}
        </button>
    )
}

export default PrimaryButton
