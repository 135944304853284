import { FC } from 'react'
import { CheckCircle } from '../../../../assets/icons'
import style from './style.m.less'

interface ISuccessMessage {
    header: string
    message: string
}

const SuccessMessage: FC<ISuccessMessage> = ({ header, message }) => {
    return (
        <div className={style.mainWrapper}>
            <CheckCircle />
            <div className={style.messageWrapper}>
                <div className={style.messageHeader}>{header}</div>
                <div className={style.messageContent}>{message}</div>
            </div>
        </div>
    )
}

export default SuccessMessage
