import { Input } from 'antd'
import React from 'react'

type Props = {
    onChange: (value: number) => void
    value?: any
    placeholder?: string
    className?: string
    style?: React.CSSProperties
    maxLength?: number
}

const InputOnlyNumber = ({ onChange, value, placeholder, className, style, maxLength }: Props) => {
    return (
        <Input
            className={className}
            placeholder={placeholder}
            type="number"
            value={value}
            style={style}
            onKeyDown={(event: any) => {
                if (event.key === 'Backspace') {
                    return
                }
                if (maxLength && maxLength < event.target.value.length) {
                    event.preventDefault()
                }
                if (/[^0-9]/.test(event.key)) {
                    event.preventDefault()
                }
            }}
            onChange={(event: any) => onChange(Math.abs(Number(event.target.value)))}
        />
    )
}

export default InputOnlyNumber
