import { Alert, Modal, PrimaryButton } from '../../../ui'
import { FC } from 'react'

import style from './style.m.less'
import { useTranslation } from 'react-i18next'

interface SendNotificationUserProps {
    isModalVisible: boolean
    fio?: string
    userRole?: string
    login?: string
    email?: string
    onCancel: () => void
    onOk: () => void
}

const SendNotificationUser: FC<SendNotificationUserProps> = ({
    isModalVisible,
    fio,
    email,
    login,
    userRole,
    onCancel,
    onOk,
}) => {
    const { t } = useTranslation()

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>Отправка уведомления пользователю</div>
                </div>
            </div>
            <ul className={style.list}>
                <li className={style.listItem}>
                    <span className={style.listItemKey}>ФИО</span>
                    <span className={style.listItemValue}>{fio}</span>
                </li>
                <li className={style.listItem}>
                    <span className={style.listItemKey}>Роль в системе</span>
                    <span className={style.listItemValue}>{userRole}</span>
                </li>
                <li className={style.listItem}>
                    <span className={style.listItemKey}>Логин</span>
                    <span className={style.listItemValue}>{login}</span>
                </li>
                <li className={style.listItem}>
                    <span className={style.listItemKey}>E-mail</span>
                    <span className={style.listItemValue}>{email}</span>
                </li>
            </ul>
            <Alert
                message={t('editMyProfile.addUserEmailVerification')}
                type="info"
                showIcon
                className={style.alert}
            />
            <div className={style.bottom}>
                <PrimaryButton
                    onClick={onCancel}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryButton
                    onClick={onOk}
                    isDelete={false}
                    value={'Отправить'}
                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                />
            </div>
        </Modal>
    )
}

export default SendNotificationUser
