import { RequestTypes } from '../hooks/useRequest'

export const GT_API_PREFIX = '/api/green-tools/v1/'
export const CA_API_PREFIX = '/api/ca/v1/'
export const GT_REGISTRY_WM = 'wm/submissions'
export const GT_REGISTRY_RM = 'rm/submission/list'
export const GT_DOCS_WM = 'wm/submission'
export const GT_DOCS_RM = 'rm/submission'
export const CA_AVAILABLE = 'wm/submission-available-admin'

export const getGtRegistryWm = {
    link: `${GT_API_PREFIX}${GT_REGISTRY_WM}`,
    method: RequestTypes.post,
}

export const getGtRegistryRm = {
    link: `${GT_API_PREFIX}${GT_REGISTRY_RM}`,
    method: RequestTypes.post,
}

export const getZdDocsWM = {
    link: `${GT_API_PREFIX}${GT_DOCS_WM}`,
    method: RequestTypes.get,
}

export const getZdDocsRM = {
    link: `${GT_API_PREFIX}${GT_DOCS_RM}`,
    method: RequestTypes.get,
}

export const getSubmissionAvailable = {
    link: `${CA_API_PREFIX}${CA_AVAILABLE}`,
    method: RequestTypes.get,
}
