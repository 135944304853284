import { useNavigate } from 'react-router-dom'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import useMount from '../../../hooks/useMount'
import debounce from 'lodash/debounce'

import style from './style.m.less'
import { Table, Tabs } from 'antd'
import ControlsBlock from '../ControlsBlock/ControlsBlock'
import { useCallback, useEffect, useState } from 'react'
import { columns } from './columns'
import { AGTotal, GenerationAttributeItem, PaginatedList } from '../../types'
import GenerationAttributesHistory from '../GenerationAttributesHistory/GenerationAttributesHistory'

const { TabPane } = Tabs

enum AGListTypes {
    Active = '0',
    Blocked = '1',
    Expired = '2',
}

export type Filters = {
    volume: string
    generator_id: string
    registered_at: string
    period: string
    type: AGListTypes
}

const DEFAULT_PAG = {
    page: 1,
    pageSize: 10,
}

const tabsList = [
    { label: 'АГ помесячно', key: '0' },
    { label: 'История АГ', key: '1' },
]

const innerTabsOptions = [
    { label: 'Активные', id: '0' },
    { label: 'Истек срок действия', id: '2' },
    { label: 'Заблокированные/неоплаченные', id: '1' },
]

const GenerationAttributes = () => {
    const [filters, setFilters] = useState<Filters>({
        volume: '',
        generator_id: '',
        registered_at: '',
        period: '',
        type: AGListTypes.Active,
    })
    const [outerTab, setOuterTab] = useState(tabsList[0].key)
    const [innerTab, setInnerTab] = useState(innerTabsOptions[0].label)
    const [pagination, setPagination] = useState(DEFAULT_PAG)
    const [GOList, setGOList] = useState([])
    const [isLoadingNewData, setIsLoadingNewData] = useState(false)
    const navigate = useNavigate()

    const {
        fetch: getAGList,
        isLoading: isLoadingGetAGList,
        result: AGList,
    } = useRequest<PaginatedList<GenerationAttributeItem>>({
        link: '/api/generation_attributes/v1/gas/ga/list',
        method: RequestTypes.get,
    })

    const {
        fetch: getAGTotal,
        isLoading: isLoadingGetAGTotal,
        result: AGTotalResult,
    } = useRequest<AGTotal>({
        link: '/api/generation_attributes/v1/gas/ga/total',
        method: RequestTypes.get,
    })

    const debouncedGetAGList = useCallback(
        debounce(
            (filters) =>
                getAGList({
                    getParams: {
                        ...filters,
                    },
                }),
            300,
        ),
        [],
    )

    useEffect(() => {
        setIsLoadingNewData(true)
        debouncedGetAGList(filters)
    }, [filters])

    useEffect(() => {
        const mappedGoList = AGList?.list
            .map((item) => ({
                name: item.go_name,
                generator_id: item.generator_id,
            }))
            .filter(
                (value, index, self) =>
                    index ===
                    self.findIndex(
                        (t) => t.generator_id === value.generator_id && t.name === value.name,
                    ),
            )
        setGOList(mappedGoList)
    }, [AGList])

    useMount(() => {
        getAGList({})
        getAGTotal({})
    })

    useEffect(() => {
        getAGList({
            getParams: {
                offset: (pagination.page - 1) * pagination.pageSize,
                limit: pagination.pageSize,
                ...filters,
            },
        })
    }, [pagination])

    const onChangeInnerTab = (option: string) => {
        const typeId = innerTabsOptions.find((item) => item.label === option)?.id

        setFilters((prev) => ({
            ...prev,
            type: typeId as unknown as AGListTypes,
        }))
        setInnerTab(option)
    }

    const AGListWithGoNames = AGList?.list?.map((item) => ({
        ...item,
    }))

    const isBlockedShown = innerTab === innerTabsOptions[2].label

    return (
        <div className={style.mainWrapper}>
            <div className={style.mainHeader}>Мои атрибуты генерации</div>

            <div className={style.tabsWrapper}>
                <Tabs
                    defaultActiveKey="0"
                    onChange={(activeKey) => setOuterTab(activeKey)}
                    className={style.tabs}
                >
                    {tabsList.map(({ key, label }) => (
                        <TabPane tab={label} key={key}>
                            <></>
                        </TabPane>
                    ))}
                </Tabs>
            </div>

            <div>
                <div className={style.gaMetrics}>
                    <div className={style.gaMetricsInner}>
                        <div className={style.gaMetricItem}>
                            <div className={style.gaMetricItemLabel}>АГ в составе ЗИ</div>
                            <div className={style.gaMetricItemValue}>
                                {AGTotalResult?.green_tools_volume}
                            </div>
                        </div>
                        <div className={style.gaMetricItem}>
                            <div className={style.gaMetricItemLabel}>Свободные АГ</div>
                            <div className={style.gaMetricItemValue}>
                                {AGTotalResult?.free_volume}
                            </div>
                        </div>
                        <div className={style.gaMetricItem}>
                            <div className={style.gaMetricItemLabel}>
                                Заблокированные/неоплаченные АГ
                            </div>
                            <div className={style.gaMetricItemValue}>
                                {AGTotalResult?.locked_volume}
                            </div>
                        </div>
                    </div>
                </div>

                {outerTab === '0' && (
                    <div className={style.gaWrapper}>
                        <ControlsBlock
                            onChange={onChangeInnerTab}
                            currentOption={innerTab}
                            options={innerTabsOptions}
                            onChangeFilters={setFilters}
                            goList={GOList || []}
                            filters={filters}
                        />
                        <Table
                            style={{ marginTop: '24px' }}
                            dataSource={AGList ? AGListWithGoNames : []}
                            loading={isLoadingGetAGList}
                            columns={columns(isBlockedShown)}
                            rowClassName="tableRow"
                            onRow={(record) => ({
                                onClick: () => navigate('/generation-attributes/go/' + record.id),
                            })}
                            onChange={(tablePagination) =>
                                setPagination((prev) => ({
                                    ...prev,
                                    page: tablePagination?.current || 1,
                                }))
                            }
                            pagination={{
                                position: ['bottomLeft'],
                                pageSize: pagination.pageSize,
                                current: pagination.page,
                                showSizeChanger: (AGList?.total || 0) > 0,
                                total: AGList?.total || 0,
                                disabled: AGList?.total === 0,
                                onShowSizeChange: (current, size) =>
                                    setPagination((prev) => ({
                                        ...prev,
                                        pageSize: size,
                                    })),
                            }}
                        />
                    </div>
                )}

                {outerTab === '1' && (
                    <div className={style.historyWrapper}>
                        <GenerationAttributesHistory />
                    </div>
                )}
            </div>
        </div>
    )
}

export default GenerationAttributes
