// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".greenToolsMain_JdbAC {\n  display: flex;\n  flex-direction: column;\n  width: 1280px;\n  padding: 40px 0px 100px;\n  margin: 0 auto;\n}\n.mainHeader_tncLY {\n  display: flex;\n  justify-content: space-between;\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.btnRow_tfpHF {\n  position: sticky;\n  height: 70px;\n  right: 0px;\n  bottom: 0px;\n  width: 100%;\n  background: #FFFFFF;\n  box-shadow: 0px 2px 14px rgba(18, 3, 56, 0.1);\n}\n.btnRowContainer_pC8s8 {\n  padding: 16px 0px;\n  display: flex;\n  gap: 16px;\n  width: 1280px;\n  margin: 0 auto;\n}\n.btn_yIuJQ {\n  padding: 8px 16px;\n  height: 38px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UserGreenTools/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,mBAAA;EACA,6CAAA;AADJ;AAIA;EACI,iBAAA;EACA,aAAA;EACA,SAAA;EACA,aAAA;EACA,cAAA;AAFJ;AAKA;EACI,iBAAA;EACA,YAAA;AAHJ","sourcesContent":[".greenToolsMain {\n    display: flex;\n    flex-direction: column;\n    width: 1280px;\n    padding: 40px 0px 100px;\n    margin: 0 auto;\n}\n\n.mainHeader {\n    display: flex;\n    justify-content: space-between;\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.btnRow {\n    position: sticky;\n    height: 70px;\n    right: 0px;\n    bottom: 0px;\n    width: 100%;\n    background: #FFFFFF;\n    box-shadow: 0px 2px 14px rgba(18, 3, 56, 0.1);\n}\n\n.btnRowContainer {\n    padding: 16px 0px;\n    display: flex;\n    gap: 16px;\n    width: 1280px;\n    margin: 0 auto;\n}\n\n.btn {\n    padding: 8px 16px;\n    height: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greenToolsMain": "greenToolsMain_JdbAC",
	"mainHeader": "mainHeader_tncLY",
	"btnRow": "btnRow_tfpHF",
	"btnRowContainer": "btnRowContainer_pC8s8",
	"btn": "btn_yIuJQ"
};
export default ___CSS_LOADER_EXPORT___;
