import { FC } from 'react'
import IconButton, { IIconButton } from './IconButton'

import style from './style.m.less'

const IconButtonSmall: FC<IIconButton> = ({ ...rest }) => {
    return <IconButton {...rest} size="small" className={style.small} />
}

export default IconButtonSmall
