import { FC, useState } from 'react'
import { Card, DropdownSelector, IconButton, ImportButton } from '../ui'
import { Divider, Select, Alert, Input, Radio, RadioChangeEvent, Tooltip } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft, Search, Edit, Trash, AddCircle } from '../../assets/icons'
import SearchConsumer from './components/SearchConsumer/SearchConsumer'
import AddConsumer from './components/AddConsumer/AddConsumer'
import InsufficientFunds from './components/InsufficientFunds/InsufficientFunds'

const options = [
    { id: 'Я', value: 'Я' },
    { id: 'Физическое лицо', value: 'Физическое лицо' },
    { id: 'Юридическое лицо', value: 'Юридическое лицо' },
    { id: 'Индивидуальный предприниматель', value: 'Индивидуальный предприниматель' },
]

const status = 'Физическое лицо'
const isConsumerAdded = true
const isConsumerAddedManually = false

const ImplementationGenerationAttributes: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    const [isSearchConsumerModalVisible, setIsSearchConsumerModalVisible] = useState(false)
    const openSearchConsumerModal = () => {
        setIsSearchConsumerModalVisible(true)
    }

    const [isAddConsumerModalVisible, setIsAddConsumerModalVisible] = useState(false)
    const openAddConsumerModal = () => {
        setIsAddConsumerModalVisible(true)
    }

    const [isInsufficientFundsModalVisible, setIsInsufficientFundsModalVisible] = useState(false)
    const showInsufficientFundsModal = () => {
        setIsInsufficientFundsModalVisible(!isInsufficientFundsModalVisible)
    }

    const [value, setValue] = useState(1)
    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value)
    }

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div className={style.mainHeader}>Реализация атрибутов генерации</div>
            </div>

            <Card className={style.repaymentCard}>
                <div className={style.title}>Выберите потребителя ЭЭ</div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>Потребитель</div>
                    {status === 'initial' && (
                        <Select placeholder="Выберите потребителя" className={style.select} />
                    )}
                    {status !== 'initial' && (
                        <>
                            <DropdownSelector
                                placeholder="Выберите потребителя"
                                options={options}
                                customStyle={{
                                    width: '400px',
                                    background: '#FFFFFF',
                                    border: '1px solid #D9D9D9',
                                    borderRadius: '8px',
                                }}
                            />
                            {isConsumerAdded && status === 'Физическое лицо' && (
                                <Alert
                                    className={style.resultAlert}
                                    message={
                                        <div className={style.alertTitleWrapper}>
                                            <span className={style.alertTitle}>
                                                Ильин Макар Владимирович
                                            </span>
                                            <div className={style.alertIconWrapper}>
                                                <span className={style.icon}>
                                                    <Edit />
                                                </span>
                                                <span className={style.icon}>
                                                    <Trash />
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <>
                                            <span className={style.alertDetail}>
                                                ИНН - 9462618934
                                            </span>
                                            <Divider type="vertical" />
                                            <span className={style.alertDetail}>
                                                СНИЛС - 126-975-800 54
                                            </span>
                                        </>
                                    }
                                    type="info"
                                />
                            )}
                            {isConsumerAdded && status === 'Юридическое лицо' && (
                                <Alert
                                    className={style.resultLegalAlert}
                                    message={
                                        <div className={style.alertTitleWrapper}>
                                            <span className={style.alertTitle}>ООО Ромашка</span>
                                            <div className={style.alertIconWrapper}>
                                                <span className={style.icon}>
                                                    <Edit />
                                                </span>
                                                <span className={style.icon}>
                                                    <Trash />
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <>
                                            <span className={style.alertDetail}>
                                                ИНН - 9462618934
                                            </span>
                                            <Divider type="vertical" />
                                            <span className={style.alertDetail}>
                                                ОГРН - 1234567890987
                                            </span>
                                            <Divider type="vertical" />
                                            <span className={style.alertDetail}>
                                                КПП - 1234567890987
                                            </span>
                                        </>
                                    }
                                    type="info"
                                />
                            )}{' '}
                            {isConsumerAdded && status === 'Индивидуальный предприниматель' && (
                                <Alert
                                    className={style.resultAlert}
                                    message={
                                        <div className={style.alertTitleWrapper}>
                                            <span className={style.alertTitle}>
                                                ИП Дмитрий Селиванов
                                            </span>
                                            <div className={style.alertIconWrapper}>
                                                <span className={style.icon}>
                                                    <Edit />
                                                </span>
                                                <span className={style.icon}>
                                                    <Trash />
                                                </span>
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <>
                                            <span className={style.alertDetail}>
                                                ИНН - 9462618934
                                            </span>
                                            <Divider type="vertical" />
                                            <span className={style.alertDetail}>
                                                ОГРН - 1234567890987
                                            </span>
                                        </>
                                    }
                                    type="info"
                                />
                            )}
                            {status !== 'initial' && !isConsumerAdded && (
                                <div className={style.linkWrapper}>
                                    <ImportButton
                                        customIconStyle={{ color: '#296DFF' }}
                                        value="Найти в системе"
                                        icon={<Search />}
                                        onClick={openSearchConsumerModal}
                                    />
                                    <Divider type="vertical" />
                                    <ImportButton
                                        value="Заполнить вручную"
                                        icon={<Edit />}
                                        onClick={openAddConsumerModal}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={style.inputWrapper}>
                    <div className={style.label}>Объем</div>
                    <Input placeholder="Введите объем" className={style.select} />
                </div>
                {status !== 'initial' && isConsumerAdded && (
                    <Radio.Group onChange={onChange} value={value} className={style.radio}>
                        <Radio value={1}>Комиссию плачу я</Radio>
                        {isConsumerAddedManually ? (
                            <Tooltip
                                placement="bottom"
                                color="#434343"
                                overlayInnerStyle={{ color: 'white' }}
                                title="Опция недоступна, так как вы добавили потребителя вручную"
                            >
                                <Radio disabled value={2}>
                                    Комиссию платит потребитель
                                </Radio>
                            </Tooltip>
                        ) : (
                            <Radio value={2}>Комиссию платит потребитель</Radio>
                        )}
                    </Radio.Group>
                )}
                <Divider />
                <div className={style.addButtonWrapper}>
                    <AddCircle />
                    <span>Добавить потребителя</span>
                </div>
                <Divider />
                <div className={style.repaymentItemWrapper}>
                    <div className={style.repaymentItemName}>Общий объем</div>
                    <div className={style.repaymentItemValue}>120 000 кВт*ч</div>
                </div>
                <div className={style.repaymentItemWrapper}>
                    <div className={style.repaymentItemName}>Реализуемый объем</div>
                    <div className={style.repaymentItemValue}>-</div>
                </div>
                <div className={style.repaymentItemWrapper}>
                    <div className={style.repaymentItemName}>Оплата</div>
                    <div className={style.repaymentItemValue}>-</div>
                </div>
                <IconButton
                    customStyle={{
                        marginTop: '24px',
                        border: 'none',
                        background: `${(status === 'Я' || isConsumerAdded === true) && '#E6F1FF'}`,
                    }}
                    disabled={status !== 'Я' && isConsumerAdded !== true}
                    onClick={showInsufficientFundsModal}
                >
                    Реализовать
                </IconButton>
            </Card>
            <SearchConsumer
                isModalVisible={isSearchConsumerModalVisible}
                setIsModalVisible={setIsSearchConsumerModalVisible}
                userType={status}
            />
            <AddConsumer
                isModalVisible={isAddConsumerModalVisible}
                setIsModalVisible={setIsAddConsumerModalVisible}
                userType={status}
            />
            <InsufficientFunds
                isModalVisible={isInsufficientFundsModalVisible}
                setIsModalVisible={setIsInsufficientFundsModalVisible}
            />
        </div>
    )
}

export default ImplementationGenerationAttributes
