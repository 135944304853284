import { CSSProperties, FC } from 'react'
import { Modal, PrimaryButton } from '..'
import { Box } from '../Box/Box'

import style from './style.m.less'

interface ISoftModal {
    isModalVisible: boolean
    onCancel: () => void
    onOk: () => void
    headerText: string
    buttonTitle?: string
    cancelTitle?: string
    isDelete?: boolean
    bodyStyle?: CSSProperties
}

const SoftModal: FC<ISoftModal> = ({
    isModalVisible,
    onCancel,
    onOk,
    headerText,
    buttonTitle = 'Подтверить',
    cancelTitle = 'Отмена',
    children,
    bodyStyle,
}) => {
    return (
        <Modal isModalVisible={isModalVisible} onCancel={onCancel} bodyStyle={bodyStyle}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>{headerText}</div>
                </div>
            </div>
            <Box padding="0px 0px">{children}</Box>
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    onClick={onCancel}
                    value={cancelTitle}
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryButton
                    onClick={onOk}
                    value={buttonTitle}
                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                />
            </div>
        </Modal>
    )
}

export default SoftModal
