/* eslint-disable */
import './cadesplugin_api'
import ab2str from 'arraybuffer-to-string'
import moment from 'moment'
import Web3Utils from 'web3/lib/utils/utils'
import SignUtils from './SignUtils'
import GostCryptoService from '../streebog/gostDigest'
import { parseCertificateSubjectName } from '../ParamValueList'

const gost256Value = '1.2.643.7.1.1.1.1'
const gost512Value = '1.2.643.7.1.1.1.2'
const gost2001Value = '1.2.643.2.2.19'
const OID_ADMINISTRATOR_CERTIFICATE = '1.2.643.6.57.1.5.2.3'
const OID_MANAGER_CERTIFICATE = '1.2.643.6.57.1.5.2.4'

export default {
    gost256Value,
    gost512Value,
    gost2001Value,

    // Метод возвращает список всех активных сертификатов по ГОСТ-2012/512
    getAllCertificatesAsync() {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (arg) {
                    var certList = []
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')
                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        return
                    }

                    var certCnt
                    var certs

                    try {
                        certs = yield oStore.Certificates
                        certCnt = yield certs.Count
                    } catch (ex) {
                        return
                    }

                    if (certCnt === 0) {
                        return
                    }

                    for (var i = 1; i <= certCnt; i++) {
                        var cert
                        try {
                            cert = yield certs.Item(i)
                        } catch (ex) {
                            return
                        }

                        var publicKey = yield cert.PublicKey()
                        var algorithm = yield publicKey.Algorithm
                        var algorithmName = yield algorithm.FriendlyName
                        if (
                            !algorithmName.startsWith('ГОСТ') &&
                            !algorithmName.startsWith('GOST')
                        ) {
                            continue
                        }

                        var certRes = {}
                        var dateObj = new Date()
                        try {
                            var ValidToDate = new Date(yield cert.ValidToDate)
                            var ValidFromDate = new Date(yield cert.ValidFromDate)

                            var Validator = yield cert.IsValid()
                            var IsValid = yield Validator.Result
                            console.log(IsValid)
                            if (
                                dateObj < ValidToDate &&
                                (yield cert.HasPrivateKey()) /* && IsValid */
                            ) {
                                certRes.text = new CertificateAdjuster().GetCertInfoString(
                                    yield cert.SubjectName,
                                    ValidFromDate,
                                )
                            } else {
                                continue
                            }
                        } catch (ex) {}
                        try {
                            certRes.thumbprint = yield cert.Thumbprint
                        } catch (ex) {}

                        var algorithmValue = yield algorithm.Value
                        if (algorithmValue === gost512Value) {
                            certList.push(certRes)
                        }
                    }

                    yield oStore.Close()
                    arg[0](certList)
                },
                resolve,
                reject,
            )
        })
    },

    // Метод возвращает список всех активных сертификатов по ГОСТ-2012/512
    getAllCertificatesSync() {
        return new Promise(function (resolve, reject) {
            var certList = []
            var oStore = cadesplugin.CreateObject('CAdESCOM.Store')
            if (!oStore) {
                reject()
            }

            try {
                oStore.Open()
            } catch (ex) {
                reject(ex)
            }

            var certCnt
            var certs

            try {
                certs = oStore.Certificates
                certCnt = certs.Count
            } catch (ex) {
                reject(ex)
            }

            if (certCnt === 0) {
                return
            }

            for (var i = 1; i <= certCnt; i++) {
                var cert
                try {
                    cert = certs.Item(i)
                } catch (ex) {
                    console.log(`Error in certificate ${i}: ${ex}`)
                    continue
                }

                var publicKey = cert.PublicKey()
                var algorithm = publicKey.Algorithm
                var algorithmName = algorithm.FriendlyName
                if (!algorithmName.startsWith('ГОСТ') && !algorithmName.startsWith('GOST')) {
                    continue
                }

                var certRes = {}
                var dateObj = new Date()
                try {
                    var ValidToDate = new Date(cert.ValidToDate)
                    var ValidFromDate = new Date(cert.ValidFromDate)

                    var Validator = cert.IsValid()
                    var IsValid = Validator.Result
                    console.log(IsValid)
                    if (dateObj < ValidToDate && cert.HasPrivateKey() /* && IsValid */) {
                        certRes.text = new CertificateAdjuster().GetCertInfoString(
                            cert.SubjectName,
                            ValidFromDate,
                        )
                    } else {
                        continue
                    }
                } catch (ex) {
                    console.log(`Error in certificate ${i}: ${ex}`)
                    continue
                }
                try {
                    certRes.thumbprint = cert.Thumbprint
                } catch (ex) {
                    console.log(`Error in certificate ${i}: ${ex}`)
                    continue
                }

                var algorithmValue = algorithm.Value
                if (algorithmValue === gost512Value) {
                    certList.push(certRes)
                }
            }

            oStore.Close()
            resolve(certList)
        })
    },

    checkAllCertificatesAsync() {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    var certList = []
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')
                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        return
                    }

                    var certCnt
                    var certs

                    try {
                        certs = yield oStore.Certificates
                        certCnt = yield certs.Count
                    } catch (ex) {
                        return
                    }

                    if (certCnt === 0) {
                        return
                    }

                    for (var i = 1; i <= certCnt; i++) {
                        var certRes = {
                            text: null,
                            thumbprint: null,

                            checkNotBeforeDateFrom: null,
                            checkNotAfterDateTo: null,
                            checkPrivateKey: null,
                            checkIsValid: null,
                            checkAlgorithmValue: null,
                            checkExtendedKeyUsage: null,
                            checkKeyUsage: null,

                            algorithmName: null,
                            // validFromDate: null,
                            // validToDate: null
                        }

                        var cert
                        var ValidToDate, ValidFromDate

                        try {
                            cert = yield certs.Item(i)

                            ValidToDate = new Date(yield cert.ValidToDate)
                            ValidFromDate = new Date(yield cert.ValidFromDate)

                            certRes.text = new CertificateAdjuster().GetCertInfoString(
                                yield cert.SubjectName,
                                ValidFromDate,
                            )
                            certRes.thumbprint = yield cert.Thumbprint
                        } catch (e) {
                            console.error(e)
                            continue
                        }

                        // 1. Срок действия сертификата
                        try {
                            certRes.checkNotBeforeDateFrom = checkNotBeforeDateFrom(ValidFromDate)
                            certRes.checkNotAfterDateTo = checkNotAfterDateTo(ValidToDate)

                            // certRes.validToDate = ValidToDate
                            // certRes.validFromDate = ValidFromDate
                        } catch (e) {
                            console.error(e)
                        }

                        // 2. Наличие закрытого ключа
                        try {
                            certRes.checkPrivateKey = yield cert.HasPrivateKey()
                        } catch (e) {
                            console.error(e)
                        }

                        // 3. Проверка цепочки сертификата
                        try {
                            var Validator = yield cert.IsValid()
                            certRes.checkIsValid = yield Validator.Result
                        } catch (e) {
                            console.error(e)
                        }

                        // 4. Проверка указанного в сертификате алгоритма для открытого ключа
                        try {
                            var publicKey = yield cert.PublicKey()
                            var algorithm = yield publicKey.Algorithm

                            certRes.checkAlgorithmValue = checkAlgorithmValue(yield algorithm.Value)
                            certRes.algorithmName = yield algorithm.FriendlyName
                        } catch (e) {
                            console.error(e)
                        }

                        // 5. Проверка значений Расширенное использование Ключа
                        try {
                            var eku = yield cert.ExtendedKeyUsage()
                            var ekus = yield eku.EKUs
                            var ekusCount = yield ekus.Count

                            var ekuList = []

                            for (var j = 1; j <= ekusCount; ++j) {
                                var ekuItem = yield ekus.Item(j)
                                var ekuOID = yield ekuItem.OID

                                ekuList.push(ekuOID)
                            }

                            certRes.checkExtendedKeyUsage = checkExtendedKeyUsage(ekuList)
                        } catch (e) {
                            console.error(e)
                        }

                        // 6. Проверка KeyUsage
                        try {
                            var usage = yield cert.KeyUsage()

                            var digitalSignature = yield usage.IsDigitalSignatureEnabled
                            var nonRepudiationEnabled = yield usage.IsNonRepudiationEnabled

                            certRes.checkKeyUsage = checkKeyUsage(
                                digitalSignature,
                                nonRepudiationEnabled,
                            )
                        } catch (e) {
                            console.error(e)
                        }

                        certList.push(certRes)
                    }

                    yield oStore.Close()

                    cb(certList)
                },
                resolve,
                reject,
            )
        })

        function checkAlgorithmValue(algorithmValue) {
            return [gost256Value, gost512Value, gost2001Value].indexOf(algorithmValue) !== -1
        }

        function checkNotBeforeDateFrom(validFromDate) {
            return !moment().isBefore(validFromDate)
        }

        function checkNotAfterDateTo(validToDate) {
            return !moment().isAfter(validToDate)
        }

        function checkExtendedKeyUsage(ekuList) {
            return (
                ekuList.includes(OID_ADMINISTRATOR_CERTIFICATE) ||
                ekuList.includes(OID_MANAGER_CERTIFICATE)
            )
        }

        function checkKeyUsage(digitalSignature, nonRepudiationEnabled) {
            return digitalSignature === 1 && nonRepudiationEnabled === 1
        }
    },

    checkAllCertificatesSync() {
        return new Promise(function (resolve, reject) {
            try {
                var certList = []

                var oStore = cadesplugin.CreateObject('CAdESCOM.Store')
                if (!oStore) {
                    throw new Error('Cannot create CAdESCOM.Store object')
                }

                oStore.Open()

                var certs = oStore.Certificates
                var certCnt = certs.Count

                for (var i = 1; i <= certCnt; i++) {
                    var certRes = {
                        text: null,
                        thumbprint: null,

                        checkNotBeforeDateFrom: null,
                        checkNotAfterDateTo: null,
                        checkPrivateKey: null,
                        checkIsValid: null,
                        checkAlgorithmValue: null,
                        checkExtendedKeyUsage: null,
                        checkKeyUsage: null,

                        algorithmName: null,
                        // validFromDate: null,
                        // validToDate: null
                    }

                    var cert
                    var ValidToDate, ValidFromDate

                    try {
                        cert = certs.Item(i)

                        ValidToDate = new Date(cert.ValidToDate)
                        ValidFromDate = new Date(cert.ValidFromDate)

                        certRes.text = new CertificateAdjuster().GetCertInfoString(
                            cert.SubjectName,
                            ValidFromDate,
                        )
                        certRes.thumbprint = cert.Thumbprint
                        // NOTE: Хак для IE11 чтобы он мог сделать переносы в слишком длинных строках
                        // (вставляем символ ZERO WIDTH SPACE после каждого печатного символа)
                        certRes.text = certRes.text.split('').join('\u200b')
                    } catch (e) {
                        console.error(e)
                        continue
                    }

                    // 1. Срок действия сертификата
                    try {
                        certRes.checkNotBeforeDateFrom = checkNotBeforeDateFrom(ValidFromDate)
                        certRes.checkNotAfterDateTo = checkNotAfterDateTo(ValidToDate)

                        // certRes.validToDate = ValidToDate
                        // certRes.validFromDate = ValidFromDate
                    } catch (e) {
                        console.error(e)
                    }

                    // 2. Наличие закрытого ключа
                    try {
                        certRes.checkPrivateKey = cert.HasPrivateKey()
                    } catch (e) {
                        console.error(e)
                    }

                    // 3. Проверка цепочки сертификата
                    try {
                        var Validator = cert.IsValid()
                        certRes.checkIsValid = Validator.Result
                    } catch (e) {
                        console.error(e)
                    }

                    // 4. Проверка указанного в сертификате алгоритма для открытого ключа
                    try {
                        var publicKey = cert.PublicKey()
                        var algorithm = publicKey.Algorithm

                        certRes.checkAlgorithmValue = checkAlgorithmValue(algorithm.Value)
                        certRes.algorithmName = algorithm.FriendlyName
                    } catch (e) {
                        console.error(e)
                    }

                    // 5. Проверка значений Расширенное использование Ключа
                    try {
                        var eku = cert.ExtendedKeyUsage()
                        var ekus = eku.EKUs
                        var ekusCount = ekus.Count

                        var ekuList = []

                        for (var j = 1; j <= ekusCount; ++j) {
                            var ekuItem = ekus.Item(j)
                            var ekuOID = ekuItem.OID

                            ekuList.push(ekuOID)
                        }

                        certRes.checkExtendedKeyUsage = checkExtendedKeyUsage(ekuList)
                    } catch (e) {
                        console.error(e)
                    }

                    // 6. Проверка KeyUsage
                    try {
                        var usage = cert.KeyUsage()

                        var digitalSignature = usage.IsDigitalSignatureEnabled
                        var nonRepudiationEnabled = usage.IsNonRepudiationEnabled

                        certRes.checkKeyUsage = checkKeyUsage(
                            digitalSignature,
                            nonRepudiationEnabled,
                        )
                    } catch (e) {
                        console.error(e)
                    }

                    certList.push(certRes)
                }

                oStore.Close()

                resolve(certList)
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })

        function checkAlgorithmValue(algorithmValue) {
            return [gost256Value, gost512Value, gost2001Value].indexOf(algorithmValue) !== -1
        }

        function checkNotBeforeDateFrom(validFromDate) {
            return !moment().isBefore(validFromDate)
        }

        function checkNotAfterDateTo(validToDate) {
            return !moment().isAfter(validToDate)
        }

        function checkExtendedKeyUsage(ekuList) {
            return (
                ekuList.includes(OID_ADMINISTRATOR_CERTIFICATE) ||
                ekuList.includes(OID_MANAGER_CERTIFICATE)
            )
        }

        function checkKeyUsage(digitalSignature, nonRepudiationEnabled) {
            return digitalSignature == true && nonRepudiationEnabled == true
        }
    },

    getCertificateDataAsync(thumbprint) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (arg) {
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')

                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var certs = yield oStore.Certificates
                    var certFind
                    try {
                        certFind = yield certs.Find(
                            cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                            thumbprint,
                        )
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var cert
                    try {
                        cert = yield certFind.Item(1)
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    // вытаскиваем данные из сертификата
                    var certRes = { thumbprint }
                    certRes.validFromDate = new Date(yield cert.ValidFromDate)
                    certRes.validToDate = new Date(yield cert.ValidToDate)
                    certRes.serialNumber = yield cert.SerialNumber
                    certRes.subjectName = yield cert.SubjectName

                    yield oStore.Close()
                    arg[0](certRes)
                },
                resolve,
                reject,
            )
        })
    },

    getCertificateDataSync(thumbprint) {
        return new Promise(function (resolve, reject) {
            var oStore = cadesplugin.CreateObject('CAdESCOM.Store')

            if (!oStore) {
                reject()
            }

            try {
                oStore.Open()
            } catch (ex) {
                reject(ex)
            }

            var certs = oStore.Certificates
            var certFind
            try {
                certFind = certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH, thumbprint)
            } catch (ex) {
                reject(ex)
            }

            var cert
            try {
                cert = certFind.Item(1)
            } catch (ex) {
                console.log(`Error in certificate: ${ex}`)
            }

            // вытаскиваем данные из сертификата
            var certRes = { thumbprint }
            certRes.validFromDate = new Date(cert.ValidFromDate)
            certRes.validToDate = new Date(cert.ValidToDate)
            certRes.serialNumber = cert.SerialNumber

            oStore.Close()
            resolve(certRes)
        })
    },

    getCertificateRolesAsync(thumbprint) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')

                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var certs = yield oStore.Certificates
                    var certFind
                    try {
                        certFind = yield certs.Find(
                            cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                            thumbprint,
                        )
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var cert
                    try {
                        cert = yield certFind.Item(1)
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var rolesList = []

                    try {
                        var eku = yield cert.ExtendedKeyUsage()
                        var ekus = yield eku.EKUs
                        var ekusCount = yield ekus.Count

                        for (var j = 1; j <= ekusCount; ++j) {
                            var ekuItem = yield ekus.Item(j)
                            var ekuOID = yield ekuItem.OID

                            if (ekuOID === OID_ADMINISTRATOR_CERTIFICATE) {
                                rolesList.push('admin')
                            } else if (ekuOID === OID_MANAGER_CERTIFICATE) {
                                rolesList.push('manager')
                            }
                        }
                    } catch (e) {
                        console.error(e)
                    }

                    yield oStore.Close()

                    cb(rolesList)
                },
                resolve,
                reject,
            )
        })
    },

    getCertificateRolesSync(thumbprint) {
        return new Promise(function (resolve, reject) {
            try {
                var oStore = cadesplugin.CreateObject('CAdESCOM.Store')
                if (!oStore) {
                    throw new Error('Cannot create CAdESCOM.Store object')
                }

                oStore.Open()

                var certs = oStore.Certificates
                var certFind = certs.Find(
                    cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                    thumbprint,
                )

                var cert = certFind.Item(1)

                var rolesList = []

                var eku = cert.ExtendedKeyUsage()
                var ekus = eku.EKUs
                var ekusCount = ekus.Count

                for (var j = 1; j <= ekusCount; ++j) {
                    var ekuItem = ekus.Item(j)
                    var ekuOID = ekuItem.OID

                    if (ekuOID === OID_ADMINISTRATOR_CERTIFICATE) {
                        rolesList.push('admin')
                    } else if (ekuOID === OID_MANAGER_CERTIFICATE) {
                        rolesList.push('manager')
                    }
                }

                oStore.Close()

                resolve(rolesList)
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })
    },

    getCertificateSerialNumberAsync(thumbprint) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')

                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var certs = yield oStore.Certificates
                    var certFind
                    try {
                        certFind = yield certs.Find(
                            cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                            thumbprint,
                        )
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var cert
                    try {
                        cert = yield certFind.Item(1)
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var serialNumber = yield cert.SerialNumber

                    yield oStore.Close()

                    cb(serialNumber)
                },
                resolve,
                reject,
            )
        })
    },

    getCertificateSerialNumberSync(thumbprint) {
        return new Promise(function (resolve, reject) {
            try {
                var oStore = cadesplugin.CreateObject('CAdESCOM.Store')
                if (!oStore) {
                    throw new Error('Cannot create CAdESCOM.Store object')
                }

                oStore.Open()

                var certs = oStore.Certificates
                var certFind = certs.Find(
                    cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                    thumbprint,
                )

                var cert = certFind.Item(1)

                var serialNumber = cert.SerialNumber

                oStore.Close()

                resolve(serialNumber)
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })
    },

    parseCertificateSubjectAsync(thumbprint) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')

                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var certs = yield oStore.Certificates
                    var certFind
                    try {
                        certFind = yield certs.Find(
                            cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                            thumbprint,
                        )
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var cert
                    try {
                        cert = yield certFind.Item(1)
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    // вытаскиваем данные из сертификата
                    var subjectName = yield cert.SubjectName

                    console.log('SubjectName:', subjectName)

                    yield oStore.Close()

                    var params = null
                    try {
                        params = parseCertificateSubjectName(subjectName)
                    } catch (ex) {
                        console.error(ex)
                    }

                    cb(params)
                },
                resolve,
                reject,
            )
        })
    },

    parseCertificateSubjectSync(thumbprint) {
        return new Promise(function (resolve, reject) {
            try {
                var oStore = cadesplugin.CreateObject('CAdESCOM.Store')

                if (!oStore) {
                    throw new Error('Cannot create CAdESCOM.Store object')
                }

                oStore.Open()

                var certs = oStore.Certificates
                var certFind = certs.Find(
                    cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                    thumbprint,
                )

                var cert = certFind.Item(1)

                // вытаскиваем данные из сертификата
                var subjectName = cert.SubjectName

                oStore.Close()

                resolve(parseCertificateSubjectName(subjectName))
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })
    },

    exportCertificateAsync(thumbprint) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    var oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')

                    if (!oStore) {
                        return
                    }

                    try {
                        yield oStore.Open()
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var certs = yield oStore.Certificates
                    var certFind
                    try {
                        certFind = yield certs.Find(
                            cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                            thumbprint,
                        )
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    var cert
                    try {
                        cert = yield certFind.Item(1)
                    } catch (ex) {
                        console.log(`Error in certificate: ${ex}`)
                        return
                    }

                    // вытаскиваем данные из сертификата
                    var exportedCertificate = yield cert.Export(cadesplugin.CADESCOM_ENCODE_BASE64)

                    yield oStore.Close()

                    cb(exportedCertificate)
                },
                resolve,
                reject,
            )
        })
    },

    exportCertificateSync(thumbprint) {
        return new Promise(function (resolve, reject) {
            try {
                var oStore = cadesplugin.CreateObject('CAdESCOM.Store')

                if (!oStore) {
                    throw new Error('Cannot create CAdESCOM.Store object')
                }

                oStore.Open()

                var certs = oStore.Certificates
                var certFind = certs.Find(
                    cadesplugin.CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                    thumbprint,
                )

                var cert = certFind.Item(1)

                // вытаскиваем данные из сертификата
                var exportedCertificate = cert.Export(cadesplugin.CADESCOM_ENCODE_BASE64)

                oStore.Close()

                resolve(exportedCertificate)
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })
    },

    getCertificateLabelAsync(exportedCertificate) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    let oCertificate = yield cadesplugin.CreateObjectAsync('CAdESCOM.Certificate')

                    yield oCertificate.Import(exportedCertificate)

                    let subjectName = yield oCertificate.SubjectName
                    let validFromDate = yield oCertificate.ValidFromDate

                    cb(new CertificateAdjuster().GetCertInfoString(subjectName, validFromDate))
                },
                resolve,
                reject,
            )
        })
    },

    getCertificateLabelSync(exportedCertificate) {
        return new Promise(function (resolve, reject) {
            try {
                let oCertificate = cadesplugin.CreateObject('CAdESCOM.Certificate')

                oCertificate.Import(exportedCertificate)

                let subjectName = oCertificate.SubjectName
                let validFromDate = oCertificate.ValidFromDate

                resolve(new CertificateAdjuster().GetCertInfoString(subjectName, validFromDate))
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })
    },

    getCertificateThumbprintAsync(exportedCertificate) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    let oCertificate = yield cadesplugin.CreateObjectAsync('CAdESCOM.Certificate')

                    yield oCertificate.Import(exportedCertificate)

                    cb(yield oCertificate.Thumbprint)
                },
                resolve,
                reject,
            )
        })
    },

    getCertificateThumbprintSync(exportedCertificate) {
        return new Promise(function (resolve, reject) {
            try {
                let oCertificate = cadesplugin.CreateObject('CAdESCOM.Certificate')

                oCertificate.Import(exportedCertificate)

                resolve(oCertificate.Thumbprint)
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })
    },

    getCertificateValidToDateAsync(exportedCertificate) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* ([cb]) {
                    let oCertificate = yield cadesplugin.CreateObjectAsync('CAdESCOM.Certificate')

                    yield oCertificate.Import(exportedCertificate)

                    cb(yield oCertificate.ValidToDate)
                },
                resolve,
                reject,
            )
        })
    },

    getCertificateValidToDateSync(exportedCertificate) {
        return new Promise(function (resolve, reject) {
            try {
                let oCertificate = cadesplugin.CreateObject('CAdESCOM.Certificate')

                oCertificate.Import(exportedCertificate)

                resolve(new Date(oCertificate.ValidToDate).toISOString())
            } catch (e) {
                console.error(e)
                return reject(e)
            }
        })
    },

    // Метод возвращает имя сертификата по которому сертификато можно достать из хранилища
    extractCertificateName(cert) {
        return cert.substr(3, cert.indexOf(';') - 3)
    },

    async getUserAddressByCertificate(certificate) {
        let keyPair = await SignUtils.getKeyPairFromCertificate(certificate)
        console.log(`Public key raw = ${keyPair.publicKey}`)
        let publicKeyBlobHex = this.base64toHEX(keyPair.publicKey.replace(/[^\x20-\x7E]/gim, ''))
        console.log(`Public key blob = ${publicKeyBlobHex}`)
        let publicKeyBlob = this.hexStringToByte(publicKeyBlobHex)
        let publicKey = publicKeyBlob.slice(-64)
        let publicKeyHex = ab2str(publicKey, 'hex')
        console.log(`Public key hex = ${publicKeyHex}`)
        let publicKey64 = ab2str(publicKey, 'base64')
        console.log(`Public key for CSP = ${publicKey64}`)

        const GostDigest = GostCryptoService()
        let gostDigest = new GostDigest()
        let hashBuffer = gostDigest.digest(publicKey)
        console.log(`Pubkey hash: ${ab2str(hashBuffer, 'hex')}`)
        let pubKeyHash = ab2str(hashBuffer, 'hex')
        let userAddress = Web3Utils.toChecksumAddress(pubKeyHash.slice(-40))
        console.log(`User address: ${userAddress}`)
        return userAddress
    },

    base64toHEX(base64) {
        console.log(base64)
        let raw = atob(base64)
        let HEX = ''
        for (let i = 0; i < raw.length; i++) {
            let _hex = raw.charCodeAt(i).toString(16)
            HEX += _hex.length === 2 ? _hex : '0' + _hex
        }
        return HEX.toUpperCase()
    },

    hexStringToByte(str) {
        if (!str) {
            return new Uint8Array()
        }
        let a = []
        for (let i = 0, len = str.length; i < len; i += 2) {
            a.push(parseInt(str.substr(i, 2), 16))
        }
        return new Uint8Array(a)
    },
}

function CertificateAdjuster() {}

CertificateAdjuster.prototype.extract = function (from, what) {
    let certName = ''

    var begin = from.indexOf(what)

    if (begin >= 0) {
        var end = from.indexOf(', ', begin)
        certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin)
    }

    return certName
}

CertificateAdjuster.prototype.Print2Digit = function (digit) {
    return digit < 10 ? '0' + digit : digit
}

CertificateAdjuster.prototype.GetCertDate = function (paramDate) {
    var certDate = new Date(paramDate)
    return (
        this.Print2Digit(certDate.getUTCDate()) +
        '.' +
        this.Print2Digit(certDate.getMonth() + 1) +
        '.' +
        certDate.getFullYear() +
        ' ' +
        this.Print2Digit(certDate.getUTCHours()) +
        ':' +
        this.Print2Digit(certDate.getUTCMinutes()) +
        ':' +
        this.Print2Digit(certDate.getUTCSeconds())
    )
}

CertificateAdjuster.prototype.GetCertInfoString = function (certSubjectName, certFromDate) {
    return this.extract(certSubjectName, 'CN=') + '; Выдан: ' + this.GetCertDate(certFromDate)
}
