import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChangeRoleModal } from '../'
import { ESignatureModal } from '../'
import { ChangeEmailModal } from '../'
import { RemoveRequestModal } from '../'
import { GeneratorInfoFrame, IconButton } from '../../../ui'
import { Key, Edit, Stamp, Mail2, CloseCircle } from '../../../../assets/icons'
import { legalPersonInfoConfig } from './legalPersonInfoConfig'
import { individualEntrepreneurInfoConfig } from './individualEntrepreneurInfoConfig'
import { privatePersonInfoConfig } from './privatePersonInfoConfig'
import { DocumentsGrid } from '../'
import style from './style.m.less'
import { Alert, Divider } from 'antd'
import { EStatus } from '../../../../constants/approval'

const GeneralInfo: FC = () => {
    const { t } = useTranslation()

    const [isChangeRoleModalVisible, setIsChangeRoleModalVisible] = useState(false)
    const [isESignatureModalVisible, setIsESignatureModalVisible] = useState(false)
    const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false)
    const [isRemoveRequestModalVisible, setIsRemoveRequestModalVisible] = useState(false)

    const openESignatureModalModal = () => {
        setIsESignatureModalVisible(true)
    }

    const openChangeEmailModal = () => {
        setIsChangeEmailModalVisible(true)
    }

    const openRemoveRequestModal = () => {
        setIsRemoveRequestModalVisible(true)
    }

    const status = EStatus.UnderRevision

    return (
        <div className={style.generalInfo}>
            <GeneratorInfoFrame
                label={t('myProfile.totalInfo')}
                config={privatePersonInfoConfig}
                customStyle={{ margin: 0 }}
                isProfile
                statusBlock={
                    <>
                        <Alert
                            className={style.generalInfoAlert}
                            message={
                                <>
                                    {t('myProfile.generalInfoAlert')}
                                    <br />
                                    {t('myProfile.generalInfoAlert2')}
                                </>
                            }
                            type="info"
                            showIcon
                        />
                        {status === EStatus.UnderRevision && (
                            <Alert
                                className={style.generalInfoWarning}
                                message={
                                    <>
                                        <span className={style.generalInfoWarningMessage}>
                                            Данные отправлены на доработку
                                        </span>
                                        <br />
                                        <span>
                                            Комментарий оператора о том, что пользователю нужно
                                            доработать
                                        </span>
                                        <div
                                            className={style.generalInfoWarningWrapper}
                                            onClick={openRemoveRequestModal}
                                        >
                                            <CloseCircle />
                                            <span className={style.generalInfoWarningButton}>
                                                Снять заявку
                                            </span>
                                        </div>
                                    </>
                                }
                                type="warning"
                            />
                        )}
                    </>
                }
                bottomChildren={
                    <>
                        <Divider type={'horizontal'} />
                        <div className={style.profileHeader}>{t('myProfile.eSignature')}</div>
                        <div className={style.stampBlock} onClick={openESignatureModalModal}>
                            <Stamp />
                            <div className={style.stampOwner}>Макаров Михаил (ОАО Лето)</div>
                        </div>
                        <Divider type={'horizontal'} />
                        <DocumentsGrid />
                        <Divider type={'horizontal'} />
                        <div className={style.configButtonsBlock}>
                            <Link to="/edit-my-profile" state={{ type: 'profile' }}>
                                <IconButton icon={<Edit />}>
                                    {t('myProfile.editProfile')}
                                </IconButton>
                            </Link>
                            <Link to="/password">
                                <IconButton icon={<Key />}>
                                    {t('myProfile.changePassword')}
                                </IconButton>
                            </Link>
                            <IconButton
                                onClick={openChangeEmailModal}
                                icon={<Mail2 />}
                                customIconStyle={{
                                    marginRight: '8px',
                                    marginTop: '3px',
                                    color: '#528EFF',
                                }}
                            >
                                {t('myProfile.changeEmail')}
                            </IconButton>
                        </div>
                    </>
                }
            ></GeneratorInfoFrame>

            {/* <div className={style.contentBlock}>
                <div className={style.profileHeader}>Документы</div>
                <div className={style.DocumentsBlock}>
                    
                </div>
            </div> */}
            <ChangeRoleModal
                isModalVisible={isChangeRoleModalVisible}
                setIsModalVisible={setIsChangeRoleModalVisible}
            />
            <ESignatureModal
                isModalVisible={isESignatureModalVisible}
                setIsModalVisible={setIsESignatureModalVisible}
            />
            <ChangeEmailModal
                isModalVisible={isChangeEmailModalVisible}
                setIsModalVisible={setIsChangeEmailModalVisible}
            />
            <RemoveRequestModal
                isModalVisible={isRemoveRequestModalVisible}
                setIsModalVisible={setIsRemoveRequestModalVisible}
            />
        </div>
    )
}

export default GeneralInfo
