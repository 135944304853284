import { FC } from 'react'
import { Doughnut } from 'react-chartjs-2/dist'

interface IAccountChart {
    certificates_free: number
    other_free: number
    blocked: number
    attributes: number
    isOwner: boolean
    total: number
}

const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
    maintainAspectRatio: false,
}

const AccountChart: FC<IAccountChart> = ({
    certificates_free,
    other_free,
    blocked,
    attributes,
    isOwner,
    total,
}) => {
    const plugins = [
        {
            beforeDraw: function (chart) {
                const width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx
                ctx.restore()
                const fontSize = (height / 180).toFixed(2)
                ctx.font = fontSize + 'em sans-serif'
                ctx.fontWeight = 600
                ctx.textBaseline = 'top'
                const text = `${total} кВт*ч`,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2.2
                ctx.fillText(text, textX, textY)
                ctx.save()
            },
        },
    ]

    const ownerData = {
        labels: [
            'Свободные СП',
            'Свободные ЗИ других систем',
            'Свободные АГ',
            'Заблокированные АГ, СП и ЗД',
        ],
        datasets: [
            {
                borderWidth: 1,
                data: [certificates_free, other_free, attributes, blocked],
                backgroundColor: ['#B7EB8F', '#BAE7FF', '#FFE58F', '#EFDBFF'],
                offset: 4,
            },
            {
                responsive: true,
            },
        ],
    }

    const data = {
        labels: ['Свободные СП', 'Свободные ЗИ других систем', 'Заблокированные АГ, СП и ЗД'],
        datasets: [
            {
                borderWidth: 1,
                data: [certificates_free, other_free, blocked],
                backgroundColor: ['#B7EB8F', '#BAE7FF', '#EFDBFF'],
                offset: 4,
            },
            {
                responsive: true,
            },
        ],
    }

    const defaultData = {
        datasets: [
            {
                borderWidth: 1,
                data: [100],
                backgroundColor: ['#F0F0F0'],
            },
            {
                responsive: true,
            },
        ],
    }

    return (
        <Doughnut
            data={total === 0 ? defaultData : isOwner ? ownerData : data}
            options={options}
            plugins={plugins}
        />
    )
}

export default AccountChart
