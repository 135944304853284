import { FC, useState } from 'react'
import { LoadingContainer, Box } from '../ui'
import { Switch, Input, Divider, Checkbox, Form, Alert, InputNumber } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import useMount from '../hooks/useMount'
import useRequest from '../hooks/useRequest'
import { useDebouncedRequest } from '../hooks'
import { getAutoSettings, postAutoSettings, getHideSettings, postHideSettings } from './config'

import style from './style.m.less'
import { AutoSettings, HideSettings } from './types'
import { useCurrentUser } from '../hooks/useCurrentUser'

interface ISettingsBlock {
    data: {
        id: number
        generator_name: string
        is_active: boolean
        is_shared: boolean
        volume: number
    }
    isLast: boolean
}

const SettingBlock: FC<ISettingsBlock> = ({ data, isLast }) => {
    const { id, generator_name, is_active, is_shared, volume } = data
    const [isOn, setIsOn] = useState(is_active)
    const [isChecked, setIsChecked] = useState(is_shared)
    const [value, setValue] = useState(volume)
    const { fetch: sendAutoSettings } = useRequest(postAutoSettings)

    const onChangeSetting = (type?: string, setting?: boolean, checked?: boolean) => {
        const typeOn = type === 'isOn'
        if (value || (typeOn && !checked)) {
            sendAutoSettings({
                body: {
                    settings: [
                        {
                            id,
                            is_active: typeOn ? setting : isOn,
                            is_shared: typeOn ? false : checked ? checked : isChecked,
                            volume: typeOn ? null : value,
                        },
                    ],
                },
            })
        }
    }

    const debouncedSendVolume = useDebouncedRequest(onChangeSetting, 500)

    return (
        <>
            <div className={style.settingRow}>
                <div className={style.settingHeader}>{generator_name}</div>
                <Form.Item name={id} valuePropName="checked">
                    <Switch
                        size={'small'}
                        defaultChecked={isOn}
                        className={style.switch}
                        onChange={(checked) => {
                            if (!checked) {
                                setValue(null)
                                setIsChecked(false)
                            }
                            setIsOn(checked)
                            onChangeSetting('isOn', checked)
                        }}
                    />
                </Form.Item>
            </div>
            {isOn && (
                <>
                    <div className={style.settingBlock}>
                        <div>Тип инструмента</div>
                        <Input
                            value={'Сертификат происхождения'}
                            className={style.input}
                            disabled
                        />
                    </div>
                    <div className={style.settingBlock}>
                        <div>Объем выпуска</div>
                        <Input value={'На весь остаток'} className={style.input} disabled />
                    </div>
                    <Box direction="row" gap="8px" margin="8px 0 0 0">
                        <Checkbox
                            defaultChecked={isChecked}
                            onChange={(e) => {
                                setIsChecked(e.target.checked)
                                if (!e.target.checked) {
                                    setValue(null)
                                    onChangeSetting('isOn', true, false)
                                }
                            }}
                        />
                        <div>Несколько СП (указать номинал)</div>
                    </Box>
                    {isChecked && (
                        <>
                            <div className={style.settingBlock}>
                                <div>Номинал СП, кВт*ч</div>
                                <InputNumber
                                    className={style.input}
                                    value={value}
                                    onChange={(value) => setValue(value)}
                                    controls={false}
                                    onKeyUp={() => debouncedSendVolume()}
                                />
                            </div>
                            <Alert
                                className={style.alert}
                                message="Сертификаты будут выпущены одним номиналом. Если объем выпуска не кратен указанному номиналу, то объем-остаток останется свободным"
                                type="info"
                                showIcon
                            />
                        </>
                    )}
                </>
            )}
            {!isLast && <Divider />}
        </>
    )
}

const Settings: FC = () => {
    const { isTSO, isClient, isLoading: isLoadingUser } = useCurrentUser()
    const isUserClientOrTSO = !isLoadingUser && (isTSO || isClient)
    const {
        fetch: fetchAutoSettings,
        result: resultAuto,
        isLoading: isLoadingAuto,
    } = useRequest<AutoSettings>(getAutoSettings)
    const {
        fetch: fetchHideSettings,
        result: resultHide,
        isLoading: isLoadingHide,
    } = useRequest<HideSettings>(getHideSettings)
    const { fetch: sendHideSettings } = useRequest(postHideSettings)

    const [form] = Form.useForm()

    useMount(() => {
        if (!isUserClientOrTSO) {
            fetchAutoSettings({})
        }
        fetchHideSettings({})
    })

    const onChangeSetting = (e: CheckboxChangeEvent, id: number) => {
        sendHideSettings({ body: { is_hidden: e.target.checked, setting_type_id: id } })
    }

    const isLoading = isUserClientOrTSO
        ? isLoadingHide || !resultHide
        : isLoadingAuto || isLoadingHide || !resultHide || !resultAuto

    return (
        <LoadingContainer isLoading={isLoading}>
            <Form form={form} name="settingsForm" initialValues={{ ...resultAuto, ...resultHide }}>
                <div className={style.settingsMain}>
                    <div className={style.settingsHeader}>Настройки</div>

                    {!isUserClientOrTSO && (
                        <div className={style.settingsFrame}>
                            <div className={style.subheader}>
                                Автоматический выпуск зеленых инструментов
                            </div>
                            {resultAuto?.settings
                                ?.sort((a, b) => a.id - b.id)
                                .map((item, index) => {
                                    return (
                                        <SettingBlock
                                            key={item.id}
                                            data={item}
                                            isLast={index === resultAuto?.settings?.length - 1}
                                        />
                                    )
                                })}
                        </div>
                    )}

                    <div className={style.settingsFrame}>
                        <div className={style.subheader}>Конфиденциальность</div>
                        <div className={style.settingRowCheckboxFirst}>
                            <Form.Item name={'hide_reestr_certificate'} valuePropName="checked">
                                <Checkbox onChange={(e) => onChangeSetting(e, 1)} />
                            </Form.Item>
                            <div className={style.checkboxText}>
                                Не показывать мои сертификаты происхождения в реестре
                            </div>
                        </div>
                        <div className={style.settingRowCheckbox}>
                            <Form.Item name={'hide_reestr_contract'} valuePropName="checked">
                                <Checkbox onChange={(e) => onChangeSetting(e, 2)} />
                            </Form.Item>
                            <div className={style.checkboxText}>
                                Не показывать мои зеленые договоры в реестре
                            </div>
                        </div>
                        <div className={style.settingRowCheckbox}>
                            <Form.Item name={'hide_reestr_other'} valuePropName="checked">
                                <Checkbox onChange={(e) => onChangeSetting(e, 3)} />
                            </Form.Item>
                            <div className={style.checkboxText}>
                                Не показывать мои другие зеленые инструменты в реестре
                            </div>
                        </div>
                        <Divider />
                        <div className={style.settingRowNoMargin}>
                            <Form.Item name={'hide_consumer_certificate'} valuePropName="checked">
                                <Checkbox onChange={(e) => onChangeSetting(e, 4)} />
                            </Form.Item>
                            <div className={style.checkboxText}>
                                Не показывать сертификаты происхождения, в которых я являюсь
                                потребителем
                            </div>
                        </div>
                        <div className={style.settingRowCheckbox}>
                            <Form.Item name={'hide_consumer_contract'} valuePropName="checked">
                                <Checkbox onChange={(e) => onChangeSetting(e, 5)} />
                            </Form.Item>
                            <div className={style.checkboxText}>
                                Не показывать зеленые договоры, в которых я являюсь потребителем
                            </div>
                        </div>
                        <div className={style.settingRowCheckbox}>
                            <Form.Item name={'hide_consumer_other'} valuePropName="checked">
                                <Checkbox onChange={(e) => onChangeSetting(e, 6)} />
                            </Form.Item>
                            <div className={style.checkboxText}>
                                Не показывать другие зеленые инструменты, в которых я являюсь
                                потребителем
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </LoadingContainer>
    )
}

export default Settings
