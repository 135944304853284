import { FC } from 'react'
import { Modal, PrimaryButton } from '..'

import style from './style.m.less'
import { QuestionCircle } from '../../../assets/icons'

interface IDeleteModal {
    isModalVisible: boolean
    onCancel: () => void
    onDelete: () => void
    headerText: string
    messageText: string
    deleteTitle?: string
    cancelTitle?: string
    isDelete?: boolean
    icon?: JSX.Element
}

const DeleteModal: FC<IDeleteModal> = ({
    isModalVisible,
    onCancel,
    onDelete,
    headerText,
    messageText,
    deleteTitle = 'Удалить',
    cancelTitle = 'Отмена',
    isDelete = true,
    icon,
}) => {
    return (
        <Modal isModalVisible={isModalVisible} onCancel={onCancel}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    {icon || <QuestionCircle />}
                    <div className={style.header}>{headerText}</div>
                </div>
            </div>
            <div className={style.text}>{messageText}</div>
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    onClick={onCancel}
                    value={cancelTitle}
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryButton
                    onClick={onDelete}
                    isDelete={isDelete}
                    value={deleteTitle}
                    customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
                />
            </div>
        </Modal>
    )
}

export default DeleteModal
