import { FC, useState } from 'react'
import { CertificatesModal, ICertificates, Auth, Register, SetPassword } from './components'
import { Link } from 'react-router-dom'
import SignUtils from '../../utils/sign/SignUtils'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

interface ILogin {
    type: 'auth' | 'registration' | 'setPassword' | 'restorePassword' | 'emailConfirmation'
}

const Login: FC<ILogin> = ({ type }) => {
    const { t } = useTranslation()

    const [certificatesList, setCertificatesList] = useState<ICertificates | null>(null)

    const clearCertificates = () => {
        setCertificatesList(null)
    }

    const onCertificateLogin = async () => {
        const allCertificates = await SignUtils.getAllCertificates()
        setCertificatesList(allCertificates)
    }

    return (
        <div className={style.loginMain}>
            <div className={style.loginBlock}>
                <div className={style.loginForm}>
                    {type === 'auth' ? (
                        <Auth onCertificateLogin={onCertificateLogin} />
                    ) : type === 'setPassword' ? (
                        <SetPassword />
                    ) : type === 'restorePassword' ? (
                        <Register isRestorePassword />
                    ) : type === 'emailConfirmation' ? (
                        <Register isEmailConfirmation />
                    ) : (
                        <Register isRestorePassword={false} />
                    )}
                </div>
                <div className={style.authImg} />
            </div>
            {type === 'auth' && (
                <>
                    <div className={style.formBlockInfo}>
                        <div>{t('login.noAccount')}</div>
                        <Link className={style.linkRegister} to="/register">
                            {t('login.signUp')}
                        </Link>
                    </div>
                    {certificatesList && (
                        <CertificatesModal
                            allCertificates={certificatesList}
                            clearCertificates={clearCertificates}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default Login
