import { FC, useState } from 'react'
import { IconButton, DeleteModal, CenteredSpin } from '../../../ui'
import { DeregisterModal } from '../'
import { Trash, AddCircle } from '../../../../assets/icons'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import request from '../../../../services/request'
import { useNavigate } from 'react-router-dom'

interface IControlsBlockFiled {
    name?: string
    id?: string
    showDelete?: boolean
    idDeleted?: boolean
}

const ControlsBlockFiled: FC<IControlsBlockFiled> = ({ name, id, showDelete, idDeleted }) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDeregisterModalVisible, setIsDeregisterModalVisible] = useState(false)

    const openDeregisterModal = () => {
        setIsDeregisterModalVisible(true)
    }

    const [loading, setLoading] = useState(false)

    const handleDelete = async () => {
        try {
            setLoading(true)
            await request.delete(`/api/generators/v1/go?generator_id=${id}`)
            navigate(-1)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={style.controlsWrapper}>
            {loading && <CenteredSpin />}
            {idDeleted && <IconButton
                onClick={openDeregisterModal}
                customStyle={{ marginRight: '16px' }}
                customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                icon={<AddCircle />}
            >
                Создать на основе
            </IconButton>}
            {showDelete && (
                <IconButton
                    customStyle={{ marginRight: '16px' }}
                    icon={<Trash />}
                    onClick={() => setIsDeleteModalVisible(true)}
                >
                    Удалить
                </IconButton>
            )}
            <DeregisterModal
                isModalVisible={isDeregisterModalVisible}
                setIsModalVisible={setIsDeregisterModalVisible}
            />
            <DeleteModal
                headerText="Удалить заявку"
                messageText={`Вы действительно хотите безвозвратно удалить несогласованную заявку по добавлению ГО ${name}?`}
                isModalVisible={isDeleteModalVisible}
                onCancel={() => setIsDeleteModalVisible(false)}
                onDelete={handleDelete}
            />
        </div>
    )
}

export default ControlsBlockFiled
