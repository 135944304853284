import { ConfigProvider, Table } from 'antd'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import { LoadingContainer } from '../../../ui'
import style from './style.m.less'
import useMount from '../../../hooks/useMount'
import { getCaList } from '../../config'
import { renderDayMonthYear } from '../../../../utils/formatter'
import { monthPicker } from '../../../helpers'
import useRequest from '../../../hooks/useRequest'

export const getStatus = (option: number) => {
    switch (option) {
        case 1:
            return <div className={style.statusNew}>Новая</div>
        case 2:
            return <div className={style.statusNew}>На рассмотрении</div>
        case 3:
            return <div className={style.statusCancel}>Отказана</div>
        case 4:
            return <div className={style.statusAgreed}>Согласована</div>
        default:
            return <></>
    }
}

type PaginationType = {
    page: number
    pageSize: number
    filters?: any
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

const COLUMNS = () => [
    {
        title: 'Отчетный период',
        dataIndex: 'month',
        key: 'month',
        render: (month: number, record: any) => `${monthPicker(month)}, ${record.year}`,
    },
    {
        title: 'Дата подачи',
        dataIndex: 'ts_created',
        key: 'ts_created',
        render: (date: Date) => renderDayMonthYear(date),
    },
    {
        title: 'Статус',
        dataIndex: 'status_id',
        key: 'status',
        render: (status_id: number) => getStatus(status_id),
    },
]

const KUTable: FC = () => {
    const navigate = useNavigate()
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const { fetch, result, isLoading } = useRequest(getCaList)

    const getData = ({ page = 1, pageSize = 10 }: PaginationType) => {
        fetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
            },
        })
    }

    useMount(() => {
        fetch({})
    })

    return (
        <LoadingContainer isLoading={isLoading || !result}>
            <div className={style.opened}>
                <div className={style.table}>
                    <ConfigProvider locale={ruRU}>
                        <Table
                            dataSource={result?.submissions || []}
                            columns={COLUMNS()}
                            rowClassName={(record) => (record.has_update ? style.rowBold : '')}
                            pagination={{
                                position: ['bottomLeft'],
                                onChange: (page, pageSize) => {
                                    getData({ page, pageSize })
                                    setPagination({ page, pageSize })
                                },
                                total: result?.total,
                                current: pagination.page,
                                pageSize: pagination.pageSize,
                                showSizeChanger: true,
                            }}
                            onRow={(record) => ({
                                onClick: () => {
                                    navigate('/ku-owner-card/' + record.id)
                                },
                            })}
                            loading={isLoading}
                        />
                    </ConfigProvider>
                </div>
            </div>
        </LoadingContainer>
    )
}

export default KUTable
