// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controlsWrapper_pgw5g {\n  margin-top: 32px;\n  margin-bottom: 8px;\n  display: flex;\n}\n.lockIcon_SkUlg {\n  color: aqua;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/ControlsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAEA;EACI,WAAA;AAAJ","sourcesContent":[".controlsWrapper {\n    margin-top: 32px;\n    margin-bottom: 8px;\n    display: flex;\n}\n\n.lockIcon {\n    color: aqua;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlsWrapper": "controlsWrapper_pgw5g",
	"lockIcon": "lockIcon_SkUlg"
};
export default ___CSS_LOADER_EXPORT___;
