import { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Alert, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import {
    ChangeEmailModal,
    RemoveRequestModal,
    ESignatureModal,
    DocumentsGrid,
    ChangePasswordModal,
    HandleBlockModal,
    BlockReasonModal,
} from '../'
import { useSelector } from 'react-redux'
import IAppState from '../../../../store/states'
import { getUserName } from '../../../../utils/formatter'
import { GeneratorInfoFrame, IconButton, CenteredSpin } from '../../../ui'
import { Key, Edit, Stamp, Email, CloseCircle, Lock } from '../../../../assets/icons'
import { profileInfoConfig } from '../../../configuration/profileInfoConfig'
import { IUserInfo } from '../../../../store/user/interfaces'
import useRequest from '../../../hooks/useRequest'
import { requestBlockConfig, requestResetPasswordConfig } from './configs'

import style from './style.m.less'
import moment from 'moment'

interface IGeneralInfo {
    user?: {
        data: IUserInfo
        isLoading: boolean
        error: string
        actualData: IUserInfo
    }
    updateUser?: () => void
}

const GeneralInfo: FC<IGeneralInfo> = ({ user, updateUser }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
    const [isRemoveRequestModalVisible, setIsRemoveRequestModalVisible] = useState(false)
    const [isESignatureModalVisible, setIsESignatureModalVisible] = useState(false)
    const [isHandleBlockModalVisible, setIsHandleBlockModalVisible] = useState(false)
    const [isBlockReasonModalVisible, setIsBlockReasonModalVisible] = useState(false)

    const openEmailModal = () => {
        setIsEmailModalVisible(true)
    }

    const handlePasswordModal = () => {
        setIsPasswordModalVisible(!isPasswordModalVisible)
    }

    const handleBlockReasonModal = () => {
        setIsBlockReasonModalVisible(!isBlockReasonModalVisible)
    }

    const openESignatureModalModal = () => {
        setIsESignatureModalVisible(true)
    }

    const openRemoveRequestModal = () => {
        setIsRemoveRequestModalVisible(true)
    }

    const toggleHandleBlockModal = () => {
        setIsHandleBlockModalVisible(!isHandleBlockModalVisible)
    }

    const userInfo = useSelector((store: IAppState) => store.user)

    const { data, actualData, error, isLoading } = user || userInfo
    const { id: userId } = data || {}
    const {
        data: { id: currentUserId },
    } = userInfo || {}
    const isCurrentUser = userId === currentUserId

    if (error === 'no refresh token') {
        navigate('/auth')
        window.location.reload()
    }

    const { fetch: fetchReset } = useRequest(requestResetPasswordConfig)

    const { fetch: fetchBlock } = useRequest(requestBlockConfig)

    const resetPassword = () => {
        fetchReset({
            getParams: { uid: data.uid },
            successMessage: {
                title: 'Успешно',
                description: 'Ссылка для создания нового пароля отправлена на почту пользователю',
            },
            onSuccess: () => {
                handlePasswordModal()
            },
        })
    }

    const getEditLink = () => {
        if (user) {
            return `/user/edit/${user.data.uid}`
        }
        return isShortProfile ? '/edit' : '/edit-form'
    }

    const handleBlock = (body: {
        need_block_add: boolean
        reason: string
        unblock_conditions: string
        documents: any[]
    }) => {
        const isBlocked = data.is_blocked
        fetchBlock({
            getParams: { uid: data.uid },
            body: {
                ...body,
                need_block_add: !isBlocked,
            },
            errorCodeMessage: true,
            successMessage: {
                title: 'Успешно',
                description: `Пользователь ${getUserName(user?.data)} ${
                    isBlocked ? 'разблокирован' : 'заблокирован'
                }`,
            },
            onSuccess: () => {
                updateUser && updateUser()
                toggleHandleBlockModal()
            },
        })
    }

    const isShortProfile =
        data?.user_role === 1 &&
        (data?.profile_status === 0 || data?.profile_status === 1 || data?.profile_status === 2)

    const isDataInProcessing =
        data?.profile_status === 1 || data?.profile_status === 2 || data?.profile_status === 4

    const isRoleMatches = data?.user_role === actualData?.user_role

    const isAdminView = !!user

    const isAdmin = data?.user_role === 6 || data?.user_role === 5

    if (isLoading) {
        return <CenteredSpin />
    }

    return (
        <div className={style.generalInfo}>
            <GeneratorInfoFrame
                label={'Общая информация'}
                config={profileInfoConfig(data)}
                customStyle={{ marginTop: 0 }}
                userRole={data?.user_role}
                bottomChildren={
                    <>
                        {data?.user_role !== 1 && (
                            <>
                                {!isAdmin && (
                                    <>
                                        <Divider type={'horizontal'} />
                                        <div className={style.profileHeader}>
                                            {t('myProfile.eSignature')}
                                        </div>
                                        {data?.certificate_data && (
                                            <>
                                                <div
                                                    className={
                                                        moment(
                                                            data?.certificate_data.token_expiration,
                                                        ).isAfter()
                                                            ? style.stampBlock
                                                            : style.expiredStampBlock
                                                    }
                                                    onClick={openESignatureModalModal}
                                                >
                                                    <Stamp />
                                                    <div className={style.stampOwner}>
                                                        {data.certificate_data !== null
                                                            ? `${data.certificate_data.sur_name} ${data.certificate_data.common_name}`
                                                            : ''}
                                                    </div>
                                                </div>
                                                {!moment(
                                                    data?.certificate_data.token_expiration,
                                                ).isAfter() && (
                                                    <Alert
                                                        className={style.responseError}
                                                        message={'Истек срок действия ЭП'}
                                                        type="error"
                                                        showIcon
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {data?.documents && (
                                    <>
                                        <Divider type={'horizontal'} />
                                        <div className={style.profileHeader}>
                                            {t('documents.docs')}
                                        </div>
                                        <DocumentsGrid documents={data?.documents} />
                                    </>
                                )}
                                {isAdmin && (
                                    <>
                                        <Divider type={'horizontal'} />
                                        <div className={style.authorityTitle}>
                                            Документ, подтверждающий полномочия административного
                                            пользователя
                                        </div>
                                        <div className={style.authorityWrapper}>
                                            <div>
                                                <div className={style.authorityRow}>
                                                    <div className={style.authorityName}>
                                                        Наименование документа
                                                    </div>
                                                    <div>{data.document_custom_name}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={style.authorityRow}>
                                                    <div className={style.authorityName}>Номер</div>
                                                    <div>{data.document_custom_number}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={style.authorityRow}>
                                                    <div className={style.authorityName}>
                                                        Дата выдачи
                                                    </div>
                                                    <div>
                                                        {moment(
                                                            data.document_custom_betrayed_date,
                                                        ).format('DD.MM.YYYY')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={style.authorityRow}>
                                                    <div className={style.authorityName}>
                                                        Дата истечения срока действия
                                                    </div>
                                                    <div>
                                                        {moment(
                                                            data.document_custom_end_date,
                                                        ).format('DD.MM.YYYY')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className={style.authorityRow}>
                                                    <div className={style.authorityName}>
                                                        Кем выдан
                                                    </div>
                                                    <div>{data.document_custom_betrayed_name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <Divider type={'horizontal'} />
                        <div className={style.buttons}>
                            <div className={style.configButtonsBlock}>
                                <Link to={getEditLink()} state={{ type: 'profile' }}>
                                    <IconButton
                                        disabled={[1, 2].includes(data?.profile_status)}
                                        icon={<Edit />}
                                    >
                                        Редактировать профиль
                                    </IconButton>
                                </Link>
                                {user ? (
                                    <IconButton onClick={handlePasswordModal} icon={<Key />}>
                                        Сбросить пароль
                                    </IconButton>
                                ) : (
                                    <Link to="/change-password">
                                        <IconButton icon={<Key />}>Сменить пароль</IconButton>
                                    </Link>
                                )}
                                <IconButton
                                    onClick={openEmailModal}
                                    icon={<Email />}
                                    customIconStyle={{ marginRight: '8px', marginTop: '4px' }}
                                >
                                    Сменить e-mail
                                </IconButton>
                            </div>
                            {isAdminView && (
                                <IconButton
                                    className={style.gray}
                                    onClick={toggleHandleBlockModal}
                                    icon={<Lock color="currentColor" />}
                                >
                                    {data.is_blocked ? 'Разблокировать' : 'Заблокировать'}
                                </IconButton>
                            )}
                        </div>
                    </>
                }
                isProfile
            >
                {isAdminView && data?.is_blocked && (
                    <Alert
                        type="error"
                        showIcon
                        message={
                            <div>
                                <div>Пользователь заблокирован</div>
                                <div onClick={handleBlockReasonModal} className={style.blockReason}>
                                    Посмотреть причину
                                </div>
                            </div>
                        }
                        className={style.alert}
                    />
                )}
                {data?.profile_status === 0 && isCurrentUser && (
                    <Alert
                        message={
                            <span>
                                <Link to="/continue-registration">
                                    Заключить договор о ведении реестра
                                </Link>
                                , чтобы получить полный доступ к работе с зелеными инструментами.
                            </span>
                        }
                        type="info"
                        showIcon
                        className={style.alert}
                    />
                )}
                {data?.email_status === 2 && isCurrentUser && (
                    <Alert
                        message="E-mail был изменен. После перехода по ссылке в почте - в профиле появится новый e-mail."
                        type="info"
                        showIcon
                        className={style.alert}
                    />
                )}
                {actualData?.profile_status === 0 && isDataInProcessing && (
                    <Alert
                        message="Данные находятся в обработке. После проверки оператором будет открыт платежный счет."
                        type="info"
                        showIcon
                        className={style.alert}
                    />
                )}
                {actualData?.profile_status === 5 && isDataInProcessing && isRoleMatches && (
                    <Alert
                        message="Данные находятся в обработке. После проверки оператором вы снова сможете редактировать профиль."
                        type="info"
                        showIcon
                        className={style.alert}
                    />
                )}
                {actualData?.profile_status === 5 && isDataInProcessing && !isRoleMatches && (
                    <Alert
                        message="Данные находятся в обработке. После проверки оператором роль будет изменена."
                        type="info"
                        showIcon
                        className={style.alert}
                    />
                )}
                {data?.profile_status === 3 && (
                    <Alert
                        className={style.generalInfoWarning}
                        message={
                            <>
                                <span className={style.generalInfoWarningMessage}>
                                    Данные отправлены на доработку
                                </span>
                                <br />
                                <span>{data?.profile_comment}</span>
                                <div
                                    className={style.generalInfoWarningWrapper}
                                    onClick={openRemoveRequestModal}
                                >
                                    <CloseCircle />
                                    <span className={style.generalInfoWarningButton}>
                                        Снять заявку
                                    </span>
                                </div>
                            </>
                        }
                        type="warning"
                    />
                )}
                {data?.profile_status === 6 && (
                    <div className={style.statusNotAgreedContainer}>
                        <div className={style.statusText}>Данные не приняты. Причина:</div>
                        <div className={style.statusReason}>{data?.profile_comment}</div>
                    </div>
                )}
            </GeneratorInfoFrame>

            <ChangeEmailModal
                isModalVisible={isEmailModalVisible}
                setIsModalVisible={setIsEmailModalVisible}
                currentEmail={data.email}
                userId={isAdminView && data.uid}
            />
            {isAdminView && (
                <ChangePasswordModal
                    isVisible={isPasswordModalVisible}
                    email={data.email}
                    onClose={handlePasswordModal}
                    onOk={resetPassword}
                />
            )}
            {isAdminView && (
                <HandleBlockModal
                    onOk={handleBlock}
                    onCancel={toggleHandleBlockModal}
                    isVisible={isHandleBlockModalVisible}
                    isUnBlocking={data.is_blocked}
                    blockingUserRole={data.user_role}
                />
            )}
            {user && (
                <BlockReasonModal
                    isVisible={isBlockReasonModalVisible}
                    onCancel={handleBlockReasonModal}
                    reason={data.block_unblock_reason}
                    documents={data.block_unblock_documents}
                />
            )}
            <ESignatureModal
                certificateData={data.certificate_data}
                requestId={data.request_id}
                isModalVisible={isESignatureModalVisible}
                setIsModalVisible={setIsESignatureModalVisible}
            />
            <RemoveRequestModal
                isModalVisible={isRemoveRequestModalVisible}
                setIsModalVisible={setIsRemoveRequestModalVisible}
            />
        </div>
    )
}

export default GeneralInfo
