import { FC } from 'react'

import { Modal, Checkbox } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'

import style from './style.m.less'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface ISubscribeModal {
    isVisible: boolean
    onCancel: () => void
    onSubscribe: () => void
}

const SubscribeModal: FC<ISubscribeModal> = ({ isVisible, onCancel, onSubscribe }) => {
    const { t } = useTranslation()

    return (
        <Modal isModalVisible={isVisible} onCancel={onCancel}>
            <div className={style.wrap}>
                <div className={style.title}>{t('newsAndEvents.subscribe2')}</div>
                <div className={style.email}>
                    <div>E-mail</div>
                    <div className={style.inputBlock}>
                        <Input className={style.input} defaultValue="makaroff@mail.ru" />
                    </div>
                </div>
                <div className={style.checkboxes}>
                    <div className={style.checkboxTitle}>{t('newsAndEvents.iWant')}</div>
                    <div className={style.checkbox}>
                        <Checkbox defaultChecked>{t('newsAndEvents.news')}</Checkbox>
                    </div>
                    <div className={style.checkbox}>
                        <Checkbox defaultChecked>{t('newsAndEvents.events')}</Checkbox>
                    </div>
                </div>
                <div className={style.buttonsBlock}>
                    <ButtonsBlock
                        confirmAction={onSubscribe}
                        cancelAction={onCancel}
                        confirmTitle={'Подписаться'}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default SubscribeModal
