// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonContainer_dk7ez {\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n}\n.buttonContainerCancel__IJKg {\n  border: 1px solid #ff7875;\n  border-radius: 8px;\n  color: #ff4d4f;\n}\n.buttonContainerCancel__IJKg:hover,\n.buttonContainerCancel__IJKg:focus {\n  border: 1px solid #ff7875;\n  color: #ff4d4f;\n}\n.buttonContainerApprove_D_pYU {\n  background: #e6f1ff;\n  border-radius: 8px;\n  color: #296dff;\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOApprovalItem/components/ButtonContainer/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;AAEA;EACI,yBAAA;EACA,kBAAA;EACA,cAAA;AAAJ;AACI;;EAEI,yBAAA;EACA,cAAA;AACR;AAGA;EACI,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AADJ","sourcesContent":[".buttonContainer {\n    display: flex;\n    justify-content: space-between;\n    gap: 16px;\n}\n\n.buttonContainerCancel {\n    border: 1px solid #ff7875;\n    border-radius: 8px;\n    color: #ff4d4f;\n    &:hover,\n    &:focus {\n        border: 1px solid #ff7875;\n        color: #ff4d4f;\n    }\n}\n\n.buttonContainerApprove {\n    background: #e6f1ff;\n    border-radius: 8px;\n    color: #296dff;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "buttonContainer_dk7ez",
	"buttonContainerCancel": "buttonContainerCancel__IJKg",
	"buttonContainerApprove": "buttonContainerApprove_D_pYU"
};
export default ___CSS_LOADER_EXPORT___;
