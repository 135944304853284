import { Typography } from 'antd'
import React, { useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { Box } from '../Box'
import style from './style.m.less'

type Props = {
    label?: string
    min?: number
    max?: number
    onChange?: any
    value?: number | null
    form?: any
    itemName?: string
}

const CurrInput = ({
    label = 'Сумма, ₽',
    min = 0.01,
    max = 999999999999.99,
    onChange,
    value,
}: Props) => {
    const [amount, setAmount] = useState<string | null>(String(value) || null)

    function checkMinMax(value: string | null) {
        if (!value) {
            return true
        }
        const sum = Number(value.replace(',', '.'))
        return sum > min && sum <= max
    }

    const changeHandle = (val: string) => {
        if (onChange) {
            onChange(val)
        }
        setAmount(val)
    }

    const isValidateError = value && !checkMinMax(amount)

    return (
        <Box margin="20px 0px 0px 0px">
            <Typography.Text className={style.required}>{label || 'Сумма, ₽'}</Typography.Text>
            <CurrencyInput
                placeholder="Укажите сумму"
                allowDecimals
                decimalsLimit={2}
                value={!value ? '' : value ? value : amount ? amount : ''}
                className="ant-input"
                style={{
                    marginTop: 5,
                    borderColor: isValidateError ? 'red' : '',
                }}
                onValueChange={(value: any) => changeHandle(value)}
            />
            {isValidateError ? (
                <Typography.Text type="danger" style={{ fontSize: '12px' }}>
                    {`Сумма должна быть в пределах от ${min} до ${max}`}
                </Typography.Text>
            ) : (
                <Box height={10} />
            )}
        </Box>
    )
}

export default CurrInput
