import { FC } from 'react'
import { Divider, Tooltip } from 'antd'
import { PowerTypeTag, CardInfoBlock } from '../'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Flash, Calendar, Location } from '../../../assets/icons'
import { energyTypePicker } from '../../helpers'
import { PageType } from '../interfaces'
import { IGeneratingObject } from '../../../store/generators/interfaces'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

interface IGeneratorCard {
    generatorData: IGeneratingObject
    type: PageType
}

const GeneratorCard: FC<IGeneratorCard> = ({ generatorData, type }) => {
    const {
        id,
        name,
        power,
        qualification_date,
        last_check,
        country,
        region,
        type_renewable_energy_id,
    } = generatorData

    const { t } = useTranslation()

    const energyType = energyTypePicker(type_renewable_energy_id)

    const infoRowSelector = () =>
        type === 'owners' ? (
            <>
                <CardInfoBlock label={t('generatorCard.webSide')} value={'www.nord-hydro.ru'} />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock label={t('generatorCard.email')} value={'info@nord-hydro.ru'} />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label={t('generatorCard.powerSum')}
                    value={`${power} ${t('generatorCard.scale')}`}
                    icon={<Flash />}
                    gap={7}
                />
            </>
        ) : (
            <>
                <CardInfoBlock
                    label={t('generatorCard.power')}
                    value={`${power} ${t('generatorCard.scale')}`}
                    icon={<Flash />}
                    gap={7}
                />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label={t('generatorCard.qualifyDate')}
                    value={moment(qualification_date).format('DD.MM.YYYY')}
                    icon={<Calendar />}
                    gap={6}
                />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label={t('generatorCard.lastCheckDate')}
                    value={moment(last_check).format('DD.MM.YYYY')}
                    icon={<Calendar />}
                    gap={6}
                />
            </>
        )

    return (
        <div className={style.cardMain}>
            <div>
                <Link
                    to={{
                        pathname: `/generator-card/${id}`,
                    }}
                    state={{ generatorData, type }}
                    className={style.cardHeader}
                >
                    {name}
                </Link>
                {type === 'userGenerators' && (
                    <Tooltip
                        placement="top"
                        color="#434343"
                        overlayInnerStyle={{ color: 'white' }}
                        title="Свободно атрибутов генерации"
                    >
                        <div className={style.freeGenerationAttributes}>
                            15 000 000 {t('generatorCard.scale2')}
                        </div>
                    </Tooltip>
                )}
                <div className={style.infoRow}>{infoRowSelector()}</div>
                {type !== 'owners' && (
                    <div className={style.locationBlock}>
                        <CardInfoBlock
                            value={`${country}, ${region}`}
                            icon={<Location />}
                            gap={11}
                        />
                    </div>
                )}
            </div>
            <PowerTypeTag value={energyType}>{energyType}</PowerTypeTag>
        </div>
    )
}

export default GeneratorCard
