import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const CloseCircle: FC<IIconProps> = ({ size = 16 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.9987 14.6673C11.6654 14.6673 14.6654 11.6673 14.6654 8.00065C14.6654 4.33398 11.6654 1.33398 7.9987 1.33398C4.33203 1.33398 1.33203 4.33398 1.33203 8.00065C1.33203 11.6673 4.33203 14.6673 7.9987 14.6673Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.11328 9.88661L9.88661 6.11328"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.88661 9.88661L6.11328 6.11328"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
