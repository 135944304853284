import { FC } from 'react'
import { Collapse, CollapsePanelProps } from 'antd'
import classNames from 'classnames'

import style from './style.m.less'
import { Card } from '../../../ui'
import { Down } from '../../../../assets/icons'

const { Panel: AntPanel } = Collapse

const CardPanel: FC<CollapsePanelProps> = (props) => {
    const realHeader = (
        <div className={classNames(style.header, { [style.active]: (props as any).isActive })}>
            {props.header}
            <span className={style.icon}>
                <Down />
            </span>
        </div>
    )
    return (
        <Card className={style.card}>
            <AntPanel showArrow={false} {...props} header={realHeader} className={style.panel} />
        </Card>
    )
}

export default CardPanel
