import { Table } from 'antd'
import { FC } from 'react'

import style from './style.m.less'

import { columns } from './columns'
import { dataSource } from './data'

const Tables: FC = () => {
    return (
        <div className={style.opened}>
            <div className={style.tableName}>Солнечная электростанция "Абаканская"</div>
            <div className={style.table}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{
                        position: ['bottomLeft'],
                        pageSize: 30,
                        showSizeChanger: true,
                    }}
                />
            </div>
        </div>
    )
}

export default Tables
