import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import style from './style.m.less'

const FooterUser: FC = () => {
    const { t } = useTranslation()

    return (
        <div>
            <div className={style.footerHeader}>{t('footer.account')}</div>
            <div className={style.userList}>
                <NavLink className={style.listItem} to={'auth'}>
                    {t('footer.signIn')}
                </NavLink>
                <NavLink className={style.listItem} to={'register'}>
                    {t('footer.singUp')}
                </NavLink>
            </div>
        </div>
    )
}

export default FooterUser
