// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper_klz92 {\n  margin-bottom: 8px;\n}\n.headerWrapper_BZ_nq {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.header_RQYhU {\n  font-size: 16px;\n  line-height: 24px;\n}\n.text_wEywi {\n  width: 360px;\n  padding: 0 40px;\n}\n.closeButton_JsZ5t {\n  display: flex;\n  align-items: center;\n  color: #bfbfbf;\n  cursor: pointer;\n}\n.close_Hb4rv {\n  display: flex;\n  align-items: flex-end;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/ReasonModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAGA;EACI,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,YAAA;EACA,eAAA;AAFJ;AAKA;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AAHJ;AAMA;EACI,aAAA;EACA,qBAAA;EACA,cAAA;AAJJ","sourcesContent":[".modalWrapper {\n    margin-bottom: 8px;\n}\n\n.headerWrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.header {\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.text {\n    width: 360px;\n    padding: 0 40px;\n}\n\n.closeButton {\n    display: flex;\n    align-items: center;\n    color: #bfbfbf;\n    cursor: pointer;\n}\n\n.close {\n    display: flex;\n    align-items: flex-end;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper_klz92",
	"headerWrapper": "headerWrapper_BZ_nq",
	"header": "header_RQYhU",
	"text": "text_wEywi",
	"closeButton": "closeButton_JsZ5t",
	"close": "close_Hb4rv"
};
export default ___CSS_LOADER_EXPORT___;
