import { put, StrictEffect, takeLatest } from 'redux-saga/effects'
import { getUserInfo, updateUserInfo } from './index'
import { createFIO } from '../../components/helpers'
import { fetchUserInfo } from '../../services/user'

function* handleGetUserInfo() {
    try {
        yield put(updateUserInfo({ error: '', isLoading: true }))
        const { data, error } = yield fetchUserInfo()

        if (error) {
            throw error
        }

        const FIO = createFIO(data)

        const storage = localStorage.getItem('accessToken') ? localStorage : sessionStorage

        storage.setItem('FIO', FIO)

        const res = yield fetchUserInfo(true)
        const actualData = res.data
        const secondError = res.error

        if (secondError) {
            throw secondError
        }

        yield put(updateUserInfo({ isLoading: false, data: data, actualData: actualData }))
    } catch (error) {
        yield put(updateUserInfo({ error, isLoading: false, data: {}, actualData: {} }))
    }
}

export function* watchUserInfo(): Generator<StrictEffect> {
    yield takeLatest(getUserInfo, handleGetUserInfo)
}
