import { FC } from 'react'
import { Divider } from 'antd'
import { Alert, ImportButton } from '../../../ui'
import style from './style.m.less'
import { Edit, Search, Trash } from '../../../../assets/icons'

export interface ITsoGp {
    company_inn: string
    company_name_short: string
    kpp: string
    ogrn: string
    uid?: string
}

interface ISelectedTsoGp2 {
    label: string
    selectedTsoGp?: ITsoGp
    required?: boolean
    onRemoveClick(): void
    onEditClick(): void
    onSearchClick(): void
    onManualEditClick(): void
}

const SelectedTsoGp2: FC<ISelectedTsoGp2> = ({
    label,
    selectedTsoGp,
    required,
    onRemoveClick,
    // onEditClick,
    onSearchClick,
    onManualEditClick,
}) => {
    return (
        <>
            <div className={style.inputWrapper}>
                <div className={style.label}>
                    {label}
                    {required && <span className={style.star}>*</span>}
                </div>
                {selectedTsoGp && (
                    <Alert
                        className={style.resultLegalAlert}
                        message={
                            <div className={style.alertTitleWrapper}>
                                <span className={style.alertTitle}>
                                    {selectedTsoGp.company_name_short}
                                </span>
                                <div className={style.alertIconWrapper}>
                                    {/* <span className={style.icon} onClick={onEditClick}>
                                        <Edit />
                                    </span> */}
                                    <span className={style.icon} onClick={onRemoveClick}>
                                        <Trash />
                                    </span>
                                </div>
                            </div>
                        }
                        description={
                            <>
                                <span className={style.alertDetail}>
                                    ИНН - {selectedTsoGp.company_inn}
                                </span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>
                                    ОГРН - {selectedTsoGp.ogrn}
                                </span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>КПП - {selectedTsoGp.kpp}</span>
                            </>
                        }
                        type="info"
                        showIcon={false}
                    />
                )}

                {!selectedTsoGp && (
                    <div className={style.linkWrapper}>
                        <ImportButton
                            value="Найти в системе"
                            icon={<Search />}
                            onClick={onSearchClick}
                            type={'button'} // убрал срабатывание валидации (по дефолту тип button is submit)
                        />
                        <Divider type="vertical" />
                        <ImportButton
                            value="Заполнить вручную"
                            icon={<Edit />}
                            onClick={onManualEditClick}
                            type={'button'}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default SelectedTsoGp2
