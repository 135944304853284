import { FC, useEffect } from 'react'
import { Empty } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Table } from 'antd'
import { getNotifications } from '../../store/notifications'
import IAppState from '../../store/states'
import { Calendar, Flame } from '../../assets/icons'
import { LoadingContainer } from '../ui'

import style from './style.m.less'

interface INotifications {
    setReaded: () => void
}

const Notifications: FC<INotifications> = ({ setReaded }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getNotifications({ limit: 500 }))
        return () => {
            setReaded()
        }
    }, [])

    const notifications = useSelector((store: IAppState) => store.notifications)
    const { feeds, total, isLoading, needReload } = notifications

    useEffect(() => {
        if (needReload) {
            dispatch(getNotifications({ limit: 1000 }))
        }
    }, [needReload])

    const columns = [
        {
            title: 'Element',
            dataIndex: 'element',
        },
    ]

    const getDataSource = () =>
        feeds?.map((item) => {
            const { id, is_unread, title, ts_created, links } = item
            let { message } = item

            if (links.length !== 0) {
                const messages = message.split('{{1}}')

                const { pathname } = links[0].link.includes('http')
                    ? new URL(links[0].link)
                    : { pathname: links[0].link }

                const certState = {
                    toolType: 'Сертификаты происхождения',
                    cardLabel: 'Сертификат происхождения',
                    isUserType: true,
                    isIssuedCert: true,
                    id: links[1]?.link,
                }

                message = (
                    <>
                        {messages[0]}
                        <Link
                            to={pathname}
                            state={links[1]?.name === 'id сертификата' ? certState : {}}
                        >
                            {links[0].name}
                        </Link>
                        {messages[1]}
                    </>
                )
            }

            return {
                key: id,
                element: (
                    <div>
                        <div className={is_unread ? style.rowTitle : style.rowTitleCommon}>
                            {is_unread && <span className={style.bullet}>•</span>}
                            {title}
                        </div>
                        <div className={is_unread ? style.rowMessage : style.rowMessageCommon}>
                            {message}
                        </div>
                        <div className={style.rowTime}>
                            <Calendar />
                            <div className={style.timeBlock}>
                                {moment.unix(ts_created).format('DD.MM.YYYY HH:mm')}
                            </div>
                        </div>
                    </div>
                ),
            }
        })

    const closedDataSource = getDataSource()

    return (
        <>
            <div className={style.notificationsMain}>
                <div className={style.notificationsHeader}>{t('notifications.notifications')}</div>
                <LoadingContainer isLoading={isLoading}>
                    {!!total && (
                        <div className={style.notificationsFrame}>
                            {feeds[0].is_unread === true ? (
                                <div className={style.tableHeader}>
                                    <Flame />
                                    <div className={style.headerTitle}>Новые</div>
                                </div>
                            ) : (
                                <div className={style.tableHeader}>Прочитанные</div>
                            )}
                            <Table
                                columns={columns}
                                dataSource={closedDataSource}
                                showHeader={false}
                                pagination={{
                                    position: ['bottomLeft'],
                                    total: feeds?.length,
                                    pageSize: 10,
                                }}
                                onChange={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            ></Table>
                        </div>
                    )}
                    {!total && (
                        <div className={style.notificationsFrameWithoutPadding}>
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <div>
                                        У вас пока нет <br />
                                        уведомлений
                                    </div>
                                }
                            />
                        </div>
                    )}
                </LoadingContainer>
            </div>
        </>
    )
}

export default Notifications
