import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Alert, Checkbox, Input, Typography } from 'antd'
import { Modal, CenteredSpin } from '../../../ui'
import { encode, btoa } from 'js-base64'
import { ButtonsBlock } from '../../../Edit/components'
import { openAccountService } from '../../../../services/accounts'
import { errorHandler } from '../../../helpers'
import { useAppSelector } from '../../../../store/hooks'
import SignUtils from '../../../../utils/sign/SignUtils'
import { hexToBinary } from '../../../helpers'
import { getAccounts } from '../../../../store/accounts'
import { useGetTariffRate } from '../../../hooks'
import { v4 as uuidv4 } from 'uuid'
import style from './style.m.less'

interface IOpenFirstAccountModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const OpenFirstAccountModal: FC<IOpenFirstAccountModal> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [name, setName] = useState<string>('')
    const [responseError, setResponseError] = useState<string>('')

    const userInfo = useAppSelector((store) => store.user)
    const { data } = userInfo

    const onChangeName = (e: any) => {
        setName(e.target.value)
    }

    const handleCancel = () => {
        setResponseError('')
        setIsModalVisible(false)
    }

    const { loadingTariffs, balance, rate } = useGetTariffRate({
        dependency: isModalVisible,
        id: 10,
    })

    const onFinish = async () => {
        //TODO вынести функционал подписывания в отдельный хук
        if (name !== '') {
            const requestUuid = uuidv4()
            const thumbprint = await SignUtils.getCertificateThumbprint(
                data.certificate_data.certificate,
            )
            const totalInfo = { name }
            const encodedInfo = encode(JSON.stringify(totalInfo))
            const encoded = requestUuid + encodedInfo
            const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
            const signature = btoa(hexToBinary(preSignature))

            await openAccountService({
                userInfo: totalInfo,
                uuid: requestUuid,
                sign: signature,
                onFinish: () => {
                    setIsModalVisible(false)
                    dispatch(getAccounts())
                },
                onError: (error) => {
                    setResponseError(errorHandler(error.err_code))
                },
            })
        } else {
            setResponseError('Введите наименование лицевого счета')
        }
    }

    if (loadingTariffs) {
        return <CenteredSpin />
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.modalHeader}>{t('myProfile.openPersonalAccount')}</div>
                <Alert
                    message={'Номер счета присваивается автоматически после открытия'}
                    className={style.alert}
                    type="info"
                    showIcon
                />
                <div className={style.modalText}>
                    <div>{t('myProfile.accountName')}</div>
                    <Input className={style.input} value={name} onChange={onChangeName} />
                </div>
                <Alert
                    className={style.modalAlert}
                    message={
                        <div className={style.modalAlertText}>
                            <div>
                                За операцию взымается оплата в размере{' '}
                                <Typography.Text strong>{rate}</Typography.Text>
                            </div>
                            <div>Баланс платежного счета: {balance}</div>
                        </div>
                    }
                    type="warning"
                    showIcon
                />
                {responseError && (
                    <Alert
                        className={style.responseError}
                        message={responseError}
                        type="error"
                        showIcon
                    />
                )}
                <div className={style.buttonsBlock}>
                    <ButtonsBlock
                        confirmAction={onFinish}
                        cancelAction={handleCancel}
                        confirmTitle={t('myProfile.openAccount')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default OpenFirstAccountModal
