import { FC, useState } from 'react'
import style from './style.m.less'
import { Form } from 'antd'
import { PrimaryIconButton } from '../../../../ui'
import { iconSelector } from '../../../../helpers'
import { CloseCircle, NoteAdd, Trash } from '../../../../../assets/icons'
import { formatBytes } from '../../../../../utils/bytes/bytes'
import TextArea from 'antd/lib/input/TextArea'

interface IDocument {
    file: any
    deleteFromFileList: (uid: string) => void
    withoutComment?: boolean
}

const Document: FC<IDocument> = ({ file, deleteFromFileList, withoutComment = false }) => {
    const [isTextAreaVisible, setIsTextAreaVisible] = useState(false)

    const showTextArea = () => {
        setIsTextAreaVisible(!isTextAreaVisible)
    }

    const deleteFile = () => {
        deleteFromFileList(file.uid ? file.uid : file.id)
    }

    return (
        <>
            <div className={style.documentsListWrapper}>
                <div className={style.documentWrapper}>
                    {iconSelector(file, 'name')}
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>{decodeURI(file.name)}</div>
                        <div className={style.fileSize}>{formatBytes(file.size)}</div>
                    </div>
                </div>
                <div className={style.iconWrapper}>
                    {!withoutComment && (
                        <PrimaryIconButton
                            onClick={showTextArea}
                            icon={isTextAreaVisible ? <CloseCircle /> : <NoteAdd />}
                        />
                    )}

                    <PrimaryIconButton
                        onClick={deleteFile}
                        isCancel
                        customStyle={{ marginLeft: '8px' }}
                        icon={<Trash />}
                    />
                </div>
            </div>
            {isTextAreaVisible && (
                <Form.Item name={file.uid ? file.uid : file.id}>
                    <TextArea
                        placeholder={'Введите описание документа'}
                        className={style.textArea}
                    />
                </Form.Item>
            )}
        </>
    )
}

export default Document
