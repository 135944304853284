import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'

const FooterSubscribe: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.footerSubscribe}>
            <div className={style.footerHeader}>{t('footer.subscribe')}</div>
            <div className={style.itemText}>
                <a className={style.navItem} target={'_blank'} href='https://lk.np-sr.ru/ru/subreg/register'>{t('footer.subscribeText')}</a>
            </div>
        </div>
    )
}

export default FooterSubscribe
