import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from '../../../ui'

import style from './style.m.less'

const TagFilter: FC = () => {
    const { t } = useTranslation()

    const [addingSelected, setAddingelected] = useState(true)
    const [editingSelected, setEditingSelected] = useState(false)
    const [continueSelected, setContinueSelected] = useState(false)

    return (
        <div className={style.tagFilter}>
            <Tag
                outline
                className={style.clickable}
                color={addingSelected ? 'blue' : undefined}
                onClick={() => setAddingelected((s) => !s)}
            >
                {t('questionnaireApproval.editing')}
            </Tag>
            <Tag
                outline
                className={style.clickable}
                color={editingSelected ? 'blue' : undefined}
                onClick={() => setEditingSelected((s) => !s)}
            >
                {t('questionnaireApproval.changeRole')}
            </Tag>
            <Tag
                outline
                className={style.clickable}
                color={continueSelected ? 'blue' : undefined}
                onClick={() => setContinueSelected((s) => !s)}
            >
                {t('questionnaireApproval.continuedRegistration')}
            </Tag>
        </div>
    )
}

export default TagFilter
