import { FC, useEffect, useState } from 'react'
import { AutoComplete, AutoCompleteProps } from 'antd'
import style from './style.m.less'

type IValueSelector = AutoCompleteProps & {
    placeholder?: string
    disabled?: boolean
    options?: any[]
    initValue?: string
    onSearch?: (value: string) => void
    value: string
    setValue: (arg: any) => void
    customStyle?: React.CSSProperties
    validate?: boolean
    validationMessage?: string
}

const { Option } = AutoComplete

const ValueSelector: FC<IValueSelector> = ({
    placeholder,
    disabled,
    options,
    onSearch,
    initValue,
    value,
    setValue,
    validate,
    validationMessage,
}) => {
    const [validator, setValidator] = useState(false)

    const onChange = (data: string) => {
        setValue(data)
        setValidator(true)
    }

    const onSelect = (data: string) => {
        setValue(data)
        setValidator(true)
    }

    useEffect(() => {
        if (initValue) {
            setValue(initValue)
            setValidator(true)
        }
    }, [initValue])

    return (
        <>
            <AutoComplete
                value={value}
                disabled={disabled}
                className={
                    validator && validate && value === ''
                        ? style.selectorError
                        : style.locationSelector
                }
                placeholder={placeholder}
                notFoundContent="Нет данных"
                onSearch={onSearch}
                onChange={onChange}
                onSelect={onSelect}
                filterOption={(input, option) =>
                    // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
                    (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                }
            >
                {options?.length !== 0 &&
                    options?.map((item) => {
                        const { company_name } = item
                        return <Option value={company_name}>{company_name}</Option>
                    })}
            </AutoComplete>
            {validator && validate && value === '' && validationMessage && (
                <div className={style.explain}>{validationMessage}</div>
            )}
        </>
    )
}

export default ValueSelector
