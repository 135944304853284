import { FC, useState } from 'react'
import { Form, Input, Checkbox } from 'antd'
import { Box, ValueSelector } from '../../../../../ui'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { getCompanyService } from '../../../../../../services/auth'
import style from './style.m.less'
import { lengthConfig } from '../../../../../helpers'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface IStepTwo {
    userType: string
    form: any
    middleName: string
    setMiddleName: any
    companyName: string
    setCompanyName: (arg: any) => void
    agreement: boolean
    setAgreement: (state: any) => void
}

const StepTwo: FC<IStepTwo> = ({
    userType,
    form,
    companyName,
    setCompanyName,
    agreement,
    setAgreement,
    middleName,
    setMiddleName,
}) => {
    const { t } = useTranslation()

    const [companies, setCompanies] = useState<any[]>([])
    const [isMiddleName, setIsMiddleName] = useState<boolean>(false)
    const [entrepreneurCompanyName, setEntrepreneurCompanyName] = useState(
        `ИП ${form.getFieldValue('last_name')} ${form.getFieldValue(
            'first_name',
        )} ${form.getFieldValue('middle_name')}`,
    )

    const refreshCompanyName = () => {
        setEntrepreneurCompanyName(
            `ИП ${form.getFieldValue('last_name')} ${form.getFieldValue(
                'first_name',
            )} ${form.getFieldValue('middle_name')}`,
        )
    }

    const onChange = (e: CheckboxChangeEvent) => {
        if (!isMiddleName) {
            setMiddleName('')
            setEntrepreneurCompanyName(
                `ИП ${form.getFieldValue('last_name')} ${form.getFieldValue('first_name')}`,
            )
        }
        setIsMiddleName(e.target.checked)
    }

    const handleSearch = async (value: string) => {
        if (value) {
            const res = await getCompanyService(value, (error) => {
                console.log(error)
            })
            setCompanies(res)
        } else {
            setCompanies([])
        }
    }

    const isIndividual = userType === 'Физическое лицо'
    const isEntity = userType === 'Юридическое лицо'
    const isEntrepreneur = userType === 'Индивидуальный предприниматель'
    const isEntityOrEntrepreneur =
        userType === 'Юридическое лицо' || userType === 'Индивидуальный предприниматель'
    const isIndividualOrEntity = userType === 'Физическое лицо' || userType === 'Юридическое лицо'

    return (
        <>
            <div className={style.inputsWrapper}>
                <Form.Item
                    name="last_name"
                    rules={[
                        {
                            required: true,
                            message: t('login.pleaseSurname'),
                        },
                    ]}
                >
                    <div className={style.formBlockShort}>
                        <div>
                            {t('login.surname')}
                            <span className={style.star}>*</span>
                        </div>

                        <div className={style.inputBlock}>
                            <Input
                                onBlur={() => refreshCompanyName()}
                                minLength={1}
                                maxLength={30}
                                className={style.inputSmall}
                            />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: t('login.pleaseName'),
                        },
                    ]}
                >
                    <div className={style.formBlockShort}>
                        <div>
                            {t('login.name')}
                            {<span className={style.star}>*</span>}
                        </div>
                        <div className={style.inputBlock}>
                            <Input
                                onBlur={() => refreshCompanyName()}
                                minLength={1}
                                maxLength={30}
                                className={style.inputSmall}
                            />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    name="middle_name"
                    noStyle={isMiddleName}
                    rules={[
                        {
                            required: !isMiddleName,
                            message: t('login.pleaseLastName'),
                        },
                    ]}
                >
                    <div className={style.formBlockShort}>
                        <div>
                            {t('login.lastName')}
                            {!isMiddleName && <span className={style.star}>*</span>}
                        </div>
                        <div className={style.inputBlock}>
                            <Input
                                disabled={isMiddleName}
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                                onBlur={() => refreshCompanyName()}
                                minLength={1}
                                maxLength={30}
                                className={style.inputSmall}
                            />
                        </div>
                    </div>
                </Form.Item>
            </div>
            <Form.Item name="middle_name_absent" valuePropName="checked" noStyle>
                <div className={style.checkboxWrapper}>
                    <Checkbox onChange={onChange}>{t('edit.noLastName')}</Checkbox>
                </div>
            </Form.Item>
            <Form.Item
                name="company_name"
                className={style.dropdownWrapper}
                rules={[
                    {
                        required: isEntity,
                        message: t('login.pleaseCompany'),
                    },
                ]}
            >
                <div className={style.formBlockShort}>
                    <div>
                        {t('login.company')}
                        {isEntity && <span className={style.star}>*</span>}
                    </div>
                    <div className={style.inputBlock}>
                        <ValueSelector
                            initValue={isEntrepreneur ? entrepreneurCompanyName : undefined}
                            value={companyName}
                            setValue={setCompanyName}
                            onSearch={handleSearch}
                            validate={isEntity}
                            disabled={isEntrepreneur}
                            options={companies}
                            placeholder={'Начните вводить название организации'}
                        />
                    </div>
                </div>
            </Form.Item>
            <div className={style.inputsWrapperShort}>
                <Form.Item
                    name={isIndividualOrEntity ? 'company_inn' : 'personal_inn'}
                    rules={[
                        {
                            required: isEntityOrEntrepreneur ? true : false,
                            pattern: new RegExp(/^\d+$/),
                            message: t('login.pleaseINN'),
                        },
                    ]}
                >
                    <div className={style.formBlockShort}>
                        <div>
                            {isIndividualOrEntity ? t('login.innOfCompany') : t('login.inn')}
                            {isEntityOrEntrepreneur && <span className={style.star}>*</span>}
                        </div>
                        <div className={style.inputBlock}>
                            <Input
                                className={style.inputMiddle}
                                maxLength={lengthConfig[userType].maxInn}
                                minLength={lengthConfig[userType].minInn}
                            />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    name="ogrn"
                    rules={[
                        {
                            required: isEntityOrEntrepreneur ? true : false,
                            pattern: new RegExp(/^\d+$/),
                            message: t('login.pleaseOGRN'),
                        },
                    ]}
                >
                    <div className={style.formBlockShort}>
                        <div>
                            {isIndividual
                                ? t('login.ogrnForIndividual')
                                : isEntity
                                ? t('login.ogrn')
                                : t('login.ogrnForEntrepreneur')}
                            {isEntityOrEntrepreneur && <span className={style.star}>*</span>}
                        </div>
                        <div className={style.inputBlock}>
                            <Input
                                className={style.inputMiddle}
                                maxLength={lengthConfig[userType].maxOgrn}
                                minLength={lengthConfig[userType].minOgrn}
                            />
                        </div>
                    </div>
                </Form.Item>
            </div>
            <div className={style.inputsWrapperShort}>
                <Form.Item name="company_position">
                    <div className={style.formBlockShort}>
                        <div>{t('login.state')}</div>
                        <div className={style.inputBlock}>
                            <Input minLength={1} maxLength={50} className={style.inputMiddle} />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            pattern: new RegExp(/^(?=.*[0-9])[- +()0-9]+$/),
                            message: t('login.pleasePhone'),
                        },
                    ]}
                >
                    <div className={style.formBlockShort}>
                        <div>
                            {t('login.phone')} / {t('login.fax')}
                        </div>
                        <div className={style.inputBlock}>
                            <Input
                                type="phone"
                                minLength={10}
                                maxLength={50}
                                className={style.inputMiddle}
                            />
                        </div>
                    </div>
                </Form.Item>
            </div>
            <Form.Item
                valuePropName="checked"
                noStyle
                rules={[
                    {
                        required: true,
                        message: t('login.pleaseAgree'),
                    },
                ]}
            >
                <Box direction="row" align="flex-start" justify="flex-start">
                    <Checkbox
                        className={style.inputsWrapperShort}
                        value={agreement}
                        onChange={setAgreement}
                    />
                    <Box display="inline" margin="24px 0px 0px 10px">
                        Согласен с условиями{' '}
                        <Link className={style.agreeLink} to="/documents" target="_blank">
                            Пользовательского соглашения и Обработкой персональных данных
                        </Link>
                        <span className={style.star}>*</span>
                    </Box>
                </Box>
            </Form.Item>
        </>
    )
}

export default StepTwo
