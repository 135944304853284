
import { CSSProperties, FC } from 'react'
import styles from './style.m.less'
import { PngIcon, DocIcon, XlsIcon, PdfIcon } from '../../../assets/icons'
import { formatBytes } from '../../../utils/bytes/bytes'
import { Tooltip } from 'antd'
import { documentIconPicker } from '../../helpers'
import { Spin } from 'antd'
import classNames from 'classnames'

export type IFileView = {
    iconName?: string
    title: string
    size: number
    style?: CSSProperties
    onClick?: () => void
    loading?: boolean
    fileTitleStyle?: CSSProperties
}

const icons = {
    PngIcon,
    DocIcon,
    XlsIcon,
    PdfIcon,
    JpgIcon: PngIcon,
}

export const FileView: FC<IFileView> = ({
    iconName = undefined,
    title = '',
    size = 0,
    style = {},
    children,
    onClick = undefined,
    loading = false,
    fileTitleStyle = {},
}) => {
    const Icon = icons[iconName as keyof typeof icons]
    const type = title.split('.').reverse()[0]
    return (
        <div className={styles.file} style={style}>
            <Spin spinning={loading}>
                <div className={onClick && styles.icon} onClick={onClick}>
                    {iconName ? <Icon /> : documentIconPicker(type)}
                </div>
            </Spin>
            <Tooltip title={title}>
                <div>
                    <div className={styles.fileTitle} style={fileTitleStyle}>{title}</div>
                    <div className={styles.fileSize}>{formatBytes(size)}</div>
                </div>
            </Tooltip>
            {children && <div>{children}</div>}
        </div>
    )
}
  
  export default FileView
