import { FC } from 'react'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'

interface ICoordinateDataModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const CoordinateDataModal: FC<ICoordinateDataModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleOk = () => {
        setIsModalVisible(false)
        navigate('/ku-approval')
        notification.success({
            message: 'Успешно',
            description:
                'Данные по КУ за 17.02.2022 - 30.02.2022 по ЗГО “Бугульчанская солнечная электростанция” согласованы',
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.wrapper}>
                <div className={style.titleWrapper}>
                    <QuestionCircleOutlined className={style.icon} />
                    <div className={style.header}>{t('kUApprovalItem.sendForRevision')}</div>
                </div>
                <div className={style.message}>{t('kUApprovalItem.modalMessage')}</div>
                <div className={style.buttonsBlock}>
                    <ButtonsBlock
                        confirmAction={handleOk}
                        cancelAction={handleOk}
                        confirmTitle={t('kUApprovalItem.approveButton')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default CoordinateDataModal
