import { FC } from 'react'
import style from './style.m.less'

interface ICommonBlock {
    label: string
    select: React.ReactNode
    customStyle?: React.CSSProperties
    margin?: boolean
    required?: boolean
}

const CommonBlock: FC<ICommonBlock> = ({
    label,
    select,
    customStyle,
    margin,
    required,
    children,
}) => {
    return (
        <div className={margin ? style.editBlockMargin : style.editBlock} style={customStyle}>
            <div className={style.label}>
                {label}
                {required && <div className={style.star}>*</div>}
            </div>
            {select}
            {children}
        </div>
    )
}

export default CommonBlock
