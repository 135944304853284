import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Email: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.75 16.25H6.25C4 16.25 2.5 15.1471 2.5 12.5735V7.42647C2.5 4.85294 4 3.75 6.25 3.75H13.75C16 3.75 17.5 4.85294 17.5 7.42647V12.5735C17.5 15.1471 16 16.25 13.75 16.25Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.1663 7.5L11.558 9.58333C10.6997 10.2667 9.29134 10.2667 8.433 9.58333L5.83301 7.5"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
