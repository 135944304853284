import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const DocIcon: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="21"
                height="28"
                viewBox="0 0 21 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.6 0H1.4C0.626801 0 0 0.626801 0 1.4V26.6C0 27.3732 0.626801 28 1.4 28H19.6C20.3732 28 21 27.3732 21 26.6V1.4C21 0.626801 20.3732 0 19.6 0Z"
                    fill="#7AADFF"
                />
                <g opacity="0.5">
                    <path d="M3 14H18V13H3V14Z" fill="white" />
                    <path d="M3 17H18V16H3V17Z" fill="white" />
                    <path d="M18 20H3V19H18V20Z" fill="white" />
                    <path d="M3 23H11V22H3V23Z" fill="white" />
                </g>
                <path
                    d="M7 8.77C6.78 8.92333 6.51333 9 6.2 9H5.7C5.11333 9 4.68333 8.87667 4.41 8.63C4.13667 8.37667 4 7.96667 4 7.4V5.6C4 5.04 4.13667 4.63333 4.41 4.38C4.69 4.12667 5.18 4 5.76 4H7V2H8V9H7V8.77ZM6.35 8C6.57 8 6.73333 7.94333 6.84 7.83C6.94667 7.71 7 7.58333 7 7.45V5H5.75C5.50333 5 5.31667 5.05667 5.19 5.17C5.06333 5.28333 5 5.44333 5 5.65V7.35C5 7.55667 5.06333 7.71667 5.19 7.83C5.31667 7.94333 5.50333 8 5.75 8H6.35Z"
                    fill="white"
                />
                <path
                    d="M13 7.4C13 7.96 12.86 8.36667 12.58 8.62C12.3067 8.87333 11.88 9 11.3 9L10.7 9C10.12 9 9.69 8.87333 9.41 8.62C9.13667 8.36667 9 7.96 9 7.4V5.6C9 5.04 9.13667 4.63333 9.41 4.38C9.69 4.12667 10.12 4 10.7 4L11.3 4C11.88 4 12.3067 4.12667 12.58 4.38C12.86 4.63333 13 5.04 13 5.6V7.4ZM12 5.65C12 5.44333 11.9367 5.28333 11.81 5.17C11.6833 5.05667 11.4967 5 11.25 5H10.75C10.5033 5 10.3167 5.05667 10.19 5.17C10.0633 5.28333 10 5.44333 10 5.65V7.35C10 7.55667 10.0633 7.71667 10.19 7.83C10.3167 7.94333 10.5033 8 10.75 8H11.25C11.4967 8 11.6833 7.94333 11.81 7.83C11.9367 7.71667 12 7.55667 12 7.35V5.65Z"
                    fill="white"
                />
                <path
                    d="M15.71 9C15.1233 9 14.6933 8.87667 14.42 8.63C14.1467 8.37667 14.01 7.96667 14.01 7.4V5.6C14.01 5.04 14.1467 4.63333 14.42 4.38C14.7 4.12667 15.13 4 15.71 4H17V5H15.75C15.5033 5 15.3167 5.05667 15.19 5.17C15.0633 5.28333 15 5.44333 15 5.65V7.35C15 7.55667 15.0633 7.71667 15.19 7.83C15.3167 7.94333 15.5033 8 15.75 8H17V9H15.71Z"
                    fill="white"
                />
            </svg>
        )}
    />
)
