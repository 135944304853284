// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoBlockMain_KcYi_ {\n  display: flex;\n  flex-direction: column;\n}\n.iconBlock_u5cg6 {\n  display: flex;\n  align-items: center;\n}\n.label_kEP92 {\n  font-size: 12px;\n  line-height: 16px;\n  color: #bfbfbf;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/CardInfoBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;AAAJ;AAGA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AADJ","sourcesContent":[".infoBlockMain {\n    display: flex;\n    flex-direction: column;\n}\n\n.iconBlock {\n    display: flex;\n    align-items: center;\n}\n\n.label {\n    font-size: 12px;\n    line-height: 16px;\n    color: #bfbfbf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlockMain": "infoBlockMain_KcYi_",
	"iconBlock": "iconBlock_u5cg6",
	"label": "label_kEP92"
};
export default ___CSS_LOADER_EXPORT___;
