import { FC } from 'react'
import { Link } from 'react-router-dom'
import { NewLogo, Navigation, UserPanel, AdminNavigation } from './components'
import { useAppSelector } from '../../store/hooks'
import { EUserRole } from '../../constants/approval'

import style from './style.m.less'

interface IHeader {
    isUnread: boolean
    isAuth: boolean
}

const Header: FC<IHeader> = ({ isUnread, isAuth }) => {
    const isUserLoading = useAppSelector((state) => state.user.isLoading)

    const userRole = useAppSelector((state) => state.user.data.user_role)

    const isOperator = userRole === EUserRole.Operator

    const isAdmin = userRole === EUserRole.Administrator || userRole === EUserRole.SuperAdmin

    return (
        <div className={style.headerMain}>
            <div className={style.container}>
                <Link to="/main" className={style.link}>
                    <NewLogo />
                </Link>
                {!isAuth ? (
                    <Navigation />
                ) : (
                    !isUserLoading && (
                        <>
                            {!isUserLoading && (isAdmin || isOperator) ? (
                                <AdminNavigation isAdmin={isAdmin} />
                            ) : (
                                <Navigation />
                            )}
                        </>
                    )
                )}
                <UserPanel isAdminOrOperator={isAdmin || isOperator} isUnread={isUnread} />
            </div>
        </div>
    )
}

export default Header
