// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_lNI6i {\n  width: 536px;\n}\n.headerWrapper_jeWZF {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 24px;\n}\n.header_E6Me5 {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  font-feature-settings: \"lnum\";\n  color: #303440;\n}\n.closeIcon_DwDDk {\n  color: #D5DCE6;\n  cursor: pointer;\n}\n.raw_fb9Y4 {\n  display: flex;\n  gap: 24px;\n}\n.categoryNumber_ao90S {\n  width: 40px;\n  min-width: 40px;\n  color: #8C8C8C;\n}\n.divider_Z9dOA {\n  margin: 12px 0;\n}\n.divider_Z9dOA:last-child {\n  display: none;\n}\n.content_DkrgE {\n  max-height: 500px;\n  overflow: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AddEditGO/components/QualificationCategoryModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAGA;EACI,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;AADJ;AAIA;EACI,cAAA;EACA,eAAA;AAFJ;AAKA;EACI,aAAA;EACA,SAAA;AAHJ;AAMA;EACI,WAAA;EACA,eAAA;EACA,cAAA;AAJJ;AAOA;EACI,cAAA;AALJ;AAQA;EACI,aAAA;AANJ;AASA;EACI,iBAAA;EACA,cAAA;AAPJ","sourcesContent":[".modal {\n    width: 536px;\n}\n\n.headerWrapper {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 24px;\n}\n\n.header {\n    font-family: 'Raleway';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    font-feature-settings: \"lnum\";\n    color: #303440;\n}\n\n.closeIcon {\n    color: #D5DCE6;\n    cursor: pointer;\n}\n\n.raw {\n    display: flex;\n    gap: 24px;\n}\n\n.categoryNumber {\n    width: 40px;\n    min-width: 40px;\n    color: #8C8C8C;\n}\n\n.divider {\n    margin: 12px 0;\n}\n\n.divider:last-child {\n    display: none;\n}\n\n.content {\n    max-height: 500px;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_lNI6i",
	"headerWrapper": "headerWrapper_jeWZF",
	"header": "header_E6Me5",
	"closeIcon": "closeIcon_DwDDk",
	"raw": "raw_fb9Y4",
	"categoryNumber": "categoryNumber_ao90S",
	"divider": "divider_Z9dOA",
	"content": "content_DkrgE"
};
export default ___CSS_LOADER_EXPORT___;
