import { FC } from 'react'
import moment from 'moment'
import { DatePicker as AntDatePicker, DatePickerProps } from 'antd'
import { Calendar } from '../../../assets/icons'
import locale from 'antd/es/date-picker/locale/ru_RU'

interface IDatePicker {
    customStyle?: React.CSSProperties
    className?: string
    onChange?: (date: any) => void
    defaultValue?: string | moment.Moment
    format?: string
    disabledDate?: (date: moment.Moment) => boolean
    placeholder?: string
    disabled?: boolean
    picker?: DatePickerProps['picker']
}

const DatePicker: FC<IDatePicker> = ({
    customStyle,
    className,
    onChange,
    defaultValue,
    format = 'DD.MM.YYYY',
    disabledDate,
    disabled = false,
    placeholder,
    picker,
}) => (
    <AntDatePicker
        defaultValue={defaultValue ? moment(defaultValue) : undefined}
        onChange={onChange}
        suffixIcon={<Calendar color={'#8C8C8C'} />}
        format={format}
        locale={locale}
        style={customStyle}
        className={className}
        disabledDate={disabledDate}
        disabled={disabled}
        picker={picker}
        placeholder={placeholder}
    />
)

export default DatePicker
