import React, { FC } from 'react'
import style from './style.m.less'

export interface ICardInfoBlock {
    label?: string
    value: React.ReactNode
    icon?: React.ReactNode
    gap?: number
    customBlockStyle?: React.CSSProperties
}

const CardInfoBlock: FC<ICardInfoBlock> = ({ label, value, icon, gap, customBlockStyle }) => {
    return (
        <div className={style.infoBlockMain} style={customBlockStyle}>
            {label && <div className={style.label}>{label}</div>}
            <div className={style.iconBlock}>
                {icon && icon}
                <div style={{ marginLeft: `${gap}px` }}>{value}</div>
            </div>
        </div>
    )
}

export default CardInfoBlock
