import { RequestTypes } from '../hooks/useRequest'

export const CA_API_PREFIX = '/api/ca/v1/'
export const CA_SUBMISSIONS = 'wm/submissions'
export const CA_AVAILABLE = 'wm/submission-available-admin'
export const CA_DOCS = 'wm/submission?id='

export const getCaList = {
    link: `${CA_API_PREFIX}${CA_SUBMISSIONS}`,
    method: RequestTypes.post,
}

export const getSubmissionAvailable = {
    link: `${CA_API_PREFIX}${CA_AVAILABLE}`,
    method: RequestTypes.get,
}

export const getCaDocs = {
    link: `${CA_API_PREFIX}${CA_DOCS}`,
    method: RequestTypes.get,
}
