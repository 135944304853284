import { Import } from '../../../../assets/icons'
import { CenteredSpin, IconButton } from '../../../../components/ui'
import moment from 'moment'
import { FC, useState } from 'react'
import FileSaver from 'file-saver'
import { getSignatureService } from '../../../../services/user'
import { Tooltip } from 'antd'
import style from './style.m.less'

export interface IHistoryItem {
    request_id: string
    ts_created: string
    ts_deregister_add: string
    ts_deregister_cancel?: string
    ts_deregister_decline?: string
}

const render = (date: Date) => (date ? moment(date).format('DD.MM.YYYY') : '-')

interface IDownload {
    requestId?: string
}

const Download: FC<IDownload> = ({ requestId }) => {
    const [loading, setLoading] = useState(false)
    const handleClickDownload = async () => {
        try {
            if (!requestId) {
                return
            }

            setLoading(true)

            const [body, signature] = await Promise.all([
                getSignatureService(requestId, true, (error) => {
                    console.log(error)
                }),
                getSignatureService(requestId, false, (error) => {
                    console.log(error)
                }),
            ])

            const bodyBlob = new Blob([body?.data], { type: 'application/octet-stream' })
            const signatureBlob = new Blob([signature?.data], {
                type: 'application/octet-stream',
            })

            FileSaver.saveAs(bodyBlob, body?.headers.filename)
            FileSaver.saveAs(signatureBlob, signature?.headers.filename)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {loading && <CenteredSpin />}
            {requestId && (
                <IconButton
                    icon={<Import />}
                    onClick={handleClickDownload}
                    className={style.downloadButton}
                />
            )}
        </>
    )
}

export const columns = [
    {
        title: 'Дата операции (снятие)',
        dataIndex: 'ts_created',
        key: 'ts_created',
        render,
        sorter: (a: IHistoryItem, b: IHistoryItem) => {
            return new Date(a.ts_created).getTime() - new Date(b.ts_created).getTime()
        },
    },
    {
        title: 'Дата снятия с учета',
        dataIndex: 'ts_deregister_add',
        key: 'ts_deregister_add',
        render,
    },
    {
        title: 'Дата возвращения на учет',
        dataIndex: 'ts_deregister_cancel',
        key: 'ts_deregister_cancel',
        render,
    },
    {
        title: 'Дата отмены снятия с учета',
        dataIndex: 'ts_deregister_decline',
        key: 'ts_deregister_decline',
        render,
    },
    {
        title: '',
        dataIndex: 'request_id',
        key: 'download',
        render: (requestId: string) => (
            <div className={style.butUp}>
                <Tooltip title="Скачать запрос и подпись">
                    <span>
                        <Download requestId={requestId} />
                    </span>
                </Tooltip>
            </div>
        ),
        width: 32,
    },
]
