import { FC } from 'react'
import { Select, SelectProps, Tag } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { DownMini } from '../../../../assets/icons'
import style from './style.m.less'
import { colorPicker, energyTypePicker } from '../../../helpers'

interface ITagSelector extends SelectProps {
    customStyle?: React.CSSProperties
    value?: number[]
    showSearch?: boolean
    onChange?(value: number[]): void
}

interface IPowerTypeTag {
    value: number
    customStyle?: React.CSSProperties
}

const PowerTypeTag: FC<IPowerTypeTag> = ({ children, value, customStyle }) => {
    return (
        <Tag color={colorPicker(energyTypePicker(value))} style={customStyle} className={style.typeTag}>
            {children}
        </Tag>
    )
}

const tagRender = (props: CustomTagProps) => {
    const { label, value } = props

    return (
        <PowerTypeTag value={value} customStyle={{ marginRight: 8 }}>
            {label}
        </PowerTypeTag>
    )
}

const TagSelector: FC<ITagSelector> = ({ customStyle, options, value, showSearch, onChange }) => (
    <Select
        mode="multiple"
        showArrow
        suffixIcon={
            <div style={{ marginTop: '4px', marginRight: '3px' }}>
                <DownMini />
            </div>
        }
        style={customStyle}
        tagRender={tagRender}
        className={style.typeSelector}
        options={options}
        value={value}
        onChange={onChange}
        showSearch={showSearch}
    />
)

export default TagSelector
