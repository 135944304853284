import { FC, useState } from 'react'
import { DropdownSelector2, ImportButton } from '../../../ui'
import { Box } from '../../../ui/Box/Box'
import { Divider, InputNumber, Alert } from 'antd'
import style from './style.m.less'
import { useAppSelector } from '../../../../store/hooks'
import { userTypeIdPicker } from '../../../helpers'
import { Search, Trash, Edit } from '../../../../assets/icons'
import { SearchConsumer, AddConsumer } from '../'

const options = [
    { id: 'Я', value: 'Я' },
//    { id: 'Физическое лицо', value: 'Физическое лицо' },
    { id: 'Юридическое лицо', value: 'Юридическое лицо' },
//    { id: 'Индивидуальный предприниматель', value: 'Индивидуальный предприниматель' },
]

interface IConsumerBlock {
    index: number
    totalVolume: number
    updateRepayments: (index: number, key: string, value: number | string) => void
    updateUnregistredRepayments: (index: number, key: string, value: number | string) => void
}

const ConsumerBlock: FC<IConsumerBlock> = ({
    index,
    updateRepayments,
    updateUnregistredRepayments,
    totalVolume,
}) => {
    const [type, setType] = useState(null)
    const [selected, setSelected] = useState<any>(undefined)
    const [volume, setVolume] = useState(0)

    const uid = useAppSelector((state) => state.user.data.uid)

    const selectHandle = (consumer: any) => {
        setSelected(consumer)
        updateRepayments(index, 'user_id', consumer.uid)
    }

    const selectUnregistredHandle = (consumer: any) => {
        setSelected(consumer)
        updateUnregistredRepayments(index, 'unreg_consumer_data', consumer)
    }

    const clearCard = () => {
        setSelected(undefined)
        setType(null)
        setVolume(0)
        updateRepayments(index, 'user_id', '')
        updateUnregistredRepayments(index, 'unreg_consumer_data', '')
    }

    const [isSearchConsumerModalVisible, setIsSearchConsumerModalVisible] = useState(false)
    const openSearchConsumerModal = () => {
        setIsSearchConsumerModalVisible(true)
    }

    const [isAddConsumerModalVisible, setIsAddConsumerModalVisible] = useState(false)
    const openAddConsumerModal = () => {
        setIsAddConsumerModalVisible(true)
    }

    return (
        <div className={style.inputWrapper}>
            {!selected && (
                <>
                    <div className={style.label}>Потребитель</div>
                    <DropdownSelector2
                        placeholder="Выберите потребителя"
                        noDefaultValue
                        options={options}
                        customStyle={{
                            width: '400px',
                        }}
                        onChange={(value: any) => {
                            if (value === 'Я') {
                                updateRepayments(index, 'user_id', uid)
                            } else {
                                updateRepayments(index, 'user_id', '')
                            }
                            setType(value)
                        }}
                    />
                </>
            )}
            {type && type !== 'Я' && !selected && (
                <div className={style.linkWrapper}>
                    <ImportButton
                        value="Найти в системе"
                        icon={<Search />}
                        onClick={openSearchConsumerModal}
                    />

                    <Divider type="vertical" style={{ height: '22px', margin: '0 16px' }} />
                    <ImportButton
                        value="Заполнить вручную"
                        icon={<Edit />}
                        onClick={openAddConsumerModal}
                    />
                </div>
            )}
            {selected && type === 'Физическое лицо' && (
                <Alert
                    className={style.resultAlert}
                    message={
                        <div className={style.alertTitleWrapper}>
                            <span className={style.alertTitle}>
                                {`${selected.first_name} ${selected.middle_name} ${
                                    selected.last_name ?? ''
                                }`}
                            </span>
                            <div className={style.alertIconWrapper}>
                                <div className={style.icon} onClick={clearCard}>
                                    <Trash />
                                </div>
                            </div>
                        </div>
                    }
                    description={
                        <>
                            <span
                                className={style.alertDetail}
                            >{`ИНН ${selected.personal_inn}`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`СНИЛС ${selected.snils}`}</span>
                        </>
                    }
                    type="info"
                />
            )}
            {selected && type === 'Юридическое лицо' && (
                <Alert
                    className={style.resultLegalAlert}
                    message={
                        <div className={style.alertTitleWrapper}>
                            <span className={style.alertTitle}>{selected.company_name_short}</span>
                            <div className={style.alertIconWrapper}>
                                <div className={style.icon} onClick={clearCard}>
                                    <Trash />
                                </div>
                            </div>
                        </div>
                    }
                    description={
                        <>
                            <span className={style.alertDetail}>{`ИНН ${
                                selected.company_inn ? selected.company_inn : selected.inn
                            }`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`ОГРН ${selected.ogrn}`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`КПП ${selected.kpp}`}</span>
                        </>
                    }
                    type="info"
                />
            )}
            {selected && type === 'Индивидуальный предприниматель' && (
                <Alert
                    className={style.resultAlert}
                    message={
                        <div className={style.alertTitleWrapper}>
                            <span className={style.alertTitle}>{`ИП ${selected.last_name} ${
                                selected.first_name
                            } ${selected.middle_name ?? ''}`}</span>
                            <div className={style.alertIconWrapper}>
                                <div className={style.icon} onClick={clearCard}>
                                    <Trash />
                                </div>
                            </div>
                        </div>
                    }
                    description={
                        <>
                            <span className={style.alertDetail}>{`ИНН ${
                                selected.personal_inn ? selected.personal_inn : selected.inn
                            }`}</span>
                            <Divider type="vertical" />
                            <span className={style.alertDetail}>{`ОГРНИП ${selected.ogrnip}`}</span>
                        </>
                    }
                    type="info"
                />
            )}
            {type && (
                <Box style={{ marginTop: '16px' }}>
                    <div className={style.label}>Объем, кВт*ч</div>
                    <InputNumber
                        placeholder="Введите объем"
                        className={style.input}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        style={volume > totalVolume ? { borderColor: '#FF7875' } : undefined}
                        controls={false}
                        onChange={(value) => {
                            setVolume(value)
                            updateRepayments(index, 'volume', value)
                            if (type !== 'Я') {
                                updateUnregistredRepayments(index, 'volume', value)
                            }
                        }}
                    />
                    {volume > totalVolume && (
                        <div className={style.explain}>
                            Укажите число, соответствующее объему сертификата
                        </div>
                    )}
                </Box>
            )}
            <Divider />
            <SearchConsumer
                isModalVisible={isSearchConsumerModalVisible}
                setIsModalVisible={() => setIsSearchConsumerModalVisible(false)}
                userType={userTypeIdPicker(type)}
                selectHandle={selectHandle}
            />
            <AddConsumer
                isModalVisible={isAddConsumerModalVisible}
                selectHandle={selectUnregistredHandle}
                setIsModalVisible={() => setIsAddConsumerModalVisible(false)}
                userType={userTypeIdPicker(type)}
            />
        </div>
    )
}

export default ConsumerBlock
