// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".graphFrameMain_yKs5j {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  width: 1172px;\n  height: 398px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  padding: 24px 40px 24px 32px;\n  border-radius: 16px;\n  margin-top: 16px;\n}\n.headerRow_roUna {\n  display: flex;\n  justify-content: space-between;\n  width: 1094px;\n}\n.headerPrimary_TTLZv {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #262626;\n}\n.headerSecondary_InLEq {\n  margin-left: 8px;\n  font-weight: 500;\n  font-size: 17px;\n  line-height: 20px;\n  color: #8c8c8c;\n}\n.graph_hu_i9 {\n  height: 296px;\n  width: 1100px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/GraphFrame/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,8CAAA;EACA,4BAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,aAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKA;EACI,aAAA;EACA,aAAA;AAHJ","sourcesContent":[".graphFrameMain {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    width: 1172px;\n    height: 398px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    padding: 24px 40px 24px 32px;\n    border-radius: 16px;\n    margin-top: 16px;\n}\n\n.headerRow {\n    display: flex;\n    justify-content: space-between;\n    width: 1094px;\n}\n\n.headerPrimary {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #262626;\n}\n\n.headerSecondary {\n    margin-left: 8px;\n    font-weight: 500;\n    font-size: 17px;\n    line-height: 20px;\n    color: #8c8c8c;\n}\n\n.graph {\n    height: 296px;\n    width: 1100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphFrameMain": "graphFrameMain_yKs5j",
	"headerRow": "headerRow_roUna",
	"headerPrimary": "headerPrimary_TTLZv",
	"headerSecondary": "headerSecondary_InLEq",
	"graph": "graph_hu_i9"
};
export default ___CSS_LOADER_EXPORT___;
