import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from 'antd'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'
import style from './style.m.less'

interface IChangeRoleModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const ChangeRoleModal: FC<IChangeRoleModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleOk = () => {
        setIsModalVisible(false)
    }
    const goToRoleChange = () => {
        navigate('/change-role')
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>{t('myProfile.changeRole')}</div>
            <div className={style.modalText}>
                {t('myProfile.changeRoleText')}
                <br /> {t('myProfile.changeRoleText2')}
            </div>
            <Checkbox checked className={style.checkbox}>
                {t('myProfile.familiar')}
            </Checkbox>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={goToRoleChange}
                    cancelAction={handleOk}
                    confirmTitle={t('myProfile.continue')}
                />
            </div>
        </Modal>
    )
}

export default ChangeRoleModal
