import { FC } from 'react'
import { message } from 'antd'
import { useEditData } from '../hooks/useEditData'
import { useNavigate } from 'react-router-dom'
import { ButtonsBlock, SuccessMessage } from './components'
import { ProfileLayout, GeneratorLayout } from './pages'
import { HeaderBlock } from '../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const EditMyProfile: FC = () => {
    const { t } = useTranslation()

    // const type = useEditData()
    const type = 'generator2'
    const navigate = useNavigate()

    const handleConfirm = () => {
        navigate(-1)
        message.success({
            content: (
                <SuccessMessage
                    header={t('editMyProfile.success')}
                    message={t('editMyProfile.edited')}
                />
            ),
            icon: <></>,
        })
    }

    const getBack = () => {
        navigate(-1)
    }

    return (
        <div className={style.editMain}>
            <HeaderBlock
                label={
                    type === 'generator'
                        ? t('editMyProfile.editGO')
                        : t('editMyProfile.editProfile')
                }
            />
            <div className={style.editFrame}>
                {type === 'generator' ? <GeneratorLayout type={type} /> : <ProfileLayout />}
                <ButtonsBlock
                    margin={type === 'generator'}
                    confirmAction={handleConfirm}
                    cancelAction={getBack}
                />
            </div>
        </div>
    )
}

export default EditMyProfile
