import { Dispatch, SetStateAction, FC, useState } from 'react'
import { Form, Switch, Divider, InputNumber } from 'antd'
import { TakePaymentBlock } from '../'
import { payTypePicker } from '../../../helpers'
import { DropdownSelector2 } from '../../../ui'
import style from './style.m.less'

const fixedOptions = [{ value: 'Фиксированная', id: 'w371c913-a16a-4bb8-93a6-081a89cc036a' }]

const fromAGValueOptions = [{ value: 'От объема АГ', id: 'zk7a2c6a-2840-470c-922a-289fca5017ad' }]

const totalOptions = [...fixedOptions, ...fromAGValueOptions]

const frequencyValues = [
    { value: 'Раз в месяц', id: 'd6371e5f-8c84-433c-b9dc-13d23c839ebb' },
    { value: 'Раз в квартал', id: '99ac57af-930a-4987-9501-300fe47e0a9c' },
    { value: 'Раз в полгода', id: '87df5e1a-e395-4eae-86d9-075318ab354f' },
    { value: 'Раз в год', id: 'c9c3fa61-bac1-4548-9234-c14f95f041f7' },
]

const approvalFrequencyValues = [
    { value: 'Раз в год', id: '6e23fa61-bac1-4548-9234-c14f95f041f7' },
    { value: 'Раз в 4 года', id: 'n9if5e1a-e395-4eae-86d9-075318ab354f' },
]

interface ISettingsBlock {
    config: any
    data: any
    setDisableConfirm: Dispatch<SetStateAction<boolean>>
}

const SettingsBlock: FC<ISettingsBlock> = ({ config, data, setDisableConfirm }) => {
    const [isOn, setIsOn] = useState(data?.is_active)
    const [payType, setPayType] = useState(payTypePicker(data?.pay_type_id))

    const isAccountService = data?.operation_type_id === 50

    const handlePayTypeChange = (value: any) => {
        setPayType(value)
    }

    const isDisabled = config.payTypes === 'fixed' || config.payTypes === 'fromAGValue'
    const options =
        config.payTypes === 'fixed'
            ? fixedOptions
            : config.payTypes === 'fromAGValue'
            ? fromAGValueOptions
            : totalOptions

    const ownerData = {
        has_role_go_owner: data?.has_role_go_owner,
        ...(!config.onlyOwner && {
            has_role_client: data?.has_role_client,
            has_role_tso_gp: data?.has_role_tso_gp,
        }),
    }

    const paymentsBlock = (
        <TakePaymentBlock
            isSeparate
            setDisableConfirm={setDisableConfirm}
            config={config.name}
            onlyOwner={config.onlyOwner}
            ownerData={ownerData}
        />
    )

    return (
        <>
            <div className={style.settingRowNoMargin}>
                <div className={style.settingHeader}>{config.label}</div>
                <Form.Item name={`${config.name}-is_active`} valuePropName="checked">
                    <Switch
                        size={'small'}
                        className={style.switch}
                        onChange={(checked) => setIsOn(checked)}
                    />
                </Form.Item>
            </div>

            {isOn &&
                (config.frequencyTypes ? (
                    <>
                        <div className={style.settingRowNoMarginGap16}>
                            <div>
                                <div>
                                    Стоимость <span className={style.star}>*</span>
                                </div>
                                <Form.Item name={`${config.name}-pay_type_id`}>
                                    <DropdownSelector2
                                        onChange={handlePayTypeChange}
                                        customStyle={{ width: '265px' }}
                                        options={options}
                                        disabled={isDisabled}
                                        value={payType}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div>
                                    Стоимость услуги, Р <span className={style.star}>*</span>
                                </div>
                                <Form.Item
                                    name={`${config.name}-fixed_price`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите стоимость услуги',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        decimalSeparator=","
                                        controls={false}
                                        min={0.00001}
                                        maxLength={14}
                                        precision={5}
                                        className={style.input}
                                        placeholder="Введите значение"
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div>
                                    Периодичность <span className={style.star}>*</span>
                                </div>
                                <Form.Item name={`${config.name}-frequency_id`}>
                                    <DropdownSelector2
                                        onChange={handlePayTypeChange}
                                        customStyle={{ width: '265px' }}
                                        options={
                                            isAccountService
                                                ? frequencyValues
                                                : approvalFrequencyValues
                                        }
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {paymentsBlock}
                    </>
                ) : payType === 'Фиксированная' ? (
                    <>
                        <div className={style.settingRowNoMarginGap16}>
                            <div>
                                <div>
                                    Стоимость <span className={style.star}>*</span>
                                </div>
                                <Form.Item name={`${config.name}-pay_type_id`}>
                                    <DropdownSelector2
                                        onChange={handlePayTypeChange}
                                        customStyle={{ width: '265px' }}
                                        options={options}
                                        disabled={isDisabled}
                                        value={payType}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div>
                                    Стоимость услуги, Р <span className={style.star}>*</span>
                                </div>
                                <Form.Item
                                    name={`${config.name}-fixed_price`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите стоимость услуги',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        decimalSeparator=","
                                        controls={false}
                                        min={0.00001}
                                        maxLength={14}
                                        precision={5}
                                        className={style.input}
                                        placeholder="Введите значение"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {paymentsBlock}
                    </>
                ) : (
                    <>
                        <div className={style.settingRowNoMarginGap16}>
                            <div>
                                <div>
                                    Стоимость <span className={style.star}>*</span>
                                </div>
                                <Form.Item name={`${config.name}-pay_type_id`}>
                                    <DropdownSelector2
                                        onChange={handlePayTypeChange}
                                        customStyle={{ width: '265px' }}
                                        options={options}
                                        disabled={isDisabled}
                                        value={payType}
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div>
                                    Стоимость услуги за ед. АГ <span className={style.star}>*</span>
                                </div>
                                <Form.Item
                                    name={`${config.name}-qnt_price`}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите стоимость услуги',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        decimalSeparator=","
                                        controls={false}
                                        min={0.00001}
                                        maxLength={14}
                                        precision={5}
                                        className={style.input}
                                        placeholder="Введите значение"
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div>Минимальная стоимость услуги</div>
                                <Form.Item name={`${config.name}-min_service_price`}>
                                    <InputNumber
                                        decimalSeparator=","
                                        controls={false}
                                        min={0.00001}
                                        maxLength={14}
                                        precision={5}
                                        className={style.input}
                                        placeholder="Введите значение"
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <div>Максимальная стоимость услуги </div>
                                <Form.Item
                                    name={`${config.name}-max_service_price`}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    Number(
                                                        getFieldValue(`${config.name}-qnt_price`),
                                                    ) < Number(value)
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'Максимальная сумма меньше суммы за 1 ед. АГ',
                                                    ),
                                                )
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber
                                        decimalSeparator=","
                                        controls={false}
                                        min={0.00001}
                                        maxLength={14}
                                        precision={5}
                                        className={style.input}
                                        placeholder="Введите значение"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {paymentsBlock}
                    </>
                ))}
            <Divider />
        </>
    )
}

export default SettingsBlock
