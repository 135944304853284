// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blockTitle_PbpM6 {\n  font-weight: 600;\n  color: #434343;\n  margin: 24px 0;\n}\n.input_sn97B {\n  width: 86px;\n  height: 32px;\n  border: 1px solid #D9D9D9;\n  border-radius: 8px;\n}\n.row_irD8E {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 16px;\n}\n.label_etwIL {\n  margin-bottom: 2px;\n}\n.date_azg5b {\n  width: 200px;\n  height: 32px;\n  border: 1px solid #D9D9D9;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOSubmitDataKU/components/VolumeIndicators/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;EACA,cAAA;AACJ;AAEA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAAJ;AAGA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;EACI,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAHJ","sourcesContent":[".blockTitle {\n    font-weight: 600;\n    color: #434343;\n    margin: 24px 0;\n}\n\n.input {\n    width: 86px;\n    height: 32px;\n    border: 1px solid #D9D9D9;\n    border-radius: 8px;\n}\n\n.row {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 16px;\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.date {\n    width: 200px;\n    height: 32px;\n    border: 1px solid #D9D9D9;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockTitle": "blockTitle_PbpM6",
	"input": "input_sn97B",
	"row": "row_irD8E",
	"label": "label_etwIL",
	"date": "date_azg5b"
};
export default ___CSS_LOADER_EXPORT___;
