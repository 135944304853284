import moment from 'moment'
import { citizenshipTypeMap, documentTypeMap, userRoleMap } from '../../../../constants/approval'

export const privatePersonInfoConfig = (profile: any, nextProfile: any) => [
    {
        name: 'Роль в системе',
        value: userRoleMap.get(profile.user_role),
        blur: !!nextProfile && profile.user_role !== nextProfile.user_role,
    },
    {
        name: 'Тип пользователя',
        value: 'Физическое лицо',
        blur: !!nextProfile && profile.user_type !== nextProfile.user_type,
    },
    {
        name: 'ФИО',
        value: `${profile.last_name} ${profile.first_name} ${profile.middle_name}`,
        blur:
            !!nextProfile &&
            (profile.last_name !== nextProfile.last_name ||
                profile.first_name !== nextProfile.first_name ||
                profile.middle_name !== nextProfile.middle_name),
    },
    {
        name: 'Логин',
        value: profile.login,
        blur: !!nextProfile && profile.login !== nextProfile.login,
    },
    {
        name: 'E-mail',
        value: profile.email,
        blur: !!nextProfile && profile.email !== nextProfile.email,
    },
    {
        isDivider: true,
        noMargin: true,
    },
    {
        name: 'Дата рождения',
        value: profile.birthday && moment(profile.birthday).format('DD.MM.YYYY'),
        blur: !!nextProfile && profile.birthday !== nextProfile.birthday,
    },
    {
        name: 'Статус гражданина',
        value: citizenshipTypeMap.get(profile.citizenship_type),
        blur: !!nextProfile && profile.citizenship_type !== nextProfile.citizenship_type,
    },
    {
        name: 'Гражданство',
        value: profile.citizenship,
        blur: !!nextProfile && profile.citizenship !== nextProfile.citizenship,
    },
    {
        name: 'Документ, удостоверяющий личность',
        value: documentTypeMap.get(profile.document_type),
        blur: !!nextProfile && profile.document_type !== nextProfile.document_type,
    },
    {
        name: 'Серия и номер паспорта',
        value:
            profile.document_type === 1
                ? `${profile.passport_series} ${profile.passport_number}`
                : profile.document_custom_number,
        blur:
            !!nextProfile &&
            (profile.document_type !== nextProfile.document_type ||
                profile.passport_series !== nextProfile.passport_series ||
                profile.document_custom_number !== nextProfile.document_custom_number),
    },
    {
        name: 'Дата выдачи паспорта',
        value:
            profile.document_type === 1
                ? moment(profile.passport_date).format('DD.MM.YYYY')
                : profile.document_custom_betrayed_name,
        blur:
            !!nextProfile &&
            (profile.document_type !== nextProfile.document_type ||
                profile.passport_date !== nextProfile.passport_date ||
                profile.document_custom_betrayed_date !==
                    nextProfile.document_custom_betrayed_date),
    },
    {
        name: 'Кем выдан паспорт',
        value:
            profile.document_type === 1
                ? profile.passport_betrayed_name
                : profile.document_custom_betrayed_name,
        blur:
            !!nextProfile &&
            (profile.document_type !== nextProfile.document_type ||
                profile.passport_betrayed_name !== nextProfile.passport_betrayed_name ||
                profile.document_custom_betrayed_date !==
                    nextProfile.document_custom_betrayed_name),
    },
    {
        name: 'Код подразделения ',
        value: profile.document_type === 1 ? profile.passport_betrayed_code : '',
        blur:
            !!nextProfile && profile.passport_betrayed_code !== nextProfile.passport_betrayed_code,
    },
    {
        isDivider: true,
        noMargin: true,
    },
    {
        name: 'Адрес регистрации',
        value: profile.registration_address,
        blur: !!nextProfile && profile.registration_address !== nextProfile.registration_address,

        noMargin: true,
    },
    {
        name: 'Почтовый адрес',
        value: profile.post_address_matches ? profile.registration_address : profile.post_address,
        blur:
            !!nextProfile &&
            (profile.post_address_matches !== nextProfile.post_address_matches ||
                (profile.post_address_matches &&
                    profile.registration_address !== nextProfile.registration_address) ||
                (!profile.post_address_matches &&
                    profile.post_address !== nextProfile.post_address)),
    },
    {
        name: 'ИНН',
        value: profile.personal_inn,
        blur: !!nextProfile && profile.personal_inn !== nextProfile.personal_inn,
    },
    {
        name: 'СНИЛС',
        value: profile.snils,
        blur: !!nextProfile && profile.snils !== nextProfile.snils,
    },
    {
        name: 'Телефон / факс',
        value: profile.phone,
        blur: !!nextProfile && profile.phone !== nextProfile.phone,
    },
    {
        isDivider: true,
        noMargin: true,
    },
    {
        name: 'Организация',
        value: profile.company_name,
        blur: !!nextProfile && profile.company_name !== nextProfile.company_name,
        noMargin: true,
    },
    {
        name: 'ИНН организации',
        value: profile.company_inn,
        blur: !!nextProfile && profile.company_inn !== nextProfile.company_inn,
    },
    {
        name: 'ОГРН',
        value: profile.ogrn,
        blur: !!nextProfile && profile.ogrn !== nextProfile.ogrn,
    },
    {
        name: 'Должность',
        value: profile.company_position,
        blur: !!nextProfile && profile.company_position !== nextProfile.company_position,
    },
]
