// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconButton_ExwuG {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 8px 16px;\n  height: 38px;\n  border: 1px solid #528eff;\n  background: rgba(0, 0, 0, 0);\n  color: #528eff;\n}\n.large_cKgHa {\n  padding: 10px 20px;\n  height: 44px;\n}\n.small_rFRq5 {\n  padding: 5px 10px;\n  height: 30px;\n}\n.small_rFRq5 .anticon {\n  height: 16px;\n}\n.simple_bzCso {\n  color: #595959;\n  border: 1px solid #bfbfbf;\n}\n.danger_dzodD {\n  border-color: #FF7875;\n  color: #FF4D4F;\n}\n.danger_dzodD:hover {\n  border-color: #f64c49 !important;\n  color: #fb292d !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/IconButton/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,4BAAA;EACA,cAAA;AACJ;AAEA;EACI,kBAAA;EACA,YAAA;AAAJ;AAGA;EACI,iBAAA;EACA,YAAA;AADJ;AADA;EAKQ,YAAA;AADR;AAKA;EACI,cAAA;EACA,yBAAA;AAHJ;AAMA;EACI,qBAAA;EACA,cAAA;AAJJ;AAKI;EACI,gCAAA;EACA,yBAAA;AAHR","sourcesContent":[".iconButton {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 8px 16px;\n    height: 38px;\n    border: 1px solid #528eff;\n    background: rgba(0, 0, 0, 0);\n    color: #528eff;\n}\n\n.large {\n    padding: 10px 20px;\n    height: 44px;\n}\n\n.small {\n    padding: 5px 10px;\n    height: 30px;\n    \n    :global(.anticon) {\n        height: 16px;\n    }\n}\n\n.simple {\n    color: #595959;\n    border: 1px solid #bfbfbf;\n}\n\n.danger{\n    border-color: #FF7875;\n    color: #FF4D4F;\n    &:hover{\n        border-color: #f64c49 !important;\n        color: #fb292d !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "iconButton_ExwuG",
	"large": "large_cKgHa",
	"small": "small_rFRq5",
	"simple": "simple_bzCso",
	"danger": "danger_dzodD"
};
export default ___CSS_LOADER_EXPORT___;
