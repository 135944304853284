import { FC, useState } from 'react'
import { IconButton } from '../../../ui'
import { CoordinateDataModal } from '../'
import { CancelCoordinateModal } from '../'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const ButtonContainer: FC = () => {
    const { t } = useTranslation()

    const [isCoordinateModalVisible, setIsCoordinateModalVisible] = useState(false)
    const openCoordinateModal = () => {
        setIsCoordinateModalVisible(true)
    }

    const [isCancelCoordinateModalVisible, setIsCancelCoordinateModalVisible] = useState(false)
    const openCancelCoordinateModal = () => {
        setIsCancelCoordinateModalVisible(true)
    }

    return (
        <div className={style.buttonContainer}>
            <IconButton
                size="large"
                className={style.buttonContainerCancel}
                onClick={openCancelCoordinateModal}
            >
                {t('zdApprovalItem.cancelButton')}
            </IconButton>
            <IconButton
                size="large"
                className={style.buttonContainerApprove}
                onClick={openCoordinateModal}
            >
                {t('zdApprovalItem.approveButton')}
            </IconButton>
            <CoordinateDataModal
                isModalVisible={isCoordinateModalVisible}
                setIsModalVisible={setIsCoordinateModalVisible}
            />
            <CancelCoordinateModal
                isModalVisible={isCancelCoordinateModalVisible}
                setIsModalVisible={setIsCancelCoordinateModalVisible}
            />
        </div>
    )
}

export default ButtonContainer
