import { FC, ReactElement } from 'react'

import style from './style.m.less'

import { EStatus, IData } from './data'
import { Tag } from '../../../ui'

const IsNewCell: FC<{ isNew?: boolean; children: ReactElement }> = ({ isNew, children }) =>
    isNew ? <strong>{children}</strong> : children

const render = (value: any, record: IData) => (
    <IsNewCell isNew={record.isNew}>{value || '-'}</IsNewCell>
)

const renderWithDot = (value: any, record: IData) => (
    <div className={record.isNew ? style.newCellDot : undefined}>
        <IsNewCell isNew={record.isNew}>{value || '-'}</IsNewCell>
    </div>
)

export const columns = [
    {
        title: 'Дата подачи',
        dataIndex: 'submissionDate',
        key: 'submissionDate',
        render: renderWithDot,
    },
    {
        title: 'Название Владельца ГО',
        dataIndex: 'gOOwner',
        key: 'gOOwner',
        render,
    },
    {
        title: 'Название ГО',
        dataIndex: 'gOName',
        key: 'gOName',
        render,
    },
    {
        title: 'ИНН',
        dataIndex: 'inn',
        key: 'inn',
        render,
    },
    // {
    //     title: 'Юридический адрес',
    //     dataIndex: 'address',
    //     key: 'address',
    //     render,
    // },
]

export const openedColumns = [
    ...columns,
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (status: EStatus) => (
            <Tag outline transparent>
                {status}
            </Tag>
        ),
    },
]

export const closedColumns = [
    ...columns,
    // {
    //     title: 'Статус',
    //     dataIndex: 'status',
    //     key: 'status',
    //     render: (status: EStatus) => (
    //         <Tag
    //             outline
    //             transparent
    //             color={
    //                 status === EStatus.NotAgreed
    //                     ? 'red'
    //                     : status === EStatus.Accepted
    //                     ? 'blue'
    //                     : undefined
    //             }
    //         >
    //             {status}
    //         </Tag>
    //     ),
    // },
]
