import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const RowVertical: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.6087 8.875H3.39199C2.51699 8.875 2.16699 9.24833 2.16699 10.1758V12.5325C2.16699 13.46 2.51699 13.8333 3.39199 13.8333H12.6087C13.4837 13.8333 13.8337 13.46 13.8337 12.5325V10.1758C13.8337 9.24833 13.4837 8.875 12.6087 8.875Z"
                    stroke="#296DFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.6087 2.1665H3.39199C2.51699 2.1665 2.16699 2.53984 2.16699 3.46734V5.824C2.16699 6.7515 2.51699 7.12484 3.39199 7.12484H12.6087C13.4837 7.12484 13.8337 6.7515 13.8337 5.824V3.46734C13.8337 2.53984 13.4837 2.1665 12.6087 2.1665Z"
                    stroke="#296DFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
