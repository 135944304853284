import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const CheckCircle: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z"
                    fill="#52C41A"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6903 8.19248C17.0483 8.49323 17.1041 9.03889 16.8149 9.41125L12.065 15.6779C11.9055 15.8832 11.6648 16.0018 11.4111 16C11.1573 15.9982 10.9182 15.8763 10.7614 15.6688L8.1781 12.6021C7.89376 12.2258 7.95662 11.6809 8.31852 11.3852C8.68041 11.0895 9.20428 11.1549 9.48863 11.5312L11.4257 13.7425L15.5184 8.32214C15.8075 7.94978 16.3322 7.89173 16.6903 8.19248Z"
                    fill="#52C41A"
                />
            </svg>
        )}
    />
)
