import { Divider, Input, Select, Typography } from 'antd'
import { Box } from '../../ui/Box/Box'
import { useEffect, useState } from 'react'
import { IconButton } from '../../ui'
import { Search, Trash } from '../../../assets/icons'
import { PlusCircleOutlined } from '@ant-design/icons'
import UserSearchModal from '../../ui/UserSearch/UserSearchModal'
import { UserTypesMap } from '../../../constants/approval'

import style from '../style.m.less'
import UserCard from '../../ui/UserSearch/UserCard'

type Props = {
    setNotifications: any
}

const usetRoleOptions = Array.from(UserTypesMap, ([key, value]) => ({
    label: value,
    value: key,
}))

const USER_TEMPLATE = {
    uid: '',
    message: null,
}

const AddNotification = ({ setNotifications }: Props) => {
    const [list, setList] = useState<any[]>([])
    const [userType, setUserType] = useState<number>(0)
    const [userIndex, setUserIndex] = useState<number>(0)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    const addNotification = () => {
        if (isDisableAdd) {
            return
        }
        setList([...list, USER_TEMPLATE])
    }

    const removeNotification = (index: number) => {
        setList((prev) => prev.filter((item, itemIndex) => itemIndex !== index))
    }

    const openModal = (index = 0) => {
        setUserIndex(index)
        setIsModalVisible(!isModalVisible)
    }

    const commentHandler = (index: number, text: string) => {
        const l = [...list]
        l[index].message = text
        setList(l)
    }

    const updateNotification = (data: any) => {
        const l = [...list]
        l[userIndex] = {
            ...list[userIndex],
            ...data,
        }
        setList(l)
    }

    const deleteUser = (user: any) => {
        if (list.length > 1) {
            setList((prev) => prev.filter((el: any) => el.uid !== user.uid))
        } else {
            setList([USER_TEMPLATE])
        }
    }

    useEffect(() => {
        setNotifications(list)
    }, [list])

    const isDisableAdd = !!list.find((el: any) => el.uid.length < 1)

    return (
        <Box>
            {list?.map((el: any, index: number) => (
                <Box width={el.uid.length ? 600 : ''}>
                    {el.uid.length ? (
                        <UserCard user={el} unEditable onDelete={deleteUser} />
                    ) : (
                        <>
                            <Typography.Text className={style.required}>
                                Тип пользователя
                            </Typography.Text>
                            <Box direction="row" margin="5px 0px 0px 0px">
                                <Box
                                    width={420}
                                    className={
                                        list[index].uid?.length < 1 &&
                                        list[index].message?.length > 1
                                            ? style.errorSelect
                                            : ''
                                    }
                                >
                                    <Select
                                        onChange={setUserType}
                                        options={usetRoleOptions}
                                        value={userType}
                                    />
                                </Box>
                                <IconButton
                                    onClick={() => openModal(index)}
                                    className={style.searchButton}
                                    icon={<Search />}
                                >
                                    Найти в системе
                                </IconButton>
                                <IconButton
                                    icon={<Trash />}
                                    simple
                                    onClick={() => removeNotification(index)}
                                    className={style.searchButton}
                                />
                            </Box>
                            {list[index].message?.length > 0 && (
                                <Typography.Text type="danger" style={{ fontSize: '12px' }}>
                                    Не выбран получатель уведомления
                                </Typography.Text>
                            )}
                        </>
                    )}

                    <Box margin="20px 0px 0px 0px">
                        <Typography.Text className={style.required}>
                            Текст уведомления
                        </Typography.Text>
                        <Input.TextArea
                            value={list[index].message}
                            onChange={(e: any) => commentHandler(index, e.target.value)}
                            style={{
                                minHeight: 95,
                                width: 420,
                                marginTop: 5,
                                borderColor:
                                    list[index].message?.length > 500 ||
                                    list[index].message?.length < 1
                                        ? 'red'
                                        : '',
                            }}
                        />
                        {list[index].message?.length > 500 && (
                            <Typography.Text type="danger" style={{ fontSize: '12px' }}>
                                Введено больше 500 символов
                            </Typography.Text>
                        )}
                        {list[index].message?.length < 1 && (
                            <Typography.Text type="danger" style={{ fontSize: '12px' }}>
                                Не указан текст комментария
                            </Typography.Text>
                        )}
                    </Box>
                    {list.length > 1 && index < list.length - 1 ? (
                        <Box>
                            <Divider />
                        </Box>
                    ) : (
                        <Box height={20} />
                    )}
                </Box>
            ))}
            <UserSearchModal
                prevSelected={list?.map((el: any) => el.uid)}
                userType={userType}
                onSelect={updateNotification}
                isModalVisible={isModalVisible}
                setIsModalVisible={openModal}
            />
            <Box direction="row" align="center" onClick={addNotification}>
                <PlusCircleOutlined style={{ color: isDisableAdd ? '#BFBFBF' : '#296DFF' }} />
                <Typography.Link
                    strong
                    style={{ color: isDisableAdd ? '#BFBFBF' : '#296DFF', marginLeft: '18px' }}
                >
                    Добавить уведомление
                </Typography.Link>
            </Box>
        </Box>
    )
}

export default AddNotification
