import Questionnaire from './Questionnaire/Questionnaire'
import { individualEntrepreneurInfoConfig } from './Questionnaire/individualEntrepreneurInfoConfig'
import { legalPersonInfoConfig } from './Questionnaire/legalPersonInfoConfig'
import { privatePersonInfoConfig } from './Questionnaire/privatePersonInfoConfig'
import DocumentsGrid from './DocumentsGrid/DocumentsGrid'
import Documents from './Documents/Documents'
import ButtonContainer from './ButtonContainer/ButtonContainer'
import SendForRevisionModal from './SendForRevisionModal/SendForRevisionModal'
import RefuseToApproveModal from './RefuseToApproveModal/RefuseToApproveModal'
import CardPanel from './CardPanel/CardPanel'
import Sign from './Sign/Sign'
import HistoryModal from './HistoryModal/HistoryModal'

export {
    Questionnaire,
    DocumentsGrid,
    Documents,
    ButtonContainer,
    SendForRevisionModal,
    RefuseToApproveModal,
    individualEntrepreneurInfoConfig,
    legalPersonInfoConfig,
    privatePersonInfoConfig,
    CardPanel,
    Sign,
    HistoryModal,
}
