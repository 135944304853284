import { IGeneratingObject } from '../../store/generators/interfaces'
import { useLocation } from 'react-router-dom'

interface ICustomizedLocationState {
    generatorData: IGeneratingObject
    type: string
}

export const useGeneratorData = () => {
    const location = useLocation()
    const state = location.state as ICustomizedLocationState
    const generatorData = state?.generatorData
    const type = state?.type
    return { generatorData, type }
}
