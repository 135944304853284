import { FC } from 'react'
import { message, Alert } from 'antd'
import { ButtonsBlock, SuccessMessage } from '../../../Edit/components'
import { DropdownSelector, ImportButton, Modal } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const TSOTypes = [
    { value: 'ТСО 1', id: 'f416c913-a16a-4bb8-93a6-081a89cc036a' },
    { value: 'ТСО 2', id: 'f26a2c6a-2840-470c-922a-289fca5017ad' },
]

const GPTypes = [
    { value: 'ГП 1', id: '86b99feb-b23e-4a89-961a-472bbaf81da0' },
    { value: 'ГП 2', id: 'b7140a33-0730-45aa-b435-9edb4393012c' },
]

interface IActivateGeneratorModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const ActivateGeneratorModal: FC<IActivateGeneratorModal> = ({
    isModalVisible,
    setIsModalVisible,
}) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleConfirm = () => {
        setIsModalVisible(false)
        message.success({
            content: (
                <SuccessMessage
                    header={t('detailedCard.success')}
                    message={`ЗГО СЭС “Абаканская” ${t('detailedCard.activated')}`}
                />
            ),
            icon: <></>,
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>{t('detailedCard.activate')}</div>

            <div className={style.inputBlockWrapper}>
                <div>{t('detailedCard.tco')}</div>
                <div className={style.inputBlock}>
                    <DropdownSelector
                        customStyle={{ width: '436px' }}
                        placeholder={t('detailedCard.chooseFromList')}
                        options={TSOTypes}
                    />
                </div>
                <ImportButton
                    noMargin
                    customStyle={{ marginTop: '8px' }}
                    value={t('detailedCard.manualType')}
                />
            </div>
            <div className={style.inputBlockWrapper}>
                <div>{t('detailedCard.gp')}</div>
                <div className={style.inputBlock}>
                    <DropdownSelector
                        customStyle={{ width: '436px' }}
                        placeholder={t('detailedCard.chooseFromList')}
                        options={GPTypes}
                    />
                </div>
                <ImportButton
                    noMargin
                    customStyle={{ marginTop: '8px' }}
                    value={t('detailedCard.manualType')}
                />
            </div>
            <Alert
                className={style.activationAlert}
                message={t('detailedCard.activatePrice', { price: 2500 })}
                type="warning"
                showIcon
            />

            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleConfirm}
                    cancelAction={handleOk}
                    confirmTitle={t('detailedCard.activate2')}
                />
            </div>
        </Modal>
    )
}

export default ActivateGeneratorModal
