import InfoBlock from './InfoBlock/InfoBlock'
import Tables from './Tables/Tables'
import DocumentsGrid from './DocumentsGrid/DocumentsGrid'
import ButtonContainer from './ButtonContainer/ButtonContainer'
import CoordinateDataModal from './CoordinateDataModal/CoordinateDataModal'
import CancelCoordinateModal from './CancelCoordinateModal/CancelCoordinateModal'

export {
    InfoBlock,
    Tables,
    DocumentsGrid,
    ButtonContainer,
    CoordinateDataModal,
    CancelCoordinateModal,
}
