import { Typography } from 'antd'
import React, { CSSProperties, ReactNode } from 'react'
import { Box } from '../Box/Box'

type Props = {
    icon: ReactNode
    text: string
    classNameText?: CSSProperties
    strong?: boolean
}

const IconText = ({ icon, text, ...rest }: Props) => {
    return (
        <Box direction="row" align="center" height={30}>
            {icon}
            <Typography.Text style={{ marginLeft: '10px' }} {...rest}>
                {text}
            </Typography.Text>
        </Box>
    )
}

export default IconText
