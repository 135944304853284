import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatSize, documentIconPicker } from '../../../helpers'
import { ShowMoreLink } from '../../../ui'

import style from './style.m.less'

interface IDocumentsGrid {
    documents: any
}

const DocumentsGrid: FC<IDocumentsGrid> = ({ documents }) => {
    const { t } = useTranslation()

    const [showHidden, setShowHidden] = useState(false)

    const toggleHidden = () => {
        setShowHidden(!showHidden)
    }

    const file = (item: { file_name: string; file_size: number; comment: string }) => {
        const format = item.file_name.split('.')[1]
        const size = formatSize(item.file_size)
        return (
            <div className={style.documentsWrapper}>
                <div className={style.file}>
                    {documentIconPicker(format)}
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>{item.file_name.split('.')[0]}</div>
                        <div className={style.fileSize}>{size}</div>
                    </div>
                </div>
                <div className={style.description}>{item.comment}</div>
            </div>
        )
    }

    return (
        <div className={style.main}>
            <div className={style.header}>Документы</div>
            <div className={style.documentsGrid}>{documents?.slice(0, 6).map(file)}</div>
            {showHidden && (
                <div className={style.documentsGrid}>{documents?.slice(6).map(file)}</div>
            )}
            {documents.length > 6 && (
                <ShowMoreLink
                    showMore={showHidden}
                    onChange={toggleHidden}
                    className={style.showMore}
                />
            )}
        </div>
    )
}

export default DocumentsGrid
