import { FC, useState, useRef, useEffect } from 'react'
import { StepOne, StepTwo, CheckMailModal } from './components'
import { useLocation, useNavigate } from 'react-router-dom'
import { PrimaryIconButton } from '../../../ui'
import { Form, Alert, message } from 'antd'
import { userTypeIdPicker, errorHandler } from '../../../helpers'
import {
    registrationService,
    signupService,
    passwordRecoveryService,
} from '../../../../services/auth'
import { loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { confirmEmailService, checkTokenService } from '../../../../services/auth'

const DEFAULT_PAGE_NUMBER = 1
const token = localStorage.getItem('accessToken')
    ? localStorage.getItem('accessToken')
    : sessionStorage.getItem('accessToken')

const isAuth = !!token

interface IRegister {
    isRestorePassword?: boolean
    isEmailConfirmation?: boolean
}

const Register: FC<IRegister> = ({ isRestorePassword = false, isEmailConfirmation = false }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const confirmToken = location?.search?.split('?confirm_token=')[1]

    const confirmEmail = async () => {
        await confirmEmailService(
            confirmToken,
            () => {
                if (isAuth) {
                    navigate('/profile')
                    message.success('Ваш e-mail успешно изменен')
                } else {
                    navigate('/auth')
                }
            },
            (error) => {
                console.log(error)
            },
        )
    }

    const checkToken = async () => {
        await checkTokenService(
            confirmToken,
            'email',
            () => {
                confirmEmail()
            },
            () => {
                navigate('/invalid-link')
            },
        )
    }

    const captchaRef = useRef(null)

    const [form] = Form.useForm()

    const [stepNumber, setStepNumber] = useState<number>(DEFAULT_PAGE_NUMBER)
    const [companyName, setCompanyName] = useState<string>('')
    const [middleName, setMiddleName] = useState<string>('')
    const [responseError, setResponseError] = useState('')
    const [loginAndEmail, setLoginAndEmail] = useState({ login: '', email: '' })
    const [userType, setUserType] = useState<string>('Физическое лицо')
    const [componentDisabled, setComponentDisabled] = useState<boolean>(true)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [agreement, setAgreement] = useState<boolean>(false)

    useEffect(() => {
        if (isEmailConfirmation) {
            checkToken()
        }
    }, [])

    const handleChangeAgreement = (e: any) => {
        setAgreement(e.target.checked)
    }
    const handleTypeChange = (value: any) => {
        setUserType(value)
        form.setFieldsValue({ userType: value })
    }

    const onFormLayoutChange = () => {
        setResponseError('')
        setComponentDisabled(false)
    }

    const onFinish = async (values: any) => {
        if (stepNumber === 1 && isRestorePassword) {
            let userCaptcha = captchaRef.current.state.value
            const { login, email } = values

            if (validateCaptcha(userCaptcha) === true) {
                await passwordRecoveryService(
                    login,
                    email,
                    () => {
                        setLoginAndEmail({ login: login, email: email })
                        setIsModalVisible(true)
                    },
                    (error) => {
                        console.log(error)
                        setComponentDisabled(true)
                        setResponseError(errorHandler(error.err_code))
                    },
                )
            } else {
                loadCaptchaEnginge(5)
                setComponentDisabled(true)
                userCaptcha = ''
            }
        } else if (stepNumber === 1) {
            let userCaptcha = captchaRef.current.state.value
            const { login, email } = values

            if (validateCaptcha(userCaptcha) === true) {
                await registrationService(
                    login,
                    email,
                    () => {
                        setLoginAndEmail({ login, email })
                        setComponentDisabled(true)
                        setStepNumber(2)
                        userCaptcha = ''
                    },
                    (error) => {
                        console.log(error)
                        setComponentDisabled(true)
                        setResponseError(errorHandler(error.err_code))
                    },
                )
            } else {
                loadCaptchaEnginge(5)
                setComponentDisabled(true)
                userCaptcha = ''
            }
        } else {
            const totalUserInfo = {
                ...loginAndEmail,
                ...values,
                user_type: userTypeIdPicker(userType),
                middle_name: middleName,
            }

            if (userType !== 'Индивидуальный предприниматель') {
                totalUserInfo.company_name = companyName
            }

            await signupService(
                totalUserInfo,
                () => {
                    setIsModalVisible(true)
                },
                (error) => {
                    setComponentDisabled(true)
                    setResponseError(errorHandler(error.err_code))
                },
            )
        }
    }

    const selectStep = () => {
        if (stepNumber === 1) {
            return {
                page: (
                    <StepOne
                        isRestorePassword={isRestorePassword}
                        handleTypeChange={handleTypeChange}
                        captchaRef={captchaRef}
                    />
                ),
                buttonTitle: t('login.next'),
            }
        } else if (stepNumber === 2) {
            return {
                page: (
                    <StepTwo
                        middleName={middleName}
                        setMiddleName={setMiddleName}
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                        agreement={agreement}
                        setAgreement={handleChangeAgreement}
                        form={form}
                        userType={userType}
                    />
                ),
                buttonTitle: t('login.signUp'),
            }
        }
    }

    return (
        <>
            <div className={style.headerWrapper}>
                <div className={style.loginHeader}>
                    {isRestorePassword
                        ? t('login.restorePassword')
                        : stepNumber !== 3
                        ? t('login.signUp2')
                        : t('login.setPass')}
                </div>
                {stepNumber !== 3 && !isRestorePassword && (
                    <div className={style.steps}>{`${stepNumber} из 2`}</div>
                )}
            </div>
            <Form
                form={form}
                name="registerForm"
                onFinish={onFinish}
                onValuesChange={onFormLayoutChange}
                initialValues={{
                    userType: userType,
                    middle_name_absent: false,
                    accept: true,
                    company_name: '',
                    company_position: '',
                    phone: '',
                    first_name: '',
                    last_name: '',
                    middle_name: '',
                    personal_inn: '',
                    company_inn: '',
                    ogrn: '',
                }}
            >
                {selectStep()?.page}
                {responseError && (
                    <Alert
                        className={style.responseError}
                        message={responseError}
                        type="error"
                        showIcon
                    />
                )}
                <div className={style.formBlock}>
                    <Form.Item>
                        <PrimaryIconButton
                            customStyle={{
                                width: 'max-content',
                                padding: '0 30px',
                                height: '44px',
                                fontSize: '16px',
                            }}
                            disabled={componentDisabled || (!agreement && stepNumber === 2)}
                            htmlType="submit"
                            value={selectStep()?.buttonTitle}
                        />
                    </Form.Item>
                </div>
            </Form>
            <CheckMailModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
        </>
    )
}

export default Register
