// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ownersMain_Yt3IB {\n  display: flex;\n  flex-direction: column;\n  width: 1300px;\n  padding: 40px 10px 100px;\n  margin: 0 auto;\n}\n.mainHeader_vegRd {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.mainWrapper_YjLE5 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n}\n.mainBlock_wh0B0 {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 24px;\n  width: 1280px;\n}\n.cardsBlock_ybd3N {\n  padding: 16px 24px;\n  width: 848px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.divider_wKQDQ {\n  margin: 20px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Owners/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,wBAAA;EACA,cAAA;AACJ;AAEA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,aAAA;AAFJ;AAKA;EACI,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AAHJ;AAMA;EACI,cAAA;AAJJ","sourcesContent":[".ownersMain {\n    display: flex;\n    flex-direction: column;\n    width: 1300px;\n    padding: 40px 10px 100px;\n    margin: 0 auto;\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.mainWrapper {\n    display: flex;\n    flex-direction: column;\n    margin-top: 32px;\n}\n\n.mainBlock {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 24px;\n    width: 1280px;\n}\n\n.cardsBlock {\n    padding: 16px 24px;\n    width: 848px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.divider {\n    margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ownersMain": "ownersMain_Yt3IB",
	"mainHeader": "mainHeader_vegRd",
	"mainWrapper": "mainWrapper_YjLE5",
	"mainBlock": "mainBlock_wh0B0",
	"cardsBlock": "cardsBlock_ybd3N",
	"divider": "divider_wKQDQ"
};
export default ___CSS_LOADER_EXPORT___;
