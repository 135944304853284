import { FC, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { SuccessMessage } from '../../../Edit/components'
import { PrimaryIconButton } from '../../../ui'
import { Form, Input, message, Alert } from 'antd'
import { setPasswordService, checkTokenService } from '../../../../services/auth'
import { errorHandler } from '../../../helpers'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const list = [
    {
        title: 'login.oneNumber',
    },
    {
        title: 'login.oneUpperChar',
    },
    {
        title: 'login.oneLowerChar',
    },
    {
        title: 'login.eightChars',
    },
]

const SetPassword: FC = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    const [responseError, setResponseError] = useState('')
    const [componentDisabled, setComponentDisabled] = useState<boolean>(true)

    const confirmToken = location?.search?.split('?confirm_token=')[1]

    const checkToken = async () => {
        await checkTokenService(
            confirmToken,
            'password',
            () => {
                console.log('Токен актуален')
            },
            () => {
                navigate('/invalid-link')
            },
        )
    }

    useEffect(() => {
        checkToken()
    }, [])

    const onFormLayoutChange = ({ disabled }: { disabled: boolean }) => {
        setResponseError('')
        setComponentDisabled(disabled)
    }

    const onFinish = async (values: any) => {
        const password = values.setPassword
        await setPasswordService(
            password,
            confirmToken,
            () => {
                navigate('/auth')
                message.success({
                    content: (
                        <SuccessMessage
                            header={t('login.passwordSuccess')}
                            message={t('login.enterWithPassword')}
                        />
                    ),
                    icon: <></>,
                })
            },
            (error) => {
                setComponentDisabled(true)
                setResponseError(errorHandler(error.err_code))
            },
        )
    }

    const [form] = Form.useForm()

    return (
        <>
            <div className={style.loginHeader}>{t('login.setPass')}</div>
            <Form
                form={form}
                onFinish={onFinish}
                name="setPasswordForm"
                onValuesChange={onFormLayoutChange}
            >
                <Form.Item
                    name="setPassword"
                    rules={[
                        {
                            required: true,
                            message: t('login.checkPass'),
                        },
                        {
                            pattern: new RegExp(/^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё])(?=.*\d).*$/),
                            message: t('login.correctPass'),
                        },
                    ]}
                >
                    <div className={style.formBlock}>
                        <div>{t('login.password')}</div>
                        <div className={style.inputBlock}>
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                minLength={8}
                                maxLength={20}
                            />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item
                    name="repeatPassword"
                    dependencies={['setPassword']}
                    rules={[
                        {
                            required: true,
                            message: t('login.repeatPass'),
                        },
                        {
                            pattern: new RegExp(/^(?=.*[A-ZА-ЯЁ])(?=.*[a-zа-яё])(?=.*\d).*$/),
                            message: t('login.correctPass'),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('setPassword') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(t('login.passwordsNotMatch')))
                            },
                        }),
                    ]}
                >
                    <div className={style.formBlockShort}>
                        <div>{t('login.passAgain')}</div>
                        <div className={style.inputBlock}>
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                minLength={8}
                                maxLength={20}
                            />
                        </div>
                    </div>
                </Form.Item>
                <Form.Item>
                    <div className={style.listWrapper}>
                        <div>{t('login.passMustHave')}:</div>
                        {list.map((item, index) => {
                            return (
                                <div
                                    key={item.title}
                                    className={index === 0 ? style.listItemFirst : style.listItem}
                                >
                                    <div className={style.bullet}>•</div>
                                    <div className={style.itemTitle}>{t(item.title)}</div>
                                </div>
                            )
                        })}
                    </div>
                </Form.Item>
                {responseError && (
                    <Alert
                        className={style.responseError}
                        message={responseError}
                        type="error"
                        showIcon
                    />
                )}
                <Form.Item>
                    <PrimaryIconButton
                        customStyle={{
                            width: 'max-content',
                            padding: '0 30px',
                            height: '44px',
                            fontSize: '16px',
                            marginTop: '32px',
                        }}
                        disabled={componentDisabled}
                        htmlType="submit"
                        value={t('login.savePass')}
                    />
                </Form.Item>
            </Form>
        </>
    )
}

export default SetPassword
