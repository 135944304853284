import { FC } from 'react'
import style from './style.m.less'
import moment from 'moment'
import { DropdownSelector2, TimeRangePicker } from '../../../ui'

interface IFilter {
    onChange: (data: string | number | number[], key: string, obj?: any) => void
    dict: any
    owners: any
    isOpen: boolean
}

const statusIds = [
    { id: 3, name: 'Согласована' },
    { id: 4, name: 'Отказана' },
]

const Filter: FC<IFilter> = ({ onChange, dict, owners, isOpen }) => {
    const updateFilters = (data: any, key: string) => {
        switch (key) {
            case 'rangeDate':
                return onChange('', '', {
                    ts_created_from: data ? moment(data[0]).startOf('day') : undefined,
                    ts_created_to: data ? moment(data[1]).endOf('day') : undefined,
                })
            case 'rangeReport':
                return onChange('', '', {
                    year_from: data ? moment(data[0]).year() : undefined,
                    month_from: data ? moment(data[0]).month() + 1 : undefined,
                    year_to: data ? moment(data[1]).year() : undefined,
                    month_to: data ? moment(data[1]).month() + 1 : undefined,
                })
            case 'fuel_application_status_ids':
                if (!data.length) {
                    return onChange([3, 4], 'fuel_application_status_ids')
                } else {
                    return onChange(data, key)
                }
            default:
                return onChange(data, key)
        }
    }

    const filterWidth = { width: isOpen ? '296px' : '233px' }

    return (
        <div className={style.filter}>
            <div className={style.filterItem}>
                <div className={style.label}>Отчетный период</div>
                <TimeRangePicker
                    style={filterWidth}
                    onChange={(value) => updateFilters(value, 'rangeReport')}
                    picker="month"
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Владелец ЗГО</div>
                <DropdownSelector2
                    onChange={(tid) => {
                        updateFilters(tid, 'uid_go_owner')
                    }}
                    options={owners?.go_owners?.map((item: any) => {
                        const label =
                            item.user_type === 1
                                ? `ИП ${item.last_name} ${item.first_name} ${item.middle_name}`
                                : item.company_name_short
                        return {
                            text: label,
                            value: item.uid,
                            id: item.uid,
                            key: item.uid,
                        }
                    })}
                    customStyle={filterWidth}
                    placeholder={'Все'}
                    noDefaultValue
                    allowClear
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>ЗГО</div>
                <DropdownSelector2
                    onChange={(tid) => {
                        updateFilters(tid, 'generator_id')
                    }}
                    options={dict?.generators?.map((item: any) => {
                        return {
                            text: item.name,
                            value: item.id,
                            id: item.id,
                            key: item.id,
                        }
                    })}
                    customStyle={filterWidth}
                    placeholder={'Все'}
                    noDefaultValue
                    allowClear
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Дата подачи заявки</div>
                <TimeRangePicker
                    style={filterWidth}
                    onChange={(value) => updateFilters(value, 'rangeDate')}
                />
            </div>
            {!isOpen && (
                <div className={style.filterItem}>
                    <div className={style.label}>Статус</div>
                    <DropdownSelector2
                        onChange={(tid) => {
                            updateFilters(tid, 'fuel_application_status_ids')
                        }}
                        options={statusIds.map((item: any) => {
                            return {
                                text: item.name,
                                value: item.id,
                                id: item.id,
                                key: item.id,
                            }
                        })}
                        customStyle={filterWidth}
                        placeholder={'Все'}
                        noDefaultValue
                        isMultiple
                        allowClear
                    />
                </div>
            )}
        </div>
    )
}

export default Filter
