// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".importButton_I7Ivh {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  font-weight: 600;\n  border: 0;\n  padding: 0;\n  background: rgba(0, 0, 0, 0);\n  color: #296dff;\n  transition: all 0.2s ease-in;\n}\n.importButton_I7Ivh:hover {\n  color: #528eff;\n}\n.importDisabled_DrXLj {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: 600;\n  border: 0;\n  padding: 0;\n  background: rgba(0, 0, 0, 0);\n  color: #bfbfbf;\n}\n.content_Ujcfg {\n  margin-left: 11px;\n}\n.icon__EYIO {\n  line-height: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/ImportButton/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,4BAAA;EACA,cAAA;EACA,4BAAA;AACJ;AACI;EACI,cAAA;AACR;AAGA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,4BAAA;EACA,cAAA;AADJ;AAIA;EACI,iBAAA;AAFJ;AAKA;EACI,cAAA;AAHJ","sourcesContent":[".importButton {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    font-weight: 600;\n    border: 0;\n    padding: 0;\n    background: rgba(0, 0, 0, 0);\n    color: #296dff;\n    transition: all 0.2s ease-in;\n\n    &:hover {\n        color: #528eff;\n    }\n}\n\n.importDisabled {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-weight: 600;\n    border: 0;\n    padding: 0;\n    background: rgba(0, 0, 0, 0);\n    color: #bfbfbf;\n}\n\n.content {\n    margin-left: 11px;\n}\n\n.icon {\n    line-height: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importButton": "importButton_I7Ivh",
	"importDisabled": "importDisabled_DrXLj",
	"content": "content_Ujcfg",
	"icon": "icon__EYIO"
};
export default ___CSS_LOADER_EXPORT___;
