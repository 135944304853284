import { RequestTypes } from '../hooks/useRequest'

const GENERATORS_PREFIX = '/api/generators/v1/'
const GO_OWNER_STATISTICS = 'go-owner-statistics'
const GO_PLOTSTATS = 'go-plotstats'
const DICTIONARIES_API_PREFIX = '/api/dictionaries/v1/'
const RENEWABLE_ENERGY_TYPE = 'renewable-energy-types'

export const getGoOwnerStatistics = {
    link: `${GENERATORS_PREFIX}${GO_OWNER_STATISTICS}`,
    method: RequestTypes.get,
}

export const getRenewableEnergyTypesDictionaries = {
    link: `${DICTIONARIES_API_PREFIX}${RENEWABLE_ENERGY_TYPE}`,
    method: RequestTypes.get,
}

export const getPlotstatsGOData = {
    link: `${GENERATORS_PREFIX}${GO_PLOTSTATS}`,
    method: RequestTypes.get,
}
