import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const DoubleSave: FC<IIconProps> = ({ size = 20 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.741 4.90039H4.25767C2.83267 4.90039 1.66602 6.06705 1.66602 7.49205V16.9587C1.66602 18.1671 2.53268 18.6837 3.59101 18.092L6.86601 16.267C7.21601 16.0754 7.78268 16.0754 8.12435 16.267L11.3993 18.092C12.4577 18.6837 13.3243 18.1671 13.3243 16.9587V7.49205C13.3327 6.06705 12.166 4.90039 10.741 4.90039Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3327 7.49205V16.9587C13.3327 18.1671 12.466 18.6754 11.4077 18.092L8.13269 16.267C7.78269 16.0754 7.21601 16.0754 6.86601 16.267L3.59101 18.092C2.53268 18.6754 1.66602 18.1671 1.66602 16.9587V7.49205C1.66602 6.06705 2.83267 4.90039 4.25767 4.90039H10.741C12.166 4.90039 13.3327 6.06705 13.3327 7.49205Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3327 4.25865V13.7253C18.3327 14.9337 17.466 15.442 16.4077 14.8586L13.3327 13.142V7.49198C13.3327 6.06698 12.166 4.90033 10.741 4.90033H6.66602V4.25865C6.66602 2.83365 7.83267 1.66699 9.25767 1.66699H15.741C17.166 1.66699 18.3327 2.83365 18.3327 4.25865Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
