import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Form, Input, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { CustomModal, Alert } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { DocumentsBlock } from '../../../AddEditGO/components'
import useRequest from '../../../hooks/useRequest'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import { blockAGQuery, getListAGQuery, getListAGOwners } from './config'
import { Paginated } from 'src/api/common/types'
import { AGItem } from 'src/api/ag/types'

interface IFreeGenerationAttributesProps {
    value?: string
    isModalVisible?: boolean
    setIsModalVisible: (s: boolean) => void
    refreshTotalAG: () => void
}

const dateFormat = (date: string) => {
    return moment(date, 'YYYY-MM-DD').format('MMMM, YYYY')
}

const dateDeFormat = (date: string) => {
    return moment(date, 'MMMM, YYYY').format('YYYY-MM-DD')
}

const BlockModalGenerationAttributes: FC<IFreeGenerationAttributesProps> = ({
    isModalVisible = false,
    setIsModalVisible,
    refreshTotalAG,
}) => {
    const { t } = useTranslation()
    const [period, setPeriod] = useState()
    const { id: generator_id } = useParams<{ id: string }>()

    const [form] = useForm()

    const { fetch: blockAG } = useRequest(blockAGQuery)
    const { fetch: getOwners, result: responseOwners } = useRequest(getListAGOwners)
    const { fetch: getList, result: responseGetList } =
        useRequest<Paginated<AGItem, 'list'>>(getListAGQuery)

    useEffect(() => {
        refreshAGList()
    }, [])

    const refreshAGList = () => {
        getList({ body: {}, addToLink: `?generator_id=${generator_id}` })
    }

    useEffect(() => {
        if (period) {
            getOwners({ addToLink: `?generator_id=${generator_id}&period=${period}` })
        }
    }, [period])

    const onFinishAG = async (values: any) => {
        const body = {
            ...values,
            generator_id,
            owner_id: values.owner.value,
            reason: values.reason,
            period: dateDeFormat(values.period.value),
            documents: (values?.documents || []).map((doc: any) => doc.id),
        }
        delete body.type
        delete body.owner
        blockAG({
            body,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Объем АГ успешно заблокирован',
            },
            onSuccess: () => {
                refreshTotalAG()
                refreshAGList()
                setIsModalVisible(false)
                form.resetFields()
            },
            errorCodeMessage: true,
        })
    }

    const ownersOptions = responseOwners?.owners.map((item, index: number) => {
        return {
            key: item.id || index,
            id: item.id || index,
            value: item.id,
            label: item.name,
        }
    })

    const AGPeriodsOptions = responseGetList?.list
        ?.filter(({ status }) => status === 2 || status === 3)
        .map((item, index: number) => {
            return {
                key: item.id || index,
                id: item.id || index,
                value: `${capitalize(dateFormat(item.period))}`,
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{`${capitalize(dateFormat(item.period))}`}</span>
                        <span className={style.greyText}>{`(${item.volume} кВт*ч)`}</span>
                    </div>
                ),
            }
        })

    return (
        <CustomModal
            isModalVisible={isModalVisible}
            styleWrapper={{ marginBottom: '24px' }}
            styleButtonsBlock={{ display: 'flex' }}
            params={{
                title: 'Блокировка объема АГ',
                cancelTextButton: t('documents.cancel'),
                confirmTextButton: t('documents.confirm'),
            }}
            children={
                <div className={style.modalAG}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinishAG}
                        name="formAG"
                        className={style.form}
                        validateTrigger={['onSubmit', 'onBlur', 'onChange']}
                    >
                        <Form.Item>
                            <Alert
                                className={style.documentsAlert}
                                message={
                                    'Укажите основание для блокировки. Пользователь увидит его в уведомлении о блокировке'
                                }
                                type="info"
                                showIcon
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    {t('Период производства АГ')}
                                    <span className={style.star}>*</span>
                                </>
                            }
                            name="period"
                            rules={[{ required: true, message: t('rules.required') }]}
                        >
                            <Select
                                onChange={(value) => setPeriod(dateDeFormat(value.value))}
                                labelInValue
                                placeholder={'Выберите период производства'}
                                options={AGPeriodsOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    {t('Владелец ЗГО')}
                                    <span className={style.star}>*</span>
                                </>
                            }
                            name="owner"
                            rules={[{ required: true, message: t('rules.required') }]}
                        >
                            <Select
                                labelInValue
                                placeholder={'Выберите Владельца ЗГО'}
                                options={ownersOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label={
                                <>
                                    {t('Основание')}
                                    <span className={style.star}>*</span>
                                </>
                            }
                            name="reason"
                            rules={[{ required: true, message: t('rules.required') }]}
                        >
                            <Input.TextArea
                                placeholder={'Ваш комментарий'}
                                style={{ minHeight: 45 }}
                                rows={3}
                            />
                        </Form.Item>
                        <Divider />
                        <Form.Item name="documents">
                            <DocumentsBlock
                                required
                                description="Загрузите необходимые документы"
                            />
                        </Form.Item>
                    </Form>
                </div>
            }
            onClose={() => setIsModalVisible(false)}
            closeAfterConfirm={false}
            confirmTitle={'Заблокировать'}
            onConfirm={() => {
                form.submit()
            }}
        />
    )
}

export default BlockModalGenerationAttributes
