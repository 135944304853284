import { FC, useState } from 'react'
import { Divider } from 'antd'
import { useNavigate } from 'react-router-dom'
import style from './style.m.less'
import { Card, Box, IconButton, CenteredSpin } from '../ui'
import { EmptyWallet, MoneyOut, Export, Import } from '../../assets/icons'
import { Default } from './components'
import { useTranslation } from 'react-i18next'
import useRequest from '../hooks/useRequest'
import { getAdminSum } from './config'
import useMount from '../hooks/useMount'
import { currencyFormat, downloadZip } from '../helpers'

const Registry: FC = () => {
    const { t } = useTranslation()
    const [filters, setFilters] = useState<any>()
    const navigate = useNavigate()
    const { fetch, result, isLoading } = useRequest(getAdminSum)

    useMount(() => {
        fetch({})
    })

    const filtersData = filters ? { filters: { ...filters } } : null

    const getFile = () => {
        downloadZip('/api/audit/v1/admin-history-payment?download', 'PaymentData.zip', filtersData)
    }

    if (isLoading || !result) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <h1 className={style.title}>{t('registry.title')}</h1>
            <Box direction="row" margin="32px 0" gap="16px">
                <IconButton
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<EmptyWallet />}
                    onClick={() => navigate('/admin-deposit')}
                >
                    {'Пополнить'}
                </IconButton>
                <IconButton
                    customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                    icon={<MoneyOut />}
                    onClick={() => navigate('/admin-withdraw')}
                >
                    {'Списать'}
                </IconButton>
                <IconButton
                    icon={<Export />}
                    customIconStyle={{ marginRight: '8px', marginTop: '5px' }}
                    onClick={() => navigate('/admin-uploading')}
                >
                    {'Загрузить данные'}
                </IconButton>
                <IconButton
                    icon={<Import />}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                    onClick={getFile}
                >
                    {'Выгрузить данные'}
                </IconButton>
            </Box>
            <Card className={style.sumFrame}>
                <Box direction="row" style={{ padding: '8px 16px' }}>
                    <Box>
                        <div className={style.sumTitle}>Общая сумма пополнений</div>
                        <div className={style.sumValue}>{`${currencyFormat(
                            result.total_deposit,
                        )} ₽`}</div>
                    </Box>
                </Box>
                <Divider type="vertical" className={style.divider} />
                <Box direction="row" style={{ padding: '8px 16px' }}>
                    <Box>
                        <div className={style.sumTitle}>Общая сумма списаний</div>
                        <div className={style.sumValue}>{`${currencyFormat(
                            result.total_withdrawal,
                        )} ₽`}</div>
                    </Box>
                </Box>
                <Divider type="vertical" className={style.divider} />
                <Box direction="row" style={{ padding: '8px 16px' }}>
                    <Box>
                        <div className={style.sumTitle}>Неосвоенные средства</div>
                        <div className={style.sumValue}>{`${currencyFormat(
                            result.available_funds,
                        )} ₽`}</div>
                    </Box>
                </Box>
            </Card>
            <Card style={{ marginTop: '16px' }}>
                <Default filters={filters} setFilters={setFilters} />
            </Card>
        </div>
    )
}

export default Registry
