export const getFilters = (typeIndex: number, isGeneric: boolean) => {
    const filters: {
        label: string
        type: 'input' | 'dropDown'
        key: string
        placeholder?: string
        maxLength?: number
    }[] = [
        { label: 'ФИО', type: 'input', key: 'full_name', placeholder: 'ФИО', maxLength: 100 },
        { label: 'Логин', type: 'input', key: 'login', placeholder: 'логин', maxLength: 15 },
        { label: 'Роль пользователя', type: 'dropDown', key: 'user_role' },
        { label: 'E-mail', type: 'input', key: 'email', placeholder: 'E-mail', maxLength: 30 },
        ...(isGeneric
            ? [{ label: 'ИНН', type: 'input', key: 'inn', placeholder: 'ИНН', maxLength: 12 }]
            : []),
    ]

    if (typeIndex === 0 && isGeneric) {
        filters.push({
            label: 'СНИЛС',
            type: 'input',
            key: 'snils',
            placeholder: 'СНИЛС',
            maxLength: 11,
        })
    }

    if (typeIndex === 2) {
        filters.splice(3, 0, {
            label: 'Название организации',
            type: 'input',
            key: 'company_name',
            placeholder: 'название',
            maxLength: 100,
        })
    }

    if (!isGeneric) {
        filters.push({
            label: 'Название организации',
            type: 'input',
            key: 'company_name',
            placeholder: 'название',
            maxLength: 100,
        })
    }

    return filters
}
