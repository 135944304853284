import { RequestTypes } from '../hooks/useRequest'

export const CA_API_PREFIX = '/api/ca/v1/'
export const CA_SUBMISSIONS = 'rm/submissions'
export const CA_AVAILABLE = 'rm/submission-available'
export const CA_DETAILS = 'rm/submission?id='

export const getCaList = {
    link: `${CA_API_PREFIX}${CA_SUBMISSIONS}`,
    method: RequestTypes.post,
}

export const getSubmissionAvailable = {
    link: `${CA_API_PREFIX}${CA_AVAILABLE}`,
    method: RequestTypes.get,
}

export const getCaDetails = {
    link: `${CA_API_PREFIX}${CA_DETAILS}`,
    method: RequestTypes.get,
}
