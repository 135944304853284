// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stampBlock_J13zU {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  color: #296dff;\n  cursor: pointer;\n}\n.stampBlockDisabled_ZuNP1 {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  color: #bfbfbf;\n  cursor: not-allowed;\n}\n.stampOwner_sm45e {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/IconLink/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,QAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;AACJ;AAEA;EACI,aAAA;EACA,QAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;AAAJ;AAGA;EACI,iBAAA;AADJ","sourcesContent":[".stampBlock {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    color: #296dff;\n    cursor: pointer;\n}\n\n.stampBlockDisabled {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    color: #bfbfbf;\n    cursor: not-allowed;\n}\n\n.stampOwner {\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stampBlock": "stampBlock_J13zU",
	"stampBlockDisabled": "stampBlockDisabled_ZuNP1",
	"stampOwner": "stampOwner_sm45e"
};
export default ___CSS_LOADER_EXPORT___;
