import { FC } from 'react'
import { InputNumber, Input, message } from 'antd'
import style from './style.m.less'
import moment from 'moment'
import { DropdownSelector2, TimeRangePicker, Box } from '../../../ui'

interface IFilter {
    onChange: (data: string | number, key: string, obj?: any) => void
    dict: any
    filters: any
}

const Filter: FC<IFilter> = ({ onChange, dict, filters }) => {
    const updateFilters = (data: any, key: string) => {
        switch (key) {
            case 'amount_min':
                if (data > filters?.amount_max) {
                    message.error('Максимальная сумма операции не должна быть меньше минимальной')
                    break
                } else {
                    return onChange(data, key)
                }
            case 'amount_max':
                if (data < filters?.amount_min) {
                    message.error('Максимальная сумма операции не должна быть меньше минимальной')
                    break
                } else {
                    return onChange(data, key)
                }
            case 'rangeDate':
                return onChange('', '', {
                    event_date_from: data ? moment(data[0]).utc().startOf('day') : undefined,
                    event_date_to: data ? moment(data[1]).utc().endOf('day') : undefined,
                })
            default:
                return onChange(data, key)
        }
    }

    return (
        <div className={style.filter}>
            <div className={style.filterItem}>
                <div className={style.label}>Дата операции</div>
                <TimeRangePicker
                    style={{ width: '296px' }}
                    onChange={(value) => updateFilters(value, 'rangeDate')}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Тип операции</div>
                <DropdownSelector2
                    onChange={(tid) => {
                        updateFilters(tid, 'type_event_ids')
                    }}
                    options={dict?.map((item: any) => {
                        return {
                            text: item.name,
                            value: item.id,
                            id: item.id,
                            key: item.id,
                        }
                    })}
                    customStyle={{ width: '296px' }}
                    placeholder={'Все'}
                    noDefaultValue
                    allowClear
                    isMultiple
                />
            </div>
            <div className={style.filterItem}>
                <div>Сумма операции</div>
                <Box direction="row" gap="8px">
                    <InputNumber
                        className={style.inputShort}
                        placeholder="С"
                        controls={false}
                        onChange={(value) => updateFilters(value, 'amount_min')}
                    />
                    <InputNumber
                        className={style.inputShort}
                        placeholder="По"
                        controls={false}
                        onChange={(value) => updateFilters(value, 'amount_max')}
                    />
                </Box>
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Пользователь</div>
                <Input
                    onChange={(event) => updateFilters(event.target.value, 'user')}
                    placeholder="Введите наименование или ИНН"
                    className={style.input}
                />
            </div>
        </div>
    )
}

export default Filter
