import CommonBlock from './CommonBlock/CommonBlock'
import ButtonsBlock from './ButtonsBlock/ButtonsBlock'
import ThreePartsBlock from './ThreePartsBlock/ThreePartsBlock'
import SuccessMessage from './SuccessMessage/SuccessMessage'
import FivePartsBlock from './FivePartsBlock/FivePartsBlock'
import DocumentsBlock from './DocumentsBlock/DocumentsBlock'
import TermsModal from './TermsModal/TermsModal'
import CloseEditModal from './CloseEditModal/CloseEditModal'
import ConfirmRoleChangeModal from './ConfirmRoleChangeModal/ConfirmRoleChangeModal'
import CodesConsumingBlock from './CodesConsumingBlock/CodesConsumingBlock'
import SendNotificationUser from './SendNotificationUser/SendNotificationUser'

export {
    CommonBlock,
    ButtonsBlock,
    ThreePartsBlock,
    SuccessMessage,
    FivePartsBlock,
    DocumentsBlock,
    TermsModal,
    SendNotificationUser,
    CloseEditModal,
    ConfirmRoleChangeModal,
    CodesConsumingBlock,
}
