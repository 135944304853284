import { FC, useRef } from 'react'
import { InputNumber } from 'antd'
import moment from 'moment'
import { Box, DropdownSelector2, TimeRangePicker } from '../../../ui'
import { IOperationSettings } from '../../../ui/OperationHistoryMapper/types'
import style from './style.m.less'

interface IFilter {
    settings: IOperationSettings
    typeEventIDsOptions: any
    typeEventIDsFilter: number[]
    operationTypeIdsOptions: any[]
    onChangeTypeEventIDs: (id: number[]) => void
    onChangeAmountMin: (amountMin: number) => void
    onChangeAmountMax: (amountMax: number) => void
    onChangeDate: (date: [moment.Moment, moment.Moment] | null) => void
    onChangeOperationTypeId: (id: number[]) => void
}

const OperationContractsFilters: FC<IFilter> = ({
    typeEventIDsOptions,
    operationTypeIdsOptions,
    onChangeTypeEventIDs,
    onChangeAmountMin,
    onChangeAmountMax,
    onChangeDate,
    onChangeOperationTypeId,
}: IFilter) => {
    const divBlock: any = useRef(null)

    return (
        <div className={style.filter} ref={divBlock}>
            <Box
                className={style.controlsBlock}
                justify="space-between"
                direction="row"
                style={{ gap: 10 }}
            >
                <div>
                    <div>Тип операции</div>
                    <div className={style.inputBlock}>
                        <DropdownSelector2
                            allowClear
                            customStyle={{ width: '265px' }}
                            isMultiple
                            noDefaultValue
                            onChange={(event) => {
                                onChangeTypeEventIDs(event)
                            }}
                            options={typeEventIDsOptions}
                            placeholder={'Все'}
                        />
                    </div>
                </div>
                <div className={style.dateBlock}>
                    <div>Дата операции</div>
                    <div className={style.inputBlock}>
                        <TimeRangePicker onChange={onChangeDate} />
                    </div>
                </div>
                <div className={style.dateBlock}>
                    <div>Объем атрибутов генерации, кВт*ч</div>
                    <Box direction="row" gap="8px">
                        <InputNumber
                            className={style.inputShort}
                            style={{ borderRadius: '8px' }}
                            placeholder="С"
                            controls={false}
                            onChange={(value: any) => {
                                onChangeAmountMin(value)
                            }}
                        />
                        <InputNumber
                            className={style.inputShort}
                            style={{ borderRadius: '8px' }}
                            placeholder="По"
                            controls={false}
                            onChange={(value: any) => {
                                onChangeAmountMax(value)
                            }}
                        />
                    </Box>
                </div>
                <div>
                    <div>Списание/начисление</div>
                    <div className={style.inputBlock}>
                        <DropdownSelector2
                            allowClear
                            customStyle={{ width: '255px' }}
                            noDefaultValue
                            onChange={(event) => {
                                onChangeOperationTypeId(event)
                            }}
                            options={operationTypeIdsOptions}
                            placeholder={'Все'}
                        />
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default OperationContractsFilters
