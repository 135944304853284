import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Drop: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.463 1.43375C10.1852 1.18875 9.79944 1.18875 9.52166 1.43375C8.05563 2.7025 3.72694 6.84125 3.75009 11.6625C3.75009 15.565 6.551 18.75 10.0001 18.75C13.4491 18.75 16.25 15.5737 16.25 11.6712C16.2577 6.92 11.9213 2.71125 10.463 1.43375Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                />
            </svg>
        )}
    />
)
