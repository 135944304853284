import { RequestTypes } from '../../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_ACCOUNTS = 'accounts?need_opened_only=1'
export const GREEN_TOOLS_ACCOUNTS_ADMIN = 'accounts?need_opened_only=1&user_uid='
export const GREEN_TOOLS_CHANGE = 'certificate/change-account'
export const GREEN_TOOLS_CONTRACT_CHANGE = 'contracts/change-account'
export const GREEN_TOOLS_OTHERS_CHANGE = 'ei/change-account'

export const getAccounts = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_ACCOUNTS}`,
    method: RequestTypes.get,
}

export const getAccountsAdmin = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_ACCOUNTS_ADMIN}`,
    method: RequestTypes.get,
}

export const changeAccount = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CHANGE}`,
    method: RequestTypes.post,
}

export const contractChangeAccount = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CONTRACT_CHANGE}`,
    method: RequestTypes.post,
}

export const othersChangeAccount = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_OTHERS_CHANGE}`,
    method: RequestTypes.post,
}
