import { Component, ErrorInfo, ReactNode } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ErrorScreen } from './components'

interface IErrorProps extends WithTranslation {
    children: ReactNode
}

interface IErrorState {
    hasError: boolean
}

class ErrorBoundary extends Component<IErrorProps, IErrorState> {
    state: IErrorState = {
        hasError: false,
    }

    static getDerivedStateFromError(_: Error): IErrorState {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <ErrorScreen type="common" />
        }
        return this.props.children
    }
}

export default withTranslation()(ErrorBoundary)
