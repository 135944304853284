import { FC } from 'react'
import { Input, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'

interface IThreePartsBlock {
    labels: string[]
}

const ThreePartsBlock: FC<IThreePartsBlock> = ({ labels }) => {
    const { t } = useTranslation()

    return (
        <div className={style.inputsWrapperShort}>
            <div>
                <div className={style.inputBlock}>
                    {labels[0]}
                    <div className={style.star}>*</div>
                </div>
                <Form.Item
                    name={'company_inn'}
                    rules={[
                        {
                            required: true,
                            pattern: new RegExp(/^\d+$/),
                            message: t('login.pleaseINN'),
                        },
                    ]}
                >
                    <Input className={style.inputMiddle} minLength={10} maxLength={10} />
                </Form.Item>
            </div>
            <div>
                <div className={style.inputBlock}>
                    {labels[2]}
                    <div className={style.star}>*</div>
                </div>
                <Form.Item
                    name="kpp"
                    rules={[
                        {
                            required: true,
                            pattern: new RegExp(/^\d+$/),
                            message: t('login.pleaseKPP'),
                        },
                    ]}
                >
                    <Input className={style.inputMiddle} minLength={9} maxLength={9} />
                </Form.Item>
            </div>
            <div>
                <div className={style.inputBlock}>
                    {labels[1]}
                    <div className={style.star}>*</div>
                </div>
                <Form.Item
                    name="ogrn"
                    rules={[
                        {
                            required: true,
                            pattern: new RegExp(/^\d+$/),
                            message: t('login.pleaseOGRN'),
                        },
                    ]}
                >
                    <Input className={style.inputMiddle} minLength={13} maxLength={13} />
                </Form.Item>
            </div>
        </div>
    )
}

export default ThreePartsBlock
