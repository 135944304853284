import { FC } from 'react'
import { Divider, Checkbox } from 'antd'
import style from './style.m.less'

const MySettings: FC = () => {
    return (
        <div className={style.settingsMain}>
            <div className={style.settingsFrame}>
                <div className={style.subheader}>Конфиденциальность</div>
                <div className={style.settingRowCheckboxFirst}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать мои сертификаты происхождения в реестре
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать мои зеленые договоры в реестре
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать мои другие зеленые инструменты в реестре
                    </div>
                </div>
                <Divider />
                <div className={style.settingRowNoMargin}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать сертификаты происхождения, в которых я являюсь потребителем
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox checked />
                    <div className={style.checkboxText}>
                        Не показывать зеленые договоры, в которых я являюсь потребителем
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать другие зеленые инструменты, в которых я являюсь потребителем
                    </div>
                </div>
            </div>
            <div className={style.settingsFrame}>
                <div className={style.subheader}>Комиссии</div>
                <div className={style.settingRowCheckboxFirst}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Автоматическое списание комиссии за обслуживание лицевых счетов
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MySettings
