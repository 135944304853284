import { RequestTypes } from '../../../hooks'
import { IOperationConfig } from '../../../ui/OperationHistoryMapper/types'

export enum EOperationTypeID {
    OpTypeAll = 0, // Все
    OpTypeDec = 1, // списания
    OpTypeInc = 2, // начисления
}

export const operationTypeIdMap = new Map<EOperationTypeID, string>([
    [EOperationTypeID.OpTypeAll, 'Все'],
    [EOperationTypeID.OpTypeDec, 'Cписания'],
    [EOperationTypeID.OpTypeInc, 'Начисления'],
])

export const historyConfig: IOperationConfig[] = [
    {
        date: 'Сегодня, 17 июня, пятница',
        operations: [
            { id: 10, time: '22:40', value: 2450 },
            { id: 14, time: '22:37', value: 524739 },
            { id: 15, time: '19:56', value: 364595 },
        ],
    },
    {
        date: 'Вчера, 16 июня, четверг',
        operations: [
            { id: 18, time: '19:01', value: 114 },
            { id: 19, time: '18:11', value: 191291 },
            { id: 20, time: '13:03', value: 253591 },
            { id: 29, time: '12:46', value: 127409 },
            { id: 30, time: '08:46', value: 823086 },
            { id: 32, time: '05:20', value: 154578 },
            { id: 37, time: '04:40', value: 689106 },
        ],
    },
    {
        date: '14 июня, вторник',
        operations: [
            { id: 45, time: '20:53', value: 873037 },
            { id: 50, time: '20:28', value: 156268 },
            { id: 56, time: '18:32', value: 357854 },
            { id: 62, time: '16:15', value: 119175 },
            { id: 63, time: '14:59', value: 176537 },
            { id: 65, time: '14:33', value: 17331 },
            { id: 66, time: '13:41', value: 712494 },
            { id: 67, time: '08:26', value: 860133 },
            { id: 70, time: '03:45', value: 908255 },
            { id: 71, time: '02:58', value: 1004243 },
        ],
    },
]

export const requestConfig = {
    link: '/api/audit/v1/event-history-payment-acc',
    method: RequestTypes.post,
}

export const getOperationContractsHistory = {
    link: '/api/audit/v1/event-history-contracts',
    method: RequestTypes.post,
}
