import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { HeaderBlock, DropdownSelector, DatePicker, DocumentsBlockUI } from '../../../ui'
import { Divider, Input, Checkbox } from 'antd'
import { ButtonsBlock, CommonBlock, ThreePartsBlock } from '../'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const clientTypes = [
    { value: 'Клиент', id: '32177a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Не клиент', id: '7c23e945-c4c6-46dc-ad08-156c3456610d' },
]

const userTypes = [
    { value: 'Физическое лицо', id: '60df8c96-6af7-448f-9308-2c0f815a1e37' },
    { value: 'Юридическое лицо', id: '8212377f-4b14-4e93-b2d1-fe76307b7eec' },
    { value: 'Индивидуальный предприниматель', id: '34ce7be6-b338-4e5b-951c-ef2ecade5da0' },
]

const ChangeRole: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const getBackToProfile = () => {
        navigate('/profile')
    }

    return (
        <div className={style.editMain}>
            <HeaderBlock label={t('edit.changeRole')} />
            <div className={style.editFrame}>
                <CommonBlock
                    label={t('edit.currentRole')}
                    select={
                        <Input disabled defaultValue={t('edit.ownerGO')} className={style.input} />
                    }
                />
                <CommonBlock
                    required
                    margin
                    label={t('edit.newRole')}
                    select={
                        <DropdownSelector
                            options={clientTypes}
                            customStyle={{ width: '400px', marginLeft: '16px' }}
                        />
                    }
                />
                <CommonBlock
                    required
                    margin
                    label={t('edit.userType')}
                    select={
                        <DropdownSelector
                            options={userTypes}
                            customStyle={{ width: '400px', marginLeft: '16px' }}
                        />
                    }
                />
                <Divider />
                <CommonBlock
                    required
                    margin
                    label={t('edit.fullName')}
                    select={<Input className={style.input} />}
                />
                <CommonBlock
                    required
                    margin
                    label={t('edit.shortName')}
                    select={<Input className={style.input} />}
                />
                <ThreePartsBlock labels={[t('edit.inn'), t('edit.ogrn'), t('edit.kpp')]} />
                <CommonBlock
                    margin
                    label={t('edit.phoneFax')}
                    select={<Input className={style.input} />}
                />
                <CommonBlock margin label="Сайт" select={<Input className={style.input} />} />
                <Divider />
                <CommonBlock
                    required
                    label={t('edit.egrulCompName')}
                    select={<Input className={style.inputLong} />}
                />
                <CommonBlock
                    required
                    margin
                    label={t('edit.egrulDate')}
                    select={
                        <DatePicker
                            customStyle={{ marginLeft: '16px', width: '200px', height: '32px' }}
                        />
                    }
                />
                <Divider />
                <CommonBlock
                    required
                    label={t('edit.bossStatus')}
                    select={<Input className={style.input} />}
                />
                <ThreePartsBlock
                    labels={[t('edit.surname'), t('edit.userName'), t('edit.lastName')]}
                />
                <Divider />
                <CommonBlock
                    required
                    label={t('edit.urAddress')}
                    select={<Input className={style.inputLong} />}
                />
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('edit.region')}</div>
                        <Input className={style.addressInput} />
                    </div>
                    <div>
                        <div>{t('edit.city')}</div>
                        <Input className={style.addressInput} />
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('edit.street')}</div>
                        <Input className={style.addressInputLong} />
                    </div>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('edit.index')}</div>
                        <Input className={style.addressInputShort} />
                    </div>
                    <div>
                        <div>{t('edit.house2')}</div>
                        <Input className={style.addressInputShort} />
                    </div>
                    <Checkbox className={style.addressCheckbox}>{t('edit.noHome')}</Checkbox>
                </div>
                <div className={style.blockGroup}>
                    <div>
                        <div>{t('edit.block')}</div>
                        <Input className={style.addressInputTiny} />
                    </div>
                    <div>
                        <div>{t('edit.building')}</div>
                        <Input className={style.addressInputTiny} />
                    </div>
                    <div>
                        <div>{t('edit.appartment')}</div>
                        <Input className={style.addressInputTiny} />
                    </div>
                    <Checkbox checked className={style.addressCheckbox}>
                        {t('edit.noAppartment')}
                    </Checkbox>
                </div>
                <Divider />
                <CommonBlock
                    margin
                    label={t('edit.mailAddress')}
                    select={
                        <Checkbox checked className={style.checkbox}>
                            {t('edit.matchUr')}
                        </Checkbox>
                    }
                />
                <Divider />
                <div className={style.documentsBlockWrapper}>
                    <DocumentsBlockUI type={'profile'} />
                </div>
                <Divider />
                <ButtonsBlock
                    confirmTitle={t('edit.changeRole2')}
                    cancelAction={getBackToProfile}
                />
            </div>
        </div>
    )
}

export default ChangeRole
