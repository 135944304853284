import { FC, useState, useEffect } from 'react'
import { message, Divider, Input, Checkbox, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ICertificates, CertificatesModal } from '../Login/components'
import { Link, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
    ButtonsBlock,
    SuccessMessage,
    CommonBlock,
    ThreePartsBlock,
    FivePartsBlock,
    DocumentsBlock,
    TermsModal,
    SendNotificationUser,
    ConfirmRoleChangeModal,
    CodesConsumingBlock,
} from './components'
import IAppState from '../../store/states'
import moment from 'moment'
import {
    userTypeIdPicker,
    userTypePicker,
    userRolePicker,
    userRoleIdPicker,
    citizenshipIdPicker,
    documentTypeIdPicker,
    availableRolesSelector,
    errorHandler,
    hexToBinary,
} from '../helpers'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import {
    DropdownSelector,
    IconButton,
    HeaderBlock,
    DatePicker,
    ValueSelector,
    CenteredSpin,
    Alert,
} from '../ui'
import style from './style.m.less'
import { ArrowSwap, Stamp, AddCircle, InfoCircle } from '../../assets/icons'
import { profileSignupService, getCompanyService } from '../../services/auth'
import { userTypes, citizenship, documentTypes } from '../configuration/systemTypesConfig'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import { getUser } from '../../store/user'
import SignUtils from '../../utils/sign/SignUtils'
import { signBody } from '../helpers/signing'
import { checkErrorPairs } from './components/TermsModal/config'
import { IUserInfo } from 'store/user/interfaces'
import { EUserRole } from '../../constants/profile'
import { encode } from 'js-base64'

interface IContinueRegistration {
    type: 'registration' | 'edit' | 'add'
    user?: {
        data: IUserInfo
        isLoading: boolean
    }
}
// TODO NEED refact
const ContinueRegistration: FC<IContinueRegistration> = ({ type, user }) => {
    const { t } = useTranslation()

    const [form] = Form.useForm()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isRegistration = type === 'registration'
    const isEdit = type === 'edit'
    const isAddUser = type === 'add'

    const userInfo = useSelector((store: IAppState) => store.user)
    const { data, isLoading } = user || userInfo

    const isEditingAdmin =
        user?.data?.user_role === EUserRole.Administrator ||
        user?.data?.user_role === EUserRole.Operator
    const isEditBySuperAdmin = userInfo?.data?.user_role === EUserRole.SuperAdmin
    const defaultUserRole = isRegistration
        ? 'Клиент'
        : isAddUser
        ? 'Оператор'
        : userRolePicker(data?.user_role)

    const [userType, setUserType] = useState<string>(
        isAddUser || isEditingAdmin ? 'Юридическое лицо' : userTypePicker(data?.user_type),
    )
    const [userRole, setUserRole] = useState<string>(defaultUserRole)

    const isAddingAdminOrOperator =
        (userRole === 'Администратор' || userRole === 'Оператор') && !isEdit

    const [citizenshipType, setCitizenshipType] = useState<string>(citizenship[0].value)
    const [noMiddleName, setNoMiddleName] = useState<boolean>(data?.middle_name_absent)
    const [documentType, setDocumentType] = useState<string>(documentTypes[0].value)
    const [birthdayDate, setBirthdayDate] = useState<string>(data?.birthday)
    const [egrulDate, setEgrulDate] = useState<string>(data?.egrul_registration_date)
    const [passDate, setPassDate] = useState<string>(data?.passport_date)
    const [companyName, setCompanyName] = useState<string>(data?.company_name)
    const [companyNameShort, setCompanyNameShort] = useState<string>(data?.company_name_short)
    const [companies, setCompanies] = useState<any[]>([])
    const [companiesShort, setCompaniesShort] = useState<any[]>([])
    const [fileList, setFileList] = useState<{ list: any[]; total: number }>({ list: [], total: 0 })
    const [codesArray, setCodesArray] = useState<string[]>(
        data?.point_delivery_codes_consuming || [''],
    )
    const [codesError, updateCodesError] = useState<boolean>(false)
    const [certificatesList, setCertificatesList] = useState<ICertificates | null>(null)
    const [currentCertificate, setCurrentCertificate] = useState<any | null>(null)
    const [entrepreneurCompanyName, setEntrepreneurCompanyName] = useState(
        `ИП ${data?.last_name} ${data?.first_name} ${data?.middle_name}`,
    )
    const [isTermsModalVisible, setIsTermsModalVisible] = useState(false)
    const [isRolesModalVisible, setIsRolesModalVisible] = useState(false)
    const [isAddingConfirmationModalVisible, setIsAddingConfirmationModalVisible] = useState(false)
    const [responseError, setResponseError] = useState('')
    const [valuesData, setValuesData] = useState()
    const [documentCustomBetrayedDate, setDocumentCustomBetrayedDate] = useState<string>(
        data?.document_custom_betrayed_date,
    )
    const [documentCustomEndDate, setDocumentCustomEndDate] = useState<string>(
        data?.document_custom_end_date,
    )
    const roleChangeCase = data?.user_role !== userRoleIdPicker(userRole) && type === 'edit'
    const userRoleName = userType ? userType : userTypePicker(data?.user_type)
    const isUserIP = userRoleName === 'Индивидуальный предприниматель'
    const isWithoutPA = userRoleIdPicker(userRole) === 1

    const { fetch: editUserByAdmin } = useRequest({
        link: '/api/users/v1/edit',
        method: RequestTypes.post,
    })

    const { fetch: addUser } = useRequest({
        link: `/api/users/v1/create`,
        method: RequestTypes.post,
    })

    useEffect(() => {
        if (!user) {
            dispatch(getUser())
        }
    }, [])

    useEffect(() => {
        const getTP = async () => {
            const thumbprint = await SignUtils.getCertificateThumbprint(
                data.certificate_data.certificate,
            )

            setCurrentCertificate({ ...data.certificate_data, thumbprint })
        }
        if (data?.certificate_data) {
            getTP()
        }

        if (data?.documents && data?.documents.length !== 0) {
            const changedDocs = data?.documents?.map((item) => {
                return { ...item, size: item.file_size, name: item.file_name }
            })
            const sizesArray = changedDocs?.map((item) => item.size)
            const totalSize = sizesArray.reduce((acc, val) => acc + val, 0)
            setFileList({ list: [...changedDocs], total: totalSize })
        }
    }, [data])

    useEffect(() => {
        if (isRegistration) {
            checkErrorPairs(userRoleIdPicker(userRole), userTypeIdPicker(userType))
        }
    }, [userType, userRole])

    useEffect(() => {
        setUserType(
            isAddUser || isEditingAdmin ? 'Юридическое лицо' : userTypePicker(data?.user_type),
        )
    }, [isAddUser, isEditingAdmin, data?.user_type])

    const clearCertificates = () => {
        setCertificatesList(null)
    }

    const onAddCertificate = async () => {
        const allCertificates = await SignUtils.getAllCertificates()
        setCertificatesList(allCertificates)
    }

    const setCertificate = (certificate: any) => {
        setCurrentCertificate(certificate)
    }

    const updateFileList = (file: any, id: string, hash: string, comment: string) => {
        file.id = id
        file.hash = hash
        file.comment = comment
        setFileList((currentList) => {
            return {
                list: [...currentList.list, file],
                total: currentList.total + file.size,
            }
        })
    }

    const updateCodesArray = (code: string) => {
        setCodesArray((currentList) => [...currentList, code])
    }

    const removeFromCodesArray = (index: number) => {
        setCodesArray((currentList) => currentList.filter((_, i) => i !== index))
    }

    const changeCode = (index: number, value: string) => {
        const newArray = [...codesArray]
        newArray[index] = value
        const trimed = newArray.filter((el: string) => el.length > 0)
        setCodesArray(trimed)
    }

    const setCodesError = (value: boolean) => {
        updateCodesError(value)
    }

    const deleteFromFileList = (uid: string) => {
        const filteredArray = fileList.list.filter((obj) => obj.id !== uid)
        setFileList({
            total: filteredArray.length,
            list: filteredArray,
        })
    }

    const refreshCompanyName = () => {
        setEntrepreneurCompanyName(
            `ИП ${form.getFieldValue('last_name')} ${form.getFieldValue(
                'first_name',
            )} ${form.getFieldValue('middle_name')}`,
        )
    }

    const openTermsModal = () => {
        setIsTermsModalVisible(true)
    }

    const openRolesModal = () => {
        setIsRolesModalVisible(true)
    }

    const openAddingConfirmationAddingModal = () => {
        setIsAddingConfirmationModalVisible(true)
    }

    const confirmRoleChange = () => {
        handleFinish(valuesData)
        setIsRolesModalVisible(false)
    }

    useEffect(() => {
        if (!isAddUser) {
            form.setFieldsValue(data)
        }

        form.setFieldsValue({
            citizenship: 'Российская Федерация',
        })
    }, [form, data])

    const handleSearch = async (value: string) => {
        if (value) {
            const res = await getCompanyService(
                value,
                (error) => {
                    console.log(error)
                },
                false,
            )
            setCompanies(res)
        } else {
            setCompanies([])
        }
    }

    const handleSearchShort = async (value: string) => {
        if (value) {
            const res = await getCompanyService(
                value,
                (error) => {
                    console.log(error)
                },
                true,
            )
            setCompaniesShort(res)
        } else {
            setCompaniesShort([])
        }
    }

    const handleTypeChange = (value: any) => {
        setUserType(value)
    }
    const handleRoleChange = (value: any) => {
        setUserRole(value)
    }
    const handleCitizenshipChange = (value: any) => {
        setCitizenshipType(value)
    }
    const handleDocumentTypeChange = (value: any) => {
        setDocumentType(value)
    }
    const handleBirthdayDateChange = (value: any) => {
        setBirthdayDate(moment.utc(value).format())
    }
    const handleEgrulDateChange = (value: any) => {
        setEgrulDate(moment.utc(value).format())
    }
    const handlePassDateChange = (value: any) => {
        setPassDate(moment.utc(value).format())
    }
    const handleDocumentCustomBetrayedDateChange = (value: string) => {
        setDocumentCustomBetrayedDate(moment.utc(value).format())
    }
    const handleDocumentCustomEndDateChange = (value: any) => {
        setDocumentCustomEndDate(moment.utc(value).format())
    }

    const onChange = (e: any) => {
        if (!noMiddleName) {
            form.setFieldsValue({
                middle_name: '',
            })
            setEntrepreneurCompanyName(
                `ИП ${form.getFieldValue('last_name')} ${form.getFieldValue('first_name')}`,
            )
        }
        setNoMiddleName(e.target.checked)
    }

    const [isAddressesMatch, setIsAddressesMatch] = useState<boolean>(data?.post_address_matches)
    const addressesMatch = (e: any) => {
        setIsAddressesMatch(e.target.checked)
    }

    const createFilesForRequest = (values: any) => {
        if (!fileList.list.length) {
            return []
        }

        const newFileList = fileList?.list?.map((item) => {
            //TODO REFACT THIS if elseif to "? :" наличия ключа / содержимого можно проверить без hasOwnProperty, в целом не принципиально, но лучше упростить
            let comment = ''
            if (values.hasOwnProperty(item.uid)) {
                comment = values[item.uid]
            } else if (values.hasOwnProperty(item.id)) {
                comment = values[item.id]
            }
            return { id: item.id, hash: item.hash, comment: comment }
        })
        return newFileList
    }

    const handleFinish = async (values: any) => {
        const commonInfo = {
            user_role: userRoleIdPicker(userRole),
            user_type: userTypeIdPicker(userType),
            first_name: values.first_name,
            last_name: values.last_name,
            middle_name: values.middle_name,
            middle_name_absent: noMiddleName,
            documents: createFilesForRequest(values),
            certificate: isAddUser ? null : currentCertificate?.certificate,
            point_delivery_codes_consuming: codesArray.filter((item) => item !== ''),
        }

        const documentInfo =
            documentTypeIdPicker(documentType) === 1
                ? {
                      passport_series: values.passport_series,
                      passport_number: values.passport_number,
                      passport_date: passDate,
                      passport_betrayed_code: values.passport_betrayed_code,
                      passport_betrayed_name: values.passport_betrayed_name,
                  }
                : { document_comment: values.document_comment }

        const totalInfo =
            userTypeIdPicker(userType) === 0
                ? {
                      ...commonInfo,
                      birthday: birthdayDate,
                      citizenship_type: citizenshipIdPicker(citizenshipType),
                      citizenship: values.citizenship,
                      document_type: documentTypeIdPicker(documentType),
                      ...documentInfo,
                      registration_address: values.registration_address,
                      post_address: values.post_address,
                      post_address_matches: isAddressesMatch,
                      personal_inn: values.personal_inn,
                      snils: values.snils,
                      phone: values.phone,
                      company_name: companyName,
                      company_name_short: companyNameShort,
                      ogrn: values.ogrn,
                      company_position: values.company_position,
                  }
                : userTypeIdPicker(userType) === 1
                ? {
                      ...commonInfo,
                      birthday: birthdayDate,
                      citizenship_type: citizenshipIdPicker(citizenshipType),
                      citizenship: values.citizenship,
                      document_type: documentTypeIdPicker(documentType),
                      ...documentInfo,
                      registration_address: values.registration_address,
                      post_address: values.post_address,
                      post_address_matches: isAddressesMatch,
                      ogrn: values.ogrn,
                      egrul_government_name: values.egrul_government_name,
                      egrul_registration_date: egrulDate,
                      company_position: values.company_position,
                      personal_inn: values.personal_inn,
                      snils: values.snils,
                      phone: values.phone,
                      web: values.web,
                  }
                : {
                      ...commonInfo,
                      company_name: companyName,
                      company_name_short: companyNameShort,
                      company_inn: values.company_inn,
                      ogrn: values.ogrn,
                      kpp: values.kpp,
                      egrul_government_name: values.egrul_government_name,
                      egrul_registration_date: egrulDate,
                      company_position: values.company_position,
                      phone: values.phone,
                      web: values.web,
                      legal_address: values.legal_address,
                      post_address: values.post_address,
                      post_address_matches: isAddressesMatch,
                  }

        const adminTotalInfo = {
            user_role: userRoleIdPicker(userRole),
            first_name: values.first_name,
            last_name: values.last_name,
            middle_name: values.middle_name,
            middle_name_absent: noMiddleName,
            phone: values.phone,
            ogrn: values.ogrn,
            company_name: companyName,
            company_name_short: companyNameShort,
            company_inn: values.company_inn,
            company_phone: values.company_phone,
            kpp: values.kpp,
            egrul_government_name: values.egrul_government_name,
            egrul_registration_date: egrulDate,
            web: values.web,
            legal_address: values.legal_address,
            post_address: values.post_address,
            post_address_matches: isAddressesMatch,

            document_custom_name: values.document_custom_name,
            document_custom_number: values.document_custom_number,
            document_custom_betrayed_name: values.document_custom_betrayed_name,
            document_custom_betrayed_date: documentCustomBetrayedDate,
            document_custom_end_date: documentCustomEndDate,
        }

        let signature = ''
        if (!isAddUser && !isEditBySuperAdmin) {
            const requestUuid = uuidv4()
            const encodedInfo = encode(JSON.stringify(totalInfo))
            const encoded = requestUuid + encodedInfo
            const preSignature = await SignUtils.SignCreate(currentCertificate?.thumbprint, encoded)
            signature = btoa(hexToBinary(preSignature))
        }

        if (isAddUser) {
            openAddingConfirmationAddingModal()
        } else if (user && !isAddUser) {
            editUserByAdmin({
                getParams: { uid: data.uid },
                body: {
                    editing_reason: values.reason,
                    ...(isEditingAdmin ? adminTotalInfo : totalInfo),
                },
                onSuccess: () => {
                    navigate(`/user/${data.uid}`)
                },
                successMessage: {
                    title: 'Данные успешно изменены',
                },
                errorCodeMessage: true,
            })
        } else {
            const certificateData = {
                certificate_data: { certificate: currentCertificate.certificate },
            }
            const { request_id, signature } = await signBody(totalInfo, certificateData)

            await profileSignupService(
                totalInfo,
                request_id,
                signature,
                () => {
                    navigate('/profile')
                    message.success({
                        content: <SuccessMessage header="" message={t('editMyProfile.dataSend')} />,
                        icon: <></>,
                    })
                },
                (error) => {
                    if (roleChangeCase) {
                        setIsRolesModalVisible(false)
                    }
                    setResponseError(errorHandler(error.err_code))
                },
            )
        }
    }

    const onFinish = (values: any) => {
        if (
            isEditBySuperAdmin ||
            isEdit ||
            (form.getFieldValue('agreeTerms') &&
                form.getFieldValue('agreeProcessing') &&
                !codesError)
        ) {
            setResponseError('')
            if (!isRegistration) {
                setValuesData(values)
            }
            if (roleChangeCase) {
                if (isRegistration) {
                    setValuesData(values)
                }
                setValuesData(values)
                openRolesModal()
            } else if (currentCertificate || isEditingAdmin) {
                handleFinish(values)
            } else {
                setCertificate('empty')
            }
        } else if (codesError) {
            setResponseError(
                'Код ГТП потребления должен состоять из 8 символов и содержать только латинские буквы и цифры',
            )
        } else {
            setResponseError(
                'Пожалуйста, подтвердите согласие с обработкой персональных данных и условиями договора',
            )
        }
    }

    const validateCheckBox = (message: string) => {
        return {
            validator: (_: any, value: any) =>
                value ? Promise.resolve() : Promise.reject(new Error(message)),
        }
    }

    const getBack = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        navigate('/profile')
    }

    const onAddUser = () => {
        addUser({
            body: {
                user_role: userRoleIdPicker(userRole),
                documents: [],
                certificate: null,
                point_delivery_codes_consuming: [],
                ...form.getFieldsValue(),
                middle_name_absent: noMiddleName,
                company_name: companyName,
                company_name_short: companyNameShort,
                company_phone: '',
                egrul_registration_date: egrulDate,
                post_address_matches: isAddressesMatch,

                document_custom_betrayed_date: documentCustomBetrayedDate,
                document_custom_end_date: documentCustomEndDate,
            },
            successMessage: {
                title: 'Успешно',
                description: 'Новый административный пользователь добавлен в Систему',
            },
            onSuccess: () => {
                navigate(`/users`)
            },
            errorCodeMessage: true,
        })
    }

    if (isLoading || !data) {
        return <CenteredSpin />
    }

    return (
        <div className={style.editMain}>
            {isRegistration && (
                <div className={style.loginHeader}>{t('login.continueRegTitle')}</div>
            )}
            {isEdit && <HeaderBlock label={t('editMyProfile.editProfile')} />}
            {isAddUser && <HeaderBlock label={t('editMyProfile.addUser')} />}
            <>
                <Form
                    form={form}
                    name="continueRegistrationForm"
                    scrollToFirstError={true}
                    initialValues={
                        // if we add a new user we don't need any filled fields, but if we edit we need
                        isAddUser
                            ? {
                                  citizenship: 'Российская Федерация',
                                  citizenship_type: citizenshipType,
                                  user_type: userType,
                                  user_role: userRole,
                                  post_address_matches: isAddressesMatch,
                                  document_type: documentType,
                                  agreeProcessing: true,
                                  agreeTerms: true,
                                  passport_date: passDate,
                              }
                            : {
                                  first_name: data.first_name,
                                  last_name: data.last_name,
                                  middle_name: data.middle_name,
                                  middle_name_absent: data.middle_name_absent,
                                  company_name: data.company_name,
                                  company_name_short: data.company_name_short,
                                  ogrn: data.ogrn,
                                  company_inn: data.company_inn,
                                  personal_inn: data.personal_inn,
                                  company_position: data.company_position,
                                  phone: data.phone,
                                  post_address: data.post_address,
                                  registration_address: data.registration_address,
                                  legal_address: data.legal_address,
                                  kpp: data.kpp,
                                  egrul_government_name: data.egrul_government_name,
                                  egrul_registration_date: data.egrul_registration_date,
                                  web: data.web,
                                  document_custom_name: data.document_custom_name,
                                  document_custom_number: data.document_custom_number,
                                  document_comment: data.document_comment,
                                  passport_series: data.passport_series,
                                  passport_number: data.passport_number,
                                  passport_betrayed_name: data.passport_betrayed_name,
                                  passport_betrayed_code: data.passport_betrayed_code,
                                  snils: data.snils,
                                  citizenship: 'Российская Федерация',
                              }
                    }
                    onFinish={onFinish}
                    onValuesChange={() => form.validateFields()}
                    validateTrigger={['onSubmit', 'onBlur']}
                >
                    {user && (
                        <div className={`${style.editFrame} ${style.reason}`}>
                            <div className={style.reasonLabel}>Причина редактирования</div>
                            <Form.Item
                                className={style.reasonTextArea}
                                name="reason"
                                rules={[{ required: true, message: 'Укажите причину' }]}
                            >
                                <TextArea className={style.reasonTextArea} rows={4} />
                            </Form.Item>
                        </div>
                    )}
                    <div className={style.editFrame}>
                        {isAddUser && (
                            <>
                                <CommonBlock
                                    required
                                    label={t('login.login')}
                                    select={
                                        <Form.Item
                                            name="login"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseLogin'),
                                                },
                                                {
                                                    pattern: new RegExp(/^[a-zA-Z0-9_]+$/),
                                                    message:
                                                        'Логин должен содержать только латинские буквы, цифры и подчеркивания',
                                                },
                                            ]}
                                        >
                                            <Input maxLength={15} className={style.input} />
                                        </Form.Item>
                                    }
                                />
                                <CommonBlock
                                    required
                                    margin
                                    label={t('login.email')}
                                    select={
                                        <Form.Item
                                            name="email"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseEmail'),
                                                },
                                            ]}
                                        >
                                            <Input className={style.input} />
                                        </Form.Item>
                                    }
                                />
                            </>
                        )}
                        <CommonBlock
                            required
                            margin={isAddUser}
                            label={t('editMyProfile.role')}
                            select={
                                <DropdownSelector
                                    options={availableRolesSelector(
                                        data.user_role,
                                        data.user_type,
                                        isRegistration,
                                        isAddUser,
                                    )}
                                    onChange={handleRoleChange}
                                    customStyle={{ width: '400px', marginLeft: '16px' }}
                                />
                            }
                        >
                            <div className={style.infoCircle} onClick={openTermsModal}>
                                <InfoCircle />
                            </div>
                        </CommonBlock>
                        {!isEditingAdmin && !isAddingAdminOrOperator && !isAddUser && (
                            <CommonBlock
                                required
                                margin
                                label={t('editMyProfile.userType')}
                                select={
                                    <DropdownSelector
                                        defaultValue={userTypePicker(data.user_type)}
                                        options={userTypes}
                                        onChange={handleTypeChange}
                                        customStyle={{ width: '400px', marginLeft: '16px' }}
                                    />
                                }
                            />
                        )}
                        <Divider />
                        <CommonBlock
                            required
                            label={t('editMyProfile.surname')}
                            select={
                                <Form.Item
                                    name="last_name"
                                    className={style.inputAlert}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('login.pleaseSurname'),
                                        },
                                    ]}
                                >
                                    <Input
                                        onBlur={() => refreshCompanyName()}
                                        minLength={1}
                                        maxLength={30}
                                        className={style.input}
                                    />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            required
                            margin
                            label={t('editMyProfile.userName')}
                            select={
                                <Form.Item
                                    name="first_name"
                                    className={style.inputAlert}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('login.pleaseName'),
                                        },
                                    ]}
                                >
                                    <Input
                                        onBlur={() => refreshCompanyName()}
                                        minLength={1}
                                        maxLength={30}
                                        className={style.input}
                                    />
                                </Form.Item>
                            }
                        />
                        <CommonBlock
                            required={!noMiddleName}
                            margin
                            label={t('editMyProfile.lastName')}
                            select={
                                <Form.Item
                                    name="middle_name"
                                    className={style.inputAlert}
                                    noStyle={noMiddleName}
                                    rules={[
                                        {
                                            required: !noMiddleName ? true : false,
                                            message: t('login.pleaseLastName'),
                                        },
                                    ]}
                                >
                                    <Input
                                        onBlur={() => refreshCompanyName()}
                                        minLength={1}
                                        maxLength={30}
                                        className={style.input}
                                        disabled={noMiddleName}
                                    />
                                </Form.Item>
                            }
                        >
                            <Checkbox
                                checked={noMiddleName}
                                className={style.checkbox}
                                onClick={onChange}
                            >
                                {t('editMyProfile.noLastName')}
                            </Checkbox>
                        </CommonBlock>
                        {(isEditingAdmin || (isAddUser && isAddingAdminOrOperator)) && (
                            <CommonBlock
                                margin
                                required
                                label={t('editMyProfile.phone')}
                                select={
                                    <Form.Item
                                        name="phone"
                                        className={style.inputAlert}
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp(/^(?=.*[0-9])[- +()0-9]+$/),
                                                message: t('login.pleasePhone'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            className={style.input}
                                            minLength={10}
                                            maxLength={50}
                                        />
                                    </Form.Item>
                                }
                            />
                        )}
                        <Divider />
                        {userRoleName === 'Юридическое лицо' && (
                            <>
                                <CommonBlock
                                    required
                                    label={t('editMyProfile.fullName')}
                                    select={
                                        <Form.Item
                                            className={style.dropdownWrapper}
                                            name="company_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseCompany'),
                                                },
                                            ]}
                                        >
                                            <ValueSelector
                                                value={companyName}
                                                setValue={(value) => {
                                                    setCompanyName(value)
                                                    form.setFieldsValue({
                                                        company_name: value,
                                                    })
                                                    form.validateFields(['company_name'])
                                                }}
                                                onSearch={handleSearch}
                                                initValue={companyName ?? data.company_name}
                                                options={companies}
                                                validate
                                                placeholder={'Начните вводить название организации'}
                                            />
                                        </Form.Item>
                                    }
                                />
                                {!isWithoutPA && (
                                    <>
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.shortName')}
                                            select={
                                                <Form.Item
                                                    className={style.dropdownWrapper}
                                                    name="company_name_short"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseCompanyShort'),
                                                        },
                                                    ]}
                                                >
                                                    <ValueSelector
                                                        value={companyNameShort}
                                                        setValue={(value) => {
                                                            setCompanyNameShort(value)
                                                            form.setFieldsValue({
                                                                company_name_short: value,
                                                            })
                                                            form.validateFields([
                                                                'company_name_short',
                                                            ])
                                                        }}
                                                        onSearch={handleSearchShort}
                                                        initValue={
                                                            companyNameShort ??
                                                            data.company_name_short
                                                        }
                                                        validate
                                                        options={companiesShort}
                                                        placeholder={
                                                            'Начните вводить название организации'
                                                        }
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <ThreePartsBlock
                                            labels={[
                                                t('editMyProfile.inn'),
                                                t('editMyProfile.ogrn'),
                                                t('editMyProfile.kpp'),
                                            ]}
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.egrulCompName')}
                                            select={
                                                <Form.Item
                                                    name="egrul_government_name"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseEGRULName'),
                                                        },
                                                    ]}
                                                >
                                                    <Input className={style.inputLong} />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.egrulDate')}
                                            select={
                                                <Form.Item
                                                    name="egrul_registration_date"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseEGRULDate'),
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        disabledDate={(current) =>
                                                            current.isAfter(moment())
                                                        }
                                                        defaultValue={egrulDate}
                                                        onChange={handleEgrulDateChange}
                                                        className={style.datePicker}
                                                        format={'DD.MM.YYYY'}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        {!isEditingAdmin && !isEditBySuperAdmin && (
                                            <CommonBlock
                                                isCodesBlock
                                                label="Код ГТП потребления"
                                                select={
                                                    <CodesConsumingBlock
                                                        codesArray={codesArray}
                                                        updateCodesArray={updateCodesArray}
                                                        removeFromCodesArray={removeFromCodesArray}
                                                        changeCode={changeCode}
                                                        setCodesError={setCodesError}
                                                    />
                                                }
                                            />
                                        )}
                                    </>
                                )}
                                {isWithoutPA && (
                                    <>
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.organizationINN')}
                                            select={
                                                <Form.Item
                                                    name="company_inn"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            pattern: new RegExp(/^\d+$/),
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className={style.input}
                                                        minLength={10}
                                                        maxLength={10}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label="ОГРН"
                                            select={
                                                <Form.Item
                                                    name="ogrn"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            pattern: new RegExp(/^\d+$/),
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input className={style.input} />
                                                </Form.Item>
                                            }
                                        />{' '}
                                    </>
                                )}
                                {!isEditingAdmin && (
                                    <CommonBlock
                                        margin
                                        label={t('editMyProfile.status')}
                                        select={
                                            <Form.Item name="company_position">
                                                <Input className={style.input} />
                                            </Form.Item>
                                        }
                                    />
                                )}
                                <CommonBlock
                                    margin
                                    label={t('editMyProfile.phoneFax')}
                                    select={
                                        <Form.Item
                                            name={isEditingAdmin ? 'company_phone' : 'phone'}
                                            rules={[
                                                {
                                                    pattern: new RegExp(/^(?=.*[0-9])[- +()0-9]+$/),
                                                    message: t('login.pleasePhone'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={style.input}
                                                minLength={10}
                                                maxLength={50}
                                            />
                                        </Form.Item>
                                    }
                                />
                                {!isWithoutPA && (
                                    <>
                                        <CommonBlock
                                            margin
                                            label={t('editMyProfile.website')}
                                            select={
                                                <Form.Item name="web">
                                                    <Input className={style.input} />
                                                </Form.Item>
                                            }
                                        />
                                        <Divider />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.urAddress')}
                                            select={
                                                <Form.Item
                                                    name="legal_address"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseLegalAddress'),
                                                        },
                                                    ]}
                                                >
                                                    <Input className={style.inputLong} />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.mailAddress')}
                                            select={
                                                !isAddressesMatch && (
                                                    <Form.Item
                                                        name="post_address"
                                                        className={style.inputAlert}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    t('login.pleasePostAddress'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input className={style.inputLong} />
                                                    </Form.Item>
                                                )
                                            }
                                        >
                                            <Checkbox
                                                checked={isAddressesMatch}
                                                className={style.checkbox}
                                                onClick={addressesMatch}
                                            >
                                                {t('editMyProfile.matchUr')}
                                            </Checkbox>
                                        </CommonBlock>
                                        {isEditingAdmin && <Divider />}
                                    </>
                                )}
                            </>
                        )}
                        {userRoleName === 'Физическое лицо' && !isEditingAdmin && (
                            <>
                                {!isWithoutPA && (
                                    <>
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.birthdate')}
                                            select={
                                                <Form.Item
                                                    name="birthday"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseBirthday'),
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        disabledDate={(current) =>
                                                            current.isAfter(moment())
                                                        }
                                                        defaultValue={birthdayDate}
                                                        onChange={handleBirthdayDateChange}
                                                        className={style.datePicker}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.citizen')}
                                            select={
                                                <Form.Item name="citizenship">
                                                    <Input
                                                        className={style.input}
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.identityDocument')}
                                            select={
                                                <DropdownSelector
                                                    options={documentTypes}
                                                    onChange={handleDocumentTypeChange}
                                                    customStyle={{
                                                        width: '400px',
                                                        marginLeft: '16px',
                                                    }}
                                                />
                                            }
                                        />
                                        {documentTypeIdPicker(documentType) === 1 ? (
                                            <FivePartsBlock
                                                passDate={passDate}
                                                dateChange={handlePassDateChange}
                                            />
                                        ) : (
                                            <CommonBlock
                                                required
                                                margin
                                                label={t('editMyProfile.comment')}
                                                select={
                                                    <Form.Item
                                                        name="document_comment"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea className={style.textArea} />
                                                    </Form.Item>
                                                }
                                            />
                                        )}
                                        <Divider />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.registrationAddress')}
                                            select={
                                                <Form.Item
                                                    name="registration_address"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input className={style.inputLong} />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.mailAddress')}
                                            select={
                                                !isAddressesMatch && (
                                                    <Form.Item
                                                        name="post_address"
                                                        className={style.inputAlert}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    t('login.pleasePostAddress'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input className={style.inputLong} />
                                                    </Form.Item>
                                                )
                                            }
                                        >
                                            <Checkbox
                                                checked={isAddressesMatch}
                                                className={style.checkbox}
                                                onClick={addressesMatch}
                                            >
                                                {t('editMyProfile.mathReg')}
                                            </Checkbox>
                                        </CommonBlock>
                                        <CommonBlock
                                            margin
                                            required
                                            label={t('editMyProfile.inn')}
                                            select={
                                                <Form.Item
                                                    name={'personal_inn'}
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            pattern: new RegExp(/^\d+$/),
                                                            message: 'Укажите ИНН (12 цифр)',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className={style.input}
                                                        minLength={12}
                                                        maxLength={12}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            margin
                                            required
                                            label={t('editMyProfile.snils')}
                                            select={
                                                <Form.Item
                                                    name={'snils'}
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            pattern: new RegExp(/^\d+$/),
                                                            message: 'Укажите СНИЛС (11 цифр)',
                                                        },
                                                        {
                                                            min: 11,
                                                            message: 'Укажите СНИЛС (11 цифр)',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        minLength={11}
                                                        maxLength={11}
                                                        className={style.input}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            margin
                                            label={t('editMyProfile.phoneFax')}
                                            select={
                                                <Form.Item
                                                    name="phone"
                                                    rules={[
                                                        {
                                                            pattern: new RegExp(
                                                                /^(?=.*[0-9])[- +()0-9]+$/,
                                                            ),
                                                            message: t('login.pleasePhone'),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className={style.input}
                                                        minLength={10}
                                                        maxLength={50}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <Divider />
                                    </>
                                )}
                                <CommonBlock
                                    margin
                                    required={false}
                                    label={t('editMyProfile.organization')}
                                    select={
                                        <Form.Item
                                            className={style.dropdownWrapper}
                                            rules={[
                                                {
                                                    message: t('login.pleaseCompany'),
                                                },
                                            ]}
                                        >
                                            <ValueSelector
                                                value={companyName}
                                                setValue={setCompanyName}
                                                onSearch={handleSearch}
                                                initValue={data.company_name}
                                                options={companies}
                                                placeholder={'Начните вводить название организации'}
                                            />
                                        </Form.Item>
                                    }
                                />
                                <CommonBlock
                                    margin
                                    required={false}
                                    label={t('editMyProfile.organizationINN')}
                                    select={
                                        <Form.Item
                                            name="company_inn"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    pattern: new RegExp(/^\d+$/),
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={style.input}
                                                minLength={10}
                                                maxLength={10}
                                            />
                                        </Form.Item>
                                    }
                                />
                                <CommonBlock
                                    margin
                                    required={false}
                                    label="ОГРН"
                                    select={
                                        <Form.Item
                                            name="ogrn"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    pattern: new RegExp(/^\d+$/),
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input className={style.input} />
                                        </Form.Item>
                                    }
                                />
                                {!isWithoutPA && (
                                    <CommonBlock
                                        isCodesBlock
                                        label="Код ГТП потребления"
                                        select={
                                            <CodesConsumingBlock
                                                codesArray={codesArray}
                                                updateCodesArray={updateCodesArray}
                                                removeFromCodesArray={removeFromCodesArray}
                                                changeCode={changeCode}
                                                setCodesError={setCodesError}
                                            />
                                        }
                                    />
                                )}
                                <CommonBlock
                                    margin
                                    label={t('editMyProfile.position')}
                                    select={
                                        <Form.Item name="company_position">
                                            <Input className={style.input} />
                                        </Form.Item>
                                    }
                                />
                                {isWithoutPA && (
                                    <CommonBlock
                                        margin
                                        label={t('editMyProfile.phoneFax')}
                                        select={
                                            <Form.Item
                                                name="phone"
                                                rules={[
                                                    {
                                                        pattern: new RegExp(
                                                            /^(?=.*[0-9])[- +()0-9]+$/,
                                                        ),
                                                        message: t('login.pleasePhone'),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className={style.input}
                                                    minLength={10}
                                                    maxLength={50}
                                                />
                                            </Form.Item>
                                        }
                                    />
                                )}
                            </>
                        )}
                        {isUserIP && (
                            <>
                                {!isWithoutPA && (
                                    <>
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.birthdate')}
                                            select={
                                                <Form.Item
                                                    name="birthday"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseBirthday'),
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        disabledDate={(current) =>
                                                            current.isAfter(moment())
                                                        }
                                                        defaultValue={birthdayDate}
                                                        onChange={handleBirthdayDateChange}
                                                        className={style.datePicker}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.citizen')}
                                            select={
                                                <Form.Item
                                                    name="citizenship"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className={style.input}
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.registrationAddress')}
                                            select={
                                                <Form.Item
                                                    name="registration_address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '',
                                                        },
                                                    ]}
                                                >
                                                    <Input className={style.inputLong} />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.mailAddress')}
                                            select={
                                                !isAddressesMatch && (
                                                    <Form.Item
                                                        name="post_address"
                                                        className={style.inputAlert}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    t('login.pleasePostAddress'),
                                                            },
                                                        ]}
                                                    >
                                                        <Input className={style.inputLong} />
                                                    </Form.Item>
                                                )
                                            }
                                        >
                                            <Checkbox
                                                checked={isAddressesMatch}
                                                className={style.checkbox}
                                                onClick={addressesMatch}
                                            >
                                                {t('editMyProfile.mathReg')}
                                            </Checkbox>
                                        </CommonBlock>
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.identityDocument')}
                                            select={
                                                <DropdownSelector
                                                    options={documentTypes}
                                                    onChange={handleDocumentTypeChange}
                                                    customStyle={{
                                                        width: '400px',
                                                        marginLeft: '16px',
                                                    }}
                                                />
                                            }
                                        />
                                        {documentTypeIdPicker(documentType) === 1 ? (
                                            <FivePartsBlock
                                                passDate={passDate}
                                                dateChange={handlePassDateChange}
                                            />
                                        ) : (
                                            <CommonBlock
                                                required
                                                margin
                                                label={t('editMyProfile.comment')}
                                                select={
                                                    <Form.Item
                                                        name="document_comment"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '',
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea className={style.textArea} />
                                                    </Form.Item>
                                                }
                                            />
                                        )}
                                        <Divider />
                                    </>
                                )}
                                <CommonBlock
                                    margin
                                    required
                                    label={t('editMyProfile.organization')}
                                    select={
                                        <Form.Item
                                            className={style.dropdownWrapper}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseCompany'),
                                                },
                                            ]}
                                        >
                                            <ValueSelector
                                                disabled
                                                value={entrepreneurCompanyName}
                                                setValue={setCompanyName}
                                                onSearch={handleSearch}
                                                validate
                                                initValue={entrepreneurCompanyName}
                                                options={companies}
                                                placeholder={'Начните вводить название организации'}
                                            />
                                        </Form.Item>
                                    }
                                />
                                {isWithoutPA && (
                                    <CommonBlock
                                        margin
                                        required
                                        label={t('editMyProfile.inn')}
                                        select={
                                            <Form.Item
                                                name={'personal_inn'}
                                                className={style.inputAlert}
                                                rules={[
                                                    {
                                                        required: true,
                                                        pattern: new RegExp(/^\d+$/),
                                                        message: t('login.pleaseINN'),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    className={style.input}
                                                    minLength={12}
                                                    maxLength={12}
                                                />
                                            </Form.Item>
                                        }
                                    />
                                )}
                                <CommonBlock
                                    margin
                                    required
                                    label={t('editMyProfile.ogrnip')}
                                    select={
                                        <Form.Item
                                            name="ogrn"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    pattern: new RegExp(/^\d+$/),
                                                    message: t('login.pleaseOGRN'),
                                                },
                                            ]}
                                        >
                                            <Input className={style.input} />
                                        </Form.Item>
                                    }
                                />
                                {!isWithoutPA && (
                                    <>
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.egripCompName')}
                                            select={
                                                <Form.Item
                                                    name="egrul_government_name"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseEGRIPName'),
                                                        },
                                                    ]}
                                                >
                                                    <Input className={style.inputLong} />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            required
                                            margin
                                            label={t('editMyProfile.egripDate')}
                                            select={
                                                <Form.Item
                                                    name="egrul_registration_date"
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('login.pleaseEGRULDate'),
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        disabledDate={(current) =>
                                                            current.isAfter(moment())
                                                        }
                                                        defaultValue={egrulDate}
                                                        onChange={handleEgrulDateChange}
                                                        className={style.datePicker}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            isCodesBlock
                                            label="Код ГТП потребления"
                                            select={
                                                <CodesConsumingBlock
                                                    codesArray={codesArray}
                                                    updateCodesArray={updateCodesArray}
                                                    removeFromCodesArray={removeFromCodesArray}
                                                    changeCode={changeCode}
                                                    setCodesError={setCodesError}
                                                />
                                            }
                                        />
                                    </>
                                )}
                                <CommonBlock
                                    margin
                                    label={t('editMyProfile.position')}
                                    select={
                                        <Form.Item name="company_position">
                                            <Input className={style.input} />
                                        </Form.Item>
                                    }
                                />
                                {!isWithoutPA && (
                                    <>
                                        <Divider />
                                        <CommonBlock
                                            margin
                                            required
                                            label={t('editMyProfile.inn')}
                                            select={
                                                <Form.Item
                                                    name={'personal_inn'}
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            pattern: new RegExp(/^\d+$/),
                                                            message: t('login.pleaseINN'),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className={style.input}
                                                        minLength={12}
                                                        maxLength={12}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                        <CommonBlock
                                            margin
                                            required
                                            label={t('editMyProfile.snils')}
                                            select={
                                                <Form.Item
                                                    name={'snils'}
                                                    className={style.inputAlert}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            pattern: new RegExp(/^\d+$/),
                                                            message: 'Укажите СНИЛС (11 цифр)',
                                                        },
                                                        {
                                                            min: 11,
                                                            message: 'Укажите СНИЛС (11 цифр)',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        minLength={11}
                                                        maxLength={11}
                                                        className={style.input}
                                                    />
                                                </Form.Item>
                                            }
                                        />
                                    </>
                                )}
                                <CommonBlock
                                    margin
                                    label={t('editMyProfile.phoneFax')}
                                    select={
                                        <Form.Item
                                            name="phone"
                                            rules={[
                                                {
                                                    pattern: new RegExp(/^(?=.*[0-9])[- +()0-9]+$/),
                                                    message: t('login.pleasePhone'),
                                                },
                                            ]}
                                        >
                                            <Input
                                                className={style.input}
                                                minLength={10}
                                                maxLength={50}
                                            />
                                        </Form.Item>
                                    }
                                />
                                {!isWithoutPA && (
                                    <CommonBlock
                                        margin
                                        label={t('editMyProfile.website')}
                                        select={
                                            <Form.Item name="web">
                                                <Input className={style.input} />
                                            </Form.Item>
                                        }
                                    />
                                )}
                            </>
                        )}
                        {(isEditingAdmin ||
                            (isAddUser &&
                                (userRole === 'Администратор' || userRole === 'Оператор'))) && (
                            <>
                                {isAddUser && <Divider />}
                                <div className={style.headerPrimary}>
                                    Документ, подтверждающий полномочия административного
                                    пользователя
                                </div>
                                <CommonBlock
                                    required
                                    margin
                                    label={t('editMyProfile.documentName')}
                                    select={
                                        <Form.Item
                                            name="document_custom_name"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseDocumentName'),
                                                },
                                            ]}
                                        >
                                            <Input className={style.inputLong} />
                                        </Form.Item>
                                    }
                                />
                                <CommonBlock
                                    required
                                    margin
                                    label={t('editMyProfile.documentNumber')}
                                    select={
                                        <Form.Item
                                            name="document_custom_number"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseDocumentNumber'),
                                                },
                                            ]}
                                        >
                                            <Input className={style.inputLong} />
                                        </Form.Item>
                                    }
                                />
                                <CommonBlock
                                    required
                                    margin
                                    label={t('editMyProfile.documentDate')}
                                    select={
                                        <Form.Item
                                            name="document_custom_betrayed_date"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseDocumentDate'),
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                disabledDate={(current) =>
                                                    current.isAfter(moment())
                                                }
                                                defaultValue={documentCustomBetrayedDate}
                                                onChange={handleDocumentCustomBetrayedDateChange}
                                                className={style.datePicker}
                                            />
                                        </Form.Item>
                                    }
                                />

                                <CommonBlock
                                    required
                                    margin
                                    label={t('editMyProfile.documentExpiration')}
                                    select={
                                        <Form.Item
                                            name="document_custom_end_date"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseDocumentExpiration'),
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                disabledDate={(date) =>
                                                    date.isBefore(moment().endOf('day'))
                                                }
                                                defaultValue={documentCustomEndDate}
                                                onChange={handleDocumentCustomEndDateChange}
                                                className={style.datePicker}
                                            />
                                        </Form.Item>
                                    }
                                />
                                <CommonBlock
                                    required
                                    margin
                                    label={t('editMyProfile.documentBetrayedName')}
                                    select={
                                        <Form.Item
                                            name="document_custom_betrayed_name"
                                            className={style.inputAlert}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('login.pleaseDocumentBetrayedName'),
                                                },
                                            ]}
                                        >
                                            <Input className={style.inputLong} />
                                        </Form.Item>
                                    }
                                />
                            </>
                        )}
                        {!isWithoutPA && !isEditingAdmin && !isAddUser && (
                            <>
                                <Divider />
                                <DocumentsBlock
                                    fileList={fileList}
                                    updateFileList={updateFileList}
                                    deleteFromFileList={deleteFromFileList}
                                    noCheckExtantion
                                />
                                <Divider />
                                <div className={style.eSignatureWrapper}>
                                    {currentCertificate && currentCertificate !== 'empty' && (
                                        <div className={style.stampBlock}>
                                            <Stamp />
                                            <div className={style.stampOwner}>
                                                {currentCertificate.name
                                                    ? currentCertificate.name
                                                    : data.certificate_data !== null
                                                    ? `${data.certificate_data?.sur_name || ''} ${
                                                          data.certificate_data.common_name
                                                      }`
                                                    : ''}
                                            </div>
                                        </div>
                                    )}
                                    {currentCertificate && currentCertificate !== 'empty' ? (
                                        <IconButton
                                            onClick={onAddCertificate}
                                            icon={<ArrowSwap color={'currentColor'} />}
                                            customIconStyle={{
                                                marginRight: '10px',
                                                marginTop: '5px',
                                            }}
                                        >
                                            {t('editMyProfile.changeESignature')}
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton
                                                onClick={onAddCertificate}
                                                icon={<AddCircle />}
                                                customIconStyle={{
                                                    marginRight: '10px',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {t('editMyProfile.addSignature')}
                                            </IconButton>
                                            <div className={style.info}>
                                                {t('editMyProfile.pleaseAddSignature')}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {!isEdit && !isAddUser && (
                            <>
                                <Divider />
                                <div className={style.checkboxAgreement}>
                                    <Form.Item
                                        name="agreeProcessing"
                                        valuePropName="checked"
                                        rules={[
                                            validateCheckBox(
                                                'Необходимо ваше согласие на обработку персональных данных',
                                            ),
                                        ]}
                                    >
                                        <Checkbox>
                                            Согласен с{' '}
                                            <Link to="/documents" target="_blank">
                                                Обработкой персональных данных
                                            </Link>
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="agreeTerms"
                                        valuePropName="checked"
                                        rules={[
                                            validateCheckBox(
                                                'Необходимо ваше согласие с условиями договора',
                                            ),
                                        ]}
                                    >
                                        <Checkbox className={style.checkboxBottom}>
                                            Согласен с{' '}
                                            <Link to="/documents" target="_blank">
                                                Условиями договора
                                            </Link>
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </>
                        )}
                        {isAddUser && (
                            <CommonBlock
                                margin
                                label={''}
                                select={
                                    <Alert
                                        message={t('editMyProfile.addUserEmailVerification')}
                                        type="info"
                                        showIcon
                                        className={style.alert}
                                    />
                                }
                            />
                        )}
                        <Divider />
                        {currentCertificate === 'empty' && !isEditBySuperAdmin && (
                            <Alert
                                className={style.responseError}
                                message={'Пожалуйста, добавьте ЭП'}
                                type="error"
                                showIcon
                            />
                        )}
                        {responseError && (
                            <Alert
                                className={style.responseError}
                                message={responseError}
                                type="error"
                                showIcon
                            />
                        )}
                        <ButtonsBlock
                            margin
                            confirmTitle={
                                isRegistration ? 'Подписать и зарегистрироваться' : 'Сохранить'
                            }
                            cancelAction={getBack}
                        />
                    </div>
                </Form>
            </>
            {certificatesList && (
                <CertificatesModal
                    type={'addCertificate'}
                    setCertificate={setCertificate}
                    allCertificates={certificatesList}
                    clearCertificates={clearCertificates}
                />
            )}

            {isAddingConfirmationModalVisible && (
                <SendNotificationUser
                    onCancel={() => setIsAddingConfirmationModalVisible(false)}
                    onOk={onAddUser}
                    isModalVisible={true}
                    userRole={userRole}
                    login={form.getFieldValue('login')}
                    fio={form.getFieldValue('first_name')}
                    email={form.getFieldValue('email')}
                />
            )}
            <TermsModal
                isRegistration={isRegistration}
                userRole={data.user_role}
                userType={data.user_type}
                isModalVisible={isTermsModalVisible}
                setIsModalVisible={setIsTermsModalVisible}
            />
            <ConfirmRoleChangeModal
                confirmAction={confirmRoleChange}
                isModalVisible={isRolesModalVisible}
            />
        </div>
    )
}

export default ContinueRegistration
