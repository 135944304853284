import { createAction, createSlice } from '@reduxjs/toolkit'
import { initialState } from './resources'

const generatorsList = createSlice({
    name: 'generatorsList',
    initialState,
    reducers: {
        getGeneratorsList: (state) => {
            return state
        },
        updateGeneratorsList: (state, action) => {
            return { ...state, ...action.payload }
        },
        dropGeneratorsList: () => {
            return { ...initialState }
        },
    },
})

interface IGeneratorsPageRequest {
    limit?: number
    page?: number
}

export const getGenerators = createAction<IGeneratorsPageRequest>('generatorsList/getGenerators')

export const { updateGeneratorsList, getGeneratorsList, dropGeneratorsList } =
    generatorsList.actions

export default generatorsList.reducer
