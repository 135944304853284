import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Input, Radio, Alert, message } from 'antd'
import type { RadioChangeEvent } from 'antd'
import { SuccessMessage, ButtonsBlock } from '../../components'
import { CommonBlock } from '../../components'
import {
    DropdownSelector,
    ImportButton,
    TagSelector,
    DocumentsBlockUI,
    IconButton,
} from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

const userTypes = [
    { value: 'ООО Ромашка', id: '55677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'ООО Фиалка', id: '7c23e409-c4c6-46dc-ad08-156c3456610d' },
]

interface IGeneratorLayout {
    type: 'generator' | 'create' | 'profile'
}

const GeneratorLayout: FC<IGeneratorLayout> = ({ type }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const isCreate = type === 'create' ? true : undefined

    const [value, setValue] = useState(1)

    const handleConfirm = () => {
        navigate(-1)
        message.success({
            content: (
                <SuccessMessage
                    header={t('detailedCard.success')}
                    message={`ЗГО СЭС “Абаканская” ${t('detailedCard.created')}`}
                />
            ),
            icon: <></>,
        })
    }

    const getBack = () => {
        navigate(-1)
    }

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value)
    }

    return (
        <>
            <CommonBlock
                label={t('edit.marketEE')}
                select={
                    <Radio.Group onChange={onChange} value={value} className={style.radio}>
                        <Radio value={1}>{t('edit.retail')}</Radio>
                        <Radio value={2}>{t('edit.wholesale')}</Radio>
                    </Radio.Group>
                }
            />
            <Divider />
            <CommonBlock
                label={t('edit.name')}
                select={
                    <Input
                        placeholder={isCreate && t('edit.namePlaceholder')}
                        className={style.inputLong}
                    />
                }
            />
            <CommonBlock
                margin
                label={t('edit.codeGO')}
                select={
                    <Input
                        placeholder={isCreate && t('edit.codeGOPlaceholder')}
                        className={style.input}
                    />
                }
            />
            <Divider />
            <CommonBlock
                label={t('edit.tco')}
                select={
                    <DropdownSelector
                        placeholder={isCreate ? 'Выберите из списка' : 'Введите название ГО'}
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <ImportButton
                noMargin
                customStyle={{ marginTop: '8px', marginLeft: '216px' }}
                value={t('edit.fillManually')}
            />
            <CommonBlock
                label={t('edit.gp')}
                margin
                select={
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <ImportButton
                noMargin
                customStyle={{ marginTop: '8px', marginLeft: '216px' }}
                value={t('edit.fillManually')}
            />
            <Divider />
            <CommonBlock
                label={t('edit.placeGO')}
                select={
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <div className={style.blockGroup}>
                <div>
                    <div>{t('edit.subject')}</div>
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '492px' }}
                    />
                </div>
                <div>
                    <div>{t('edit.index')}</div>
                    <Input className={style.inputTiny} placeholder="_ _ _ _ _ _" />
                </div>
            </div>
            <div className={style.blockGroup}>
                <div>
                    <div>{t('edit.locality')}</div>
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '296px' }}
                    />
                </div>
                <div>
                    <div>{t('edit.city')}</div>
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '296px' }}
                    />
                </div>
            </div>
            <div className={style.blockGroup}>
                <div>
                    <div>{t('edit.street')}</div>
                    <Input
                        placeholder={isCreate && 'Введите название улицы'}
                        className={style.inputShort}
                    />
                </div>
                <div>
                    <div>{t('edit.house')}</div>
                    <Input className={style.inputTiny} />
                </div>
                <div>
                    <div>{t('edit.block')}</div>
                    <Input className={style.inputTiny} />
                </div>
                <div>
                    <div>{t('edit.building')}</div>
                    <Input className={style.inputTiny} />
                </div>
            </div>
            <div className={style.blockGroup}>
                <div>
                    <div>{t('edit.lat')}</div>
                    <Input className={style.inputMiddle} placeholder="Укажите широту" />
                </div>
                <div>
                    <div>{t('edit.lng')}</div>
                    <Input className={style.inputMiddle} placeholder="Укажите долготу" />
                </div>
            </div>
            <div className={style.blockGroup}>
                <div>
                    <div>{t('edit.addressMore')}</div>
                    <TextArea className={style.textArea} rows={4} />
                </div>
            </div>
            <Divider />
            <CommonBlock
                label={t('edit.categoryGO')}
                select={
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <CommonBlock
                label={t('edit.fuelType')}
                select={<TagSelector customStyle={{ marginLeft: '16px', width: '400px' }} />}
            />
            <CommonBlock
                margin
                label={t('edit.ustPower')}
                select={<Input className={style.input} />}
            />
            <CommonBlock
                margin
                label={t('edit.manufMode')}
                select={
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <CommonBlock
                margin
                label={t('edit.ownerBase')}
                select={
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <CommonBlock
                margin
                label={t('edit.ownerGO')}
                select={<Input className={style.input} />}
            />
            <CommonBlock
                margin
                label={t('edit.support')}
                select={
                    <DropdownSelector
                        placeholder={isCreate && 'Выберите из списка'}
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <Divider />
            <DocumentsBlockUI type={type} />
            {type === 'create' && (
                <>
                    <Divider />
                    <Alert
                        message={
                            <div>
                                <div className={style.alertHeader}>
                                    За добавление ГО будет взыматься оплата
                                </div>
                                <div className={style.alertText}>
                                    Баланс платежного счета: 230 000 ₽
                                </div>
                            </div>
                        }
                        className={style.alert}
                        type="warning"
                        showIcon
                    />
                    <IconButton onClick={handleConfirm} customStyle={{ marginTop: '32px' }}>
                        Подать данные
                    </IconButton>
                </>
            )}
            <ButtonsBlock margin confirmAction={handleConfirm} cancelAction={getBack} />
        </>
    )
}

export default GeneratorLayout
