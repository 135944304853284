import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalType } from '../../types'
import { ImportButton, IconButton, DropdownSelector, OperationsMapper } from '../../../ui'
import { AddCircle, Edit, Trash, SimpleStar, Stamp, Blocks } from '../../../../assets/icons'
import { AccountChart, AccountModal } from './components'
import { accounts, dataSet, historyConfig } from './configurations'
import style from './style.m.less'
import { Alert, Checkbox, Divider, Tooltip } from 'antd'
import OpenFirstAccountModal from '../OpenFirstAccountModal/OpenFirstAccountModal'
import OpenAccountModal from '../OpenAccountModal/OpenAccountModal'
import { Link } from 'react-router-dom'

const PersonalAccount: FC = () => {
    const { t } = useTranslation()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalType, setModalType] = useState<ModalType>('rename')

    const openModal = (type: ModalType) => {
        setModalType(type)
        setIsModalVisible(true)
    }

    const [isOpenFirstAccountModalVisible, setIsOpenFirstAccountModalVisible] = useState(false)

    const openOpenFirstAccountModal = () => {
        setIsOpenFirstAccountModalVisible(true)
    }

    const [isOpenAccountModalVisible, setIsOpenAccountModalVisible] = useState(false)

    const openOpenAccountModal = () => {
        setIsOpenAccountModalVisible(true)
    }

    const status = 'withAccount'

    return (
        <div className={style.personalAccount}>
            {status === 'withoutAccount' && (
                <>
                    <div className={style.withoutAccountBlock}>
                        <Alert
                            message={
                                <div>
                                    <div className={style.alertHeader}>
                                        {t('myProfile.personalAccountAlertHeader')}
                                    </div>
                                    <div className={style.alertText}>
                                        {t('myProfile.personalAccountAlert')}
                                    </div>
                                </div>
                            }
                            className={style.alert}
                            type="info"
                            showIcon
                        />
                        <IconButton
                            customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                            icon={<AddCircle />}
                            onClick={openOpenFirstAccountModal}
                        >
                            {t('myProfile.openNewAccount')}
                        </IconButton>
                    </div>
                </>
            )}
            {status === 'withNewAccount' && (
                <>
                    <div className={style.accountNumberBlock}>
                        <div className={style.accountNumberRow}>
                            <div>
                                <div>{t('myProfile.accountNumber')}</div>
                                <DropdownSelector
                                    options={[
                                        {
                                            value: 'Счет №055579',
                                            id: '11677a62-97a0-47d2-8b8f-d57abe52f591',
                                        },
                                    ]}
                                    customStyle={{
                                        width: '300px',
                                        marginTop: '2px',
                                    }}
                                />
                                <div className={style.mainCount}>
                                    <Tooltip
                                        placement="bottomLeft"
                                        color="#434343"
                                        overlayInnerStyle={{ color: 'white' }}
                                        title={t('myProfile.mainCountTooltip')}
                                        // visible
                                    >
                                        <span className={style.star}>
                                            <SimpleStar />
                                        </span>
                                        <span className={style.mainCountText}>
                                            {t('myProfile.mainCount')}
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <IconButton
                                customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                                icon={<AddCircle />}
                                onClick={openOpenAccountModal}
                            >
                                {t('myProfile.openNewAccount')}
                            </IconButton>
                        </div>
                        <div className={style.freeGenerationAttributes}>0 кВт*ч</div>
                        <div className={style.buttonsRow}>
                            <ImportButton
                                value={t('myProfile.renameCount')}
                                icon={<Edit size={16} />}
                                customIconStyle={{ marginTop: '3px' }}
                                onClick={() => openModal('rename')}
                            />
                            <ImportButton
                                value={t('myProfile.closeCount')}
                                icon={<Trash />}
                                customStyle={{ marginLeft: '34px' }}
                                customIconStyle={{ marginTop: '3px' }}
                                onClick={() => openModal('delete')}
                            />
                        </div>
                    </div>
                    <div className={style.chartAndHistoryRow}>
                        <div className={style.chartBlock}>
                            <div className={style.header}>{t('myProfile.myGreenTools')}</div>
                            <div className={style.chartRow}>
                                <div className={style.graph}>
                                    <AccountChart />
                                </div>
                                <div className={style.legendMain}>
                                    {dataSet.map((item) => {
                                        const { color, name, value, id } = item
                                        return (
                                            <div id={id}>
                                                <div className={style.legendRow}>
                                                    <div
                                                        style={{ background: color }}
                                                        className={style.legendColor}
                                                    ></div>
                                                    <div className={style.legendName}>{name}</div>
                                                </div>
                                                <div className={style.legendValue}>{value}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={style.historyBlock}>
                            <div className={style.header}>{t('myProfile.operationHistory')}</div>
                            <Alert
                                className={style.historyAlert}
                                message={
                                    <div className={style.modalAlertText}>
                                        <div>{t('myProfile.historyAlert')}</div>
                                    </div>
                                }
                                type="info"
                                showIcon
                            />
                        </div>
                    </div>
                </>
            )}
            {status === 'withAccount' && (
                <>
                    <div className={style.accountNumberBlock}>
                        <div className={style.accountNumberRow}>
                            <div>
                                <div>{t('myProfile.accountNumber')}</div>
                                <DropdownSelector
                                    options={accounts}
                                    customStyle={{ width: '300px', marginTop: '2px' }}
                                />
                            </div>
                            <IconButton
                                customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                                icon={<AddCircle />}
                                onClick={openOpenAccountModal}
                            >
                                {t('myProfile.openNewAccount')}
                            </IconButton>
                        </div>
                        <div className={style.infoWrapper}>
                            <div>
                                <div className={style.infoTitle}>Атрибутов генерации на счету</div>
                                <div className={style.info}>824 458 000 кВт*ч</div>
                            </div>
                            <Divider className={style.divider} type="vertical" />
                            <div>
                                <div className={style.infoTitle}>Свободные атрибуты генерации</div>
                                <div className={style.info}>15 000 000 кВт*ч</div>
                            </div>
                        </div>
                        <div className={style.buttonsRow}>
                            <IconButton
                                icon={<Edit size={16} />}
                                onClick={() => openModal('rename')}
                            >
                                {t('myProfile.renameCount')}
                            </IconButton>
                            <IconButton icon={<Trash />} onClick={() => openModal('closeWithZI')}>
                                {t('myProfile.closeCount')}
                            </IconButton>
                            <IconButton icon={<Stamp />}>
                                {t('myProfile.downloadESignature')}
                            </IconButton>
                            <IconButton icon={<Blocks />}>Перейти к моим АГ</IconButton>
                            <Checkbox className={style.checkbox}>
                                {t('myProfile.showAccount')}
                            </Checkbox>
                        </div>
                    </div>
                    <div className={style.chartAndHistoryRow}>
                        <div className={style.chartBlock}>
                            <div className={style.header}>{t('myProfile.myGreenTools')}</div>
                            <div className={style.chartRow}>
                                <div className={style.graph}>
                                    <AccountChart />
                                </div>
                                <div className={style.legendMain}>
                                    {dataSet.map((item) => {
                                        const { color, name, value, id } = item
                                        return (
                                            <div id={id}>
                                                <div className={style.legendRow}>
                                                    <div
                                                        style={{ background: color }}
                                                        className={style.legendColor}
                                                    ></div>
                                                    <div className={style.legendName}>{name}</div>
                                                </div>
                                                <div className={style.legendValue}>{value}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <IconButton customStyle={{ marginTop: '32px' }}>
                                Перейти к моим ЗИ
                            </IconButton>
                        </div>
                        <div className={style.historyBlock}>
                            <div className={style.header}>{t('myProfile.operationHistory')}</div>
                            <OperationsMapper config={historyConfig} />
                            <Link to={`/go-personal-operations/${accounts[0].number}`}>
                                <IconButton customStyle={{ marginTop: '40px' }}>
                                    {t('myProfile.allOperations')}
                                </IconButton>
                            </Link>
                        </div>
                    </div>
                </>
            )}
            {status === '' && (
                <>
                    <div className={style.accountNumberBlock}>
                        <div className={style.accountNumberRow}>
                            <div>
                                <div>{t('myProfile.accountNumber')}</div>
                                <DropdownSelector
                                    options={accounts}
                                    customStyle={{ width: '300px', marginTop: '2px' }}
                                />
                            </div>
                            <IconButton
                                customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                                icon={<AddCircle />}
                                onClick={openOpenAccountModal}
                            >
                                {t('myProfile.openNewAccount')}
                            </IconButton>
                        </div>
                        <div className={style.freeGenerationAttributes}>809 458 000 кВт*ч</div>
                        <div className={style.buttonsRow}>
                            <ImportButton
                                value={t('myProfile.renameCount')}
                                icon={<Edit size={16} />}
                                customIconStyle={{ marginTop: '3px' }}
                                onClick={() => openModal('rename')}
                            />
                            <ImportButton
                                value={t('myProfile.closeCount')}
                                icon={<Trash />}
                                customStyle={{ marginLeft: '34px' }}
                                customIconStyle={{ marginTop: '3px' }}
                                onClick={() => openModal('delete')}
                            />
                            <ImportButton
                                value={t('myProfile.assignMain')}
                                icon={<SimpleStar />}
                                customStyle={{ marginLeft: '32px' }}
                                customIconStyle={{ marginTop: '3px' }}
                                onClick={() => openModal('setMain')}
                            />
                            <ImportButton
                                value={t('myProfile.downloadESignature')}
                                icon={<Stamp />}
                                customStyle={{ marginLeft: '32px' }}
                                customIconStyle={{ marginTop: '3px' }}
                            />
                        </div>
                    </div>
                    <div className={style.chartAndHistoryRow}>
                        <div className={style.chartBlock}>
                            <div className={style.header}>{t('myProfile.myGreenTools')}</div>
                            <div className={style.chartRow}>
                                <div className={style.graph}>
                                    <AccountChart />
                                </div>
                                <div className={style.legendMain}>
                                    {dataSet.map((item) => {
                                        const { color, name, value, id } = item
                                        return (
                                            <div id={id}>
                                                <div className={style.legendRow}>
                                                    <div
                                                        style={{ background: color }}
                                                        className={style.legendColor}
                                                    ></div>
                                                    <div className={style.legendName}>{name}</div>
                                                </div>
                                                <div className={style.legendValue}>{value}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={style.historyBlock}>
                            <div className={style.header}>{t('myProfile.operationHistory')}</div>
                            <OperationsMapper config={historyConfig} />
                            <Link to="/my-all-operations">
                                <IconButton customStyle={{ marginTop: '40px' }}>
                                    {t('myProfile.allOperations')}
                                </IconButton>
                            </Link>
                        </div>
                    </div>
                </>
            )}
            {status === 'accountClosed' && (
                <>
                    <div className={style.accountNumberBlock}>
                        <div className={style.accountNumberRow}>
                            <div>
                                <div>{t('myProfile.accountNumber')}</div>
                                <DropdownSelector
                                    options={accounts}
                                    customStyle={{ width: '300px', marginTop: '2px' }}
                                />
                            </div>
                            <IconButton
                                customIconStyle={{ marginTop: '5px', marginRight: '10px' }}
                                icon={<AddCircle />}
                            >
                                {t('myProfile.openNewAccount')}
                            </IconButton>
                        </div>
                        <Alert
                            className={style.closedAlert}
                            message={
                                <div className={style.modalAlertText}>
                                    <div>Счет закрыт 18.06.2022</div>
                                </div>
                            }
                            type="error"
                            showIcon
                        />

                        <div className={style.freeGenerationAttributes}>0 кВт*ч</div>
                        <div className={style.buttonsRow}>
                            <ImportButton
                                value={t('myProfile.downloadESignature')}
                                icon={<Stamp />}
                                customIconStyle={{ marginTop: '3px' }}
                            />
                        </div>
                    </div>
                    <div className={style.chartAndHistoryRow}>
                        <div className={style.chartBlock}>
                            <div className={style.header}>{t('myProfile.myGreenTools')}</div>
                            <div className={style.chartRow}>
                                <div className={style.graph}>
                                    <AccountChart />
                                </div>
                                <div className={style.legendMain}>
                                    {dataSet.map((item) => {
                                        const { color, name, value, id } = item
                                        return (
                                            <div id={id}>
                                                <div className={style.legendRow}>
                                                    <div
                                                        style={{ background: color }}
                                                        className={style.legendColor}
                                                    ></div>
                                                    <div className={style.legendName}>{name}</div>
                                                </div>
                                                <div className={style.legendValue}>{value}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={style.historyBlock}>
                            <div className={style.header}>{t('myProfile.operationHistory')}</div>
                            <OperationsMapper config={historyConfig} />
                            <Link to={`/go-personal-operations/${accounts[0].number}`}>
                                <IconButton customStyle={{ marginTop: '40px' }}>
                                    {t('myProfile.allOperations')}
                                </IconButton>
                            </Link>
                        </div>
                    </div>
                </>
            )}
            <AccountModal
                type={modalType}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
            />
            <OpenFirstAccountModal
                isModalVisible={isOpenFirstAccountModalVisible}
                setIsModalVisible={setIsOpenFirstAccountModalVisible}
            />
            <OpenAccountModal
                isModalVisible={isOpenAccountModalVisible}
                setIsModalVisible={setIsOpenAccountModalVisible}
            />
        </div>
    )
}

export default PersonalAccount
