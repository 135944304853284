import { FC } from 'react'
import { CardInfoBlock } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { Divider } from 'antd'
import { Calendar, Flash, Location } from '../../../../assets/icons'
import PowerTypeTag from '../PowerTypeTag/PowerTypeTag'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useAppSelector } from '../../../../store/hooks'
import { selectDictionariesMaps } from '../../../../store/dictionaries'

export interface ICardInfo {
    generator_id: string
    go_name: string
    power: number
    go_type_id: number
    qualification_date: string | null
    last_check_date: string | null
    region_id: number
    renewable_energy_type_id: number
}

interface ICard {
    cardInfo: ICardInfo
}

const Card: FC<ICard> = ({ cardInfo }) => {
    const { t } = useTranslation()

    const dictionaries = useAppSelector(selectDictionariesMaps)

    const { regionsMap, renewableEnergyTypesMap } = dictionaries

    const energyType = renewableEnergyTypesMap.get(cardInfo.renewable_energy_type_id)

    return (
        <div className={style.cardBlock}>
            <div className={style.cardsBlockHeader}>
                <Link
                    to={{
                        pathname: `/related-zgo/${cardInfo.generator_id}`,
                    }}
                    className={style.cardHeader}
                >
                    {cardInfo.go_name}
                </Link>
                <div className={style.tagsWrapper}>
                    <PowerTypeTag value={cardInfo.renewable_energy_type_id}>
                        {energyType}
                    </PowerTypeTag>
                </div>
            </div>
            <div className={style.infoRow}>
                <CardInfoBlock
                    label={t('generatorCard.power')}
                    value={`${cardInfo.power / 1000} ${t('generatorCard.scale')}`}
                    icon={<Flash />}
                    gap={7}
                />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label="Тип ГО"
                    value={cardInfo.go_type_id === 1 ? 'КГО' : 'ЗГО'}
                    gap={6}
                />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label={t('generatorCard.qualifyDate')}
                    value={
                        cardInfo.qualification_date
                            ? moment(cardInfo.qualification_date).format('DD.MM.YYYY')
                            : ''
                    }
                    icon={<Calendar />}
                    gap={6}
                />
                <Divider className={style.divider} type={'vertical'} />
                <CardInfoBlock
                    label={t('generatorCard.lastCheckDate')}
                    value={
                        cardInfo.last_check_date
                            ? moment(cardInfo.last_check_date).format('DD.MM.YYYY')
                            : ''
                    }
                    icon={<Calendar />}
                    gap={6}
                />
            </div>
            <div className={style.locationBlock}>
                <CardInfoBlock
                    value={`Россия, ${regionsMap.get(cardInfo.region_id)}`}
                    icon={<Location />}
                    gap={11}
                />
            </div>
        </div>
    )
}

export default Card
