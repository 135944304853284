import { RequestTypes } from '../../../../hooks'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_CHANGE_OWNER = 'certificate/change-owner'

export const postChangeOwner = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_CHANGE_OWNER}`,
    method: RequestTypes.post,
}

export const USER_API_PREFIX = '/api/users/v1/'
export const USER_FIND = '/find-for-green-cert'

export const getFindUsers = {
    link: `${USER_API_PREFIX}${USER_FIND}`,
    method: RequestTypes.post,
}
