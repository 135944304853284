// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editBlock_Raqd8 {\n  display: flex;\n  align-items: center;\n}\n.editBlockMargin_lPlDI {\n  display: flex;\n  align-items: center;\n  margin-top: 16px;\n}\n.label_ty8jZ {\n  text-align: right;\n  width: 200px;\n}\n.star_mcd5X {\n  margin-left: 4px;\n  display: inline;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EditMyProfile/components/CommonBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AACR;AAGA;EACI,iBAAA;EACA,YAAA;AADJ;AAIA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AAFJ","sourcesContent":[".editBlock {\n    display: flex;\n    align-items: center;\n\n    &Margin {\n        display: flex;\n        align-items: center;\n        margin-top: 16px;\n    }\n}\n\n.label {\n    text-align: right;\n    width: 200px;\n}\n\n.star {\n    margin-left: 4px;\n    display: inline;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editBlock": "editBlock_Raqd8",
	"editBlockMargin": "editBlockMargin_lPlDI",
	"label": "label_ty8jZ",
	"star": "star_mcd5X"
};
export default ___CSS_LOADER_EXPORT___;
