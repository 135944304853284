import React, { FC } from 'react'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import Document from './Document'

export interface IFile {
    id: string
    name: string
    size: number
    type?: string
    description: string
    hash: string
}

interface IDocumentsList {
    hideComment?: boolean
    hideRemove?: boolean
    files: IFile[]
    onChange(file: IFile): void
    onRemove(file: IFile): void
}

const DocumentsList: FC<IDocumentsList> = ({
    hideComment,
    hideRemove,
    files,
    onRemove,
    onChange,
}) => {
    const { t } = useTranslation()

    return (
        <>
            {files?.map((item) => {
                return (
                    <React.Fragment key={item.id}>
                        <Document
                            hideRemove={hideRemove}
                            key={item.name}
                            hideComment={hideComment}
                            file={item}
                            onRemove={() => onRemove(item)}
                            onChange={onChange}
                        />
                        <div className={style.emptyDivider}></div>
                    </React.Fragment>
                )
            })}
        </>
    )
}

export default DocumentsList
