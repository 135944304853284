import { Alert, Table } from 'antd'
import { FC } from 'react'
import Filter from '../Filter/Filter'

import style from './style.m.less'

import { closedDataSource } from './data'
import { closedColumns } from './columns'

const Closed: FC = () => {
    if (!closedDataSource || closedDataSource.length === 0) {
        return (
            <Alert
                message="У вас пока нет закрытых заявок"
                type="info"
                showIcon
                className={style.alert}
            />
        )
    }

    return (
        <div className={style.opened}>
            <Filter />
            <div className={style.table}>
                <Table
                    dataSource={closedDataSource}
                    columns={closedColumns}
                    pagination={{
                        position: ['bottomLeft'],
                    }}
                />
            </div>
        </div>
    )
}

export default Closed
