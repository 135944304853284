import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Flame: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_734_75617)">
                    <path
                        d="M16.232 10.2916C14.9237 6.89162 10.2654 6.70829 11.3904 1.76662C11.4737 1.39995 11.082 1.11662 10.7654 1.30829C7.74036 3.09162 5.56536 6.66662 7.39036 11.35C7.54036 11.7333 7.09036 12.0916 6.76536 11.8416C5.25703 10.7 5.0987 9.05829 5.23203 7.88329C5.28203 7.44995 4.71536 7.24162 4.4737 7.59995C3.90703 8.46662 3.33203 9.86662 3.33203 11.975C3.6487 16.6416 7.59036 18.075 9.00703 18.2583C11.032 18.5166 13.2237 18.1416 14.7987 16.7C16.532 15.0916 17.1654 12.525 16.232 10.2916ZM8.4987 14.4833C9.6987 14.1916 10.3154 13.325 10.482 12.5583C10.757 11.3666 9.68203 10.2 10.407 8.31662C10.682 9.87495 13.132 10.85 13.132 12.55C13.1987 14.6583 10.9154 16.4666 8.4987 14.4833Z"
                        fill="#FFA940"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_734_75617">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )}
    />
)
