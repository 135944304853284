import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { QuestionCircle } from '../../../../assets/icons'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal } from '../../../ui'
import request from '../../../../services/request'

import style from './style.m.less'

interface IRemoveRequestModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const RemoveRequestModal: FC<IRemoveRequestModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleConfirm = async () => {
        setIsModalVisible(false)
        await request.get('/api/users/v1/profile-cancel')
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.mainWrapper}>
                <QuestionCircle />
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>Снять заявку</div>
                    <div className={style.text}>
                        Вы действительно хотите снять заявку? Все внесенные изменения будут отменены
                    </div>
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            isDelete
                            confirmAction={handleConfirm}
                            cancelAction={handleOk}
                            confirmTitle="Снять заявку"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RemoveRequestModal
