import { FC, useState } from 'react'
import { currencyFormat } from '../../../helpers'
import { Pagination } from 'antd'
import style from './style.m.less'

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
}

interface IRepaymentsBlock {
    eventDetails: any
    getCustomData: (data: PaginationType) => void
}

const RepaymentsBlockAG: FC<IRepaymentsBlock> = ({ eventDetails, getCustomData }) => {
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const list = eventDetails?.payload?.consumers
    const total = eventDetails?.payload?.consumers.length

    return (
        <>
            <div className={style.energyBlock}>
                <div className={style.energyHeader}>{`Потребители АГ по ЗД (${total})`}</div>
                <div className={style.energyWrapper}>
                    <div className={style.energyTop}>Потребитель</div>
                    <div className={style.energyTop}>Объем, кВт*ч</div>
                </div>
                {list?.map((item: any, index: number) => {
                    return (
                        <div
                            className={
                                index === list.length - 1
                                    ? style.energyItemWrapperBottom
                                    : style.energyItemWrapper
                            }
                        >
                            <div className={style.energyItem}>{item.user_name}</div>
                            <div className={style.energyItem}>{currencyFormat(item.volume)}</div>
                        </div>
                    )
                })}
            </div>
            {total > 10 && (
                <Pagination
                    className={style.pagination}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    total={total || 0}
                    onChange={(page, pageSize) => {
                        getCustomData({ page, pageSize })
                        setPagination({ page, pageSize })
                    }}
                    showSizeChanger
                />
            )}
        </>
    )
}

export default RepaymentsBlockAG
