import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useMount from './useMount'

interface IContractData {
    toolType: string
    cardLabel: string
    id: string
    isUserType: boolean
    certificateNumber: string
    option: number
    energyType: number
    isIssuedCert: boolean
    isOwner: boolean
}

export const useContractData = () => {
    const location = useLocation()
    const state = location.state as IContractData

    const [certData, setSertData] = useState()

    useMount(() => {
        setSertData(state)
    })

    if (certData !== undefined) {
        return certData
    }
}
