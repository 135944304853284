import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TimeRangePicker } from '../../../ui'
import Graph from '../../../ui/Graph/Graph'
import style from './style.m.less'
import useRequest from '../../../hooks/useRequest'
import useMount from '../../../hooks/useMount'
import { getPlotstatsGOData } from '../../../OwnerCard/config'
import { useParams } from 'react-router-dom'
import moment, { Moment } from 'moment'

const GraphFrame: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()
    const { fetch, result } = useRequest(getPlotstatsGOData)
    const [range, setRange] = useState<[Moment, Moment] | null>()

    useMount(() => {
        fetch({
            getParams: {
                generator_id: id,
            },
        })
    })

    const rangeHandler = (r: [Moment, Moment]) => {
        if (!r) {
            setRange(null)
            return
        }
        setRange([moment(r[0]).startOf('month'), moment(r[1]).startOf('month')])
    }

    useEffect(() => {
        fetch({
            getParams: {
                generator_id: id,
                ...(range && {
                    start_period: moment(range[0]).startOf('month').format('YYYY-MM-DD'),
                    end_period: moment(range[1]).startOf('month').format('YYYY-MM-DD'),
                }),
            },
        })
    }, [range])

    return (
        <div className={style.graphFrameMain}>
            <div className={style.headerRow}>
                <div>
                    <span className={style.headerPrimary}>{t('detailedCard.graphVolume')}</span>
                    <span className={style.headerSecondary}>({t('detailedCard.scale2')})</span>
                </div>
                <TimeRangePicker
                    onlyBeforeDateAndToday={moment()}
                    picker="month"
                    value={range}
                    format={'MMMM YY'}
                    onChange={(value) => rangeHandler(value)}
                />
            </div>
            <div className={style.graph}>
                <Graph stats={result?.stats} period={range} />
            </div>
        </div>
    )
}

export default GraphFrame
