import request from '../request'
import { IRequest } from '../types'

export const fetchNotifications = async (offset?: number, limit?: number): Promise<IRequest> => {
    try {
        const { data, status } = await request.get(
            `/api/notifier/v1/feeds?${limit ? `&limit=${limit}` : ''}${
                offset ? `&offset=${offset}` : ''
            }`,
        )

        if (status !== 200) {
            throw new Error()
        }

        return { data: data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}
