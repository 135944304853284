import { FC } from 'react'
import IconButton, { IIconButton } from './IconButton'

import style from './style.m.less'

const IconButtonLarge: FC<IIconButton> = ({ ...rest }) => {
    return <IconButton {...rest} size="large" className={style.large} />
}

export default IconButtonLarge
