import { FC } from 'react'
import { PowerTypeTag, BackArrow } from '..'
import style from './style.m.less'

interface IHeaderBlock {
    label?: string
    energyType?: string
}

const HeaderBlock: FC<IHeaderBlock> = ({ label, energyType }) => {
    return (
        <div className={style.headerMain}>
            <div className={style.headerBlock}>
                <BackArrow />
                <div className={style.mainHeader}>{label}</div>
                {energyType && (
                    <PowerTypeTag value={energyType} customStyle={{ marginLeft: '16px' }}>
                        {energyType}
                    </PowerTypeTag>
                )}
            </div>
        </div>
    )
}

export default HeaderBlock
