// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_dgysN {\n  margin-top: 16px;\n  display: flex;\n}\n.header_CEleS {\n  color: #8c8c8c;\n  width: 229px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Audit/components/DocumentsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;AACJ;AAEA;EACI,cAAA;EACA,YAAA;AAAJ","sourcesContent":[".wrapper {\n    margin-top: 16px;\n    display: flex;\n}\n\n.header {\n    color: #8c8c8c;\n    width: 229px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_dgysN",
	"header": "header_CEleS"
};
export default ___CSS_LOADER_EXPORT___;
