import { Spin } from 'antd'
import { formatSize } from '../../helpers/formatSize'
import { iconSelector } from '../../helpers/profileHelpers'
import { Box } from '../Box'
import style from './style.m.less'

type Props = {
    document: any
    nameMaxLength?: number
    prefix?: string
    onClick?: (doc: any) => void
    downloading?: boolean
}

export const slicedFileName = (str: string, length = 50) => {
    const slice = str.split('.')
    if (slice[0].length < length) {
        return str
    }
    return `${slice[0].slice(0, length - 3)}....${slice[1]}`
}

const Document = ({ document, nameMaxLength, prefix = 'name', onClick, downloading }: Props) => {
    return (
        <Box
            className={style.iconWrapper}
            onClick={() => onClick && !downloading && onClick(document)}
            direction="row"
            align="center"
            justify="flex-start"
        >
            {downloading ? (
                <Spin size="default" style={{ marginRight: '1px' }} />
            ) : (
                iconSelector(document, prefix)
            )}
            <div className={style.infoBlock}>
                <div className={style.documentName}>
                    {slicedFileName(document.name, nameMaxLength)}
                </div>
                <div className={style.documentSize}>{formatSize(document.size)}</div>
            </div>
        </Box>
    )
}

export default Document
