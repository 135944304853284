import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_MY_CONTRACT = 'contracts/contract/my?id='
export const GREEN_TOOLS_PUBLIC_CONTRACT = 'contracts/contract?id='

export const getMyContractData = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_MY_CONTRACT}`,
    method: RequestTypes.get,
}

export const getPublicContractData = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_PUBLIC_CONTRACT}`,
    method: RequestTypes.get,
}
