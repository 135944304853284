import { FC } from 'react'
import { Divider, Alert } from 'antd'
import { ArrowLeft, Stamp } from '../../assets/icons'
import { DocumentsGrid, Tables } from './components'
import { Card, PrimaryButton, Tag } from '../ui'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import style from './style.m.less'

enum EStatus {
    UnderRevision = 'На доработке',
    UnderConsideration = 'На проверке',
    NotAgreed = 'Не согласовано',
    Accepted = 'Принято',
}

const status = EStatus.Accepted

const GODataKUPeriod: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div>
                    <h1 className={style.title}>Данные по КУ за 1.09.2019 - 30.09.2019</h1>
                </div>
                <Tag
                    outline
                    transparent
                    color={
                        status === EStatus.NotAgreed
                            ? 'red'
                            : status === EStatus.Accepted
                            ? 'blue'
                            : undefined
                    }
                >
                    {status}
                </Tag>
            </div>
            <Card className={style.card}>
                <div className={style.statusWrapper}>
                    {status === EStatus.NotAgreed && (
                        <div className={style.statusNotAgreedContainer}>
                            <div className={style.statusText}>Данные не приняты. Причина:</div>
                            <div className={style.statusReason}>
                                Комментарий оператора о том, что пользователю нужно доработать
                            </div>
                        </div>
                    )}
                    {status === EStatus.UnderConsideration && (
                        <Alert
                            message={<div>Данные отправлены на проверку Оператора</div>}
                            className={style.infoAlert}
                            type="info"
                            showIcon
                        />
                    )}
                    {status === EStatus.Accepted && (
                        <Alert
                            message={<div>Данные приняты</div>}
                            className={style.infoAlert}
                            type="success"
                            showIcon
                        />
                    )}
                    <div className={style.stampBlock}>
                        <Stamp />
                        <div className={style.stampOwner}>Скачать запрос и подпись</div>
                    </div>
                </div>

                <DocumentsGrid />
                <Tables />
                {status === EStatus.NotAgreed && (
                    <>
                        <Divider type={'horizontal'} className={style.divider} />
                        {/* <ButtonContainer /> */}
                        <PrimaryButton
                            value="Редактировать данные"
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </>
                )}
            </Card>
        </div>
    )
}

export default GODataKUPeriod
