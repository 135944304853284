// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonContainer_Vfoh5 {\n  margin-top: 18px;\n  padding-top: 24px;\n  border-top: 1px solid #F0F0F0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ZDData/components/DocsCard/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,6BAAA;AACJ","sourcesContent":[".buttonContainer {\n    margin-top: 18px;\n    padding-top: 24px;\n    border-top: 1px solid #F0F0F0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": "buttonContainer_Vfoh5"
};
export default ___CSS_LOADER_EXPORT___;
