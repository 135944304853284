enum EUserRole {
    All = 0,
    UserWithoutPA = 1,
    Client = 2,
    GOOwner = 3,
    TSO = 4,
    Operator = 5,
    Administrator = 6,
    SuperAdmin = 7,
}

export { EUserRole }
