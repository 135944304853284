import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IconButton, Box } from '../ui'
import { ELoading } from '../../store/dictionaries/interfaces'
import useMount from '../hooks/useMount'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getDictionaries } from '../../store/dictionaries'
import { UnregisteredLayout, RegisteredLayout } from './components'
import { EUserRole } from '../../constants/approval'
import style from './style.m.less'

interface IUserGreenTools {
    isAuth: boolean
    isUserType?: boolean
}

const UserGreenTools: FC<IUserGreenTools> = ({ isAuth, isUserType }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const isLoading = useAppSelector((state) => state.dictionaries.loading)
    const userRole = useAppSelector((state) => state.user.data.user_role)
    const isGOOwner = userRole === EUserRole.GOOwner

    useMount(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    return (
        <>
            <div className={style.greenToolsMain}>
                <div className={style.mainHeader}>
                    <div>
                        {isUserType ? t('greenTools.myGreenTools') : t('greenTools.greenTools')}
                    </div>
                    {isAuth && (
                        <Box direction="row" gap="16px">
                            {isGOOwner && (
                                <IconButton onClick={() => navigate('/issue-certificate')}>
                                    {t('detailedCard.issueCerf')}
                                </IconButton>
                            )}
                        </Box>
                    )}
                </div>
                <div className={style.mainWrapper}>
                    {isAuth ? <RegisteredLayout isUserType={isUserType} /> : <UnregisteredLayout />}
                </div>
            </div>
        </>
    )
}

export default UserGreenTools
