import { RequestTypes } from '../../../hooks/useRequest'

export const BILLING_API_PREFIX = '/api/billing/v1/'
export const BILLING_CREDENTIALS = 'credentials'
export const BILLING_BALANCES = 'account-balances'
export const BILLING_PACKAGES = 'service-packages/my'
export const AUDIT_API_PREFIX = '/api/audit/v1/'
export const HISTORY_PAYMENT = 'event-history-payment-acc'
const CREDENTIALS_DOWNLOAD = 'credentials-download'

export const getCredentials = {
    link: `${BILLING_API_PREFIX}${BILLING_CREDENTIALS}`,
    method: RequestTypes.get,
}

export const getBalances = {
    link: `${BILLING_API_PREFIX}${BILLING_BALANCES}`,
    method: RequestTypes.get,
}

export const getPaymentHistoryLimited = {
    link: `${AUDIT_API_PREFIX}${HISTORY_PAYMENT}`,
    method: RequestTypes.post,
}

export const getPdfFile = {
    link: `${BILLING_API_PREFIX}${CREDENTIALS_DOWNLOAD}`,
    method: RequestTypes.post,
}

export const getPackages = {
    link: `${BILLING_API_PREFIX}${BILLING_PACKAGES}`,
    method: RequestTypes.get,
}
