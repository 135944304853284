// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader_ZhBMX {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.modalText_tYrsH {\n  margin-top: 24px;\n}\n.checkbox_Tdz6n {\n  margin-top: 16px;\n}\n.buttonsBlock_bYgRC {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.inputBlockWrapper_ReJKy {\n  margin-top: 24px;\n}\n.activationAlert_JDNwv {\n  margin-top: 24px;\n  border-radius: 12px;\n  border: 1px solid #fff1b8;\n  color: #555861;\n  width: max-content;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DetailedCard/components/ActivateGeneratorModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,gBAAA;AAHJ;AAMA;EACI,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AAJJ","sourcesContent":[".modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.modalText {\n    margin-top: 24px;\n}\n\n.checkbox {\n    margin-top: 16px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.inputBlockWrapper {\n    margin-top: 24px;\n}\n\n.activationAlert {\n    margin-top: 24px;\n    border-radius: 12px;\n    border: 1px solid #fff1b8;\n    color: #555861;\n    width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "modalHeader_ZhBMX",
	"modalText": "modalText_tYrsH",
	"checkbox": "checkbox_Tdz6n",
	"buttonsBlock": "buttonsBlock_bYgRC",
	"inputBlockWrapper": "inputBlockWrapper_ReJKy",
	"activationAlert": "activationAlert_JDNwv"
};
export default ___CSS_LOADER_EXPORT___;
