import { FC, useRef, useState } from 'react'
import { Input, InputNumber } from 'antd'
import moment from 'moment'
import { Box, DropdownSelector2, TimeRangePicker } from '../../../ui'
import { EOperationTypeID } from './configurations'
import {
    EHistoryType,
    IOperationSettings,
} from '../../../../components/ui/OperationHistoryMapper/types'
import style from './style.m.less'

interface IFilter {
    settings: IOperationSettings
    typeEventIDsOptions: any
    typeEventIDsFilter: number[]
    onChangeTypeEventIDs: (id: number[]) => void
    onChangeAmountMin: (amountMin: number) => void
    onChangeAmountMax: (amountMax: number) => void
    onChangeOperationTypeID: (id: EOperationTypeID) => void
    onChangeDate: (date: [moment.Moment, moment.Moment] | null) => void
}
interface IOperationTypes {
    key: number
    text: string
    id: EOperationTypeID
    value: EOperationTypeID
}
const OperationFilters: FC<IFilter> = ({
    settings,
    typeEventIDsOptions,
    typeEventIDsFilter,
    onChangeTypeEventIDs,
    onChangeAmountMin,
    onChangeAmountMax,
    onChangeOperationTypeID,
    onChangeDate,
}: IFilter) => {
    const divBlock: any = useRef(null)

    const operationTypes: IOperationTypes[] = [
        {
            key: 0,
            text: 'Все',
            value: EOperationTypeID.OpTypeAll,
            id: EOperationTypeID.OpTypeAll,
        },
        {
            key: 1,
            text: 'Списания',
            value: EOperationTypeID.OpTypeDec,
            id: EOperationTypeID.OpTypeDec,
        },
        {
            key: 2,
            text: 'Пополнения',
            value: EOperationTypeID.OpTypeInc,
            id: EOperationTypeID.OpTypeInc,
        },
    ]
    const [currentOperationTypeValue, setCurrentOperationTypeValue] = useState<IOperationTypes>()
    const [disabledOperationTypes, setBlockOperationTypes] = useState(false)

    return (
        <div className={style.filter} ref={divBlock}>
            <div className={style.controlsBlock}>
                <div>
                    <div>Тип операции</div>
                    <div className={style.inputBlock}>
                        <DropdownSelector2
                            allowClear
                            customStyle={{ width: '265px' }}
                            isMultiple
                            noDefaultValue
                            onChange={(event) => {
                                onChangeTypeEventIDs(event)
                                setBlockOperationTypes(true)
                            }}
                            options={typeEventIDsOptions}
                            placeholder={'Все'}
                        />
                    </div>
                </div>
                <div className={style.dateBlock}>
                    <div>Дата операции</div>
                    <div className={style.inputBlock}>
                        <TimeRangePicker onChange={onChangeDate} />
                    </div>
                </div>
                <div className={style.dateBlock}>
                    {settings.historyType === EHistoryType.Payment && <div>Сумма операции</div>}
                    {(settings.historyType === EHistoryType.Personal ||
                        settings.historyType === EHistoryType.Certs) && (
                        <div>Объем атрибутов генерации, кВт*ч</div>
                    )}
                    <Box direction="row" gap="8px">
                        <InputNumber
                            className={style.inputShort}
                            style={{ borderRadius: '8px' }}
                            placeholder="С"
                            controls={false}
                            onChange={(value) => {
                                onChangeAmountMin(value)
                            }}
                        />
                        <InputNumber
                            className={style.inputShort}
                            style={{ borderRadius: '8px' }}
                            placeholder="По"
                            controls={false}
                            onChange={(value) => {
                                onChangeAmountMax(value)
                            }}
                        />
                    </Box>
                </div>
                <div className={style.dateBlock}>
                    <div>Пополнение/списание</div>
                    <div className={style.inputBlock}>
                        <DropdownSelector2
                            allowClear
                            customStyle={{ width: '265px' }}
                            defaultValue={operationTypes[0].text}
                            options={operationTypes}
                            onChange={(value) => {
                                onChangeOperationTypeID(value)
                                setCurrentOperationTypeValue(value)
                                setBlockOperationTypes(false)
                            }}
                            disabled={typeEventIDsFilter?.length > 0}
                            value={
                                disabledOperationTypes
                                    ? operationTypes[0].value
                                    : currentOperationTypeValue
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OperationFilters
