import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const NoteAdd: FC<IIconProps> = ({ size = 16 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.66797 9.33301H8.0013"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3 4.33333V1"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.66536 2.66699H1.33203"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.66797 6.66699H10.0013"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.66797 1.33301H10.668C12.888 1.45301 14.0013 2.27301 14.0013 5.32634V10.6663"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2 6.00684V10.6535C2 13.3268 2.66667 14.6668 6 14.6668H8C8.11333 14.6668 9.89333 14.6668 10 14.6668"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14 10.667L10 14.667V12.667C10 11.3337 10.6667 10.667 12 10.667H14Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
