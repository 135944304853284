import { FC } from 'react'
import { Divider, Input } from 'antd'
import { Alert, ImportButton } from '../../../ui'
import style from './style.m.less'
import { Edit, Search, Trash } from '../../../../assets/icons'

export interface ITsoGp {
    company_inn: string
    company_name_short: string
    kpp: string
    ogrn: string
    uid?: string
}

interface ISelectedTsoGp {
    label: string
    selectedTsoGp?: ITsoGp
    onRemoveClick(): void
    onEditClick(): void
    onSearchClick(): void
    onManualEditClick(): void
}

const SelectedTsoGp: FC<ISelectedTsoGp> = ({
    label,
    selectedTsoGp,
    onRemoveClick,
    // onEditClick,
    onSearchClick,
    onManualEditClick,
}) => {
    return (
        <>
            <div className={style.inputWrapper}>
                <div className={style.label}>{label}</div>
                {selectedTsoGp ? (
                    <Alert
                        className={style.resultLegalAlert}
                        message={
                            <div className={style.alertTitleWrapper}>
                                <span className={style.alertTitle}>
                                    {selectedTsoGp.company_name_short}
                                </span>
                                <div className={style.alertIconWrapper}>
                                    {/* <span className={style.icon} onClick={onEditClick}>
                                        <Edit />
                                    </span> */}
                                    <span className={style.icon} onClick={onRemoveClick}>
                                        <Trash />
                                    </span>
                                </div>
                            </div>
                        }
                        description={
                            <>
                                <span className={style.alertDetail}>
                                    ИНН - {selectedTsoGp.company_inn}
                                </span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>
                                    ОГРН - {selectedTsoGp.ogrn}
                                </span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>КПП - {selectedTsoGp.kpp}</span>
                            </>
                        }
                        type="info"
                        showIcon={false}
                    />
                ) : (
                    <Input className={style.input} readOnly />
                )}
            </div>
            {!selectedTsoGp && (
                <div className={style.linkWrapper}>
                    <ImportButton
                        value="Найти в системе"
                        icon={<Search />}
                        onClick={onSearchClick}
                    />
                    <Divider type="vertical" />
                    <ImportButton
                        value="Заполнить вручную"
                        icon={<Edit />}
                        onClick={onManualEditClick}
                    />
                </div>
            )}
        </>
    )
}

export default SelectedTsoGp
