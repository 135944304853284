import { FC, useState } from 'react'
// import { ValueSelector } from '../../../'
import { Input, Divider, Alert, Checkbox } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { DropdownSelector, IconButton, Modal, PrimaryButton } from '../../../ui'
import { WarningOutlined } from '@ant-design/icons'

const InsufficientFunds: FC = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible}>
                <div className={style.errorHeaderWrapper}>
                    <WarningOutlined className={style.errorModalIcon} />
                    <div className={style.errorModalHeader}>Недостаточно денежных средств</div>
                </div>
                <div className={style.errorModalText}>
                    Операция не выполнена, так как на Вашем балансе недостаточно средств, чтобы
                    произвести оплату комиссии.
                </div>
                <div className={style.buttonsBlock}>
                    <PrimaryButton
                        onClick={handleOk}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryButton
                        onClick={handleOk}
                        value="Перейти к платежному счету"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default InsufficientFunds
