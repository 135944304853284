// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badgeWrapper_CiqR3 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  width: 32px;\n  background-color: #fff1f0;\n  border-radius: 8px;\n}\n.badgeWrapperHovered_AzV0U:hover {\n  background-color: #ffccc7;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/LockBadge/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AAGI;EACI,yBAAA;AADR","sourcesContent":[".badgeWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 32px;\n    width: 32px;\n    background-color: #fff1f0;\n    border-radius: 8px;\n}\n\n.badgeWrapperHovered {\n    &:hover {\n        background-color: #ffccc7;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": "badgeWrapper_CiqR3",
	"badgeWrapperHovered": "badgeWrapperHovered_AzV0U"
};
export default ___CSS_LOADER_EXPORT___;
