import { RequestTypes } from '../hooks/useRequest'

export const USERS_API_PREFIX = '/api/users/v1/'
export const USERS_GENERIC = 'generic'
export const USERS_ADMINS = 'admins'

export const getGenericUsersList = {
    link: `${USERS_API_PREFIX}${USERS_GENERIC}`,
    method: RequestTypes.post,
}

export const getAdminsUsersList = {
    link: `${USERS_API_PREFIX}${USERS_ADMINS}`,
    method: RequestTypes.post,
}
