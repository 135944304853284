import ChangeRoleModal from './ChangeRoleModal/ChangeRoleModal'
import GeneralInfo from './GeneralInfo/GeneralInfo'
import PersonalAccount from './PersonalAccount/PersonalAccount'
import PaymentAccount from './PaymentAccount/PaymentAccount'
import DocumentsGrid from './DocumentsGrid/DocumentsGrid'
import ESignatureModal from './ESignatureModal/ESignatureModal'
import ChangeEmailModal from './ChangeEmailModal/ChangeEmailModal'
import Password from './Password/Password'
import TagFilter from './TagFilter/TagFilter'
import OperationDetailsModal from './OperationDetailsModal/OperationDetailsModal'
import OpenFirstAccountModal from './OpenFirstAccountModal/OpenFirstAccountModal'
import OpenAccountModal from './OpenAccountModal/OpenAccountModal'

export {
    ChangeRoleModal,
    GeneralInfo,
    PersonalAccount,
    PaymentAccount,
    DocumentsGrid,
    ESignatureModal,
    ChangeEmailModal,
    Password,
    TagFilter,
    OperationDetailsModal,
    OpenFirstAccountModal,
    OpenAccountModal,
}
