import { FC, useState } from 'react'
import { Input, Divider, Alert, Empty } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { CenteredSpin, IconButton, Modal, PrimaryButton, PrimaryIconButton } from '../../../ui'
import request from '../../../../services/request'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'

export interface IGP {
    company_inn: string
    company_name_short: string
    kpp: string
    ogrn: string
    uid: string
    isDraft: boolean
}

interface ISearchGP {
    isModalVisible: boolean
    onCancel(): void
    onSelect(tso: IGP): void
}

const SearchGP: FC<ISearchGP> = ({ isModalVisible, onCancel, onSelect }) => {
    const { t } = useTranslation()

    const [shortName, setShortName] = useState('')
    const [inn, setInn] = useState('')
    const [ogrn, setOgrn] = useState('')
    const [kpp, setKpp] = useState('')

    const searchIsDisabled = !shortName && !inn && !ogrn && !kpp

    const [gpLoading, setGpLoading] = useState(false)
    const [gps, setGps] = useState<IGP[]>([])
    const [selectedGP, setSelectedGP] = useState<IGP>()

    const handleOk = () => {
        if (!selectedGP) {
            return
        }
        onSelect({ ...selectedGP, isDraft: false })
    }

    const showSearchResult = async () => {
        try {
            setGpLoading(true)
            const res = await request.post<any, AxiosResponse<{ total: number; companies: IGP[] }>>(
                '/api/users/v1/tso-gp/find',
                {
                    filters: {
                        company_name: shortName,
                        company_inn: inn,
                        ogrn: ogrn,
                        kpp: kpp,
                    },
                    limit: 0,
                    offset: 0,
                },
            )
            setGps(res.data.companies)
        } finally {
            setGpLoading(false)
        }
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible}>
                <div className={style.modalHeader}>Поиск ГП в системе</div>
                <Alert
                    className={style.resultAlert}
                    message="Поиск осуществляется по любому из заполненных полей"
                    type="info"
                    showIcon
                />
                <>
                    <div className={style.lineWrapper}>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Сокращенное название организации </div>
                            <Input
                                placeholder="Введите cокращенное название организации "
                                className={style.input}
                                value={shortName}
                                onChange={(e) => setShortName(e.target.value)}
                            />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ИНН</div>
                            <Input
                                placeholder="Введите ИНН"
                                className={style.input}
                                value={inn}
                                onChange={(e) => setInn(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={style.lineWrapper}>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>КПП</div>
                            <Input
                                placeholder="Введите КПП"
                                className={style.input}
                                value={kpp}
                                onChange={(e) => setKpp(e.target.value)}
                            />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ОГРН</div>
                            <Input
                                placeholder="Введите ОГРН"
                                className={style.input}
                                value={ogrn}
                                onChange={(e) => setOgrn(e.target.value)}
                            />
                        </div>
                    </div>
                </>
                <IconButton
                    customStyle={{ marginTop: '16px' }}
                    onClick={showSearchResult}
                    disabled={searchIsDisabled}
                >
                    Найти
                </IconButton>
                {gpLoading ? (
                    <CenteredSpin />
                ) : gps?.length > 0 ? (
                    <>
                        {gps.map((gp) => (
                            <div
                                className={classNames(style.resultBlock, {
                                    [style.resultAlert]: selectedGP === gp,
                                })}
                                onClick={() => setSelectedGP(gp)}
                            >
                                <div className={style.alertTitle}>{gp.company_name_short}</div>
                                <div>
                                    <span className={style.alertDetail}>
                                        ИНН - {gp.company_inn}
                                    </span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>ОГРН - {gp.ogrn}</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>КПП - {gp.kpp}</span>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
                {/* <>
                        <Alert
                            className={style.resultAlert}
                            message={<span className={style.alertTitle}>ООО Ромашка</span>}
                            description={
                                <>
                                    <span className={style.alertDetail}>ИНН - 9462618934</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>ОГРН - 1230563001975</span>
                                    <Divider type="vertical" />
                                    <span className={style.alertDetail}>КПП - 1234567890987</span>
                                </>
                            }
                            type="info"
                        />
                        <div className={style.resultBlock}>
                            <div className={style.alertTitle}>ОАО Ромашковая Ромашка</div>
                            <div>
                                <span className={style.alertDetail}>ИНН - 8765678022</span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>ОГРН - 3880457163840</span>
                                <Divider type="vertical" />
                                <span className={style.alertDetail}>КПП - 1234567890987</span>
                            </div>
                        </div>
                    </>
                )} */}
                <Divider />
                <div className={style.buttonsBlock}>
                    <PrimaryButton
                        onClick={onCancel}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryIconButton
                        size="large"
                        disabled={!selectedGP}
                        onClick={handleOk}
                        value="Добавить"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default SearchGP
