import { RequestTypes } from '../../../../components/hooks'

const API_BILLING_PREFIX = '/api/billing/v1'
const API_GA_PREFIX = '/api/generation_attributes/v1'

export const balanceConfig = {
    link: `${API_BILLING_PREFIX}/account-balances`,
    method: RequestTypes.get,
}

export const paymentConfig = {
    link: `${API_GA_PREFIX}/gas/actions/payment-fixation`,
    method: RequestTypes.post,
}
