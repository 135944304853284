import { RequestTypes } from '../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_MY_CERTIFICATE = 'my-certificates?id='
export const GREEN_TOOLS_PUBLIC_CERTIFICATE = 'certificates?id='

export const getMyCertificateData = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_MY_CERTIFICATE}`,
    method: RequestTypes.get,
}

export const getPublicCertificateData = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_PUBLIC_CERTIFICATE}`,
    method: RequestTypes.get,
}
