import { Table } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Filter from '../Filter/Filter'
import request from '../../../../services/request'
import { IData } from './data'
import { closedColumns } from './columns'
import moment from 'moment'
import debounce from 'lodash/debounce'

import style from './style.m.less'
import { EUserType } from '../../../../constants/approval'

const PAGE_SIZE = 10
interface ILoadGenerators {
    pagination: number
    pageSize: number
    dateFilter: [moment.Moment, moment.Moment] | null
    gOOwner: string
    goName: string
    innFilter: string
}

const Closed: FC = () => {
    const navigate = useNavigate()

    const [data, setData] = useState()
    const [generatorsLoading, setGeneratorsLoading] = useState(false)
    const [total, setTotal] = useState()
    const [pagination, setPagination] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment] | null>(null)
    const [gOOwner, setGOOwner] = useState('')
    const [goName, setGoName] = useState('')
    const [innFilter, setInnFilter] = useState('')

    const loadGenerators = useCallback(
        async ({
            pagination,
            dateFilter,
            gOOwner,
            goName,
            innFilter,
            pageSize,
        }: ILoadGenerators) => {
            try {
                setGeneratorsLoading(true)
                const res = await request.post('/api/generators/v1/gos-admin', {
                    limit: pageSize,
                    offset: (pagination - 1) * pageSize,
                    filters: {
                        ts_from: dateFilter ? moment(dateFilter[0]).startOf('day') : undefined,
                        ts_to: dateFilter ? moment(dateFilter[1]).endOf('day') : undefined,
                        go_owner_name: gOOwner,
                        action_type: 1,
                        go_name: goName,
                        inn: innFilter,
                    },
                })
                setData(res.data)
                setTotal(res.data.total)
            } finally {
                setGeneratorsLoading(false)
            }
        },
        [pageSize, pagination],
    )

    const loadProfilesDebounced = useCallback(debounce(loadGenerators, 300), [])

    useEffect(() => {
        loadProfilesDebounced({
            pagination,
            dateFilter,
            gOOwner,
            goName,
            innFilter,
            pageSize,
        })
    }, [pagination, dateFilter, gOOwner, goName, innFilter, pageSize])

    const getDataSource = (): IData[] =>
        data?.generators?.map(
            (item: {
                generator_id: string
                ts_created: string
                first_name: string
                last_name: string
                personal_inn: string
                company_inn: string
                go_name: string
                company_name_short: string
                uid: string
                user_type: EUserType
                generator_status: number
            }) => {
                const user =
                    item.user_type === 0
                        ? item.first_name + ' ' + item.last_name
                        : item.user_type === 1
                        ? `ИП ${item.first_name + ' ' + item.last_name}`
                        : item.company_name_short
                return {
                    key: item.generator_id,
                    submissionDate: moment(item.ts_created).format('DD.MM.YYYY'),
                    gOOwner: user,
                    inn: item.user_type === EUserType.UrLico ? item.company_inn : item.personal_inn,
                    gOName: item.go_name,
                    isNew: item.generator_status === 1,
                    uid: item.uid,
                }
            },
        )

    const closedDataSource = getDataSource()

    return (
        <div className={style.opened}>
            <Filter
                onChangeDate={setDateFilter}
                onChangeGOOwner={setGOOwner}
                onChangeGoName={setGoName}
                onChangeInn={setInnFilter}
            />
            <div className={style.table}>
                <Table
                    dataSource={closedDataSource}
                    columns={closedColumns}
                    pagination={{
                        position: ['bottomLeft'],
                        onChange: (page, pageSize) => {
                            setPagination(page)
                            setPageSize(pageSize)
                        },
                        total: total,
                        pageSize: pageSize,
                        showSizeChanger: true,
                    }}
                    onRow={(record) => ({
                        onClick: () => {
                            navigate('/go-approval/' + record.key)
                        },
                    })}
                    loading={generatorsLoading}
                />
            </div>
        </div>
    )
}

export default Closed
