enum EStatus {
    RevisionRequired = 'Требуется доработка',
    UnderConsideration = 'На проверке',
    NotAgreed = 'Не согласовано',
    Accepted = 'Принято',
}

interface IData {
    key: string
    period: [Date, Date]
    volume: number
    zgo: string
    date: Date
    status: EStatus
}

const getDataSource = (): IData[] => [
    {
        key: '1',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 100928,
        zgo: 'ООО "Бугульчанская солнечная электростанция"',
        date: new Date(2022, 5, 5),
        status: EStatus.UnderConsideration,
    },
    {
        key: '2',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 15000,
        zgo: 'ООО "Бугульчанская солнечная электростанция"',
        date: new Date(2022, 5, 4),
        status: EStatus.UnderConsideration,
    },
    {
        key: '3',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 7855092,
        zgo: 'ООО "Бугульчанская солнечная электростанция"',
        date: new Date(2022, 5, 1),
        status: EStatus.NotAgreed,
    },
    {
        key: '4',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 13839900,
        zgo: 'ООО "Авелар Солар Технолоджи"',
        date: new Date(2022, 4, 29),
        status: EStatus.NotAgreed,
    },
    {
        key: '5',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 4800311,
        zgo: 'ООО "Авелар Солар Технолоджи"',
        date: new Date(2022, 4, 29),
        status: EStatus.Accepted,
    },
    {
        key: '6',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 56000430,
        zgo: 'ООО "Авелар Солар Технолоджи"',
        date: new Date(2022, 4, 27),
        status: EStatus.Accepted,
    },
    {
        key: '7',
        period: [new Date(2020, 1, 1), new Date(2020, 1, 29)],
        volume: 7000800,
        zgo: 'ООО "Первый Ветропарк ФРВ"',
        date: new Date(2022, 4, 29),
        status: EStatus.Accepted,
    },
]

const dataSource = getDataSource()

export { EStatus, dataSource }
export type { IData }
