import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import debounce from 'lodash/debounce'
import request from '../../../../services/request'
import FileSaver from 'file-saver'
import { message, Spin } from 'antd'
import useMount from '../../../hooks/useMount'
import { Import, FolderCross } from '../../../../assets/icons'
import {
    CenteredSpin,
    HeaderBlock,
    IconButton,
    OperationHistoryMapper,
    Pagination,
    Card,
    Box,
} from '../../../ui'
import { EOperationTypeID } from '../../../../components/Profile/pages/AllOperations/configurations'
import OperationFilters from '../../../../components/Profile/pages/AllOperations/OperationFilters'
import { getPersAccTypes } from '../../../../services/dictionaries'
import { getPersAccHistory } from '../../../../services/audit'
import {
    EHistoryType,
    IOperationConfig,
} from '../../../../components/ui/OperationHistoryMapper/types'
import style from './style.m.less'

interface ILoadPersAccHistory {
    id: number
    page: number
    pageSize: number
    eventDateFilter: [moment.Moment, moment.Moment] | null
    typeEventIDsFilter: number[]
    volumeMinFilter: number | null
    volumeMaxFilter: number | null
    operationTypeIDFilter: EOperationTypeID | undefined
}

const paidOnly = false
const PAGE_SIZE = 10
const debounceWaitMs = 300

const GOPersonalOperations: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<{ id?: string }>()

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const [historyLoading, setHistoryLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [history, setHistory] = useState<IOperationConfig[]>([])
    const [typeEventIDs, setTypeEventIDs] = useState({
        options: [],
        dictionary: {},
    })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [total, setTotal] = useState(0)
    const [eventDateFilter, setEventDateFilter] = useState<[moment.Moment, moment.Moment] | null>(
        null,
    )
    const [typeEventIDsFilter, setTypeEventIDsFilter] = useState<number[]>([])
    const [volumeMinFilter, setVolumeMinFilter] = useState(0)
    const [volumeMaxFilter, setVolumeMaxFilter] = useState(0)
    const [operationTypeIDFilter, setOperationTypeIDFilter] = useState<
        EOperationTypeID | undefined
    >(EOperationTypeID.OpTypeAll)

    const getDict = async () => {
        try {
            const typeEventsResponse = await getPersAccTypes()
            const eventOptions = typeEventsResponse?.data?.map((item) => {
                return {
                    id: item.id,
                    value: item.id,
                    text: item.name,
                }
            })

            setTypeEventIDs({
                dictionary:
                    eventOptions?.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.text })) ?? {},
                options: [...eventOptions],
            })
        } catch (error: any) {
            console.log(error)
        }
    }

    useMount(() => {
        getDict()
    })

    const today = moment().startOf(dayUnit)
    const yesterday = moment().subtract(1, dayUnit).format(shortFormat)

    const loadPersAccHistory = useCallback(
        async ({
            id,
            page,
            pageSize,
            eventDateFilter,
            typeEventIDsFilter,
            volumeMinFilter,
            volumeMaxFilter,
            operationTypeIDFilter,
        }: ILoadPersAccHistory) => {
            try {
                setHistoryLoading(true)
                const historyResponse = await getPersAccHistory(
                    id,
                    pageSize,
                    (page - 1) * pageSize,
                    {
                        event_date_from: eventDateFilter
                            ? moment(eventDateFilter[0]).startOf(dayUnit)
                            : null,
                        event_date_to: eventDateFilter
                            ? moment(eventDateFilter[1]).endOf(dayUnit)
                            : null,
                        type_event_ids: typeEventIDsFilter.length > 0 ? typeEventIDsFilter : null,
                        volume_min: volumeMinFilter,
                        volume_max: volumeMaxFilter,
                        operation_type_id: operationTypeIDFilter,
                        paid_only: paidOnly,
                    },
                )

                const resp: {
                    events: []
                    total: number
                } = historyResponse.data

                if (resp.total > 0) {
                    setHistory(
                        resp?.events?.map((phe: { date: string; operations: any[] }) => {
                            const eventDay = moment(phe.date, shortFormat)

                            let title = eventDay.format(extraFormat)

                            const isYesterday = eventDay.format(shortFormat) === yesterday

                            if (eventDay.isSame(today)) {
                                title = `${t('history.today')}, ${title}`
                            } else if (isYesterday) {
                                title = `${t('history.yesterday')}, ${title}`
                            }

                            return {
                                flexStart: false,
                                iconMargin: false,
                                fullScreen: true,
                                date: title,
                                operations: phe.operations,
                            }
                        }),
                    )
                }

                setTotal(resp.total)
            } finally {
                setHistoryLoading(false)
            }
        },
        [],
    )

    const loadHistoryDebounced = useCallback(debounce(loadPersAccHistory, debounceWaitMs), [])

    useEffect(() => {
        loadHistoryDebounced({
            id,
            page,
            pageSize,
            eventDateFilter,
            typeEventIDsFilter,
            volumeMinFilter,
            volumeMaxFilter,
            operationTypeIDFilter,
        })
    }, [
        id,
        page,
        pageSize,
        eventDateFilter,
        typeEventIDsFilter,
        volumeMinFilter,
        volumeMaxFilter,
        operationTypeIDFilter,
    ])

    const handleDownload = async () => {
        try {
            setIsDownloading(true)

            const url = `/api/audit/v1/event-history-acc?id=${id}&download`
            const dataParam = {
                filters: {
                    event_date_from: eventDateFilter
                        ? moment(eventDateFilter[0]).startOf(dayUnit)
                        : null,
                    event_date_to: eventDateFilter
                        ? moment(eventDateFilter[1]).endOf(dayUnit)
                        : null,
                    type_event_ids: typeEventIDsFilter.length > 0 ? typeEventIDsFilter : null,
                    amount_min: volumeMinFilter,
                    amount_max: volumeMaxFilter,
                    operation_type_id: operationTypeIDFilter,
                    paid_only: paidOnly,
                },
            }

            const { data, status } = await request.post(url, dataParam)

            if (status === 200) {
                const fileName = `Истории операций по лицевому счету ${id}.csv`
                const linkSource = `data:text/csv;charset=utf-8,${data}`

                FileSaver.saveAs(linkSource, fileName)
            }
        } catch (error: any) {
            message.error(error.err_message ?? error)
        } finally {
            setIsDownloading(false)
        }
    }

    return (
        <div className={style.container}>
            <div className={style.editMain}>
                <div className={style.headerWrapper}>
                    <HeaderBlock label={t('myProfile.allOperations')} />
                    <Spin spinning={isDownloading}>
                        <IconButton
                            icon={<Import />}
                            customIconStyle={{ marginTop: '3px' }}
                            onClick={handleDownload}
                        >
                            {t('myProfile.upload')}
                        </IconButton>
                    </Spin>
                </div>
                {historyLoading && !history && <CenteredSpin />}
                <div className={style.editFrame}>
                    <div className={style.controlsBlock}>
                        <OperationFilters
                            settings={{ historyType: EHistoryType.Personal }}
                            typeEventIDsOptions={typeEventIDs.options}
                            typeEventIDsFilter={typeEventIDsFilter}
                            onChangeTypeEventIDs={(values) => {
                                setTypeEventIDsFilter(values)
                                setOperationTypeIDFilter(undefined)
                            }}
                            onChangeAmountMin={(value) => {
                                setPage(1)
                                setVolumeMinFilter(value)
                            }}
                            onChangeAmountMax={(value) => {
                                setPage(1)
                                setVolumeMaxFilter(value)
                            }}
                            onChangeOperationTypeID={(value) => {
                                setPage(1)
                                setOperationTypeIDFilter(value)
                            }}
                            onChangeDate={(range) => {
                                setPage(1)
                                setEventDateFilter(range)
                            }}
                        />
                    </div>
                    {total ? (
                        <>
                            <OperationHistoryMapper
                                settings={{ historyType: EHistoryType.Personal, navigate: true }}
                                config={history}
                            />
                            <Pagination
                                showSizeChanger
                                customStyle={{ padding: 0, marginTop: '32px', boxShadow: 'none' }}
                                current={page}
                                paginationChange={(
                                    receivedPage: number,
                                    receivedPageSize?: number,
                                ) => {
                                    setPage(receivedPage)
                                    setPageSize(receivedPageSize ?? PAGE_SIZE)
                                }}
                                pageSize={pageSize}
                                total={total}
                            />
                        </>
                    ) : (
                        <Card className={style.card}>
                            <Box>
                                <FolderCross />
                                <div className={style.textAlert}>Ничего не найдено</div>
                            </Box>
                        </Card>
                    )}
                </div>
            </div>
        </div>
    )
}

export default GOPersonalOperations
