import { FC, useMemo, useState } from 'react'
import { Select } from 'antd'
import { DownMini } from '../../../assets/icons'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

const { Option } = Select

interface IDropdownInput {
    options: string[]
    customStyle?: React.CSSProperties
    onChange?: (value: any) => void
    placeholder?: string
    className?: string
    size?: SizeType
}

const DropdownInput: FC<IDropdownInput> = ({
    options,
    customStyle,
    placeholder,
    onChange,
    className,
    size,
}) => {
    const [search, setSearch] = useState('')
    const [value, setValue] = useState<any>()

    const realOptions = useMemo(
        () =>
            [search, value]
                .filter((o) => !!o)
                .concat(options.filter((o) => o !== search && o !== value)),
        [search, value, options],
    )

    return (
        <Select
            suffixIcon={
                <div style={{ marginTop: '4px', marginRight: '3px' }}>
                    <DownMini />
                </div>
            }
            onChange={(value) => {
                setValue(value)
                onChange?.(value)
            }}
            placeholder={placeholder}
            style={customStyle}
            className={className}
            size={size}
            onSearch={(search) => setSearch(search)}
            filterOption={false}
            searchValue={search}
            showSearch
            value={value}
        >
            {realOptions?.map((item) => (
                <Option key={item} value={item}>
                    {item}
                </Option>
            ))}
        </Select>
    )
}

export default DropdownInput
