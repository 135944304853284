import { renderDayMonthYear } from '../../../../utils/formatter'

export const fuelInfoConfig = (data: any) => [
    {
        name: 'Владелец ЗГО',
        value:
            data.go_owner.user_type === 1
                ? `ИП ${data.go_owner.last_name} ${data.go_owner.first_name} ${data.go_owner.middle_name}`
                : data.go_owner.company_name_short,
    },
    {
        name: 'ЗГО',
        value: data.generator.name,
    },
    {
        name: 'Дата подачи',
        value: renderDayMonthYear(data.ts_created),
    },
]
