import { ConfigProvider, Table } from 'antd'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import Filter from '../Filter/Filter'
import style from './style.m.less'
import useMount from '../../../hooks/useMount'
import { getGtRegistryWm, getGtRegistryRm } from '../../config'
import { renderDayMonthYear } from '../../../../utils/formatter'
import { monthPicker } from '../../../helpers'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

import useRequest from '../../../hooks/useRequest'

type PaginationType = {
    page: number
    pageSize: number
    filters?: any
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

const COLUMNS = [
    {
        title: 'Дата подачи',
        dataIndex: 'ts_created',
        key: 'ts_created',
        width: '33%',
        render: (date: Date) => renderDayMonthYear(date),
    },
    {
        title: 'Отчетный период',
        dataIndex: 'month',
        key: 'month',
        width: '34%',
        render: (month: number, record: any) => `${monthPicker(month)}, ${record.year}`,
    },
    {
        title: 'Количество договоров в подаче',
        dataIndex: 'contracts',
        key: 'contracts',
        width: '33%',
        render: (contracts: number) => contracts,
    },
]

const Default: FC = () => {
    const navigate = useNavigate()
    const { isUserAdmin } = useCurrentUser()
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>()
    const { fetch: fetchWm, result: resultWm, isLoading: isLoadingWm } = useRequest(getGtRegistryWm)
    const { fetch: fetchRm, result: resultRm, isLoading: isLoadingRm } = useRequest(getGtRegistryRm)

    const getData = ({ page = 1, pageSize = 10, filters }: PaginationType) => {
        ;(isUserAdmin ? fetchWm : fetchRm)({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters,
            },
            errorCodeMessage: true,
        })
    }

    const updateFilters = (data: string | number | boolean | number[], key: string, obj?: any) => {
        const updatedFilter = {
            ...filters,
            ...(obj ? { ...obj } : { [key]: data }),
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        getData({ page: 1, pageSize: pagination.pageSize, filters: updatedFilter })
    }

    useMount(() => getData({ page: 1, pageSize: pagination.pageSize, filters: filters }))

    const result = isUserAdmin ? resultWm : resultRm
    const isLoading = isUserAdmin ? isLoadingWm : isLoadingRm
    const type = isUserAdmin ? 'wm' : 'rm'

    return (
        <div className={style.opened}>
            <Filter onChange={updateFilters} />
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <Table
                        dataSource={result?.submissions || []}
                        pagination={{
                            position: ['bottomLeft'],
                            onChange: (page, pageSize) => {
                                getData({ page, pageSize, filters })
                                setPagination({ page, pageSize })
                            },
                            total: result?.total,
                            current: pagination.page,
                            pageSize: pagination.pageSize,
                            showSizeChanger: true,
                        }}
                        columns={COLUMNS}
                        onRow={(record) => ({
                            onClick: () => {
                                navigate(`/zd-card-docs/${type}/${record.id}`)
                            },
                        })}
                        loading={isLoading}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Default
