// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".generatorsMain_hvyMl {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 0 100px;\n  width: 1280px;\n  margin: 0 auto;\n}\n.mainHeader_nTDZx {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.mainWrapper_WWJCS {\n  display: flex;\n  flex-direction: column;\n  margin-top: 32px;\n}\n.mainBlock_LdXTT {\n  display: flex;\n  justify-content: space-between;\n  width: 1280px;\n}\n.cardsBlock_ToBA3 {\n  padding: 16px 24px;\n  width: 848px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.cardsBlock_ToBA3 .ant-divider-horizontal {\n  margin: 20px 0;\n}\n.controlsMain_dSolu {\n  display: flex;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Generators/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,aAAA;EACA,cAAA;AACJ;AAEA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,8BAAA;EACA,aAAA;AAFJ;AAKA;EACI,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AAHJ;AAFA;EASY,cAAA;AAJZ;AASA;EACI,aAAA;EACA,SAAA;AAPJ","sourcesContent":[".generatorsMain {\n    display: flex;\n    flex-direction: column;\n    padding: 40px 0 100px;\n    width: 1280px;\n    margin: 0 auto;\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.mainWrapper {\n    display: flex;\n    flex-direction: column;\n    margin-top: 32px;\n}\n\n.mainBlock {\n    display: flex;\n    justify-content: space-between;\n    width: 1280px;\n}\n\n.cardsBlock {\n    padding: 16px 24px;\n    width: 848px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n\n    :global {\n        .ant-divider-horizontal {\n            margin: 20px 0;\n        }\n    }\n}\n\n.controlsMain {\n    display: flex;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generatorsMain": "generatorsMain_hvyMl",
	"mainHeader": "mainHeader_nTDZx",
	"mainWrapper": "mainWrapper_WWJCS",
	"mainBlock": "mainBlock_LdXTT",
	"cardsBlock": "cardsBlock_ToBA3",
	"controlsMain": "controlsMain_dSolu"
};
export default ___CSS_LOADER_EXPORT___;
