import { FC, ReactElement } from 'react'

import style from './style.m.less'

const IsConflictCell: FC<{ conflict?: boolean; children: ReactElement }> = ({
    conflict,
    children,
}) => (conflict ? <div className={style.conflict}>{children}</div> : children)

const render = (value: any) => (
    <IsConflictCell conflict={value.conflict}>{value.value.toLocaleString() || '-'}</IsConflictCell>
)

export const columns = [
    {
        title: '',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: '17.02.2022',
        dataIndex: 'one',
        key: 'one',
        render,
    },
    {
        title: '18.02.2022',
        dataIndex: 'two',
        key: 'two',
        render,
    },
    {
        title: '19.02.2022',
        dataIndex: 'three',
        key: 'three',
        render,
    },
    {
        title: '20.02.2022',
        dataIndex: 'four',
        key: 'four',
        render,
    },
    {
        title: '21.02.2022',
        dataIndex: 'five',
        key: 'five',
        render,
    },
    {
        title: '22.02.2022',
        dataIndex: 'six',
        key: 'six',
        render,
    },
    {
        title: '23.02.2022',
        dataIndex: 'seven',
        key: 'seven',
        render,
    },
    {
        title: '24.02.2022',
        dataIndex: 'eight',
        key: 'eight',
        render,
    },
    {
        title: '25.02.2022',
        dataIndex: 'nine',
        key: 'nine',
        render,
    },
    {
        title: '26.02.2022',
        dataIndex: 'ten',
        key: 'ten',
        render,
    },
]
