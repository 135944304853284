// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".controlsMain_ziusj {\n  display: flex;\n  justify-content: space-between;\n  width: 848px;\n}\n.controlsSwitch_LrljL {\n  display: flex;\n  justify-content: space-between;\n  width: 70px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Controls/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,YAAA;AACJ;AAEA;EACI,aAAA;EACA,8BAAA;EACA,WAAA;AAAJ","sourcesContent":[".controlsMain {\n    display: flex;\n    justify-content: space-between;\n    width: 848px;\n}\n\n.controlsSwitch {\n    display: flex;\n    justify-content: space-between;\n    width: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlsMain": "controlsMain_ziusj",
	"controlsSwitch": "controlsSwitch_LrljL"
};
export default ___CSS_LOADER_EXPORT___;
