import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Battery: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.666 7.3335C14.666 7.3335 14.666 7.66683 14.666 8.3335V9.66683C14.666 10.3335 14.666 10.6668 13.666 10.6668"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.6668 6.3335L5.4068 8.00016C5.1468 8.44683 5.4668 9.00016 5.98013 9.00016H7.51346C8.0268 9.00016 8.3468 9.5535 8.09346 10.0002L6.6668 11.6668"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.66732 13.6668C2.00065 13.6668 1.33398 13.0002 1.33398 10.3335V7.66683C1.33398 5.00016 2.00065 4.3335 4.66732 4.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.66602 4.3335C11.3327 4.3335 11.9993 5.00016 11.9993 7.66683V10.3335C11.9993 13.0002 11.3327 13.6668 8.66602 13.6668"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
