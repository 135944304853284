import { RequestTypes } from '../hooks/useRequest'

export const HELPDESK_API_PREFIX = '/api/helpdesk/v1/'
export const HELPDESK_INQUIRIES_USER = 'inquiries-user'
export const HELPDESK_INQUIRIES_ADMIN = 'inquiries-operator'
export const HELPDESK_INQUIRIES_APPROVE = 'inquiries-approve'
export const HELPDESK_INQUIRIES_CHAT = 'inquiries-chat'
export const HELPDESK_INQUIRIES_CANCEL = 'inquiries-cancel'
export const HELPDESK_APPEAL = 'inquiries'
export const HELPDESK_DICT = 'dict/topics'
export const HELPDESK_CANCEL_PAYMENT_REQUEST = 'cancel-payment'
export const HELPDESK_CONFIRM_PAYMENT_REQUEST = 'confirm-payment'
export const HELPDESK_APPEAL_BALANCE = 'payment-info'

export const getInquiresUser = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_INQUIRIES_USER}`,
    method: RequestTypes.post,
}

export const cancelAppealUser = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_INQUIRIES_CANCEL}`,
    method: RequestTypes.post,
}

export const getInquiresAdmin = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_INQUIRIES_ADMIN}`,
    method: RequestTypes.post,
}

export const sendMessage = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_INQUIRIES_CHAT}`,
    method: RequestTypes.post,
}

export const approveAppeal = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_INQUIRIES_APPROVE}`,
    method: RequestTypes.post,
}

export const getDictHelpdesk = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_DICT}`,
    method: RequestTypes.get,
}

export const sendAppealCfg = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_APPEAL}`,
    method: RequestTypes.post,
}

export const getUserAppeal = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_APPEAL}`,
    method: RequestTypes.get,
}

export const getAppealBalance = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_APPEAL_BALANCE}`,
    method: RequestTypes.get,
}

export const addTopicCfg = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_DICT}`,
    method: RequestTypes.post,
}

export const confirmPayment = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_CONFIRM_PAYMENT_REQUEST}`,
    method: RequestTypes.post,
}

export const editTopicCfg = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_DICT}`,
    method: RequestTypes.patch,
}
export const deleteTopicCfg = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_DICT}`,
    method: RequestTypes.delete,
}

export const candelPaymentReq = {
    link: `${HELPDESK_API_PREFIX}${HELPDESK_CANCEL_PAYMENT_REQUEST}`,
    method: RequestTypes.delete,
}

export interface AppealStatus {
    [key: number]: string
}

export const APPEAL_STATUS: AppealStatus = {
    1: 'Новая',
    2: 'На рассмотрении',
    4: 'На доработке (запрос инф.)',
    6: 'Исправлена',
    7: 'Отказано',
    9: 'Снята',
    254: 'Выполнена',
}
