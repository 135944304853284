import { useRef, useEffect, useMemo } from 'react'
import debounce from 'lodash/debounce'

export const useDebouncedRequest = (callback: (...args: any[]) => any, delay: number) => {
    const ref = useRef<(...args: any[]) => void>()

    useEffect(() => {
        ref.current = callback
    }, [callback])

    const debouncedCallback = useMemo(() => {
        function fn(...args: any[]) {
            ref.current?.(...args)
        }

        return debounce(fn, delay || 1000)
    }, [])

    return debouncedCallback
}

export default useDebouncedRequest
