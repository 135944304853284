import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Mail: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.6429 4H2.35714C2.1596 4 2 4.14219 2 4.31818V10.6818C2 10.8578 2.1596 11 2.35714 11H11.6429C11.8404 11 12 10.8578 12 10.6818V4.31818C12 4.14219 11.8404 4 11.6429 4ZM10.7411 5.08281L7.21987 7.52386C7.13281 7.58452 7.01116 7.58452 6.92411 7.52386L3.40179 5.08281C3.38851 5.07368 3.37877 5.06108 3.37393 5.04677C3.3691 5.03246 3.36941 5.01717 3.37483 5.00303C3.38024 4.98888 3.39049 4.9766 3.40413 4.96791C3.41777 4.95922 3.43412 4.95454 3.45089 4.95455H10.692C10.7087 4.95454 10.7251 4.95922 10.7387 4.96791C10.7524 4.9766 10.7626 4.98888 10.768 5.00303C10.7734 5.01717 10.7738 5.03246 10.7689 5.04677C10.7641 5.06108 10.7543 5.07368 10.7411 5.08281Z"
                    fill="white"
                />
            </svg>
        )}
    />
)
