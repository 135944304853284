// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardMain_Wi25h {\n  display: flex;\n  justify-content: space-between;\n}\n.cardHeader_RUxwI {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #223432;\n}\n.infoRow_aigx9 {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 16px;\n}\n.locationBlock_a7hTK {\n  margin-top: 17px;\n}\n.divider_UDsac {\n  height: 38px;\n  margin: 0 16px;\n}\n.freeGenerationAttributes_Bx5UQ {\n  margin-top: 16px;\n  width: max-content;\n  padding: 6px 12px;\n  border: 1px solid #a3c9ff;\n  border-radius: 12px;\n  font-size: 16px;\n  line-height: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/GeneratorCard/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,YAAA;EACA,cAAA;AAHJ;AAMA;EACI,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAJJ","sourcesContent":[".cardMain {\n    display: flex;\n    justify-content: space-between;\n}\n\n.cardHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #223432;\n}\n\n.infoRow {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 16px;\n}\n\n.locationBlock {\n    margin-top: 17px;\n}\n\n.divider {\n    height: 38px;\n    margin: 0 16px;\n}\n\n.freeGenerationAttributes {\n    margin-top: 16px;\n    width: max-content;\n    padding: 6px 12px;\n    border: 1px solid #a3c9ff;\n    border-radius: 12px;\n    font-size: 16px;\n    line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardMain": "cardMain_Wi25h",
	"cardHeader": "cardHeader_RUxwI",
	"infoRow": "infoRow_aigx9",
	"locationBlock": "locationBlock_a7hTK",
	"divider": "divider_UDsac",
	"freeGenerationAttributes": "freeGenerationAttributes_Bx5UQ"
};
export default ___CSS_LOADER_EXPORT___;
