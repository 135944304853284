import { hexToBinary } from '../helpers'
import { encode, btoa } from 'js-base64'
import { v4 as uuidv4 } from 'uuid'
import SignUtils from '../../utils/sign/SignUtils'

export const signBody = async (body: any, data: any) => {
    const request_id = uuidv4()
    const thumbprint = await SignUtils.getCertificateThumbprint(data.certificate_data.certificate)
    const encodedInfo = encode(JSON.stringify(body))
    const encoded = request_id + encodedInfo
    const preSignature = await SignUtils.SignCreate(thumbprint, encoded)
    const signature = btoa(hexToBinary(preSignature))
    return { request_id, signature }
}
