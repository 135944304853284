import { isUserAdmin } from '../../constants/approval'
import { CurrentTab, EUserCardTabs } from './types'

export const getTabs = (userRole: number): CurrentTab[] => {
    const tabs: CurrentTab[] = [EUserCardTabs.PROFILE]

    if (userRole !== 1 && !isUserAdmin(userRole)) {
        tabs.splice(1, 0, EUserCardTabs.PAYMENT, EUserCardTabs.PERSONAL)
    }

    // if (userRole === 3) {
    //     tabs.push('ГО пользователя')
    // }

    return tabs
}
