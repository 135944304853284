import { FC, useState } from 'react'
import { IGO } from '../../../GOActivation/components/AddEditGO/AddEditGO'
import { SearchGOOwner, AddEditGO } from '../../../GOActivation/components'
import SelectedTsoGp2 from '../SelectedTsoGp2/SelectedTsoGp2'
import SelectedIPGO, { IIPGO } from '../SelectedIPGO/SelectedIPGO'
import { EUserType } from '../../../../constants/approval'

interface ISelectedGO {
    value?: string
    initialGO?: IGO | IIPGO
    onChange?(value?: { uid_go_owner: string; is_uid_go_owner_draft: boolean }): void
}

const SelectedGO: FC<ISelectedGO> = ({ onChange, initialGO }) => {
    const [selectedGo, setSelectedGo] = useState<IGO | IIPGO | undefined>(initialGO)
    const [openAddEditGo, setOpenAddEditGo] = useState(false)

    const [isSearchGOModalVisible, setIsSearchGOModalVisible] = useState(false)
    const openSearchGOModal = () => {
        setIsSearchGOModalVisible(true)
    }

    return (
        <>
            {!selectedGo || selectedGo.user_type === EUserType.UrLico ? (
                <SelectedTsoGp2
                    label="Владелец ГО"
                    required
                    selectedTsoGp={selectedGo as IGO | undefined}
                    onEditClick={() => setOpenAddEditGo(true)}
                    onRemoveClick={() => {
                        setSelectedGo(undefined)
                        onChange?.(undefined)
                    }}
                    onSearchClick={openSearchGOModal}
                    onManualEditClick={() => setOpenAddEditGo(true)}
                />
            ) : selectedGo.user_type === EUserType.IP ? (
                <SelectedIPGO
                    label="Владелец ГО"
                    required
                    selectedGO={selectedGo as IIPGO}
                    onEditClick={() => setOpenAddEditGo(true)}
                    onRemoveClick={() => {
                        setSelectedGo(undefined)
                        onChange?.(undefined)
                    }}
                    onSearchClick={openSearchGOModal}
                    onManualEditClick={() => setOpenAddEditGo(true)}
                />
            ) : null}
            <SearchGOOwner
                isModalVisible={isSearchGOModalVisible}
                onCancel={() => setIsSearchGOModalVisible(false)}
                onSelect={(go) => {
                    setSelectedGo(go)
                    setIsSearchGOModalVisible(false)
                    onChange?.({ uid_go_owner: go.uid, is_uid_go_owner_draft: false })
                }}
                key={String(isSearchGOModalVisible)}
            />
            <AddEditGO
                isModalVisible={openAddEditGo}
                onCancel={() => setOpenAddEditGo(false)}
                onChange={(go) => {
                    setOpenAddEditGo(false)
                    setSelectedGo(go)
                    onChange?.({ uid_go_owner: go.uid, is_uid_go_owner_draft: true })
                }}
                key={String(openAddEditGo)}
            />
        </>
    )
}

export default SelectedGO
