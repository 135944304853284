// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerMain_zeoyN {\n  width: 100%;\n  height: 77px;\n  padding: 24px 0px;\n  background: #fff;\n  box-shadow: 0px 2px 12px rgba(40, 97, 91, 0.07);\n}\n.container_cuKwa {\n  width: 1300px;\n  padding: 0 10px;\n  margin: 0 auto;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.link_NhBQ4 {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/style.m.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,+CAAA;AACJ;AAEA;EACI,aAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAGA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;AADJ","sourcesContent":[".headerMain {\n    width: 100%;\n    height: 77px;\n    padding: 24px 0px;\n    background: #fff;\n    box-shadow: 0px 2px 12px rgba(40, 97, 91, 0.07);\n}\n\n.container {\n    width: 1300px;\n    padding: 0 10px;\n    margin: 0 auto;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.link {\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerMain": "headerMain_zeoyN",
	"container": "container_cuKwa",
	"link": "link_NhBQ4"
};
export default ___CSS_LOADER_EXPORT___;
