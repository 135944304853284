// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editBlock_k8skY {\n  display: flex;\n}\n.editBlockMargin_MQe9A {\n  display: flex;\n  margin-top: 16px;\n}\n.label_DNqk2 {\n  text-align: right;\n  width: 200px;\n  margin-right: 16px;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n.star_lBrnW {\n  margin-left: 4px;\n  display: inline;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AddEditGO/components/CommonBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEI;EACI,aAAA;EAEA,gBAAA;AADR;AAKA;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAHJ;AAMA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AAJJ","sourcesContent":[".editBlock {\n    display: flex;\n    // align-items: center;\n\n    &Margin {\n        display: flex;\n        // align-items: center;\n        margin-top: 16px;\n    }\n}\n\n.label {\n    text-align: right;\n    width: 200px;\n    margin-right: 16px;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.star {\n    margin-left: 4px;\n    display: inline;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editBlock": "editBlock_k8skY",
	"editBlockMargin": "editBlockMargin_MQe9A",
	"label": "label_DNqk2",
	"star": "star_lBrnW"
};
export default ___CSS_LOADER_EXPORT___;
