// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader_mYeZV {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 24px;\n}\n.buttonsBlock_Ykp0u {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.textArea_tu2DN {\n  width: 436px;\n  margin-bottom: 24px;\n  margin-top: 3px;\n  border-radius: 8px;\n}\n.activationAlert_VK9hQ {\n  border-radius: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ZDApprovalItem/components/CancelCoordinateModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAEA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;AAGA;EACI,YAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AADJ;AAIA;EACI,mBAAA;AAFJ","sourcesContent":[".modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 24px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.textArea {\n    width: 436px;\n    margin-bottom: 24px;\n    margin-top: 3px;\n    border-radius: 8px;\n}\n\n.activationAlert {\n    border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "modalHeader_mYeZV",
	"buttonsBlock": "buttonsBlock_Ykp0u",
	"textArea": "textArea_tu2DN",
	"activationAlert": "activationAlert_VK9hQ"
};
export default ___CSS_LOADER_EXPORT___;
