import { FC } from 'react'
import { message } from 'antd'
import { SuccessMessage } from '../../../Edit/components'
import { CenteredSpin, Modal, PrimaryButton, PrimaryIconButton } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import request from '../../../../services/request'
import { QuestionCircle } from '../../../../assets/icons'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'
import { EUserRole } from '../../../../constants/approval'

interface IDeregisterModal {
    isModalVisible: boolean
    goName?: string
    deregisterDate?: string
    onClose: () => void
    onReload: () => void
}

const DeregisterModal: FC<IDeregisterModal> = ({
    isModalVisible,
    goName,
    deregisterDate,
    onClose,
    onReload,
}) => {
    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()

    const isUserLoading = useAppSelector((state) => state.user.isLoading)

    const userData = useAppSelector((state) => state.user.data)
    const userRole = userData.user_role
    const isGOOwner = userRole === EUserRole.GOOwner

    const handleConfirm = async () => {
        try {
            await request.get(`/api/generators/v1/go-deregister-decline?generator_id=${id}`)
            onClose()
            message.success({
                content: (
                    <SuccessMessage
                        header={t('detailedCard.success')}
                        message={`Снятие ГО с учета ${moment(deregisterDate).format(
                            'DD.MM.YYYY',
                        )} успешно отменено`}
                    />
                ),
                icon: <></>,
            })
            onReload()
        } catch {
            message.error(
                `Ошибка!${
                    isGOOwner
                        ? ' Отменить снятие с учета может только тот кто его заплонировал!'
                        : ''
                }`,
            )
        }
    }

    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            {isUserLoading && <CenteredSpin />}
            <div className={style.wrapper}>
                <QuestionCircle />
                <div className={style.header}>Отменить снятие с учета</div>
            </div>
            <div className={style.text}>
                Вы действительно хотите отменить снятие с учета{' '}
                {moment(deregisterDate).format('DD.MM.YYYY')} для ГО {goName}?
            </div>
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    onClick={onClose}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryIconButton
                    size="large"
                    onClick={handleConfirm}
                    value="Да"
                    customStyle={{ padding: '8px 16px', marginLeft: '12px' }}
                />
            </div>
        </Modal>
    )
}

export default DeregisterModal
