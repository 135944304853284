import { IUserInfo } from './interfaces'

export const initialInfo = {
    action_type: 0,
    birthday: '2000-09-07T00:00:00Z',
    certificate_data: {
        address: '',
        common_name: '',
        sur_name: '',
        organization_name: '',
        serial_number: '',
    },
    citizenship: '',
    citizenship_type: 0,
    company_inn: '',
    company_name: '',
    company_name_short: '',
    company_position: '',
    document_comment: '',
    document_custom_name: '',
    document_custom_number: '',
    document_type: 0,
    documents: null,
    egrul_government_name: '',
    egrul_registration_date: '2000-09-07T00:00:00Z',
    email: '',
    email_status: 1,
    entrepreneur_registration_date: 0,
    entrepreneur_registration_name: '',
    first_name: '',
    id: 1,
    kpp: '',
    last_name: '',
    legal_address: '',
    login: '',
    middle_name: '',
    middle_name_absent: false,
    ogrn: '',
    passport_betrayed_code: '',
    passport_betrayed_name: '',
    passport_date: '2000-09-07T00:00:00Z',
    passport_number: '',
    passport_series: '',
    personal_inn: '',
    phone: '',
    post_address: '',
    post_address_matches: false,
    profile_comment: '',
    profile_status: 5,
    registration_address: '',
    snils: '',
    ts_created: 1660853351,
    uid: '',
    user_role: 2,
    user_type: 2,
    web: '',
} as IUserInfo
