import { Box } from '../../ui/Box'
import { Divider, Typography } from 'antd'
import { useSelector } from 'react-redux'
import IAppState from 'store/states'
import style from '../style.m.less'
import { renderDate } from '../../../utils/formatter'
import { PaperClipOutlined } from '@ant-design/icons'
import IconText from '../../ui/IconText/IconText'
import { slicedFileName } from '../../ui/Document/Document'
import classNames from 'classnames'
import { currencyFormatRu } from '../../helpers/format'

type Props = {
    chats: any[]
    inquire: any
    isClient: boolean
}

const AppealChat = ({ chats, isClient, inquire }: Props) => {
    const userInfo = useSelector((store: IAppState) => store.user)
    const { data } = userInfo

    const NeedPay = ({ arg }: any) => {
        if (!arg.payment) {
            return <></>
        }
        return (
            <Box>
                <Typography>
                    За требуемую операцию необходимо внести оплату в размере{' '}
                    <Typography.Text strong>{currencyFormatRu(arg.payment.amount)}</Typography.Text>
                </Typography>
                <Box>{arg.message}</Box>
            </Box>
        )
    }

    return (
        <>
            <Divider />
            <Box padding="0px 32px">
                {chats?.map((el: any) => (
                    <>
                        <Box
                            className={classNames(
                                !isClient && el.chat_type_id === 2 && style.messageBoxOut,
                                !isClient && el.chat_type_id === 1 && style.messageBoxInner,
                                isClient && el.chat_type_id === 2 && style.messageBoxInner,
                                isClient && el.chat_type_id === 1 && style.messageBoxOut,
                            )}
                        >
                            <Box direction="row" justify="space-between">
                                <Typography.Text strong>
                                    {el.chat_type_id === 2
                                        ? 'Оператор'
                                        : !isClient
                                        ? `${inquire.user.first_name} ${inquire.user.last_name}`
                                        : `${data.first_name} ${data.last_name}`}
                                </Typography.Text>
                                <Typography.Text type="secondary">
                                    {renderDate(el.ts_created)}
                                </Typography.Text>
                            </Box>
                            <Box margin="10px 0 0 0">
                                {el.payment ? (
                                    <NeedPay arg={el} />
                                ) : (
                                    <Typography.Text>{el.message}</Typography.Text>
                                )}
                            </Box>
                            {el.documents && (
                                <Box margin="20px 0 0 0">
                                    {el.documents.map((doc: any) => (
                                        <IconText
                                            text={slicedFileName(doc.file_name, 30)}
                                            icon={<PaperClipOutlined />}
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>
                        {[2, 4].includes(el.payment?.payment_status) && (
                            <Box
                                className={classNames(
                                    !isClient && el.chat_type_id === 1 && style.messageBoxOut,
                                    !isClient && el.chat_type_id === 2 && style.messageBoxInner,
                                    isClient && el.chat_type_id === 1 && style.messageBoxInner,
                                    isClient && el.chat_type_id === 2 && style.messageBoxOut,
                                )}
                            >
                                <Box direction="row" justify="space-between">
                                    <Typography.Text strong>
                                        {!isClient
                                            ? `${inquire.user.first_name} ${inquire.user.last_name}`
                                            : `${data.first_name} ${data.last_name}`}
                                    </Typography.Text>
                                    <Typography.Text type="secondary">
                                        {renderDate(el.ts_created)}
                                    </Typography.Text>
                                </Box>
                                <Box margin="10px 0 0 0">
                                    <Typography.Text>
                                        {el.payment.payment_status === 2 ? (
                                            'Отказ от оплаты.'
                                        ) : el.payment.payment_status === 3 ? (
                                            'Оплата не прошла.'
                                        ) : (
                                            <Box>
                                                <Typography.Text>Операция оплачена</Typography.Text>
                                                <Typography.Text strong>
                                                    {currencyFormatRu(el.payment.amount)}
                                                </Typography.Text>
                                            </Box>
                                        )}
                                    </Typography.Text>
                                </Box>
                            </Box>
                        )}
                    </>
                ))}
            </Box>
        </>
    )
}

export default AppealChat
