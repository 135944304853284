import { FC, useState } from 'react'
import { Input } from 'antd'

import style from './style.m.less'
import { DropdownSelector2 } from '../../../ui'
import { useTranslation } from 'react-i18next'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { EStatus, statusMap } from '../../../../constants/approval'

const statusTypeOptions = [
    {
        id: EStatus.All,
        value: EStatus.All,
        text: statusMap.get(EStatus.All),
    },
    {
        id: EStatus.New,
        value: EStatus.New,
        text: statusMap.get(EStatus.New),
    },
    {
        id: EStatus.UnderConsideration,
        value: EStatus.UnderConsideration,
        text: statusMap.get(EStatus.UnderConsideration),
    },
    // {
    //     id: EStatus.UnderRevision,
    //     value: EStatus.UnderRevision,
    //     text: statusMap.get(EStatus.UnderRevision),
    // },
    {
        id: EStatus.Corrected,
        value: EStatus.Corrected,
        text: statusMap.get(EStatus.Corrected),
    },
    // {
    //     id: EStatus.Agreed,
    //     value: EStatus.Agreed,
    //     text: statusMap.get(EStatus.Agreed),
    // },
    // {
    //     id: EStatus.Rejected,
    //     value: EStatus.Rejected,
    //     text: statusMap.get(EStatus.Rejected),
    // },
    // {
    //     id: EStatus.UserCanceled,
    //     value: EStatus.UserCanceled,
    //     text: statusMap.get(EStatus.UserCanceled),
    // },
]

interface IMoreFilter {
    onChangeStatusType: (actionType: EStatus) => void
    onChangeOgrn: (ogrn: string) => void
}

const MoreFilters: FC<IMoreFilter> = (props) => {
    const { t } = useTranslation()

    const [isMoreFilterVisible, setIsMoreFilterVisible] = useState(false)
    const showMoreFilter = () => {
        setIsMoreFilterVisible(!isMoreFilterVisible)
    }

    return (
        <>
            {isMoreFilterVisible && (
                <div className={style.filter}>
                    <div className={style.moreFilterItem}>
                        <div className={style.label}>{t('questionnaireApproval.ogrn-ogrnip')}</div>
                        <Input
                            onChange={(event) => props.onChangeOgrn(event.target.value)}
                            placeholder={t('questionnaireApproval.typeOgrn')}
                            className={style.input}
                        />
                    </div>
                    <div className={style.moreFilterItem}>
                        <div className={style.label}>{t('questionnaireApproval.status')}</div>
                        <DropdownSelector2
                            onChange={props.onChangeStatusType}
                            options={statusTypeOptions}
                            className={style.select}
                        />
                    </div>
                </div>
            )}
            <div className={style.moreFilter} onClick={showMoreFilter}>
                {!isMoreFilterVisible && <span>{t('questionnaireApproval.moreFilters')}</span>}
                {isMoreFilterVisible && <span>{t('questionnaireApproval.fewerFilters')}</span>}
                {!isMoreFilterVisible && <DownOutlined className={style.icon} />}
                {isMoreFilterVisible && <UpOutlined className={style.icon} />}
            </div>
        </>
    )
}

export default MoreFilters
