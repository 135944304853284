import { userRolePicker } from '../../../helpers'
import moment from 'moment'
import { IUserInfo } from '../../../../store/user/interfaces'

export const profileInfoConfig = (userData: IUserInfo) => {
    const {
        user_role,
        first_name,
        last_name,
        middle_name,
        login,
        email,
        phone,
        company_name,
        post_address,
        company_inn,
        kpp,
        company_name_short,
        ogrn,
        egrul_government_name,
        egrul_registration_date,
        web,
        legal_address,
        post_address_matches,
    } = userData

    return {
        visibleInfo: [
            {
                name: 'Роль в системе',
                value: userRolePicker(user_role),
            },
            {
                name: 'ФИО',
                value: `${last_name} ${first_name} ${middle_name}`,
            },
            {
                name: 'Логин',
                value: login,
            },
            {
                name: 'E-mail',
                value: email,
            },
            {
                name: 'Телефон',
                value: phone,
            },
        ],
        hiddenInfo: [
            {
                name: 'Полное название организации',
                value: company_name,
                noMargin: true,
            },
            {
                name: 'Сокращенное название организации',
                value: company_name_short,
            },
            {
                name: 'ИНН',
                value: company_inn,
            },
            {
                name: 'КПП',
                value: kpp,
            },
            {
                name: 'ОГРН',
                value: ogrn,
            },
            {
                name: 'Наименование гос. органа, осуществившего регистрацию в ЕГРЮЛ',
                value: egrul_government_name,
            },
            {
                name: 'Дата регистрации в ЕГРЮЛ',
                value: moment(egrul_registration_date).format('DD.MM.YYYY'),
            },
            {
                name: 'Телефон/факс организации',
                value: phone,
            },
            {
                name: 'Сайт',
                value: web,
            },
            {
                isDivider: true,
                noMargin: true,
            },
            {
                name: 'Юридический адрес',
                value: legal_address,
                noMargin: true,
            },
            {
                name: 'Почтовый адрес',
                value: post_address_matches ? legal_address : post_address,
            },
        ],
    }
}
