import { put, StrictEffect, takeLatest } from 'redux-saga/effects'
import { getAccountsData, updateAccounts } from './index'
import { getAccountsList } from '../../services/accounts'
import { errorHandler } from '../../components/helpers'

function* handleAccounts() {
    try {
        yield put(updateAccounts({ error: '', isLoading: true }))
        const { data, error } = yield getAccountsList()

        if (error) {
            throw new Error(errorHandler(error.err_code))
        }

        const { accounts } = data

        yield put(updateAccounts({ isLoading: false, accounts }))
    } catch (error: any) {
        yield put(updateAccounts({ error: error.message, isLoading: false }))
    }
}

export function* watchAccounts(): Generator<StrictEffect> {
    yield takeLatest(getAccountsData, handleAccounts)
}
