// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerNav_IldUN {\n  display: flex;\n  flex-direction: column;\n}\n.footerHeader_qPbL3 {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n}\n.navList_TbvRy {\n  display: flex;\n  flex-direction: column;\n  margin-top: 12px;\n}\n.navItem__lt43 {\n  color: #fff;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/components/FooterNav/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AADJ;AAIA;EACI,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".footerNav {\n    display: flex;\n    flex-direction: column;\n}\n\n.footerHeader {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n}\n\n.navList {\n    display: flex;\n    flex-direction: column;\n    margin-top: 12px;\n}\n\n.navItem {\n    color: #fff;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerNav": "footerNav_IldUN",
	"footerHeader": "footerHeader_qPbL3",
	"navList": "navList_TbvRy",
	"navItem": "navItem__lt43"
};
export default ___CSS_LOADER_EXPORT___;
