import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CenteredSpin, CloseModal } from '../ui'
import { Form, Alert } from 'antd'
import { SettingsBlock } from './components'
import { ButtonsBlock } from '../Edit/components'
import useRequest from '../hooks/useRequest'
import useMount from '../hooks/useMount'
import { useTranslation } from 'react-i18next'
import {
    getTariffsDictionary,
    getInitialConfig,
    auditTypesArray,
    payTypeIdPicker,
    frequencyIdPicker,
} from '../helpers'
import { getTariffsSettings, setTariffsSettings } from './config'
import style from './style.m.less'

const TariffsSettings: FC = () => {
    const { t } = useTranslation()
    const { fetch, result, isLoading } = useRequest(getTariffsSettings)
    const [isFormChanged, setIsFormChanged] = useState(false)
    const [isCloseModalVisible, setIsCloseModalVisible] = useState(false)
    const [disableConfirm, setDisableConfirm] = useState(false)
    const { fetch: sendSettings } = useRequest(setTariffsSettings)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)

    const navigate = useNavigate()
    const [form] = Form.useForm()

    useMount(() => {
        fetch({})
    })

    const onFormLayoutChange = () => {
        setIsFormChanged(true)

        setTimeout(() => {
            const hasErrors = !!form.getFieldsError().filter((field) => field.errors.length).length
            setIsSubmitDisabled(hasErrors)
        }, 0)
    }

    const openCloseModal = () => {
        if (isFormChanged) {
            setIsCloseModalVisible(true)
        } else {
            navigate(-1)
        }
    }

    if (isLoading || !result) {
        return <CenteredSpin />
    }

    //соответствие кода операции и наименования можно посмотреть в /helpers/billing
    const dict = getTariffsDictionary(result['payment-tariffs'])

    const initialConfig = getInitialConfig(result['payment-tariffs'])

    const onFinish = async (values: any) => {
        for (const item in values) {
            const split = item.split('-')
            const fieldValue =
                split[1] === 'pay_type_id'
                    ? payTypeIdPicker(values[item])
                    : split[1] === 'frequency_id'
                    ? frequencyIdPicker(values[item])
                    : values[item]
            dict[split[0]][split[1]] = fieldValue
        }

        if (!isCloseModalVisible) {
            sendSettings({
                body: { 'payment-tariffs': Object.values(dict) },
                successMessage: {
                    title: t('detailedCard.success'),
                    description: 'Настройки тарификации изменены',
                },
                errorCodeMessage: true,
                onSuccess: () => {
                    fetch({})
                    setIsFormChanged(false)
                },
            })
        }
    }

    const getBack = () => {
        navigate(-1)
    }

    const isConfirmDisabled = disableConfirm || !isFormChanged || isSubmitDisabled

    return (
        <div className={style.settingsMain}>
            <Form
                form={form}
                onFinish={onFinish}
                name="tariffSettingsForm"
                onValuesChange={onFormLayoutChange}
                initialValues={initialConfig}
            >
                <div className={style.settingsHeader}>Настройки тарификации</div>
                <div className={style.settingsFrame}>
                    <Alert
                        className={style.alert}
                        message={
                            <>
                                <div>
                                    Система позволяет ввести в настройке тарифа значение с 5 знаками
                                    после запятой (значение вводится без учета 20% НДС).
                                </div>
                                <div>
                                    При оплате будет отображаться значение с математическим
                                    округлением до 2 знаков после запятой.
                                </div>
                                <div>При оплате будет отображаться значение с учетом 20% НДС.</div>
                            </>
                        }
                        type="info"
                        showIcon
                    />
                    {auditTypesArray.map((item) => {
                        return (
                            <SettingsBlock
                                setDisableConfirm={setDisableConfirm}
                                config={item}
                                data={dict[item.name]}
                            />
                        )
                    })}
                    <ButtonsBlock
                        disableCondition={isConfirmDisabled}
                        cancelAction={openCloseModal}
                    />
                </div>
            </Form>
            <CloseModal
                isModalVisible={isCloseModalVisible}
                setIsModalVisible={setIsCloseModalVisible}
                headerText={'Выйти без сохранения?'}
                messageText={
                    'Для того, чтобы внесенные изменения вступили в силу, необходимо сохранить их'
                }
                confirmTitle={'Выйти без сохранения'}
                cancelTitle={'Отмена'}
                confirmAction={getBack}
            />
        </div>
    )
}

export default TariffsSettings
