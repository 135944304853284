// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper_p3oTL {\n  margin-bottom: 16px;\n}\n.headerWrapper_hhjRB {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 16px;\n}\n.header_B_hyw {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.list_Supxc {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  width: 40%;\n}\n.listItem_HHoM2 {\n  display: flex;\n  align-items: center;\n}\n.listItem_HHoM2:not(:last-child) {\n  margin-bottom: 8px;\n}\n.listItemKey_CZqvU {\n  color: #8C8C8C;\n  flex-shrink: 0;\n  width: 140px;\n}\n.alert_JU7DC {\n  width: 70%;\n  margin-top: 24px;\n}\n.bottom_JY418 {\n  display: flex;\n  justify-content: end;\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContinueRegistration/components/SendNotificationUser/style.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,gBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;AAFJ;AAKA;EACI,aAAA;EACA,mBAAA;AAHJ;AAOA;EACI,kBAAA;AALJ;AAQA;EACI,cAAA;EACA,cAAA;EACA,YAAA;AANJ;AASA;EACI,UAAA;EACA,gBAAA;AAPJ;AAUA;EACI,aAAA;EACA,oBAAA;EACA,gBAAA;AARJ","sourcesContent":[".modalWrapper {\n    margin-bottom: 16px;\n}\n\n.headerWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 16px;\n}\n\n.header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.list {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n    width: 40%;\n}\n\n.listItem {\n    display: flex;\n    align-items: center;\n    // justify-content: space-between;\n}\n\n.listItem:not(:last-child) {\n    margin-bottom: 8px;\n}\n\n.listItemKey {\n    color: #8C8C8C;\n    flex-shrink: 0;\n    width: 140px;\n}\n\n.alert {\n    width: 70%;\n    margin-top: 24px;\n}\n\n.bottom {\n    display: flex;\n    justify-content: end;\n    margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper_p3oTL",
	"headerWrapper": "headerWrapper_hhjRB",
	"header": "header_B_hyw",
	"list": "list_Supxc",
	"listItem": "listItem_HHoM2",
	"listItemKey": "listItemKey_CZqvU",
	"alert": "alert_JU7DC",
	"bottom": "bottom_JY418"
};
export default ___CSS_LOADER_EXPORT___;
