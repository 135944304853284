import { FC, useEffect } from 'react'
import { HeaderBlock, GeneratorInfoFrame, LoadingContainer } from '../ui'
import { Statistics, ObjectsList } from './components'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import moment from 'moment'
import { IUserInfo } from '../../store/user/interfaces'
import { getDictionaries } from '../../store/dictionaries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ELoading } from '../../store/dictionaries/interfaces'
import { EUserType, EUserRole } from '../../constants/approval'

export const profileInfoConfig = (userData: IUserInfo) => {
    const {
        company_name,
        personal_inn,
        company_inn,
        egrul_government_name,
        egrul_registration_date,
        email,
        phone,
        web,
        legal_address,
        post_address_matches,
        post_address,
        user_type,
        company_name_short,
        first_name,
        last_name,
        middle_name,
        ogrn,
        registration_address,
    } = userData

    const name =
        user_type === EUserType.UrLico
            ? company_name_short
            : user_type === EUserType.IP
            ? `ИП ${last_name} ${first_name} ${middle_name}`
            : `${last_name} ${first_name} ${middle_name}`

    return {
        visibleInfo: [
            {
                // Полное наименование
                name: 'Полное название организации',
                value: company_name || name,
            },
            user_type === 2
                ? {
                      name: 'ownerCard.company_inn',
                      value: company_inn,
                      translate: true,
                  }
                : {
                      name: 'ownerCard.personal_inn',
                      value: personal_inn,
                      translate: true,
                  },
            user_type === 2
                ? {
                      // ОГРН / ОГРНИП
                      name: 'ownerCard.ogrn',
                      value: ogrn,
                      translate: true,
                  }
                : {
                      name: 'ownerCard.ogrn_ip',
                      value: ogrn,
                      translate: true,
                  },
            {
                name: 'ownerCard.egrul_government_name',
                value: egrul_government_name,
                translate: true,
            },
            {
                name: 'ownerCard.egrul_registration_date',
                value: moment(egrul_registration_date).format('DD.MM.YYYY'),
                translate: true,
            },
/*
            {
                name: 'ownerCard.phone',
                value: phone,
                translate: true,
            },
            {
                name: 'ownerCard.email',
                value: email,
                translate: true,
            },
*/
            {
                name: 'ownerCard.web',
                value: web,
                redirect: { path: web },
                translate: true,
            },
            {
                isDivider: true,
            },
            {
                name: 'ownerCard.registration_address',
                value: registration_address || (post_address_matches ? post_address : ''),
                noMargin: true,
                translate: true,
            },
            {
                name: 'Юридический адрес',
                value: legal_address,
            },
            {
                name: 'ownerCard.post_address',
                value: post_address_matches ? legal_address : post_address,
                translate: true,
            },
        ],
    }
}

const OwnerCard: FC = () => {
    const { t } = useTranslation()
    const { id } = useParams<{ id?: string }>()

    const userRole = useAppSelector((state) => state.user.data.user_role)

    const isAdmin = [EUserRole.Administrator, EUserRole.SuperAdmin].includes(userRole)

    const isLoading = useAppSelector((state) => state.dictionaries.loading)

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (isLoading !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    }, [])

    const {
        fetch: getOwner,
        result: owner,
        isLoading: isLoadingOwner,
    } = useRequest({
        link: `/api/users/v1/profile-go-owner`,
        method: RequestTypes.get,
    })

    useEffect(() => {
        getOwner({
            addToLink: `?id=${id}`,
            body: {},
            errorCodeMessage: true,
        })
    }, [id])

    const { user_type, company_name_short, first_name, last_name, middle_name } = owner || {}
    const name =
        user_type === EUserType.UrLico
            ? company_name_short
            : user_type === EUserType.IP
            ? `ИП ${last_name} ${first_name} ${middle_name}`
            : `${last_name} ${first_name} ${middle_name}`

    return (
        <LoadingContainer isLoading={isLoadingOwner || !owner}>
            <div className={style.ownerCardMain}>
                <HeaderBlock label={name || ''} />
                <div className={style.infoFrame}>
                    {owner && (
                        <GeneratorInfoFrame
                            customStyle={{ marginTop: '32px' }}
                            label={t('ownerCard.commonInfo')}
                            config={profileInfoConfig(owner)}
                        />
                    )}
                </div>
                <Statistics />
                <ObjectsList ownerName={name} />
            </div>
        </LoadingContainer>
    )
}

export default OwnerCard
