import { Dispatch, FC, SetStateAction, useState } from 'react'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal, DocumentsBlock, Box } from '../../../ui'
import { Alert, Input, Divider, message } from 'antd'
import style from './style.m.less'
import { useNavigate } from 'react-router-dom'
import { blockGreenTool, unblockGreenTool } from './config'
import useRequest from '../../../hooks/useRequest'

const { TextArea } = Input

interface IBlockModal {
    modalStatus: { isVisible: boolean; isUnblock: boolean }
    setModalStatus: Dispatch<
        SetStateAction<{
            isVisible: boolean
            isUnblock: boolean
        }>
    >
    contractId: string
}

const BlockModal: FC<IBlockModal> = ({ modalStatus, setModalStatus, contractId }) => {
    const navigate = useNavigate()
    const { isUnblock } = modalStatus
    const { fetch: fetchBlock } = useRequest(blockGreenTool)
    const { fetch: fetchUnblock } = useRequest(unblockGreenTool)

    const [files, setFiles] = useState<any[]>([])
    const [reason, setReason] = useState('')

    const fileHandler = (list: any) => {
        setFiles(list)
    }

    const handleCancel = () => {
        setModalStatus({ isVisible: false, isUnblock: modalStatus.isUnblock })
    }

    const handleConfirm = async () => {
        if (!reason && !isUnblock) {
            message.error('Укажите основание')
        } else {
            const body = isUnblock
                ? {
                      contract_id: contractId,
                      reason,
                      documents: files,
                  }
                : {
                      contract_id: contractId,
                      reason,
                      documents: files,
                  }

            if (isUnblock) {
                fetchUnblock({
                    body,
                    successMessage: {
                        title: 'Успешно',
                        description: `Зеленый договор успешно ${
                            isUnblock ? 'разблокирован' : 'заблокирован'
                        }`,
                    },
                    onSuccess: () => navigate('/green-tools'),
                    errorCodeMessage: true,
                })
            } else {
                fetchBlock({
                    body,
                    successMessage: {
                        title: 'Успешно',
                        description: `Зеленый договор успешно ${
                            isUnblock ? 'разблокирован' : 'заблокирован'
                        }`,
                    },
                    onSuccess: () => navigate('/green-tools'),
                    errorCodeMessage: true,
                })
            }
        }
    }

    return (
        <Modal isModalVisible={modalStatus.isVisible}>
            <div className={style.mainWrapper}>
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>
                        {isUnblock ? 'Снятие блокировки' : 'Блокировка зеленого договора'}
                    </div>
                    <Alert
                        className={style.modalAlert}
                        message={
                            isUnblock
                                ? 'Укажите основание для снятия блокировки, если это необходимо'
                                : 'Укажите основание для блокировки. Пользователь увидит его в уведомлении о блокировке'
                        }
                        type="info"
                        showIcon
                    />
                    <Box style={{ marginTop: '24px' }}>
                        <Box direction="row">
                            <div>Основание</div>
                            {!isUnblock && <div className={style.star}>*</div>}
                        </Box>
                        <TextArea
                            placeholder="Ваш комментарий"
                            className={style.textArea}
                            rows={4}
                            onChange={(event) => setReason(event.target.value)}
                        />
                    </Box>
                    <Divider />
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={4}
                        description="Загрузите необходимые документы"
                    />
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            isDelete={!isUnblock}
                            confirmAction={handleConfirm}
                            cancelAction={handleCancel}
                            confirmTitle={isUnblock ? 'Разблокировать' : 'Заблокировать'}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default BlockModal
