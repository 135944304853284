// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputsWrapperShort_cXYlf {\n  width: 608px;\n  margin-top: 16px;\n  margin-left: 216px;\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n}\n.star_QmSy0 {\n  margin-left: 4px;\n  display: flex;\n  align-items: center;\n  color: #f5222d;\n}\n.inputBlock_ixEyF {\n  display: flex;\n}\n.inputMiddle_Cn0A6 {\n  width: 192px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContinueRegistration/components/ThreePartsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;AAEA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;AADJ;AAIA;EACI,YAAA;EACA,kBAAA;AAFJ","sourcesContent":[".inputsWrapperShort {\n    width: 608px;\n    margin-top: 16px;\n    margin-left: 216px;\n    display: flex;\n    justify-content: space-between;\n    gap: 16px;\n}\n\n.star {\n    margin-left: 4px;\n    display: flex;\n    align-items: center;\n    color: #f5222d;\n}\n\n.inputBlock {\n    display: flex;\n}\n\n.inputMiddle {\n    width: 192px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsWrapperShort": "inputsWrapperShort_cXYlf",
	"star": "star_QmSy0",
	"inputBlock": "inputBlock_ixEyF",
	"inputMiddle": "inputMiddle_Cn0A6"
};
export default ___CSS_LOADER_EXPORT___;
