import { FC, ReactElement } from 'react'
import moment from 'moment'

import { IData } from './data'

const DateCell: FC<{ date: Date }> = ({ date }) => {
    return <>{moment(date).format('DD.MM.YYYY')}</>
}

const IsNewCell: FC<{ isNew?: boolean; children: ReactElement }> = ({ isNew, children }) =>
    isNew ? <strong>{children}</strong> : children

const render = (value: any, record: IData) => (
    <IsNewCell isNew={record.isNew}>{value || '-'}</IsNewCell>
)

export const columns = [
    {
        title: 'Дата события',
        dataIndex: 'date',
        key: 'date',
        render: (date: Date, record: IData) => render(<DateCell date={date} />, record),
    },
    {
        title: 'Тип события',
        dataIndex: 'eventType',
        key: 'eventType',
        render: render,
    },
    {
        title: 'Исполнитель',
        dataIndex: 'creatorName',
        key: 'creatorName',
        render,
    },
    {
        title: 'Роль пользователя (исполнителя)',
        dataIndex: 'creatorRole',
        key: 'creatorRole',
        render,
    },
    {
        title: 'Адресат действия',
        dataIndex: 'recipientName',
        key: 'recipientName',
        render,
    },
    {
        title: 'Роль пользователя (адресата действия)',
        dataIndex: 'recipientRole',
        key: 'recipientRole',
        render,
    },
]
