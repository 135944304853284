import moment from 'moment'
import { userRoleMap } from '../../../../constants/approval'

export const legalPersonInfoConfig = (profile: any, nextProfile: any) => [
    {
        name: 'Роль в системе',
        value: userRoleMap.get(profile.user_role),
        blur: !!nextProfile && profile.user_role !== nextProfile.user_role,
    },
    {
        name: 'Тип пользователя',
        value: 'Юридическое лицо',
        blur: !!nextProfile && profile.user_type !== nextProfile.user_type,
    },
    {
        name: 'ФИО',
        value: `${profile.last_name} ${profile.first_name} ${profile.middle_name}`,
        blur:
            !!nextProfile &&
            (profile.last_name !== nextProfile.last_name ||
                profile.first_name !== nextProfile.first_name ||
                profile.middle_name !== nextProfile.middle_name),
    },
    {
        name: 'Логин',
        value: profile.login,
        blur: !!nextProfile && profile.login !== nextProfile.login,
    },
    {
        name: 'E-mail',
        value: profile.email,
        blur: !!nextProfile && profile.email !== nextProfile.email,
    },
    {
        isDivider: true,
        noMargin: true,
    },
    {
        name: 'Полное название организации',
        value: profile.company_name,
        blur: !!nextProfile && profile.company_name !== nextProfile.company_name,
    },
    {
        name: 'Сокращенное название организации',
        value: profile.company_name_short,
        blur: !!nextProfile && profile.company_name_short !== nextProfile.company_name_short,
    },
    {
        name: 'ИНН',
        value: profile.company_inn,
        blur: !!nextProfile && profile.company_inn !== nextProfile.company_inn,
    },
    {
        name: 'КПП',
        value: profile.kpp,
        blur: !!nextProfile && profile.kpp !== nextProfile.kpp,
    },
    {
        name: 'ОГРН',
        value: profile.ogrn,
        blur: !!nextProfile && profile.ogrn !== nextProfile.ogrn,
    },
    {
        name: 'Наименование гос. органа, осуществившего регистрацию в ЕГРЮЛ',
        value: profile.egrul_government_name,
        blur: !!nextProfile && profile.egrul_government_name !== nextProfile.egrul_government_name,
    },
    {
        name: 'Дата регистрации в ЕГРЮЛ',
        value:
            profile.egrul_registration_date &&
            moment(profile.egrul_registration_date).format('DD.MM.YYYY'),
        blur:
            !!nextProfile &&
            profile.egrul_registration_date !== nextProfile.egrul_registration_date,
    },
    {
        code: true,
        name: 'Коды ГТП потребления',
        value: profile.point_delivery_codes_consuming,
        blur: !!nextProfile && ((profile.point_delivery_codes_consuming + '') !== (nextProfile.point_delivery_codes_consuming + '')),
    },
    {
        name: 'Должность',
        value: profile.company_position,
        blur: !!nextProfile && profile.company_position !== nextProfile.company_position,
    },
    {
        name: 'Телефон / факс',
        value: profile.company_phone || profile.phone,
        blur: !!nextProfile && profile.company_phone !== nextProfile.company_phone,
    },
    {
        name: 'Сайт',
        value: profile.web,
        blur: !!nextProfile && profile.web !== nextProfile.web,
    },
    {
        isDivider: true,
        noMargin: true,
    },
    {
        name: 'Юридический адрес',
        value: profile.legal_address,
        blur: !!nextProfile && profile.legal_address !== nextProfile.legal_address,
        noMargin: true,
    },
    {
        name: 'Почтовый адрес',
        value: profile.post_address_matches ? profile.legal_address : profile.post_address,
        blur:
            !!nextProfile &&
            (profile.post_address_matches !== nextProfile.post_address_matches ||
                (profile.post_address_matches &&
                    profile.legal_address !== nextProfile.legal_address) ||
                (!profile.post_address_matches &&
                    profile.post_address !== nextProfile.post_address)),
    },
]
