import { CSSProperties, forwardRef, PropsWithChildren } from 'react'

export type BoxProps = {
    padding?: string
    margin?: string
    direction?: CSSProperties['flexDirection']
    align?: CSSProperties['alignItems']
    justify?: CSSProperties['justifyContent']
    flex?: CSSProperties['flex']
    width?: CSSProperties['width']
    height?: CSSProperties['height']
    maxHeight?: CSSProperties['maxHeight']
    minHeight?: CSSProperties['minHeight']
    overflow?: CSSProperties['overflow']
    position?: CSSProperties['position']
    gap?: CSSProperties['gap']
    className?: string
    dataTestID?: string
    display?: CSSProperties['display']
    borderRadius?: CSSProperties['borderRadius']
    flexWrap?: CSSProperties['flexWrap']
    style?: CSSProperties
    onClick?: () => void
}

export const Box = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
    (
        {
            padding = '0px',
            margin = '0px',
            direction = 'column',
            justify = 'normal',
            align = 'stretch',
            children,
            width,
            height,
            maxHeight,
            minHeight,
            flex,
            overflow,
            className,
            dataTestID,
            position,
            gap,
            flexWrap,
            display = 'flex',
            onClick,
            style,
        },
        ref,
    ) => {
        const styles = {
            padding,
            margin,
            display,
            flexDirection: direction,
            justifyContent: justify,
            alignItems: align,
            gap,
            width,
            height,
            maxHeight,
            minHeight,
            overflow,
            flex,
            position,
            flexWrap,
        }

        return (
            <div
                ref={ref}
                style={style || styles}
                className={className}
                data-testid={dataTestID}
                onClick={onClick && onClick}
            >
                {children}
            </div>
        )
    },
)
