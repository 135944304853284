// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerBlock_RgF98 {\n  display: flex;\n  align-items: center;\n}\n.backButton_gLLNE {\n  padding-left: 0;\n  border: none;\n  cursor: pointer;\n  transition: all 0.2s ease-in;\n}\n.backButton_gLLNE:hover {\n  color: #296dff;\n}\n.mainHeader_VPaxt {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  margin-left: 13px;\n  font-family: 'Raleway';\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/HeaderBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AAEA;EACI,eAAA;EACA,YAAA;EACA,eAAA;EACA,4BAAA;AAAJ;AAEI;EACI,cAAA;AAAR;AAIA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,sBAAA;AAFJ","sourcesContent":[".headerBlock {\n    display: flex;\n    align-items: center;\n}\n\n.backButton {\n    padding-left: 0;\n    border: none;\n    cursor: pointer;\n    transition: all 0.2s ease-in;\n\n    &:hover {\n        color: #296dff;\n    }\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    margin-left: 13px;\n    font-family: 'Raleway';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerBlock": "headerBlock_RgF98",
	"backButton": "backButton_gLLNE",
	"mainHeader": "mainHeader_VPaxt"
};
export default ___CSS_LOADER_EXPORT___;
