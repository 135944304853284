import { createAction, createSlice } from '@reduxjs/toolkit'
import { initialInfo } from './resources'
import { IUserInfo } from './interfaces'

export interface IUserState {
    data: IUserInfo
    actualData: any
    isLoading: boolean
    error?: string
}

const initialState = {
    isLoading: true,
    data: initialInfo,
    actualData: initialInfo,
} as IUserState

const userInfo = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        getUserInfo: (state) => {
            return state
        },
        updateUserInfo: (state, action) => {
            return { ...state, ...action.payload }
        },
        dropUserInfo: () => {
            return { ...initialState }
        },
    },
})

export const getUser = createAction('userInfo/getUserInfo')

export const { getUserInfo, updateUserInfo, dropUserInfo } = userInfo.actions

export default userInfo.reducer
