import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'antd'
import { ModalType } from '../../../../types'
import { QuestionCircle } from '../../../../../../assets/icons'
import { ButtonsBlock } from '../../../../../Edit/components'
import { Modal } from '../../../../../ui'
import style from './style.m.less'

interface IAccountModal {
    type: ModalType
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const AccountModal: FC<IAccountModal> = ({ type, isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const typeConfig =
        type === 'rename'
            ? { headerName: 'Переименовать лицевой счет', confirmTitle: 'Переименовать' }
            : type === 'setMain'
            ? {
                  headerName: 'Назначить счет главным',
                  confirmTitle: 'Подтвердить',
                  text: 'Вы хотите назначить счет №234567 главным для зачисления ЗИ от других пользователей СКИЗИ?',
              }
            : type === 'closeWithZI'
            ? {
                  headerName: 'Закрыть лицевой счет',
                  confirmTitle: 'Закрыть',
                  text: 'На лицевом счету №234567 остались ЗИ, при его закрытии они будут заблокированы. Вы действительно хотите удалить счет? Отменить это действие будет невозможно.',
              }
            : {
                  headerName: 'Закрыть лицевой счет',
                  confirmTitle: 'Закрыть',
                  text: 'Вы действительно хотите закрыть счет №234567? Отменить это действие будет невозможно.',
              }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleConfirm = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.mainWrapper}>
                <QuestionCircle />
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>{typeConfig.headerName}</div>
                    {type === 'rename' ? (
                        <div className={style.inputBlock}>
                            <div>{t('myProfile.appellation')}</div>
                            <Input className={style.input} />
                        </div>
                    ) : (
                        <div className={style.text}>{typeConfig.text}</div>
                    )}
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            isDelete={type === 'delete' || type === 'closeWithZI'}
                            confirmAction={handleConfirm}
                            cancelAction={handleOk}
                            confirmTitle={typeConfig.confirmTitle}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AccountModal
