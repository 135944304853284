// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_iUq1D {\n  font-family: 'Raleway';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  font-feature-settings: \"pnum\";\n  color: #303440;\n}\n.buttonsBlock_zI7S6 {\n  justify-content: right;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Modal/style.m.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;AACJ;AAEA;EACI,sBAAA;EACA,aAAA;AAAJ","sourcesContent":[".header {\n    font-family: 'Raleway';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    font-feature-settings: \"pnum\";\n    color: #303440;\n}\n\n.buttonsBlock {\n    justify-content: right;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_iUq1D",
	"buttonsBlock": "buttonsBlock_zI7S6"
};
export default ___CSS_LOADER_EXPORT___;
