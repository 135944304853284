// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".required_EK1uQ::before {\n  display: inline-block;\n  margin-right: 4px;\n  color: #FF4D4F;\n  font-size: 14px;\n  font-family: SimSun, sans-serif;\n  line-height: 1;\n  content: '*';\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/CurrencyInput/style.m.less"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,+BAAA;EACA,cAAA;EACA,YAAA;AACJ","sourcesContent":[".required::before {\n    display: inline-block;\n    margin-right: 4px;\n    color: #FF4D4F;\n    font-size: 14px;\n    font-family: SimSun, sans-serif;\n    line-height: 1;\n    content: '*';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"required": "required_EK1uQ"
};
export default ___CSS_LOADER_EXPORT___;
