import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const CircleRubOut: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M22.6668 16.0002C22.6668 19.6802 19.6802 22.6668 16.0002 22.6668C12.3202 22.6668 9.3335 19.6802 9.3335 16.0002C9.3335 12.3202 12.3202 9.3335 16.0002 9.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.6667 12.0002V9.3335H20"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.3335 12.6668L22.6668 9.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.3335 16.0002H17.0002C18.0002 16.0067 18.3335 15.3335 18.3335 14.6668C18.3335 14.0002 18.0002 13.3335 17.0002 13.3335H15.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.3335 17.3335H17.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.6665 13.3335V19.0003"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
