import request from '../request'
import { IRequest } from '../types'

export const getGeneratorsList = async (page: number, limit: number): Promise<IRequest> => {
    try {
        const { data, status } = await request.get(`/api/v1/go/list`, {
            params: { page, limit },
        })

        if (status !== 202) {
            throw new Error('fetch error')
        }

        if (data?.items.length === 0) {
            throw new Error('data error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}
