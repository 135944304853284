// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainInfoWrapper_rznTG {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.infoBlock_m7UOB {\n  margin-left: 8px;\n}\n.iconWrapper_O0bgq {\n  display: flex;\n}\n.iconWrapperRight_PHYYT {\n  display: flex;\n  margin-left: auto;\n}\n.documentBlock_Lp8kC {\n  padding: 8px 0 8px 8px;\n}\n.documentName_Cw_e3 {\n  font-size: 13px;\n  line-height: 16px;\n  color: #262626;\n}\n.documentSize_FwhIM {\n  color: #bfbfbf;\n  font-size: 12px;\n}\n.documentDescription_Se4CJ {\n  margin-top: 8px;\n  font-size: 12px;\n}\n.emptyDivider_NaN1_ {\n  height: 16px;\n}\n.textArea_DsLEc {\n  width: 400px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AddEditGO/components/DocumentsList/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,aAAA;AADJ;AAIA;EACI,aAAA;EACA,iBAAA;AAFJ;AAKA;EACI,sBAAA;AAHJ;AAMA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AAJJ;AAOA;EACI,cAAA;EACA,eAAA;AALJ;AAQA;EACI,eAAA;EACA,eAAA;AANJ;AASA;EACI,YAAA;AAPJ;AAUA;EACI,YAAA;EACA,kBAAA;AARJ","sourcesContent":[".mainInfoWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.infoBlock {\n    margin-left: 8px;\n}\n\n.iconWrapper {\n    display: flex;\n}\n\n.iconWrapperRight {\n    display: flex;\n    margin-left: auto;\n}\n\n.documentBlock {\n    padding: 8px 0 8px 8px\n}\n\n.documentName {\n    font-size: 13px;\n    line-height: 16px;\n    color: #262626;\n}\n\n.documentSize {\n    color: #bfbfbf;\n    font-size: 12px;\n}\n\n.documentDescription {\n    margin-top: 8px;\n    font-size: 12px;\n}\n\n.emptyDivider {\n    height: 16px;\n}\n\n.textArea {\n    width: 400px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainInfoWrapper": "mainInfoWrapper_rznTG",
	"infoBlock": "infoBlock_m7UOB",
	"iconWrapper": "iconWrapper_O0bgq",
	"iconWrapperRight": "iconWrapperRight_PHYYT",
	"documentBlock": "documentBlock_Lp8kC",
	"documentName": "documentName_Cw_e3",
	"documentSize": "documentSize_FwhIM",
	"documentDescription": "documentDescription_Se4CJ",
	"emptyDivider": "emptyDivider_NaN1_",
	"textArea": "textArea_DsLEc"
};
export default ___CSS_LOADER_EXPORT___;
