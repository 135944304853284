// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stackedBarChart_AN5lQ {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  height: 590px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  padding: 24px 40px 24px 32px;\n  border-radius: 16px;\n  margin-top: 32px;\n}\n.headerRow_HkBOP {\n  display: flex;\n  gap: 104px;\n}\n.headerPrimary_Ap3gP {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #262626;\n}\n.headerSecondary_R7uuN {\n  margin-left: 8px;\n  font-weight: 500;\n  font-size: 17px;\n  line-height: 20px;\n  color: #8c8c8c;\n}\n.graph_w9spi {\n  flex-grow: 1;\n}\n.filters_xtWIE {\n  display: flex;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Main/components/StackedBarChart/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,gBAAA;EACA,8CAAA;EACA,4BAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAEA;EACI,aAAA;EACA,UAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKA;EACI,YAAA;AAHJ;AAMA;EACI,aAAA;EACA,SAAA;AAJJ","sourcesContent":[".stackedBarChart {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n    height: 590px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    padding: 24px 40px 24px 32px;\n    border-radius: 16px;\n    margin-top: 32px;\n}\n\n.headerRow {\n    display: flex;\n    gap: 104px;\n}\n\n.headerPrimary {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #262626;\n}\n\n.headerSecondary {\n    margin-left: 8px;\n    font-weight: 500;\n    font-size: 17px;\n    line-height: 20px;\n    color: #8c8c8c;\n}\n\n.graph {\n    flex-grow: 1;\n}\n\n.filters {\n    display: flex;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stackedBarChart": "stackedBarChart_AN5lQ",
	"headerRow": "headerRow_HkBOP",
	"headerPrimary": "headerPrimary_Ap3gP",
	"headerSecondary": "headerSecondary_R7uuN",
	"graph": "graph_w9spi",
	"filters": "filters_xtWIE"
};
export default ___CSS_LOADER_EXPORT___;
