import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const EmptyWallet: FC<IIconProps> = ({ size = 20 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.032 11.2923C14.682 11.634 14.482 12.1256 14.532 12.6506C14.607 13.5506 15.432 14.209 16.332 14.209H17.9154V15.2006C17.9154 16.9256 16.507 18.334 14.782 18.334H5.21536C3.49036 18.334 2.08203 16.9256 2.08203 15.2006V9.59233C2.08203 7.86733 3.49036 6.45898 5.21536 6.45898H14.782C16.507 6.45898 17.9154 7.86733 17.9154 9.59233V10.7923H16.232C15.7654 10.7923 15.3404 10.9756 15.032 11.2923Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.08203 10.3421V6.53379C2.08203 5.54212 2.69036 4.65875 3.61536 4.30875L10.232 1.80875C11.2654 1.41708 12.3737 2.18377 12.3737 3.29211V6.45876"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.7977 11.641V13.3577C18.7977 13.816 18.431 14.191 17.9644 14.2077H16.331C15.431 14.2077 14.606 13.5493 14.531 12.6493C14.481 12.1243 14.681 11.6327 15.031 11.291C15.3394 10.9743 15.7644 10.791 16.231 10.791H17.9644C18.431 10.8077 18.7977 11.1826 18.7977 11.641Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83203 10H11.6654"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
