// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uploadDocumentFrame_o93AR {\n  width: 600px;\n  height: 112px;\n  background: rgba(230, 241, 255, 0.35);\n  border-radius: 16px;\n  padding: 20px 24px;\n}\n.info_mM12h {\n  margin-top: 12px;\n}\n.documentsListWrapper_D20Yi {\n  margin-top: 24px;\n  margin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/DocumentsBlockUI/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,gBAAA;AADJ","sourcesContent":[".uploadDocumentFrame {\n    width: 600px;\n    height: 112px;\n    background: rgba(230, 241, 255, 0.35);\n    border-radius: 16px;\n    padding: 20px 24px;\n}\n\n.info {\n    margin-top: 12px;\n}\n\n.documentsListWrapper {\n    margin-top: 24px;\n    margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadDocumentFrame": "uploadDocumentFrame_o93AR",
	"info": "info_mM12h",
	"documentsListWrapper": "documentsListWrapper_D20Yi"
};
export default ___CSS_LOADER_EXPORT___;
