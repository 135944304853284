// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_YDS54 {\n  font-weight: 600;\n  font-size: 16px;\n}\n.modal_u24_4 span {\n  margin-top: 16px;\n}\n.content_RBq1U {\n  margin-top: 24px;\n  font-size: 14px;\n}\n.documents_wzvwZ {\n  width: 436px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  margin-top: 24px;\n}\n.documents_wzvwZ span {\n  margin-top: 0;\n}\n.documents_wzvwZ div {\n  width: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/components/BlockReasonModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;AAEA;EAEQ,gBAAA;AADR;AAKA;EACI,gBAAA;EACA,eAAA;AAHJ;AAMA;EACI,YAAA;EACA,aAAA;EACA,qCAAA;EACA,gBAAA;AAJJ;AAAA;EAOQ,aAAA;AAJR;AAHA;EAWQ,WAAA;AALR","sourcesContent":[".title {\n    font-weight: 600;\n    font-size: 16px;\n}\n\n.modal {\n    span {\n        margin-top: 16px;\n    }\n}\n\n.content {\n    margin-top: 24px;\n    font-size: 14px;\n}\n\n.documents {\n    width: 436px;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    margin-top: 24px;\n\n    span {\n        margin-top: 0;\n    }\n\n    div {\n        width: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_YDS54",
	"modal": "modal_u24_4",
	"content": "content_RBq1U",
	"documents": "documents_wzvwZ"
};
export default ___CSS_LOADER_EXPORT___;
