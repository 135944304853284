import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { User, DownMini, Logout } from '../../../../../assets/icons'
import { logout } from '../../../../../store/auth'
import IAppState from '../../../../../store/states'
import { createFIO } from '../../../../helpers'
import { Divider, Dropdown, Menu } from 'antd'
import style from './style.m.less'

const getMenu = ({ papams, logoutFunc }: any) => {
    return (
        <Menu className={style.menu}>
            {(papams || []).map((item: any) => {
                if (item.idDivider) {
                    return <Divider key={item.id} className={style.divider} />
                }
                if (item.isLogout) {
                    return (
                        <Menu.Item key={item.id}>
                            <div className={style.logoutLink} onClick={() => logoutFunc()}>
                                <div className={style.logout}>
                                    <Logout />
                                    Выход
                                </div>
                            </div>
                        </Menu.Item>
                    )
                }
                return (
                    <Menu.Item key={item.id}>
                        <Link to={item.to}>{item.name}</Link>
                    </Menu.Item>
                )
            })}
        </Menu>
    )
}

const commonMenu = (logoutFunc: any) => (
    <Menu className={style.menu}>
        <Menu.Item>
            <Link to="/profile">Мой профиль</Link>
        </Menu.Item>
        <Menu.Item>
            <Link to="/settings">Настройки</Link>
        </Menu.Item>
        <Divider className={style.divider} />
        <Menu.Item>
            <div className={style.logoutLink} onClick={() => logoutFunc()}>
                <div className={style.logout}>
                    <Logout />
                    Выход
                </div>
            </div>
        </Menu.Item>
    </Menu>
)

const TSOMenuPapams = [
    { id: 1, to: '/profile', name: 'Мой профиль' },
    { id: 2, to: '/user-my-green-tools', name: 'Мои зеленые инструменты' },
    { id: 3, to: '/related-zgo', name: 'Связанные ЗГО' },
    { id: 5, to: '/zd-data', name: 'Данные ЗД' },
    { id: 6, to: '/my-appeals', name: 'Мои заявки' },
    { id: 8, to: '/settings', name: 'Настройки' },
    { id: 8, idDivider: true },
    { id: 9, isLogout: true },
]
const TSOMenu = (logoutFunc: any) => getMenu({ papams: TSOMenuPapams, logoutFunc })

const clientMenuPapams = [
    { id: 1, to: '/profile', name: 'Мой профиль' },
    { id: 2, to: '/user-my-green-tools', name: 'Мои зеленые инструменты' },
    { id: 6, to: '/my-appeals', name: 'Мои заявки' },
    { id: 8, to: '/settings', name: 'Настройки' },
    { id: 9, idDivider: true },
    { id: 10, isLogout: true },
]
const clientMenu = (logoutFunc: any) => getMenu({ papams: clientMenuPapams, logoutFunc })

const gOOwnerMenuPapams = [
    { id: 1, to: '/profile', name: 'Мой профиль' },
    { id: 2, to: '/user-my-green-tools', name: 'Мои зеленые инструменты' },
    { id: 3, to: '/my-generators', name: 'Мои генерирующие объекты' },
    { id: 11, to: '/generation-attributes', name: 'Мои атрибуты генерации' },
    { id: 4, to: '/ku-owner', name: 'Данные КУ' },
    { id: 6, to: '/fuel-registry', name: 'Данные по топливу' },
    { id: 7, to: '/my-appeals', name: 'Мои заявки' },
    { id: 8, to: '/settings', name: 'Настройки' },
    { id: 9, idDivider: true },
    { id: 10, isLogout: true },
]
const gOOwnerMenu = (logoutFunc: any) => getMenu({ papams: gOOwnerMenuPapams, logoutFunc })

const operatorMenu = (logoutFunc: any) => (
    <Menu className={style.menu}>
        <Menu.Item>
            <Link to="/profile">Мой профиль</Link>
        </Menu.Item>
        <Divider className={style.divider} />
        <Menu.Item>
            <div className={style.logoutLink} onClick={() => logoutFunc()}>
                <div className={style.logout}>
                    <Logout />
                    Выход
                </div>
            </div>
        </Menu.Item>
    </Menu>
)

interface IUserEntry {
    isAuth: boolean
}

const UserEntry: FC<IUserEntry> = ({ isAuth }) => {
    const { t } = useTranslation()
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()

    const { error: authError } = useSelector((store: IAppState) => store.auth)
    const { data } = useSelector((store: IAppState) => store.user)

    const logOut = () => {
        reduxDispatch(logout())
        if (!authError) {
            navigate('/auth')
        }
    }

    const reduxFIO = createFIO(data)
    const reduxRole = data?.user_role

    const storage = localStorage.getItem('FIO') ? localStorage : sessionStorage
    const FIO = reduxFIO ? reduxFIO : storage.getItem('FIO')
    const role = reduxRole
        ? reduxRole
        : Number(
              localStorage.getItem('userRole')
                  ? localStorage.getItem('userRole')
                  : sessionStorage.getItem('userRole'),
          )

    const getOverlayForUserType = () => {
        switch (role) {
            //Пользователь без ЛС
            case 1:
                return commonMenu(logOut)
            //Клиент
            case 2:
                return clientMenu(logOut)
            //Владелец ЗГО
            case 3:
                return gOOwnerMenu(logOut)
            //ТСО/ГП
            case 4:
                return TSOMenu(logOut)
            //Оператор
            case 5:
                return operatorMenu(logOut)
            //Администратор
            case 6:
                return operatorMenu(logOut)
            //Супер Администратор
            case 7:
                return operatorMenu(logOut)
            default:
                return commonMenu(logOut)
        }
    }

    return isAuth ? (
        <div className={style.userName}>
            <Dropdown trigger={['click']} overlay={getOverlayForUserType()} placement="bottomRight">
                <a onClick={(e) => e.preventDefault()} className={style.userNameLink}>
                    {FIO}
                    <DownMini />
                </a>
            </Dropdown>
        </div>
    ) : (
        <Link className={style.userEntry} to="/auth">
            <User />
            <div className={style.userInfo}>{t('header.signIn')}</div>
        </Link>
    )
}

export default UserEntry
