import { RequestTypes } from '../hooks/useRequest'

export const FUEL_API_PREFIX = '/api/fuel/v1/'
export const FUEL_REGISTRY = 'applications'
export const FUEL_GOS = 'applications-gos'
export const FUEL_OWNERS = 'applications-go-owners'
export const FUEL_DETAILS = 'application?id='
export const FUEL_APPROVE = 'application-approve'

export const getFuelRegistry = {
    link: `${FUEL_API_PREFIX}${FUEL_REGISTRY}`,
    method: RequestTypes.post,
}

export const getGosDict = {
    link: `${FUEL_API_PREFIX}${FUEL_GOS}`,
    method: RequestTypes.post,
}

export const getFuelDetails = {
    link: `${FUEL_API_PREFIX}${FUEL_DETAILS}`,
    method: RequestTypes.get,
}

export const getGosOwners = {
    link: `${FUEL_API_PREFIX}${FUEL_OWNERS}`,
    method: RequestTypes.post,
}

export const postApprove = {
    link: `${FUEL_API_PREFIX}${FUEL_APPROVE}`,
    method: RequestTypes.post,
}
