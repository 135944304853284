// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerHeader_Xpxqp {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n}\n.userList_sAHJt {\n  display: flex;\n  flex-direction: column;\n  margin-top: 12px;\n}\n.listItem_agys7 {\n  color: #fff;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/components/FooterUser/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;AAGA;EACI,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADJ","sourcesContent":[".footerHeader {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n}\n\n.userList {\n    display: flex;\n    flex-direction: column;\n    margin-top: 12px;\n}\n\n.listItem {\n    color: #fff;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerHeader": "footerHeader_Xpxqp",
	"userList": "userList_sAHJt",
	"listItem": "listItem_agys7"
};
export default ___CSS_LOADER_EXPORT___;
