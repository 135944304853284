import { FC, useState } from 'react'
import { IconButton } from '../../../ui'
import { Clock } from '../../../../assets/icons'
import { PaymentModal, SubModal } from '../'
import { moneyAmountFormatter } from '../../../helpers/moneyAmountFormatter'

import style from '../../style.m.less'

interface IRealisation {
    info: {
        delivery_ts: string
        volume?: number
        ammount: number
        status: 0 | 1 | 2
        realisation_date?: string
        consumers?: number
        payment_date?: string
        released_volume?: number
        id: number
    }
    editRealisations: (id: number) => void
}

const statuses = ['Оплатить до', 'Денежные средства зарезервированы', 'Реализация прошла успешно']

const Realisation: FC<IRealisation> = ({
    info: {
        status,
        payment_date,
        delivery_ts,
        volume,
        ammount,
        realisation_date,
        released_volume,
        consumers,
        id,
    },
    editRealisations,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isSubModalVisible, setIsSubModalVisible] = useState(false)

    const handleModal = () => {
        setIsModalVisible(!isModalVisible)
    }

    const handleSubModal = () => {
        setIsSubModalVisible(!isSubModalVisible)
    }

    const formattedAmmount = moneyAmountFormatter.format(ammount)
    return (
        <div className={style.gridBlock}>
            <div className={style.gridBlockHeader}>
                <div className={style.repaymentHeader}>Реализация АГ</div>
                <div className={status ? style.paymentStatusSuccess : style.paymentStatusPending}>
                    <Clock />
                    <div>
                        {statuses[status]} {payment_date}
                    </div>
                </div>
            </div>
            <div className={style.info}>
                {realisation_date && (
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Дата реализации</div>
                        <div className={style.infoRowValue}>{realisation_date}</div>
                    </div>
                )}
                {delivery_ts && (
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Период поставки</div>
                        <div className={style.infoRowValue}>{delivery_ts}</div>
                    </div>
                )}
                {volume && (
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Объем</div>
                        <div className={style.infoRowValue}>{`${volume
                            .toLocaleString()
                            .replace(',', ' ')} кВт*ч`}</div>
                    </div>
                )}
                {released_volume && (
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Реализованный объем</div>
                        <div className={style.infoRowValue}>{`${released_volume
                            .toLocaleString()
                            .replace(',', ' ')} кВт*ч`}</div>
                    </div>
                )}
                {!!consumers && (
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Количество потребителей</div>
                        <div className={style.infoRowValue}>{`${consumers} потребителя`}</div>
                    </div>
                )}
                {ammount && (
                    <div className={style.infoRow}>
                        <div className={style.infoRowName}>Сумма комиссии</div>
                        <div className={style.infoRowValue}>{formattedAmmount}</div>
                    </div>
                )}
            </div>

            {status !== 1 && (
                <>
                    <IconButton onClick={handleModal} className={style.payButton}>
                        {status ? 'Подробнее о реализации' : 'Перейти к оплате'}
                    </IconButton>
                    <PaymentModal
                        isVisible={isModalVisible}
                        status={status}
                        onCancel={handleModal}
                        volume={volume}
                        consume_period={delivery_ts}
                        commission_assign={ammount}
                        id={id}
                        onOk={editRealisations}
                        openSubModal={handleSubModal}
                    />
                    <SubModal isVisible={isSubModalVisible} onClose={handleSubModal} />
                </>
            )}
        </div>
    )
}

export default Realisation
