import { FC, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { Import } from '../../assets/icons'
import {
    BackArrow,
    CenteredSpin,
    ContractCard,
    IconButton,
    Box,
    OperationHistoryMapper,
} from '../ui'
import { EHistoryType, IOperationConfig } from '../ui/OperationHistoryMapper/types'
import { useContractData } from '../hooks/useContractData'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'
import { energyTypePicker } from '../helpers'
import { getMyContractData, getPublicContractData } from './config'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getDictionaries } from '../../store/dictionaries'
import { ELoading } from '../../store/dictionaries/interfaces'
import { EUserRole } from '../../constants/profile'
import { useGetTariffRate } from '../hooks'
import style from './style.m.less'
import { Consumption } from './types'
import BlockModal from './components/BlockModal/BlockModal'
import { PaymentModal, SubModal } from './components'
import RealisationCard from './RealizationCard/RealisationCard'

const Contract: FC = () => {
    const [blockModalData, setBlockModalData] = useState({
        isVisible: false,
        isUnblock: false,
    })
    const [paymentModalOptions, setPaymentModalOptions] = useState({
        modalIndex: 0,
        isModalOpen: false,
    })
    const [isSubModalModalOpen, setIsSubModalModalOpen] = useState(false)

    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams()

    const contractData = useContractData()

    const { fetch, result, isLoading } = useRequest(
        contractData?.isOwner ? getMyContractData : getPublicContractData,
    )

    const isLoadingDict = useAppSelector((state) => state.dictionaries.loading)
    const dispatch = useAppDispatch()
    const role = useAppSelector((state) => state?.user?.data?.user_role)
    const isAdminType = [
        EUserRole.Operator,
        EUserRole.Administrator,
        EUserRole.SuperAdmin,
    ].includes(role)
    const userId = useAppSelector((state) => state?.user?.data?.uid)

    const { fetch: fetchContractHistory, result: contractHistory } = useRequest({
        link: '/api/audit/v1/event-history-contracts?id=',
        method: RequestTypes.post,
    })
    const contractHistoryParsed: IOperationConfig[] = []

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'

    const getContractHistory = () => {
        if (contractData?.id) {
            fetchContractHistory({
                addToLink: contractData?.id,
            })
        }
    }

    const fetchContractData = () => {
        if (contractData?.id) {
            fetch({
                addToLink: contractData?.id,
            })
        } else {
            fetch({
                addToLink: params?.id,
            })
        }
    }

    const { rateNumber } = useGetTariffRate({
        id: 18,
        volume: 1,
    })

    useMount(() => {
        if (isLoadingDict !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    useEffect(() => {
        if (contractData) {
            fetchContractData()
            getContractHistory()
        }
    }, [contractData])

    useEffect(() => {
        if (result?.status_id === 3) {
            setBlockModalData((prev) => ({ ...prev, isUnblock: true }))
        }
    }, [result?.status_id])

    if (isLoading || isLoadingDict === ELoading.Loading || !result) {
        return <CenteredSpin />
    }

    const handlePaymentModal = (modalIndex: number) => {
        setPaymentModalOptions({ isModalOpen: !paymentModalOptions.isModalOpen, modalIndex })
    }

    const {
        contract_id,
        contract_number,
        account_number,
        buyer_account,
        seller_account,
        renewable_energy_type_id,
        status_id,
        volume_consume,
        volume_new,
        buyer_uid,
        buyer_name,
        buyer_codes,
        seller_uid,
        seller_name,
        seller_codes,
        tax_payer_type_id,
        invisible_in_the_list,
        is_buyer_tax_payer,
        ts_registration,
        ts_created,
        ts_delivery,
        consumptions,
        block,
        sdd_start_begin_date,
        consumers,
        seller_account_id,
        buyer_account_id,
    } = result

    if (contractHistory?.total > 0) {
        const today = moment().startOf(dayUnit)
        const eh = contractHistory?.events?.map(
            (phe: { ts_event: string; payload: any[]; type_event_id: number }) => {
                const parsedDate = moment(phe.ts_event).format(shortFormat)
                const eventDay = moment(parsedDate, shortFormat)

                let title = eventDay.format(extraFormat)

                if (eventDay.isSame(today)) {
                    title = `${t('history.today')}, ${title}`
                }

                if (eventDay.isSame(today.subtract(1, dayUnit))) {
                    title = `${t('history.yesterday')}, ${title}`
                }

                const operation = {
                    id: phe.type_event_id,
                    time: phe.ts_event,
                    value: phe?.payload?.volume_consume,
                }

                return {
                    flexStart: false,
                    iconMargin: false,
                    date: title,
                    operations: [operation],
                }
            },
        )

        contractHistoryParsed.push(...eh)
    }

    const importDataToCSV = () => {
        const rows = [
            [
                'Номер ЗД',
                'Статус ЗД',
                'Тип ВИЭ',
                'Общий объем ЗД',
                'Объем ЗД по расчетным периодам',
                'Конечные потребители по расчетным периодам',
                'Покупатель (с ГТП)',
                'Продавец (с ГТП)',
                'ЛС Покупателя',
                'ЛС Продавца',
                'Дата регистрации',
                'Дата создания в Системе',
                'Дата начала поставки по ЗД',
            ],
            [
                contract_number,
                status_id === 1 ? 'Активен' : 'Заблокирован',
                energyTypePicker(renewable_energy_type_id),
                volume_consume,
                volume_new,
                consumers
                    ?.map(
                        (el: { user_name: string; user_id: string; volume: number }) =>
                            el.user_name,
                    )
                    .join(', ') || '',
                `${buyer_name} (${buyer_codes.toString()})`,
                `${seller_name} (${seller_codes.toString()})`,
                buyer_account,
                seller_account,
                moment(ts_registration).format(shortFormat),
                moment(ts_created).format(shortFormat),
                moment(sdd_start_begin_date).format(shortFormat),
            ],
        ]

        const csvContent =
            'data:text/csv;charset=utf-8,\uFEFF' + rows.map((e) => e.join(';')).join('\n')

        const encodedUri = encodeURI(csvContent)
        const a = document.createElement('a'),
            url = encodedUri
        a.href = url
        a.download = `ЗД-${contract_number}.csv`
        document.body.appendChild(a)
        a.click()
    }

    const isSeller = userId === seller_uid
    const isBuyer = userId === buyer_uid
    const isBuyerOrSeller = isSeller || isBuyer

    const historyContent = (
        <div className={style.historyBlock}>
            <div className={style.header}>{t('myProfile.operationHistory')}</div>
            <OperationHistoryMapper
                config={contractHistoryParsed}
                settings={{ historyType: EHistoryType.Certs }}
                isContract
            />
            {contractHistory?.total > 0 && (
                <Link to={`/contract-history-operations/${contractData?.id}`}>
                    <IconButton customStyle={{ marginTop: '40px' }}>Все операции</IconButton>
                </Link>
            )}
        </div>
    )

    return (
        <div className={style.certificateMain}>
            <div className={style.container}>
                <div className={style.headerBlock}>
                    <div className={style.backBlock}>
                        <BackArrow />
                        <div className={style.mainHeader}>
                            {contractData?.cardLabel || 'Зеленый договор'}
                        </div>
                    </div>
                    {isAdminType && (
                        <IconButton
                            icon={<Import />}
                            customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                            onClick={importDataToCSV}
                            disabled
                        >
                            Выгрузить
                        </IconButton>
                    )}
                </div>
                <ContractCard
                    isDetailed
                    onBlock={() => setBlockModalData((prev) => ({ ...prev, isVisible: true }))}
                    isUserType={contractData?.isUserType}
                    isOwner={contractData?.isOwner}
                    refreshData={fetchContractData}
                    contract={{
                        contract_id,
                        buyer_account,
                        seller_account,
                        account_number,
                        buyer_name,
                        renewable_energy_type_id,
                        contract_number,
                        status_id,
                        volume_consume,
                        volume_new,
                        seller_name,
                        buyer_codes,
                        buyer_uid,
                        seller_uid,
                        ts_registration,
                        ts_created,
                        ts_delivery,
                        seller_codes,
                        tax_payer_type_id,
                        invisible_in_the_list,
                        is_buyer_tax_payer,
                        sdd_start_begin_date,
                        seller_account_id,
                        buyer_account_id,
                    }}
                />
                <div className={style.wrapper}>
                    <Box>
                        {consumptions?.map((item: Consumption, itemIndex: number) => {
                            const tariffsActive = {
                                transfer: item.seller_fee > 0,
                                realisation: item.buyer_fee > 0,
                            }
                            const comission_on =
                                moment(item.pay_before).unix() > moment().unix() &&
                                ((!is_buyer_tax_payer && tariffsActive.realisation) ||
                                    tariffsActive.transfer)

                            return (
                                <>
                                    <RealisationCard
                                        userId={userId}
                                        isAdmin={isAdminType}
                                        data={result}
                                        consumption={item}
                                        handlePaymentModal={() => handlePaymentModal(itemIndex)}
                                        auditNavigatge={() =>
                                            navigate(`/audit/${item?.consumers_audit}`)
                                        }
                                        tariffsActive={tariffsActive}
                                        rateNumber={rateNumber}
                                    />
                                    <PaymentModal
                                        seller_fee={item.seller_fee}
                                        buyer_fee={item.buyer_fee}
                                        is_buyer_tax_payer={is_buyer_tax_payer}
                                        consume_period={item.period}
                                        commission_assign={item?.fee || item.seller_fee}
                                        volume={item.volume}
                                        buyer_uid={buyer_uid}
                                        buyer_name={buyer_name}
                                        comission_on={comission_on}
                                        contract_number={contract_number}
                                        isVisible={
                                            paymentModalOptions.isModalOpen &&
                                            itemIndex === paymentModalOptions.modalIndex
                                        }
                                        onCancel={() => handlePaymentModal(itemIndex)}
                                        onOk={() => {
                                            fetchContractData()
                                            handlePaymentModal(itemIndex)
                                        }}
                                        openSubModal={() => setIsSubModalModalOpen(true)}
                                        contract_id={item.contract_id}
                                    />
                                    <SubModal
                                        isVisible={isSubModalModalOpen}
                                        onClose={() => setIsSubModalModalOpen(false)}
                                    />
                                </>
                            )
                        })}
                    </Box>
                    {isAdminType || isBuyerOrSeller ? historyContent : <></>}
                </div>
            </div>
            <BlockModal
                contractId={contractData?.id}
                modalStatus={blockModalData}
                setModalStatus={setBlockModalData}
            />
        </div>
    )
}

export default Contract
