import { FC } from 'react'

import style from './style.m.less'
import { Card, IconButton, LoadingContainer } from '../ui'
import { KUTable } from './components'
import { Receipt, Import } from '../../assets/icons'
import { Link } from 'react-router-dom'
import useMount from '../hooks/useMount'
import useRequest from '../hooks/useRequest'
import { getSubmissionAvailable } from './config'
import { downloadCsv } from '../helpers'

const KUData: FC = () => {
    const {
        fetch: fetchAvailable,
        result: availability,
        isLoading: isLoadingAvailable,
    } = useRequest(getSubmissionAvailable)

    useMount(() => {
        fetchAvailable({ errorCodeMessage: true })
    })

    const getFile = () => {
        downloadCsv('/api/ca/v1/wm/submissions?download', 'KUData.csv')
    }

    return (
        <LoadingContainer isLoading={isLoadingAvailable || !availability}>
            <div className={style.container}>
                <h1 className={style.title}>Данные КУ</h1>
                <div className={style.buttonWrapper}>
                    <Link to="/ku-submit-data">
                        <IconButton icon={<Receipt />} disabled={!availability?.is_available}>
                            Подать данные по КУ
                        </IconButton>
                    </Link>
                    <IconButton
                        icon={<Import />}
                        customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                        onClick={getFile}
                    >
                        Выгрузить данные
                    </IconButton>
                </div>
                <div className={style.tabs}>
                    <Card className={style.card}>
                        <KUTable />
                    </Card>
                </div>
            </div>
        </LoadingContainer>
    )
}

export default KUData
