import { FC, useState } from 'react'
// import { ValueSelector } from '../../../'
import { Input, Divider, Alert, Checkbox } from 'antd'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { DropdownSelector, IconButton, Modal, PrimaryButton } from '../../../ui'
import { WarningOutlined } from '@ant-design/icons'

const options = [
    { id: 'Физическое лицо', value: 'Физическое лицо' },
    { id: 'Юридическое лицо', value: 'Юридическое лицо' },
    { id: 'Индивидуальный предприниматель', value: 'Индивидуальный предприниматель' },
]

const ownerType = 'Индивидуальный предприниматель'

const AddConsumer: FC = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const [hasPatronymic, setHasPatronymic] = useState(true)
    const showPatronymic = () => {
        setHasPatronymic(!hasPatronymic)
    }

    return (
        <>
            <Modal isModalVisible={isModalVisible}>
                <div className={style.modalHeader}>Добавить потребителя</div>
                {ownerType === 'Физическое лицо' && (
                    <>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Фамилия</div>
                            <Input placeholder="Введите фамилию" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Имя</div>
                            <Input placeholder="Введите имя" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Отчество</div>
                            <div className={style.checkboxWrapper}>
                                <Input
                                    placeholder="Введите отчество"
                                    className={style.input}
                                    disabled={!hasPatronymic}
                                />
                                <Checkbox className={style.checkbox} onClick={showPatronymic}>
                                    {t('editMyProfile.noLastName')}
                                </Checkbox>
                            </div>
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ИНН</div>
                            <Input placeholder="Введите ИНН" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>СНИЛС</div>
                            <Input placeholder="Введите СНИЛС" className={style.input} />
                        </div>
                    </>
                )}
                {ownerType === 'Юридическое лицо' && (
                    <>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Название</div>
                            <Input placeholder="Введите Название" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ИНН</div>
                            <Input placeholder="Введите ИНН" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ОГРН</div>
                            <Input placeholder="Введите ОГРН" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>КПП</div>
                            <Input placeholder="Введите КПП" className={style.input} />
                        </div>
                    </>
                )}
                {ownerType === 'Индивидуальный предприниматель' && (
                    <>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Фамилия</div>
                            <Input placeholder="Введите фамилию" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Имя</div>
                            <Input placeholder="Введите имя" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>Отчество</div>
                            <div className={style.checkboxWrapper}>
                                <Input
                                    placeholder="Введите отчество"
                                    className={style.input}
                                    disabled={!hasPatronymic}
                                />
                                <Checkbox className={style.checkbox} onClick={showPatronymic}>
                                    {t('editMyProfile.noLastName')}
                                </Checkbox>
                            </div>
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ИНН</div>
                            <Input placeholder="Введите ИНН" className={style.input} />
                        </div>
                        <div className={style.inputWrapper}>
                            <div className={style.label}>ОГРНИП</div>
                            <Input placeholder="Введите ОГРНИП" className={style.input} />
                        </div>
                    </>
                )}
                <div className={style.buttonsBlock}>
                    <PrimaryButton
                        onClick={handleOk}
                        value="Отмена"
                        isCancel
                        customStyle={{ padding: '8px 16px' }}
                    />
                    <PrimaryButton
                        onClick={handleOk}
                        value="Добавить"
                        customStyle={{ padding: '8px 16px' }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default AddConsumer
