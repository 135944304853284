import { userTypePicker, userRolePicker, documentTypePicker } from '../helpers'
import moment from 'moment'
import { IUserInfo } from '../../store/user/interfaces'

const hiddenInfoReduced = (userData: IUserInfo) => {
    const {
        personal_inn,
        company_inn,
        phone,
        company_name,
        ogrn,
        company_position,
        last_name,
        first_name,
        middle_name,
        user_type,
        company_name_short,
        user_role,
        kpp,
        egrul_government_name,
        egrul_registration_date,
        web,
    } = userData

    const isAdmin = user_role === 6 || user_role === 5

    return [
        {
            name: 'Полное название организации',
            value: user_type === 1 ? `ИП ${last_name} ${first_name} ${middle_name}` : company_name,
            noMargin: true,
        },
        {
            name: 'Сокращенное название организации',
            value: company_name_short,
        },
        {
            name: `ИНН ${isAdmin ? '' : 'организации'}`,
            value: personal_inn !== '' ? personal_inn : company_inn,
        },
        {
            name: 'КПП',
            value: kpp,
        },
        {
            name: 'ОГРН (ОГРНИП)',
            value: ogrn,
        },
        ...(isAdmin
            ? [
                  {
                      name: 'Наименование гос. органа, осуществившего регистрацию в ЕГРЮЛ',
                      value: egrul_government_name,
                  },
                  {
                      name: 'Дата регистрации в ЕГРЮЛ',
                      value: moment(egrul_registration_date).format('DD.MM.YYYY'),
                  },
              ]
            : []),
        {
            name: 'Должность',
            value: company_position,
        },
        {
            name: 'Телефон / факс',
            value: phone,
        },
        ...(web
            ? [
                  {
                      name: 'Сайт',
                      value: web,
                  },
              ]
            : [{}]),
    ]
}

const hiddenInfoIndividual = (userData: IUserInfo) => {
    const {
        birthday,
        citizenship,
        document_type,
        passport_series,
        passport_number,
        passport_date,
        passport_betrayed_name,
        passport_betrayed_code,
        registration_address,
        post_address,
        company_inn,
        personal_inn,
        snils,
        phone,
        company_name,
        ogrn,
        company_position,
        post_address_matches,
        point_delivery_codes_consuming,
    } = userData

    return [
        {
            name: 'Дата рождения',
            value: moment(birthday).format('DD.MM.YYYY'),
            noMargin: true,
        },
        {
            name: 'Гражданство',
            value: citizenship || 'Российская Федерация',
        },
        {
            name: 'Адрес регистрации',
            value: registration_address,
        },
        {
            name: 'Почтовый адрес',
            value: post_address_matches ? registration_address : post_address,
        },
        {
            name: 'Документ, удостоверяющий личность',
            value: documentTypePicker(document_type),
        },
        {
            name: 'Серия и номер паспорта',
            value: `${passport_series} ${passport_number}`,
        },
        {
            name: 'Дата выдачи',
            value: moment(passport_date).format('DD.MM.YYYY'),
        },
        {
            name: 'Код подразделения ',
            value: passport_betrayed_code,
        },
        {
            name: 'Кем выдан',
            value: passport_betrayed_name,
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'ИНН',
            value: personal_inn,
        },
        {
            name: 'СНИЛС',
            value: snils,
        },
        {
            name: 'Телефон / факс',
            value: phone,
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'Полное название организации',
            value: company_name,
            noMargin: true,
        },
        {
            name: 'ИНН организации',
            value: company_inn,
        },
        {
            name: 'ОГРН',
            value: ogrn,
        },
        {
            name: 'Коды ГТП потребления',
            fieldWithHide: point_delivery_codes_consuming,
        },
        {
            name: 'Должность',
            value: company_position,
        },
    ]
}

const hiddenInfoEntity = (userData: IUserInfo) => {
    const {
        company_name,
        post_address,
        company_inn,
        phone,
        kpp,
        company_name_short,
        ogrn,
        egrul_government_name,
        egrul_registration_date,
        web,
        legal_address,
        post_address_matches,
        point_delivery_codes_consuming,
        company_position,
    } = userData

    return [
        {
            name: 'Полное название организации',
            value: company_name,
            noMargin: true,
        },
        {
            name: 'Сокращенное название организации',
            value: company_name_short,
        },
        {
            name: 'ИНН',
            value: company_inn,
        },
        {
            name: 'КПП',
            value: kpp,
        },
        {
            name: 'ОГРН',
            value: ogrn,
        },
        {
            name: 'Наименование гос. органа, осуществившего регистрацию в ЕГРЮЛ',
            value: egrul_government_name,
        },
        {
            name: 'Дата регистрации в ЕГРЮЛ',
            value: moment(egrul_registration_date).format('DD.MM.YYYY'),
        },
        {
            name: 'Коды ГТП потребления',
            fieldWithHide: point_delivery_codes_consuming,
        },
        {
            name: 'Должность',
            value: company_position,
        },
        {
            name: 'Телефон / факс',
            value: phone,
        },
        {
            name: 'Сайт',
            value: web,
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'Юридический адрес',
            value: legal_address,
            noMargin: true,
        },
        {
            name: 'Почтовый адрес',
            value: post_address_matches ? legal_address : post_address,
        },
    ]
}

const hiddenInfoEntrepreneur = (userData: IUserInfo) => {
    const {
        birthday,
        citizenship,
        document_type,
        passport_series,
        passport_number,
        passport_date,
        passport_betrayed_name,
        passport_betrayed_code,
        registration_address,
        post_address,
        personal_inn,
        snils,
        phone,
        first_name,
        last_name,
        middle_name,
        ogrn,
        company_position,
        egrul_government_name,
        egrul_registration_date,
        web,
        point_delivery_codes_consuming,
    } = userData

    return [
        {
            name: 'Дата рождения',
            value: moment(birthday).format('DD.MM.YYYY'),
            noMargin: true,
        },
        {
            name: 'Гражданство',
            value: citizenship || 'Российская Федерация',
        },
        {
            name: 'Адрес регистрации',
            value: registration_address,
        },
        {
            name: 'Почтовый адрес',
            value: post_address,
        },
        {
            name: 'Документ, удостоверяющий личность',
            value: documentTypePicker(document_type),
        },
        {
            name: 'Серия и номер паспорта',
            value: `${passport_series} ${passport_number}`,
        },
        {
            name: 'Дата выдачи',
            value: moment(passport_date).format('DD.MM.YYYY'),
        },
        {
            name: 'Код подразделения ',
            value: passport_betrayed_code,
        },
        {
            name: 'Кем выдан',
            value: passport_betrayed_name,
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'Полное название организации',
            value: `ИП ${last_name} ${first_name} ${middle_name}`,
            noMargin: true,
        },
        {
            name: 'ОГРНИП',
            value: ogrn,
        },
        {
            name: 'Наименование гос. органа, осуществившего регистрацию в ЕГРИП',
            value: egrul_government_name,
        },
        {
            name: 'Дата регистрации в ЕГРИП',
            value: moment(egrul_registration_date).format('DD.MM.YYYY'),
        },
        {
            name: 'Коды ГТП потребления',
            fieldWithHide: point_delivery_codes_consuming,
            value: point_delivery_codes_consuming,
        },
        {
            name: 'Должность',
            value: company_position,
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'ИНН',
            value: personal_inn,
            noMargin: true,
        },
        {
            name: 'СНИЛС',
            value: snils,
        },
        {
            name: 'Телефон / факс',
            value: phone,
        },
        {
            name: 'Сайт',
            value: web,
        },
    ]
}

export const profileInfoConfig = (userData: IUserInfo) => {
    const {
        user_role,
        user_type,
        first_name,
        last_name,
        middle_name,
        login,
        email,
        profile_status,
        user_pub_id,
    } = userData

    console.log(user_type)

    const defineHiddenInfo = () => {
        if (profile_status === 0) {
            return hiddenInfoReduced(userData)
        } else if (user_type === 0) {
            return hiddenInfoIndividual(userData)
        } else if (user_type === 1) {
            return hiddenInfoEntrepreneur(userData)
        } else {
            return hiddenInfoEntity(userData)
        }
    }

    return {
        visibleInfo: [
            {
                name: 'Уникальный номер пользователя',
                value: user_pub_id,
            },
            {
                name: 'Роль в системе',
                value: userRolePicker(user_role),
            },
            {
                name: 'Тип пользователя',
                value: userTypePicker(user_type),
            },
            {
                name: 'ФИО',
                value: `${last_name} ${first_name} ${middle_name}`,
            },
            {
                name: 'Логин',
                value: login,
            },
            {
                name: 'E-mail',
                value: email,
            },
        ],
        hiddenInfo: defineHiddenInfo(),
    }
}
