import { FC } from 'react'
import { Button, ButtonProps as AntdButtonProps } from 'antd'
import style from './style.m.less'
import './style.less'
import classNames from 'classnames'

export type IIconButton = AntdButtonProps & {
    icon?: React.ReactNode
    customStyle?: React.CSSProperties
    customIconStyle?: React.CSSProperties
    onClick?: () => void
    red?: boolean
    simple?: boolean
}

const IconButton: FC<IIconButton> = ({
    icon,
    customIconStyle,
    customStyle,
    className,
    onClick,
    red,
    simple,
    ...rest
}) => {
    return (
        <Button
            icon={customIconStyle ? <div style={customIconStyle}>{icon}</div> : icon}
            onClick={onClick}
            className={classNames(
                className,
                style.iconButton,
                red && style.danger,
                simple && style.simple,
            )}
            style={customStyle}
            {...rest}
        />
    )
}

export default IconButton
