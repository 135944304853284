import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const AddCircleHistory: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="32" height="32" rx="8" fill="#E6F7FF" />
                <path
                    d="M16.0002 22.6668C19.6668 22.6668 22.6668 19.6668 22.6668 16.0002C22.6668 12.3335 19.6668 9.3335 16.0002 9.3335C12.3335 9.3335 9.3335 12.3335 9.3335 16.0002C9.3335 19.6668 12.3335 22.6668 16.0002 22.6668Z"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.3335 16H18.6668"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16 18.6668V13.3335"
                    stroke="#40A9FF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )}
    />
)
