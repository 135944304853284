import { FC, useState } from 'react'
import { Tabs } from 'antd'
import style from './style.m.less'
import { Card } from '../ui'
import { Default } from './components'

const { TabPane } = Tabs

const tabsList = [
    { label: 'Открытые', key: 0, content: <></> },
    { label: 'Закрытые', key: 1, content: <></> },
]

const FuelApproval: FC = () => {
    const [currentTab, setCurrentTab] = useState(tabsList[0].label)

    return (
        <div className={style.container}>
            <h1 className={style.title}>Согласование данных КУ</h1>
            <Tabs
                defaultActiveKey="0"
                onChange={(activeKey) => {
                    const activeNumber = Number(activeKey)
                    setCurrentTab(tabsList[activeNumber].label)
                }}
                className={style.tabs}
            >
                {tabsList.map((item) => {
                    const { label, key, content } = item

                    return (
                        <TabPane tab={label} key={key}>
                            {content}
                        </TabPane>
                    )
                })}
            </Tabs>
            <Card style={{ marginTop: '24px' }}>
                <Default currentTab={currentTab} />
            </Card>
        </div>
    )
}

export default FuelApproval
