// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginHeader_emb_f {\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.formBlock_c9PdK {\n  margin-top: 32px;\n}\n.formBlockShort_we7O9 {\n  margin-top: 24px;\n}\n.listWrapper_ET0KD {\n  margin-top: 32px;\n  margin-left: 16px;\n}\n.bullet_I5_eO {\n  font-size: 25px;\n  color: #434343;\n  margin-bottom: 2px;\n}\n.listItem_dwMfH {\n  display: flex;\n  align-items: center;\n  height: 22px;\n  margin-left: 2px;\n}\n.listItemFirst_YOtmN {\n  margin-top: 12px;\n  margin-left: 2px;\n  display: flex;\n  align-items: center;\n  height: 22px;\n}\n.itemTitle__IGES {\n  margin-left: 12px;\n}\n.responseError_huh2P {\n  margin-top: 24px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Login/components/SetPassword/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAEI;EACI,gBAAA;AAAR;AAIA;EACI,gBAAA;EACA,iBAAA;AAFJ;AAKA;EACI,eAAA;EACA,cAAA;EACA,kBAAA;AAHJ;AAMA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,gBAAA;AAJJ;AAMI;EACI,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAJR;AAQA;EACI,iBAAA;AANJ;AASA;EACI,gBAAA;EACA,kBAAA;AAPJ","sourcesContent":[".loginHeader {\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.formBlock {\n    margin-top: 32px;\n\n    &Short {\n        margin-top: 24px;\n    }\n}\n\n.listWrapper {\n    margin-top: 32px;\n    margin-left: 16px;\n}\n\n.bullet {\n    font-size: 25px;\n    color: #434343;\n    margin-bottom: 2px;\n}\n\n.listItem {\n    display: flex;\n    align-items: center;\n    height: 22px;\n    margin-left: 2px;\n\n    &First {\n        margin-top: 12px;\n        margin-left: 2px;\n        display: flex;\n        align-items: center;\n        height: 22px;\n    }\n}\n\n.itemTitle {\n    margin-left: 12px;\n}\n\n.responseError {\n    margin-top: 24px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginHeader": "loginHeader_emb_f",
	"formBlock": "formBlock_c9PdK",
	"formBlockShort": "formBlockShort_we7O9",
	"listWrapper": "listWrapper_ET0KD",
	"bullet": "bullet_I5_eO",
	"listItem": "listItem_dwMfH",
	"listItemFirst": "listItemFirst_YOtmN",
	"itemTitle": "itemTitle__IGES",
	"responseError": "responseError_huh2P"
};
export default ___CSS_LOADER_EXPORT___;
