import { FC, useRef } from 'react'
import TimeRangePicker from '../../ui/TimeRangePicker/TimeRangePicker'
import { userRoleMap } from '../../../constants/approval'
import { APPEAL_STATUS } from '../config'
import { Input, Select } from 'antd'
import style from './style.m.less'
import moment from 'moment'

interface IFilter {
    onChange: (data: string | number, key: string, obj?: any) => void
    dict: any[]
    filters: any
    isClient: boolean
    isOpend: boolean | undefined
}

const all = [{ label: 'Все', value: 0 }]

const Filter: FC<IFilter> = ({ onChange, dict, isClient = false, isOpend, filters }: IFilter) => {
    const divBlock: any = useRef(null)
    const marginSpace = (isClient ? 4 : 5) * 10
    const itemWidth = `${
        ((divBlock?.current?.offsetWidth || 0) - marginSpace) / (isClient ? 4 : 5)
    }px`
    const itemStyle = { width: itemWidth }

    const updateFilters = (data: any, key: string) => {
        if (key === 'date') {
            onChange('', '', {
                ts_from: data ? moment(data[0]).startOf('day') : undefined,
                ts_to: data ? moment(data[1]).endOf('day') : undefined,
            })
        } else {
            onChange(data, key)
        }
    }

    const usetRoleOptions = Array.from(userRoleMap, ([key, value]) => ({
        label: value,
        value: key,
    })).filter((el: any) => [0, 2, 3, 4].includes(el.value))

    const optionStatus = Object.entries(APPEAL_STATUS)
        .map((el: any) => ({
            label: el[1],
            value: Number(el[0]),
        }))
        .filter((el: any) => (isClient ? true : isOpend ? el.value < 7 : el.value >= 7))

    const optionsTopic = (dict || []).map((el: any) => ({ label: el.topic, value: el.id })) || []

    return (
        <div className={style.filter} ref={divBlock}>
            <div className={style.filterItem}>
                <div className={style.label}>Номер заявки</div>
                <Input
                    placeholder="Введите номер заявки"
                    className={style.input}
                    onChange={(event) => updateFilters(Number(event.target.value), 'id')}
                />
            </div>
            {!isClient && (
                <>
                    <div className={style.filterItem}>
                        <div className={style.label}>ФИО / Название организации</div>
                        <Input
                            placeholder="Введите ФИО или название"
                            className={style.input}
                            onChange={(event) => updateFilters(event.target.value, 'name')}
                        />
                    </div>
                    <div className={style.filterItem}>
                        <div className={style.label}>Роль пользователя</div>
                        <Select
                            options={usetRoleOptions}
                            defaultValue={0}
                            placeholder="Выберите роль заявки"
                            onChange={(tid) => updateFilters(tid, 'user_role')}
                            style={itemStyle}
                        />
                    </div>
                </>
            )}

            <div className={style.filterItem}>
                <div className={style.label}>Тема заявки</div>
                <Select
                    options={[...all, ...optionsTopic]}
                    loading={!dict}
                    defaultValue={0}
                    placeholder="Выберите тему заявки"
                    onChange={(tid) => updateFilters(tid, 'topic_id')}
                    style={itemStyle}
                />
            </div>
            {isClient && (
                <div className={style.filterItem}>
                    <div className={style.label}>Дата подачи</div>
                    <TimeRangePicker
                        onChange={(tid) => updateFilters(tid, 'date')}
                        style={itemStyle}
                        onlyBeforeDateAndToday={moment()}
                    />
                </div>
            )}
            <div className={style.filterItem}>
                <div className={style.label}>Статус заявки</div>
                <Select
                    options={[...all, ...optionStatus]}
                    loading={!dict}
                    value={filters.status_id || 0}
                    placeholder="Выберите статус заявки"
                    onChange={(tid) => updateFilters(tid, 'status_id')}
                    style={itemStyle}
                />
            </div>
        </div>
    )
}

export default Filter
