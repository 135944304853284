import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Input, Checkbox, DatePicker } from 'antd'
import { CommonBlock, ThreePartsBlock, FivePartsBlock, DocumentsBlock } from '../../components'
import { DropdownSelector, IconButton } from '../../../ui'
import style from './style.m.less'
import { ArrowSwap, Stamp } from '../../../../assets/icons'

const currentUserType = 'Юридическое лицо'

const userTypes = [
    { value: 'Юридическое лицо', id: '55677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Физическое лицо', id: '65677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Индивидуальный предприниматель', id: '7c23e409-c4c6-46dc-ad08-156c3456610d' },
]

const roleTypes = [
    { value: 'Клиент', id: '65677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Гость', id: '8c23e409-c4c6-46dc-ad08-156c3456610d' },
]

const documentTypes = [
    { value: 'Паспорт', id: '625677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Другой документ', id: '82c23e409-c4c6-46dc-ad08-156c3456610d' },
]

const сitizen = [
    { value: 'Гражданин РФ', id: '625677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Иностранный гражданин', id: '82c23e409-c4c6-46dc-ad08-156c3456610d' },
]

const ProfileLayout: FC = () => {
    const { t } = useTranslation()

    const [userType, setUserType] = useState(currentUserType)

    const [hasPatronymic, setHasPatronymic] = useState(true)
    const showPatronymic = () => {
        setHasPatronymic(!hasPatronymic)
    }

    const [isAddressesMatch, setIsAddressesMatch] = useState(true)
    const addressesMatch = () => {
        setIsAddressesMatch(!isAddressesMatch)
    }

    return (
        <>
            <CommonBlock
                required
                label={t('editMyProfile.role')}
                select={
                    <DropdownSelector
                        options={roleTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                    />
                }
            />
            <CommonBlock
                required
                margin
                label={t('editMyProfile.userType')}
                select={
                    <DropdownSelector
                        options={userTypes}
                        customStyle={{ width: '400px', marginLeft: '16px' }}
                        onChange={setUserType}
                    />
                }
            />
            <Divider />
            <CommonBlock
                required
                label={t('editMyProfile.surname')}
                select={<Input className={style.input} />}
            />
            <CommonBlock
                required
                margin
                label={t('editMyProfile.userName')}
                select={<Input className={style.input} />}
            />
            {/* {hasPatronymic && ( */}
            <CommonBlock
                required
                margin
                label={t('editMyProfile.lastName')}
                select={<Input className={style.input} disabled={!hasPatronymic} />}
            >
                <Checkbox className={style.checkbox} onClick={showPatronymic}>
                    {t('editMyProfile.noLastName')}
                </Checkbox>
            </CommonBlock>
            <Divider />
            {userType === 'Юридическое лицо' && (
                <>
                    <CommonBlock
                        required
                        label={t('editMyProfile.fullName')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.shortName')}
                        select={<Input className={style.input} />}
                    />
                    <ThreePartsBlock
                        labels={[
                            t('editMyProfile.inn'),
                            t('editMyProfile.ogrn'),
                            t('editMyProfile.kpp'),
                        ]}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.egrulCompName')}
                        select={<Input className={style.inputLong} />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.egrulDate')}
                        select={<Input className={style.inputLong} />}
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.status')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.phoneFax')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.website')}
                        select={<Input className={style.input} />}
                    />
                    <Divider />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.urAddress')}
                        select={<Input className={style.inputLong} />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.mailAddress')}
                        select={isAddressesMatch && <Input className={style.inputLong} />}
                    >
                        <Checkbox className={style.checkbox} onClick={addressesMatch}>
                            {t('editMyProfile.matchUr')}
                        </Checkbox>
                    </CommonBlock>
                </>
            )}
            {userType === 'Физическое лицо' && (
                <>
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.birthdate')}
                        select={<DatePicker className={style.datePicker} placeholder="" />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.citizenStatus')}
                        select={
                            <DropdownSelector
                                options={сitizen}
                                customStyle={{ width: '400px', marginLeft: '16px' }}
                            />
                        }
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.citizen')}
                        select={<Input value="Россия" className={style.input} disabled />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.identityDocument')}
                        select={
                            <DropdownSelector
                                options={documentTypes}
                                customStyle={{ width: '400px', marginLeft: '16px' }}
                            />
                        }
                    />
                    <FivePartsBlock />
                    <Divider />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.registrationAddress')}
                        select={<Input className={style.inputLong} />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.mailAddress')}
                        select={isAddressesMatch && <Input className={style.inputLong} />}
                    >
                        <Checkbox className={style.checkbox} onClick={addressesMatch}>
                            {t('editMyProfile.mathReg')}
                        </Checkbox>
                    </CommonBlock>
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.inn')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.snils')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.phoneFax')}
                        select={<Input className={style.input} />}
                    />
                    <Divider />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.organization')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.organizationINN')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock margin label="ОГРН" select={<Input className={style.input} />} />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.position')}
                        select={<Input className={style.input} />}
                    />
                </>
            )}
            {userType === 'Индивидуальный предприниматель' && (
                <>
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.birthdate')}
                        select={<DatePicker className={style.datePicker} placeholder="" />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.citizenStatus')}
                        select={
                            <DropdownSelector
                                options={сitizen}
                                customStyle={{ width: '400px', marginLeft: '16px' }}
                            />
                        }
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.citizen')}
                        select={<Input value="Россия" className={style.input} disabled />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.registrationAddress')}
                        select={<Input className={style.inputLong} />}
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.mailAddress')}
                        select={isAddressesMatch && <Input className={style.inputLong} />}
                    >
                        <Checkbox className={style.checkbox} onClick={addressesMatch}>
                            {t('editMyProfile.mathReg')}
                        </Checkbox>
                    </CommonBlock>
                    <CommonBlock
                        required
                        margin
                        label={t('editMyProfile.identityDocument')}
                        select={
                            <DropdownSelector
                                options={documentTypes}
                                customStyle={{ width: '400px', marginLeft: '16px' }}
                            />
                        }
                    />
                    <FivePartsBlock />
                    <Divider />
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.organization')}
                        select={
                            <Input
                                value="ИП Макаров Михаил Алексеевич"
                                className={style.input}
                                disabled
                            />
                        }
                    />
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.ogrnip')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.egrulCompName')}
                        select={<Input className={style.inputLong} />}
                    />
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.egrulDate')}
                        select={<Input className={style.inputLong} />}
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.position')}
                        select={<Input className={style.input} />}
                    />
                    <Divider />
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.inn')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        required
                        label={t('editMyProfile.snils')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock
                        margin
                        label={t('editMyProfile.phoneFax')}
                        select={<Input className={style.input} />}
                    />
                    <CommonBlock margin label="Сайт" select={<Input className={style.input} />} />
                </>
            )}
            <Divider />
            <DocumentsBlock />
            <Divider />
            <div className={style.eSignatureWrapper}>
                <div className={style.stampBlock}>
                    <Stamp />
                    <div className={style.stampOwner}>Макаров Михаил (ОАО Лето)</div>
                </div>
                <IconButton
                    icon={<ArrowSwap />}
                    customIconStyle={{ marginRight: '10px', marginTop: '5px' }}
                >
                    {t('editMyProfile.changeESignature')}
                </IconButton>
            </div>
            <Divider />
        </>
    )
}

export default ProfileLayout
