// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".primaryButton_Rja2I {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px 12px;\n  background: #e6f1ff;\n  border: 0;\n  color: #296dff;\n}\n.primaryButton_Rja2I:hover {\n  background: #d5e6ff;\n}\n.primaryButtonCancel_Z4M0t {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px 12px;\n  background: #fff;\n  border: 1px solid #bfbfbf;\n  color: #595959;\n}\n.primaryButtonCancel_Z4M0t:hover {\n  color: #528eff;\n  background: #fff;\n  border: 1px solid #528eff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/PrimaryIconButton/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;AACJ;AAAI;EACI,mBAAA;AAER;AACI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;EACA,cAAA;AACR;AACQ;EACI,cAAA;EACA,gBAAA;EACA,yBAAA;AACZ","sourcesContent":[".primaryButton {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 4px 12px;\n    background: #e6f1ff;\n    border: 0;\n    color: #296dff;\n    &:hover {\n        background: #d5e6ff;\n    }\n\n    &Cancel {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: 4px 12px;\n        background: #fff;\n        border: 1px solid #bfbfbf;\n        color: #595959;\n\n        &:hover {\n            color: #528eff;\n            background: #fff;\n            border: 1px solid #528eff;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryButton": "primaryButton_Rja2I",
	"primaryButtonCancel": "primaryButtonCancel_Z4M0t"
};
export default ___CSS_LOADER_EXPORT___;
