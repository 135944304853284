import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Edit: FC<IIconProps> = ({ size = 20 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.7225 5.00012L6.76347 9.95918C6.68609 10.0366 6.62193 10.1261 6.57356 10.2243L4.33093 14.7756C4.2465 14.9469 4.42738 15.1278 4.59874 15.0434L9.15002 12.8007C9.24818 12.7524 9.33774 12.6882 9.41512 12.6108L14.5258 7.50012M11.7225 5.00012L13.8945 2.8282C14.285 2.43768 14.9181 2.43768 15.3087 2.8282L16.5461 4.06564C16.9366 4.45616 16.9366 5.08933 16.5461 5.47985L14.5258 7.50012M11.7225 5.00012L14.5258 7.50012"
                    stroke="currentColor"
                />
                <path
                    d="M2.5 18.75L17.5 18.75"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
