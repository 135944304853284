import {
    IRegions,
    IRenewableEnergyTypes,
    IQualificationCategories,
    ICategoriesGo,
    IProductionModes,
    IOwnerships,
    IAids,
    IFuel,
} from '../../services/dictionaries'

export interface IDictionaries {
    regions: IRegions[]
    renewableEnergyTypes: IRenewableEnergyTypes[]
    qualificationCategories: IQualificationCategories[]
    categoriesGo: ICategoriesGo[]
    productionModes: IProductionModes[]
    ownerships: IOwnerships[]
    aids: IAids[]
    fuel: IFuel[]
}

export enum ELoading {
    None,
    Loading,
    Error,
    Loaded,
}

export interface IDictionariesResponse {
    loading: ELoading
    data?: IDictionaries
}
