// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper_R4Yf4 {\n  display: flex;\n}\n.messageHeader_wNwxW {\n  font-size: 16px;\n  line-height: 24px;\n}\n.messageWrapper_u_o74 {\n  margin-left: 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n.messageContent_FkUpe {\n  margin-top: 8px;\n  font-size: 14px;\n  line-height: 22px;\n  color: #595959;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EditMyProfile/components/SuccessMessage/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;EACI,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AADJ;AAIA;EACI,eAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ","sourcesContent":[".mainWrapper {\n    display: flex;\n}\n\n.messageHeader {\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.messageWrapper {\n    margin-left: 16px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.messageContent {\n    margin-top: 8px;\n    font-size: 14px;\n    line-height: 22px;\n    color: #595959;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": "mainWrapper_R4Yf4",
	"messageHeader": "messageHeader_wNwxW",
	"messageWrapper": "messageWrapper_u_o74",
	"messageContent": "messageContent_FkUpe"
};
export default ___CSS_LOADER_EXPORT___;
