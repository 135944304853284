// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttonsBlock_NqZW5 {\n  display: flex;\n}\n.buttonsBlockMargin_FQWWA {\n  margin-top: 32px;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContinueRegistration/components/ButtonsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,gBAAA;EACA,aAAA;AACR","sourcesContent":[".buttonsBlock {\n    display: flex;\n\n    &Margin {\n        margin-top: 32px;\n        display: flex;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsBlock": "buttonsBlock_NqZW5",
	"buttonsBlockMargin": "buttonsBlockMargin_FQWWA"
};
export default ___CSS_LOADER_EXPORT___;
