import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Telegram: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.71592 8.73629C6.52215 7.51759 8.39029 6.70779 9.32837 6.31492C11.9983 5.20044 12.5595 5.00802 12.9203 5C13.0005 5 13.1769 5.01604 13.2972 5.11225C13.3934 5.19243 13.4174 5.29666 13.4335 5.37684C13.4495 5.45701 13.4656 5.62539 13.4495 5.75367C13.3052 7.27705 12.6798 10.9732 12.3591 12.673C12.2228 13.3946 11.9582 13.6352 11.7016 13.6592C11.1404 13.7073 10.7154 13.2904 10.1783 12.9376C9.32837 12.3844 8.85532 12.0396 8.02949 11.4944C7.07537 10.869 7.69274 10.5243 8.23795 9.96301C8.38227 9.81869 10.8437 7.57371 10.8918 7.37326C10.8999 7.34921 10.8999 7.253 10.8437 7.20489C10.7876 7.15678 10.7074 7.17282 10.6433 7.18886C10.5551 7.20489 9.20811 8.10288 6.58629 9.87481C6.20143 10.1394 5.85667 10.2677 5.54398 10.2597C5.19921 10.2516 4.54175 10.0672 4.04465 9.90688C3.44331 9.71446 2.96225 9.61023 3.00234 9.27348C3.02639 9.09709 3.26692 8.9207 3.71592 8.73629Z"
                    fill="white"
                />
            </svg>
        )}
    />
)
