import { FC, useState } from 'react'
import { ArrowLeft } from '../../assets/icons'
import { ETab } from './components/TagTabs/TagTabs'
import { AddFromFile, AddManually, TagTabs } from './components'
import { Card, IconButton } from '../ui'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import style from './style.m.less'

const GOSubmitDataKU: FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    const [tab, setTab] = useState(ETab.Manually)

    return (
        <div className={style.container}>
            <div className={style.titleWrapper}>
                <span className={style.backLink} onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </span>
                <div>
                    <h1 className={style.title}>Подача данных по КУ</h1>
                </div>
            </div>
            <Card className={style.card}>
                <p className={style.cardTitle}>Как Вы хотите добавить данные?</p>
                {/* <div className={style.buttonWrapper}>
                    <IconButton onClick={() => setIsAddManually(true)}>Вручную</IconButton>
                    <IconButton onClick={() => setIsAddManually(false)}>Из файла</IconButton>
                </div> */}
                <TagTabs tab={tab} onChange={setTab} />
                <div className={style.page}>
                    {tab === ETab.Manually ? <AddManually /> : <AddFromFile />}
                </div>
            </Card>
        </div>
    )
}

export default GOSubmitDataKU
