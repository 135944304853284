import { FC, CSSProperties } from 'react'
import styles from './style.m.less'
import { Empty } from 'antd'

import imgEmpty from '../../../assets/images/folder-cross.jpg'

export type IEmpty = {
    imageStyle?: CSSProperties
    iconName?: string
    descriptionText?: string
}

export const CustomEmpty: FC<IEmpty> = ({ descriptionText = '', imageStyle = {} }) => {

    return (
        <Empty
            image={imgEmpty}
            imageStyle={imageStyle}
            description={descriptionText ? <div className={styles.descriptionText}><span >{descriptionText}</span></div> : undefined}
        />
    )
}

export default CustomEmpty
