import { useCallback, useState } from 'react'
import request from '../../services/request'

const useGetFiles = () => {
    const [loadingIds, setLoadingIds] = useState<any>({})
    const [files, setFiles] = useState<any>({})

    const handleClickDownload = async (id: string) => {
        try {
            if (!id) {
                return
            }

            // для каждого id файла ставим свой loading
            setLoadingIds((lastState: any) => ({...lastState , [id]: true }))
            const body = await request.get(`/api/documents/v1/file/${id}`, {
                responseType: 'blob',
            })
 
            const arrayBuffer = await body?.data.arrayBuffer()

            var base64String = btoa(new Uint8Array(arrayBuffer).reduce(
                function (data, byte) {
                    return data + String.fromCharCode(byte);
                },
                ''
            ));

            setFiles((prevState: any) => ({
                ...prevState,
                [id]: base64String,
              }))
        } finally {
            setLoadingIds((lastState: any) => ({...lastState , [id]: false }))
        }
    }

    const downloadList = async (list: string[]) => {
        for (let i = 0; i < list.length; i++) {
            await handleClickDownload(list[i])
        }
    }

    const downloadFile = useCallback((id: string) => {
        handleClickDownload(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const downloadFileList = useCallback((list: string[]) => {
        downloadList(list)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        files,
        downloadFile,
        downloadFileList,
        loadingIds,
    }
}

export default useGetFiles
