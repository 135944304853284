// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper_kga5A {\n  margin-bottom: 16px;\n}\n.headerWrapper_d14TF {\n  display: flex;\n  gap: 16px;\n}\n.header_vhLS1 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.text_J9tZe {\n  width: 360px;\n  padding: 0 40px;\n}\n.buttonsBlock_u0021 {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/FuelApproval/components/FuelCard/ApproveModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEA;EACI,aAAA;EACA,SAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,YAAA;EACA,eAAA;AAFJ;AAKA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAHJ","sourcesContent":[".modalWrapper {\n    margin-bottom: 16px;\n}\n\n.headerWrapper {\n    display: flex;\n    gap: 16px;\n}\n\n.header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.text {\n    width: 360px;\n    padding: 0 40px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper_kga5A",
	"headerWrapper": "headerWrapper_d14TF",
	"header": "header_vhLS1",
	"text": "text_J9tZe",
	"buttonsBlock": "buttonsBlock_u0021"
};
export default ___CSS_LOADER_EXPORT___;
