import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Tag, DeleteModal, IconButton } from '../ui'
import { ArrowLeft } from '../../assets/icons'

import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import { EUserRole } from '../../constants/approval'
import { useAppSelector } from '../../store/hooks'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import { Space, Spin } from 'antd'
import useGetFiles from '../hooks/useGetFiles'
import OtherNews from '../../../src/components/OtherNews/OtherNews'

const NewsOrEvent: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()

    const userRole = useAppSelector((state) => state.user.data.user_role)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const handleClickNews = () => {
        navigate(`/news`)
    }

    const {
        fetch: getNews,
        result: news,
        isLoading: isLoadingNews,
    } = useRequest({
        link: `/api/content/v1/news`,
        method: RequestTypes.get,
    })

    useEffect(() => {
        getNews({
            addToLink: `?id=${id}`,
            body: {},
            errorCodeMessage: true,
        })
    }, [id])

    const {
        fetch: delNews,
        result: deleteResult,
        isLoading: deleteNewsLoading,
    } = useRequest({
        link: `/api/content/v1/news`,
        method: RequestTypes.delete,
    })

    const { files, downloadFileList, loadingIds: loadingFileId } = useGetFiles()

    useEffect(() => {
        if (news?.document?.id) {
            downloadFileList([news.document.id])
        }
    }, [news])

    const isAdmin = [EUserRole.Administrator, userRole === EUserRole.SuperAdmin].includes(userRole)

    const handleClickChange = () => {
        navigate(`/news/deal/change/${id}`)
    }
    const handleClickDelete = async () => {
        await delNews({
            addToLink: `?id=${id}`,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Успешное удаление новости',
            },
            errorCodeMessage: true,
        })
        handleClickNews()
    }

    return (
        <>
            <div className={style.newsOrEvent}>
                <div className={style.container}>
                    <div className={style.titleWrapper}>
                        <span className={style.backLink} onClick={() => navigate(-1)}>
                            <ArrowLeft />
                        </span>
                        <Space
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <h1 className={style.title}>{news?.title || ''}</h1>
                            <Space>
                                {isAdmin && (
                                    <IconButton
                                        style={{ marginRight: '8px' }}
                                        onClick={() => handleClickChange()}
                                    >
                                        {t('newsAndEvents.editNews')}
                                    </IconButton>
                                )}
                                {isAdmin && (
                                    <IconButton
                                        onClick={() => setShowDeleteModal(true)}
                                        loading={deleteNewsLoading}
                                    >
                                        {t('newsAndEvents.deleteNews')}
                                    </IconButton>
                                )}
                            </Space>
                        </Space>
                        <DeleteModal
                            isModalVisible={showDeleteModal}
                            headerText="Удалить новость"
                            messageText="Вы уверены, что хотите безвозвратно удалить новость?"
                            onCancel={() => setShowDeleteModal(false)}
                            onDelete={handleClickDelete}
                        />
                        <div className={style.mainHeader}></div>
                    </div>
                    <Spin spinning={isLoadingNews}>
                        <div className={style.content}>
                            <div className={style.info}>
                                <span className={style.date}>
                                    {news?.ts_created
                                        ? moment(news?.ts_created).format('DD.MM.YYYY')
                                        : ''}
                                </span>
                                <span>
                                    {news?.news_type_id === 2 ? (
                                        <Tag color="green">#{t('newsAndEvents.events')}</Tag>
                                    ) : news?.news_type_id === 1 ? (
                                        <Tag color="blue">#{t('newsAndEvents.news')}</Tag>
                                    ) : null}
                                </span>
                            </div>
                            {files && news?.document?.id && files[news.document.id] && (
                                <Spin spinning={loadingFileId[news.document.id]}>
                                    <div className={style.imageWrapper}>
                                        <img
                                            src={`data:application/octet-stream;base64, ${
                                                files[news.document.id]
                                            }`}
                                            className={style.image}
                                            alt="title"
                                        />
                                    </div>
                                </Spin>
                            )}
                            <div className={style.mainDecription}>
                                {(news?.body || '').split('\n').map((el: string, ind: number) => (
                                    <p key={ind}>{el}</p>
                                ))}
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
            <OtherNews style={{ marginBottom: '100px' }} />
        </>
    )
}

export default NewsOrEvent
