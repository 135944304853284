import { useCallback, useState } from 'react'
import FileSaver from 'file-saver'
import request from '../../services/request'

const useDownLoadFile = () => {
    const [loadingDownLoad, setLoadingDownLoad] = useState<boolean>(false)
    const [downloadedId, setDowloadedId] = useState<string | null>(null)

    const handleClickDownload = async (id: string, file_name = '') => {
        try {
            if (!id) {
                return
            }
            setDowloadedId(id)
            setLoadingDownLoad(true)

            const body = await request.get(`/api/documents/v1/file/${id}`, {
                responseType: 'blob',
            })

            const bodyBlob = new Blob([body?.data], {
                type: 'application/octet-stream',
            })

            FileSaver.saveAs(
                bodyBlob,
                file_name.length ? file_name : decodeURI(body?.headers.filename),
            )
        } finally {
            setDowloadedId(null)
            setLoadingDownLoad(false)
        }
    }

    const downloadList = async (list: string[]) => {
        for (let i = 0; i < list.length; i++) {
            await handleClickDownload(list[i])
        }
    }

    const downloadNamedList = async (list: { id: string; file_name: string }[]) => {
        for (let i = 0; i < list.length; i++) {
            await handleClickDownload(list[i].id, list[i].file_name)
        }
    }

    const downloadFile = useCallback((id: string, file_name = '') => {
        handleClickDownload(id, file_name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const downloadFileList = useCallback((list: string[]) => {
        downloadList(list)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const downloadFileNamedList = useCallback((list: { id: string; file_name: string }[]) => {
        downloadNamedList(list)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isAllFilesDownloaded =
        Object.values.length !== 0
            ? Object.values(loadingDownLoad).some((el) => el === true)
            : false

    return {
        downloadFile,
        downloadFileList,
        downloadFileNamedList,
        loadingDownLoad,
        downloadedId,
        isAllFilesDownloaded,
    }
}

export default useDownLoadFile
