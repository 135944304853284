import { FC } from 'react'
import { Tabs } from 'antd'

import style from './style.m.less'
import { Card } from '../ui'
import { Closed, Opened } from './components'
import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

const ZDApproval: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.container}>
            <h1 className={style.title}>{t('zdApproval.title')}</h1>
            <div className={style.tabs}>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('zdApproval.opened')} key="1">
                        <Card>
                            <Opened />
                        </Card>
                    </TabPane>
                    <TabPane tab={t('zdApproval.closed')} key="2">
                        <Card>
                            <Closed />
                        </Card>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default ZDApproval
