import { FC, useState } from 'react'
import { Alert, Input } from 'antd'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

interface IRefuseToApproveModal {
    isModalVisible: boolean
    onConfirm: (text: string) => void
    onCancel: () => void
}

const RefuseToApproveModal: FC<IRefuseToApproveModal> = ({
    isModalVisible,
    onConfirm,
    onCancel,
}) => {
    const { t } = useTranslation()

    const [text, setText] = useState('')

    const handleCancel = () => {
        onCancel()
    }

    const handleConfirm = () => {
        onConfirm(text)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>{t('gOApprovalItem.refuseToApprove')}</div>
            <div className={style.dataBlock}>
                <div>{t('gOApprovalItem.reasonRefusal')}</div>
                <TextArea
                    className={style.textArea}
                    placeholder={t('gOApprovalItem.yourComment')}
                    rows={4}
                    onChange={(event) => setText(event.target.value)}
                    value={text}
                />
            </div>
            <Alert
                className={style.activationAlert}
                message={t('gOApprovalItem.userSeeComment')}
                type="warning"
                showIcon
            />
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleConfirm}
                    cancelAction={handleCancel}
                    confirmTitle={t('gOApprovalItem.modalButtonTitle')}
                />
            </div>
        </Modal>
    )
}

export default RefuseToApproveModal
