import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FooterInfo, FooterNav, FooterUser } from './components'
import style from './style.m.less'

const Footer: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.footerMain}>
            <div className={style.footerTop}>
                <FooterInfo />
                <FooterNav />
                <FooterUser />
            </div>
        </div>
    )
}

export default Footer
