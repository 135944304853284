import { FC, useState } from 'react'

import style from './style.m.less'
import { Card, IconButton } from '../ui'
import { KUTable, UnloadingParamsModal } from './components'
import { useTranslation } from 'react-i18next'
import { Receipt, Import } from '../../assets/icons'
import { Link } from 'react-router-dom'

const GODataKU: FC = () => {
    const { t } = useTranslation()

    const [isUnloadingParamsModalVisible, setIsUnloadingParamsModalVisible] = useState(false)
    const openUnloadingParamsModal = () => {
        setIsUnloadingParamsModalVisible(true)
    }

    return (
        <div className={style.container}>
            <h1 className={style.title}>Данные КУ</h1>
            <div className={style.buttonWrapper}>
                <Link to="/ku-submit-data">
                    <IconButton icon={<Receipt />}>Подать данные по КУ</IconButton>
                </Link>
                <IconButton icon={<Import />} onClick={openUnloadingParamsModal}>
                    Выгрузить данные за период
                </IconButton>
            </div>
            <div className={style.tabs}>
                <Card>
                    <KUTable />
                </Card>
            </div>
            <UnloadingParamsModal
                isModalVisible={isUnloadingParamsModalVisible}
                setIsModalVisible={setIsUnloadingParamsModalVisible}
            />
        </div>
    )
}

export default GODataKU
