// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bullet_YhKrY {\n  font-size: 25px;\n  color: #434343;\n  margin-bottom: 2px;\n  line-height: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/BulletDot/style.m.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;AACJ","sourcesContent":[".bullet {\n    font-size: 25px;\n    color: #434343;\n    margin-bottom: 2px;\n    line-height: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bullet": "bullet_YhKrY"
};
export default ___CSS_LOADER_EXPORT___;
