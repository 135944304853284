import { all, call, put, StrictEffect, takeEvery } from 'redux-saga/effects'
import { getDictionaries, updateDictionaries } from './index'
import {
    getAids,
    getCategoriesGo,
    getFuel,
    getOwnerships,
    getProductionModes,
    getQualificationCategories,
    getRegions,
    getRenewableEnergyTypes,
} from '../../services/dictionaries'
import { ELoading } from './interfaces'

function* handleDictionaries() {
    try {
        yield put(updateDictionaries({ loading: ELoading.Loading }))

        const [
            regions,
            qualificationCategories,
            renewableEnergyTypes,
            categoriesGo,
            productionModes,
            ownerships,
            aids,
            fuel,
        ] = yield all([
            call(getRegions),
            call(getQualificationCategories),
            call(getRenewableEnergyTypes),
            call(getCategoriesGo),
            call(getProductionModes),
            call(getOwnerships),
            call(getAids),
            call(getFuel),
        ])

        yield put(
            updateDictionaries({
                loading: ELoading.Loaded,
                data: {
                    regions: regions.data,
                    renewableEnergyTypes: renewableEnergyTypes.data,
                    qualificationCategories: qualificationCategories.data,
                    categoriesGo: categoriesGo.data,
                    productionModes: productionModes.data,
                    ownerships: ownerships.data,
                    aids: aids.data,
                    fuel: fuel.data,
                },
            }),
        )
    } catch {
        yield put(updateDictionaries({ loading: ELoading.Error }))
    }
}

export function* watchDictionaries(): Generator<StrictEffect> {
    yield takeEvery(getDictionaries, handleDictionaries)
}
