// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eSignatureModalWrapper_t7wFm {\n  margin-bottom: 16px;\n}\n.modalHeader_Hw_pm {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 24px;\n}\n.modalText_tQedD {\n  margin-top: 24px;\n}\n.eSignatureModalItemWrapper_VO4h7 {\n  display: flex;\n  gap: 24px;\n  margin-bottom: 12px;\n}\n.eSignatureModalItemName_mv2tM {\n  width: 150px;\n  color: #8C8C8C;\n}\n.buttonsBlock_P3wtz {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.eSignatureModalAlert_u3BDo {\n  background: #E6F7FF;\n  border: 1px solid #BAE7FF;\n  border-radius: 12px;\n  width: 436px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MyProfile/components/ESignatureModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AAFJ;AAKA;EACI,YAAA;EACA,cAAA;AAHJ;AAMA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAJJ;AAOA;EACI,mBAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;AALJ","sourcesContent":[".eSignatureModalWrapper {\n    margin-bottom: 16px;\n}\n\n.modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 24px;\n}\n\n.modalText {\n    margin-top: 24px;\n}\n\n.eSignatureModalItemWrapper {\n    display: flex;\n    gap: 24px;\n    margin-bottom: 12px;\n}\n\n.eSignatureModalItemName {\n    width: 150px;\n    color: #8C8C8C;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.eSignatureModalAlert {\n    background: #E6F7FF;\n    border: 1px solid #BAE7FF;\n    border-radius: 12px;\n    width: 436px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"eSignatureModalWrapper": "eSignatureModalWrapper_t7wFm",
	"modalHeader": "modalHeader_Hw_pm",
	"modalText": "modalText_tQedD",
	"eSignatureModalItemWrapper": "eSignatureModalItemWrapper_VO4h7",
	"eSignatureModalItemName": "eSignatureModalItemName_mv2tM",
	"buttonsBlock": "buttonsBlock_P3wtz",
	"eSignatureModalAlert": "eSignatureModalAlert_u3BDo"
};
export default ___CSS_LOADER_EXPORT___;
