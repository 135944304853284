// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputsWrapperShort_EgNyV {\n  width: 608px;\n  margin-top: 16px;\n  margin-left: 216px;\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n}\n.star_lrdGj {\n  margin-left: 4px;\n  display: flex;\n  align-items: center;\n  color: #f5222d;\n}\n.inputBlock_xFm9B {\n  display: flex;\n}\n.inputSmall_ipirR {\n  width: 80px;\n  border-radius: 8px;\n}\n.inputMiddle_ua6x_ {\n  width: 200px;\n  border-radius: 8px;\n}\n.datePicker_UWpHh {\n  height: 32px;\n  width: 200px;\n  border-radius: 8px;\n}\n.textAreaBlock_O7A27 {\n  width: 608px;\n  margin-left: 216px;\n  margin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EditMyProfile/components/FivePartsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;AAEA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;AADJ;AAIA;EACI,WAAA;EACA,kBAAA;AAFJ;AAKA;EACI,YAAA;EACA,kBAAA;AAHJ;AAMA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;AAJJ;AAOA;EACI,YAAA;EACA,kBAAA;EACA,gBAAA;AALJ","sourcesContent":[".inputsWrapperShort {\n    width: 608px;\n    margin-top: 16px;\n    margin-left: 216px;\n    display: flex;\n    justify-content: space-between;\n    gap: 16px;\n}\n\n.star {\n    margin-left: 4px;\n    display: flex;\n    align-items: center;\n    color: #f5222d;\n}\n\n.inputBlock {\n    display: flex;\n}\n\n.inputSmall {\n    width: 80px;\n    border-radius: 8px;\n}\n\n.inputMiddle {\n    width: 200px;\n    border-radius: 8px;\n}\n\n.datePicker {\n    height: 32px;\n    width: 200px;\n    border-radius: 8px;\n}\n\n.textAreaBlock {\n    width: 608px;\n    margin-left: 216px;\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsWrapperShort": "inputsWrapperShort_EgNyV",
	"star": "star_lrdGj",
	"inputBlock": "inputBlock_xFm9B",
	"inputSmall": "inputSmall_ipirR",
	"inputMiddle": "inputMiddle_ua6x_",
	"datePicker": "datePicker_UWpHh",
	"textAreaBlock": "textAreaBlock_O7A27"
};
export default ___CSS_LOADER_EXPORT___;
