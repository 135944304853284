// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_wNXam {\n  display: flex;\n  gap: 16px;\n  justify-content: start;\n  width: 100%;\n  margin-top: 24px;\n}\n.moreFilterItem_RI9_H {\n  width: 233px;\n}\n.label__hRGp {\n  margin-bottom: 2px;\n}\n.input_ux8VU {\n  border-radius: 8px;\n}\n.select_MQ3Bx {\n  width: 100%;\n  height: 32px;\n}\n.moreFilter_UhhCX {\n  display: flex;\n  gap: 4px;\n  margin-top: 12px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  color: #296dff;\n  cursor: pointer;\n}\n.leftGapBlock_q1qt1 {\n  width: 296px;\n}\n.icon_cQdHA {\n  font-size: 10px;\n  margin-top: 7px;\n}\n.inputBlock_ez1Ko {\n  margin-top: 2px;\n  width: 296px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GreenTools/components/RegisteredLayout/components/MoreFilters/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AAEA;EACI,YAAA;AAAJ;AAGA;EACI,kBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;EACI,WAAA;EACA,YAAA;AAHJ;AAMA;EACI,aAAA;EACA,QAAA;EACA,gBAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAJJ;AAOA;EACI,YAAA;AALJ;AAQA;EACI,eAAA;EACA,eAAA;AANJ;AASA;EACI,eAAA;EACA,YAAA;AAPJ","sourcesContent":[".filter {\n    display: flex;\n    gap: 16px;\n    justify-content: start;\n    width: 100%;\n    margin-top: 24px;\n}\n\n.moreFilterItem {\n    width: 233px;\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.select {\n    width: 100%;\n    height: 32px;\n}\n\n.moreFilter {\n    display: flex;\n    gap: 4px;\n    margin-top: 12px;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    color: #296dff;\n    cursor: pointer;\n}\n\n.leftGapBlock {\n    width: 296px;\n}\n\n.icon {\n    font-size: 10px;\n    margin-top: 7px;\n}\n\n.inputBlock {\n    margin-top: 2px;\n    width: 296px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "filter_wNXam",
	"moreFilterItem": "moreFilterItem_RI9_H",
	"label": "label__hRGp",
	"input": "input_ux8VU",
	"select": "select_MQ3Bx",
	"moreFilter": "moreFilter_UhhCX",
	"leftGapBlock": "leftGapBlock_q1qt1",
	"icon": "icon_cQdHA",
	"inputBlock": "inputBlock_ez1Ko"
};
export default ___CSS_LOADER_EXPORT___;
