import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const BlueLock: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5 8.33268V6.66602C5 3.90768 5.83333 1.66602 10 1.66602C14.1667 1.66602 15 3.90768 15 6.66602V8.33268"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0013 15.4167C11.1519 15.4167 12.0846 14.4839 12.0846 13.3333C12.0846 12.1827 11.1519 11.25 10.0013 11.25C8.85071 11.25 7.91797 12.1827 7.91797 13.3333C7.91797 14.4839 8.85071 15.4167 10.0013 15.4167Z"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.168 18.334H5.83464C2.5013 18.334 1.66797 17.5007 1.66797 14.1673V12.5007C1.66797 9.16732 2.5013 8.33398 5.83464 8.33398H14.168C17.5013 8.33398 18.3346 9.16732 18.3346 12.5007V14.1673C18.3346 17.5007 17.5013 18.334 14.168 18.334Z"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
