import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ESignatureModal } from '../../../Profile/components'
import { Stamp } from '../../../../assets/icons'

import style from './style.m.less'

interface ISign {
    value?: string
    certificateData: any
    requestId: string
}

const Sign: FC<ISign> = ({ value, certificateData, requestId }) => {
    const { t } = useTranslation()
    const [isESignatureModalVisible, setIsESignatureModalVisible] = useState(false)

    const openESignatureModalModal = () => {
        setIsESignatureModalVisible(true)
    }

    return (
        <>
            <div className={style.wrapper} onClick={openESignatureModalModal}>
                <div className={style.title}>Электронная подпись</div>
                {value && (
                    <div className={style.sign}>
                        <Stamp />
                        <div className={style.stampOwner}>
                            {value.certificate_data?.common_name}
                        </div>
                    </div>
                )}
            </div>
            {value && (
                <ESignatureModal
                    certificateData={certificateData ? certificateData : value?.certificate_data}
                    requestId={requestId}
                    isModalVisible={isESignatureModalVisible}
                    setIsModalVisible={setIsESignatureModalVisible}
                />
            )}
        </>
    )
}

export default Sign
