import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Sort1: FC<IIconProps> = ({ size = 16 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.83333 12.5C1.64924 12.5 1.5 12.3508 1.5 12.1667C1.5 11.9826 1.64924 11.8333 1.83333 11.8333H4.83333C5.01743 11.8333 5.16667 11.9826 5.16667 12.1667C5.16667 12.3508 5.01743 12.5 4.83333 12.5H1.83333ZM1.5 3.83333C1.5 3.64924 1.64924 3.5 1.83333 3.5H14.1667C14.3508 3.5 14.5 3.64924 14.5 3.83333C14.5 4.01743 14.3508 4.16667 14.1667 4.16667H1.83333C1.64924 4.16667 1.5 4.01743 1.5 3.83333ZM1.83333 8.33333C1.64924 8.33333 1.5 8.18409 1.5 8C1.5 7.8159 1.64924 7.66667 1.83333 7.66667H9.5C9.68409 7.66667 9.83333 7.8159 9.83333 8C9.83333 8.1841 9.6841 8.33333 9.5 8.33333H1.83333Z"
                    stroke="currentColor"
                />
            </svg>
        )}
    />
)
