import { FC, useRef, useLayoutEffect } from 'react'
import style from './style.m.less'

export const TextWrap: FC = ({ id, setNoShowIcons, openTextIds, doc }: any) => {

    const ref = useRef()

    useLayoutEffect(() => {
        if (ref?.current?.scrollHeight <= 44) {
            setNoShowIcons((s: any) => ({ ...s, [id]: true }))
        }
        if (ref?.current?.scrollHeight > 44) {
            setNoShowIcons((s: any) => ({ ...s, [id]: false }))
        }
    }, [ref?.current?.scrollHeight])

    return (
        <div
            id={id}
            ref={ref}
            className={style.blockWithText}
            style={{
                width: '368px',
                maxWidth: '368px',
                padding: 0,
                height: openTextIds[doc.id] ? '' : '44px',
                minHeight: '44px',
                display: openTextIds[doc.id] ? 'block' : '-webkit-box',
            }}
        >
            {doc?.comment || ''}
        </div>
    )
}
