import { FC, useEffect, useState } from 'react'
import { Divider, Input, message } from 'antd'
import {
    Card,
    DocumentsBlock,
    PrimaryButton,
    PrimaryIconButton,
    IFile,
    LoadingContainer,
    DatePicker,
} from '../ui'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import { getOperationStatus, postKU } from './config'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import style from './style.m.less'
import { AdminSubmissionResponse, OperationStatusResponse } from './types'

const KUSubmitData: FC = () => {
    const navigate = useNavigate()
    const [files, setFiles] = useState<IFile[]>([])
    const { fetch: sendKU, isLoading } = useRequest<AdminSubmissionResponse>(postKU)
    const { fetch: checkOperationStatus } = useRequest<OperationStatusResponse>(getOperationStatus)
    const [date, setDate] = useState<string>()
    const [operationId, setOperationId] = useState('')

    const fileHandler = (list: any) => {
        if (files.length <= 6) {
            return setFiles(list)
        }
    }

    const handleConfirm = async () => {
        const operation_id = uuidv4()
        setOperationId(operation_id)
        if (files.length) {
            const body = {
                documents: files,
                period: moment(date).format('YYYY-MM'),
                operation_id,
            }
            sendKU({
                body,
                successMessage: {
                    title: 'Успешно',
                    description: 'Данные по КУ успешно поданы',
                },
                onSuccess: (result) => {
                    if (result.id) {
                        navigate('/ku-data')
                    }
                },
                onError: () => {
                    let intervalId: NodeJS.Timer
                    ;(function () {
                        intervalId = setInterval(() => {
                            checkOperationStatus({
                                getParams: {
                                    id: operationId,
                                },
                                showError: false,
                                onSuccess: (result) => {
                                    if (result.status_id === 1) {
                                        setOperationId('')
                                        clearInterval(intervalId)
                                        navigate('/ku-data')
                                    } else if (result.status_id === 2) {
                                        setOperationId('')
                                        clearInterval(intervalId)
                                        message.error({
                                            content: 'Ошибка обработки файла. Повторите попытку.',
                                        })
                                    }
                                },
                            })
                        }, 3000)
                    })()
                },
                errorCodeMessage: true,
            })
        } else {
            message.error('Невозможно подать данные - выберите хотя бы один файл')
        }
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <div className={style.container}>
                <div className={style.titleWrapper}>
                    <div>
                        <h1 className={style.title}>Подача данных по КУ</h1>
                    </div>
                </div>
                <Card>
                    <div className={style.formItem}>
                        <div className={style.label}>Отчетный период</div>
                        <DatePicker
                            defaultValue={date}
                            onChange={(value) => setDate(moment.utc(value).format())}
                            format="MMMM, YYYY"
                            className={style.monthInput}
                            picker="month"
                        />
                    </div>
                    <DocumentsBlock
                        value={files}
                        onChange={fileHandler}
                        hideComment
                        serviceId={9}
                        description="Загрузите файлы, из которых хотите импортировать данные (не более 6)"
                        customStyle={{ width: '440px', marginTop: '24px' }}
                        disabled={files.length >= 6}
                    />
                    <Divider />
                    <div className={style.buttonWrapper}>
                        <PrimaryButton
                            onClick={() => navigate(-1)}
                            value="Отмена"
                            isCancel
                            customStyle={{ padding: '8px 16px' }}
                        />
                        <PrimaryIconButton
                            size="large"
                            onClick={handleConfirm}
                            value="Подать данные"
                            disabled={!files.length}
                            customStyle={{ padding: '8px 16px' }}
                        />
                    </div>
                </Card>
            </div>
        </LoadingContainer>
    )
}

export default KUSubmitData
