// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filtersMain_gJM5U {\n  width: 416px;\n  height: max-content;\n  padding: 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.resetButton_MrLxy {\n  margin-top: 32px;\n}\n.filterTitle_WTuM6 {\n  margin-top: 24px;\n}\n.inputWrapper_hoAFq {\n  display: flex;\n  gap: 5px;\n  margin-top: 16px;\n}\n.input_BncFt {\n  border: 1px solid #D3D5DA;\n  border-radius: 8px;\n}\n.filterItem_oswnZ {\n  margin-top: 24px;\n}\n.filterItem_oswnZ .ant-picker-range {\n  width: 100%;\n}\n.label__4qPQ {\n  margin-bottom: 2px;\n}\n.rangePicker_PDZ6e {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Generators/components/Filters/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,QAAA;EACA,gBAAA;AAFJ;AAKA;EACI,yBAAA;EACA,kBAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAGA;EAGQ,WAAA;AAHR;AAOA;EACI,kBAAA;AALJ;AAQA;EACI,WAAA;AANJ","sourcesContent":[".filtersMain {\n    width: 416px;\n    height: max-content;\n    padding: 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.resetButton {\n    margin-top: 32px;\n}\n\n.filterTitle {\n    margin-top: 24px;\n}\n\n.inputWrapper {\n    display: flex;\n    gap: 5px;\n    margin-top: 16px;\n}\n\n.input {\n    border: 1px solid #D3D5DA;\n    border-radius: 8px;\n}\n\n.filterItem {\n    margin-top: 24px;\n    :global(.ant-picker-range) {\n        width: 100%;\n    }\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.rangePicker {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersMain": "filtersMain_gJM5U",
	"resetButton": "resetButton_MrLxy",
	"filterTitle": "filterTitle_WTuM6",
	"inputWrapper": "inputWrapper_hoAFq",
	"input": "input_BncFt",
	"filterItem": "filterItem_oswnZ",
	"label": "label__4qPQ",
	"rangePicker": "rangePicker_PDZ6e"
};
export default ___CSS_LOADER_EXPORT___;
