import { FC } from 'react'
import { Input } from 'antd'

import style from './style.m.less'
import { DatePicker, TimeRangePicker, DropdownSelector2 } from '../../../ui'
import { useTranslation } from 'react-i18next'
import { actionTypeMap, EActionType, EUserRole, userRoleMap } from '../../../../constants/approval'

const actionTypeOptions = [
    { id: EActionType.All, value: EActionType.All, text: actionTypeMap.get(EActionType.All) },
    { id: EActionType.Edit, value: EActionType.Edit, text: actionTypeMap.get(EActionType.Edit) },
    {
        id: EActionType.ChangeRole,
        value: EActionType.ChangeRole,
        text: actionTypeMap.get(EActionType.ChangeRole),
    },
    {
        id: EActionType.ContinueRegistration,
        value: EActionType.ContinueRegistration,
        text: actionTypeMap.get(EActionType.ContinueRegistration),
    },
]

const userTypeOptions = [
    { id: EUserRole.All, value: EUserRole.All, text: userRoleMap.get(EUserRole.All) },
    {
        id: EUserRole.UserWithouPA,
        value: EUserRole.UserWithouPA,
        text: userRoleMap.get(EUserRole.UserWithouPA),
    },
    {
        id: EUserRole.Client,
        value: EUserRole.Client,
        text: userRoleMap.get(EUserRole.Client),
    },
    {
        id: EUserRole.GOOwner,
        value: EUserRole.GOOwner,
        text: userRoleMap.get(EUserRole.GOOwner),
    },
    {
        id: EUserRole.TSO,
        value: EUserRole.TSO,
        text: userRoleMap.get(EUserRole.TSO),
    },
    // {
    //     id: EUserRole.Operator,
    //     value: EUserRole.Operator,
    //     text: userRoleMap.get(EUserRole.Operator),
    // },
    // {
    //     id: EUserRole.Administrator,
    //     value: EUserRole.Administrator,
    //     text: userRoleMap.get(EUserRole.Administrator),
    // },
]

interface IFilter {
    onChangeActionType: (actionType: EActionType) => void
    onChangeUserType: (actionType: EUserRole) => void
    onChangeUserName: (name: string) => void
    onChangeInn: (inn: string) => void
    onChangeDate: (date: [moment.Moment, moment.Moment] | null) => void
}

const Filter: FC<IFilter> = (props) => {
    const { t } = useTranslation()

    return (
        <div className={style.filter}>
            <div className={style.filterItem}>
                <div className={style.label}>Дата подачи</div>
                <TimeRangePicker onChange={props.onChangeDate} className={style.datePicker} />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Пользователь</div>
                <Input
                    onChange={(event) => props.onChangeUserName(event.target.value)}
                    placeholder="Введите ФИО или организацию"
                    className={style.input}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Действие</div>
                <DropdownSelector2
                    onChange={props.onChangeActionType}
                    options={actionTypeOptions}
                    className={style.select}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('questionnaireApproval.userRole')}</div>
                <DropdownSelector2
                    onChange={props.onChangeUserType}
                    options={userTypeOptions}
                    className={style.select}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('questionnaireApproval.inn')}</div>
                <Input
                    onChange={(event) => props.onChangeInn(event.target.value)}
                    placeholder={t('questionnaireApproval.typeInn')}
                    className={style.input}
                />
            </div>
        </div>
    )
}

export default Filter
