import { FC } from 'react'
import { IOperationConfig, IOperationSettings } from './types'
import { HistoryBlock } from './components'
import { Divider } from 'antd'
import envelope from '../../../assets/images/envelope.png'
import style from './style.m.less'

interface IOperationsMapper {
    config: IOperationConfig[] | undefined
    settings: IOperationSettings
    isContract?: boolean
    isThirdParty?: boolean
    isShort?: boolean
}

const OperationsHistoryMapper: FC<IOperationsMapper> = ({
    config,
    settings,
    isContract,
    isThirdParty,
    isShort,
}) => {
    return (
        <>
            {!config?.length && (
                <>
                    <Divider />
                    <div className={style.error}>
                        <div>
                            <img src={envelope} />
                        </div>
                        <div className={style.text}>Нет данных</div>
                    </div>
                </>
            )}
            {config?.map(({ date, operations, flexStart, iconMargin, fullScreen }, index) => (
                <HistoryBlock
                    key={index}
                    date={date}
                    isShort={isShort}
                    settings={settings}
                    operations={operations}
                    flexStart={flexStart}
                    iconMargin={iconMargin}
                    isContract={isContract}
                    isThirdParty={isThirdParty}
                    fullScreen={fullScreen}
                />
            ))}
        </>
    )
}

export default OperationsHistoryMapper
