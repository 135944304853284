// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_bWJaB {\n  width: 1300px;\n  padding: 40px 10px 100px;\n  margin: 0 auto;\n}\n.editMain_ZoRYW {\n  width: 1172px;\n}\n.editFrame_CZwVN {\n  margin-top: 32px;\n  padding: 40px 32px 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.input_g3BDA {\n  margin-left: 16px;\n  width: 400px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOProfile/components/Password/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,wBAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;AAAJ;AAGA;EACI,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AADJ;AAIA;EACI,iBAAA;EACA,YAAA;AAFJ","sourcesContent":[".container {\n    width: 1300px;\n    padding: 40px 10px 100px;\n    margin: 0 auto;\n}\n\n.editMain {\n    width: 1172px;\n}\n\n.editFrame {\n    margin-top: 32px;\n    padding: 40px 32px 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.input {\n    margin-left: 16px;\n    width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_bWJaB",
	"editMain": "editMain_ZoRYW",
	"editFrame": "editFrame_CZwVN",
	"input": "input_g3BDA"
};
export default ___CSS_LOADER_EXPORT___;
