import { FC, useState } from 'react'
import { Alert } from 'antd'
import moment from 'moment'
import { Modal, TimeRangePicker } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'
import { downloadXml } from '../../../helpers'

import style from './style.m.less'

interface IChangeRoleModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const ChangeEmailModal: FC<IChangeRoleModal> = ({ isModalVisible, setIsModalVisible }) => {
    const [date, setDate] = useState<[moment.Moment, moment.Moment] | null>(null)

    const filters = date
        ? {
              filters: {
                  ts_from: moment(date[0]).startOf('day'),
                  ts_to: moment(date[1]).endOf('day'),
              },
          }
        : null

    const getFile = () => {
        downloadXml('/api/users/v1/profiles-admin?download', filters, 'ProfilesData.xml')
        setIsModalVisible(false)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>Параметры выгрузки</div>
            <Alert
                message="Выберите период, за который вы хотите скачать анкеты пользователей"
                type="info"
                showIcon
                className={style.alert}
            />
            <div className={style.inputBlock}>
                <div>Дата обновления</div>
                <TimeRangePicker
                    onChange={(date: any) => setDate(date)}
                    style={{ width: '100%' }}
                />
            </div>
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={getFile}
                    cancelAction={handleOk}
                    confirmTitle="Выгрузить"
                />
            </div>
        </Modal>
    )
}

export default ChangeEmailModal
