import { FC, ReactNode } from 'react'

import style from '../style.m.less'

type PageContainerProps = {
    className?: any
    children: ReactNode
}

const TableContainer: FC<PageContainerProps> = ({ className, children }) => (
    <div className={className || style.table}>{children}</div>
)

export default TableContainer
