// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_QGYha {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 24px;\n}\n.buttonsBlock_yXskQ {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.icon_USjjd {\n  color: #01060F;\n  font-size: 22px;\n}\n.message_wZBzl {\n  width: 360px;\n  padding: 0px 40px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n}\n.titleWrapper_HKhQ1 {\n  display: flex;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ZDApprovalItem/components/CoordinateDataModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AAEA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;AAGA;EACI,cAAA;EACA,eAAA;AADJ;AAIA;EACI,YAAA;EACA,iBAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAFJ;AAKA;EACI,aAAA;EACA,SAAA;AAHJ","sourcesContent":[".header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 24px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.icon {\n    color: #01060F;\n    font-size: 22px;\n}\n\n.message {\n    width: 360px;\n    padding: 0px 40px;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n}\n\n.titleWrapper {\n    display: flex;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_QGYha",
	"buttonsBlock": "buttonsBlock_yXskQ",
	"icon": "icon_USjjd",
	"message": "message_wZBzl",
	"titleWrapper": "titleWrapper_HKhQ1"
};
export default ___CSS_LOADER_EXPORT___;
