import { FC } from 'react'
import { HeaderBlock, CenteredSpin } from '../ui'
import { useNavigate } from 'react-router-dom'
import { CommonBlock, ButtonsBlock } from '../Edit/components'
import { Divider, Form, Input } from 'antd'
import useRequest from '../hooks/useRequest'
import style from './style.m.less'
import { getCredentials, postCredentials } from './config'
import useMount from '../hooks/useMount'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'

const EditCredentials: FC = () => {
    const { t } = useTranslation()
    const { fetch, result, isLoading } = useRequest(getCredentials)
    const { fetch: sendCredentials } = useRequest(postCredentials)

    const [form] = Form.useForm()
    const navigate = useNavigate()

    useMount(() => {
        fetch({})
    })

    if (isLoading || !result) {
        return <CenteredSpin />
    }

    const onFinish = async (values: any) => {
        sendCredentials({
            body: values,
            successMessage: {
                title: t('detailedCard.success'),
                description: 'Реквизиты для пополнения изменены',
            },
            onSuccess: () => navigate('/billing'),
            errorCodeMessage: true,
        })
    }

    const goBack = () => {
        navigate(-1)
    }

    const onSubmit = () => {
        form.submit()
    }

    return (
        <div className={style.editMain}>
            <HeaderBlock label="Редактирование реквизитов для пополнения" />
            <div className={style.editFrame}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    name="editCredentialsForm"
                    initialValues={result}
                >
                    <CommonBlock
                        margin
                        required
                        label="Получатель"
                        select={
                            <Form.Item
                                name="recipient"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleaseRecipientBilling'),
                                    },
                                ]}
                            >
                                <Input maxLength={50} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        required
                        label="ИНН получателя"
                        select={
                            <Form.Item
                                name="inn"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleaseInnBilling'),
                                    },
                                ]}
                            >
                                <Input minLength={10} maxLength={10} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        required
                        label="Банк получателя"
                        select={
                            <Form.Item
                                name="bank_name"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleaseBankName'),
                                    },
                                ]}
                            >
                                <Input maxLength={45} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        required
                        label="БИК банка получателя"
                        select={
                            <Form.Item
                                name="bic"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleaseBicBilling'),
                                    },
                                ]}
                            >
                                <Input minLength={9} maxLength={9} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        required
                        label="КПП получателя"
                        select={
                            <Form.Item
                                name="kpp"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleaseKppBilling'),
                                    },
                                ]}
                            >
                                <Input minLength={9} maxLength={9} className={style.input} />
                            </Form.Item>
                        }
                    />

                    <CommonBlock
                        margin
                        required
                        label="К/С банка получателя"
                        select={
                            <Form.Item
                                name="corr_acc"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleaseCorrAccBilling'),
                                    },
                                ]}
                            >
                                <Input minLength={20} maxLength={20} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        required
                        label="Счет получателя в банке получателя"
                        customStyle={{ alignItems: 'unset' }}
                        select={
                            <Form.Item
                                name="beneficiary_acc"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleaseBeneficiaryAccBilling'),
                                    },
                                ]}
                            >
                                <Input minLength={20} maxLength={20} className={style.input} />
                            </Form.Item>
                        }
                    />
                    <CommonBlock
                        margin
                        label="Назначение платежа"
                        required
                        customStyle={{
                            alignItems: 'flex-start',
                        }}
                        select={
                            <Form.Item
                                name="reason"
                                className={style.fixErrorPosition}
                                rules={[
                                    {
                                        required: true,
                                        message: t('billing.pleasePaymentGoalBilling'),
                                    },
                                ]}
                            >
                                <TextArea className={style.textarea}></TextArea>
                            </Form.Item>
                        }
                    />
                    <Divider type="horizontal" />
                </Form>
                <ButtonsBlock margin confirmAction={onSubmit} cancelAction={goBack} />
            </div>
        </div>
    )
}

export default EditCredentials
