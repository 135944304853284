// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".documentsGrid_iEUpq {\n  display: flex;\n  gap: 32px;\n}\n.documentsWrapper_XMEVb {\n  padding: 8px;\n}\n.downloadSignature_bo1Qu {\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 12px;\n  line-height: 20px;\n  color: #296DFF;\n  margin-top: 10px;\n}\n.file_hDVDF {\n  display: flex;\n  gap: 8px;\n}\n.fileTitle_RNIFk {\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 16px;\n  color: #434343;\n}\n.fileSize_VToFD {\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  color: #BFBFBF;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ZDApprovalItem/components/DocumentsGrid/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;AACJ;AAEA;EACI,YAAA;AAAJ;AAGA;EACI,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,QAAA;AAFJ;AAKA;EACI,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAHJ;AAMA;EACI,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAJJ","sourcesContent":[".documentsGrid {\n    display: flex;\n    gap: 32px;\n}\n\n.documentsWrapper {\n    padding: 8px;\n}\n\n.downloadSignature {\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 20px;\n    color: #296DFF;\n    margin-top: 10px;\n}\n\n.file {\n    display: flex;\n    gap: 8px;\n}\n\n.fileTitle {\n    font-family: 'SF Pro Display', sans-serif;\n    font-weight: 400;\n    font-size: 13px;\n    line-height: 16px;\n    color: #434343;\n}\n\n.fileSize {\n    font-family: 'SF Pro Display', sans-serif;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    color: #BFBFBF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentsGrid": "documentsGrid_iEUpq",
	"documentsWrapper": "documentsWrapper_XMEVb",
	"downloadSignature": "downloadSignature_bo1Qu",
	"file": "file_hDVDF",
	"fileTitle": "fileTitle_RNIFk",
	"fileSize": "fileSize_VToFD"
};
export default ___CSS_LOADER_EXPORT___;
