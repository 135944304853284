import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Alert, Typography } from 'antd'
import { ModalType } from '../../../../types'
import { QuestionCircle } from '../../../../../../assets/icons'
import { ButtonsBlock } from '../../../../../Edit/components'
import { Modal, CenteredSpin } from '../../../../../ui'
import { errorHandler } from '../../../../../helpers'
import {
    changeAccountNameService,
    closeAccountService,
    setAccountMainService,
} from '../../../../../../services/accounts'
import style from './style.m.less'
import { useGetTariffRate } from '../../../../../hooks'
import {
    accNameMaxLength,
    accNameMaxLengthErrMsg,
} from '../../../OpenAccountModal/OpenAccountModal'

interface IAccountModal {
    type: ModalType
    isModalVisible: boolean
    accountData: any
    isByAdmin?: boolean
    userId?: string
    setIsModalVisible: (value: boolean) => void
    getCurrentAccount: (id: string) => void
    onDataChanged?: () => void
}

const AccountModal: FC<IAccountModal> = ({
    type,
    accountData,
    isModalVisible,
    userId,
    setIsModalVisible,
    getCurrentAccount,
    onDataChanged,
}) => {
    const { t } = useTranslation()
    const [accountName, setAccountName] = useState<string>(accountData.name)
    const [, setResponseError] = useState('')

    useEffect(() => {
        setAccountName(accountData.name)
    }, [accountData])

    const onChange = (e: any) => {
        if (type === 'rename') {
            setResponseError(
                e.target.value?.length > accNameMaxLength ? accNameMaxLengthErrMsg : '',
            )
        }
        setAccountName(e.target.value)
    }

    let typeConfig: {
        headerName: string
        confirmTitle: string
        text?: string
    }

    switch (type) {
        case 'rename':
            typeConfig = { headerName: 'Переименовать лицевой счет', confirmTitle: 'Переименовать' }
            break
        case 'setMain':
            typeConfig = {
                headerName: 'Назначить счет главным',
                confirmTitle: 'Подтвердить',
                text: `Вы хотите назначить счет №${accountData.number} главным для зачисления ЗИ от других пользователей СКИЗИ?`,
            }
            break
        case 'closeWithZI':
            typeConfig = {
                headerName: 'Закрытие лицевого счета',
                confirmTitle: 'Закрыть счет',
                text: `На лицевом счету №${accountData.number} остались ЗИ, при его закрытии они будут заблокированы. Вы действительно хотите закрыть счет? Отменить это действие будет невозможно.`,
            }
            break
        default:
            typeConfig = {
                headerName: 'Закрыть лицевой счет',
                confirmTitle: 'Закрыть',
                text: `Вы действительно хотите закрыть счет №${accountData.number}? Отменить это действие будет невозможно.`,
            }
    }

    const handleCancel = () => {
        setResponseError('')
        setIsModalVisible(false)
    }

    const handleConfirm = async () => {
        switch (type) {
            case 'rename':
                if (accountName !== '') {
                    if (accountName.length > accNameMaxLength) {
                        setResponseError(accNameMaxLengthErrMsg)

                        return
                    }

                    await changeAccountNameService({
                        accountId: accountData.id,
                        newName: accountName,
                        userId,
                        onFinish: () => {
                            setIsModalVisible(false)
                            getCurrentAccount(accountData.id)
                        },
                        onError: (error) => {
                            setResponseError(errorHandler(error.err_code))
                        },
                    })

                    accountData.name = accountName
                    onDataChanged()
                } else {
                    setResponseError('Введите корректное наименование лицевого счета')
                }
                break
            case 'setMain':
                await setAccountMainService({
                    accountId: accountData.id,
                    userId,
                    onFinish: () => {
                        setIsModalVisible(false)
                        getCurrentAccount(accountData.id)
                    },
                    onError: (error) => {
                        setResponseError(errorHandler(error.err_code))
                    },
                })
                break
            default:
                await closeAccountService({
                    accountId: accountData.id,
                    userId,
                    onFinish: () => {
                        setIsModalVisible(false)
                        getCurrentAccount(accountData.id)
                    },
                    onError: (error) => {
                        setResponseError(errorHandler(error.err_code))
                    },
                })
        }
    }

    const { isLoading, balance, rate, balanceNumber, rateNumber } = useGetTariffRate({
        dependency: isModalVisible,
        id: 14,
        showError: !userId,
    })

    if (isLoading) {
        return <CenteredSpin />
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.mainWrapper}>
                <QuestionCircle />
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>{typeConfig.headerName}</div>
                    {type === 'rename' && (
                        <div className={style.inputBlock}>
                            <div>{t('myProfile.appellation')}</div>
                            <Input
                                value={accountName}
                                className={style.input}
                                onChange={onChange}
                            />
                        </div>
                    )}

                    <div className={style.text}>{typeConfig.text}</div>
                    {type !== 'rename' && rateNumber > 0 && (
                        <>
                            <Alert
                                className={style.modalAlert}
                                message={
                                    <div className={style.modalAlertText}>
                                        <div>
                                            За операцию взымается оплата в размере{' '}
                                            <Typography.Text strong>{rate}</Typography.Text>
                                        </div>
                                        <div>Баланс платежного счета: {balance}</div>
                                    </div>
                                }
                                type="warning"
                                showIcon
                            />
                        </>
                    )}
                    {rateNumber > balanceNumber && (
                        <Alert
                            className={style.responseError}
                            message={
                                'На вашем платежном счете недостаточно денежных средств для выполнения данной операции. Пожалуйста, пополните платежный счет и повторите попытку'
                            }
                            type="error"
                            showIcon
                        />
                    )}
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            isDelete={type === 'delete' || type === 'closeWithZI'}
                            confirmAction={handleConfirm}
                            cancelAction={handleCancel}
                            confirmTitle={typeConfig.confirmTitle}
                            confirmDisabled={rateNumber > balanceNumber}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AccountModal
