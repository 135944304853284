import { ConfigProvider, Table, Alert } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import Filter from '../Filter/Filter'
import { CenteredSpin } from '../../../ui'
import BulletDot from '../../../ui/BulletDot/BulletDot'
import style from './style.m.less'
import useMount from '../../../hooks/useMount'
import { getCaRegistry, getGosOwners } from '../../config'
import { renderDayMonthYear } from '../../../../utils/formatter'
import { monthPicker, currencyFormat } from '../../../helpers'

import useRequest from '../../../hooks/useRequest'

export const getStatus = (option: number) => {
    switch (option) {
        case 1:
            return <div className={style.statusNew}>Новая</div>
        case 2:
            return <div className={style.statusNew}>На рассмотрении</div>
        case 3:
            return <div className={style.statusCancel}>Отказана</div>
        case 4:
            return <div className={style.statusAgreed}>Согласована</div>
        default:
            return <></>
    }
}

type PaginationType = {
    page: number
    pageSize: number
    filters?: any
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

const COLUMNS = (isOpen: boolean) => [
    {
        title: '',
        dataIndex: 'status_id',
        key: 'status_id',
        width: 20,
        render: (status_id: number) => (status_id === 1 ? <BulletDot color="#528EFF" /> : <></>),
    },
    {
        title: 'Отчетный период',
        dataIndex: 'month',
        key: 'month',
        render: (month: number, record: any) => `${monthPicker(month)}, ${record.year}`,
    },
    {
        title: 'Общий объем выработки, кВт*ч',
        dataIndex: 'volume',
        key: 'volume',
        render: (volume: number) => currencyFormat(volume),
    },
    {
        title: 'Владелец ЗГО',
        dataIndex: 'user',
        key: 'user',
        render: (user: any, record: any) =>
            user.user_type === 1
                ? `ИП ${record.user.last_name} ${record.user.first_name} ${record.user.middle_name}`
                : record.user.company_name_short,
    },
    {
        title: 'Дата подачи',
        dataIndex: 'ts_created',
        key: 'ts_created',
        render: (date: Date) => renderDayMonthYear(date),
    },
    !isOpen
        ? {
              title: 'Статус',
              dataIndex: 'status_id',
              key: 'status',
              render: (status_id: number) => getStatus(status_id),
          }
        : {},
]

interface IDefault {
    currentTab: string
}

const Default: FC<IDefault> = ({ currentTab }) => {
    const navigate = useNavigate()
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const [filters, setFilters] = useState<any>()
    const {
        fetch: fetchOwners,
        result: owners,
        isLoading: isLoadingOwners,
    } = useRequest(getGosOwners)
    const { fetch, result, isLoading } = useRequest(getCaRegistry)
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [isEmptyRegistry, setIsEmptyRegistry] = useState(false)

    const isOpen = currentTab === 'Открытые'

    const getData = ({ page = 1, pageSize = 10, filters }: PaginationType) => {
        fetch({
            body: {
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters,
            },
            onSuccess: (data) => {
                if (isFirstLoad) {
                    if (!data?.submissions?.length) {
                        setIsEmptyRegistry(true)
                    }
                    setIsFirstLoad(false)
                } else {
                    if (data?.submissions?.length) {
                        setIsEmptyRegistry(false)
                    }
                }
            },
        })
    }

    const updateFilters = (data: string | number | boolean | number[], key: string, obj?: any) => {
        const updatedFilter = {
            ...filters,
            ...(obj ? { ...obj } : { [key]: data }),
        }
        setFilters(updatedFilter)
        setPagination({ page: 1, pageSize: pagination.pageSize })
        getData({ page: 1, pageSize: pagination.pageSize, filters: updatedFilter })
    }

    useMount(() => {
        fetchOwners({})
    })

    useEffect(() => {
        if (!owners) {
            return
        }
        const isOpenValues = isOpen ? [1, 2] : [3, 4]
        updateFilters(isOpenValues, 'statuses')
    }, [owners, currentTab])

    if (isLoadingOwners || !owners) {
        return <CenteredSpin />
    }

    if (isEmptyRegistry) {
        return (
            <Alert
                message={
                    isOpen
                        ? 'У вас пока нет новых заявок на согласование'
                        : 'У вас пока нет закрытых заявок'
                }
                className={style.infoAlert}
                type="info"
                showIcon
            />
        )
    }

    return (
        <div className={style.opened}>
            <Filter onChange={updateFilters} owners={owners} isOpen={isOpen} />
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <Table
                        dataSource={result?.submissions || []}
                        columns={COLUMNS(isOpen)}
                        rowClassName={(record) => (record.status_id === 1 ? style.rowBold : '')}
                        pagination={{
                            position: ['bottomLeft'],
                            onChange: (page, pageSize) => {
                                getData({ page, pageSize, filters })
                                setPagination({ page, pageSize })
                            },
                            total: result?.total,
                            current: pagination.page,
                            pageSize: pagination.pageSize,
                            showSizeChanger: true,
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                navigate('/ku-card/' + record.id)
                            },
                        })}
                        loading={isLoading}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Default
