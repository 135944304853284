import { FC } from 'react'
import { Export } from '../../../assets/icons'
import { IconButton, DocumentsListUI } from '..'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

interface IDocumentsBlock {
    type: string
    customStyle?: React.CSSProperties
}

const DocumentsBlockUI: FC<IDocumentsBlock> = ({ type, customStyle }) => {
    const { t } = useTranslation()

    return (
        <>
            <div className={style.uploadDocumentFrame} style={customStyle}>
                <IconButton
                    icon={<Export />}
                    customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                >
                    {t('documentsBlock.downloadDoc')}
                </IconButton>
                <div className={style.info}>{t('documentsBlock.downloadNeeds')}</div>
            </div>
            {type === 'profile' && (
                <div className={style.documentsListWrapper}>
                    <DocumentsListUI isAdding />
                </div>
            )}
        </>
    )
}

export default DocumentsBlockUI
