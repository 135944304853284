import { FC } from 'react'
import { DocumentsGrid } from '../../../ui'
import { AGConsumer } from '../../types'

import style from './style.m.less'

interface IDocumentsBlock {
    documents: AGConsumer[]
}

export const DocumentsBlock: FC<IDocumentsBlock> = ({ documents }) => {
    return (
        <div className={style.wrapper}>
            <div className={style.header}>Файлы</div>
            <DocumentsGrid documents={documents} />
        </div>
    )
}
