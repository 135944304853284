// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".typeSelector_FyM1V {\n  width: 100%;\n  margin-top: 12px;\n  font-weight: normal;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Filters/components/TagSelector/style.m.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,mBAAA;AACJ","sourcesContent":[".typeSelector {\n    width: 100%;\n    margin-top: 12px;\n    font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeSelector": "typeSelector_FyM1V"
};
export default ___CSS_LOADER_EXPORT___;
