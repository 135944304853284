// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editMain_e66k5 {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 80px 74px 0;\n  width: 1300px;\n  margin: 0 auto;\n}\n.editFrame_nhEpy {\n  margin-top: 32px;\n  padding: 40px 32px 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.input_vftQ7 {\n  margin-left: 16px;\n  width: 200px;\n  border-radius: 8px;\n}\n.checkbox_ncduQ {\n  margin-top: 14px;\n  margin-left: 16px;\n}\n.buttonsBlock_X0S8a {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SuperAdminEditProfile/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;EACA,cAAA;AACJ;AAEA;EACI,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AAAJ;AAGA;EACI,iBAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAIA;EACI,gBAAA;EACA,iBAAA;AAFJ;AAKA;EACI,aAAA;AAHJ","sourcesContent":[".editMain {\n    display: flex;\n    flex-direction: column;\n    padding: 40px 80px 74px 0;\n    width: 1300px;\n    margin: 0 auto;\n}\n\n.editFrame {\n    margin-top: 32px;\n    padding: 40px 32px 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.input {\n    margin-left: 16px;\n    width: 200px;\n    border-radius: 8px;\n}\n\n.checkbox {\n    margin-top: 14px;\n    margin-left: 16px;\n}\n\n.buttonsBlock {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editMain": "editMain_e66k5",
	"editFrame": "editFrame_nhEpy",
	"input": "input_vftQ7",
	"checkbox": "checkbox_ncduQ",
	"buttonsBlock": "buttonsBlock_X0S8a"
};
export default ___CSS_LOADER_EXPORT___;
