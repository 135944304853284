import { FC } from 'react'
import { Select } from 'antd'
import { DownMini } from '../../../assets/icons'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import style from './style.m.less'

const { Option } = Select

interface IDropdownSelector {
    options: { value: string | number; id: string | number; disabled?: boolean; text?: string }[]
    customStyle?: React.CSSProperties
    onChange?: (value: any) => void
    placeholder?: string
    className?: string
    defaultValue?: string
    size?: SizeType
    disabled?: boolean
    noDefaultValue?: boolean
    allowClear?: boolean
    loading?: boolean
}

const DropdownSelector: FC<IDropdownSelector> = ({
    options = [],
    customStyle,
    placeholder,
    onChange,
    className,
    defaultValue,
    size,
    disabled,
    noDefaultValue,
    allowClear,
    loading,
    ...rest
}) => (
    <Select
        suffixIcon={
            <div style={{ marginTop: '4px', marginRight: '3px' }}>
                <DownMini />
            </div>
        }
        loading={loading}
        onChange={onChange ? (e) => onChange(e) : undefined}
        placeholder={placeholder}
        optionLabelProp={'value'}
        defaultValue={noDefaultValue ? undefined : defaultValue ? defaultValue : options[0]?.value}
        style={customStyle}
        className={className}
        size={size}
        disabled={disabled}
        allowClear={allowClear}
        {...rest}
    >
        {options.map((item) => (
            <Option key={item.id} value={item.value} disabled={item.disabled}>
                {item.text || item.value}
                {item.description && <div className={style.description}>{item.description}</div>}
            </Option>
        ))}
    </Select>
)

export default DropdownSelector
