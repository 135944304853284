export const privatePersonInfoConfig = {
    visibleInfo: [
        {
            name: 'Роль в системе',
            value: 'Клиент',
        },
        {
            name: 'Тип пользователя',
            value: 'Физическое лицо',
        },
        {
            name: 'ФИО',
            value: 'Макаров Михаил Алексеевич',
        },
        {
            name: 'Логин',
            value: 'MakaRoff575',
        },
        {
            name: 'E-mail',
            value: 'makarovv@powerservices.ru',
        },
    ],
    hiddenInfo: [
        {
            name: 'Дата рождения',
            value: '24.12.1985',
            noMargin: true,
        },
        {
            name: 'Статус гражданина',
            value: 'Гражданин РФ',
        },
        {
            name: 'Гражданство',
            value: 'Россия',
        },
        {
            name: 'Документ, удостоверяющий личность',
            value: 'Паспорт',
        },
        {
            name: 'Серия и номер паспорта',
            value: '4938  004883',
        },
        {
            name: 'Дата выдачи паспорта',
            value: '24.12.2014',
        },
        {
            name: 'Кем выдан паспорт',
            value: 'Инспекция Федеральной налоговой службы по г. Ижевску',
        },
        {
            name: 'Код подразделения ',
            value: '115-07 ',
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'Адрес регистрации',
            value: '295017, Удмуртская Республика, г. Ижевск, ул. Рубцова, д. 44А, офис 5',
            noMargin: true,
        },
        {
            name: 'Почтовый адрес',
            value: '295017, Удмуртская Республика, г. Ижевск, ул. Рубцова, д. 44А, офис 5',
        },
        {
            name: 'ИНН',
            value: '123009998771',
        },
        {
            name: 'СНИЛС',
            value: '254-584-863',
        },
        {
            name: 'Телефон / факс',
            value: '+ 7 999 764 55 55',
        },
        {
            isDivider: true,
            noMargin: true,
        },
        {
            name: 'Полное название организации',
            value: 'ОАО Лето',
            noMargin: true,
        },
        {
            name: 'ИНН организации',
            value: '123456787100',
        },
        {
            name: 'ОГРН',
            value: '2345670654321',
        },
        {
            name: 'Должность',
            value: 'Директор',
        },
    ],
}
