import { FC } from 'react'
import { DocIcon, XlsIcon } from '../../../../assets/icons'

import style from './style.m.less'

const DocumentsGrid: FC = () => {
    return (
        <div className={style.documentsGrid}>
            <div className={style.documentsWrapper}>
                <div className={style.file}>
                    <DocIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Заявка.docx</div>
                        <div className={style.fileSize}>183 Мб</div>
                    </div>
                </div>
                <div className={style.downloadSignature}>Скачать подпись</div>
            </div>
            <div className={style.documentsWrapper}>
                <div className={style.file}>
                    <XlsIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Файл с данными зСДД от 17...</div>
                        <div className={style.fileSize}>183 Мб</div>
                    </div>
                </div>
                <div className={style.downloadSignature}>Скачать подпись</div>
            </div>
        </div>
    )
}

export default DocumentsGrid
