import { RequestTypes } from '../hooks/useRequest'

export const AUDIT_API_PREFIX = '/api/audit/v1/'
export const AUDIT_SUM = 'admin-accounts'
export const AUDIT_REGISTRY = 'admin-history-payment'
export const AUDIT_TYPES = '/dict/event-payment-types'

export const getAdminSum = {
    link: `${AUDIT_API_PREFIX}${AUDIT_SUM}`,
    method: RequestTypes.get,
}

export const getAdminRegistry = {
    link: `${AUDIT_API_PREFIX}${AUDIT_REGISTRY}`,
    method: RequestTypes.post,
}

export const getTypesDict = {
    link: `${AUDIT_API_PREFIX}${AUDIT_TYPES}`,
    method: RequestTypes.get,
}
