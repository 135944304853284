import { message } from 'antd'

export const rolesConfig = [
    { name: 'Физическое лицо', values: ['Пользователь без ЛС', 'Клиент'] },
    {
        name: 'Индивидуальный предприниматель',
        values: ['Пользователь без ЛС', 'Клиент', 'Владелец ГО'],
    },
    {
        name: 'Юридическое лицо',
        values: ['Пользователь без ЛС', 'Клиент', 'Владелец ГО', 'ТСО/ГП'],
    },
]

export const checkErrorPairs = (role: number, type: number) => {
    if ((role === 4 && [0, 1].includes(type)) || (role === 3 && type === 0)) {
        message.error('Роль в системе не соответствует типу пользователя')
    }
}
