import ShowMoreLink from '../ShowMoreLink/ShowMoreLink'
import { FC, useState } from 'react'
import { iconSelector } from '../../helpers'
import { formatBytes } from '../../../utils/bytes/bytes'
import { LoadingContainer } from '../'
import useDownLoadFile from '../../hooks/useDownloadFile'

import style from './style.m.less'

interface IDocumentsGrid {
    documents: any
}

const DocumentsGrid: FC<IDocumentsGrid> = ({ documents }) => {
    const { downloadFile, isAllFilesDownloaded } = useDownLoadFile()

    const [isMoreDocumentsVisible, setIsMoreDocumentsVisible] = useState(false)
    const showMoreDocuments = () => {
        setIsMoreDocumentsVisible(!isMoreDocumentsVisible)
    }

    return (
        <LoadingContainer isLoading={isAllFilesDownloaded}>
            <div className={style.documentsGrid}>
                {documents
                    ?.slice(0, !isMoreDocumentsVisible ? 6 : documents.length + 1)
                    ?.map(
                        (item: {
                            file_name: string
                            file_size: number
                            comment: string
                            id: string
                        }) => (
                            <div
                                className={style.documentsWrapper}
                                key={decodeURI(encodeURI(item.file_name))}
                            >
                                <div className={style.file} onClick={() => downloadFile(item.id)}>
                                    {iconSelector(item, 'file_name')}
                                    <div className={style.fileInfo}>
                                        <div className={style.fileTitle}>
                                            {decodeURI(encodeURI(item.file_name))}
                                        </div>
                                        <div className={style.fileSize}>
                                            {formatBytes(item.file_size)}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.description}>{item.comment}</div>
                            </div>
                        ),
                    )}
            </div>
            {documents && documents.length > 6 && (
                <ShowMoreLink showMore={isMoreDocumentsVisible} onChange={showMoreDocuments} />
            )}
        </LoadingContainer>
    )
}

export default DocumentsGrid
