import classNames from 'classnames'
import { CSSProperties, FC, ReactNode } from 'react'

import styles from './style.m.less'

export enum ESize {
    N,
    S,
    M,
    L,
}

interface ICard {
    children: ReactNode
    size?: ESize
    zeroSidePadding?: boolean
    className?: string
    onClick?: (e: any) => void
    style?: CSSProperties
}

const Card: FC<ICard> = ({ children, size = ESize.M, className, onClick, zeroSidePadding, style }) => {
    return (
        <div
            style={style}
            className={classNames(
                styles.card,
                className,
                {
                    [styles.small]: size === ESize.S,
                    [styles.middle]: size === ESize.M,
                    [styles.large]: size === ESize.L,
                    [styles.clickable]: !!onClick,
                },
                zeroSidePadding && styles.zeroSidePadding,
            )}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

interface ICardSection {
    children: ReactNode
    size: ESize
}

export const CardSection: FC<ICardSection> = ({ children, size = ESize.M }) => (
    <div
        className={classNames(styles.cardSection, {
            [styles.small]: size === ESize.S,
            [styles.middle]: size === ESize.M,
            [styles.large]: size === ESize.L,
        })}
    >
        {children}
    </div>
)

export default Card
