import { FC } from 'react'
import { Edit } from '../../assets/icons'
import { Divider } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import style from './style.m.less'
import useRequest from '../hooks/useRequest'
import { credentialsConfig } from './config'
import { getCredentials } from './config'
import useMount from '../hooks/useMount'
import { CenteredSpin, GeneratorInfoFrame, IconButton } from '../ui'

const Billing: FC = () => {
    const { t } = useTranslation()
    const { fetch, result, isLoading } = useRequest(getCredentials)

    const navigate = useNavigate()

    useMount(() => {
        fetch({})
    })

    if (isLoading || !result) {
        return <CenteredSpin />
    }

    return (
        <div className={style.editMain}>
            <div className={style.header}>Реквизиты для пополнения</div>
            <GeneratorInfoFrame
                config={credentialsConfig(result)}
                customStyle={{ marginTop: '32px' }}
                bottomChildren={
                    <>
                        <Divider type={'horizontal'} />
                        <div>
                            <IconButton
                                icon={<Edit />}
                                onClick={() => navigate('/edit-credentials')}
                                customIconStyle={{ marginRight: '10px', marginTop: '5px' }}
                            >
                                Изменить
                            </IconButton>
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default Billing
