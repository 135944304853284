import { FC, useState, Fragment } from 'react'
import { Divider } from 'antd'
import { useGeneratorData } from '../../hooks/useGeneratorData'
import { PowerTypeTag } from '../'
import { Link } from 'react-router-dom'
import style from './style.m.less'
import { ShowMoreLink } from '../../ui'
import { useTranslation } from 'react-i18next'

interface IInfoBlock {
    name?: string
    value?: string
    isTag?: boolean
    isOwner?: boolean
    isDivider?: boolean
    noMargin?: boolean
    role?: string
    link?: {
        path: string
        state: any
    }
    fieldWithHide?: string[]
    redirect?: {
        path: string
        target?: string
    }
    translate?: boolean
}

interface IGeneratorInfoFrame {
    label?: string
    statusBlock?: React.ReactNode
    config: {
        visibleInfo: IInfoBlock[]
        hiddenInfo?: IInfoBlock[]
    }
    isProfile?: boolean
    customStyle?: React.CSSProperties
    bottomChildren?: React.ReactNode
    topChildren?: React.ReactNode
    userRole?: number
}

interface IFieldWithHideBlock {
    fieldWithHide: {
        longString: string
        shortString: string
    }
}

const checkEmpty = (config: any) => (config ? config.filter((el) => !!el.value).length > 0 : false)

const changeCodesArray = (codesArray: string[]) => {
    const shortArray = []
    const longArray = codesArray?.map((item, index, array) => {
        const increasedIndex = index + 1
        if (increasedIndex % 5 === 0) {
            item += '\n'
        } else if (index !== array.length - 1) {
            item += ';  '
        }
        if (index < 5) {
            shortArray.push(item)
        }
        return item
    })
    const shortString = shortArray.join('')
    const longString = longArray.join('')

    return { shortString, longString, length: codesArray.length }
}

const FieldWithHideBlock: FC<IFieldWithHideBlock> = ({ fieldWithHide }) => {
    const [showHidden, setShowHidden] = useState(false)

    const toggleHidden = () => {
        setShowHidden(!showHidden)
    }

    if (fieldWithHide === null) {
        return null
    }

    const { longString, shortString, length } = fieldWithHide

    return (
        <div className={style.infoWithHide}>
            {showHidden ? longString : shortString}
            {length > 5 && !showHidden && (
                <ShowMoreLink
                    noMargin
                    className={style.showMore}
                    showMore={false}
                    onChange={toggleHidden}
                />
            )}
            {length > 5 && showHidden && (
                <>
                    <ShowMoreLink
                        noMargin
                        className={style.showMore}
                        showMore
                        onChange={toggleHidden}
                    />
                </>
            )}
        </div>
    )
}

const createInfoBlocks = (config: IInfoBlock[], isVisible?: boolean, isProfile?: boolean) => {
    const { t } = useTranslation()

    const noEmpty = checkEmpty(config)
    if (!noEmpty) {
        return <></>
    }
    return config.map((item, index) => {
        const {
            value,
            isTag,
            isOwner,
            isDivider,
            noMargin,
            role,
            fieldWithHide,
            link,
            redirect,
            translate = false,
        } = item
        let { name } = item

        if (name && translate) {
            name = t(name)
        }

        const chooseBlockClass = () => {
            if (index === 0 && isVisible && !noMargin) {
                return style.infoBlockFirst
            } else if (noMargin) {
                return style.infoBlockNoMargin
            } else {
                return style.infoBlock
            }
        }

        const chooseBlockType = () => {
            if (isTag && value) {
                return (
                    <PowerTypeTag value={value} customStyle={{ marginLeft: '24px' }}>
                        {value}
                    </PowerTypeTag>
                )
            }
            if (isOwner && !isProfile) {
                const { generatorData } = useGeneratorData()
                const { user_id } = generatorData
                return (
                    <Link
                        key={index}
                        to={{
                            pathname: `/owner/${user_id}`,
                        }}
                        state={{ generatorData }}
                        className={style.infoValue}
                    >
                        {value}
                    </Link>
                )
            }
            if (link) {
                const { path, state } = link
                return (
                    <Link to={path} state={state} className={style.infoValue}>
                        {value}
                    </Link>
                )
            }
            if (redirect) {
                const { path, target = '_blank' } = redirect
                return (
                    <div className={style.infoValue}>
                        <a href={`${path}`} target={target}>
                            {value}
                        </a>
                    </div>
                )
            }
            if (role) {
                return (
                    <div>
                        <span className={style.owner}>{value ? value : '-'}</span>
                        <span className={style.role}>{role}</span>
                    </div>
                )
            }
            if (isDivider) {
                const isDataAfter = config.slice(index, config.length)
                const noEmptyAfter = checkEmpty(isDataAfter)
                if (!noEmptyAfter) {
                    return <></>
                }
                return <Divider />
            }
            if (fieldWithHide) {
                return <FieldWithHideBlock fieldWithHide={changeCodesArray(fieldWithHide)} />
            }
            return <div className={style.infoValue}>{value}</div>
        }

        if (value || fieldWithHide) {
            return (
                <div className={chooseBlockClass()} key={`key:${index}`}>
                    <div className={style.infoName}>{name}</div>
                    {chooseBlockType()}
                </div>
            )
        }
        return <Fragment key={`key:${index}`}>{chooseBlockType()}</Fragment>
    })
}

const GeneratorInfoFrame: FC<IGeneratorInfoFrame> = ({
    label,
    statusBlock,
    config,
    isProfile,
    customStyle,
    children,
    bottomChildren,
    topChildren,
    userRole,
}) => {
    const [showHidden, setShowHidden] = useState(false)

    const toggleHidden = () => {
        setShowHidden(!showHidden)
    }
    const { visibleInfo, hiddenInfo } = config

    const isHiddenNoEmpty = checkEmpty(hiddenInfo)
    const isHiddenInfoVisible = hiddenInfo?.some((item) => !!item.value)

    // вынес сюда т.к. была ошибка Rendered more hooks than during the previous
    const visibleBlock = createInfoBlocks(visibleInfo, true, isProfile)
    const hiddenBlock = hiddenInfo && createInfoBlocks(hiddenInfo)

    const withoutHidden = userRole === 1

    return (
        <div className={style.generatorInfoFrameMain} style={customStyle}>
            {topChildren && topChildren}
            {label && <div className={style.headerPrimary}>{label}</div>}
            {children}
            {statusBlock && statusBlock}
            {visibleBlock}
            {withoutHidden && isHiddenNoEmpty && (
                <>
                    <Divider />
                    {hiddenBlock}
                </>
            )}
            {!showHidden && hiddenInfo && isHiddenInfoVisible && !withoutHidden && (
                <ShowMoreLink showMore={false} onChange={toggleHidden} />
            )}
            {showHidden && hiddenInfo && !withoutHidden && isHiddenNoEmpty && (
                <>
                    <Divider />
                    {hiddenBlock}
                    <ShowMoreLink showMore onChange={toggleHidden} />
                </>
            )}
            {bottomChildren && bottomChildren}
        </div>
    )
}

export default GeneratorInfoFrame
