// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".documentsBlock_uw5ns {\n  margin-left: 216px;\n  width: 400px;\n}\n.uploadDocumentFrame_qxpvN {\n  margin-top: 24px;\n  height: 112px;\n  background: rgba(230, 241, 255, 0.35);\n  border-radius: 16px;\n  padding: 20px 24px;\n}\n.info_vrMIi {\n  margin-top: 12px;\n}\n.mainInfoWrapper_u75HE {\n  width: 400px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContinueRegistration/components/DocumentsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;AACJ;AAEA;EACI,gBAAA;EACA,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,YAAA;AAFJ","sourcesContent":[".documentsBlock {\n    margin-left: 216px;\n    width: 400px;\n}\n\n.uploadDocumentFrame {\n    margin-top: 24px;\n    height: 112px;\n    background: rgba(230, 241, 255, 0.35);\n    border-radius: 16px;\n    padding: 20px 24px;\n}\n\n.info {\n    margin-top: 12px;\n}\n\n.mainInfoWrapper {\n    width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentsBlock": "documentsBlock_uw5ns",
	"uploadDocumentFrame": "uploadDocumentFrame_qxpvN",
	"info": "info_vrMIi",
	"mainInfoWrapper": "mainInfoWrapper_u75HE"
};
export default ___CSS_LOADER_EXPORT___;
