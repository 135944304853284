import { Alert } from '../../../components/ui'
import { FeeStatus } from './AGHeader'
import moment from 'moment'
import style from './style.m.less'
import { TariffsActiveType } from './RealisationCard'

type Props = {
    isSeller: boolean
    isBuyer: boolean
    is_buyer_tax_payer: boolean
    consumersNotZero: boolean
    tariffsActive: TariffsActiveType
    seller_fee_status: number
    buyer_fee_status: number
    pay_before: string
}

const AGAlert = ({
    isSeller,
    isBuyer,
    is_buyer_tax_payer,
    consumersNotZero,
    tariffsActive,
    pay_before,
    seller_fee_status,
    buyer_fee_status,
}: Props) => {
    const isTransferCommisionActive = tariffsActive.transfer
    const isRealisationCommisionActive = tariffsActive.realisation

    const lowerTheThirdControlDate = moment(pay_before).unix() > moment().unix()

    const message =
        'Вы выполнили все необходимые условия для успешной реализации АГ по ЗД. Ожидается выполнение условий другими участниками'
    if (isSeller) {
        if (!isTransferCommisionActive && !isTransferCommisionActive) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && // Продавец оплатил комиссию “Передача АГ по ЗД“
                // buyer_fee_status === FeeStatus.NotPayed && // Покупатель НЕ оплатил комиссию “Реализация АГ по ЗД“
                !consumersNotZero // Покупатель НЕ указал конечных потребителей по ЗД
            ) {
                return (
                    <Alert className={style.historyAlert} message={message} type="info" showIcon />
                )
            }
        }
        if (
            (isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Продавец
            (isTransferCommisionActive && !isRealisationCommisionActive && is_buyer_tax_payer) || // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Покупатель)
            (isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) || // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Покупатель
            (isTransferCommisionActive && !isRealisationCommisionActive && !is_buyer_tax_payer) // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Продавец)
        ) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && // Продавец оплатил комиссию “Передача АГ по ЗД“
                // buyer_fee_status === FeeStatus.NotPayed && // Покупатель НЕ оплатил комиссию “Реализация АГ по ЗД“
                !consumersNotZero // Покупатель НЕ указал конечных потребителей по ЗД
            ) {
                return (
                    <Alert className={style.historyAlert} message={message} type="info" showIcon />
                )
            }
        }

        // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Продавец)
        if (!isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status) && // Продавец оплатил комиссию “Передача АГ по ЗД“
                !consumersNotZero // Покупатель НЕ указал конечных потребителей по ЗД
            ) {
                return (
                    <Alert className={style.historyAlert} message={message} type="info" showIcon />
                )
            }
        }
    }

    if (isBuyer) {
        if (
            !isTransferCommisionActive &&
            !isRealisationCommisionActive &&
            lowerTheThirdControlDate &&
            !consumersNotZero
        ) {
            return (
                <Alert
                    className={style.historyAlert}
                    message={
                        'Добавлен новый объем атрибутов генерации. Для успешной реализации АГ по ЗД необходимо указать Потребителей.'
                    }
                    type="info"
                    showIcon
                />
            )
        }

        if (
            (isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Продавец
            (isTransferCommisionActive && !isRealisationCommisionActive && is_buyer_tax_payer) || // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Покупатель)
            (isTransferCommisionActive && !isRealisationCommisionActive && !is_buyer_tax_payer) || // Передача АГ по ЗД - платно (Продавец), Реализация АГ оп ЗД - бесплатно (Продавец)
            (!isTransferCommisionActive && !isRealisationCommisionActive)
        ) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                seller_fee_status === FeeStatus.NotPayed && // Продавец НЕ оплатил комиссию “Передача АГ по ЗД“
                consumersNotZero // Покупатель указал конечных потребителей по ЗД
            ) {
                return (
                    <Alert className={style.historyAlert} message={message} type="info" showIcon />
                )
            }

            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero // Покупатель НЕ указал конечных потребителей по ЗД
            ) {
                return (
                    <Alert
                        className={style.historyAlert}
                        message={
                            'Добавлен новый объем атрибутов генерации. Для успешной реализации АГ по ЗД необходимо указать Потребителей.'
                        }
                        type="info"
                        showIcon
                    />
                )
            }
        }

        // Передача АГ по ЗД - платит Продавец, Реализация АГ по ЗД - платит Покупатель
        if (isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                seller_fee_status === FeeStatus.NotPayed && // Продавец НЕ оплатил комиссию “Передача АГ по ЗД“
                consumersNotZero && // Покупатель указал конечных потребителей по ЗД
                [FeeStatus.Reserved, FeeStatus.Payed].includes(buyer_fee_status) // Покупателем оплатил комиссию “Реализация АГ по ЗД“
            ) {
                return (
                    <Alert className={style.historyAlert} message={message} type="info" showIcon />
                )
            }
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero && // Покупатель НЕ указал конечных потребителей по ЗД
                buyer_fee_status === FeeStatus.NotPayed // Покупателем НЕ оплачена комиссия “Реализация АГ по ЗД“
            ) {
                return (
                    <Alert
                        className={style.historyAlert}
                        message={
                            <div className={style.modalAlertText}>
                                <div>Добавлен новый объем атрибутов генерации</div>
                                <div>Для успешной реализации АГ по ЗД необходимо:</div>
                                <div>1) указать Потребителей;</div>
                                <div>2) внести плату за реализацию АГ по ЗД.</div>
                            </div>
                        }
                        type="info"
                        showIcon
                    />
                )
            }
        }

        // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Покупатель)
        if (!isTransferCommisionActive && isRealisationCommisionActive && is_buyer_tax_payer) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero && // Покупатель НЕ указал конечных потребителей по ЗД
                buyer_fee_status === FeeStatus.NotPayed // Покупателем НЕ оплачена комиссия “Реализация АГ по ЗД“
            ) {
                return (
                    <Alert
                        className={style.historyAlert}
                        message={
                            <div className={style.modalAlertText}>
                                <div>Добавлен новый объем атрибутов генерации</div>
                                <div>Для успешной реализации АГ по ЗД необходимо:</div>
                                <div>1) указать Потребителей;</div>
                                <div>2) внести плату за реализацию АГ по ЗД.</div>
                            </div>
                        }
                        type="info"
                        showIcon
                    />
                )
            }
        }

        // Передача АГ по ЗД - бесплатно (Продавец), Реализация АГ оп ЗД - платно (Продавец)
        if (!isTransferCommisionActive && isRealisationCommisionActive && !is_buyer_tax_payer) {
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                seller_fee_status === FeeStatus.NotPayed && // Продавец НЕ оплатил комиссию “Передача АГ по ЗД“
                buyer_fee_status === FeeStatus.NotPayed &&
                consumersNotZero // Покупатель указал конечных потребителей по ЗД
            ) {
                return (
                    <Alert className={style.historyAlert} message={message} type="info" showIcon />
                )
            }
            if (
                lowerTheThirdControlDate && // Не наступила третья контрольная дата (см. тут)
                !consumersNotZero // Покупатель НЕ указал конечных потребителей по ЗД
            ) {
                return (
                    <Alert
                        className={style.historyAlert}
                        message={
                            'Добавлен новый объем атрибутов генерации. Для успешной реализации АГ по ЗД необходимо указать Потребителей.'
                        }
                        type="info"
                        showIcon
                    />
                )
            }
        }
    }

    return <></>
}

export default AGAlert
