export interface IOperation {
    id: number
    time: string
    value: number | null
    certificate_number: string
    event_id: string
}

export interface IOperationConfig {
    iconMargin: boolean
    flexStart: boolean
    date: string
    operations: IOperation[]
    fullScreen?: boolean
}

export interface PaymentHistoryEventPayload {
    amount?: number
}

export interface PaymentHistoryEvents {
    id: string
    ts_event: string
    type_event_id: number
    uid_creator: string
    name_creator: string
    role_creator: number
    uid_recipient: number
    name_recipient: string
    role_recipient: number
    payload: PaymentHistoryEventPayload
}

export enum EHistoryType {
    Payment = 1,
    Personal = 2,
    Certs = 3,
}

export interface IOperationSettings {
    historyType: EHistoryType
    navigate?: boolean
}

export interface PersAccHistoryEventPayload {
    volume?: number
}

export interface PersAccHistoryEvents {
    id: string
    ts_event: string
    type_event_id: number
    uid_creator: string
    name_creator: string
    role_creator: number
    uid_recipient: number
    name_recipient: string
    role_recipient: number
    payload: PersAccHistoryEventPayload
}

export interface IHistoryBlock {
    iconMargin: boolean
    flexStart: boolean
    date: string
    operations: IOperation[]
    settings: IOperationSettings
    isShort?: boolean
    isContract?: boolean
    isThirdParty?: boolean
    fullScreen?: boolean
}
