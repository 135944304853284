import { FC, ReactNode } from 'react'
import style from './style.m.less'

export type IIconLink = {
    children: ReactNode
    icon: ReactNode
    onClick?: () => void
    disabled?: boolean
}

const IconLink: FC<IIconLink> = ({ icon, children, onClick, disabled }) => {
    return (
        <div
            className={disabled ? style.stampBlockDisabled : style.stampBlock}
            onClick={disabled ? undefined : onClick}
        >
            {icon}
            <div className={style.stampOwner}>{children}</div>
        </div>
    )
}

export default IconLink
