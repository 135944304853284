import { FC } from 'react'
import useRequest from '../../../hooks/useRequest'
import PageContainer from '../../../ui/PageElements/PageContainer/PageContainer'
import BackLink from '../../../ui/BackTitle/BackTitle'
import { useParams } from 'react-router-dom'
import Card from '../../../ui/Card/Card'
import { Box } from '../../../ui/Box'
import { LoadingContainer } from '../../../ui'
import style from './style.m.less'
import { monthPicker } from '../../../helpers'
import { getCaDocs } from '../../config'
import useMount from '../../../hooks/useMount'
import DocumentsGrid from '../../../FuelApproval/components/FuelCard/DocumentsGrid/DocumentsGrid'

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const DocsCard: FC = () => {
    const { fetch, result, isLoading } = useRequest(getCaDocs)

    const { id } = useParams<{ id: string }>()

    useMount(() => {
        fetch({ addToLink: id })
        scrollToTop()
    })

    const currentDate = `${monthPicker(result?.month)}, ${result?.year}`

    return (
        <LoadingContainer isLoading={isLoading || !result}>
            <PageContainer>
                <>
                    <BackLink title={`Данные по КУ за ${currentDate}`} />
                    <Card className={style.card}>
                        <Box direction="row">
                            {result?.documents?.length ? (
                                <DocumentsGrid documents={result?.documents} />
                            ) : (
                                '-'
                            )}
                        </Box>
                    </Card>
                </>
            </PageContainer>
        </LoadingContainer>
    )
}

export default DocsCard
