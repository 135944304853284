import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FooterLogo, Mail, Telegram } from '../../../../assets/icons'
import style from './style.m.less'

const FooterInfo: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.footerInfo}>
            <div className={style.infoText}>{t('footer.address')}</div>
            <div className={style.phoneBlock}>
                <div>+7 (495) 740-30-27</div>
            </div>
            <div>
                <div className={style.footerInfoBlock}>
                    <div>
	                        <div>По общим вопросам и вопросам заключения договоров:</div>
                        <div><a href="mailto:info@g-e-t.ru">info@g-e-t.ru</a></div>
	                        <div>Подача заявлений на квалификацию в электронном виде:</div>
                        <div><a href="mailto:mail@g-e-t.ru">mail@g-e-t.ru</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterInfo
