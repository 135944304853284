// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".generatorsMain_tEerm {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 80px 100px 80px;\n}\n.mainHeader_YXX7V {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.mainWrapper_StHW7 {\n  margin-top: 32px;\n  padding: 32px 32px 24px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/CreateGenerator/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,6BAAA;AACJ;AAEA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AADJ","sourcesContent":[".generatorsMain {\n    display: flex;\n    flex-direction: column;\n    padding: 40px 80px 100px 80px;\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.mainWrapper {\n    margin-top: 32px;\n    padding: 32px 32px 24px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generatorsMain": "generatorsMain_tEerm",
	"mainHeader": "mainHeader_YXX7V",
	"mainWrapper": "mainWrapper_StHW7"
};
export default ___CSS_LOADER_EXPORT___;
