enum EStatus {
    New = 'Новая',
    Fixed = 'Исправлена',
    UnderRevision = 'На доработке',
    UnderConsideration = 'На рассмотрении',
    Agreed = 'Согласована',
    NotAgreed = 'Отменена пользователем',
}

interface IData {
    uid: string
    prevId: number
    id: number
    key: string
    date: Date
    status: EStatus
    comment?: string
}

const getDataSource = (): IData[] => [
    {
        key: '1',
        date: new Date(2022, 8, 1, 12),
        status: EStatus.New,
    },
    {
        key: '2',
        date: new Date(2022, 8, 1, 13),
        status: EStatus.UnderConsideration,
    },
    {
        key: '3',
        date: new Date(2022, 8, 1, 14),
        status: EStatus.UnderRevision,
        comment: 'Не приложены уставные документы',
    },
    {
        key: '4',
        date: new Date(2022, 8, 1, 14, 30),
        status: EStatus.NotAgreed,
    },
    {
        key: '5',
        date: new Date(2022, 8, 2, 9),
        status: EStatus.New,
    },
    {
        key: '6',
        date: new Date(2022, 8, 2, 10),
        status: EStatus.UnderConsideration,
    },
    {
        key: '7',
        date: new Date(2022, 8, 2, 12),
        status: EStatus.UnderRevision,
        comment: 'Неверно указан ОГРН',
    },
    {
        key: '8',
        date: new Date(2022, 8, 3, 9),
        status: EStatus.Fixed,
    },
    {
        key: '9',
        date: new Date(2022, 8, 3, 10),
        status: EStatus.UnderConsideration,
    },
    {
        key: '10',
        date: new Date(2022, 8, 3, 11, 30),
        status: EStatus.Agreed,
    },
]

const data = getDataSource()

export { EStatus, data }
export type { IData }
