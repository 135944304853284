import { RequestTypes } from '../../../hooks/useRequest'

const API_BILLING_PREFIX = '/api/billing/v1/'
const API_GT_PREFIX = '/api/green-tools/v1/'

export const balanceConfig = {
    link: `${API_BILLING_PREFIX}account-balances`,
    method: RequestTypes.get,
}

export const paymentConfig = {
    link: `${API_GT_PREFIX}contracts/payment-consumption`,
    method: RequestTypes.post,
}
