import { FC } from 'react'
import useRequest from '../../../hooks/useRequest'
import PageContainer from '../../../ui/PageElements/PageContainer/PageContainer'
import BackLink from '../../../ui/BackTitle/BackTitle'
import { useParams } from 'react-router-dom'
import Card from '../../../ui/Card/Card'
import { Divider, Alert } from 'antd'
import { Box } from '../../../ui/Box'
import { ImportButton, LoadingContainer, DocumentsGrid } from '../../../ui'
import style from './style.m.less'
import { Stamp } from '../../../../assets/icons'
import { monthPicker } from '../../../helpers'
import { getCaDetails } from '../../config'
import useMount from '../../../hooks/useMount'
import { KUInfoConfig } from './KUInfoConfig'
import useDownloadSignedRequest from '../../../hooks/useDownloadSignedRequest'
import { getStatus } from '../KUTable/KUTable'
import moment from 'moment'

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const KUOwnerCard: FC = () => {
    const { downloadSignedRequest } = useDownloadSignedRequest()
    const { fetch, result, isLoading } = useRequest(getCaDetails)

    const { id } = useParams<{ id: string }>()
    const currentDate = `${monthPicker(result?.month)}, ${result?.year}`

    useMount(() => {
        fetch({ addToLink: id })
        scrollToTop()
    })

    const statusId = result?.status_id

    return (
        <PageContainer>
            <LoadingContainer isLoading={isLoading || !result}>
                <BackLink
                    title={`Данные КУ за ${currentDate.toLowerCase()}`}
                    extra={getStatus(statusId)}
                />
                <Card className={style.card}>
                    <Box direction="row" justify="space-between">
                        <div className={style.infoWrapper}>
                            {statusId === 2 && (
                                <>
                                    <Alert
                                        message={'Данные находятся на проверке у Оператора Системы'}
                                        className={style.infoAlert}
                                        type="info"
                                        showIcon
                                    />
                                    <div />
                                </>
                            )}
                            {statusId === 3 && (
                                <>
                                    <Alert
                                        message={
                                            <>
                                                <div className={style.alertHeader}>
                                                    {`Данные не приняты. Переподача данных возможна до
                                                ${moment(result.ts_resubmission).format(
                                                    'DD.MM.YYYY',
                                                )}. Причина:`}
                                                </div>
                                                <div>{result.reason}</div>
                                            </>
                                        }
                                        className={style.errorAlert}
                                        type="error"
                                        showIcon={false}
                                    />
                                    <div />
                                </>
                            )}
                            {result &&
                                KUInfoConfig(result).map((item: any) => (
                                    <Box direction="row">
                                        <div className={style.greyBlock}>{item.name}</div>
                                        <div>{item.value}</div>
                                    </Box>
                                ))}
                        </div>
                        <ImportButton
                            customStyle={{ width: '200px', height: '20px' }}
                            icon={<Stamp />}
                            value={'Скачать запрос и подпись'}
                            onClick={() => downloadSignedRequest(result.request_id)}
                        />
                    </Box>
                    <Divider />
                    <Box direction="row">
                        <div className={style.filesTitle}>Данные КУ</div>
                        <DocumentsGrid documents={result?.ca_documents} />
                    </Box>
                    <div className={style.bottomBox}>
                        <div className={style.filesTitle}>Дополнительные файлы</div>
                        {result?.documents?.length ? (
                            <DocumentsGrid documents={result?.documents} />
                        ) : (
                            '-'
                        )}
                    </div>
                </Card>
            </LoadingContainer>
        </PageContainer>
    )
}

export default KUOwnerCard
