import React from 'react'
import moment, { Moment } from 'moment'
import { Consumption } from '../types'
import style from './style.m.less'
import { BlockingBlock } from '../components'
import { Box } from '../../../components/ui'
import AGHeader, { FeeStatus } from './AGHeader'
import AGAlert from './AGAlert'
import ObjectBlock, { ComissionBlock } from './ObjectBlock'
import { consumersLabel, currencyFormat, monthPicker } from '../../../components/helpers'
import AGButton from './AGButton'

export type TariffsActiveType = {
    transfer: boolean
    realisation: boolean
}

type Props = {
    isAdmin: boolean
    consumption: Consumption
    data: any
    handlePaymentModal: () => void
    auditNavigatge: () => void
    userId: string
    tariffsActive: TariffsActiveType
    rateNumber: number
}

export const convertDate = (date: Moment | string) =>
    date ? moment(date).format('DD.MM.YYYY') : 'Не определена'

const RealisationCard = ({
    isAdmin,
    consumption,
    userId,
    data,
    handlePaymentModal,
    auditNavigatge,
    rateNumber,
    tariffsActive,
}: Props) => {
    const {
        seller_fee_status,
        buyer_fee_status,
        pay_before,
        period,
        volume,
        contract_id,
        consumers_count,
        fee,
        seller_fee,
        buyer_fee,
    } = consumption

    const {
        seller_uid,
        buyer_uid,
        contract_number,
        seller_name,
        status_id,
        block,
        volume_new,
        is_buyer_tax_payer,
    } = data

    const consumptionState = {
        contract_id,
        contract_number,
        period_date: period,
        fee,
        seller_name,
        volume,
        buyer_uid,
    }

    const isSeller = userId === seller_uid
    const isBuyer = userId === buyer_uid

    const consumersNotZero = consumers_count > 0

    const isNewVolume = volume_new > 0

    const isPaymentOn = tariffsActive.transfer && tariffsActive.realisation
    const lowerTheThirdControlDate = moment(pay_before).unix() > moment().unix()
    const isSellerFeePayed = [FeeStatus.Reserved, FeeStatus.Payed].includes(seller_fee_status)
    const isBuyerFeePayed = [FeeStatus.Reserved, FeeStatus.Payed].includes(buyer_fee_status)

    const isRealisationDate =
        isSellerFeePayed && isBuyerFeePayed && consumersNotZero && lowerTheThirdControlDate

    const isShow = isSeller || isBuyer || isAdmin

    return (
        <div className={style.repaymentWrapper}>
            {status_id === 3 && <BlockingBlock id={contract_number} blockingInfo={block} />}
            <div className={style.repaymentBlock}>
                <Box direction="row" justify="space-between">
                    <div className={style.repaymentHeader}>{'Реализация АГ'}</div>
                    <AGHeader
                        seller_fee_status={seller_fee_status}
                        buyer_fee_status={buyer_fee_status}
                        isSeller={isSeller}
                        isBuyer={isBuyer}
                        tariffsActive={tariffsActive}
                        consumersNotZero={consumersNotZero}
                        isNewVolume={isNewVolume}
                        is_buyer_tax_payer={is_buyer_tax_payer}
                        pay_before={pay_before}
                        rateNumber={rateNumber}
                    />
                </Box>
                <AGAlert
                    seller_fee_status={seller_fee_status}
                    buyer_fee_status={buyer_fee_status}
                    isSeller={isSeller}
                    isBuyer={isBuyer}
                    tariffsActive={tariffsActive}
                    consumersNotZero={consumersNotZero}
                    is_buyer_tax_payer={is_buyer_tax_payer}
                    pay_before={pay_before}
                />
                {isRealisationDate && (
                    <ObjectBlock
                        title="Дата реализации"
                        description={moment(period).format('DD.MM.YYYY')}
                    />
                )}
                <ObjectBlock
                    title="Период поставки"
                    description={`${monthPicker(moment(period).month() + 1)}, ${moment(
                        period,
                    ).year()}`}
                />
                {isShow && !isRealisationDate && (
                    <ObjectBlock title={'Объем'} description={`${currencyFormat(volume)} кВт*ч`} />
                )}
                {isRealisationDate && (
                    <ObjectBlock
                        title={'Реализованный объем'}
                        description={`${currencyFormat(volume)} кВт*ч`}
                    />
                )}
                {isRealisationDate && (
                    <ObjectBlock
                        title={'Количество потребителей'}
                        description={`${consumers_count} ${consumersLabel(consumers_count)}`}
                    />
                )}
                <ComissionBlock
                    is_buyer_tax_payer={is_buyer_tax_payer}
                    isSeller={isSeller}
                    isBuyer={isBuyer}
                    seller_fee={seller_fee}
                    buyer_fee={buyer_fee}
                />

                <AGButton
                    lowerTheThirdControlDate={lowerTheThirdControlDate}
                    handlePaymentModal={handlePaymentModal}
                    auditNavigatge={auditNavigatge}
                    consumptionState={consumptionState}
                    isSeller={isSeller}
                    isBuyer={isBuyer}
                    is_buyer_tax_payer={is_buyer_tax_payer}
                    consumersNotZero={consumersNotZero}
                    isPaymentOn={isPaymentOn}
                    isNewVolume={isNewVolume}
                    seller_fee_status={seller_fee_status}
                    buyer_fee_status={buyer_fee_status}
                    isAdmin={isAdmin}
                    tariffsActive={tariffsActive}
                    rateNumber={rateNumber}
                    buyer_fee={buyer_fee}
                />
            </div>
        </div>
    )
}

export default RealisationCard
