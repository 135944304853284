// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter_KfKf2 {\n  display: flex;\n  gap: 16px;\n  justify-content: space-between;\n  width: 100%;\n}\n.filterItem_dHeWs {\n  flex: 1;\n}\n.label_J7dwc {\n  margin-bottom: 2px;\n}\n.input_Pyc16 {\n  border-radius: 8px;\n}\n.select_EQMZC {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Helpdesk/Filter/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,8BAAA;EACA,WAAA;AACJ;AAEA;EACI,OAAA;AAAJ;AAGA;EACI,kBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;EACI,WAAA;AAHJ","sourcesContent":[".filter {\n    display: flex;\n    gap: 16px;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.filterItem {\n    flex: 1;\n}\n\n.label {\n    margin-bottom: 2px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.select {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": "filter_KfKf2",
	"filterItem": "filterItem_dHeWs",
	"label": "label_J7dwc",
	"input": "input_Pyc16",
	"select": "select_EQMZC"
};
export default ___CSS_LOADER_EXPORT___;
