// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tagFilter_OVxlh {\n  display: flex;\n  margin-bottom: 5px;\n  margin-left: 16px;\n}\n.clickable_sHUeI {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/TSOProfile/components/TagFilter/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAEA;EACI,eAAA;AAAJ","sourcesContent":[".tagFilter {\n    display: flex;\n    margin-bottom: 5px;\n    margin-left: 16px;\n}\n\n.clickable {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagFilter": "tagFilter_OVxlh",
	"clickable": "clickable_sHUeI"
};
export default ___CSS_LOADER_EXPORT___;
