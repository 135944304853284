import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const PngIcon: FC = () => (
    <AntIcon
        component={() => (
            <svg width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.6 0H1.4C0.626801 0 0 0.626801 0 1.4V26.6C0 27.3732 0.626801 28 1.4 28H19.6C20.3732 28 21 27.3732 21 26.6V1.4C21 0.626801 20.3732 0 19.6 0Z"
                fill="#FFA940"/>
            <path
                opacity="0.6"
                d="M4.5 13H7.5V16H4.5V13ZM4.5 19H7.5V22H4.5V19ZM10.5 13H13.5V16H10.5V13ZM10.5 19H13.5V22H10.5V19ZM7.5 16H10.5V19H7.5V16ZM7.5 22H10.5V25H7.5V22ZM13.5 16H16.5V19H13.5V16ZM13.5 22H16.5V25H13.5V22Z"
                fill="white"/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.3 4V4.24C4.50667 4.08 4.77333 4 5.1 4H5.8C6.38667 4 6.81667 4.12667 7.09 4.38C7.36333 4.62667 7.5 5.03333 7.5 5.6V7.4C7.5 7.96 7.36 8.36667 7.08 8.62C6.80667 8.87333 6.38 9 5.8 9H4.51V11H3.5V4H4.3ZM5.15 5C4.93 5 4.76667 5.06 4.66 5.18C4.55333 5.29333 4.5 5.41667 4.5 5.55L4.51 8H5.75C5.99667 8 6.18333 7.94333 6.31 7.83C6.43667 7.71667 6.5 7.55667 6.5 7.35V5.65C6.5 5.44333 6.43667 5.28333 6.31 5.17C6.18333 5.05667 5.99667 5 5.75 5H5.15ZM9.5 4V4.24C9.70667 4.08 9.97333 4 10.3 4H10.9C11.44 4 11.84 4.12667 12.1 4.38C12.3667 4.63333 12.5 5.04 12.5 5.6V9H11.5V5.65C11.5 5.45 11.44 5.29333 11.32 5.18C11.2067 5.06 11.05 5 10.85 5H10.15C9.93 5 9.76667 5.06 9.66 5.18C9.55333 5.29333 9.5 5.41667 9.5 5.55V9H8.5V4H9.5ZM15.75 9C16.0433 9 16.2933 8.93333 16.5 8.8V9C16.5 10 16.5 10 15.5 10H14V11H16.5C17.0523 11 17.5 10.5523 17.5 10V4H15.2C14.62 4 14.19 4.12667 13.91 4.38C13.6367 4.63333 13.5 5.04 13.5 5.6V7.4C13.5 7.96667 13.6367 8.37667 13.91 8.63C14.1833 8.87667 14.6133 9 15.2 9H15.75ZM16.34 7.83C16.2333 7.94333 16.07 8 15.85 8H15.25C15.0033 8 14.8167 7.94333 14.69 7.83C14.5633 7.71667 14.5 7.55667 14.5 7.35V5.65C14.5 5.44333 14.5633 5.28333 14.69 5.17C14.8167 5.05667 15.0033 5 15.25 5H16.5V7.45C16.5 7.58333 16.4467 7.71 16.34 7.83Z"
                fill="white"/>
            </svg>
        )}
    />
)

export default PngIcon
