// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_ajFv8 {\n  width: 600px;\n}\n.modalWrapper_rSQae {\n  margin-bottom: 16px;\n}\n.header_lXEHT {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.text_AC8GX {\n  width: 360px;\n}\n.buttonsBlock_gcIfb {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 24px;\n}\n.payAlert_sOLj_ {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: 12px 24px;\n  margin-top: 19px;\n  background: #fafafa;\n  border: 1px solid #f0f0f0;\n  border-radius: 16px;\n}\n.payHeader_VKD3k {\n  font-weight: 600;\n}\n.payText_FNP70 {\n  font-size: 12px;\n}\n.leftSide_MT3fi {\n  width: 184px;\n  color: #8c8c8c;\n}\n.wrapper_Ngbo5 {\n  margin-top: 8px;\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/components/PersonalAccount/components/PaymentModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,mBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,YAAA;AAFJ;AAKA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAHJ;AAMA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAJJ;AAOA;EACI,gBAAA;AALJ;AAQA;EACI,eAAA;AANJ;AASA;EACI,YAAA;EACA,cAAA;AAPJ;AAUA;EACI,eAAA;EACA,aAAA;AARJ","sourcesContent":[".modal {\n    width: 600px;\n}\n\n.modalWrapper {\n    margin-bottom: 16px;\n}\n\n.header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.text {\n    width: 360px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 24px;\n}\n\n.payAlert {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    padding: 12px 24px;\n    margin-top: 19px;\n    background: #fafafa;\n    border: 1px solid #f0f0f0;\n    border-radius: 16px;\n}\n\n.payHeader {\n    font-weight: 600;\n}\n\n.payText {\n    font-size: 12px;\n}\n\n.leftSide {\n    width: 184px;\n    color: #8c8c8c;\n}\n\n.wrapper {\n    margin-top: 8px;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_ajFv8",
	"modalWrapper": "modalWrapper_rSQae",
	"header": "header_lXEHT",
	"text": "text_AC8GX",
	"buttonsBlock": "buttonsBlock_gcIfb",
	"payAlert": "payAlert_sOLj_",
	"payHeader": "payHeader_VKD3k",
	"payText": "payText_FNP70",
	"leftSide": "leftSide_MT3fi",
	"wrapper": "wrapper_Ngbo5"
};
export default ___CSS_LOADER_EXPORT___;
