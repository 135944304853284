import { FC, useState } from 'react'
import { Collapse, CollapsePanelProps } from 'antd'
import classNames from 'classnames'

import style from './style.m.less'
import { Card } from '../../../ui'
import { Down } from '../../../../assets/icons'

const { Panel: AntPanel } = Collapse
interface CollapsePanelPropsPlus extends CollapsePanelProps {
    headerClassName?: string
    isActive?: boolean
    defaultActiveKey?: string
    panelKey?: string
}

// обернул <AntPanel в <Collapse т.к. не работало defaultActiveKey
// перестал работать класс active (иконка не переворачивалась). добавил useState
// props?.defaultActiveKey == props.panelKey - это начальное положение иконки(если по дефолту defaultActiveKey панель открыта)
const CardPanel: FC<CollapsePanelPropsPlus> = (props) => {
    const [act, setAct] = useState(props?.defaultActiveKey == props.panelKey)
    const realHeader = (
        <div
            onClick={() => setAct((s) => !s)}
            className={classNames(style.header, props.headerClassName, { [style.active]: act })}
        >
            {props.header}
            <div className={style.icon}>
                <Down />
            </div>
        </div>
    )
    return (
        <Card className={classNames(style.card, props.className)}>
            <Collapse defaultActiveKey={[props?.defaultActiveKey || '']} className={style.collapse}>
                <AntPanel
                    showArrow={false}
                    {...props}
                    key={[props.panelKey]}
                    header={realHeader}
                    className={style.panel}
                />
            </Collapse>
        </Card>
    )
}

export default CardPanel
