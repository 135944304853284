import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const DefaultDoc: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="21"
                height="28"
                viewBox="0 0 21 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.6 0H1.4C0.626801 0 0 0.626801 0 1.4V26.6C0 27.3732 0.626801 28 1.4 28H19.6C20.3732 28 21 27.3732 21 26.6V1.4C21 0.626801 20.3732 0 19.6 0Z"
                    fill="#BFBFBF"
                />
                <path
                    d="M15.1766 9.07174C13.7047 7.59987 11.3078 7.59987 9.83751 9.07174L5.75939 13.1467C5.73283 13.1733 5.71876 13.2092 5.71876 13.2467C5.71876 13.2842 5.73283 13.3202 5.75939 13.3467L6.33595 13.9233C6.36231 13.9495 6.39798 13.9643 6.43517 13.9643C6.47236 13.9643 6.50804 13.9495 6.53439 13.9233L10.6125 9.8483C11.1188 9.34205 11.7922 9.06393 12.5078 9.06393C13.2235 9.06393 13.8969 9.34205 14.4016 9.8483C14.9078 10.3546 15.186 11.028 15.186 11.7421C15.186 12.4577 14.9078 13.1296 14.4016 13.6358L10.2453 17.7905L9.57189 18.4639C8.9422 19.0936 7.91876 19.0936 7.28908 18.4639C6.98439 18.1592 6.8172 17.7546 6.8172 17.3233C6.8172 16.8921 6.98439 16.4874 7.28908 16.1827L11.4125 12.0608C11.5172 11.9577 11.6547 11.8999 11.8016 11.8999H11.8031C11.95 11.8999 12.086 11.9577 12.1891 12.0608C12.2938 12.1655 12.35 12.303 12.35 12.4499C12.35 12.5952 12.2922 12.7327 12.1891 12.8358L8.81876 16.203C8.7922 16.2296 8.77814 16.2655 8.77814 16.303C8.77814 16.3405 8.7922 16.3764 8.81876 16.403L9.39533 16.9796C9.42168 17.0058 9.45736 17.0205 9.49455 17.0205C9.53173 17.0205 9.56741 17.0058 9.59376 16.9796L12.9625 13.6108C13.2735 13.2999 13.4438 12.8874 13.4438 12.4483C13.4438 12.0092 13.2719 11.5952 12.9625 11.2858C12.3203 10.6436 11.2766 10.6452 10.6344 11.2858L10.2344 11.6874L6.51251 15.4077C6.25991 15.6588 6.05967 15.9576 5.92341 16.2867C5.78716 16.6158 5.7176 16.9687 5.71876 17.3249C5.71876 18.0483 6.00158 18.728 6.51251 19.2389C7.0422 19.7671 7.73595 20.0311 8.4297 20.0311C9.12345 20.0311 9.8172 19.7671 10.3453 19.2389L15.1766 14.4108C15.8875 13.6983 16.2813 12.7499 16.2813 11.7421C16.2828 10.7327 15.8891 9.78424 15.1766 9.07174Z"
                    fill="white"
                />
            </svg>
        )}
    />
)
