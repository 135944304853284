import { RequestTypes } from '../../../../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_BLOCK = 'certificate/block'
export const GREEN_TOOLS_UNBLOCK = 'certificate/unblock'

export const blockCertificate = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_BLOCK}`,
    method: RequestTypes.post,
}

export const unblockCertificate = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_UNBLOCK}`,
    method: RequestTypes.post,
}
