// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tagFilter_ZDgf5 {\n  display: flex;\n}\n.clickable_IZdWx {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/NewsAndEvents/components/TagFilter/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;EACI,eAAA;AAAJ","sourcesContent":[".tagFilter {\n    display: flex;\n}\n\n.clickable {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagFilter": "tagFilter_ZDgf5",
	"clickable": "clickable_IZdWx"
};
export default ___CSS_LOADER_EXPORT___;
