import { FC } from 'react'
import moment from 'moment'

import { EStatus, IData } from './data'

import { Tag } from '../../../ui'

const DateCell: FC<{ date: Date }> = ({ date }) => {
    return <>{moment(date).format('DD.MM.YYYY hh:mm')}</>
}

export const columns = [
    {
        title: 'Дата события',
        dataIndex: 'date',
        key: 'date',
        render: (date: Date) => <DateCell date={date} />,
        width: 160,
        sorter: (a: IData, b: IData) => {
            return new Date(a.date).getTime() - new Date(b.date).getTime()
        },
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (status?: EStatus) =>
            status ? (
                <Tag
                    outline
                    transparent
                    color={
                        status === EStatus.NotAgreed
                            ? 'red'
                            : status === EStatus.Agreed
                            ? 'blue'
                            : undefined
                    }
                >
                    {status}
                </Tag>
            ) : undefined,
    },
    {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        render: (value: any) => value || '-',
    },
]
