import { FC, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { DoubleSave, Import } from '../../assets/icons'
import {
    BackArrow,
    Box,
    CenteredSpin,
    CertificateCard,
    IconButton,
    ModalStatus,
    OperationHistoryMapper,
} from '../ui'
import { EHistoryType, IOperationConfig } from '../ui/OperationHistoryMapper/types'
import { Divider, Tooltip } from 'antd'
import { useCertificateData } from '../hooks/useCertificateData'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../hooks/useRequest'
import useMount from '../hooks/useMount'
import { getMyCertificateData, getPublicCertificateData } from './config'
import { monthPicker } from '../helpers'
import { BlockingBlock, OwnerBlock } from './components'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getDictionaries, selectDictionariesMaps } from '../../store/dictionaries'
import { ELoading } from '../../store/dictionaries/interfaces'
import moment from 'moment'
import { historyConfig } from './configurations'
import { getCertificate } from '../../components/GreenTools/config'
import style from './style.m.less'
import { useCurrentUser } from '../hooks/useCurrentUser'

export const getStatus = (status_id: number) => {
    switch (status_id) {
        case 1:
            return 'Активен'
        case 2:
            return 'Погашен'
        case 3:
            return 'Заблокирован'
        case 4:
            return 'Истек срок действия'
        case 5:
            return 'Удален'
        case 6:
            return 'На ЭТП'
        default:
            return 'Не определен'
    }
}

const Certificate: FC = () => {
    const { t } = useTranslation()
    const { id: pubCertNumber } = useParams<{ id: string | undefined }>()
    const {
        fetch: fetchPublicCertFetch,
        result: publicCertData,
        dropState,
    } = useRequest(getCertificate)
    const [isPublicCertLoading, setPublicCertLoading] = useState(false)

    const { fetch: fetchCertificateHistory, result: certificateHistory } = useRequest({
        link: '/api/audit/v1/event-history-certs?id=',
        method: RequestTypes.post,
    })

    const { fetch: fetchAccounts, result: accounts } = useRequest({
        link: '/api/green-tools/v1/accounts',
        method: RequestTypes.get,
    })
    const certHistoryParsed: IOperationConfig[] = []

    const shortFormat = 'DD.MM.YYYY'
    const extraFormat = 'D MMMM, dddd'
    const dayUnit = 'day'
    let recordsInSet = 5

    const certificateData = useCertificateData(pubCertNumber)

    const [modalStatus, setModalStatus] = useState<ModalStatus>(['divide', false])

    const getCertificates = () => {
        // TODO: поменять логику подгрузки сертификатов при открытии в отдельной вкладке через копирование
        if (certificateData?.option !== 5) {
            fetchPublicCertFetch({
                body: {
                    certificate_number: pubCertNumber,
                },
                onError: dropState,
                errorCodeMessage: true,
            })
            setPublicCertLoading(true)
        }
    }

    const { fetch, result, isLoading } = useRequest(
        certificateData?.isOwner ? getMyCertificateData : getPublicCertificateData,
    )

    const isLoadingDict = useAppSelector((state) => state.dictionaries.loading)
    const { renewableEnergyTypesMap } = useAppSelector(selectDictionariesMaps) ?? {}
    const dispatch = useAppDispatch()
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)
    const { isUserAdmin: isAdminType, uid } = useCurrentUser()

    const isOwnerOrAdmin = result?.is_owner || isAdminType

    const fetchCertificateData = () => {
        if (certificateData?.id || publicCertData?.certificate_id) {
            fetch({
                addToLink: certificateData?.id ?? publicCertData?.certificate_id,
            })
        }
    }

    const getCertificateHistory = () => {
        if (certificateData?.id || publicCertData?.certificate_id) {
            fetchCertificateHistory({
                addToLink: certificateData?.id ?? publicCertData?.certificate_id,
            })
        }
    }

    if (!isLoading && !result && (certificateData?.id || publicCertData?.certificate_id)) {
        fetch({
            addToLink: certificateData?.id ?? publicCertData?.certificate_id,
        })
    }

    useEffect(() => {
        if (!certificateData?.isOwner) {
            return
        }
        fetchAccounts({})
    }, [certificateData])

    useMount(() => {
        if (isLoadingDict !== ELoading.Loaded) {
            dispatch(getDictionaries())
        }
    })

    useEffect(() => {
        if (certificateData) {
            getCertificateHistory()
        }
    }, [certificateData])

    if (isLoading || isLoadingDict === ELoading.Loading || !result) {
        return <CenteredSpin />
    }

    const {
        audit_id,
        certificate_number,
        generator_name,
        month,
        status_id,
        renewable_energy_type_id,
        volume,
        year,
        certificate_id,
        is_invisible,
        repayments,
        is_change_owner,
        owner,
        is_deposit,
        block,
        ts_created,
        ts_expired,
        ts_deleted,
        split,
        is_etp,
        etp_data,
        generator_id,
    } = result
    const productionPeriod = `${monthPicker(month)} ${year}`

    const account_number =
        repayments?.find((el: any) => el.user_id === uid)?.account_number || result?.account_number
    const account_id =
        repayments?.find((el: any) => el.user_id === uid)?.account_id || result?.account_id

    const repayment = repayments?.find((el: any) => el.user_id === uid) || result.repayment

    const currentAccountPaid = accounts?.accounts.find(
        (el: any) => el.number === account_number,
    )?.ts_payed_account

    const isAccountNotRadyForEtp = moment(currentAccountPaid) < moment()

    const importDataToCSV = () => {
        const rows = [
            [
                'Номер СП',
                'Статус сертификата',
                'Тип ВИЭ',
                'Объем сертификата',
                'Название ГО на котором создан СП',
                'Период производства',
                'Дата выдачи',
                'Срок действия',
            ],
            [
                certificate_number,
                getStatus(status_id),
                getEnergyType(renewable_energy_type_id),
                volume,
                generator_name,
                `${monthPicker(month)} ${year}`,
                moment(ts_created).format(shortFormat),
                moment(ts_expired).format(shortFormat),
            ],
        ]

        const csvContent =
            'data:text/csv;charset=utf-8,\uFEFF' + rows.map((e) => e.join(';')).join('\n')

        const encodedUri = encodeURI(csvContent)
        const a = document.createElement('a'),
            url = encodedUri
        a.href = url
        a.download = `СП-${certificate_number}.csv`
        document.body.appendChild(a)
        a.click()
    }

    if (certificateHistory?.total > 0 && certificateHistory?.events) {
        const today = moment().startOf(dayUnit)
        const eh = certificateHistory?.events
            ?.map((phe: { date: string; operations: any[] }) => {
                const eventDay = moment(phe.date, shortFormat)

                let title = eventDay.format(extraFormat)

                if (eventDay.isSame(today)) {
                    title = `${t('history.today')}, ${title}`
                }

                if (eventDay.isSame(today.subtract(1, dayUnit))) {
                    title = `${t('history.yesterday')}, ${title}`
                }

                let operations

                if (recordsInSet > 0 && phe.operations?.length > 0) {
                    const delta = Math.min(phe.operations.length, recordsInSet)
                    operations = phe.operations.slice(0, delta)
                    recordsInSet -= delta
                }

                return {
                    flexStart: false,
                    iconMargin: false,
                    date: title,
                    operations: operations,
                }
            })
            .filter((phe: { date: string; operations: any[] }) => phe.operations?.length > 0)

        certHistoryParsed.push(...eh)
    }

    const historyContent = (
        <div className={style.historyBlock}>
            <div className={style.header}>{t('myProfile.operationHistory')}</div>
            <OperationHistoryMapper
                config={certHistoryParsed}
                settings={{ historyType: EHistoryType.Certs }}
            />
            {certificateHistory?.total > 0 && certificateHistory?.events && (
                <Link
                    to={`/certificate-history-operations/${
                        certificateData?.id ?? publicCertData?.certificate_id
                    }`}
                >
                    <IconButton customStyle={{ marginTop: '40px' }}>Все операции</IconButton>
                </Link>
            )}
        </div>
    )

    const unimplementedHistory = (
        <div className={style.historyBlock}>
            <div className={style.header}>{t('myProfile.operationHistory')}</div>
            <div className={style.historyBlockMain}>
                {historyConfig.map((item, index) => (
                    <div key={index}>
                        <div className={style.date}>
                            <div className={style.dateLabel}>
                                <div>{item.day}</div>
                                <div className={style.current}>{item.weekday}</div>
                            </div>
                            <Divider
                                style={{
                                    minWidth: 'auto',
                                    margin: '12px 0',
                                    width: '80%',
                                }}
                            />
                        </div>
                        {item?.operations?.map((operationItem: any) => (
                            <div className={style.operationMain}>
                                <div className={style.operationRow}>
                                    <div className={style.iconBlockBlue}>
                                        <DoubleSave size={15} />
                                    </div>
                                    <div className={style.historyInfoBlock}>
                                        <div className={style.label}>{operationItem.type}</div>
                                        <div className={style.time}>
                                            {moment(operationItem.time).format('HH:mm')}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.stampBlock}>
                                    <div>
                                        <Tooltip
                                            placement="topRight"
                                            color="#434343"
                                            overlayInnerStyle={{
                                                color: 'white',
                                            }}
                                            title="Скачать запрос и подпись"
                                        >
                                            <IconButton
                                                className={style.downloadButton}
                                                icon={<Import />}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <Link to="/user-history-operations">
                <IconButton customStyle={{ marginTop: '40px' }}>
                    {t('myProfile.allOperations')}
                </IconButton>
            </Link>
        </div>
    )

    return (
        <div className={style.certificateMain}>
            <div className={style.container}>
                <div className={style.headerBlock}>
                    <div className={style.backBlock}>
                        <BackArrow
                            link={
                                certificateData?.isIssuedCert ? '/user-my-green-tools' : undefined
                            }
                        />
                        <div className={style.mainHeader}>{certificateData?.cardLabel}</div>
                    </div>
                    {isAdminType && (
                        <IconButton
                            icon={<Import />}
                            customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
                            disabled
                            onClick={importDataToCSV}
                        >
                            Выгрузить
                        </IconButton>
                    )}
                </div>
                <CertificateCard
                    isDetailed
                    generatorName={generator_name}
                    generator_id={generator_id}
                    toolType={certificateData?.toolType}
                    certificateNumber={certificate_number}
                    energyType={getEnergyType(renewable_energy_type_id)}
                    isChangeOwner={is_change_owner}
                    certificateId={certificate_id}
                    productionPeriod={productionPeriod}
                    option={status_id}
                    etpData={etp_data}
                    capacity={volume}
                    invisibleStatus={is_invisible}
                    isUserType={certificateData?.isUserType}
                    modalStatus={modalStatus}
                    setModalStatus={setModalStatus}
                    accountNumber={account_number}
                    accountId={account_id}
                    refreshData={fetchCertificateData}
                    isOwner={certificateData?.isOwner}
                    ownerId={owner.uid}
                    isDeposit={is_deposit}
                    isEtp={is_etp}
                    repayment={repayment}
                    createdTs={moment(ts_created).format(shortFormat)}
                    expiredTs={moment(ts_expired).format(shortFormat)}
                    deletedTs={ts_deleted ? moment(ts_deleted).format(shortFormat) : '-'}
                    block={block}
                    isSplitted={!!split}
                    isAccountNotRadyForEtp={isAccountNotRadyForEtp}
                />
                {certificateData?.toolType === 'Сертификаты происхождения' &&
                    (isOwnerOrAdmin || certificateData?.isOwner) && (
                        <div className={style.wrapper}>
                            {/* Состояние сертификата: 2-Погашенные*/}
                            {[2].includes(status_id) && (
                                <>
                                    <div className={style.repaymentWrapper}>
                                        {isAdminType && <OwnerBlock ownerInfo={owner} />}
                                        <div className={style.repaymentBlock}>
                                            <div className={style.repaymentHeader}>
                                                {t('certificate.payment')}
                                            </div>
                                            <div className={style.objectBlock}>
                                                <div className={style.infoBlock}>
                                                    {t('certificate.paymentDate')}
                                                </div>
                                                <div className={style.dateBlock}>
                                                    {moment(repayment.ts_created).format(
                                                        'DD.MM.YYYY',
                                                    )}
                                                </div>
                                            </div>
                                            <div className={style.objectBlock}>
                                                <div className={style.infoBlock}>
                                                    Период производства
                                                </div>
                                                <div>{`${monthPicker(repayment.month)}, ${
                                                    repayment.year
                                                }`}</div>
                                            </div>
                                            <div className={style.objectBlock}>
                                                <div className={style.infoBlock}>
                                                    Количество потребителей
                                                </div>
                                                <div>{result?.repayment?.operations_count}</div>
                                            </div>
                                            <Link to={`/audit/${audit_id}`}>
                                                <IconButton
                                                    disabled={!audit_id}
                                                    customStyle={{ marginTop: '24px' }}
                                                >
                                                    Подробнее о погашении
                                                </IconButton>
                                            </Link>
                                        </div>
                                    </div>
                                    {historyContent}
                                </>
                            )}
                            {/* Состояние сертификата: 1-Активные, 3-Заблокированные, 5-Удаленные */}
                            {[1, 3, 5, 4].includes(status_id) && (
                                <>
                                    {isOwnerOrAdmin && (
                                        <div className={style.wrapper}>
                                            <Box>
                                                {isAdminType && (
                                                    <div className={style.repaymentWrapper}>
                                                        <OwnerBlock ownerInfo={owner} />
                                                    </div>
                                                )}
                                                {(status_id === 3 || is_deposit) && (
                                                    <BlockingBlock
                                                        id={certificate_id}
                                                        isDeposit={is_deposit}
                                                        blockingInfo={block}
                                                    />
                                                )}
                                                {split && (
                                                    <div className={style.repaymentWrapper}>
                                                        <div className={style.repaymentBlock}>
                                                            <div className={style.repaymentHeader}>
                                                                Деление сертификата
                                                            </div>
                                                            <div className={style.objectBlock}>
                                                                <div className={style.infoBlock}>
                                                                    Дата деления
                                                                </div>
                                                                <div className={style.dateBlock}>
                                                                    {moment(
                                                                        split.ts_created,
                                                                    ).format('DD.MM.YYYY')}
                                                                </div>
                                                            </div>
                                                            <div className={style.objectBlock}>
                                                                <div className={style.infoBlock}>
                                                                    Дочерних сертификатов
                                                                </div>
                                                                <div>{`${split.operations_count} шт.`}</div>
                                                            </div>
                                                            <Link to={`/audit/${audit_id}`}>
                                                                <IconButton
                                                                    disabled={!audit_id}
                                                                    customStyle={{
                                                                        marginTop: '24px',
                                                                    }}
                                                                >
                                                                    Подробнее об операции
                                                                </IconButton>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )}
                                            </Box>
                                            {historyContent}
                                        </div>
                                    )}
                                    {status_id === 0 && unimplementedHistory}
                                </>
                            )}
                        </div>
                    )}
            </div>
        </div>
    )
}

export default Certificate
