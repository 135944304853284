// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_IGWw2 {\n  width: 1300px;\n  padding-top: 40px;\n  padding-left: 10px;\n  padding-bottom: 100px;\n  margin: 0 auto;\n}\n.profileMain_gDNsd {\n  display: flex;\n  flex-direction: column;\n  width: max-content;\n  padding: 40px 0px 100px 80px;\n}\n.mainHeader_e0muW {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.tabs_QymZD {\n  margin-top: 32px;\n  font-weight: 600;\n  color: #595959;\n}\n", "",{"version":3,"sources":["webpack://./src/components/TSOProfile/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;AAAJ;AAGA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AAFJ","sourcesContent":[".container {\n    width: 1300px;\n    padding-top: 40px;\n    padding-left: 10px;\n    padding-bottom: 100px;\n    margin: 0 auto;\n}\n\n.profileMain {\n    display: flex;\n    flex-direction: column;\n    width: max-content;\n    padding: 40px 0px 100px 80px;\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.tabs {\n    margin-top: 32px;\n    font-weight: 600;\n    color: #595959;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_IGWw2",
	"profileMain": "profileMain_gDNsd",
	"mainHeader": "mainHeader_e0muW",
	"tabs": "tabs_QymZD"
};
export default ___CSS_LOADER_EXPORT___;
