import { FC } from 'react'
import { Alert } from 'antd'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'

import style from './style.m.less'

interface IChangePasswordModal {
    isVisible: boolean
    email: string
    onClose: () => void
    onOk: () => void
}

export const ChangePasswordModal: FC<IChangePasswordModal> = ({
    isVisible,
    email,
    onClose,
    onOk,
}) => {
    return (
        <Modal isModalVisible={isVisible}>
            <div className={style.title}>Смена пароля</div>
            <div className={style.emailWrapper}>
                <div className={style.emailTitle}>E-mail</div>
                <div>{email}</div>
                <Alert
                    message="На e-mail пользователя будет отправлено письмо со ссылкой для создания нового пароля"
                    type="info"
                    showIcon
                    className={style.alert}
                />
                <div className={style.buttonsBlock}>
                    <ButtonsBlock
                        confirmTitle="Отправить"
                        confirmAction={onOk}
                        cancelAction={onClose}
                    />
                </div>
            </div>
        </Modal>
    )
}
