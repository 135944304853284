// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".repaymentBlock_CxYe8 {\n  width: 100%;\n  margin-top: 16px;\n  padding: 24px 32px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n  line-height: 18px;\n}\n.repaymentHeader_Q9m0M {\n  font-weight: 600;\n  font-size: 16px;\n}\n.objectBlock_H42TV {\n  margin-top: 24px;\n  display: flex;\n}\n.infoBlock_AGoIL {\n  color: #8c8c8c;\n  margin-right: 24px;\n  width: 184px;\n}\n.nameBlock_DEodH {\n  word-break: break-all;\n  width: 330px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UserContract/components/BlockingBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;AAAJ;AAGA;EACI,gBAAA;EACA,aAAA;AADJ;AAIA;EACI,cAAA;EACA,kBAAA;EACA,YAAA;AAFJ;AAKA;EACI,qBAAA;EACA,YAAA;AAHJ","sourcesContent":[".repaymentBlock {\n    width: 100%;\n    margin-top: 16px;\n    padding: 24px 32px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n    line-height: 18px;\n}\n\n.repaymentHeader {\n    font-weight: 600;\n    font-size: 16px;\n}\n\n.objectBlock {\n    margin-top: 24px;\n    display: flex;\n}\n\n.infoBlock {\n    color: #8c8c8c;\n    margin-right: 24px;\n    width: 184px;\n}\n\n.nameBlock {\n    word-break: break-all;\n    width: 330px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"repaymentBlock": "repaymentBlock_CxYe8",
	"repaymentHeader": "repaymentHeader_Q9m0M",
	"objectBlock": "objectBlock_H42TV",
	"infoBlock": "infoBlock_AGoIL",
	"nameBlock": "nameBlock_DEodH"
};
export default ___CSS_LOADER_EXPORT___;
