// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper_f4y6N {\n  margin-bottom: 16px;\n}\n.headerWrapper__Wa_A {\n  display: flex;\n  gap: 16px;\n}\n.header_OXa77 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.text_J2Ltq {\n  margin-top: 24px;\n  width: 436px;\n}\n.buttonsBlock_4HR_q {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 24px;\n}\n.modalAlert_v_b9H {\n  margin-top: 24px;\n  border-radius: 12px;\n  border: 1px solid #fff1b8;\n  background: #fffbe6;\n}\n.textArea_oUueE {\n  margin-top: 3px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/KUApproval/components/KUCard/RefuseModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEA;EACI,aAAA;EACA,SAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,gBAAA;EACA,YAAA;AAFJ;AAKA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAHJ;AAMA;EACI,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAJJ;AAOA;EACI,eAAA;EACA,kBAAA;AALJ","sourcesContent":[".modalWrapper {\n    margin-bottom: 16px;\n}\n\n.headerWrapper {\n    display: flex;\n    gap: 16px;\n}\n\n.header {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.text {\n    margin-top: 24px;\n    width: 436px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 24px;\n}\n\n.modalAlert {\n    margin-top: 24px;\n    border-radius: 12px;\n    border: 1px solid #fff1b8;\n    background: #fffbe6;\n}\n\n.textArea {\n    margin-top: 3px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper_f4y6N",
	"headerWrapper": "headerWrapper__Wa_A",
	"header": "header_OXa77",
	"text": "text_J2Ltq",
	"buttonsBlock": "buttonsBlock_4HR_q",
	"modalAlert": "modalAlert_v_b9H",
	"textArea": "textArea_oUueE"
};
export default ___CSS_LOADER_EXPORT___;
