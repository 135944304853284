export const colorPicker = (energyType: string) => {
    switch (energyType) {
        case 'Энергия солнца':
            return 'orange'
        case 'Энергия ветра':
            return 'cyan'
        case 'Энергия воды':
            return 'blue'
        case 'Энергия приливов':
            return 'blue'
        case 'ВолнЭС':
            return 'blue'
        case 'ГеоТЭС':
            return 'magenta'
        case 'НпТЭС':
            return 'volcano'
        case 'Биомасса':
            return 'green'
        case 'Биогаз':
            return 'geekblue'
        case 'Свгаз':
            return 'purple'
        case 'Уггаз':
            return '#BFBFBF'
        case 'МСЗ':
            return '#B37FEB'
        case 'Энергия атома':
            return 'yellow'
        case 'Уголь':
            return '#434343'
        case 'Мазут':
            return '#FFA940'
        case 'Дизель':
            return '#85A5FF'
        case 'Природный газ':
            return '#36CFC9'
        default:
            return '#BFBFBF'
    }
}

export const colorPickerForStatistics = (energyType: string) => {
    const params = {
        'Энергия солнца': '#FFC069',
        'Энергия ветра': '#87E8DE',
        'Энергия воды': '#1890FF',
        'Энергия приливов': '#69C0FF',
        ВолнЭС: '#BAE7FF',
        ГеоТЭС: '#FFADD2',
        НпТЭС: '#FF9C6E',
        Биомасса: '#95DE64',
        Биогаз: '#85A5FF',
        Свгаз: '#D3ADF7',
        'Энергия атома': '#FFEC3D',
    }
    return params[energyType] || '#BFBFBF'
}

export const energyTypePicker = (energyType: number) => {
    switch (energyType) {
        case 1:
            return 'Энергия солнца'
        case 2:
            return 'Энергия ветра'
        case 3:
            return 'Энергия воды'
        case 4:
            return 'Энергия приливов'
        case 5:
            return 'ВолнЭС'
        case 6:
            return 'ГеоТЭС'
        case 7:
            return 'НпТЭС'
        case 8:
            return 'Биомасса'
        case 9:
            return 'Биогаз'
        case 10:
            return 'Свгаз'
        case 11:
            return 'Уггаз'
        case 12:
            return 'МСЗ'
        case 13:
            return 'Энергия атома'
        case 14:
            return 'Уголь'
        case 15:
            return 'Мазут'
        case 16:
            return 'Дизель'
        case 17:
            return 'Природный газ'
        default:
            return 'Не определен'
    }
}

const colors = [
    'orange',
    'cyan',
    'blue',
    'magenta',
    'volcano',
    'green',
    'geekblue',
    'purple',
    '#BFBFBF',
    '#B37FEB',
    'yellow',
    '#434343',
    '#FFA940',
    '#85A5FF',
    '#36CFC9',
    '#BFBFBF',
]

export const colorPicker2 = (value: number) => colors[value % colors.length]

export const graphMonthPicker = (monthId: string) => {
    switch (monthId) {
        case '01':
            return 'ЯНВ'
        case '02':
            return 'ФЕВ'
        case '03':
            return 'MАР'
        case '04':
            return 'АПР'
        case '05':
            return 'МАЙ'
        case '06':
            return 'ИЮН'
        case '07':
            return 'ИЮЛ'
        case '08':
            return 'АВГ'
        case '09':
            return 'СЕН'
        case '10':
            return 'ОКТ'
        case '11':
            return 'НОЯ'
        case '12':
            return 'ДЕК'
        default:
            return ''
    }
}

export const monthPicker = (monthId: number) => {
    switch (monthId) {
        case 1:
            return 'Январь'
        case 2:
            return 'Февраль'
        case 3:
            return 'Март'
        case 4:
            return 'Апрель'
        case 5:
            return 'Май'
        case 6:
            return 'Июнь'
        case 7:
            return 'Июль'
        case 8:
            return 'Август'
        case 9:
            return 'Сентябрь'
        case 10:
            return 'Октябрь'
        case 11:
            return 'Ноябрь'
        case 12:
            return 'Декабрь'
        default:
            return 'Не определен'
    }
}

export const monthIdPicker = (month: string) => {
    switch (month) {
        case 'январь':
            return 1
        case 'февраль':
            return 2
        case 'март':
            return 3
        case 'апрель':
            return 4
        case 'май':
            return 5
        case 'июнь':
            return 6
        case 'июль':
            return 7
        case 'август':
            return 8
        case 'сентябрь':
            return 9
        case 'октябрь':
            return 10
        case 'ноябрь':
            return 11
        case 'декабрь':
            return 12
        default:
            return 'не определен'
    }
}

export const statusIdPicker = (status: string) => {
    switch (status) {
        case 'Активные':
            return 1
        case 'Погашенные':
            return 2
        case 'Заблокированные':
            return 3
        case 'Истек срок действия':
            return 4
        case 'Удаленные':
            return 5
        case 'На ЭТП':
            return 6
        default:
            return 0
    }
}

export const regionPicker = (regionId: string) => {
    const regions: { [key: string]: string } = {
        '1': 'Республика Адыгея (Адыгея)',
        '2': 'Республика Башкортостан',
        '3': 'Республика Бурятия',
        '4': 'Республика Алтай',
        '5': 'Республика Дагестан',
        '6': 'Республика Ингушетия',
        '7': 'Кабардино-Балкарская Республика',
        '8': 'Республика Калмыкия',
        '9': 'Карачаево-Черкесская Республика',
        '10': 'Республика Карелия',
        '11': 'Республика Коми',
        '12': 'Республика Марий Эл',
        '13': 'Республика Мордовия',
        '14': 'Республика Саха (Якутия)',
        '15': 'Республика Северная Осетия - Алания',
        '16': 'Республика Татарстан (Татарстан)',
        '17': 'Республика Тыва',
        '18': 'Удмуртская Республика',
        '19': 'Республика Хакасия',
        '20': 'Чеченская Республика',
        '21': 'Чувашская Республика - Чувашия',
        '22': 'Алтайский край',
        '23': 'Краснодарский край',
        '24': 'Красноярский край',
        '25': 'Приморский край',
        '26': 'Ставропольский край',
        '27': 'Хабаровский край',
        '28': 'Амурская область',
        '29': 'Архангельская область',
        '30': 'Астраханская область',
        '31': 'Белгородская область',
        '32': 'Брянская область',
        '33': 'Владимирская область',
        '34': 'Волгоградская область',
        '35': 'Вологодская область',
        '36': 'Воронежская область',
        '37': 'Ивановская область',
        '38': 'Иркутская область',
        '39': 'Калининградская область',
        '40': 'Калужская область',
        '41': 'Камчатский край',
        '42': 'Кемеровская область',
        '43': 'Кировская область',
        '44': 'Костромская область',
        '45': 'Курганская область',
        '46': 'Курская область',
        '47': 'Ленинградская область',
        '48': 'Липецкая область',
        '49': 'Магаданская область',
        '50': 'Московская область',
        '51': 'Мурманская область',
        '52': 'Нижегородская область',
        '53': 'Новгородская область',
        '54': 'Новосибирская область',
        '55': 'Омская область',
        '56': 'Оренбургская область',
        '57': 'Орловская область',
        '58': 'Пензенская область',
        '59': 'Пермский край',
        '60': 'Псковская область',
        '61': 'Ростовская область',
        '62': 'Рязанская область',
        '63': 'Самарская область',
        '64': 'Саратовская область',
        '65': 'Сахалинская область',
        '66': 'Свердловская область',
        '67': 'Смоленская область',
        '68': 'Тамбовская область',
        '69': 'Тверская область',
        '70': 'Томская область',
        '71': 'Тульская область',
        '72': 'Тюменская область',
        '73': 'Ульяновская область',
        '74': 'Челябинская область',
        '75': 'Забайкальский край',
        '76': 'Ярославская область',
        '77': 'г. Москва',
        '78': 'Санкт-Петербург',
        '79': 'Еврейская автономная область',
        '80': 'Ненецкий автономный округ',
        '81': 'Ханты-Мансийский автономный округ - Югра',
        '82': 'Чукотский автономный округ',
        '83': 'Ямало-Ненецкий автономный округ',
        '84': 'Иные территории, включая город и космодром Байконур',
    }

    return regions[regionId]
}
