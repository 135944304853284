import { FC, useEffect, useState } from 'react'
import { Alert, Input, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../../ui'
import { ButtonsBlock } from '../../../../Edit/components'
import useRequest from '../../../../hooks/useRequest'
import { postApprove } from '../../../config'
import style from './style.m.less'

const { TextArea } = Input

interface IRefuseModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    currentDate: string
    id?: string
}

const RefuseModal: FC<IRefuseModal> = ({ isModalVisible, setIsModalVisible, currentDate, id }) => {
    const navigate = useNavigate()
    const [reason, setReason] = useState('')
    const { fetch } = useRequest(postApprove)

    const handleOk = () => {
        setIsModalVisible(false)
    }

    useEffect(() => {
        if (!isModalVisible) {
            setReason('')
        }
    }, [isModalVisible])

    const getBack = () => {
        if (!reason) {
            message.error('Укажите комментарий длиной [1 -500] символов.')
            return
        }

        fetch({
            body: {
                id: Number(id),
                action_type_id: 2,
                reason: reason,
            },
            successMessage: {
                title: 'Отказ отправлен',
                description: `Данные КУ за ${currentDate} отклонены, комментарий направлен пользователю`,
            },
            errorCodeMessage: true,
            onSuccess: () => navigate(-1),
        })
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.header}>Отказ в согласовании данных</div>
            </div>
            <div className={style.text}>
                <div>Причина отказа</div>
                <TextArea
                    className={style.textArea}
                    rows={4}
                    placeholder="Ваш комментарий"
                    value={reason}
                    maxLength={500}
                    onChange={(e) => setReason(e.target.value)}
                />
            </div>
            <Alert
                className={style.modalAlert}
                message="Указанный комментарий увидит пользователь"
                type="warning"
                showIcon
            />
            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={getBack}
                    cancelAction={handleOk}
                    confirmTitle="Отправить"
                />
            </div>
        </Modal>
    )
}

export default RefuseModal
