// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".file_YJVKn {\n  display: flex;\n  gap: 8px;\n}\n.fileTitle_LjJq7 {\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 16px;\n  color: #434343;\n  max-width: 200px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.fileSize_lFRKw {\n  font-family: 'SF Pro Display', sans-serif;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  color: #bfbfbf;\n}\n.icon_haQ8Z {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/FileView/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,QAAA;AACJ;AAEA;EACI,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;AAGA;EACI,yCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,eAAA;AAFJ","sourcesContent":[".file {\n    display: flex;\n    gap: 8px;\n}\n\n.fileTitle {\n    font-family: 'SF Pro Display', sans-serif;\n    font-weight: 400;\n    font-size: 13px;\n    line-height: 16px;\n    color: #434343;\n    max-width: 200px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.fileSize {\n    font-family: 'SF Pro Display', sans-serif;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    color: #bfbfbf;\n}\n\n.icon {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file": "file_YJVKn",
	"fileTitle": "fileTitle_LjJq7",
	"fileSize": "fileSize_lFRKw",
	"icon": "icon_haQ8Z"
};
export default ___CSS_LOADER_EXPORT___;
