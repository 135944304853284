const token = localStorage.getItem('accessToken')
    ? localStorage.getItem('accessToken')
    : sessionStorage.getItem('accessToken')

export const getToken = () => {
    return token
}

export const parseToken = (token: string | null) => {
    return (
        token &&
        token
            .split('.')
            .slice(0, -1)
            .map((token_part) => JSON.parse(atob(token_part)))
    )
}

export const getPropFromToken = (token: string | null, prop: string, tokenPart: number) => {
    const parsedToken = parseToken(token)
    if (parsedToken) {
        try {
            const property = parsedToken[tokenPart][prop]
            return property ? property : new Error(`No ${prop} in JWT token`)
        } catch (error) {
            return new Error(`No block with ${prop} in JWT token`)
        }
    }
}

export const getRoleFromToken = (token: string | null) => {
    return getPropFromToken(token, 'role', 1)
}
