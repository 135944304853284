export const userTypes = [
    { value: 'Физическое лицо', id: '65677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Юридическое лицо', id: '55677a62-97a0-47d2-8b8f-d57abe52f591' },
    { value: 'Индивидуальный предприниматель', id: '7c23e409-c4c6-46dc-ad08-156c3456610d' },
]

export const roleTypesAll = [
    { value: 'Пользователь без ЛС', id: 'b73c3fc0-b82e-4a40-98b4-53260d52dfc2' },
    { value: 'Клиент', id: '79f3d682-2701-4a5d-a160-b5a39dc9fbf3' },
    { value: 'Владелец ЗГО', id: '13741505-6d3e-4e9d-82a5-6bc0793547f3' },
    { value: 'ТСО/ГП', id: '08b416f1-2550-4f77-b176-37792ddb9c59' },
    { value: 'Оператор', id: '50970d4b-55a3-4c13-90d8-a3473d6a8773' },
    { value: 'Администратор', id: '975214d3-d271-4c75-b1f7-ac80e98eb91e' },
]

export const roleTypesEdit = [
    { value: 'Клиент', id: '79f3d682-2701-4a5d-a160-b5a39dc9fbf3' },
    { value: 'Владелец ЗГО', id: '13741505-6d3e-4e9d-82a5-6bc0793547f3' },
    { value: 'ТСО/ГП', id: '08b416f1-2550-4f77-b176-37792ddb9c59' },
]

export const citizenship = [
    { value: 'Гражданин РФ', id: '84688660-fb32-49d5-a840-990170685e5a' },
    { value: 'Не гражданин РФ', id: '51b1e8f9-1084-43b6-a490-d2585a87cd3b' },
]

export const documentTypes = [
    { value: 'Паспорт гражданина РФ', id: 'd27949e6-38a9-4753-9b0d-9807f0db5a94' },
    { value: 'Другое', id: 'd58ea967-2fe6-406a-b6ca-fe055f7dd623' },
]
