import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Key: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.8419 12.1939C14.2972 13.7317 12.0851 14.2043 10.143 13.5966L6.61115 17.1224C6.3562 17.3849 5.8538 17.5424 5.49387 17.4899L3.85918 17.2649C3.31928 17.1899 2.81688 16.6798 2.7344 16.1397L2.50944 14.5043C2.45695 14.1443 2.62942 13.6417 2.87687 13.3866L6.40119 9.86088C5.80131 7.91048 6.26622 5.69753 7.81092 4.15971C10.023 1.94676 13.6148 1.94676 15.8344 4.15971C18.054 6.37267 18.054 9.9809 15.8419 12.1939Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.25 15L7.5 16.25"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.082 9.16699C12.7724 9.16699 13.332 8.60735 13.332 7.91699C13.332 7.22664 12.7724 6.66699 12.082 6.66699C11.3917 6.66699 10.832 7.22664 10.832 7.91699C10.832 8.60735 11.3917 9.16699 12.082 9.16699Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
