import { FC } from 'react'
import { Alert as AlertAntd, AlertProps } from 'antd'
import classnames from 'classnames'
import style from './style.m.less'

const Alert: FC<AlertProps> = ({ message, className, type = 'info', showIcon = true, ...rest }) => {
    return (
        <AlertAntd
            message={message}
            className={classnames(style.infoAlert, className)}
            type={type}
            showIcon={showIcon}
            {...rest}
        />
    )
}

export default Alert
