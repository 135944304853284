import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Logo: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="140"
                height="29"
                viewBox="0 0 140 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4633 13.566V16.3554V22.9375V24.7219L8.08576 18.475H0L10.5908 28.8496H20.7269V22.9375H24.5989L35.1897 12.562H27.1044L20.7269 18.8093L14.4633 12.3389V13.566Z"
                    fill="#2B8467"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4633 0.0898438V5.99623H10.5908L0 16.3591H8.08576L14.4633 10.1188V10.2302L20.7269 16.6934V16.3591V13.5733V10.4535V7.66726V5.99623V4.21286L27.1044 10.4535H35.1897L24.5989 0.0898438H14.4633Z"
                    fill="#54B18C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.6516 10.9431V10.6906L42.8134 10.7423C42.3335 10.7694 42.0846 10.9431 42.0846 11.2445C42.0846 11.5335 42.3431 11.7374 42.7482 11.7374C43.2683 11.7374 43.6516 11.3937 43.6516 10.9431ZM41.5332 11.2444C41.5332 10.6994 41.9598 10.3771 42.751 10.3312L43.6517 10.2796V10.0178C43.6517 9.6282 43.39 9.41493 42.9197 9.41493C42.5487 9.41493 42.2716 9.6006 42.2091 9.89578H41.6918C41.7073 9.35705 42.2401 8.94922 42.9288 8.94922C43.6986 8.94922 44.1936 9.34815 44.1936 9.97192V12.1451H43.6799V11.6006H43.6672C43.4835 11.9505 43.0783 12.191 42.6266 12.191C41.9694 12.191 41.5332 11.8045 41.5332 11.2444Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M54.3358 9.95367C54.2487 9.64913 53.9712 9.41493 53.5195 9.41493C52.9616 9.41493 52.5847 9.86863 52.5847 10.5623C52.5847 11.2747 52.968 11.7097 53.5259 11.7097C53.9493 11.7097 54.2392 11.5213 54.3422 11.1772H54.8777C54.772 11.7707 54.2455 12.1754 53.5227 12.1754C52.6284 12.1754 52.0332 11.5578 52.0332 10.5623C52.0332 9.58857 52.6253 8.94922 53.5163 8.94922C54.292 8.94922 54.7815 9.41493 54.8718 9.95367H54.3358Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M65.0096 9.95367C64.9226 9.64913 64.645 9.41493 64.1933 9.41493C63.6354 9.41493 63.2585 9.86863 63.2585 10.5623C63.2585 11.2747 63.6418 11.7097 64.1997 11.7097C64.6231 11.7097 64.913 11.5213 65.016 11.1772H65.5515C65.4458 11.7707 64.9194 12.1754 64.1965 12.1754C63.3023 12.1754 62.707 11.5578 62.707 10.5623C62.707 9.58857 63.2991 8.94922 64.1901 8.94922C64.9659 8.94922 65.4554 9.41493 65.5456 9.95367H65.0096Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M75.7145 10.5622C75.7145 9.84093 75.3626 9.41484 74.7674 9.41484C74.1689 9.41484 73.8171 9.84405 73.8171 10.5622C73.8171 11.2835 74.1721 11.7096 74.7674 11.7096C75.3626 11.7096 75.7145 11.2835 75.7145 10.5622ZM73.2656 10.5623C73.2656 9.57611 73.8513 8.94922 74.7674 8.94922C75.6804 8.94922 76.266 9.57611 76.266 10.5623C76.266 11.5454 75.6804 12.1754 74.7674 12.1754C73.8513 12.1754 73.2656 11.5454 73.2656 10.5623Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M86.4477 12.9802V12.1432H83.8242V8.97803H84.3602V11.7082H85.8994V8.97803H86.4354V11.7082H86.9522V12.9802H86.4477Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M94.6738 12.1491V8.98389H95.2098V11.3396H95.2344L96.8547 8.98389H97.3907V12.1491H96.8547V9.79332H96.8296L95.2098 12.1491H94.6738Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M107.468 10.9431V10.6906L106.63 10.7423C106.15 10.7694 105.901 10.9431 105.901 11.2445C105.901 11.5335 106.159 11.7374 106.565 11.7374C107.085 11.7374 107.468 11.3937 107.468 10.9431ZM105.35 11.2444C105.35 10.6994 105.776 10.3771 106.567 10.3312L107.468 10.2796V10.0178C107.468 9.6282 107.206 9.41493 106.736 9.41493C106.365 9.41493 106.088 9.6006 106.026 9.89578H105.508C105.524 9.35705 106.057 8.94922 106.745 8.94922C107.515 8.94922 108.01 9.34815 108.01 9.97192V12.1451H107.496V11.6006H107.484C107.3 11.9505 106.895 12.191 106.443 12.191C105.786 12.191 105.35 11.8045 105.35 11.2444Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M118.358 12.9802V12.1432H115.734V8.97803H116.27V11.7082H117.81V8.97803H118.346V11.7082H118.862V12.9802H118.358Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M126.582 12.1491V8.98389H127.118V11.3396H127.143L128.763 8.98389H129.299V12.1491H128.763V9.79332H128.738L127.118 12.1491H126.582Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M139.274 10.5821V9.41338H138.654C138.233 9.41338 137.965 9.63867 137.965 10.0189C137.965 10.3479 138.237 10.5821 138.654 10.5821H139.274ZM139.274 11.0176H138.7L137.878 12.1436H137.258L138.162 10.9201C137.704 10.7924 137.426 10.4633 137.426 10.0283C137.426 9.37696 137.906 8.97803 138.651 8.97803H139.81V12.1436H139.274V11.0176Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M46.3043 28.6235V25.1217H43.7597V28.6235H41.8828V20.4819H43.7597V23.8737H46.3043V20.4819H48.1816V28.6235H46.3043Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M54.5558 28.6235V21.7415H52.0453V28.6235H50.168V20.4819H56.4331V28.6235H54.5558Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M64.9436 28.7518C62.7157 28.7518 61.1328 27.393 61.1328 24.7532V24.4215C61.1328 21.9691 62.7499 20.3672 64.9212 20.3672C65.8369 20.3672 66.4704 20.4781 66.8209 20.6544V21.9803C66.5041 21.8253 65.9499 21.6597 65.2266 21.6597C63.8579 21.6597 63.0667 22.7314 63.0667 24.5319V24.6428C63.0667 26.5978 63.8242 27.4923 65.0571 27.4923C65.8934 27.4923 66.4818 27.2937 66.8209 27.1387V28.3983C66.572 28.5528 65.8259 28.7518 64.9436 28.7518Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M72.8987 24.4763C72.8987 22.4768 72.6271 21.6041 71.3605 21.6041C70.2525 21.6041 69.8455 22.2119 69.8455 24.4763V24.6753C69.8455 26.8846 70.1057 27.5694 71.3605 27.5694C72.401 27.5694 72.8987 26.7853 72.8987 24.6976V24.4763ZM71.3603 28.7514C69.359 28.7514 67.957 27.3814 67.957 24.8298V24.3658C67.957 22.0128 69.1443 20.3672 71.4173 20.3672C73.6561 20.3672 74.7869 22.068 74.7869 24.3658V24.808C74.7869 27.3814 73.4072 28.7514 71.3603 28.7514Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M78.6525 24.5359H78.0195V27.4522H78.7546C79.8175 27.4522 80.2245 26.9219 80.2245 25.95C80.2245 25.0773 79.7724 24.5359 78.6525 24.5359ZM78.6639 21.6525H78.0195V23.4535H78.6753C79.4328 23.4535 79.7268 23.1329 79.7268 22.5697C79.7268 21.9397 79.3763 21.6525 78.6639 21.6525ZM79.1052 28.623H76.3008V20.4819H79.1617C80.869 20.4819 81.4912 21.1004 81.4912 22.1275C81.4912 23.0336 81.0614 23.575 80.1567 23.7295C81.2647 23.884 82.0791 24.6907 82.0791 26.0385C82.0791 27.6951 80.9597 28.623 79.1052 28.623Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M83.707 28.6235V20.4819H88.773V21.6974H85.5839V23.8844H88.0606V25.1106H85.5839V27.4084H88.9991V28.6235H83.707Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M93.4259 21.697V28.623H91.5486V21.697H89.7168V20.4819H95.2577V21.697H93.4259Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M103.229 22.5697C103.229 21.9397 102.879 21.6636 102.098 21.6636H101.51V24.2046H102.189C102.766 24.2046 103.229 23.9174 103.229 23.3537V22.5697ZM102.641 25.3203H101.51V28.623H99.6328V20.4819H102.754C104.541 20.4819 105.151 21.465 105.151 22.5915V23.2326C105.151 24.6133 104.28 25.3203 102.641 25.3203Z"
                    fill="black"
                />
                <mask
                    id="mask0_243_3411"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="140"
                    height="29"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 28.7566H139.953V0.0390625H0V28.7566Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_243_3411)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M113.022 28.6235H114.854V20.4819H113.022V28.6235ZM108.815 24.6134H108.295V27.4522H108.917C109.98 27.4522 110.387 26.966 110.387 25.994C110.387 25.1214 109.935 24.6134 108.815 24.6134ZM108.295 23.4534H109.279C111.088 23.4534 112.241 24.4036 112.241 26.0162C112.241 27.6729 111.122 28.623 109.268 28.623H106.576V20.4819H108.295V23.4534Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M121.613 28.6235V25.1217H119.068V28.6235H117.191V20.4819H119.068V23.8737H121.613V20.4819H123.49V28.6235H121.613Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M130.88 20.4819C130.88 23.5309 130.326 24.083 129.161 24.2931C130.394 24.6466 131.117 25.729 131.117 28.6235H129.263C129.252 25.8506 128.697 24.9668 127.737 24.9668H127.295V28.6235H125.418V20.4819H127.295V23.7958H127.68C128.709 23.7958 129.048 23.376 129.048 20.4819H130.88Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M135.491 22.5038L134.53 25.4975H136.52L135.491 22.5038ZM137.526 28.6235L136.916 26.6685H134.168L133.535 28.6235H132.008L134.812 20.4819H136.565L139.449 28.6235H137.526Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M41.418 16.4858H139.98V16.0405H41.418V16.4858Z"
                        fill="black"
                    />
                </g>
            </svg>
        )}
    />
)
