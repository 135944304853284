import { FC } from 'react'
import { Checkbox as AntCheckbox, CheckboxProps } from 'antd'

import style from './style.m.less'

const Checkbox: FC<CheckboxProps> = (props) => (
    <div className={style.checkbox}>
        <AntCheckbox {...props} />
    </div>
)

export default Checkbox
