import { FC, useState } from 'react'
import { currencyFormat, monthPicker } from '../../../helpers'
import { DownMini } from '../../../../assets/icons'
import { Card, Box } from '../../../ui'
import { Pagination } from 'antd'
import moment from 'moment'
import style from './style.m.less'

const PAGE_SIZE = 5

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page?: number
    pageSize?: number
}

interface ICertBlock {
    data: any
    total?: number
    isMain?: boolean
}

const CertBlock: FC<ICertBlock> = ({ data, total, isMain }) => {
    const {
        account_number,
        created_ts,
        expires_ts,
        generator_name,
        month,
        volume,
        year,
        certificate_number,
        energyType,
    } = data

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Card className={style.certWrapper} onClick={toggle}>
            <Box direction="row" justify="space-between">
                <Box direction="row" gap="12px">
                    <div className={style.certTitle}>
                        {isMain ? 'Родительский СП' : 'Дочерний СП'}
                    </div>
                    <div className={style.certNumber}>{certificate_number}</div>
                    <div className={isOpen ? style.iconDownRotated : style.iconDown}>
                        <DownMini />
                    </div>
                </Box>
                {total && isOpen && (
                    <div className={style.certsTotal}>{`Дочерних СП: ${total}`}</div>
                )}
            </Box>
            {isOpen && (
                <Box style={{ marginTop: '24px' }}>
                    {isMain && (
                        <Box direction="row">
                            <div className={style.leftPart}>Номер ЛС, где находился СП</div>
                            <div>{`№${account_number}`}</div>
                        </Box>
                    )}
                    <div className={style.wrapperBlock}>
                        <div className={style.leftPart}>Вид энергии</div>
                        <div>{energyType}</div>
                    </div>
                    <div className={style.wrapperBlock}>
                        <div className={style.leftPart}>Объем, кВт*ч</div>
                        <div>{currencyFormat(volume)}</div>
                    </div>
                    <div className={style.wrapperBlock}>
                        <div className={style.leftPart}>Название ГО</div>
                        <div>{generator_name}</div>
                    </div>
                    <div className={style.wrapperBlock}>
                        <div className={style.leftPart}>Период производства АГ</div>
                        <div>{`${monthPicker(month)} ${year}`}</div>
                    </div>
                    <div className={style.wrapperBlock}>
                        <div className={style.leftPart}>Дата создания СП</div>
                        <div>{created_ts ? moment(created_ts).format('DD.MM.YYYY') : ''}</div>
                    </div>
                    <div className={style.wrapperBlock}>
                        <div className={style.leftPart}>Срок действия СП</div>
                        <div>
                            {expires_ts ? `до ${moment(expires_ts).format('DD.MM.YYYY')}` : ''}
                        </div>
                    </div>
                </Box>
            )}
        </Card>
    )
}

interface IDividedBlock {
    eventDetails: any
    getCustomData: (data: PaginationType) => void
    dictionaries: any
}

const DividedBlock: FC<IDividedBlock> = ({ eventDetails, getCustomData, dictionaries }) => {
    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)
    const { renewableEnergyTypesMap } = dictionaries
    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)
    const list = eventDetails?.payload?.certs
    const total = eventDetails?.payload?.total
    const {
        account_number,
        certificate_id,
        created_ts,
        expires_ts,
        generator_id,
        generator_name,
        month,
        renewable_energy_type_id,
        volume,
        year,
        certificate_number,
    } = eventDetails.payload

    const energyType = getEnergyType(renewable_energy_type_id)

    return (
        <>
            <div className={style.energyBlock}>
                <CertBlock
                    data={{
                        account_number,
                        certificate_id,
                        created_ts,
                        expires_ts,
                        generator_id,
                        generator_name,
                        month,
                        renewable_energy_type_id,
                        volume,
                        year,
                        certificate_number,
                        energyType,
                    }}
                    total={total}
                    isMain
                />
                {list?.map((item: any) => {
                    const energyType = getEnergyType(item.renewable_energy_type_id)
                    return <CertBlock data={{ ...item, energyType }} />
                })}
            </div>
            {total > 5 && (
                <Pagination
                    className={style.pagination}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    total={total || 0}
                    onChange={(page, pageSize) => {
                        getCustomData({ page, pageSize })
                        setPagination({ page, pageSize })
                    }}
                    showSizeChanger
                />
            )}
        </>
    )
}

export default DividedBlock
