import { RequestTypes } from '../../../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_BLOCK = 'contracts/set-contract-blocked'
export const GREEN_TOOLS_UNBLOCK = 'contracts/set-contract-unblocked'

export const blockGreenTool = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_BLOCK}`,
    method: RequestTypes.post,
}

export const unblockGreenTool = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_UNBLOCK}`,
    method: RequestTypes.post,
}
