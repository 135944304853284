import AntIcon from '@ant-design/icons'
import { IIconProps } from './interfaces'
import { FC } from 'react'

export const Sort2: FC<IIconProps> = ({ size = 16 }) => (
    <AntIcon
        component={() => (
            <svg
                width={size}
                height={size}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.83333 3.5C1.64924 3.5 1.5 3.64924 1.5 3.83333C1.5 4.01743 1.64924 4.16667 1.83333 4.16667L4.83333 4.16667C5.01743 4.16667 5.16667 4.01743 5.16667 3.83333C5.16667 3.64924 5.01743 3.5 4.83333 3.5L1.83333 3.5ZM1.5 12.1667C1.5 12.3508 1.64924 12.5 1.83333 12.5L14.1667 12.5C14.3508 12.5 14.5 12.3508 14.5 12.1667C14.5 11.9826 14.3508 11.8333 14.1667 11.8333L1.83333 11.8333C1.64924 11.8333 1.5 11.9826 1.5 12.1667ZM1.83333 7.66667C1.64924 7.66667 1.5 7.81591 1.5 8C1.5 8.1841 1.64924 8.33333 1.83333 8.33333L9.5 8.33333C9.68409 8.33333 9.83333 8.18409 9.83333 8C9.83333 7.8159 9.68409 7.66667 9.5 7.66667L1.83333 7.66667Z"
                    stroke="currentColor"
                />
            </svg>
        )}
    />
)
