import { FC, useEffect, useState } from 'react'
import { Form, Input, Typography } from 'antd'
import { Modal, PrimaryButton } from '../../ui'
import { Box } from '../../ui/Box'
import useRequest from '../../hooks/useRequest'
import { cancelAppealUser } from '../config'

import style from '../style.m.less'
import { useForm } from 'antd/lib/form/Form'

interface IChangeRoleModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean, type: number) => void
    id: number
    getAppeal: any
}

const CancelAppealModal: FC<IChangeRoleModal> = ({
    isModalVisible,
    setIsModalVisible,
    id,
    getAppeal,
}) => {
    const { fetch, isSuccess, isLoading } = useRequest(cancelAppealUser)
    const [comment, setComment] = useState<string>('')
    const [form] = useForm()

    const sendApprove = (values: any) => {
        fetch({
            body: {
                message:
                    !values.message || values.message.trim().length < 1
                        ? 'Заявка отозвана'
                        : values.message,
            },
            getParams: {
                id,
            },
        })
    }

    useEffect(() => {
        if (!isSuccess) {
            return
        }

        getAppeal()
        setIsModalVisible(false, 0)
    }, [isSuccess])

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>Отозвать заявку</div>
            <Box width={400} margin="20px 0px 0px 0px">
                <Typography.Text>
                    {`Вы действительно хотите отозвать заявку №${id}? Отменить это действие будет невозможно.`}
                </Typography.Text>
            </Box>
            <div className={style.inputBlock}>
                <Form onFinish={sendApprove} form={form} name="completeAppeal" layout="vertical">
                    <Form.Item
                        name="message"
                        label="Комментарий"
                        rules={[
                            {
                                max: 500,
                                message: 'Введено больше 500 символов',
                            },
                        ]}
                    >
                        <Input.TextArea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            style={{ minHeight: 95, maxWidth: 600 }}
                        />
                    </Form.Item>
                </Form>
            </div>
            <Box direction="row" justify="flex-end" margin="20px 0px 0px 0px">
                <PrimaryButton
                    onClick={() => setIsModalVisible(false, 0)}
                    value={'Отмена'}
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryButton
                    onClick={() => form.submit()}
                    disabled={isLoading}
                    value={'Отозвать'}
                    isDelete
                    customStyle={{
                        marginLeft: '16px',
                        padding: '8px 16px',
                    }}
                />
            </Box>
        </Modal>
    )
}

export default CancelAppealModal
