// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editMain_RSsPt {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 80px 100px 0;\n  width: 1300px;\n  margin: 0 auto;\n}\n.editFrame_QLrIr {\n  margin-top: 32px;\n  padding: 24px 32px;\n  background: #fff;\n  box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n  border-radius: 16px;\n}\n.controlsBlock_Nfnu_ {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n.dateBlock_w0VBH {\n  margin-left: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOProfile/pages/GOPaymentOperations/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,0BAAA;EACA,aAAA;EACA,cAAA;AACJ;AAEA;EACI,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,8CAAA;EACA,mBAAA;AAAJ;AAGA;EACI,aAAA;EACA,8BAAA;EACA,qBAAA;AADJ;AAIA;EACI,iBAAA;AAFJ","sourcesContent":[".editMain {\n    display: flex;\n    flex-direction: column;\n    padding: 40px 80px 100px 0;\n    width: 1300px;\n    margin: 0 auto;\n}\n\n.editFrame {\n    margin-top: 32px;\n    padding: 24px 32px;\n    background: #fff;\n    box-shadow: 0px 2px 8px rgba(40, 97, 91, 0.05);\n    border-radius: 16px;\n}\n\n.controlsBlock {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-end;\n}\n\n.dateBlock {\n    margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editMain": "editMain_RSsPt",
	"editFrame": "editFrame_QLrIr",
	"controlsBlock": "controlsBlock_Nfnu_",
	"dateBlock": "dateBlock_w0VBH"
};
export default ___CSS_LOADER_EXPORT___;
