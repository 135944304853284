export enum ELabels {
    FIZ = 'Физические лица',
    YUR = 'Юридические лица',
    IP = 'ИП',
}

export interface ITypeOption {
    label: ELabels
    id: number
}

export interface TableRef {
    updateFilters: (data: '0' | '1' | '2') => void
}

export type PaginationType = {
    page: number
    pageSize: number
    filters?: any
}

export interface ITable {
    currentUsers: string
    subTabKey?: string
}

export type Filters = {
    login?: string
    full_name?: string
    user_role?: number
    user_type?: number
    email?: string
    inn?: string
    snils?: string
}
