import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const DownMini: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5293 5.13065L0.983127 1.44972C0.729792 1.18675 0.729792 0.770524 0.983127 0.507562C1.25018 0.230357 1.69403 0.230357 1.96109 0.507562L5.0007 3.66269L8.04032 0.507562C8.30737 0.230357 8.75122 0.230357 9.01828 0.507562C9.27161 0.770524 9.27161 1.18675 9.01828 1.44972L5.47211 5.13065C5.21176 5.40089 4.78965 5.40089 4.5293 5.13065Z"
                    fill="#8C8C8C"
                />
            </svg>
        )}
    />
)
