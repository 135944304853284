// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_S_eMw {\n  display: flex;\n  align-items: center;\n  gap: 13px;\n}\n.tabs_kd4iw {\n  margin-top: 32px;\n  font-weight: 600;\n  color: #595959;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UserCard/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAEA;EACI,gBAAA;EACA,gBAAA;EACA,cAAA;AAAJ","sourcesContent":[".title {\n    display: flex;\n    align-items: center;\n    gap: 13px;\n}\n\n.tabs {\n    margin-top: 32px;\n    font-weight: 600;\n    color: #595959;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_S_eMw",
	"tabs": "tabs_kd4iw"
};
export default ___CSS_LOADER_EXPORT___;
