import { FC } from 'react'
import style from './style.m.less'
import { GeneratorLayout } from '../Edit/pages'
import { useTranslation } from 'react-i18next'

const CreateGenerator: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.generatorsMain}>
            <div className={style.mainHeader}>{t('generators.create')}</div>
            <div className={style.mainWrapper}>
                <GeneratorLayout type={'create'} />
            </div>
        </div>
    )
}

export default CreateGenerator
