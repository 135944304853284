import React from 'react'
import { DocIcon, PdfIcon, XlsIcon, DefaultDoc, PngIcon } from '../../assets/icons'

export const documentIconPicker = (documentType?: string): React.ReactNode => {
    switch (documentType) {
        case 'pdf':
            return <PdfIcon />
        case 'doc':
        case 'docx':
            return <DocIcon />
        case 'xls':
        case 'xlsx':
        case 'xml':
            return <XlsIcon />
        case 'png':
            return <PngIcon />
        default:
            return <DefaultDoc />
    }
}

export const userTypePicker = (typeId: number): string => {
    switch (typeId) {
        case 0:
            return 'Физическое лицо'
        case 1:
            return 'Индивидуальный предприниматель'
        case 2:
            return 'Юридическое лицо'
        default:
            return ''
    }
}

export const userTypeIdPicker = (typeName: string): number => {
    switch (typeName) {
        case 'Физическое лицо':
            return 0
        case 'Индивидуальный предприниматель':
            return 1
        case 'Юридическое лицо':
            return 2
        default:
            return 0
    }
}

export const userRolePicker = (roleId: number): string => {
    switch (roleId) {
        case 1:
            return 'Пользователь без ЛС'
        case 2:
            return 'Клиент'
        case 3:
            return 'Владелец ЗГО'
        case 4:
            return 'ТСО/ГП'
        case 5:
            return 'Оператор'
        case 6:
            return 'Администратор'
        case 7:
            return 'Супер администратор'
        default:
            return ''
    }
}

export const userRoleIdPicker = (roleName: string): number => {
    switch (roleName) {
        case 'Пользователь без ЛС':
            return 1
        case 'Клиент':
            return 2
        case 'Владелец ЗГО':
            return 3
        case 'ТСО/ГП':
            return 4
        case 'Оператор':
            return 5
        case 'Администратор':
            return 6
        default:
            return 0
    }
}

export const availableRolesSelector = (
    role: number,
    type: number,
    isRegistration: boolean,
    isAddingUser: boolean,
) => {
    if (isRegistration) {
        return [
            { value: 'Клиент', id: '79f3d682-2701-4a5d-a160-b5a39dc9fbf3' },
            {
                value: 'Владелец ЗГО',
                description:
                    type === 0
                        ? 'Тип пользователя необходимо изменить на юридическое лицо или ИП'
                        : null,
                id: '13741505-6d3e-4e9d-82a5-6bc0793547f3',
            },
            {
                value: 'ТСО/ГП',
                description:
                    type === 0 || type === 1
                        ? 'Тип пользователя необходимо изменить на юридическое лицо'
                        : null,
                id: '08b416f1-2550-4f77-b176-37792ddb9c59',
            },
        ]
    } else if (isAddingUser) {
        return [
            { value: 'Оператор', id: '50970d4b-55a3-4c13-90d8-a3473d6a8773' },
            { value: 'Администратор', id: '975214d3-d271-4c75-b1f7-ac80e98eb91e' },
        ]
    } else {
        switch (role) {
            case 1:
                return [
                    {
                        value: 'Пользователь без ЛС',
                        id: '8td3d682-2701-4a5d-a160-b5a39dc9fbf3',
                    },
                    { value: 'Клиент', id: '79f3d682-2701-4a5d-a160-b5a39dc9fbf3' },
                    {
                        value: 'Владелец ЗГО',
                        description:
                            type === 0
                                ? 'Тип пользователя необходимо изменить на юридическое лицо или ИП'
                                : null,
                        id: '13741505-6d3e-4e9d-82a5-6bc0793547f3',
                    },
                    {
                        value: 'ТСО/ГП',
                        description:
                            type === 0 || type === 1
                                ? 'Тип пользователя необходимо изменить на юридическое лицо'
                                : null,
                        id: '08b416f1-2550-4f77-b176-37792ddb9c59',
                    },
                ]
            case 2:
                return [
                    {
                        value: 'Клиент',
                        id: '8td3d682-2701-4a5d-a160-b5a39dc9fbf3',
                    },
                    { value: 'Пользователь без ЛС', id: '79f3d682-2701-4a5d-a160-b5a39dc9fbf3' },
                    {
                        value: 'Владелец ЗГО',
                        description:
                            type === 0
                                ? 'Тип пользователя необходимо изменить на юридическое лицо или ИП'
                                : null,
                        id: '13741505-6d3e-4e9d-82a5-6bc0793547f3',
                    },
                    {
                        value: 'ТСО/ГП',
                        description:
                            type === 0 || type === 1
                                ? 'Тип пользователя необходимо изменить на юридическое лицо'
                                : null,
                        id: '08b416f1-2550-4f77-b176-37792ddb9c59',
                    },
                ]
            case 3:
                return [
                    {
                        value: 'Владелец ЗГО',
                        id: '8td3d682-2701-4a5d-a160-b5a39dc9fbf3',
                    },
                    { value: 'Клиент', id: '79f3d682-2701-4a5d-a160-b5a39dc9fbf3' },
                    {
                        value: 'Пользователь без ЛС',
                        id: '13741505-6d3e-4e9d-82a5-6bc0793547f3',
                    },
                    {
                        value: 'ТСО/ГП',
                        description:
                            type === 0 || type === 1
                                ? 'Тип пользователя необходимо изменить на юридическое лицо'
                                : null,
                        id: '08b416f1-2550-4f77-b176-37792ddb9c59',
                    },
                ]
            case 4:
                return [
                    {
                        value: 'ТСО/ГП',
                        id: '8td3d682-2701-4a5d-a160-b5a39dc9fbf3',
                    },
                    { value: 'Клиент', id: '79f3d682-2701-4a5d-a160-b5a39dc9fbf3' },
                    { value: 'Владелец ЗГО', id: '13741505-6d3e-4e9d-82a5-6bc0793547f3' },
                    { value: 'Пользователь без ЛС', id: '08b416f1-2550-4f77-b176-37792ddb9c59' },
                ]
            case 5:
                return [
                    { value: 'Оператор', id: '50970d4b-55a3-4c13-90d8-a3473d6a8773' },
                    { value: 'Администратор', id: '975214d3-d271-4c75-b1f7-ac80e98eb91e' },
                ]
            case 6:
                return [
                    { value: 'Администратор', id: '975214d3-d271-4c75-b1f7-ac80e98eb91e' },
                    { value: 'Оператор', id: '50970d4b-55a3-4c13-90d8-a3473d6a8773' },
                ]
            default:
                return []
        }
    }
}

export const citizenshipPicker = (id: number): string => {
    switch (id) {
        case 1:
            return 'Гражданин РФ'
        case 2:
            return 'Не гражданин РФ'
        default:
            return ''
    }
}

export const citizenshipIdPicker = (value: string): number => {
    switch (value) {
        case 'Гражданин РФ':
            return 1
        case 'Не гражданин РФ':
            return 2
        default:
            return 1
    }
}

export const documentTypePicker = (id: number): string => {
    switch (id) {
        case 1:
            return 'Паспорт гражданина РФ'
        case 2:
            return 'Другое'
        default:
            return ''
    }
}

export const documentTypeIdPicker = (value: string): number => {
    switch (value) {
        case 'Паспорт гражданина РФ':
            return 1
        case 'Другое':
            return 2
        default:
            return 1
    }
}

export const iconSelector = (file: any, filename: string) => {
    const ext = file[filename].substr(file[filename].indexOf('.') + 1)
    switch (ext) {
        case 'pdf':
            return <PdfIcon />
        case 'doc':
        case 'docx':
            return <DocIcon />
        case 'xls':
        case 'xlsx':
        case 'xml':
            return <XlsIcon />
        case 'png':
            return <PngIcon />
        default:
            return <DefaultDoc />
    }
}

export const checkExtension = (filename: string) => {
    if (
        filename.includes('jpg') ||
        filename.includes('jpeg') ||
        filename.includes('doc') ||
        filename.includes('docx') ||
        filename.includes('png') ||
        filename.includes('pdf')
    ) {
        return true
    } else {
        return false
    }
}

export const createFIO = (data: any) => {
    const { first_name, last_name, middle_name, middle_name_absent } = data
    if (first_name === '' || last_name === '') {
        return null
    }
    const FIO = `${last_name} ${first_name?.slice(0, 1) + '.'}${
        middle_name_absent ? '' : middle_name?.slice(0, 1) + '.'
    }`
    return FIO
}

export const lengthConfig = {
    'Физическое лицо': {
        maxInn: 12,
        minInn: 10,
        maxOgrn: 15,
        minOgrn: 13,
    },
    'Юридическое лицо': {
        maxInn: 10,
        minInn: 10,
        maxOgrn: 13,
        minOgrn: 13,
    },
    'Индивидуальный предприниматель': {
        maxInn: 12,
        minInn: 12,
        maxOgrn: 15,
        minOgrn: 15,
    },
}
