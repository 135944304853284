import { FC, useState, useEffect } from 'react'
import { Divider } from 'antd'
import { CenteredSpin, Pagination, Empty } from '../ui'
import { useTranslation } from 'react-i18next'
import { Filters, Controls, GeneratorCard } from './components'
import { IOwner } from './components/GeneratorCard/GeneratorCard'
import { ESortType } from './components/Controls/Controls'

import style from './style.m.less'
import useRequest, { RequestTypes } from '../hooks/useRequest'

const PAGE_SIZE = 10

interface IData {
    go_owners: IOwner[]
    total: number
    total_power_max: number
}

const Owners: FC = ({ isAuth }: any) => {
    const { t } = useTranslation()
    const {
        fetch,
        result,
        isLoading: loading,
    } = useRequest({
        link: '/api/generators/v1/go-owners',
        method: RequestTypes.post,
    })

    const data: IData = result

    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)

    const [userNameFilter, setUserNameFilter] = useState('')
    const [totalPowerFromFilter, setTotalPowerFromFilter] = useState(0)
    const [totalPowerToFilter, setTotalPowerToFilter] = useState(0)
    const [renewableEnergyTypeFilter, setRenewableEnergyTypeFilter] = useState<number[]>([])
    const [sortType, setSortType] = useState<ESortType>()
    const [descending, setDescending] = useState<boolean>()

    const fetchOwners = () => {
        fetch({
            body: {
                page,
                limit: pageSize,
                offset: (page - 1) * pageSize,
                filters: {
                    renewable_energy_type_ids: renewableEnergyTypeFilter,
                    total_power_from: totalPowerFromFilter,
                    total_power_to: totalPowerToFilter,
                    name: userNameFilter,
                },
                sort: {
                    type_id: sortType,
                    is_descending: descending,
                },
            },
        })
    }

    useEffect(() => {
        fetchOwners()
    }, [page, pageSize])

    useEffect(() => {
        if (page === 1) {
            fetchOwners()
        } else {
            setPage(1)
        }
    }, [
        userNameFilter,
        renewableEnergyTypeFilter,
        totalPowerFromFilter,
        totalPowerToFilter,
        sortType,
        descending,
    ])

    return (
        <div className={style.ownersMain}>
            <div className={style.mainHeader}>{t('owners.title')}</div>
            <div className={style.mainWrapper}>
                <Controls
                    name={userNameFilter}
                    sortType={sortType}
                    desc={descending}
                    onChangeUserName={setUserNameFilter}
                    onChangeSortType={setSortType}
                    onDescendingType={setDescending}
                    showDownloadData={isAuth}
                    filters={{
                        renewableEnergyType: renewableEnergyTypeFilter,
                        totalPowerFrom: totalPowerFromFilter,
                        totalPowerTo: totalPowerToFilter,
                    }}
                />
                <div className={style.mainBlock}>
                    <div className={style.cardsBlock}>
                        {loading && <CenteredSpin />}
                        {data?.go_owners ? (
                            data?.go_owners.map((owner: IOwner, index: number) => (
                                <div key={owner.uid}>
                                    <GeneratorCard owner={owner} />
                                    {index !== data.go_owners.length - 1 && (
                                        <Divider className={style.divider} />
                                    )}
                                </div>
                            ))
                        ) : (
                            <Empty
                                imageStyle={{
                                    height: 60,
                                    marginTop: 60,
                                }}
                                descriptionText={'По вашему запросу ничего не найдено'}
                            />
                        )}
                    </div>
                    <Filters
                        values={{
                            renewableEnergyType: renewableEnergyTypeFilter,
                            totalPowerFrom: totalPowerFromFilter,
                            totalPowerTo: totalPowerToFilter,
                        }}
                        onChange={({ totalPowerFrom, totalPowerTo, renewableEnergyType }) => {
                            setTotalPowerFromFilter(totalPowerFrom)
                            setTotalPowerToFilter(totalPowerTo)
                            setRenewableEnergyTypeFilter(renewableEnergyType)
                        }}
                    />
                </div>
                <Pagination
                    current={page}
                    pageSize={pageSize}
                    paginationChange={(receivedPage: number, pageSize?: number) => {
                        setPage(receivedPage)
                        if (pageSize) {
                            setPageSize(pageSize)
                        }
                    }}
                    total={data?.total}
                    showSizeChanger
                />
            </div>
        </div>
    )
}

export default Owners
