// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper_hmtei {\n  height: 22px;\n  display: flex;\n  align-items: center;\n  border-radius: 12px;\n  font-size: 12px;\n  font-weight: 400;\n  border: 1px solid;\n  padding: 0 8px;\n}\n.activated_XHUTt {\n  color: #69c0ff;\n  border-color: #69c0ff;\n}\n.blocked_Kz8jQ {\n  color: #ffa39e;\n  border-color: #ffa39e;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/StatusTag/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEA;EACI,cAAA;EACA,qBAAA;AAAJ;AAGA;EACI,cAAA;EACA,qBAAA;AADJ","sourcesContent":[".wrapper {\n    height: 22px;\n    display: flex;\n    align-items: center;\n    border-radius: 12px;\n    font-size: 12px;\n    font-weight: 400;\n    border: 1px solid;\n    padding: 0 8px;\n}\n\n.activated {\n    color: #69c0ff;\n    border-color: #69c0ff;\n}\n\n.blocked {\n    color: #ffa39e;\n    border-color: #ffa39e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_hmtei",
	"activated": "activated_XHUTt",
	"blocked": "blocked_Kz8jQ"
};
export default ___CSS_LOADER_EXPORT___;
