import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Filter: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3.15104 1.2251H10.851C11.4927 1.2251 12.0177 1.7501 12.0177 2.39176V3.6751C12.0177 4.14176 11.726 4.7251 11.4344 5.01676L8.92604 7.23343C8.57604 7.5251 8.34271 8.10843 8.34271 8.5751V11.0834C8.34271 11.4334 8.10937 11.9001 7.81771 12.0751L7.00104 12.6001C6.24271 13.0668 5.19271 12.5418 5.19271 11.6084V8.51676C5.19271 8.10843 4.95938 7.58343 4.72604 7.29176L2.50938 4.95843C2.21771 4.66676 1.98438 4.14176 1.98438 3.79176V2.4501C1.98438 1.7501 2.50937 1.2251 3.15104 1.2251Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.37583 1.2251L3.5 5.83343"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
