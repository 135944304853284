import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const Note: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.3327 5.50065V12.0007C13.3327 14.0007 12.1393 14.6673 10.666 14.6673H5.33268C3.85935 14.6673 2.66602 14.0007 2.66602 12.0007V5.50065C2.66602 3.33398 3.85935 2.83398 5.33268 2.83398C5.33268 3.24732 5.49933 3.62065 5.77266 3.89398C6.046 4.16732 6.41935 4.33398 6.83268 4.33398H9.16602C9.99268 4.33398 10.666 3.66065 10.666 2.83398C12.1393 2.83398 13.3327 3.33398 13.3327 5.50065Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10.6673 2.83398C10.6673 3.66065 9.99398 4.33398 9.16732 4.33398H6.83398C6.42065 4.33398 6.0473 4.16732 5.77397 3.89398C5.50063 3.62065 5.33398 3.24732 5.33398 2.83398C5.33398 2.00732 6.00732 1.33398 6.83398 1.33398H9.16732C9.58065 1.33398 9.954 1.50065 10.2273 1.77399C10.5007 2.04732 10.6673 2.42065 10.6673 2.83398Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.33398 8.66602H8.00065"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.33398 11.334H10.6673"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )}
    />
)
