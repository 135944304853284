import { Table } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Filter from '../Filter/Filter'
import TagFilter from '../TagFilter/TagFilter'

import style from './style.m.less'

import { openedDataSource } from './data'
import { openedColumns } from './columns'

const Opened: FC = () => {
    const navigate = useNavigate()

    return (
        <div className={style.opened}>
            <Filter />
            <TagFilter />
            <div className={style.table}>
                <Table
                    dataSource={openedDataSource}
                    columns={openedColumns}
                    pagination={{
                        position: ['bottomLeft'],
                    }}
                    onRow={(record) => ({
                        onClick: () => {
                            navigate('/go-approval/' + record.key)
                        },
                    })}
                />
            </div>
        </div>
    )
}

export default Opened
