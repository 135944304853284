import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../ui'
import CloseEditModal from '../CloseEditModal/CloseEditModal'
import style from './style.m.less'

interface IButtonsBlock {
    confirmAction?: () => void
    cancelAction?: () => void
    confirmTitle?: string
    margin?: boolean
    isDelete?: boolean
}

const ButtonsBlock: FC<IButtonsBlock> = ({ confirmTitle, confirmAction, isDelete, margin }) => {
    const { t } = useTranslation()
    const [isCloseEditModallVisible, setIsCloseEditModallVisible] = useState(false)

    const openCloseEditModallModal = () => {
        setIsCloseEditModallVisible(true)
    }
    return (
        <div className={margin ? style.buttonsBlockMargin : style.buttonsBlock}>
            <PrimaryButton
                onClick={openCloseEditModallModal}
                value="Отмена"
                isCancel
                customStyle={{ padding: '8px 16px' }}
            />
            <PrimaryButton
                onClick={confirmAction}
                isDelete={isDelete}
                value={confirmTitle ? confirmTitle : t('editMyProfile.save')}
                customStyle={{ marginLeft: '16px', padding: '8px 16px' }}
            />
            <CloseEditModal
                isModalVisible={isCloseEditModallVisible}
                setIsModalVisible={setIsCloseEditModallVisible}
            />
        </div>
    )
}

export default ButtonsBlock
