import { IOperationConfig } from '../../../ui/OperationHistoryMapper/types'
import { RequestTypes } from '../../../hooks/useRequest'
import { AUDIT_API_PREFIX } from '../PaymentAccount/configurations'

export const GT_API_PREFIX = '/api/green-tools/v1/'
export const GT_API_DIAGRAM = 'accounts/diagram'
export const HISTORY_PERS_ACC = 'event-history-acc'
export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_ACCOUNTS = 'accounts'

export const getDiagram = {
    link: `${GT_API_PREFIX}${GT_API_DIAGRAM}`,
    method: RequestTypes.get,
}

export const getPersAccHistory = {
    link: `${AUDIT_API_PREFIX}${HISTORY_PERS_ACC}`,
    method: RequestTypes.post,
}

export const getAccountsConfig = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_ACCOUNTS}`,
    method: RequestTypes.get,
}

export const ownerDataSet = [
    {
        color: '#B7EB8F',
        name: 'Свободные СП',
        id: '314afcfc-2a0e-45de-8fa5-be33933e2ea4',
    },
    {
        color: '#BAE7FF',
        name: 'Свободные ЗИ других систем',
        id: 'd44e0aaf-2031-4c34-aa60-01eecc70e624',
    },
    {
        color: '#FFE58F',
        name: 'Свободные АГ',
        id: 'qb7e0aaf-2031-4c34-aa60-01eecc70e624',
    },
    {
        color: '#EFDBFF',
        name: 'Заблокированные АГ, СП и ЗД',
        id: 'ee462475-9278-49ce-b7c4-62f0a0cbb911',
    },
]

export const dataSet = [
    {
        color: '#B7EB8F',
        name: 'Свободные СП',
        id: '314afcfc-2a0e-45de-8fa5-be33933e2ea4',
    },
    {
        color: '#BAE7FF',
        name: 'Свободные ЗИ других систем',
        id: 'd44e0aaf-2031-4c34-aa60-01eecc70e624',
    },
    {
        color: '#EFDBFF',
        name: 'Заблокированные АГ, СП и ЗД',
        id: 'ee462475-9278-49ce-b7c4-62f0a0cbb911',
    },
]

export const valuePicker = (name: string) => {
    switch (name) {
        case 'Свободные СП':
            return 'free_certs'
        case 'Свободные ЗИ других систем':
            return 'free_green_tools'
        case 'Свободные АГ':
            return 'free_attr_generation'
        case 'Заблокированные АГ, СП и ЗД':
            return 'blocked_green_tools'
        default:
            return 'certificates_free'
    }
}

export const historyConfig: IOperationConfig[] = [
    {
        date: 'Сегодня, 17 июня, пятница',
        operations: [
            { type: 'repayment', time: '12:24', value: '- 50 780 000 кВт*ч' },
            { type: 'repayment', time: '12:00', value: '- 390 500 кВт*ч' },
            { type: 'division', time: '11:40' },
        ],
        flexStart: false,
        iconMargin: true,
    },
    {
        date: 'Вчера, 16 июня, четверг',
        operations: [{ type: 'division', time: '20:39' }],
        flexStart: false,
        iconMargin: true,
    },
]
