// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper_NdMPJ {\n  display: flex;\n}\n.contentWrapper_nRns4 {\n  margin-left: 18px;\n  width: 319px;\n}\n.modalHeader_UAYaQ {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.modalText_ng6Oc {\n  margin-top: 16px;\n  width: 280px;\n  font-family: 'SF Pro Display', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: #40434D;\n}\n.buttonsBlock_xLItK {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.dataBlock_qQZYD {\n  margin-top: 24px;\n}\n.textArea_aDW3g {\n  margin-top: 3px;\n  width: 436px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/GreenToolsModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;EACI,iBAAA;EACA,YAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,gBAAA;EACA,YAAA;EACA,yCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAOA;EACI,eAAA;EACA,YAAA;EACA,kBAAA;AALJ","sourcesContent":[".mainWrapper {\n    display: flex;\n}\n\n.contentWrapper {\n    margin-left: 18px;\n    width: 319px;\n}\n\n.modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #303440;\n}\n\n.modalText {\n    margin-top: 16px;\n    width: 280px;\n    font-family: 'SF Pro Display', sans-serif;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 22px;\n    color: #40434D;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.dataBlock {\n    margin-top: 24px;\n}\n\n.textArea {\n    margin-top: 3px;\n    width: 436px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": "mainWrapper_NdMPJ",
	"contentWrapper": "contentWrapper_nRns4",
	"modalHeader": "modalHeader_UAYaQ",
	"modalText": "modalText_ng6Oc",
	"buttonsBlock": "buttonsBlock_xLItK",
	"dataBlock": "dataBlock_qQZYD",
	"textArea": "textArea_aDW3g"
};
export default ___CSS_LOADER_EXPORT___;
