// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_LV3TN {\n  width: 1300px;\n  padding-top: 40px;\n  padding-left: 10px;\n  padding-right: 10px;\n  padding-bottom: 100px;\n  margin: 0 auto;\n}\n.titleWrapper_TdBj6 {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 32px;\n}\n.backLink_UXtxu {\n  display: flex;\n  cursor: pointer;\n}\n.cardsWrapper_ds0fF {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 16px;\n}\n.mainHeader_JTiB4 {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n}\n.pagination_agoml {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/CertificatesInPledge/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAGA;EACI,aAAA;EACA,eAAA;AADJ;AAIA;EACI,aAAA;EACA,qCAAA;EACA,SAAA;AAFJ;AAKA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAHJ;AAMA;EACI,WAAA;AAJJ","sourcesContent":[".container {\n    width: 1300px;\n    padding-top: 40px;\n    padding-left: 10px;\n    padding-right: 10px;\n    padding-bottom: 100px;\n    margin: 0 auto;\n}\n\n.titleWrapper {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    margin-bottom: 32px;\n}\n\n.backLink {\n    display: flex;\n    cursor: pointer;\n}\n\n.cardsWrapper {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 16px;\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n}\n\n.pagination {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_LV3TN",
	"titleWrapper": "titleWrapper_TdBj6",
	"backLink": "backLink_UXtxu",
	"cardsWrapper": "cardsWrapper_ds0fF",
	"mainHeader": "mainHeader_JTiB4",
	"pagination": "pagination_agoml"
};
export default ___CSS_LOADER_EXPORT___;
