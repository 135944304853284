// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalHeader_bED0q {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.buttonsBlock_V4oGc {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.alert_mFQE3 {\n  margin-top: 24px;\n  width: 436px;\n  border-radius: 12px;\n  border: 1px solid #bae7ff;\n  background: #e6f7ff;\n}\n.inputBlock_ClQpM {\n  margin-top: 24px;\n}\n.responseError_QTa4H {\n  margin-top: 24px;\n  border-radius: 8px;\n}\n.input_iRmgI {\n  border-radius: 8px;\n}\n.currentEmail_ChP5L {\n  color: #8c8c8c;\n}\n", "",{"version":3,"sources":["webpack://./src/components/QuestionnaireApproval/components/DownloadModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,gBAAA;EACA,kBAAA;AAHJ;AAMA;EACI,kBAAA;AAJJ;AAOA;EACI,cAAA;AALJ","sourcesContent":[".modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.alert {\n    margin-top: 24px;\n    width: 436px;\n    border-radius: 12px;\n    border: 1px solid #bae7ff;\n    background: #e6f7ff;\n}\n\n.inputBlock {\n    margin-top: 24px;\n}\n\n.responseError {\n    margin-top: 24px;\n    border-radius: 8px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.currentEmail {\n    color: #8c8c8c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": "modalHeader_bED0q",
	"buttonsBlock": "buttonsBlock_V4oGc",
	"alert": "alert_mFQE3",
	"inputBlock": "inputBlock_ClQpM",
	"responseError": "responseError_QTa4H",
	"input": "input_iRmgI",
	"currentEmail": "currentEmail_ChP5L"
};
export default ___CSS_LOADER_EXPORT___;
