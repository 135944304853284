import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Input } from 'antd'
import { Modal } from '../../../ui'
import { ButtonsBlock } from '../../../Edit/components'
import style from './style.m.less'

interface IChangeEmailModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const ChangeEmailModal: FC<IChangeEmailModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalWrapper}>
                <div className={style.modalHeader}>{t('myProfile.changeEmail')}</div>
                <div className={style.modalText}>
                    <div>{t('myProfile.newEmail')}</div>
                    <Input />
                </div>
                <Alert
                    className={style.modalAlert}
                    message={
                        <div className={style.modalAlertText}>
                            <div>{t('myProfile.changeEmailText')}</div>
                            <div>{t('myProfile.changeEmailText2')}</div>
                        </div>
                    }
                    type="info"
                    showIcon
                />
                <div className={style.buttonsBlock}>
                    <ButtonsBlock
                        confirmAction={handleOk}
                        cancelAction={handleOk}
                        confirmTitle={t('myProfile.continue')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ChangeEmailModal
