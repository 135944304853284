import { FC, useState, useEffect } from 'react'
import { QuestionCircle } from '../../../assets/icons'
import { ButtonsBlock } from '../../Edit/components'
import { Modal, DropdownSelector2, CenteredSpin } from '../'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import {
    getAccounts,
    getAccountsAdmin,
    changeAccount,
    contractChangeAccount,
    othersChangeAccount,
} from './config'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import useRequest from '../../hooks/useRequest'

interface IGreenToolsModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    certificateId?: string
    refreshData?: () => void
    isContract?: boolean
    isOther?: boolean
    user_id?: string
}

const GreenToolsModal: FC<IGreenToolsModal> = ({
    isModalVisible,
    setIsModalVisible,
    certificateId,
    refreshData,
    isContract,
    isOther,
    user_id,
}) => {
    const { t } = useTranslation()
    const { isUserAdmin } = useCurrentUser()

    const [currentAccount, setCurrentAccount] = useState(null)
    const {
        fetch: fetchAccounts,
        result: accounts,
        isLoading: isLoadingAccounts,
    } = useRequest(getAccounts)
    const {
        fetch: fetchAccountsAdmin,
        result: accountsAdmin,
        isLoading: isLoadingAccountsAdmin,
    } = useRequest(getAccountsAdmin)
    const { fetch: fetchAccountChange } = useRequest(changeAccount)
    const { fetch: fetchContractAccountChange } = useRequest(contractChangeAccount)
    const { fetch: fetchOtherAccountChange } = useRequest(othersChangeAccount)

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleConfirm = () => {
        isContract
            ? fetchContractAccountChange({
                  body: { contract_number: certificateId, user_id, account_number: currentAccount },
                  successMessage: {
                      title: t('detailedCard.success'),
                      description: 'Лицевой счет зеленого договора успешно изменен',
                  },
                  errorCodeMessage: true,
                  onSuccess: () => {
                      setIsModalVisible(false)
                      if (refreshData) {
                          refreshData()
                      }
                  },
              })
            : isOther
            ? fetchOtherAccountChange({
                  body: { ext_instrument_id: certificateId, account_id: currentAccount },
                  successMessage: {
                      title: t('detailedCard.success'),
                      description: 'Лицевой счет стороннего инструмента успешно изменен',
                  },
                  errorCodeMessage: true,
                  onSuccess: () => {
                      setIsModalVisible(false)
                      if (refreshData) {
                          refreshData()
                      }
                  },
              })
            : fetchAccountChange({
                  body: { certificate_id: certificateId, account_id: currentAccount },
                  successMessage: {
                      title: t('detailedCard.success'),
                      description: 'Лицевой счет сертификата успешно изменен',
                  },
                  errorCodeMessage: true,
                  onSuccess: () => {
                      setIsModalVisible(false)
                      if (refreshData) {
                          refreshData()
                      }
                  },
              })
    }

    useEffect(() => {
        if (isModalVisible) {
            isUserAdmin
                ? fetchAccountsAdmin({
                      addToLink: user_id,
                      errorCodeMessage: true,
                  })
                : fetchAccounts({
                      errorCodeMessage: true,
                  })
        }
    }, [isModalVisible])

    useEffect(() => {
        if (!accounts && !accountsAdmin) {
            return
        }
        setCurrentAccount(isUserAdmin ? accountsAdmin?.accounts[0].id : accounts?.accounts[0].id)
    }, [accounts, accountsAdmin])

    const currentsList = isUserAdmin ? accountsAdmin : accounts

    if (isLoadingAccounts || isLoadingAccountsAdmin || currentsList === undefined) {
        return <CenteredSpin />
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.mainWrapper}>
                <QuestionCircle />
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>
                        {t('greenToolsModal.changePersAccount')}
                    </div>
                    <div className={style.modalText}>
                        {isContract
                            ? t('greenToolsModal.textContract')
                            : isOther
                            ? t('greenToolsModal.textOther')
                            : t('greenToolsModal.text')}
                    </div>
                    <DropdownSelector2
                        placeholder="Выберите из списка"
                        customStyle={{ width: '319px', marginTop: '24px' }}
                        options={currentsList?.accounts?.map((item: any) => {
                            return {
                                text: `№${item.number} (${item.name})`,
                                value: item.id,
                                id: item.id,
                            }
                        })}
                        onChange={(value) => {
                            setCurrentAccount(value)
                        }}
                        value={currentAccount}
                    />
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            confirmAction={handleConfirm}
                            cancelAction={handleCancel}
                            confirmTitle={t('greenToolsModal.confirm')}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default GreenToolsModal
