import { FC, useEffect, useState, useCallback } from 'react'
import { IconButton, InputSearch, CardInfoBlock, Popover, Card, PowerTypeTag } from '../../../ui'
import { Filter, Flash, Location } from '../../../../assets/icons'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import useRequest, { RequestTypes } from '../../../hooks/useRequest'
import { useParams, Link } from 'react-router-dom'
import { Divider, Pagination, PaginationProps } from 'antd'
import { Filters, ControlsSwitch } from '../../../Owners/components/'
import moment from 'moment'
import { selectDictionariesMaps } from '../../../../store/dictionaries'
import { useAppSelector } from '../../../../store/hooks'
import { currencyFormat } from '../../../helpers'
import debounce from 'lodash/debounce'
import ClickAwayListener from '../../../ui/ClickAwayLIstener/ClickAwayLIstener'

export enum ESortType {
    Date = 0, // 0 - Дате добавления ГО в систему
    Name = 1, // 1 - Наименованию ГО
    Power = 2, // 2 - Мощности ГО
}

const PAGE_SIZE = 10

const DEFAULT_PAG = {
    page: 1,
    pageSize: PAGE_SIZE,
}

type PaginationType = {
    page: number
    pageSize: number
    f?: any
}

interface IObjectsListProps {
    ownerName?: string
}

const ObjectsList: FC<IObjectsListProps> = ({ ownerName }) => {
    const { id } = useParams<{ id?: string }>()
    const { t } = useTranslation()

    const dictionaries = useAppSelector(selectDictionariesMaps)

    const { regionsMap, renewableEnergyTypesMap } = dictionaries

    const [sortOpen, setSortOpen] = useState(false)
    const [waitClose, setWaitclose] = useState<boolean>(false)
    const [disableClickOutSide, setDisableClickOutSide] = useState<boolean>(false)

    const [totalPowerFromFilter, setTotalPowerFromFilter] = useState(0)
    const [totalPowerToFilter, setTotalPowerToFilter] = useState(0)
    const [renewableEnergyTypeFilter, setRenewableEnergyTypeFilter] = useState<number[]>([])
    const [sortType, setSortType] = useState<ESortType>()
    const [descending, setDescending] = useState<boolean>()

    const [userNameFilter, setUserNameFilter] = useState('')
    const [qualificationDate, setQualificationDate] = useState([null, null])

    const { fetch: getListGoByOwners, result: responseListGoByOwners } = useRequest({
        link: `/api/generators/v1/go-owner-gos`,
        method: RequestTypes.post,
    })

    useEffect(() => {
        getListGoByOwners({
            addToLink: `?id=${id}`,
            body: {},
            errorCodeMessage: true,
        })
    }, [id])

    const [pagination, setPagination] = useState<PaginationType>(DEFAULT_PAG)

    const onShowSizeChange = (a: any, pageSize: number) => {
        setPagination((lastPagination) => {
            const pageSizeChange = lastPagination.pageSize !== pageSize
            if (pageSizeChange) {
                return {
                    page: 1,
                    pageSize: pageSize,
                }
            } else {
                return { ...lastPagination }
            }
        })
    }

    const onChangePage: PaginationProps['onChange'] = (page) => {
        setPagination((lastPagination: any) => ({
            ...lastPagination,
            page,
        }))
    }

    const loadOwners = useCallback(
        async (
            page: number,
            pageSize: number,
            name?: string,
            renewable_energy_type_ids?: number[],
            power_from?: number,
            power_to?: number,
            type_id?: ESortType,
            is_descending?: boolean,
            qualification_date_from?: string | null,
            qualification_date_to?: string | null,
        ) => {
            getListGoByOwners({
                addToLink: `?id=${id}`,
                body: {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters: {
                        renewable_energy_type_ids,
                        power_from,
                        power_to,
                        name,
                        qualification_date_from, // "2022-01-01T00:00:00Z", // дата квалификации с; либо null если нет даты
                        qualification_date_to, // "2022-09-08T00:00:00Z", // дата квалификации по; либо null если нет даты
                    },
                    sort: {
                        type_id, // 0 - по Дате добавления ГО в систему; 1 - по мощности ГО; 2 - по наименованию ГО
                        is_descending,
                    },
                },
                errorCodeMessage: true,
            })
        },
        [],
    )

    const loadOwnersDebounced = useCallback(debounce(loadOwners, 300), [])

    useEffect(() => {
        loadOwnersDebounced(
            pagination.page,
            pagination.pageSize,
            userNameFilter,
            renewableEnergyTypeFilter,
            totalPowerFromFilter,
            totalPowerToFilter,
            sortType,
            descending,
            qualificationDate?.[0] || null,
            qualificationDate?.[1] || null,
        )
    }, [
        pagination.page,
        pagination.pageSize,
        renewableEnergyTypeFilter,
        totalPowerFromFilter,
        totalPowerToFilter,
        userNameFilter,
        sortType,
        descending,
        qualificationDate,
    ])

    // чтобы фильтр не вылезал на footer страницы
    const styleFooter =
        responseListGoByOwners?.total < 3
            ? responseListGoByOwners?.total < 1
                ? { paddingBottom: '300px' }
                : { paddingBottom: '130px' }
            : {}

    const openHandler = () => {
        setWaitclose(true)
        setSortOpen(true)
    }

    const clodeBut = async () => {
        if (disableClickOutSide) {
            return
        }
        setSortOpen(false)
        setTimeout(() => setWaitclose(false), 100)
    }

    return (
        <div className={style.objectsListMain} style={styleFooter}>
            <div className={style.headerPrimary}>
                {t('ownerCard.allObjs')} {` ${ownerName}`}
            </div>
            <div className={style.controlsBlock}>
                <div className={style.controlsWrapper}>
                    <ControlsSwitch
                        titleList={[
                            'Дате добавления ГО в систему',
                            'Мощности ГО',
                            'Наименованию ГО',
                        ]}
                        notShow={false}
                        name={userNameFilter}
                        sortType={sortType}
                        desc={descending}
                        onChangeUserName={setUserNameFilter}
                        onChangeSortType={setSortType}
                        onDescendingType={setDescending}
                    />
                    <Popover
                        open={sortOpen}
                        contentStyle={style.contentStyle}
                        wrapStyle={style.wrapStyle}
                        content={
                            <ClickAwayListener onClickOutside={clodeBut}>
                                <Filters
                                    showQualificDate
                                    onClose={clodeBut}
                                    disableOutside={setDisableClickOutSide}
                                    titles={{ power: 'Мощность' }}
                                    names={{
                                        rangePickerTitle: 'Дата квалификации',
                                    }}
                                    values={{
                                        renewableEnergyType: renewableEnergyTypeFilter,
                                        totalPowerFrom: totalPowerFromFilter,
                                        totalPowerTo: totalPowerToFilter,
                                        qualificDate: qualificationDate,
                                    }}
                                    onChange={({
                                        totalPowerFrom,
                                        totalPowerTo,
                                        renewableEnergyType,
                                        qualificDate,
                                    }) => {
                                        setTotalPowerFromFilter(totalPowerFrom)
                                        setTotalPowerToFilter(totalPowerTo)
                                        setRenewableEnergyTypeFilter(renewableEnergyType)
                                        setQualificationDate(qualificDate)
                                    }}
                                />
                            </ClickAwayListener>
                        }
                    >
                        <IconButton
                            id="close_filter"
                            onClick={() => (waitClose ? clodeBut() : openHandler())}
                            // disabled={sortOpen}
                            icon={<Filter />}
                            customIconStyle={{ marginRight: '8px', marginTop: '2px' }}
                        >
                            {t('ownerCard.filters')}
                        </IconButton>
                    </Popover>
                    <InputSearch onChange={(event: any) => setUserNameFilter(event.target.value)} />
                </div>
            </div>
            <div className={style.cardsWrapper}>
                {(responseListGoByOwners?.go_owner_gos || []).map((item: any, index: number) => {
                    const { id } = item

                    return (
                        <div
                            key={id}
                            className={index % 2 ? style.cardBlockMarginLeft : style.cardBlock}
                        >
                            <div className={style.cardMain}>
                                <div>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <Link
                                            to={{
                                                pathname: `/generator-card/${id}`,
                                            }}
                                            className={style.cardHeader}
                                        >
                                            {item.name}
                                        </Link>
                                        <PowerTypeTag
                                            value={renewableEnergyTypesMap.get(
                                                item.renewable_energy_type_id,
                                            )}
                                        >
                                            {renewableEnergyTypesMap.get(
                                                item.renewable_energy_type_id,
                                            )}
                                        </PowerTypeTag>
                                    </div>
                                    <div className={style.infoRow}>
                                        <CardInfoBlock
                                            label={t('generatorCard.power')}
                                            // power всегда приходит в кВт
                                            value={`${currencyFormat(
                                                (item?.power && item?.power / 1000) || '',
                                            )} ${t('generatorCard.scale')}`}
                                            icon={<Flash />}
                                            gap={7}
                                        />
                                        <Divider className={style.divider} type={'vertical'} />
                                        <CardInfoBlock
                                            label={t('Тип ГО')}
                                            value={item.go_type_id === 1 ? 'КГО' : 'ЗГО'}
                                        />
                                        <Divider className={style.divider} type={'vertical'} />
                                        <CardInfoBlock
                                            label={t('Дата квалификации')}
                                            value={
                                                item.qualification_date
                                                    ? moment(item.qualification_date).format(
                                                          'DD.MM.YYYY',
                                                      )
                                                    : '-'
                                            }
                                        />
                                        <Divider className={style.divider} type={'vertical'} />
                                        <CardInfoBlock
                                            label={t('Дата последней проверки')}
                                            value={
                                                item.last_check_date
                                                    ? moment(item.last_check_date).format(
                                                          'DD.MM.YYYY',
                                                      )
                                                    : '-'
                                            }
                                        />
                                    </div>
                                </div>
                                {
                                    <div className={style.locationBlock}>
                                        <CardInfoBlock
                                            value={`Россия, ${regionsMap.get(
                                                item.region_id || '',
                                            )}`}
                                            icon={<Location />}
                                            gap={11}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
            {
                <Card style={{ marginTop: '16px', padding: '16px' }}>
                    <Pagination
                        current={pagination.page}
                        onChange={onChangePage}
                        showSizeChanger={responseListGoByOwners?.total}
                        defaultPageSize={10}
                        pageSizeOptions={[10, 20, 50, 100]}
                        onShowSizeChange={onShowSizeChange}
                        defaultCurrent={1}
                        total={responseListGoByOwners?.total}
                    />
                </Card>
            }
        </div>
    )
}

export default ObjectsList
