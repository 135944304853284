import { FC, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { CenteredSpin, HeaderBlock } from '../ui'
import { useAuditData } from '../hooks'
import useRequest from '../hooks/useRequest'
import { getEventDetails, getEventCustom, getAuditEventTypes } from './config'
import { ContentBlock } from './components'

import style from './style.m.less'

interface IList {
    id: number
    name: string
}

const AuditItem: FC = () => {
    const [actualType, setActualType] = useState<IList>({} as IList)
    const { fetch, result, isLoading } = useRequest(getEventDetails)
    const {
        fetch: fetchCustom,
        result: resultCustom,
        isLoading: isLoadingCustom,
    } = useRequest(getEventCustom)
    /* const {
        fetch: fetchCertCustom,
        result: resultCertCustom,
        isLoading: isLoadingCertCustom,
    } = useRequest(getEventCertCustom) */

    const { fetch: fetchTypes } = useRequest(getAuditEventTypes)

    const { state } = useLocation()
    const { id } = useParams<{ id: string }>()

    const data = useAuditData(id)
    const eventId = data?.eventId
    const eventType = data?.eventType

    const getActualType = async (eventId: number) => {
        if (!state) {
            fetchTypes({
                onSuccess(data) {
                    const obj = data.find((item: IList) => item.id === eventId)
                    setActualType(obj)
                },
            })
        }
    }

    const isCustom = [20, 30, 67].includes(eventId ?? 0)

    const PAGE_SIZE = eventId === 20 ? 5 : 10

    const chooseFetchType = ({ page = 1, pageSize = PAGE_SIZE }) => {
        const body = {
            limit: pageSize,
            offset: (page - 1) * pageSize,
        }
        // Похоже что не используется, если не будет нареканий на потерю функционала удалить 12/03/24
        /* if (!state) {
            return {
                method: fetchCertCustom,
                body: {
                    body,
                    getParams: { id },
                    errorCodeMessage: true,
                },
                isLoad: isLoadingCertCustom,
                res: resultCertCustom,
            }
        } else  */ if (isCustom) {
            return {
                method: fetchCustom,
                body: {
                    body,
                    getParams: { id },
                    errorCodeMessage: true,
                },

                isLoad: isLoadingCustom,
                res: resultCustom,
            }
        } else {
            return {
                method: fetch,
                body: {
                    getParams: {
                        id,
                    },
                    errorCodeMessage: true,
                },
                isLoad: isLoading,
                res: result,
            }
        }
    }

    const conditions = chooseFetchType({})
    const { method, body } = conditions

    const getCustomData = () => method(body)

    useEffect(() => {
        method(body)
    }, [id, eventId])

    useEffect(() => {
        if (conditions?.res?.type_event_id) {
            getActualType(conditions?.res?.type_event_id)
        }
    }, [conditions?.res?.type_event_id])

    if (conditions.isLoad || !conditions.res) {
        return <CenteredSpin />
    }

    return (
        <div className={style.container}>
            <HeaderBlock label={state && eventType ? eventType : actualType.name} />
            <div className={style.content}>
                <ContentBlock
                    eventDetails={conditions.res}
                    eventData={{ eventId: conditions.res.type_event_id }}
                    getCustomData={getCustomData}
                />
            </div>
        </div>
    )
}

export default AuditItem
