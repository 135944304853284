import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { HeaderBlock, DocumentsBlockUI } from '../../../ui'
import { ButtonsBlock } from '..'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const AddDocuments: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const getBackToProfile = () => {
        navigate('/profile')
    }

    return (
        <div className={style.editMain}>
            <HeaderBlock label={t('editMyProfile.addDoc')} />
            <div className={style.editFrame}>
                <DocumentsBlockUI type="profile" />
                <ButtonsBlock margin cancelAction={getBackToProfile} />
            </div>
        </div>
    )
}

export default AddDocuments
