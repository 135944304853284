// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper_hqc8n {\n  margin-top: 32px;\n}\n.tabs_iAD38 {\n  font-weight: 600;\n  color: #595959;\n}\n.cardsWrapper_bsLTE {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 16px;\n  margin-top: 16px;\n}\n.cardBlockMarginLeft_bzSGG {\n  margin-left: 16px;\n}\n.card_HEnAs {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.textAlert_OJWyW {\n  text-align: center;\n  margin-top: 12px;\n  color: #bfbfbf;\n  line-height: 18px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/UserGreenTools/components/RegisteredLayout/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,gBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;EACA,gBAAA;AADJ;AAKI;EACI,iBAAA;AAHR;AAOA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AALJ;AAQA;EACI,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;AANJ","sourcesContent":[".mainWrapper {\n    margin-top: 32px;\n}\n\n.tabs {\n    font-weight: 600;\n    color: #595959;\n}\n\n.cardsWrapper {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 16px;\n    margin-top: 16px;\n}\n\n.cardBlock {\n    &MarginLeft {\n        margin-left: 16px;\n    }\n}\n\n.card {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.textAlert {\n    text-align: center;\n    margin-top: 12px;\n    color: #bfbfbf;\n    line-height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": "mainWrapper_hqc8n",
	"tabs": "tabs_iAD38",
	"cardsWrapper": "cardsWrapper_bsLTE",
	"cardBlockMarginLeft": "cardBlockMarginLeft_bzSGG",
	"card": "card_HEnAs",
	"textAlert": "textAlert_OJWyW"
};
export default ___CSS_LOADER_EXPORT___;
