import request from '../request'
import { IRequest } from '../types'

const errorHandler = (error: any, handler: any) => {
    if (error.response) {
        handler(error.response)
    } else if (error.request) {
        handler(error.request)
    } else {
        handler(error)
    }
}

export const fetchUserInfo = async (isNotActual?: boolean): Promise<IRequest> => {
    try {
        const { data, status } = await request.get(
            `/api/users/v1/profile${isNotActual ? '' : '?need_latest=1'}`,
        )

        if (status !== 200) {
            throw new Error()
        }

        return { data: data, error: '' }
    } catch (error: any) {
        return { error: ('' + error) as string }
    }
}

const getRoleTerms = (userType: number) => {
    return request.get(`/api/users/v1/role-terms?user_type=${userType}`)
}

export const getRoleTermsService = async (userType: number, onError: (error: any) => void) => {
    try {
        const { data, status } = await getRoleTerms(userType)

        if (status !== 200) {
            throw new Error('login error')
        }

        return { data }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

const getSignature = (requestId: string, isBody: boolean) => {
    return request.get(
        `/api/documents/v1/signed-request?request_id=${requestId}&content=${
            isBody ? 'body' : 'signature'
        }`,
    )
}

export const getSignatureService = async (
    requestId: string,
    isBody: boolean,
    onError: (error: any) => void,
) => {
    try {
        const { data, status, headers } = await getSignature(requestId, isBody)

        if (status !== 200) {
            throw new Error('login error')
        }

        return { data, headers }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}
