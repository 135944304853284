// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table_m1Kc5 .ant-table-tbody tr.ant-table-row td {\n  padding: 3px 16px;\n}\n.table_m1Kc5 .ant-table-tbody tr.ant-table-row td:first-child {\n  font-weight: 600;\n}\n.table_m1Kc5 .ant-table-thead > tr > th {\n  padding: 11px 16px;\n  border: 0;\n  font-weight: 600;\n}\n.table_m1Kc5 .ant-table-thead > tr > th::before {\n  display: none;\n}\n.tableName_P1MqH {\n  background-color: #F0F5FF;\n  padding: 6px 0px 6px 16px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n  display: flex;\n  align-items: center;\n  color: #595959;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOSubmitDataKU/components/Tables/style.m.less"],"names":[],"mappings":"AAAA;EAGY,iBAAA;AADZ;AAEY;EACI,gBAAA;AAAhB;AALA;EASY,kBAAA;EACA,SAAA;EACA,gBAAA;AADZ;AAEY;EACI,aAAA;AAAhB;AAMA;EACI,yBAAA;EACA,yBAAA;EACA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAJJ","sourcesContent":[".table {\n    :global {\n        .ant-table-tbody tr.ant-table-row td {\n            padding: 3px 16px;\n            &:first-child {\n                font-weight: 600;\n            }\n        }\n        .ant-table-thead > tr > th {\n            padding: 11px 16px;\n            border: 0;\n            font-weight: 600;\n            &::before {\n                display: none;\n            }\n        }\n    }\n}\n\n.tableName {\n    background-color: #F0F5FF;\n    padding: 6px 0px 6px 16px;\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n    display: flex;\n    align-items: center;\n    color: #595959;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table_m1Kc5",
	"tableName": "tableName_P1MqH"
};
export default ___CSS_LOADER_EXPORT___;
