import { FC, ReactNode } from 'react'
import { Box } from '../../../ui/Box/Box'

import style from '../style.m.less'

type PageTitleProps = {
    title?: string | ReactNode
    classNames?: any
    extra?: ReactNode
}

const PageTitle: FC<PageTitleProps> = ({ title, classNames, extra }) => (
    <Box justify="space-between" direction="row" align="center">
        <h1 className={classNames || style.title}>{title}</h1>
        {extra}
    </Box>
)

export default PageTitle
