import { put, StrictEffect, takeLatest } from 'redux-saga/effects'

import { addNewMessage, getNotifications, updateNotifications } from './index'
import { fetchNotifications } from '../../services/notifications'

function* handleNotifications({ payload }: ReturnType<typeof getNotifications>) {
    try {
        const { offset, limit } = payload

        yield put(updateNotifications({ error: '', isLoading: true }))
        const { data, error } = yield fetchNotifications(offset, limit)

        if (error) {
            throw error
        }

        const { feeds, total } = data

        yield put(updateNotifications({ isLoading: false, feeds, total, needReload: false }))
    } catch (error) {
        yield put(updateNotifications({ error, isLoading: false, data: {}, needReload: false }))
    }
}

function* handleAddNewMessage() {
    try {
        yield put(updateNotifications({ error: '', isLoading: true, needReload: true }))
    } catch (error) {
        yield put(updateNotifications({ error, isLoading: false, data: {} }))
    }
}

export function* watchNotifications(): Generator<StrictEffect> {
    yield takeLatest(getNotifications, handleNotifications)
    yield takeLatest(addNewMessage, handleAddNewMessage)
}
