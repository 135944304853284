import { FC } from 'react'
import { DropdownSelector } from '../ui'
import { Switch, Input, Divider, Checkbox } from 'antd'
import { ButtonsBlock } from '../Edit/components'
import style from './style.m.less'

const values = [
    { value: '345 656 кВт*ч', id: 'w371c913-a16a-4bb8-93a6-081a89cc036a' },
    { value: '346 666 кВт*ч', id: 'zk7a2c6a-2840-470c-922a-289fca5017ad' },
]

const GOSettings: FC = () => {
    return (
        <div className={style.settingsMain}>
            <div className={style.settingsHeader}>Настройки</div>
            <div className={style.settingsFrame}>
                <div className={style.subheader}>Автоматический выпуск зеленых инструментов</div>
                <div className={style.settingRow}>
                    <div className={style.settingHeader}>Солнечная электростанция «Абаканская»</div>
                    <Switch size={'small'} checked className={style.switch} />
                </div>
                <div className={style.settingBlock}>
                    <div>Тип инструмента</div>
                    <Input
                        className={style.input}
                        disabled={true}
                        placeholder="Зеленый сертификат"
                    />
                </div>
                <div className={style.settingBlock}>
                    <div>Объем выпуска</div>
                    <DropdownSelector customStyle={{ width: '400px' }} options={values} />
                </div>
                <div className={style.settingBlock}>
                    <div>Количество ЗИ</div>
                    <Input className={style.input} />
                </div>
                <Divider />
                <div className={style.settingRow}>
                    <div className={style.settingHeader}>
                        Ветроэлектрическая станция в Ульяновской Области/SVIE0478
                    </div>
                    <Switch size={'small'} checked className={style.switch} />
                </div>
                <div className={style.settingBlock}>
                    <div>Тип инструмента</div>
                    <Input
                        className={style.input}
                        disabled={true}
                        placeholder="Зеленый сертификат"
                    />
                </div>
                <div className={style.settingBlock}>
                    <div>Объем выпуска</div>
                    <DropdownSelector customStyle={{ width: '400px' }} options={values} />
                </div>
                <Divider />
                <div className={style.settingRowNoMargin}>
                    <div className={style.settingHeader}>Новоузенская солнечная электростанция</div>
                    <Switch size={'small'} className={style.switch} />
                </div>
                <Divider />
                <div className={style.settingRowNoMargin}>
                    <div className={style.settingHeader}>Оренбургская ГЭС</div>
                    <Switch size={'small'} className={style.switch} />
                </div>
                <Divider />
                <div className={style.settingRowNoMargin}>
                    <div className={style.settingHeader}>Ижевская солнечная электростанция</div>
                    <Switch size={'small'} className={style.switch} />
                </div>
                <Divider />
                <div className={style.settingRowNoMargin}>
                    <div className={style.settingHeader}>Солнечная электростанция №123</div>
                    <Switch size={'small'} className={style.switch} />
                </div>
            </div>
            <div className={style.settingsFrame}>
                <div className={style.subheader}>Конфиденциальность</div>
                <div className={style.settingRowCheckboxFirst}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать мои сертификаты происхождения в реестре
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать мои зеленые договоры в реестре
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать мои другие зеленые инструменты в реестре
                    </div>
                </div>
                <Divider />
                <div className={style.settingRowNoMargin}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать сертификаты происхождения, в которых я являюсь потребителем
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox checked />
                    <div className={style.checkboxText}>
                        Не показывать зеленые договоры, в которых я являюсь потребителем
                    </div>
                </div>
                <div className={style.settingRowCheckbox}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Не показывать другие зеленые инструменты, в которых я являюсь потребителем
                    </div>
                </div>
            </div>
            <div className={style.settingsFrame}>
                <div className={style.subheader}>Комиссии</div>
                <div className={style.settingRowCheckboxFirst}>
                    <Checkbox />
                    <div className={style.checkboxText}>
                        Автоматическое списание комиссии за обслуживание лицевых счетов
                    </div>
                </div>
            </div>
            <ButtonsBlock margin />
        </div>
    )
}

export default GOSettings
