// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_I6hA3 {\n  width: 500px !important;\n}\n.modal_I6hA3 .ant-modal-content {\n  width: 100%;\n}\n.modalHeader_Tk48A {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  margin-bottom: 24px;\n}\n.buttonsBlock_gz0hb {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.textArea_cOkIg {\n  margin-bottom: 24px;\n  border-radius: 12px;\n  margin-top: 3px;\n}\n.activationAlert_idJng {\n  border-radius: 8px;\n  width: 100%;\n}\n.star_hfxhA {\n  margin-left: 4px;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/QuestionnaireApprovalItem/components/SendForRevisionModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AAFA;EAIQ,WAAA;AACR;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAIA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,mBAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAMA;EACI,kBAAA;EACA,WAAA;AAJJ;AAOA;EACI,gBAAA;EACA,cAAA;AALJ","sourcesContent":[".modal {\n    width: 500px !important;\n\n    :global(.ant-modal-content) {\n        width: 100%;\n    }\n}\n\n.modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 24px;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 32px;\n}\n\n.textArea {\n    margin-bottom: 24px;\n    border-radius: 12px;\n    margin-top: 3px;\n}\n\n.activationAlert {\n    border-radius: 8px;\n    width: 100%;\n}\n\n.star {\n    margin-left: 4px;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_I6hA3",
	"modalHeader": "modalHeader_Tk48A",
	"buttonsBlock": "buttonsBlock_gz0hb",
	"textArea": "textArea_cOkIg",
	"activationAlert": "activationAlert_idJng",
	"star": "star_hfxhA"
};
export default ___CSS_LOADER_EXPORT___;
