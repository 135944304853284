import { RequestTypes } from '../hooks/useRequest'

export const FUEL_API_PREFIX = '/api/fuel/v1/'
export const FUEL_REGISTRY = 'applications'
export const FUEL_GOS = 'applications-gos'
export const FUEL_DETAILS = 'application?id='
export const FUEL_GENERATORS = 'gos'

export const getFuelRegistry = {
    link: `${FUEL_API_PREFIX}${FUEL_REGISTRY}`,
    method: RequestTypes.post,
}

export const getGosDict = {
    link: `${FUEL_API_PREFIX}${FUEL_GOS}`,
    method: RequestTypes.post,
}

export const getFuelDetails = {
    link: `${FUEL_API_PREFIX}${FUEL_DETAILS}`,
    method: RequestTypes.get,
}

export const getGenerators = {
    link: `${FUEL_API_PREFIX}${FUEL_GENERATORS}`,
    method: RequestTypes.get,
}
