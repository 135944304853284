import { ConfigProvider, Table } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ruRU from 'antd/lib/locale/ru_RU'
import Filter from '../Filter/Filter'

import style from './style.m.less'

// import { openedDataSource } from './data'
import { openedColumns } from './columns'
import MoreFilters from '../MoreFilters/MoreFilters'
import { IData } from './data'
import {
    actionTypeMap,
    EActionType,
    EStatus,
    EUserRole,
    userRoleMap,
} from '../../../../constants/approval'
import request from '../../../../services/request'
import moment from 'moment'
import debounce from 'lodash/debounce'

interface ILoadProfiles {
    pagination: number
    dateFilter: [moment.Moment, moment.Moment] | null
    userNameFilter: string
    actionTypeFilter: EActionType
    userTypeFilter: EUserRole
    innFilter: string
    ogrnFilter: string
    statusTypeFilter: EStatus
}

interface IOpened {
    res: any
    profiles: any
}

const PAGE_SIZE = 10

const Opened: FC<IOpened> = () => {
    const navigate = useNavigate()

    const [profilesLoading, setProfilesLoading] = useState(false)
    const [profiles, setProfiles] = useState([])
    const [pagination, setPagination] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [total, setTotal] = useState(0)
    const [actionTypeFilter, setActionTypeFilter] = useState(EActionType.All)
    const [userTypeFilter, setUserTypeFilter] = useState(EUserRole.All)
    const [statusTypeFilter, setStatusTypeFilter] = useState<EStatus>(EStatus.All)
    const [userNameFilter, setUserNameFilter] = useState('')
    const [innFilter, setInnFilter] = useState('')
    const [ogrnFilter, setOgrnFilter] = useState('')
    const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment] | null>(null)

    const loadProfiles = useCallback(
        async ({
            pagination,
            dateFilter,
            userNameFilter,
            actionTypeFilter,
            userTypeFilter,
            innFilter,
            ogrnFilter,
            statusTypeFilter,
        }: ILoadProfiles) => {
            try {
                setProfilesLoading(true)
                const res = await request.post('/api/users/v1/profiles-admin', {
                    limit: PAGE_SIZE,
                    offset: (pagination - 1) * PAGE_SIZE,
                    filters: {
                        ts_from: dateFilter ? moment(dateFilter[0]).startOf('day') : undefined,
                        ts_to: dateFilter ? moment(dateFilter[1]).endOf('day') : undefined,
                        name: userNameFilter,
                        action_type: actionTypeFilter,
                        user_role: userTypeFilter,
                        inn: innFilter,
                        ogrn: ogrnFilter,
                        profile_status: statusTypeFilter,
                    },
                })
                setProfiles(res.data.profiles)
                setTotal(res.data.total)
            } finally {
                setProfilesLoading(false)
            }
        },
        [],
    )

    const loadProfilesDebounced = useCallback(debounce(loadProfiles, 300), [])

    useEffect(() => {
        loadProfilesDebounced({
            pagination,
            actionTypeFilter,
            userTypeFilter,
            userNameFilter,
            innFilter,
            dateFilter,
            statusTypeFilter,
            ogrnFilter,
        })
    }, [
        pagination,
        actionTypeFilter,
        userTypeFilter,
        userNameFilter,
        innFilter,
        dateFilter,
        statusTypeFilter,
        ogrnFilter,
    ])

    const getDataSource = (): IData[] =>
        profiles?.map(
            (item: {
                user_type: number
                profile_id_previous: number
                uid: string
                company_inn: string
                company_name_short: string
                user_role: any
                user_role_previous: any
                action_type: EActionType
                profile_id: any
                ts_created: any
                full_name: any
                personal_inn: any
                profile_status: EStatus
            }) => {
                const user =
                    item.user_type === 0
                        ? item.full_name
                        : item.user_type === 1
                        ? `ИП ${item.full_name}`
                        : item.company_name_short
                const inn =
                    item.user_type === 0 || item.user_type === 1
                        ? item.personal_inn
                        : item.company_inn
                const userRole =
                    item.user_role === item.user_role_previous
                        ? userRoleMap.get(item.user_role)!
                        : `${userRoleMap.get(item.user_role_previous)} (новая: ${userRoleMap.get(
                              item.user_role,
                          )})`
                return {
                    key: item.profile_id,
                    date: item.ts_created,
                    user,
                    action: actionTypeMap.get(item.action_type)!,
                    userRole: userRole,
                    inn,
                    ogrn: item.ogrn,
                    status: item.profile_status,
                    isNew:
                        item.profile_status === EStatus.New ||
                        item.profile_status === EStatus.Corrected,
                    uid: item.uid,
                    prevId: item.profile_id_previous,
                }
            },
        )

    const openedDataSource = getDataSource()

    return (
        <div className={style.opened}>
            <Filter
                onChangeActionType={setActionTypeFilter}
                onChangeUserType={setUserTypeFilter}
                onChangeUserName={setUserNameFilter}
                onChangeInn={setInnFilter}
                onChangeDate={setDateFilter}
            />
            <MoreFilters onChangeStatusType={setStatusTypeFilter} onChangeOgrn={setOgrnFilter} />
            <div className={style.table}>
                <ConfigProvider locale={ruRU}>
                    <Table
                        dataSource={openedDataSource}
                        columns={openedColumns}
                        pagination={{
                            position: ['bottomLeft'],
                            onChange: (page, pageSize) => {
                                setPagination(page)
                                setPageSize(pageSize)
                            },
                            total: total,
                            pageSize: pageSize,
                            showSizeChanger: true,
                        }}
                        onRow={(record) => ({
                            onClick: () => {
                                navigate(
                                    `/questionnaire-approval/${record.uid}/${record.key}/${record.prevId}`,
                                )
                            },
                        })}
                        loading={profilesLoading}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default Opened
