import { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CenteredSpin, IconButton, DeleteModal } from '../../../ui'
import {
    ActivateGeneratorModal,
    DeregisterModal,
    CancelDeregisterModal,
    ReturnRegisterModal,
    AddRemoveLockModal,
    SearchGOOwner,
    ConfirmConnectionModal,
    PaymentModal,
} from '../'
import {
    Flash,
    CloseCircle,
    Stamp,
    Verify,
    Key,
    BlueLock,
    User,
    Edit,
    Trash,
    AddCircle,
    EmptyWallet,
} from '../../../../assets/icons'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'
import FileSaver from 'file-saver'
import { getSignatureService } from '../../../../services/user'
import { useAppSelector } from '../../../../store/hooks'
import { EUserRole, GeneratorStatus } from '../../../../constants/approval'
import { AddRemoveLockModalVisible } from '../AddRemoveLockModal/AddRemoveLockModal'
import { IGeneratingObject } from '../../../../store/generators/IGeneratingObject'
import request from '../../../../services/request'

interface IControlsBlockProps {
    id?: string
    requestId?: string
    goName?: string
    showDeregister?: boolean
    showCancelDeregister?: boolean
    showReturnRegister?: boolean
    deregisterDate?: string
    blocks?: {
        ga_fixation: boolean
        gt_emission: boolean
        ga_transfer: boolean
        ga_redemption: boolean
    }
    status?: GeneratorStatus
    tsoNotConfirmed?: boolean
    gpNotConfirmed?: boolean
    generator?: IGeneratingObject
    onReload: () => void
    showDelete?: boolean
    idDeleted?: boolean
    isActive?: boolean
    isCanceled?: boolean
    isUnderConsideration?: boolean
}

const ControlsBlock: FC<IControlsBlockProps> = ({
    id,
    requestId,
    showDeregister,
    showCancelDeregister,
    showReturnRegister,
    deregisterDate,
    goName,
    blocks,
    status,
    tsoNotConfirmed,
    gpNotConfirmed,
    generator,
    onReload,
    showDelete,
    isActive,
    isCanceled,
    isUnderConsideration,
    idDeleted,
}) => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const isUserLoading = useAppSelector((state) => state.user.isLoading)
    const waitingForPayment = status === GeneratorStatus.WaitForPayment

    const userData = useAppSelector((state) => state.user.data)
    const userRole = userData.user_role
    const canDeregister =
        userRole === EUserRole.GOOwner ||
        userRole === EUserRole.Administrator ||
        userRole === EUserRole.SuperAdmin
    const canCancelDeregister =
        userRole === EUserRole.GOOwner ||
        userRole === EUserRole.Operator ||
        userRole === EUserRole.Administrator ||
        userRole === EUserRole.SuperAdmin
    const canReturnRegister =
        userRole === EUserRole.Operator ||
        userRole === EUserRole.Administrator ||
        userRole === EUserRole.SuperAdmin
    const canBlock = userRole === EUserRole.Administrator || userRole === EUserRole.SuperAdmin
    const canEdit =
        userRole === EUserRole.Operator ||
        userRole === EUserRole.Administrator ||
        userRole === EUserRole.SuperAdmin
    const canActivate =
        userRole === EUserRole.GOOwner && !waitingForPayment && status === GeneratorStatus.Approved

    const showBlock =
        blocks &&
        Object.values(blocks).filter((block) => block).length < 4 &&
        isActive &&
        status === GeneratorStatus.Approved
    const showUnblock =
        blocks &&
        Object.values(blocks).filter((block) => block).length > 0 &&
        isActive &&
        status === GeneratorStatus.Approved

    const canChangeGOOwner =
        (userRole === EUserRole.Operator ||
            userRole === EUserRole.Administrator ||
            userRole === EUserRole.SuperAdmin) &&
        blocks &&
        Object.values(blocks).filter((block) => block).length === 0 &&
        status === GeneratorStatus.Approved

    const canDownloadSign =
        (userRole === EUserRole.GOOwner ||
            userRole === EUserRole.Operator ||
            userRole === EUserRole.Administrator ||
            userRole === EUserRole.SuperAdmin) &&
        !!requestId

    const isTSOGPUser = userRole === EUserRole.TSO

    const isActivationAvailable =
        !isActive && !isCanceled && !isUnderConsideration && !showReturnRegister && canActivate

    const [isActivateModalVisible, setIsActivateModalVisible] = useState(false)
    const [isDeregisterModalVisible, setIsDeregisterModalVisible] = useState(false)
    const [isCancelDeregisterModalVisible, setIsCancelDeregisterModalVisible] = useState(false)
    const [isReturnRegisterModalVisible, setIsReturnRegisterModalVisible] = useState(false)
    const [isAddRemoveLockModalVisible, setIsAddRemoveLockModalVisible] =
        useState<AddRemoveLockModalVisible>(AddRemoveLockModalVisible.None)
    const [isSearchGOOwnerModalVisible, setIsSearchGOOwnerModalVisible] = useState(false)
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)

    const openSearchGOOwnerModal = () => {
        setIsSearchGOOwnerModalVisible(true)
    }

    const openDeregisterModal = () => {
        setIsDeregisterModalVisible(true)
    }

    const openCancelDeregisterModal = () => {
        setIsCancelDeregisterModalVisible(true)
    }

    const openReturnRegisterModal = () => {
        setIsReturnRegisterModalVisible(true)
    }

    const openAddLockModal = () => {
        setIsAddRemoveLockModalVisible(AddRemoveLockModalVisible.Add)
    }

    const openRemovingLockModal = () => {
        setIsAddRemoveLockModalVisible(AddRemoveLockModalVisible.Remove)
    }

    const [isConfirmConnectionModalVisible, setIsConfirmConnectionModalVisible] = useState(false)
    const openConfirmConnectionModal = () => {
        setIsConfirmConnectionModalVisible(true)
    }

    const [loading, setLoading] = useState(false)
    const handleClickDownload = async () => {
        try {
            if (!requestId) {
                return
            }

            setLoading(true)

            const [body, signature] = await Promise.all([
                getSignatureService(requestId, true, (error) => {
                    console.log(error)
                }),
                getSignatureService(requestId, false, (error) => {
                    console.log(error)
                }),
            ])

            const bodyBlob = new Blob([body?.data], { type: 'application/octet-stream' })
            const signatureBlob = new Blob([signature?.data], {
                type: 'application/octet-stream',
            })

            FileSaver.saveAs(bodyBlob, body?.headers.filename)
            FileSaver.saveAs(signatureBlob, signature?.headers.filename)
        } finally {
            setLoading(false)
        }
    }

    // Удалить
    // перенес сюда из ControlsBlockFiled
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

    const handleDelete = async () => {
        try {
            setLoading(true)
            await request.delete(`/api/generators/v1/go?generator_id=${id}`)
            navigate(-1)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={style.controlsWrapper}>
            {loading && isUserLoading && <CenteredSpin />}
            {isTSOGPUser && (tsoNotConfirmed || gpNotConfirmed) && (
                <IconButton onClick={openConfirmConnectionModal}>Подтвердить связь</IconButton>
            )}
            {canEdit && (
                <Link to={`/edit-go/${id}`} state={{ type: 'generator' }}>
                    <IconButton
                        customStyle={{ marginRight: '16px' }}
                        customIconStyle={{ marginTop: '4px', marginRight: '10px' }}
                        icon={<Edit />}
                    >
                        {t('detailedCard.edit')}
                    </IconButton>
                </Link>
            )}

            {idDeleted && (
                <IconButton
                    onClick={() =>
                        navigate(`/adding-go`, { state: { createFrom: true, generator } })
                    }
                    customStyle={{ marginRight: '16px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<AddCircle />}
                >
                    Создать на основе
                </IconButton>
            )}
            {showDelete && (
                <IconButton
                    customStyle={{ marginRight: '16px' }}
                    icon={<Trash />}
                    onClick={() => setIsDeleteModalVisible(true)}
                >
                    Удалить
                </IconButton>
            )}
            <DeleteModal
                headerText="Удалить заявку"
                messageText={`Вы действительно хотите безвозвратно удалить несогласованную заявку по добавлению ГО ${name}?`}
                isModalVisible={isDeleteModalVisible}
                onCancel={() => setIsDeleteModalVisible(false)}
                onDelete={handleDelete}
            />

            {isActivationAvailable && (
                <Link to={`/go-activation/${id}`}>
                    <IconButton
                        customStyle={{ marginRight: '16px' }}
                        customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                        icon={<Flash size={20} />}
                    >
                        {t('detailedCard.activate2')}
                    </IconButton>
                </Link>
            )}
            {waitingForPayment && (
                <IconButton
                    customStyle={{ marginRight: '16px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<EmptyWallet />}
                    onClick={() => setIsPaymentModalVisible(true)}
                >
                    {'Оплатить'}
                </IconButton>
            )}
            {canDeregister && showDeregister && isActive && (
                <IconButton
                    onClick={openDeregisterModal}
                    customStyle={{ marginRight: '16px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<CloseCircle size={20} />}
                >
                    {t('detailedCard.deregister')}
                </IconButton>
            )}
            {canCancelDeregister && showCancelDeregister && (
                <IconButton
                    onClick={openCancelDeregisterModal}
                    customStyle={{ marginRight: '16px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<CloseCircle size={20} />}
                >
                    {t('detailedCard.cancelDeregister')}
                </IconButton>
            )}
            {canReturnRegister && showReturnRegister && (
                <IconButton
                    onClick={openReturnRegisterModal}
                    customStyle={{ marginRight: '16px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<Verify />}
                >
                    {t('detailedCard.returnRegister')}
                </IconButton>
            )}
            {canBlock && showBlock && (
                <IconButton
                    onClick={openAddLockModal}
                    customStyle={{ marginRight: '16px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={
                        <div className={style.lockIcon}>
                            <BlueLock />
                        </div>
                    }
                >
                    Добавить блокировку
                </IconButton>
            )}
            {canBlock && showUnblock && (
                <IconButton
                    onClick={openRemovingLockModal}
                    customStyle={{ marginRight: '16px' }}
                    customIconStyle={{ marginTop: '3px', marginRight: '10px' }}
                    icon={<Key />}
                >
                    Снять блокировку
                </IconButton>
            )}
            {canChangeGOOwner && (
                <IconButton
                    customStyle={{ marginRight: '16px' }}
                    onClick={openSearchGOOwnerModal}
                    icon={<User />}
                >
                    {t('certificate.changeOwner')}
                </IconButton>
            )}
            {canDownloadSign && isActive && (
                <IconButton
                    customStyle={{ marginRight: '16px' }}
                    icon={<Stamp />}
                    onClick={handleClickDownload}
                >
                    Скачать запрос и подпись
                </IconButton>
            )}
            <ActivateGeneratorModal
                isModalVisible={isActivateModalVisible}
                setIsModalVisible={setIsActivateModalVisible}
            />
            <DeregisterModal
                isModalVisible={isDeregisterModalVisible}
                goName={goName}
                onClose={() => setIsDeregisterModalVisible(false)}
                onReload={onReload}
            />
            <CancelDeregisterModal
                isModalVisible={isCancelDeregisterModalVisible}
                goName={goName}
                deregisterDate={deregisterDate}
                onClose={() => setIsCancelDeregisterModalVisible(false)}
                onReload={onReload}
            />
            <ReturnRegisterModal
                isModalVisible={isReturnRegisterModalVisible}
                goName={goName}
                onClose={() => setIsReturnRegisterModalVisible(false)}
                onReload={onReload}
            />
            <PaymentModal
                id={id}
                goName={goName}
                isModalVisible={isPaymentModalVisible}
                onCancel={() => setIsPaymentModalVisible(false)}
            />
            {blocks && isAddRemoveLockModalVisible !== AddRemoveLockModalVisible.None && (
                <AddRemoveLockModal
                    isModalVisible={isAddRemoveLockModalVisible}
                    onClose={() => setIsAddRemoveLockModalVisible(AddRemoveLockModalVisible.None)}
                    blocks={blocks}
                    onReload={onReload}
                />
            )}
            {id && isSearchGOOwnerModalVisible && (
                <SearchGOOwner
                    isModalVisible={isSearchGOOwnerModalVisible}
                    onClose={() => setIsSearchGOOwnerModalVisible(false)}
                    onReload={onReload}
                    id={id}
                />
            )}
            {generator && (
                <ConfirmConnectionModal
                    isModalVisible={isConfirmConnectionModalVisible}
                    generator={generator}
                    onClose={() => setIsConfirmConnectionModalVisible(false)}
                    onCloseAndReload={() => {
                        onReload()
                        setIsConfirmConnectionModalVisible(false)
                    }}
                />
            )}
        </div>
    )
}

export default ControlsBlock
