// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description_XVK_Z {\n  font-size: 10px;\n  line-height: 16px;\n  color: #8c8c8c;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/DropdownSelector2/style.m.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".description {\n    font-size: 10px;\n    line-height: 16px;\n    color: #8c8c8c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "description_XVK_Z"
};
export default ___CSS_LOADER_EXPORT___;
