// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoBlockTitle_YlCqk {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.infoBlockTitleWrapper_NeGKp {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n  margin-bottom: 24px;\n}\n.infoBlockItem_coIVH {\n  margin-bottom: 16px;\n}\n.infoBlockItemWrapper_VlMIO {\n  display: flex;\n  gap: 24px;\n  margin-bottom: 16px;\n}\n.infoBlockItemName_YC8OQ {\n  width: 220px;\n  color: #8C8C8C;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ZDApprovalItem/components/InfoBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAGA;EACI,mBAAA;AADJ;AAIA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AAFJ;AAKA;EACI,YAAA;EACA,cAAA;AAHJ","sourcesContent":[".infoBlockTitle {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.infoBlockTitleWrapper {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n    margin-bottom: 24px;\n}\n\n.infoBlockItem {\n    margin-bottom: 16px;\n}\n\n.infoBlockItemWrapper {\n    display: flex;\n    gap: 24px;\n    margin-bottom: 16px;\n}\n\n.infoBlockItemName {\n    width: 220px;\n    color: #8C8C8C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlockTitle": "infoBlockTitle_YlCqk",
	"infoBlockTitleWrapper": "infoBlockTitleWrapper_NeGKp",
	"infoBlockItem": "infoBlockItem_coIVH",
	"infoBlockItemWrapper": "infoBlockItemWrapper_VlMIO",
	"infoBlockItemName": "infoBlockItemName_YC8OQ"
};
export default ___CSS_LOADER_EXPORT___;
