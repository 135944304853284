import { FC } from 'react'

import { AGConsumer } from '../../types'

import style from './style.m.less'

interface ConsumersTableProps {
    consumers?: AGConsumer[]
    title?: string
}

export const ConsumersTable: FC<ConsumersTableProps> = ({ consumers, title }) => {
    return (
        <div className={style.wrapper}>
            <div className={style.table}>
                <div className={style.header}>
                    {title
                        ? `${title} (${consumers?.length})`
                        : `Потребители АГ по ЗД (${consumers?.length})`}
                </div>
                <div className={style.row}>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Потребитель
                    </div>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Объем, кВт*ч
                    </div>
                </div>
                {consumers?.map(({ user_id, user_name, volume, user_volume }, index) => (
                    <div key={user_id ? user_id : index} className={style.row}>
                        <div className={style.column}>{user_name}</div>
                        <div className={style.column}>{user_volume ? user_volume : volume}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
