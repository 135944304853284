import request from '../request'
import { btoa } from 'js-base64'
import { hexToBinary } from '../../components/helpers'
import SignUtils from '../../utils/sign/SignUtils'
import { IRequest } from '../types'

export interface ILoginServiceParams {
    data: any
    remember?: boolean
}

export interface ICertificateLoginServiceParams {
    thumbprint: string
    goToProfile: () => void
}
export interface ISignupParams {
    login: string
    password: string
    phone: string
    company: string
    fullName: string
}

const errorHandler = (error: any, handler: any) => {
    if (error.response) {
        handler(error.response)
    } else if (error.request) {
        handler(error.request)
    } else {
        handler(error)
    }
}

const tryRegister = (login: string, email: string) => {
    return request.post('api/auth/v1/login-check', {
        login,
        email,
    })
}

const signup = (userInfo: any) => {
    return request.post('/api/users/v1/signup', userInfo)
}

const profileSignup = (userInfo: any, uuid: string, sign: any) => {
    return request.post(`/api/users/v1/profile?request_id=${uuid}`, userInfo, {
        headers: {
            Signature: sign,
        },
    })
}

const editProfile = (userInfo: any) => {
    return request.post('/api/users/v1/profile-short', userInfo)
}

const getCompany = (company: string, isShort = false) => {
    return request.get(`/api/users/v1/company?name=${company}${isShort ? '&need_short=1' : ''}`)
}

const setPassword = (password: string, confirm_token: string) => {
    return request.post('/api/auth/v1/password', {
        confirm_token,
        password,
    })
}

const changePassword = (oldPassword: string, newPassword: string) => {
    return request.post('/api/auth/v1/change-password', {
        old_password: oldPassword,
        new_password: newPassword,
    })
}

const passwordRecovery = (login: string, email: string) => {
    return request.post('/api/auth/v1/password-recovery', {
        login,
        email,
    })
}

const checkToken = (confirmToken: string, type: 'email' | 'password') => {
    return request.get(
        `/api/auth/v1/confirm-token-check?confirm_token=${confirmToken}&type=${type}`,
    )
}

const changeEmail = (email: string, userId?: string) => {
    const body = userId
        ? {
              uid: userId,
              email_new: email,
          }
        : {
              email,
          }

    return request.post(`/api/auth/v1/email-${userId ? 'change' : 'update'}`, body)
}

const confirmEmail = (confirmToken: string) => {
    return request.get(`/api/auth/v1/email-update?confirm_token=${confirmToken}`)
}

const cancelProfile = () => {
    return request.get('/api/users/v1/profile-cancel')
}

export const registrationService = async (
    login: string,
    email: string,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await tryRegister(login, email)
        if (response.status === 200) {
            onFinish && onFinish()
        } else {
            throw new Error()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const getCompanyService = async (
    company: string,
    onError: (error: any) => void,
    isShort: boolean,
) => {
    try {
        const response = await getCompany(company, isShort)
        if (response.status === 200) {
            return response.data
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const signupService = async (
    userInfo: any,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await signup(userInfo)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const profileSignupService = async (
    userInfo: any,
    uuid: any,
    sign: string,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await profileSignup(userInfo, uuid, sign)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const editProfileService = async (
    userInfo: any,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await editProfile(userInfo)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const setPasswordService = async (
    password: string,
    confirm_token: string,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await setPassword(password, confirm_token)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const checkTokenService = async (
    confirmToken: string,
    type: 'email' | 'password',
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await checkToken(confirmToken, type)
        if (response.status === 200) {
            onFinish && onFinish()
        } else {
            throw new Error()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const changeEmailService = async (
    email: string,
    onFinish: () => void,
    onError: (error: any) => void,
    userId?: string,
) => {
    try {
        const response = await changeEmail(email, userId)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const confirmEmailService = async (
    confirmToken: string,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await confirmEmail(confirmToken)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const changePasswordService = async (
    oldPassword: string,
    newPassword: string,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await changePassword(oldPassword, newPassword)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const passwordRecoveryService = async (
    login: string,
    email: string,
    onFinish: () => void,
    onError: (error: any) => void,
) => {
    try {
        const response = await passwordRecovery(login, email)
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const cancelProfileService = async (onFinish: () => void, onError: (error: any) => void) => {
    try {
        const response = await cancelProfile()
        if (response.status === 200) {
            onFinish && onFinish()
        }
    } catch (error: any) {
        errorHandler(error, onError)
    }
}

export const certificateLoginService = async (
    params: ICertificateLoginServiceParams,
): Promise<any> => {
    try {
        localStorage.removeItem('challenge')

        const { thumbprint } = params
        const myCertificate = await SignUtils.exportCertificate(thumbprint)
        const headResponse = await request.get('/api/auth/v1/sign-challenge')
        const signature = await SignUtils.SignCreate(thumbprint, headResponse.data.challenge)

        const { data, status } = await request.post('/api/auth/v1/sign-challenge', {
            challenge: headResponse.data.challenge,
            signature: btoa(hexToBinary(signature)),
            certificate: myCertificate,
        })

        if (status !== 200) {
            throw new Error()
        }
        return { data }
    } catch (error: any) {
        return { error }
    }
}

export const logoutService = async (): Promise<IRequest> => {
    try {
        const refreshToken = localStorage.getItem('refreshToken')
            ? localStorage.getItem('refreshToken')
            : sessionStorage.getItem('refreshToken')
        const { data, status } = await request.post('/api/auth/v1/logout', {
            refresh_token: refreshToken,
        })
        if (status !== 200) {
            throw new Error()
        }
        return { data }
    } catch (error: any) {
        return { error }
    }
}
