import { RequestTypes } from '../../components/hooks/useRequest'
import request from '../request'
import { IRequestGeneric } from '../types'

export interface IRegions {
    id: number
    name: string
}

export const getRegions = async (): Promise<IRequestGeneric<IRegions[]>> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/regions`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IRenewableEnergyTypes {
    id: number
    full_name: string
    short_name: string
    full_gen_code: string
}

export const getRenewableEnergyTypes = async (): Promise<
    IRequestGeneric<IRenewableEnergyTypes[]>
> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/renewable-energy-types`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IQualificationCategories {
    id: number
    name: string
    description: string
}

export const getQualificationCategories = async (): Promise<
    IRequestGeneric<IQualificationCategories[]>
> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/qualification-categories`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface ICategoriesGo {
    id: number
    full_name: string
    short_name: string
    is_for_qualified: boolean
}

export const getCategoriesGo = async (): Promise<IRequestGeneric<ICategoriesGo[]>> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/categories-go`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IProductionModes {
    id: number
    name: string
}

export const getProductionModes = async (): Promise<IRequestGeneric<IProductionModes[]>> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/production-modes`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IOwnerships {
    id: number
    name: string
}

export const getOwnerships = async (): Promise<IRequestGeneric<IOwnerships[]>> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/ownerships`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IAids {
    id: number
    name: string
}

export const getAids = async (): Promise<IRequestGeneric<IAids[]>> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/aids`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IFuel {
    id: number
    name: string
    short_name: string
    is_for_renewable_energy: boolean
    thermal_conductivity: number
    unit: string
}

export const getFuel = async (): Promise<IRequestGeneric<IFuel[]>> => {
    try {
        const { data, status } = await request.get(`/api/dictionaries/v1/fuel`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IEventType {
    id: number
    name: string
}

export interface IPaymentTypes {
    id: number
    name: string
}

export const getPaymentTypes = async (): Promise<IRequestGeneric<IPaymentTypes[]>> => {
    try {
        const { data, status } = await request.get(`/api/audit/v1/dict/event-payment-types`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export interface IPersAccTypes {
    id: number
    name: string
}

export const getPersAccTypes = async (): Promise<IRequestGeneric<IPersAccTypes[]>> => {
    try {
        const { data, status } = await request.get(`/api/audit/v1/dict/event-personal-acc-types`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export const getCertTypes = async (): Promise<IRequestGeneric<IPersAccTypes[]>> => {
    try {
        const { data, status } = await request.get(`/api/audit/v1/dict/event-cert-types`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export const getContractTypes = async (): Promise<IRequestGeneric<IPersAccTypes[]>> => {
    try {
        const { data, status } = await request.get(`/api/audit/v1/dict/event-contract-types`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export const getOtherTypes = async (): Promise<IRequestGeneric<IPersAccTypes[]>> => {
    try {
        const { data, status } = await request.get(`/api/audit/v1/dict/event-ext-instrs-types`)

        if (status !== 200) {
            throw new Error('fetch error')
        }

        return { data: data, error: '' }
    } catch (error) {
        return { error: ('' + error) as string }
    }
}

export const AllOperationsDictConfig = {
    link: '/api/audit/v1/dict/event-payment-types',
    method: RequestTypes.get,
}
