// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".titleWrapper_IWdfa {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 32px;\n}\n.title_rKCpo {\n  font-family: 'Raleway';\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  font-feature-settings: \"lnum\";\n  color: #262626;\n  margin-bottom: 0;\n}\n.backLink_G_D7a {\n  display: flex;\n  cursor: pointer;\n  color: #262626;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/BackLinkArrowButton/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AACJ;AAEA;EACI,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAGA;EACI,aAAA;EACA,eAAA;EACA,cAAA;AADJ","sourcesContent":[".titleWrapper {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    margin-bottom: 32px;\n}\n\n.title {\n    font-family: 'Raleway';\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    font-feature-settings: \"lnum\";\n    color: #262626;\n    margin-bottom: 0;\n}\n\n.backLink {\n    display: flex;\n    cursor: pointer;\n    color: #262626;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": "titleWrapper_IWdfa",
	"title": "title_rKCpo",
	"backLink": "backLink_G_D7a"
};
export default ___CSS_LOADER_EXPORT___;
