import { RequestTypes } from '../../../../hooks/useRequest'

export const GREEN_TOOLS_API_PREFIX = '/api/green-tools/v1/'
export const GREEN_TOOLS_PART = 'certificate-partition'
export const GREEN_TOOLS_SPLIT_PREVIEW = 'certificate-auto-split-preview'
export const GREEN_TOOLS_SPLIT_AUTO = 'certificate-auto-split'

export const divideCertificate = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_PART}`,
    method: RequestTypes.post,
}

export const dividePreview = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_SPLIT_PREVIEW}`,
    method: RequestTypes.post,
}

export const divideAuto = {
    link: `${GREEN_TOOLS_API_PREFIX}${GREEN_TOOLS_SPLIT_AUTO}`,
    method: RequestTypes.post,
}
