import { FC, useState } from 'react'
import useRequest from '../../../hooks/useRequest'
import PageContainer from '../../../ui/PageElements/PageContainer/PageContainer'
import BackLink from '../../../ui/BackTitle/BackTitle'
import { useParams, useNavigate } from 'react-router-dom'
import Card from '../../../ui/Card/Card'
import { Divider, Alert } from 'antd'
import { Box } from '../../../ui/Box'
import { CenteredSpin, ImportButton, PrimaryButton, DocumentsGrid } from '../../../ui'
import style from './style.m.less'
import { Stamp } from '../../../../assets/icons'
import { monthPicker } from '../../../helpers'
import { getCaDetails } from '../../config'
import useMount from '../../../hooks/useMount'
import { KUInfoConfig } from './KUInfoConfig'
import useDownloadSignedRequest from '../../../hooks/useDownloadSignedRequest'
import { getStatus } from '../Tables/Default'
import moment from 'moment'
import ApproveModal from './ApproveModal/ApproveModal'
import RefuseModal from './RefuseModal/RefuseModal'

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

const KUCard: FC = () => {
    const navigate = useNavigate()
    const [approveModalVisible, isApproveModalVisible] = useState(false)
    const [refuseModalVisible, isRefuseModalVisible] = useState(false)
    const { downloadSignedRequest } = useDownloadSignedRequest()
    const { fetch, result, isLoading } = useRequest(getCaDetails)

    const { id } = useParams<{ id: string }>()
    const currentDate = `${monthPicker(result?.month)}, ${result?.year}`

    useMount(() => {
        fetch({ addToLink: id })
        scrollToTop()
    })

    if (!result || isLoading) {
        return <CenteredSpin />
    }

    const statusId = result?.status_id
    const isOpen = statusId === 1 || statusId === 2

    return (
        <PageContainer>
            <>
                <BackLink
                    title={`Согласование данных КУ за ${currentDate.toLowerCase()}`}
                    extra={getStatus(statusId)}
                />
                <Card className={style.card}>
                    <Box direction="row" justify="space-between">
                        <div className={style.infoWrapper}>
                            {statusId === 3 && (
                                <>
                                    <Alert
                                        message={
                                            <>
                                                <div className={style.alertHeader}>
                                                    {`Данные не приняты. Переподача данных возможна до
                                                ${moment(result.ts_resubmission).format(
                                                    'DD.MM.YYYY',
                                                )}. Причина:`}
                                                </div>
                                                <div>{result.reason}</div>
                                            </>
                                        }
                                        className={style.errorAlert}
                                        type="error"
                                        showIcon={false}
                                    />
                                    <div />
                                </>
                            )}
                            {KUInfoConfig(result)?.map((item: any) => (
                                <Box direction="row">
                                    <div className={style.greyBlock}>{item.name}</div>
                                    <div>{item.value}</div>
                                </Box>
                            ))}
                        </div>
                        <ImportButton
                            customStyle={{ width: '200px', height: '20px' }}
                            icon={<Stamp />}
                            value={'Скачать запрос и подпись'}
                            onClick={() => downloadSignedRequest(result.request_id)}
                        />
                    </Box>
                    <Divider />
                    <Box direction="row">
                        <div className={style.filesTitle}>Данные КУ</div>
                        <DocumentsGrid documents={result?.ca_documents} />
                    </Box>
                    <div className={style.bottomBox}>
                        <div className={style.filesTitle}>Дополнительные файлы</div>
                        {result?.documents?.length ? (
                            <DocumentsGrid documents={result?.documents} />
                        ) : (
                            '-'
                        )}
                    </div>
                    {isOpen && (
                        <>
                            <Divider />
                            <Box direction="row" justify="space-between">
                                <Box direction="row" gap="16px">
                                    <PrimaryButton
                                        onClick={() => navigate(-1)}
                                        value="Отмена"
                                        isCancel
                                        customStyle={{ padding: '8px 16px' }}
                                    />
                                    <PrimaryButton
                                        onClick={() => isApproveModalVisible(true)}
                                        value="Согласовать"
                                        customStyle={{ padding: '8px 16px' }}
                                    />
                                </Box>
                                <PrimaryButton
                                    onClick={() => isRefuseModalVisible(true)}
                                    value="Отказать"
                                    isDelete
                                    customStyle={{ padding: '8px 16px' }}
                                />
                            </Box>
                        </>
                    )}
                </Card>
            </>
            <ApproveModal
                id={id}
                currentDate={currentDate}
                isModalVisible={approveModalVisible}
                setIsModalVisible={isApproveModalVisible}
            />
            <RefuseModal
                id={id}
                currentDate={currentDate}
                isModalVisible={refuseModalVisible}
                setIsModalVisible={isRefuseModalVisible}
            />
        </PageContainer>
    )
}

export default KUCard
