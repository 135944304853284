import axios, { AxiosInstance } from 'axios'

import store from '../store'
import { logout } from '../store/auth'

const BaseUrl = process.env.REACT_APP_BASE_URL

export const Agent = (baseURL?: string): AxiosInstance => {
    const headers = { 'Content-Type': 'application/json' }

    const storage = localStorage.getItem('refreshToken') ? localStorage : sessionStorage

    const _agent = axios.create({
        baseURL,
        headers,
        withCredentials: false,
    })

    _agent.interceptors.request.use((config) => {
        const isLogin = config.url === '/api/auth/v1/login'
        const isRefresh = config.url === '/api/auth/v1/token'
        const accessToken = localStorage.getItem('accessToken')
            ? localStorage.getItem('accessToken')
            : sessionStorage.getItem('accessToken')
        if (accessToken && !isRefresh && !isLogin) {
            config.headers = {
                ...headers,
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
            }
        }
        return config
    })

    _agent.interceptors.response.use(
        (response) => {
            return response
        },
        async (error) => {
            const isRegisterUrl =
                '/api/auth/v1/login-check' ||
                '/api/users/v1/signup' ||
                `/api/users/v1/company?name` ||
                '/api/auth/v1/password' ||
                '/api/auth/v1/logout'
            const originalConfig = error.config
            if (originalConfig.url !== isRegisterUrl && error.response) {
                if (error.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true
                    const refreshToken = storage.getItem('refreshToken')
                    try {
                        if (!refreshToken) {
                            store.dispatch(logout())
                            window.location.href = '/auth'
                            throw new Error('no refresh token')
                        }
                        storage.setItem('refreshToken', refreshToken)
                        const rs = await _agent.post('/api/auth/v1/token', {
                            token_type: 'Bearer',
                            refresh_token: refreshToken,
                        })
                        const { access_token } = rs.data
                        storage.setItem('accessToken', access_token)
                        return _agent(originalConfig)
                    } catch (_error: any) {
                        return Promise.reject(_error.message)
                    }
                }
                if (error.response.status === 403) {
                    // todo: удалить после фикса на бэкенде для клиента без ЛС!!!
                    if (originalConfig.url === '/api/green-tools/v1/accounts') {
                        return Promise.reject(error.response.data)
                    } else if (originalConfig.url.includes('/api/generators/v1/go')) {
                        window.location.href = '/'
                        return Promise.reject(error.response.data)
                    }

                    store.dispatch(logout())
                } else {
                    return Promise.reject(error.response.data)
                }
            }
        },
    )

    return _agent
}

const agent = Agent(BaseUrl)

export default agent
