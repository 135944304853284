import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const FolderCross: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M29.92 34.0821L22.25 26.4121"
                    stroke="#BFBFBF"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.8341 26.498L22.1641 34.168"
                    stroke="#BFBFBF"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M47.6654 23.834V36.834C47.6654 45.5007 45.4987 47.6673 36.832 47.6673H15.1654C6.4987 47.6673 4.33203 45.5007 4.33203 36.834V15.1673C4.33203 6.50065 6.4987 4.33398 15.1654 4.33398H18.4154C21.6654 4.33398 22.3804 5.28732 23.6154 6.93398L26.8654 11.2673C27.6887 12.3507 28.1654 13.0007 30.332 13.0007H36.832C45.4987 13.0007 47.6654 15.1673 47.6654 23.834Z"
                    stroke="#BFBFBF"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
            </svg>
        )}
    />
)
