// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainWrapper_ecUvY {\n  display: flex;\n}\n.contentWrapper_yTlNB {\n  margin-left: 18px;\n  width: 319px;\n}\n.modalHeader_Dedk3 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: #303440;\n}\n.buttonsBlock_am9T1 {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 24px;\n}\n.text_uq1Tc {\n  width: 279px;\n  margin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MyProfile/components/RemoveRequestModal/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;EACI,iBAAA;EACA,YAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAIA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,YAAA;EACA,gBAAA;AAHJ","sourcesContent":[".mainWrapper {\n    display: flex;\n}\n\n.contentWrapper {\n    margin-left: 18px;\n    width: 319px;\n}\n\n.modalHeader {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #303440;\n}\n\n.buttonsBlock {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 24px;\n}\n\n.text {\n    width: 279px;\n    margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": "mainWrapper_ecUvY",
	"contentWrapper": "contentWrapper_yTlNB",
	"modalHeader": "modalHeader_Dedk3",
	"buttonsBlock": "buttonsBlock_am9T1",
	"text": "text_uq1Tc"
};
export default ___CSS_LOADER_EXPORT___;
