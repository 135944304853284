import moment from 'moment'
import { FC, useRef, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../Card/Card'
import Tag from '../Tag/Tag'
import style from './style.m.less'
import { Spin } from 'antd'

interface INewsItem {
    title: string
    date: Date
    isNews?: boolean
    isEvent?: boolean
    image?: string
    description: string
    file?: any
    fileLoading?: boolean
    onClick?(): void
}

const NewsItem: FC<INewsItem> = ({ title, date, isNews, isEvent, image, description, file, onClick, fileLoading = false }) => {
    const { t } = useTranslation()
    const [lastLineTitle, setLastLineTitle] = useState(3) // 66 px макс. высота по 22 px на строку , макс. строк 3
    const [lastLine, setLastLine] = useState(4)
    const refTitle = useRef(null);
    const refText = useRef(null);

     useLayoutEffect(() => {
        const offsetHeight = refTitle?.current?.offsetHeight
        // line-height в css прописал 22px но refText?.current?.style.lineHeight = ""
        setLastLineTitle(Math.round((offsetHeight || 0) / 22))
    }, [refTitle])

     useLayoutEffect(() => {
        const offsetHeight = refText?.current?.offsetHeight
        setLastLine(Math.round((offsetHeight || 0) / 22))
    }, [refText])

    return (
        <Card onClick={onClick} className={style.card}>
            <div className={style.allNews}>
                <div ref={refTitle} className={style.title} style={{ WebkitLineClamp: `${lastLineTitle}`}}>{title}</div>
                <div className={style.underTitle}>
                    <div className={style.info}>
                        <span className={style.date}>{moment(date).format('DD.MM.YYYY')}</span>
                        <span>
                            {isNews ? (
                                <Tag color="blue">#{t('newsItem.news')}</Tag>
                                ) : isEvent ? (
                                    <Tag color="green">#{t('newsItem.events')}</Tag>
                                    ) : null}
                        </span>
                    </div>
                    <div ref={refText} className={style.blockWithText} style={{ WebkitLineClamp: `${lastLine}`}}>{description}</div>
                    <Spin spinning={fileLoading}>
                    <div className={style.newsItem}>
                        {image && <img src={image} className={style.img} alt="title" />}
                        {file && <img src={`data:application/octet-stream;base64, ${file}`} className={style.img} alt="title" />}
                    </div>
                    </Spin>
            </div>
            </div>
        </Card>
    )
}

export default NewsItem
