import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const MoneyOut: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.3327 9.99984C18.3327 14.5998 14.5993 18.3332 9.99935 18.3332C5.39935 18.3332 1.66602 14.5998 1.66602 9.99984C1.66602 5.39984 5.39935 1.6665 9.99935 1.6665"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.3333 4.99984V1.6665H15"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.166 5.83317L18.3327 1.6665"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.16602 9.99984H11.2493C12.4993 10.008 12.916 9.1665 12.916 8.33317C12.916 7.49984 12.4993 6.6665 11.2493 6.6665H9.16602"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.16602 11.6665H11.666"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.58398 6.6665V13.75"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
