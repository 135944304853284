import { FC, useState, useEffect } from 'react'
import { Input, message } from 'antd'
import {
    IconButton,
    CertificateCard,
    Alert,
    TimeRangePicker,
    ContractCard,
    ThirdPartyToolsCard,
} from '../../../../../ui'
import useRequest from '../../../../../hooks/useRequest'
import { getCertificate, getContract, getOtherPublic } from '../../../../config'
import { monthPicker } from '../../../../../helpers'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectDictionariesMaps } from '../../../../../../store/dictionaries'
import moment from 'moment'
import style from './style.m.less'
import { Link } from 'react-router-dom'

const options = [
    { label: 'Сертификат происхождения', id: '065cdaf1-a0ec-4379-9a5e-b225be191148' },
    { label: 'Зеленый договор', id: '7ad9f398-2383-48f6-b2b9-11caad7abb1a' },
    { label: 'Сторонние инструменты', id: 'c6a34b33-03be-4211-bc66-c209c6b52e4f' },
]

const Search: FC = () => {
    const [toolType, setToolType] = useState('Сертификат происхождения')
    const [certificateNumber, setCertificateNumber] = useState('')
    const [deliveryRange, setDeliveryRange] = useState()
    const { fetch, result, dropState } = useRequest(getCertificate)
    const {
        fetch: fetchContract,
        result: resultContract,
        dropState: dropContract,
    } = useRequest(getContract)
    const {
        fetch: fetchOther,
        result: resultOther,
        dropState: dropOther,
    } = useRequest(getOtherPublic)

    const isCertificate = toolType === 'Сертификат происхождения'
    const isContract = toolType === 'Зеленый договор'
    const isOther = toolType === 'Сторонние инструменты'

    const onChange = (value: string) => {
        setToolType(value)
    }

    const onChangeCertificateNumber = (e: any) => {
        setCertificateNumber(e.target.value)
    }

    const { renewableEnergyTypesMap } = useAppSelector(selectDictionariesMaps) ?? {}

    const getEnergyType = (item: number) => renewableEnergyTypesMap?.get(item)

    useEffect(() => {
        if (isCertificate) {
            dropState()
        } else if (isContract) {
            dropContract()
        } else {
            dropOther()
        }
        setCertificateNumber('')
        setDeliveryRange(undefined)
    }, [toolType])

    const onFinish = () => {
        if (certificateNumber) {
            if (isCertificate) {
                fetch({
                    body: {
                        certificate_number: certificateNumber,
                    },
                    onError: dropState(),
                    errorCodeMessage: true,
                })
            } else if (isContract) {
                fetchContract({
                    body: {
                        limit: 10,
                        offset: 0,
                        filters: {
                            contract_number: certificateNumber,
                            ts_delivery_from: deliveryRange
                                ? moment(deliveryRange[0]).startOf('day')
                                : undefined,
                            ts_delivery_to: deliveryRange
                                ? moment(deliveryRange[1]).endOf('day')
                                : undefined,
                        },
                    },
                    onSuccess: (data) => {
                        if (!data.contracts.length) {
                            message.error('Зеленый договор не найден')
                        }
                    },
                    onError: dropContract(),
                    errorCodeMessage: true,
                })
            } else {
                fetchOther({
                    body: {
                        ext_instrument_number: certificateNumber,
                    },
                    onSuccess: (data) => {
                        if (data === '') {
                            message.error('Сторонний инструмент не найден')
                        }
                    },
                    onError: dropOther(),
                    errorCodeMessage: true,
                })
            }
        } else {
            if (isContract && !deliveryRange) {
                message.error('Период поставки не должен быть пустым')
            } else {
                message.error('Номер не должен быть пустым')
            }
        }
    }

    const createCertificateCard = () => {
        if (isCertificate && result) {
            const {
                certificate_id,
                certificate_number,
                renewable_energy_type_id,
                status_id,
                volume,
                generator_name,
                ts_created,
                is_etp,
                ts_expired,
                month,
                year,
                is_deposit,
                generator_id,
            } = result
            const productionPeriod = `${monthPicker(month)} ${year}`
            return (
                <CertificateCard
                    toolType={toolType}
                    certificateNumber={certificate_number}
                    option={status_id}
                    isEtp={is_etp}
                    energyType={getEnergyType(renewable_energy_type_id)}
                    capacity={volume}
                    productionPeriod={productionPeriod}
                    generatorName={generator_name}
                    certificateId={certificate_id}
                    generator_id={generator_id}
                    isDeposit={is_deposit}
                    createdTs={moment(ts_created).format('DD.MM.YYYY')}
                    expiredTs={moment(ts_expired).format('DD.MM.YYYY')}
                />
            )
        } else if (isContract && resultContract?.contracts?.length) {
            const totalVolume = resultContract?.contracts?.reduce(
                (acc: number, contract: any) => acc + contract?.volume_new,
                0,
            )
            return (
                <ContractCard
                    isRegistered={false}
                    isOwner
                    isSearchView
                    contract={{ ...resultContract.contracts[0], volume_new: totalVolume }}
                    key={resultContract.contracts[0].contract_id}
                />
            )
        } else if (isOther && resultOther) {
            return (
                <ThirdPartyToolsCard
                    isRegistered={false}
                    isOwner
                    tool={{
                        total_volume: resultOther.volume,
                        number: resultOther.instrument_number,
                        month: moment(resultOther.period).month() + 1,
                        year: moment(resultOther.period).year(),
                        ...resultOther,
                    }}
                    key={resultOther.generator_id}
                />
            )
        }
    }

    const toolWord = isCertificate ? 'сертификата' : isContract ? 'договора' : 'инструмента'

    return (
        <>
            <div className={style.selectorWrapper}>
                <div className={style.selectorHeader}>Что Вы хотите найти?</div>
                <div className={style.selector}>
                    {options.map((item) => {
                        const { label, id } = item
                        return (
                            <div
                                className={
                                    toolType === label ? style.greenToolSelected : style.greenTool
                                }
                                key={id}
                                onClick={() => onChange(label)}
                            >
                                {label}
                            </div>
                        )
                    })}
                </div>
                {toolType && (
                    <div className={style.numberInputWrapper}>
                        <div>
                            <div>Номер {toolWord}</div>
                            <div className={style.inputBlock}>
                                <Input
                                    value={certificateNumber}
                                    onChange={onChangeCertificateNumber}
                                    className={style.certificateNumberInput}
                                    placeholder={`Введите номер ${toolWord}`}
                                />
                            </div>
                        </div>
                        {isContract && (
                            <div className={style.leftGapBlock}>
                                <div>Период поставки</div>
                                <div className={style.inputBlock}>
                                    <TimeRangePicker
                                        onChange={(value) => setDeliveryRange(value)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={style.leftGapBlock}>
                            <IconButton onClick={onFinish} customStyle={{ height: '32px' }}>
                                Найти
                            </IconButton>
                        </div>
                    </div>
                )}
                {isCertificate && (
                    <Alert
                        message={
                            <div>
                                Вы также можете
                                <Link className={style.link} to="/certificates-in-pledge">
                                    посмотреть список СП в залоге
                                </Link>
                            </div>
                        }
                        type="info"
                        className={style.alert}
                    />
                )}
            </div>
            <div className={style.card}>{createCertificateCard()}</div>
        </>
    )
}

export default Search
