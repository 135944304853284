import { CSSProperties, FC } from 'react'
import { DatePicker, DatePickerProps } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { Calendar } from '../../../assets/icons'
import style from './style.m.less'
import moment, { Moment } from 'moment'

const { RangePicker } = DatePicker

type DatePeriod = {
    onlyPast?: boolean
    onlyBeforeDate?: Moment
    onlyBeforeDateAndToday?: Moment
    onlyWorkDays?: boolean
    onlyFuture?: boolean
}

type ITimeRangePicker = DatePeriod & {
    value?: [moment.Moment, moment.Moment] | null
    onChange(dates: [moment.Moment, moment.Moment] | null): void
    style?: CSSProperties
    picker?: string
    format?: string
}

const endOfDay = moment().startOf('day')
const onlyPastFilter: DatePickerProps['disabledDate'] = (d) => d >= endOfDay
const onlyBeforeDateFilter =
    (date: Moment): DatePickerProps['disabledDate'] =>
    (d) =>
        d.endOf('day') >= date.endOf('day')
const onlyBeforeDateAndTodayFilter =
    (date: Moment): DatePickerProps['disabledDate'] =>
    (d) =>
        d.endOf('day') > date.endOf('day')
const onlyFutureFilter: DatePickerProps['disabledDate'] = (d) => d < endOfDay && d.isoWeekday() > 5
const onlyPastWorkdaysFilter: DatePickerProps['disabledDate'] = (d) => d > endOfDay
const onlyFutureWorkdaysFilter: DatePickerProps['disabledDate'] = (d) =>
    d < endOfDay && d.isoWeekday() > 5
const onlyBeforeDateWorkdaysFilter =
    (date: Moment): DatePickerProps['disabledDate'] =>
    (d) =>
        d.endOf('day') >= date.endOf('day') || d.isoWeekday() > 5
const onlyBeforeDateAndTodayWorkdaysFilter =
    (date: Moment): DatePickerProps['disabledDate'] =>
    (d) =>
        d.endOf('day') > date.endOf('day') || d.isoWeekday() > 5

function datePeriodFilter({
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
}: DatePeriod) {
    if (onlyPast) {
        return onlyWorkDays ? onlyPastWorkdaysFilter : onlyPastFilter
    }

    if (onlyBeforeDate) {
        return onlyWorkDays
            ? onlyBeforeDateWorkdaysFilter(onlyBeforeDate)
            : onlyBeforeDateFilter(onlyBeforeDate)
    }

    if (onlyBeforeDateAndToday) {
        return onlyWorkDays
            ? onlyBeforeDateAndTodayWorkdaysFilter(onlyBeforeDateAndToday)
            : onlyBeforeDateAndTodayFilter(onlyBeforeDateAndToday)
    }

    if (onlyFuture) {
        return onlyWorkDays ? onlyFutureWorkdaysFilter : onlyFutureFilter
    }
}
const TimeRangePicker: FC<ITimeRangePicker> = ({
    onChange,
    onlyWorkDays,
    onlyPast,
    onlyFuture,
    onlyBeforeDate,
    onlyBeforeDateAndToday,
    value,
    picker,
    format,
    ...rest
}) => {
    const filter = datePeriodFilter({
        onlyWorkDays,
        onlyPast,
        onlyFuture,
        onlyBeforeDate,
        onlyBeforeDateAndToday,
    })
    return (
        <RangePicker
            {...rest}
            className={style.rangePicker}
            suffixIcon={<Calendar color={'#8C8C8C'} />}
            locale={locale}
            placeholder={picker === 'month' ? ['Месяц с', 'Месяц по'] : ['Дата с', 'Дата по']}
            onChange={onChange}
            disabledDate={filter}
            format={format ?? 'DD.MM.YYYY'}
            value={value}
            picker={picker}
        />
    )
}

export default TimeRangePicker
