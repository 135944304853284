import { RequestTypes } from '../../../hooks/useRequest'

export const GENERATION_ATTRIBUTES_API_PREFIX = '/api/generation_attributes/v1/gas/'
export const GENERATION_ATTRIBUTES_CORRECT = 'actions/correct'
export const GENERATION_ATTRIBUTES_LIST = 'ga/list'
export const GENERATION_ATTRIBUTES_OWNERS = 'ga/ownersGO'

export const correctAG = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_CORRECT}`,
    method: RequestTypes.post,
}

export const getListAG = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_LIST}`,
    method: RequestTypes.get,
}

export const getListAGOwners = {
    link: `${GENERATION_ATTRIBUTES_API_PREFIX}${GENERATION_ATTRIBUTES_OWNERS}`,
    method: RequestTypes.get,
}
