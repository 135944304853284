enum EStatus {
    UnderConsideration = 'На рассмотрении',
    NotAgreed = 'Не согласовано',
    Accepted = 'Принято',
}

interface IData {
    key: string
    docNo: string
    period: [Date, Date]
    volume: number
    zgo: string
    date: Date
    applied: string
    status: EStatus
    isNew?: boolean
}

const getDataSource = (): IData[] => [
    {
        key: '1',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 150000,
        zgo: 'ООО "Бугульчанская солнечная электростанция"',
        date: new Date(2022, 5, 5),
        applied: 'ГП',
        status: EStatus.UnderConsideration,
        isNew: true,
    },
    {
        key: '2',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 277856,
        zgo: 'ООО "Бугульчанская солнечная электростанция"',
        date: new Date(2022, 5, 4),
        applied: 'Владелец ГО',
        status: EStatus.UnderConsideration,
        isNew: true,
    },
    {
        key: '3',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 900765,
        zgo: 'ООО "Бугульчанская солнечная электростанция"',
        date: new Date(2022, 5, 1),
        applied: 'Владелец ГО',
        status: EStatus.UnderConsideration,
        isNew: true,
    },
    {
        key: '4',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 1000766,
        zgo: 'ООО "Авелар Солар Технолоджи"',
        date: new Date(2022, 4, 29),
        applied: 'Владелец ГО',
        status: EStatus.UnderConsideration,
    },
    {
        key: '5',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 322700,
        zgo: 'ООО "Авелар Солар Технолоджи"',
        date: new Date(2022, 4, 29),
        applied: 'ГП',
        status: EStatus.UnderConsideration,
    },
    {
        key: '6',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 15000000,
        zgo: 'ООО "Авелар Солар Технолоджи"',
        date: new Date(2022, 4, 27),
        applied: 'ГП',
        status: EStatus.UnderConsideration,
    },
    {
        key: '7',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2020, 1, 1), new Date(2020, 1, 29)],
        volume: 900765,
        zgo: 'ООО "Первый Ветропарк ФРВ"',
        date: new Date(2022, 4, 29),
        applied: 'Владелец ГО',
        status: EStatus.UnderConsideration,
    },
    {
        key: '8',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 13000655,
        zgo: 'ООО "Бугульчанская солнечная электростанция"',
        date: new Date(2022, 5, 6),
        applied: 'Владелец ГО',
        status: EStatus.UnderConsideration,
    },
    {
        key: '9',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2019, 8, 1), new Date(2019, 8, 30)],
        volume: 87900,
        zgo: 'ООО "Авелар Солар Технолоджи"',
        date: new Date(2022, 4, 29),
        applied: 'ГП',
        status: EStatus.UnderConsideration,
    },
    {
        key: '10',
        docNo: '0819141039197-KP-BUGULSES-USENSBI-E-SDD-19',
        period: [new Date(2020, 1, 1), new Date(2010, 1, 29)],
        volume: 257810,
        zgo: 'ООО "Первый Ветропарк ФРВ"',
        date: new Date(2022, 4, 29),
        applied: 'ГП',
        status: EStatus.UnderConsideration,
    },
]

const openedDataSource = getDataSource()

for (let i = 0; i < 10; i++) {
    openedDataSource.push(...openedDataSource)
}

const closedDataSource = getDataSource()
closedDataSource[0].status = EStatus.NotAgreed
closedDataSource[1].status = EStatus.NotAgreed
closedDataSource[2].status = EStatus.NotAgreed
closedDataSource[3].status = EStatus.Accepted
closedDataSource[4].status = EStatus.NotAgreed
closedDataSource[5].status = EStatus.Accepted
closedDataSource[6].status = EStatus.Accepted
closedDataSource[7].status = EStatus.Accepted
closedDataSource[8].status = EStatus.Accepted
closedDataSource[9].status = EStatus.Accepted
for (let i = 0; i < 4; i++) {
    closedDataSource.push(...closedDataSource)
}

export { EStatus, openedDataSource, closedDataSource }
export type { IData }
