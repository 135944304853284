import { RequestTypes } from '../hooks/useRequest'

export const GT_API_PREFIX = '/api/green-tools/v1/'
export const GT_SUBMISSION = 'ei/submission'
export const GT_REDEEM = 'ei/redeem'
export const GT_VIEW = 'ei/list'

export const postUpload = {
    link: `${GT_API_PREFIX}${GT_SUBMISSION}`,
    method: RequestTypes.post,
}

export const postRedeem = {
    link: `${GT_API_PREFIX}${GT_REDEEM}`,
    method: RequestTypes.post,
}

export const viewData = {
    link: `${GT_API_PREFIX}${GT_VIEW}`,
    method: RequestTypes.post,
}
