import { FC } from 'react'
import { useEditData } from '../hooks/useEditData'
import { ProfileLayout, GeneratorLayout } from './pages'
import { HeaderBlock } from '../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const Edit: FC = () => {
    const { t } = useTranslation()

    const type = useEditData()

    return (
        <div className={style.editMain} style={{ margin: '0 auto' }}>
            <HeaderBlock label={type === 'generator' ? t('edit.editGO') : t('edit.editProfile')} />
            <div className={style.editFrame}>
                {type === 'generator' ? <GeneratorLayout type={type} /> : <ProfileLayout />}
            </div>
        </div>
    )
}

export default Edit
