import style from './style.m.less'

export interface IConsumer {
    consumer_data: IConsumerItem
    volume: number
    user_id: string
}

interface IConsumerItem {
    company_inn: string
    company_name_short: string
    first_name: string
    full_name: string
    inn: string
    kpp: string
    last_name: string
    middle_name: string
    ogrn: string
}

const getName = (consumer: IConsumerItem) => consumer.company_name_short ?? consumer.full_name

export const SeparateConsumersTable = ({ eventDetails }: any) => {
    const { payload, name_recipient } = eventDetails
    const { consumers, volume } = payload
    const consemersList = consumers ?? [
        { consumer_data: { company_name_short: name_recipient }, volume },
    ]

    return (
        <div className={style.wrapper}>
            <div className={style.table}>
                <div className={style.header}>Потребители ({consemersList?.length})</div>
                <div className={style.row}>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Потребитель
                    </div>
                    <div className={style.column} style={{ fontWeight: '600' }}>
                        Объем, кВт*ч
                    </div>
                </div>
                {consemersList?.map((item: IConsumer) => (
                    <div key={item.user_id} className={style.row}>
                        <div className={style.column}>{getName(item.consumer_data)}</div>
                        <div className={style.column}>{item.volume}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
