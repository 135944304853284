import { FC } from 'react'

import style from './style.m.less'
import { DropdownSelector, TimeRangePicker } from '../../../ui'
import { useTranslation } from 'react-i18next'
import { Input } from 'antd'

const options = [
    { id: 'Все', value: 'Все' },
    { id: 'Все 2', value: 'Все 2' },
    { id: 'Все 3', value: 'Все 3' },
]

const Filter: FC = () => {
    const { t } = useTranslation()

    return (
        <div className={style.filter}>
            <div className={style.filterItem}>
                <div className={style.label}>{t('zdApproval.docNo')}</div>
                <Input placeholder={t('zdApproval.typeDocNo')} className={style.input} />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('zdApproval.period')}</div>
                <TimeRangePicker />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('zdApproval.zgo')}</div>
                <DropdownSelector options={options} className={style.select} />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('zdApproval.applied')}</div>
                <DropdownSelector options={options} className={style.select} />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>{t('zdApproval.status')}</div>
                <DropdownSelector options={options} className={style.select} />
            </div>
        </div>
    )
}

export default Filter
