import { FC } from 'react'
import { HeaderBlock } from '../../../ui'
import { useNavigate } from 'react-router-dom'
import { ButtonsBlock, CommonBlock } from '../../../Edit/components'
import { Input } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const Password: FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const getBackToProfile = () => {
        navigate('/profile')
    }

    return (
        <div className={style.container}>
            <div className={style.editMain}>
                <HeaderBlock label={t('edit.changePass')} />
                <div className={style.editFrame}>
                    <CommonBlock
                        required
                        label={t('edit.oldPass')}
                        select={
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                className={style.input}
                            />
                        }
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('edit.newPass')}
                        select={
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                className={style.input}
                            />
                        }
                    />
                    <CommonBlock
                        required
                        margin
                        label={t('edit.repeatNewPass')}
                        select={
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                className={style.input}
                            />
                        }
                    />
                    <ButtonsBlock margin cancelAction={getBackToProfile} />
                </div>
            </div>
        </div>
    )
}

export default Password
