import { FC } from 'react'
import { Modal } from '../../..'
import { ButtonsBlock } from '../../../../Edit/components'

import style from './style.m.less'
import { QuestionCircle } from '../../../../../assets/icons'

interface ReturnFromMarketErrorModalProps {
    isVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const ReturnFromMarketErrorModal: FC<ReturnFromMarketErrorModalProps> = ({
    isVisible,
    setIsModalVisible,
}) => {
    const handleOk = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isVisible}>
            <div className={style.modalWrapper}>
                <div className={style.headerWrapper}>
                    <QuestionCircle />
                    <div className={style.header}>Ошибка</div>
                </div>
            </div>
            <div className={style.text}>
                Невозможно вернуть сертификат происхождения с ЭТП, так как он был продан
            </div>
            <div className={style.buttonsBlock}>
                <ButtonsBlock confirmAction={handleOk} cancelAction={handleOk} />
            </div>
        </Modal>
    )
}

export default ReturnFromMarketErrorModal
