import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const CircleRubIn: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.3335 15.9997H17.0002C18.0002 16.0062 18.3335 15.333 18.3335 14.6663C18.3335 13.9997 18.0002 13.333 17.0002 13.333H15.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.3335 17.333H17.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.667 13.333V18.9998"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.6668 16.0002C22.6668 19.6802 19.6802 22.6668 16.0002 22.6668C12.3202 22.6668 9.3335 19.6802 9.3335 16.0002C9.3335 12.3202 12.3202 9.3335 16.0002 9.3335"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.3335 10V12.6667H22.0002"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22.6668 9.3335L19.3335 12.6668"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
