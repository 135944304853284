import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import store from './store'
import './i18n'
import './assets/style/app.less'

import App from './components/App'
import { CenteredSpin } from './components/ui'

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Suspense fallback={<CenteredSpin />}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </Suspense>
        </Router>
    </Provider>,
    document.getElementById('root'),
)
