import { FC } from 'react'
import { ButtonsBlock } from '../../../../Edit/components'
import { Modal, CenteredSpin } from '../../../'
import { Alert, Typography } from 'antd'
import style from './style.m.less'
import { useNavigate, Link } from 'react-router-dom'
import { deleteCertificate } from './config'
import { signBody } from '../../../../helpers'
import useRequest from '../../../../hooks/useRequest'
import { useAppSelector } from '../../../../../store/hooks'
import { useCurrentUser } from '../../../../hooks/useCurrentUser'
import { useGetTariffRate } from '../../../../hooks'

interface IDeleteModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
    certificateId?: string
    certificateNumber?: string
    capacity: number
}

const DeleteModal: FC<IDeleteModal> = ({
    isModalVisible,
    setIsModalVisible,
    certificateId,
    certificateNumber,
    capacity,
}) => {
    const navigate = useNavigate()
    const { isUserAdmin } = useCurrentUser()
    const { fetch: fetchDelete } = useRequest(deleteCertificate)

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const userInfo = useAppSelector((store) => store.user)
    const { data } = userInfo

    const handleConfirm = async () => {
        const body = { certificate_id: certificateId }
        if (isUserAdmin) {
            fetchDelete({
                body,
                successMessage: {
                    title: 'Успешно',
                    description: 'Сертификат успешно удален',
                },
                onSuccess: () => navigate('/green-tools'),
                errorCodeMessage: true,
            })
        } else {
            const { request_id, signature } = await signBody(body, data)
            fetchDelete({
                body,
                config: {
                    headers: {
                        Signature: signature,
                    },
                },
                getParams: {
                    request_id,
                },
                successMessage: {
                    title: 'Успешно',
                    description: 'Сертификат успешно удален',
                },
                onSuccess: () => navigate('/user-my-green-tools'),
                errorCodeMessage: true,
            })
        }
    }

    const { loadingTariffs, balance, rate } = useGetTariffRate({
        dependency: isModalVisible && !isUserAdmin,
        id: 19,
        volume: capacity,
    })

    if (loadingTariffs) {
        return <CenteredSpin />
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.mainWrapper}>
                <div className={style.contentWrapper}>
                    <div className={style.modalHeader}>{'Удалить сертификат происхождения'}</div>
                    <div
                        className={style.modalText}
                    >{` Вы действительно хотите удалить сертификат происхождения №${certificateNumber}? Отменить это действие будет невозможно.`}</div>
                    {!isUserAdmin && (
                        <Alert
                            className={style.modalAlert}
                            message={
                                <div className={style.modalAlertText}>
                                    <div>
                                        За операцию взымается оплата в размере{' '}
                                        <Typography.Text strong>{rate}</Typography.Text>
                                    </div>
                                    <div>Баланс платежного счета: {balance}</div>
                                </div>
                            }
                            type="warning"
                            showIcon
                        />
                    )}
                    <div className={style.buttonsBlock}>
                        <ButtonsBlock
                            isDelete
                            confirmAction={handleConfirm}
                            cancelAction={handleCancel}
                            confirmTitle={isUserAdmin ? 'Удалить' : 'Подписать и удалить'}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteModal
