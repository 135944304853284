enum EStatus {
    UnderRevision = 'На доработке',
    UnderConsideration = 'На рассмотрении',
    NotAgreed = 'Отказано',
    Accepted = 'Принято',
}

interface IData {
    key: string
    submissionDate: string
    gOOwner: string
    inn: string
    address: string
    gOName: string
    status: EStatus
    isNew?: boolean
}

const getDataSource = (): IData[] => [
    {
        key: '1',
        submissionDate: '22.02.2022',
        gOOwner: 'ОАО «Промышленная мини-ТЭЦ Белый ручей»',
        inn: '123456789',
        address:
            'РФ, Республика Хакасия, город Абакан, Ташебинский промузел, промплощадка Абаканвагонмаш, 21',
        gOName: 'Солнечная электростанция «Абаканская»',
        status: EStatus.UnderConsideration,
        isNew: true,
    },
    {
        key: '2',
        submissionDate: '22.02.2022',
        gOOwner: 'ЗАО «Норд Гидро»',
        inn: '123456789',
        address: 'РФ, 308023, г. Белгород, 5-й Заводской пер., д.17',
        gOName: 'Ветроэлектрическая станция в Ульяновской Области/SVIE0478',
        status: EStatus.UnderConsideration,
        isNew: true,
    },
    {
        key: '3',
        submissionDate: '22.02.2022',
        gOOwner: 'ОАО «Геотерм»',
        inn: '123456789',
        address: 'РФ, 308023, г. Белгород, 5-й Заводской пер., д.17',
        gOName: 'Новоузенская солнечная электростанция',
        status: EStatus.UnderConsideration,
        isNew: true,
    },
    {
        key: '4',
        submissionDate: '22.02.2022',
        gOOwner: 'ОАО «Промышленная мини-ТЭЦ Белый ручей»',
        inn: '123456789',
        address:
            'РФ, Республика Хакасия, город Абакан, Ташебинский промузел, промплощадка Абаканвагонмаш, 21',
        gOName: 'Солнечная электростанция «Абаканская»',
        status: EStatus.UnderConsideration,
        isNew: true,
    },
    {
        key: '5',
        submissionDate: '22.02.2022',
        gOOwner: 'ЗАО «Норд Гидро»',
        inn: '123456789',
        address: 'РФ, 308023, г. Белгород, 5-й Заводской пер., д.17',
        gOName: 'Ветроэлектрическая станция в Ульяновской Области/SVIE0478',
        status: EStatus.UnderConsideration,
    },
    {
        key: '6',
        submissionDate: '22.02.2022',
        gOOwner: 'ОАО «Геотерм»',
        inn: '123456789',
        address: 'РФ, 308023, г. Белгород, 5-й Заводской пер., д.17',
        gOName: 'Новоузенская солнечная электростанция',

        status: EStatus.UnderRevision,
    },
    {
        key: '7',
        submissionDate: '22.02.2022',
        gOOwner: 'ОАО «Промышленная мини-ТЭЦ Белый ручей»',
        inn: '123456789',
        address:
            'РФ, Республика Хакасия, город Абакан, Ташебинский промузел, промплощадка Абаканвагонмаш, 21',
        gOName: 'Солнечная электростанция «Абаканская»',
        status: EStatus.UnderRevision,
    },
    {
        key: '8',
        submissionDate: '22.02.2022',
        gOOwner: 'ЗАО «Норд Гидро»',
        inn: '123456789',
        address: 'РФ, 308023, г. Белгород, 5-й Заводской пер., д.17',
        gOName: 'Ветроэлектрическая станция в Ульяновской Области/SVIE0478',
        status: EStatus.UnderRevision,
    },
    {
        key: '9',
        submissionDate: '22.02.2022',
        gOOwner: 'ОАО «Геотерм»',
        inn: '123456789',
        address: 'РФ, 308023, г. Белгород, 5-й Заводской пер., д.17',
        gOName: 'Новоузенская солнечная электростанция',
        status: EStatus.UnderRevision,
    },
    {
        key: '10',
        submissionDate: '22.02.2022',
        gOOwner: 'ОАО «Промышленная мини-ТЭЦ Белый ручей»',
        inn: '123456789',
        address:
            'РФ, Республика Хакасия, город Абакан, Ташебинский промузел, промплощадка Абаканвагонмаш, 21',
        gOName: 'Солнечная электростанция «Абаканская»',
        status: EStatus.UnderRevision,
    },
]

const openedDataSource = getDataSource()

for (let i = 0; i < 10; i++) {
    openedDataSource.push(...openedDataSource)
}

const closedDataSource = getDataSource()
closedDataSource[0].status = EStatus.Accepted
closedDataSource[1].status = EStatus.NotAgreed
closedDataSource[2].status = EStatus.NotAgreed
closedDataSource[3].status = EStatus.NotAgreed
closedDataSource[4].status = EStatus.Accepted
closedDataSource[5].status = EStatus.Accepted
closedDataSource[6].status = EStatus.Accepted
closedDataSource[7].status = EStatus.Accepted
closedDataSource[8].status = EStatus.Accepted
closedDataSource[9].status = EStatus.Accepted
for (let i = 0; i < 4; i++) {
    closedDataSource.push(...closedDataSource)
}

export { EStatus, openedDataSource, closedDataSource }
export type { IData }
