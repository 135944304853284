// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerSubscribe_F4dk8 {\n  width: 189px;\n}\n.footerHeader_GfmGN {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 22px;\n}\n.itemText_QWzQO {\n  margin-top: 32px;\n}\n.navItem_mE6rA {\n  color: #fff;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/components/FooterSubscribe/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".footerSubscribe {\n    width: 189px;\n}\n\n.footerHeader {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 22px;\n}\n\n.itemText {\n    margin-top: 32px;\n}\n\n.navItem {\n    color: #fff;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 16px;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerSubscribe": "footerSubscribe_F4dk8",
	"footerHeader": "footerHeader_GfmGN",
	"itemText": "itemText_QWzQO",
	"navItem": "navItem_mE6rA"
};
export default ___CSS_LOADER_EXPORT___;
