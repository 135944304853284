import { FC } from 'react'
import { Card } from '../../../ui'
import { DocIcon, PdfIcon, XlsIcon } from '../../../../assets/icons'

import style from './style.m.less'

const DocumentsGrid: FC = () => {
    return (
        <div className={style.documentsGrid}>
            <Card>
                <div className={style.description}>
                    Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными годовым Общим
                    собранием Членов Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными
                    годовым Общим собранием Членов
                </div>
                <div className={style.file}>
                    <DocIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Устав Ассоциации</div>
                        <div className={style.fileSize}>146 Кб</div>
                    </div>
                </div>
            </Card>
            <Card>
                <div className={style.description}>
                    Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными годовым Общим
                    собранием Членов Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными
                    годовым Общим собранием Членов
                </div>
                <div className={style.file}>
                    <DocIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Устав Ассоциации</div>
                        <div className={style.fileSize}>146 Кб</div>
                    </div>
                </div>
            </Card>
            <Card>
                <div className={style.description}>
                    Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными годовым Общим
                    собранием Членов Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными
                    годовым Общим собранием Членов
                </div>
                <div className={style.file}>
                    <PdfIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Устав Ассоциации</div>
                        <div className={style.fileSize}>183 Мб</div>
                    </div>
                </div>
            </Card>
            <Card>
                <div className={style.description}>
                    Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными годовым Общим
                    собранием Членов Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными
                    годовым Общим собранием Членов
                </div>
                <div className={style.file}>
                    <XlsIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Атрибуты генерации</div>
                        <div className={style.fileSize}>183 Мб</div>
                    </div>
                </div>
            </Card>
            <Card>
                <div className={style.description}>
                    Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными годовым Общим
                    собранием Членов Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными
                    годовым Общим собранием Членов
                </div>
                <div className={style.file}>
                    <XlsIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Атрибуты генерации</div>
                        <div className={style.fileSize}>183 Мб</div>
                    </div>
                </div>
            </Card>
            <Card>
                <div className={style.description}>
                    Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными годовым Общим
                    собранием Членов Устав Ассоциации «НП Совет рынка» с изменениями, утвержденными
                    годовым Общим собранием Членов
                </div>
                <div className={style.file}>
                    <DocIcon />
                    <div className={style.fileInfo}>
                        <div className={style.fileTitle}>Положение о порядке</div>
                        <div className={style.fileSize}>146 Кб</div>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default DocumentsGrid
