import { FC } from 'react'
import { Input } from 'antd'
import style from './style.m.less'
import moment from 'moment'
import { DropdownSelector2, TimeRangePicker, Box } from '../../../ui'

const statusIds = [
    { id: 1, name: 'Новая' },
    { id: 2, name: 'На рассмотрении' },
    { id: 3, name: 'Согласована' },
    { id: 4, name: 'Отказана' },
]

interface IFilter {
    onChange: (data: string | number, key: string, obj?: any) => void
    dict: any
}

const Filter: FC<IFilter> = ({ onChange, dict }) => {
    const updateFilters = (data: any, key: string) => {
        switch (key) {
            case 'rangeDate':
                return onChange('', '', {
                    ts_created_from: data ? moment(data[0]).startOf('day') : undefined,
                    ts_created_to: data ? moment(data[1]).endOf('day') : undefined,
                })
            case 'rangeReport':
                return onChange('', '', {
                    year_from: data ? moment(data[0]).year() : undefined,
                    month_from: data ? moment(data[0]).month() + 1 : undefined,
                    year_to: data ? moment(data[1]).year() : undefined,
                    month_to: data ? moment(data[1]).month() + 1 : undefined,
                })
            default:
                return onChange(data, key)
        }
    }

    const filterWidth = { width: '296px' }

    return (
        <div className={style.filter}>
            <div className={style.filterItem}>
                <div className={style.label}>Дата подачи</div>
                <TimeRangePicker
                    style={filterWidth}
                    onChange={(value) => updateFilters(value, 'rangeDate')}
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Отчетный период</div>
                <TimeRangePicker
                    style={filterWidth}
                    onChange={(value) => updateFilters(value, 'rangeReport')}
                    picker="month"
                    format="MMMM, YYYY"
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>ЗГО</div>
                <DropdownSelector2
                    onChange={(tid) => {
                        updateFilters(tid, 'generator_id')
                    }}
                    options={dict?.generators?.map((item: any) => {
                        return {
                            text: item.name,
                            value: item.id,
                            id: item.id,
                            key: item.id,
                        }
                    })}
                    customStyle={filterWidth}
                    placeholder={'Все'}
                    noDefaultValue
                    allowClear
                />
            </div>
            <div className={style.filterItem}>
                <div className={style.label}>Статус</div>
                <DropdownSelector2
                    onChange={(tid) => {
                        updateFilters(tid, 'fuel_application_status_ids')
                    }}
                    options={statusIds.map((item: any) => {
                        return {
                            text: item.name,
                            value: item.id,
                            id: item.id,
                            key: item.id,
                        }
                    })}
                    customStyle={filterWidth}
                    placeholder={'Все'}
                    noDefaultValue
                    isMultiple
                    allowClear
                />
            </div>
        </div>
    )
}

export default Filter
