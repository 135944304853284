import { useState } from 'react'
import { Realisation } from '../'

import style from '../../style.m.less'

const BottomBlocks = () => {
    const [realisations, setRealisations] = useState<
        {
            delivery_ts: string
            volume?: number
            ammount: number
            status: 0 | 1 | 2
            realisation_date?: string
            consumers?: number
            payment_date?: string
            released_volume?: number
            id: number
        }[]
    >([
        {
            id: 0,
            delivery_ts: 'Январь, 2022',
            volume: 10000,
            ammount: 7500,
            status: 0,
            payment_date: '22.01.2022',
        },
        {
            id: 1,
            delivery_ts: 'Январь, 2022',
            volume: 10000,
            ammount: 7500,
            status: 1,
        },
        {
            id: 2,
            delivery_ts: 'Январь, 2022',
            realisation_date: '20.01.2022',
            released_volume: 10000,
            ammount: 7500,
            status: 2,
            consumers: 3,
        },
    ])

    const editRealisations = (id: number) => {
        setRealisations((prevState) =>
            prevState?.map((realisation) =>
                realisation.id === id
                    ? { ...realisation, status: 1, payment_date: '' }
                    : realisation,
            ),
        )
    }

    return (
        <>
            <div className={style.gridBlocks}>
                <div className={style.realisations}>
                    {realisations.map((info, index) => (
                        <Realisation
                            info={info}
                            key={info.id}
                            editRealisations={editRealisations}
                        />
                    ))}
                </div>

                <div className={style.gridBlock}>
                    <div className={style.repaymentHeader}>История операций</div>
                </div>
            </div>
        </>
    )
}

export default BottomBlocks
