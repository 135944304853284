import CommonBlock from './CommonBlock/CommonBlock'
import ButtonsBlock from './ButtonsBlock/ButtonsBlock'
import PasswordChange from './PasswordChange/PasswordChange'
import AddDocuments from './AddDocuments/AddDocuments'
import ChangeRole from './ChangeRole/ChangeRole'
import ThreePartsBlock from './ThreePartsBlock/ThreePartsBlock'
import SuccessMessage from './SuccessMessage/SuccessMessage'

export {
    CommonBlock,
    ButtonsBlock,
    PasswordChange,
    AddDocuments,
    ChangeRole,
    ThreePartsBlock,
    SuccessMessage,
}
