import { FC } from 'react'
import { Divider } from 'antd'
import { Modal } from '../../../ui'

import style from './style.m.less'
import { Close } from '../../../../assets/icons'
import { qualificationCategories } from './data'

interface IQualificationCategoryModal {
    isModalVisible: boolean
    onClose: () => void
}

const QualificationCategoryModal: FC<IQualificationCategoryModal> = ({
    isModalVisible,
    onClose,
}) => {
    return (
        <Modal isModalVisible={isModalVisible} onCancel={onClose}>
            <div className={style.modal}>
                <div className={style.headerWrapper}>
                    <div className={style.header}>Категории квалификации</div>
                    <div className={style.closeIcon} onClick={onClose}>
                        <Close />
                    </div>
                </div>
                <div className={style.content}>
                    {qualificationCategories?.map((item) => (
                        <>
                            <div className={style.raw}>
                                <div className={style.categoryNumber}>{item.categoryNumber}</div>
                                <div>{item.description}</div>
                            </div>
                            <Divider className={style.divider} />
                        </>
                    ))}
                </div>
            </div>
        </Modal>
    )
}

export default QualificationCategoryModal
