import { FC } from 'react'
import { Button, ButtonProps as AntdButtonProps } from 'antd'
import style from './style.m.less'

type IPrimaryIconButton = AntdButtonProps & {
    icon?: React.ReactNode
    customStyle?: React.CSSProperties
    customIconStyle?: React.CSSProperties
    isCancel?: boolean
    value?: string
    onClick?: () => void
}

const PrimaryIconButton: FC<IPrimaryIconButton> = ({
    icon,
    customIconStyle,
    customStyle,
    isCancel,
    value,
    ...rest
}) => {
    return (
        <Button
            icon={customIconStyle ? <div style={customIconStyle}>{icon}</div> : icon}
            className={isCancel ? style.primaryButtonCancel : style.primaryButton}
            style={customStyle}
            {...rest}
        >
            {value}
        </Button>
    )
}

export default PrimaryIconButton
