import { RequestTypes } from '../hooks/useRequest'

export const SETTINGS_API_PREFIX = '/api/settings/v1/'
export const SETTINGS_DAYS = 'settings/last-action-days'

export const getSettings = {
    link: `${SETTINGS_API_PREFIX}${SETTINGS_DAYS}`,
    method: RequestTypes.get,
}

export const postSettings = {
    link: `${SETTINGS_API_PREFIX}${SETTINGS_DAYS}`,
    method: RequestTypes.post,
}
