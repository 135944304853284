// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".editBlock_nchkR {\n  display: flex;\n  align-items: center;\n}\n.editBlockMargin_LvjC1 {\n  display: flex;\n  align-items: center;\n  margin-top: 16px;\n}\n.editBlockCodes_RpSXG {\n  display: flex;\n  margin-top: 16px;\n}\n.label_HVVjJ {\n  text-align: right;\n  width: 200px;\n}\n.labelCodes_IubXf {\n  text-align: right;\n  width: 200px;\n  margin-top: 4px;\n}\n.star_AvYr8 {\n  margin-left: 4px;\n  display: inline;\n  color: #f5222d;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContinueRegistration/components/CommonBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AACR;AAEI;EACI,aAAA;EACA,gBAAA;AAAR;AAIA;EACI,iBAAA;EACA,YAAA;AAFJ;AAII;EACI,iBAAA;EACA,YAAA;EACA,eAAA;AAFR;AAMA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AAJJ","sourcesContent":[".editBlock {\n    display: flex;\n    align-items: center;\n\n    &Margin {\n        display: flex;\n        align-items: center;\n        margin-top: 16px;\n    }\n\n    &Codes {\n        display: flex;\n        margin-top: 16px;\n    }\n}\n\n.label {\n    text-align: right;\n    width: 200px;\n\n    &Codes {\n        text-align: right;\n        width: 200px;\n        margin-top: 4px;\n    }\n}\n\n.star {\n    margin-left: 4px;\n    display: inline;\n    color: #f5222d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editBlock": "editBlock_nchkR",
	"editBlockMargin": "editBlockMargin_LvjC1",
	"editBlockCodes": "editBlockCodes_RpSXG",
	"label": "label_HVVjJ",
	"labelCodes": "labelCodes_IubXf",
	"star": "star_AvYr8"
};
export default ___CSS_LOADER_EXPORT___;
