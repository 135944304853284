// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputsWrapperShort_hSLnw {\n  width: 608px;\n  margin-top: 16px;\n  margin-left: 216px;\n  display: flex;\n  justify-content: space-between;\n  gap: 16px;\n}\n.star_YmjSg {\n  margin-left: 4px;\n  display: flex;\n  align-items: center;\n  color: #f5222d;\n}\n.inputBlock_a29Ba {\n  display: flex;\n}\n.inputSmall_ZRJic {\n  width: 80px;\n  border-radius: 8px;\n}\n.inputMiddle_sGyyX {\n  width: 200px;\n  border-radius: 8px;\n}\n.datePicker_TBYHA {\n  height: 32px;\n  width: 200px;\n  border-radius: 8px;\n}\n.textAreaBlock_kZtFs {\n  width: 608px;\n  margin-left: 216px;\n  margin-top: 16px;\n  border-radius: 8px;\n}\n.textArea_SrXUs {\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContinueRegistration/components/FivePartsBlock/style.m.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AACJ;AAEA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAAJ;AAGA;EACI,aAAA;AADJ;AAIA;EACI,WAAA;EACA,kBAAA;AAFJ;AAKA;EACI,YAAA;EACA,kBAAA;AAHJ;AAMA;EACI,YAAA;EACA,YAAA;EACA,kBAAA;AAJJ;AAOA;EACI,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AALJ;AAQA;EACI,kBAAA;AANJ","sourcesContent":[".inputsWrapperShort {\n    width: 608px;\n    margin-top: 16px;\n    margin-left: 216px;\n    display: flex;\n    justify-content: space-between;\n    gap: 16px;\n}\n\n.star {\n    margin-left: 4px;\n    display: flex;\n    align-items: center;\n    color: #f5222d;\n}\n\n.inputBlock {\n    display: flex;\n}\n\n.inputSmall {\n    width: 80px;\n    border-radius: 8px;\n}\n\n.inputMiddle {\n    width: 200px;\n    border-radius: 8px;\n}\n\n.datePicker {\n    height: 32px;\n    width: 200px;\n    border-radius: 8px;\n}\n\n.textAreaBlock {\n    width: 608px;\n    margin-left: 216px;\n    margin-top: 16px;\n    border-radius: 8px;\n}\n\n.textArea {\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsWrapperShort": "inputsWrapperShort_hSLnw",
	"star": "star_YmjSg",
	"inputBlock": "inputBlock_a29Ba",
	"inputSmall": "inputSmall_ZRJic",
	"inputMiddle": "inputMiddle_sGyyX",
	"datePicker": "datePicker_TBYHA",
	"textAreaBlock": "textAreaBlock_kZtFs",
	"textArea": "textArea_SrXUs"
};
export default ___CSS_LOADER_EXPORT___;
