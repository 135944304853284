import { IOperationConfig } from '../../../ui/OperationsMapper/types'

export const accounts = [
    {
        value: 'Мой любимый счет (№877035)',
        id: '55677a62-97a0-47d2-8b8f-d57abe52f591',
        number: 877035,
    },
    {
        value: 'Мой нелюбимый счет (№131313)',
        id: '7c23e409-c4c6-46dc-ad08-156c3456610d',
        number: 131313,
    },
]

export const dataSet = [
    {
        color: '#EFDBFF',
        name: 'Активные',
        value: '250 123 000 кВт*ч',
        id: '314afcfc-2a0e-45de-8fa5-be33933e2ea4',
    },
    {
        color: '#52D2A0',
        name: 'Погашенные',
        value: '500 000 852 кВт*ч',
        id: 'd44e0aaf-2031-4c34-aa60-01eecc70e624',
    },
    {
        color: '#BAE7FF',
        name: 'Заблокированные',
        value: '15 130 200 кВт*ч',
        id: 'ee462475-9278-49ce-b7c4-62f0a0cbb911',
    },
    {
        color: '#F4FFB8',
        name: 'Истек срок действия',
        value: '800 445 кВт*ч',
        id: 'c77fa207-0d28-4ac5-95a9-cf299d4cfed5',
    },
]

export const historyConfig: IOperationConfig[] = [
    {
        date: 'Сегодня, 17 июня, пятница',
        operations: [
            { type: 'repayment', time: '12:24', value: '- 50 780 000 кВт*ч' },
            { type: 'repayment', time: '12:00', value: '- 390 500 кВт*ч' },
            { type: 'division', time: '11:40' },
        ],
        flexStart: false,
        iconMargin: true,
    },
    {
        date: 'Вчера, 16 июня, четверг',
        operations: [{ type: 'division', time: '20:39' }],
        flexStart: false,
        iconMargin: true,
    },
]
