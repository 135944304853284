import { FC, useState } from 'react'
import { Checkbox, Divider } from 'antd'
import { CenteredSpin, DocumentsBlock, Modal, PrimaryButton, PrimaryIconButton } from '../../../ui'
import request from '../../../../services/request'
import { useParams } from 'react-router-dom'
import { Alert } from '../../../ui'
import TextArea from 'antd/lib/input/TextArea'

import style from './style.m.less'
import { IFile } from 'components/ui/DocumentsList/DocumentsList'

export enum AddRemoveLockModalVisible {
    None,
    Add,
    Remove,
}

interface IAddRemoveLockModal {
    isModalVisible: AddRemoveLockModalVisible
    blocks: {
        ga_fixation: boolean
        gt_emission: boolean
        ga_transfer: boolean
        ga_redemption: boolean
    }
    onClose: () => void
    onReload: () => void
}

const AddRemoveLockModal: FC<IAddRemoveLockModal> = ({
    isModalVisible,
    onClose,
    onReload,
    blocks,
}) => {
    const { id } = useParams<{ id: string }>()

    const isAddBlock = isModalVisible === AddRemoveLockModalVisible.Add

    const initaialGaFixation = isAddBlock ? blocks.ga_fixation : !blocks.ga_fixation
    const initaialGaRedemption = isAddBlock ? blocks.ga_redemption : !blocks.ga_redemption
    const initaialGaTransfer = isAddBlock ? blocks.ga_transfer : !blocks.ga_transfer
    const initaialGtEmission = isAddBlock ? blocks.gt_emission : !blocks.gt_emission

    const [reasonBlocking, setReasonBlocking] = useState('')
    const [checkedGaFixation, setCheckedGaFixation] = useState(initaialGaFixation)
    const [checkedGaRedemption, setCheckedGaRedemption] = useState(initaialGaRedemption)
    const [checkedGaTransfer, setCheckedGaTransfer] = useState(initaialGaTransfer)
    const [checkedGtEmission, setCheckedGtEmission] = useState(initaialGtEmission)
    const [files, setFiles] = useState<IFile[]>([])
    const [loading, setLoading] = useState(false)

    const disableButton =
        !reasonBlocking ||
        (checkedGaFixation === initaialGaFixation &&
            checkedGaRedemption === initaialGaRedemption &&
            checkedGaTransfer === initaialGaTransfer &&
            checkedGtEmission === initaialGtEmission)

    const handleConfirm = async () => {
        const needToBlockArray: number[] = []
        if (!initaialGaFixation && checkedGaFixation) {
            needToBlockArray.push(1)
        }
        if (!initaialGtEmission && checkedGtEmission) {
            needToBlockArray.push(2)
        }
        if (!initaialGaTransfer && checkedGaTransfer) {
            needToBlockArray.push(3)
        }
        if (!initaialGaRedemption && checkedGaRedemption) {
            needToBlockArray.push(4)
        }

        const params = {
            need_block_add: isAddBlock,
            function_ids: needToBlockArray,
            comment: reasonBlocking,
            documents: files.length
                ? files?.map((file: IFile) => ({
                      id: file.id,
                      hash: file.hash,
                  }))
                : undefined,
        }

        try {
            setLoading(true)
            await request.post(`/api/generators/v1/go-block?generator_id=${id}`, params)
            onClose()
            onReload()
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            isModalVisible={isModalVisible !== AddRemoveLockModalVisible.None}
            onCancel={onClose}
        >
            {loading && <CenteredSpin />}
            <div className={style.header}>
                {isAddBlock ? 'Добавление блокировки для ГО' : 'Снятие блокировки с ГО'}
            </div>
            <Alert
                message={
                    <div>
                        {isAddBlock
                            ? 'Укажите, какой функционал необходимо заблокировать для ГО'
                            : 'Выберите функционал, который необходимо разблокировать'}
                    </div>
                }
                type="info"
                className={style.alert}
            />
            <div className={style.checkboxWrapper}>
                {((isAddBlock && !blocks.ga_fixation) || (!isAddBlock && blocks.ga_fixation)) && (
                    <Checkbox
                        className={style.checkbox}
                        onClick={() => setCheckedGaFixation(!checkedGaFixation)}
                    >
                        {'Фиксация АГ'}
                    </Checkbox>
                )}
                {((isAddBlock && !blocks.gt_emission) || (!isAddBlock && blocks.gt_emission)) && (
                    <Checkbox
                        className={style.checkbox}
                        onClick={() => setCheckedGtEmission(!checkedGtEmission)}
                    >
                        {'Выпуск ЗИ'}
                    </Checkbox>
                )}

                {((isAddBlock && !blocks.ga_transfer) || (!isAddBlock && blocks.ga_transfer)) && (
                    <Checkbox
                        className={style.checkbox}
                        onClick={() => setCheckedGaTransfer(!checkedGaTransfer)}
                    >
                        {'Передача АГ по ЗД'}
                    </Checkbox>
                )}

                {((isAddBlock && !blocks.ga_redemption) ||
                    (!isAddBlock && blocks.ga_redemption)) && (
                    <Checkbox
                        className={style.checkbox}
                        onClick={() => setCheckedGaRedemption(!checkedGaRedemption)}
                    >
                        {'Собственное погашение АГ'}
                    </Checkbox>
                )}
            </div>
            <Divider />
            <div>
                <div>
                    Основание <div className={style.star}>*</div>
                </div>
                <TextArea
                    className={style.textArea}
                    rows={3}
                    onChange={(e) => setReasonBlocking(e.target.value)}
                />
            </div>
            <div className={style.documentsBlock}>
                <DocumentsBlock value={files} onChange={setFiles} hideComment />
            </div>
            <div className={style.buttonsBlock}>
                <PrimaryButton
                    onClick={onClose}
                    value="Отмена"
                    isCancel
                    customStyle={{ padding: '8px 16px' }}
                />
                <PrimaryIconButton
                    size="large"
                    onClick={handleConfirm}
                    value={isAddBlock ? 'Заблокировать' : 'Разблокировать'}
                    customStyle={{ padding: '8px 16px', marginLeft: '12px' }}
                    disabled={disableButton}
                />
            </div>
        </Modal>
    )
}

export default AddRemoveLockModal
