import { Table } from 'antd'
import { FC } from 'react'

import style from './style.m.less'

import { dataSource } from './data'
import { columns } from './columns'
import { useNavigate } from 'react-router-dom'

const KUTable: FC = () => {
    const navigate = useNavigate()

    return (
        <div className={style.table}>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    position: ['bottomLeft'],
                }}
                onRow={(record) => ({
                    onClick: () => {
                        navigate('/go-data-ku-period/' + record.key)
                    },
                })}
            />
        </div>
    )
}

export default KUTable
