import { FC, useMemo } from 'react'

import style from './style.m.less'
import { DropdownSelector2, TimeRangePicker } from '../../../ui'
import { useTranslation } from 'react-i18next'
import { EGoStatusType, EGoType, goStatusTypeMap, goTypeMap } from '../../../../constants/approval'
import { useAppSelector } from '../../../../store/hooks'
import { selectDictionariesOptions } from '../../../../store/dictionaries'

interface IFilter {
    onChangeEnergyType: (type: number) => void
    onChangeGoType: (type: number) => void
    onChangeRegionType: (type: number) => void
    onChangeDate: (date: [moment.Moment, moment.Moment] | null) => void
    onChangeStatusTypes: (type: EGoStatusType) => void
}

const goTypeOptions = [
    { id: EGoType.All, value: EGoType.All, text: goTypeMap.get(EGoType.All) },
    { id: EGoType.KGO, value: EGoType.KGO, text: goTypeMap.get(EGoType.KGO) },
    { id: EGoType.ZGO, value: EGoType.ZGO, text: goTypeMap.get(EGoType.ZGO) },
]

const goStatusTypeOptions = [
    {
        id: EGoStatusType.All,
        value: EGoStatusType.All,
        text: goStatusTypeMap.get(EGoStatusType.All),
    },
    {
        id: EGoStatusType.Blocked,
        value: EGoStatusType.Blocked,
        text: goStatusTypeMap.get(EGoStatusType.Blocked),
    },
    {
        id: EGoStatusType.NotBlocked,
        value: EGoStatusType.NotBlocked,
        text: goStatusTypeMap.get(EGoStatusType.NotBlocked),
    },
]

const FilterActivated: FC<IFilter> = (props) => {
    const { t } = useTranslation()
    const { regionsOptions, renewableEnergyTypesOptions } =
        useAppSelector(selectDictionariesOptions) ?? {}

    const realRegionsOptions = useMemo(
        () =>
            regionsOptions
                ? [{ id: 0, value: 0, text: 'Любой', label: 'Любой' }, ...regionsOptions]
                : [{ id: 0, value: 0, text: 'Любой', label: 'Любой' }],
        [regionsOptions],
    )

    const realRenewableEnergyTypesOptions = useMemo(
        () =>
            renewableEnergyTypesOptions
                ? [{ id: 0, value: 0, text: 'Все', label: 'Все' }, ...renewableEnergyTypesOptions]
                : [{ id: 0, value: 0, text: 'Все', label: 'Все' }],
        [renewableEnergyTypesOptions],
    )

    return (
        <div className={style.controlsBlock}>
            <div className={style.leftGapBlockFirst}>
                <div>Вид ВИЭ</div>
                <div className={style.inputBlock}>
                    <DropdownSelector2
                        onChange={(value) => {
                            props.onChangeEnergyType(value)
                        }}
                        customStyle={{ width: '212px' }}
                        options={realRenewableEnergyTypesOptions}
                    />
                </div>
            </div>
            <div className={style.leftGapBlock}>
                <div>Тип ГО</div>
                <div className={style.inputBlock}>
                    <DropdownSelector2
                        customStyle={{ width: '212px' }}
                        options={goTypeOptions}
                        onChange={(value) => {
                            props.onChangeGoType(value)
                        }}
                    />
                </div>
            </div>
            <div className={style.leftGapBlock}>
                <div>Дата квалификации</div>
                <div className={style.inputBlock}>
                    <TimeRangePicker
                        onChange={props.onChangeDate}
                        customStyle={{ width: '212px', height: '32px' }}
                    />
                </div>
            </div>
            <div className={style.leftGapBlock}>
                <div>Субъект РФ (регион)</div>
                <div className={style.inputBlock}>
                    <DropdownSelector2
                        customStyle={{ width: '212px' }}
                        options={realRegionsOptions}
                        onChange={(value) => {
                            props.onChangeRegionType(value)
                        }}
                    />
                </div>
            </div>
            <div className={style.leftGapBlock}>
                <div>Статус блокировки</div>
                <div className={style.inputBlock}>
                    <DropdownSelector2
                        customStyle={{ width: '212px' }}
                        options={goStatusTypeOptions}
                        onChange={(value) => {
                            props.onChangeStatusTypes(value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default FilterActivated
