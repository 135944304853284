import { FC, useState } from 'react'
import { Alert, Input } from 'antd'
import ButtonsBlock from '../ButtonsBlock/ButtonsBlock'
import { Modal } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

interface ISendForRevisionModal {
    isModalVisible: boolean
    onConfirm: (text: string) => void
    onCancel: () => void
}

const SendForRevisionModal: FC<ISendForRevisionModal> = ({
    isModalVisible,
    onConfirm,
    onCancel,
}) => {
    const { t } = useTranslation()

    const [text, setText] = useState('')

    const handleCancel = () => {
        onCancel()
    }

    const handleConfirm = () => {
        onConfirm(text)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>
                {t('questionnaireApprovalItem.sendForRevision')}
            </div>

            <div className={style.dataBlock}>
                <div>
                    {t('questionnaireApprovalItem.comment')}
                    <span className={style.star}>*</span>
                </div>
                <TextArea
                    className={style.textArea}
                    placeholder={t('questionnaireApprovalItem.yourComment')}
                    rows={4}
                    onChange={(event) => setText(event.target.value)}
                    value={text}
                />
            </div>

            <Alert
                className={style.activationAlert}
                message={t('questionnaireApprovalItem.userSeeComment')}
                type="warning"
                showIcon
            />

            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleConfirm}
                    cancelAction={handleCancel}
                    confirmTitle={t('questionnaireApprovalItem.modalButtonTitle')}
                    disabled={!text}
                />
            </div>
        </Modal>
    )
}

export default SendForRevisionModal
