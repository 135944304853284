import { FC, useRef, useState } from 'react'
import { Input } from 'antd'
import style from './style.m.less'
import { Box } from '../../../ui/Box'

interface IFilter {
    onChange: (data: string | number, key: string, obj?: any) => void
    userType: number
    setUserType: (value: number) => void
}

const fieldNamer = (ut: number) => {
    switch (ut) {
        case 0:
            return {
                l: 'ИНН',
                ln: 'personal_inn',
                lkey: '05979071-632c-44fd-8fa8-dfec5848fd94',
                m: 'СНИЛС',
                mn: 'snils',
                mkey: 'fd0e8684-4524-4e9d-aa31-180386e92ef4',
            }
        case 1:
            return {
                l: 'ИНН',
                ln: 'personal_inn',
                lkey: '05979071-632c-44fd-8fa8-dfec5848fd94',
                m: 'ОГРНИП',
                mn: 'ogrn',
                mkey: 'fd0e8684-4524-4e9d-aa31-180386e92ef4',
            }
        case 2:
            return {
                l: 'КПП',
                ln: 'kpp',
                lkey: '9f458cd9-16ab-4c70-b3da-43ce2c894cdd',
                m: 'ОГРН',
                mn: 'ogrn',
                mkey: '9d9ae87b-7da8-4f49-9a63-fcf14f55e461',
            }
        default:
            return {
                l: '',
                ln: '',
                lkey: '',
                m: '',
                mn: '',
                mkey: '',
            }
    }
}

const ConsumerFilters: FC<IFilter> = ({ onChange, userType }: IFilter) => {
    const { l, ln, lkey, m, mn, mkey } = fieldNamer(userType)
    const divBlock: any = useRef(null)
    const isLegal = userType === 2

    const updateFilters = (data: any, key: string) => {
        onChange(data, key)
    }

    return (
        <div className={style.filter} ref={divBlock}>
            <Box direction="row" justify="space-between" margin="15px 0px 0px 0px">
                {isLegal ? (
                    <>
                        <div className={style.filterItem}>
                            <div className={style.label}>Сокращенное название организации</div>
                            <Input
                                placeholder="Введите cокращенное название организации"
                                key="ead35950-6987-480b-9270-e2464e540970"
                                className={style.inputMiddle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'company_name_short')
                                }
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>ИНН</div>
                            <Input
                                placeholder="Введите ИНН"
                                key="fa90f898-21d0-4353-99ff-6fc4587dea2c"
                                className={style.inputMiddle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'company_inn')
                                }
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={style.filterItem}>
                            <div className={style.label}>Фамилия</div>
                            <Input
                                placeholder="Введите фамилию"
                                key="4bf4dc8d-e175-4ad2-99d8-212eb0477a53"
                                className={style.inputLittle}
                                onChange={(event) => updateFilters(event.target.value, 'last_name')}
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>Имя</div>
                            <Input
                                placeholder="Введите имя"
                                key="9855f743-9f2f-44f2-a5cb-32da737abed2"
                                className={style.inputLittle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'first_name')
                                }
                            />
                        </div>
                        <div className={style.filterItem}>
                            <div className={style.label}>Отчество</div>
                            <Input
                                placeholder="Введите отчество"
                                key="69f979b8-4f4e-48af-9b98-322ad76bbe03"
                                className={style.inputLittle}
                                onChange={(event) =>
                                    updateFilters(event.target.value, 'middle_name')
                                }
                            />
                        </div>
                    </>
                )}
            </Box>

            <Box direction="row" justify="space-between" margin="15px 0px 0px 0px">
                <div className={style.filterItem}>
                    <div className={style.label}>{l}</div>
                    <Input
                        placeholder={`Введите ${l}`}
                        key={lkey}
                        className={style.inputMiddle}
                        onChange={(event) => updateFilters(event.target.value, ln)}
                    />
                </div>
                <div className={style.filterItem}>
                    <div className={style.label}>{m}</div>
                    <Input
                        placeholder={`Введите ${m}`}
                        key={mkey}
                        className={style.inputMiddle}
                        onChange={(event) => updateFilters(event.target.value, mn)}
                    />
                </div>
            </Box>
        </div>
    )
}

export default ConsumerFilters
