// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".descriptionText_gGxUP {\n  /* По вашему запросу ничего не найдено */\n  width: 139px;\n  height: 36px;\n  font-family: 'SF Pro Display';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 18px;\n  /* or 129% */\n  display: flex;\n  align-items: center;\n  text-align: center;\n  margin: auto;\n  /* Gray / gray-6 */\n  color: #BFBFBF;\n  /* Inside auto layout */\n  flex: none;\n  order: 1;\n  flex-grow: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Empty/style.m.less"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACtC,YAAA;EACA,YAAA;EAEA,6BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAAF,YAAY;EAGV,aAAA;EACA,mBAAA;EACA,kBAAA;EAEA,YAAA;EAFF,kBAAkB;EAMhB,cAAA;EAJF,uBAAuB;EASrB,UAAA;EACA,QAAA;EACA,YAAA;AAPJ","sourcesContent":[".descriptionText {\n    /* По вашему запросу ничего не найдено */\n    width: 139px;\n    height: 36px;\n\n    font-family: 'SF Pro Display';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 18px;\n    /* or 129% */\n\n    display: flex;\n    align-items: center;\n    text-align: center;\n\n    margin: auto;\n\n    /* Gray / gray-6 */\n\n    color: #BFBFBF;\n\n\n    /* Inside auto layout */\n\n    flex: none;\n    order: 1;\n    flex-grow: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionText": "descriptionText_gGxUP"
};
export default ___CSS_LOADER_EXPORT___;
