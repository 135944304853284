// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alert_oGOb4 {\n  padding: 20px 0 20px 24px;\n  display: inline-flex;\n  border-radius: 12px;\n  width: 600px;\n  border: none;\n}\n.info_yQeSH {\n  margin-top: 12px;\n}\n.addDataButton_XHBAE {\n  margin-top: 24px;\n}\n.buttonsBlock_QMZy1 {\n  display: flex;\n  margin-top: 32px;\n}\n.disabledButton_hc8yB {\n  border: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GOSubmitDataKU/components/AddManually/style.m.less"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,oBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,gBAAA;AAFJ;AAKA;EACI,SAAA;AAHJ","sourcesContent":[".alert {\n    padding: 20px 0 20px 24px;\n    display: inline-flex;\n    border-radius: 12px;\n    width: 600px;\n    border: none;\n}\n\n.info {\n    margin-top: 12px;\n}\n\n.addDataButton {\n    margin-top: 24px;\n}\n\n.buttonsBlock {\n    display: flex;\n    margin-top: 32px;\n}\n\n.disabledButton {\n    border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "alert_oGOb4",
	"info": "info_yQeSH",
	"addDataButton": "addDataButton_XHBAE",
	"buttonsBlock": "buttonsBlock_QMZy1",
	"disabledButton": "disabledButton_hc8yB"
};
export default ___CSS_LOADER_EXPORT___;
