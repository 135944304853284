// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginHeader_eFOdE {\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  font-family: 'Raleway';\n}\n.inputBlock_eBFle {\n  display: flex;\n  margin-top: 2px;\n}\n.input_qR925 {\n  border-radius: 8px;\n}\n.form_XavYP {\n  margin-top: 40px;\n}\n.formBlock_Ca1DU {\n  margin-top: 32px;\n}\n.formBlockInfo_UFdoh {\n  margin-top: 32px;\n  display: flex;\n  justify-content: space-between;\n}\n.buttonsBlock_SBagT {\n  margin-top: 40px;\n  display: flex;\n}\n.link_Urirz {\n  color: #296dff;\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Login/components/Auth/style.m.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACJ;AAEA;EACI,aAAA;EACA,eAAA;AAAJ;AAGA;EACI,kBAAA;AADJ;AAIA;EACI,gBAAA;AAFJ;AAKA;EACI,gBAAA;AAHJ;AAKI;EACI,gBAAA;EACA,aAAA;EACA,8BAAA;AAHR;AAOA;EACI,gBAAA;EACA,aAAA;AALJ;AAQA;EACI,cAAA;EACA,gBAAA;AANJ","sourcesContent":[".loginHeader {\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    font-family: 'Raleway';\n}\n\n.inputBlock {\n    display: flex;\n    margin-top: 2px;\n}\n\n.input {\n    border-radius: 8px;\n}\n\n.form {\n    margin-top: 40px;\n}\n\n.formBlock {\n    margin-top: 32px;\n\n    &Info {\n        margin-top: 32px;\n        display: flex;\n        justify-content: space-between;\n    }\n}\n\n.buttonsBlock {\n    margin-top: 40px;\n    display: flex;\n}\n\n.link {\n    color: #296dff;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginHeader": "loginHeader_eFOdE",
	"inputBlock": "inputBlock_eBFle",
	"input": "input_qR925",
	"form": "form_XavYP",
	"formBlock": "formBlock_Ca1DU",
	"formBlockInfo": "formBlockInfo_UFdoh",
	"buttonsBlock": "buttonsBlock_SBagT",
	"link": "link_Urirz"
};
export default ___CSS_LOADER_EXPORT___;
