import { FC } from 'react'
import { message, Alert, Input } from 'antd'
import { ButtonsBlock } from '../../../Edit/components'
import { Modal } from '../../../ui'
import style from './style.m.less'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

interface ISendForRevisionModal {
    isModalVisible: boolean
    setIsModalVisible: (value: boolean) => void
}

const SendForRevisionModal: FC<ISendForRevisionModal> = ({ isModalVisible, setIsModalVisible }) => {
    const { t } = useTranslation()

    const handleOk = () => {
        setIsModalVisible(false)
    }

    return (
        <Modal isModalVisible={isModalVisible}>
            <div className={style.modalHeader}>
                {t('questionnaireApprovalItem.sendForRevision')}
            </div>

            <div className={style.dataBlock}>
                <div>{t('questionnaireApprovalItem.comment')}</div>
                <TextArea
                    className={style.textArea}
                    placeholder={t('questionnaireApprovalItem.yourComment')}
                    rows={4}
                />
            </div>

            <Alert
                className={style.activationAlert}
                message={t('questionnaireApprovalItem.userSeeComment')}
                type="warning"
                showIcon
            />

            <div className={style.buttonsBlock}>
                <ButtonsBlock
                    confirmAction={handleOk}
                    cancelAction={handleOk}
                    confirmTitle={t('questionnaireApprovalItem.modalButtonTitle')}
                />
            </div>
        </Modal>
    )
}

export default SendForRevisionModal
