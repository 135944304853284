import { useLocation } from 'react-router-dom'

interface IEditData {
    type: string
}

export const useEditData = () => {
    const location = useLocation()
    const state = location.state as IEditData
    const { type } = state
    return type
}
