/* eslint-disable */
import './cadesplugin_api'
import CertificateUtils from './CertificateUtils'
import ab2str from 'arraybuffer-to-string'
import str2ab from 'string-to-arraybuffer'
import GostCryptoService from '../streebog/gostDigest'

let canAsync = true
let isReady = false

export default {
    async initialize() {
        await cadesplugin

        canAsync = cadesplugin.CreateObjectAsync != null

        isReady = true
    },

    async checkIfPluginIsReady() {
        return isReady
    },

    getCertificates() {
        return canAsync
            ? CertificateUtils.getAllCertificatesAsync()
            : CertificateUtils.getAllCertificatesSync()
    },

    getAllCertificates() {
        return canAsync
            ? CertificateUtils.checkAllCertificatesAsync()
            : CertificateUtils.checkAllCertificatesSync()
    },

    getCertificateData(thumbprint) {
        return canAsync
            ? CertificateUtils.getCertificateDataAsync(thumbprint)
            : CertificateUtils.getCertificateDataSync(thumbprint)
    },

    getCertificateRoles(thumbprint) {
        return canAsync
            ? CertificateUtils.getCertificateRolesAsync(thumbprint)
            : CertificateUtils.getCertificateRolesSync(thumbprint)
    },

    getCertificateSerialNumber(thumbprint) {
        return canAsync
            ? CertificateUtils.getCertificateSerialNumberAsync(thumbprint)
            : CertificateUtils.getCertificateSerialNumberSync(thumbprint)
    },

    parseCertificateSubject(thumbprint) {
        return canAsync
            ? CertificateUtils.parseCertificateSubjectAsync(thumbprint)
            : CertificateUtils.parseCertificateSubjectSync(thumbprint)
    },

    exportCertificate(thumbprint) {
        return canAsync
            ? CertificateUtils.exportCertificateAsync(thumbprint)
            : CertificateUtils.exportCertificateSync(thumbprint)
    },

    getCertificateLabel(certificateData) {
        return canAsync
            ? CertificateUtils.getCertificateLabelAsync(certificateData)
            : CertificateUtils.getCertificateLabelSync(certificateData)
    },

    getCertificateThumbprint(certificateData) {
        return canAsync
            ? CertificateUtils.getCertificateThumbprintAsync(certificateData)
            : CertificateUtils.getCertificateThumbprintSync(certificateData)
    },

    getCertificateValidToDate(certificateData) {
        return canAsync
            ? CertificateUtils.getCertificateValidToDateAsync(certificateData)
            : CertificateUtils.getCertificateValidToDateSync(certificateData)
    },

    async prepareDocumentSignInfo(document, currentUser) {
        let cert = await this.getCertificateData(currentUser.certificate)
        return {
            dateSign: new Date().toISOString(),
            certValidFromDate: cert.validFromDate.toISOString(),
            certValidToDate: cert.validToDate.toISOString(),
            id: currentUser.id,
            fio: currentUser.fio,
            position: currentUser.position,
            organizationId: currentUser.organizationId,
            certificate: currentUser.certificate,
        }
    },

    async sign(selectedCertificate, dataToSign) {
        try {
            // Подготавливаем данные для подписи
            let data = prepareDataToSign(dataToSign)
            // Подписываем данные
            return canAsync
                ? SignDataAsync(selectedCertificate, data)
                : SignDataSync(selectedCertificate, data)
        } catch (e) {
            console.log(e)
            let result = {}
            result.isSuccess = false
            result.error = e
            return result
        }
    },

    async signDocument(selectedCertificate, dataToSign) {
        try {
            // Подготавливаем данные для подписи
            let data = prepareDocumentToSign(dataToSign)
            // Подписываем данные
            return canAsync
                ? SignDocumentAsync(selectedCertificate, data)
                : SignDocumentSync(selectedCertificate, data)
        } catch (e) {
            console.log(e)
            let result = {}
            result.isSuccess = false
            result.error = e
            return result
        }
    },

    async verify(dataToVerify) {
        let oStore
        if (canAsync) {
            oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store')
            await oStore.Open(
                CAPICOM_CURRENT_USER_STORE,
                CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            )
        } else {
            oStore = cadesplugin.CreateObject('CAdESCOM.Store')
            oStore.Open(
                CAPICOM_CURRENT_USER_STORE,
                CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            )
        }
        let isDataVerified = VerifyData(dataToVerify)
        oStore.Close()
        return isDataVerified
    },

    async getKeyPairFromCertificate(certificate) {
        return canAsync
            ? this.getKeyPairFromCertificateAsync(certificate)
            : this.getKeyPairFromCertificateSync(certificate)
    },

    async getKeyPairFromCertificateAsync(certificate) {
        let oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store')
        await oStore.Open(
            CAPICOM_CURRENT_USER_STORE,
            CAPICOM_MY_STORE,
            CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        )

        let Certificates = await oStore.Certificates
        let oCertificates = await Certificates.Find(CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certificate)

        let Count = await oCertificates.Count
        if (Count === 0) {
            throw 'Certificate not found'
        }
        let oCertificate = await oCertificates.Item(1)
        let publicKeyObject = await oCertificate.PublicKey()
        let publicKey = await publicKeyObject.EncodedKey
        let privateKey = await oCertificate.PrivateKey
        return {
            publicKey: await publicKey.Value(),
            privateKey: privateKey,
        }
    },

    async getKeyPairFromCertificateSync(certificate) {
        return new Promise((resolve, reject) => {
            let oStore = cadesplugin.CreateObject('CAdESCOM.Store')
            oStore.Open(
                CAPICOM_CURRENT_USER_STORE,
                CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            )

            let Certificates = oStore.Certificates
            let oCertificates = Certificates.Find(CAPICOM_CERTIFICATE_FIND_SHA1_HASH, certificate)

            let Count = oCertificates.Count
            if (Count === 0) {
                reject('Certificate not found')
            }
            let oCertificate = oCertificates.Item(1)
            let publicKeyObject = oCertificate.PublicKey()
            let publicKey = publicKeyObject.EncodedKey
            let privateKey = oCertificate.PrivateKey
            resolve({
                publicKey: publicKey.Value,
                privateKey: privateKey,
            })
        })
    },

    hashToken(token) {
        const GostDigest = GostCryptoService()
        let gostDigest = new GostDigest()
        let tokenBuffer = hexStringToByte(token)
        let messageBuffer = str2ab(`\x19Signed Message:\n32`)
        let fullToken = appendBuffers(messageBuffer, tokenBuffer)
        let tokenHashBuffer = gostDigest.digest(fullToken)
        return ab2str(tokenHashBuffer, 'hex')
    },

    hashBytes(bytes) {
        const GostDigest = GostCryptoService()
        let gostDigest = new GostDigest()
        let tokenHashBuffer = gostDigest.digest(bytes)
        return ab2str(tokenHashBuffer, 'hex')
    },

    async SignCreate(selectedCertificate, dataToSign) {
        return canAsync
            ? this.SignCreateAsync(selectedCertificate, dataToSign)
            : this.SignCreateSync(selectedCertificate, dataToSign)
    },

    SignCreateAsync(selectedCertificate, dataToSign) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (arg) {
                    // await hashedData.SetHashValue(reverseBytes(hash))
                    // let reversedHash = await hashedData.Value
                    // console.log(`Reversed hashed token: ${reversedHash}`)

                    let oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')
                    yield oStore.Open(
                        CAPICOM_CURRENT_USER_STORE,
                        CAPICOM_MY_STORE,
                        CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                    )

                    let Certificates = yield oStore.Certificates
                    let oCertificates = yield Certificates.Find(
                        CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                        selectedCertificate,
                    )

                    let Count = yield oCertificates.Count
                    if (Count === 0) {
                        alert('Сертификат не найден!')
                        throw new Error('Certificate not found')
                    }
                    let oCertificate = yield oCertificates.Item(1)
                    console.log(`Cert thumbprint = ${yield oCertificate.Thumbprint}`)
                    var oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner')
                    yield oSigner.propset_Certificate(oCertificate)

                    // Создаем объект CAdESCOM.HashedData
                    var oHashedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')

                    // Инициализируем объект заранее вычисленным хэш-значением
                    // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
                    var oPublicKey = yield oCertificate.PublicKey()
                    var oAlgorithm = yield oPublicKey.Algorithm
                    var algorithmValue = yield oAlgorithm.Value

                    if (algorithmValue === CertificateUtils.gost2001Value) {
                        yield oHashedData.propset_Algorithm(
                            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411,
                        )
                    } else if (algorithmValue === CertificateUtils.gost256Value) {
                        yield oHashedData.propset_Algorithm(
                            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
                        )
                    } else if (algorithmValue === CertificateUtils.gost512Value) {
                        yield oHashedData.propset_Algorithm(
                            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512,
                        )
                    } else {
                        console.warn('Wrong certificate algorithm:', algorithmValue)
                    }

                    yield oHashedData.propset_DataEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY)
                    yield oHashedData.Hash(btoa(dataToSign))

                    var oRawSignature = yield cadesplugin.CreateObjectAsync('CAdESCOM.RawSignature')
                    // Вычисляем значение подписи
                    try {
                        var sRawSignature = yield oRawSignature.SignHash(oHashedData, oCertificate)
                        yield oRawSignature.VerifyHash(oHashedData, oCertificate, sRawSignature)
                        console.log('Signature verified')
                    } catch (err) {
                        alert('Failed to create signature. Error: ' + cadesplugin.getLastError(err))
                        return
                    }

                    oStore.Close()
                    arg[0](sRawSignature)
                },
                resolve,
                reject,
            )
        })
    },

    SignCreateSync(selectedCertificate, dataToSign) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (arg) {
                    // await hashedData.SetHashValue(reverseBytes(hash))
                    // let reversedHash = await hashedData.Value
                    // console.log(`Reversed hashed token: ${reversedHash}`)

                    let oStore = cadesplugin.CreateObject('CAdESCOM.Store')
                    oStore.Open(
                        CAPICOM_CURRENT_USER_STORE,
                        CAPICOM_MY_STORE,
                        CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                    )

                    let Certificates = oStore.Certificates
                    let oCertificates = Certificates.Find(
                        CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                        selectedCertificate,
                    )

                    let Count = oCertificates.Count
                    if (Count === 0) {
                        throw 'Certificate not found'
                    }
                    let oCertificate = oCertificates.Item(1)
                    console.log(`Cert thumbprint = ${oCertificate.Thumbprint}`)
                    var oSigner = cadesplugin.CreateObject('CAdESCOM.CPSigner')
                    oSigner.Certificate = oCertificate

                    // Создаем объект CAdESCOM.HashedData
                    var oHashedData = cadesplugin.CreateObject('CAdESCOM.HashedData')

                    // Инициализируем объект заранее вычисленным хэш-значением
                    // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
                    var oPublicKey = oCertificate.PublicKey()
                    var oAlgorithm = oPublicKey.Algorithm
                    var algorithmValue = oAlgorithm.Value

                    if (algorithmValue === CertificateUtils.gost2001Value) {
                        oHashedData.Algorithm = cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411
                    } else if (algorithmValue === CertificateUtils.gost256Value) {
                        oHashedData.Algorithm =
                            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256
                    } else if (algorithmValue === CertificateUtils.gost512Value) {
                        oHashedData.Algorithm =
                            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512
                    } else {
                        console.warn('Wrong certificate algorithm:', algorithmValue)
                    }

                    oHashedData.DataEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY
                    oHashedData.Hash(btoa(dataToSign))

                    var oRawSignature = cadesplugin.CreateObject('CAdESCOM.RawSignature')
                    // Вычисляем значение подписи
                    try {
                        var sRawSignature = oRawSignature.SignHash(oHashedData, oCertificate)
                        oRawSignature.VerifyHash(oHashedData, oCertificate, sRawSignature)
                        console.log('Signature verified')
                    } catch (err) {
                        alert('Failed to create signature. Error: ' + cadesplugin.getLastError(err))
                        return
                    }

                    oStore.Close()
                    arg[0](sRawSignature)
                },
                resolve,
                reject,
            )
        })
    },

    async ContainerSignCreate(selectedCertificate, dataToSign) {
        return canAsync
            ? this.ContainerSignCreateAsync(selectedCertificate, dataToSign)
            : this.ContainerSignCreateSync(selectedCertificate, dataToSign)
    },

    ContainerSignCreateAsync(selectedCertificate, dataToSign) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (arg) {
                    let oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store')
                    yield oStore.Open(
                        CAPICOM_CURRENT_USER_STORE,
                        CAPICOM_MY_STORE,
                        CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                    )

                    let Certificates = yield oStore.Certificates
                    let oCertificates = yield Certificates.Find(
                        CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                        selectedCertificate,
                    )

                    let Count = yield oCertificates.Count
                    if (Count === 0) {
                        throw 'Certificate not found'
                    }
                    let oCertificate = yield oCertificates.Item(1)
                    // console.log(`Cert thumbprint = ${yield oCertificate.Thumbprint}`)
                    var oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner')
                    yield oSigner.propset_Certificate(oCertificate)

                    // Создаем объект CAdESCOM.HashedData
                    var oHashedData = yield cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')

                    // Инициализируем объект заранее вычисленным хэш-значением
                    // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
                    yield oHashedData.propset_Algorithm(101)
                    yield oHashedData.propset_DataEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY)

                    yield oHashedData.Hash(dataToSign)
                    let v1 = yield oHashedData.Value
                    // console.log('Hashed value', v1);
                    // let reversedValue = reverseBytes(v1);
                    let reversedValue = v1
                    // console.log('Reversed bytes', reversedValue);

                    yield oHashedData.propset_DataEncoding(0)

                    yield oHashedData.SetHashValue(reversedValue)

                    var oRawSignature = yield cadesplugin.CreateObjectAsync('CAdESCOM.RawSignature')
                    // Вычисляем значение подписи
                    try {
                        var sRawSignature = yield oRawSignature.SignHash(oHashedData, oCertificate)
                        yield oRawSignature.VerifyHash(oHashedData, oCertificate, sRawSignature)
                        console.log('Signature verified')
                    } catch (err) {
                        alert('Failed to create signature. Error: ' + cadesplugin.getLastError(err))
                        return
                    }

                    oStore.Close()
                    arg[0](sRawSignature)
                },
                resolve,
                reject,
            )
        })
    },

    ContainerSignCreateSync(selectedCertificate, dataToSign) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (arg) {
                    let oStore = cadesplugin.CreateObject('CAdESCOM.Store')
                    oStore.Open(
                        CAPICOM_CURRENT_USER_STORE,
                        CAPICOM_MY_STORE,
                        CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                    )

                    let Certificates = oStore.Certificates
                    let oCertificates = Certificates.Find(
                        CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                        selectedCertificate,
                    )

                    let Count = oCertificates.Count
                    if (Count === 0) {
                        throw 'Certificate not found'
                    }
                    let oCertificate = oCertificates.Item(1)
                    console.log(`Cert thumbprint = ${oCertificate.Thumbprint}`)
                    var oSigner = cadesplugin.CreateObject('CAdESCOM.CPSigner')
                    oSigner.Certificate = oCertificate

                    // Создаем объект CAdESCOM.HashedData
                    var oHashedData = cadesplugin.CreateObject('CAdESCOM.HashedData')

                    // Инициализируем объект заранее вычисленным хэш-значением
                    // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
                    oHashedData.Algorithm = 101
                    oHashedData.DataEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY

                    oHashedData.Hash(dataToSign)
                    let v1 = oHashedData.Value
                    console.log('Hashed value', v1)
                    // let reversedValue = reverseBytes(v1);
                    let reversedValue = v1
                    console.log('Reversed bytes', reversedValue)

                    oHashedData.DataEncoding = 0

                    oHashedData.SetHashValue(reversedValue)

                    var oRawSignature = cadesplugin.CreateObject('CAdESCOM.RawSignature')
                    // Вычисляем значение подписи
                    try {
                        var sRawSignature = oRawSignature.SignHash(oHashedData, oCertificate)
                        oRawSignature.VerifyHash(oHashedData, oCertificate, sRawSignature)
                        console.log('Signature verified')
                    } catch (err) {
                        alert('Failed to create signature. Error: ' + cadesplugin.getLastError(err))
                        return
                    }

                    oStore.Close()
                    arg[0](sRawSignature)
                },
                resolve,
                reject,
            )
        })
    },

    generateHmacKey() {
        let crypto = window.crypto || window.msCrypto
        let key = crypto.getRandomValues(new Uint8Array(32))
        return ab2str(key, 'hex')
    },

    async calculateHmac(stringedKey, document) {
        let key = hexStringToByte(stringedKey)
        // HMAC( key, document ) = H( ( key xor opad ) || H( ( key xor ipad ) || document ) ):
        const ipad = Array(32).fill(0x36)
        const opad = Array(32).fill(0x5c)

        // op1 = K xor opad
        let op1 = opad.map((value, index) => opad[index] ^ key[index])
        // op2 = key xor ipad
        let op2 = ipad.map((value, index) => ipad[index] ^ key[index])
        // op3 = ( key xor ipad ) || document
        let documentArrayBuffer = hexStringToByte(document)
        let resultArrayLength1 = Math.max(documentArrayBuffer.byteLength, op2.length)
        let op3 = []
        for (let i = 0; i < resultArrayLength1; i++) {
            op3.push(documentArrayBuffer[i] | op2[i])
        }
        // op4 = H (op3)
        let stringedOp3 = ab2str(op3, 'hex')
        let op4 = await this.hashData(stringedOp3)
        // op5 = op1 || op4
        let bufferedOp4 = hexStringToByte(op4)
        let op5 = []
        let resultArrayLength2 = Math.max(bufferedOp4.byteLength, op1.length)
        for (let i = 0; i < resultArrayLength2; i++) {
            op5.push(bufferedOp4[i] | op1[i])
        }
        // op6 = H (op5)
        let stringedOp5 = ab2str(op5, 'hex')
        let op6 = await this.hashData(stringedOp5)
        return '0x' + op6
    },

    async hashData(data) {
        return canAsync ? this.hashDataAsync(data) : this.hashDataSync(data)
    },

    async hashDataAsync(data) {
        let oHashedData = await cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')
        await oHashedData.propset_Algorithm(
            cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
        )
        await oHashedData.propset_DataEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY)

        await oHashedData.Hash(data)
        return oHashedData.Value
    },

    async hashDataSync(data) {
        let oHashedData = cadesplugin.CreateObject('CAdESCOM.HashedData')

        oHashedData.Algorithm = cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256
        oHashedData.DataEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY

        oHashedData.Hash(data)
        return oHashedData.Value
    },

    // Документ подписан, если у него есть хотя бы один непустой элемент 'ds:SignatureValue'
    isDocumentSigned(serializedDocument) {
        let xmlDocument = new DOMParser().parseFromString(serializedDocument, 'text/xml')
        let signatures = xmlDocument.getElementsByTagName('ds:SignatureValue')
        if (signatures.length > 0) {
            return Array.from(signatures).some((sig) => Boolean(sig.textContent))
        }
        return false
    },
}

const CAPICOM_CURRENT_USER_STORE = 2
const CAPICOM_MY_STORE = 'My'
const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2
const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0

const CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE = 2

var CADESCOM_BASE64_TO_BINARY = 1
var CADESCOM_CADES_BES = 1

const XML_TEMPLATE =
    '<?xml version="1.0" encoding="UTF-8"?>\n' +
    '<Envelope xmlns="urn:envelope">\n' +
    '  <Data xml:id="dataID">\n' +
    '     {dataToSign}\n' +
    '  </Data>\n' +
    '  <ds:Signature xmlns:ds="http://www.w3.org/2000/09/xmldsig#">\n' +
    '  <ds:SignedInfo>\n' +
    '      <ds:CanonicalizationMethod Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#"/>\n' +
    '      <ds:SignatureMethod Algorithm="urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256"/>\n' +
    '      <ds:Reference URI="#dataID">\n' +
    '      <ds:Transforms>\n' +
    '          <ds:Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature"/>\n' +
    '      </ds:Transforms>\n' +
    '      <ds:DigestMethod Algorithm="urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256"/>\n' +
    '      <ds:DigestValue/>\n' +
    '      </ds:Reference>\n' +
    '  </ds:SignedInfo>\n' +
    '  <ds:SignatureValue/>\n' +
    '  <ds:KeyInfo/>\n' +
    '  </ds:Signature>\n' +
    '</Envelope>'

const SIGNATURE_TEMPLATE = `<ds:Signature xmlns:ds=\"http://www.w3.org/2000/09/xmldsig#\">
  <ds:SignedInfo>
      <ds:CanonicalizationMethod Algorithm=\"http://www.w3.org/2001/10/xml-exc-c14n#\"/>
      <ds:SignatureMethod Algorithm=\"urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256\"/>
      <ds:Reference URI=\"#dataID\">
      <ds:Transforms>
          <ds:Transform Algorithm=\"http://www.w3.org/2000/09/xmldsig#enveloped-signature\"/>
      </ds:Transforms>
      <ds:DigestMethod Algorithm=\"urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256\"/>
      <ds:DigestValue/>
      </ds:Reference>
  </ds:SignedInfo>
  <ds:SignatureValue/>
  <ds:KeyInfo/>
  </ds:Signature>`

function prepareDataToSign(data) {
    return XML_TEMPLATE.replace('{dataToSign}', data)
}

function prepareDocumentToSign(document) {
    let domParser = new DOMParser()
    let xmlDocument = domParser.parseFromString(document, 'text/xml')
    let xmlSignature = domParser.parseFromString(SIGNATURE_TEMPLATE, 'text/xml')
    // Добавляем дополнительный пустой шаблон для подписи
    xmlDocument.firstChild.appendChild(xmlSignature.firstChild)
    return new XMLSerializer().serializeToString(xmlDocument)
}

async function SignDataAsync(selectedCertificate, dataToSign) {
    try {
        let oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store')
        await oStore.Open(
            CAPICOM_CURRENT_USER_STORE,
            CAPICOM_MY_STORE,
            CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        )

        let Certificates = await oStore.Certificates
        let oCertificates = await Certificates.Find(
            CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
            selectedCertificate,
        )

        let Count = await oCertificates.Count
        if (Count == 0) {
            throw 'Certificate not found'
        }
        let oCertificate = await oCertificates.Item(1)

        let oSigner = await cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner')
        await oSigner.propset_Certificate(oCertificate)

        let oSignedXML = await cadesplugin.CreateObjectAsync('CAdESCOM.SignedXML')

        await oSignedXML.propset_Content(dataToSign)
        await oSignedXML.propset_SignatureType(CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE)

        let sSignedMessage = await oSignedXML.Sign(oSigner)

        await oStore.Close()

        let result = {}
        result.isSuccess = true
        result.signedXml = sSignedMessage

        return result
    } catch (e) {
        console.log(e)
        let result = {}
        result.isSuccess = false
        result.error = e.message
        return result
    }
}

function SignDataSync(selectedCertificate, dataToSign) {
    return new Promise((resolve, reject) => {
        try {
            let oStore = cadesplugin.CreateObject('CAdESCOM.Store')
            oStore.Open(
                CAPICOM_CURRENT_USER_STORE,
                CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            )

            let Certificates = oStore.Certificates
            let oCertificates = Certificates.Find(
                CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                selectedCertificate,
            )

            let Count = oCertificates.Count
            if (Count === 0) {
                reject('Certificate not found')
            }
            let oCertificate = oCertificates.Item(1)

            let oSigner = cadesplugin.CreateObject('CAdESCOM.CPSigner')
            oSigner.Certificate = oCertificate

            let oSignedXML = cadesplugin.CreateObject('CAdESCOM.SignedXML')

            oSignedXML.Content = dataToSign
            oSignedXML.SignatureType = CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE

            let sSignedMessage = oSignedXML.Sign(oSigner)

            oStore.Close()

            let result = {}
            result.isSuccess = true
            result.signedXml = sSignedMessage

            resolve(result)
        } catch (e) {
            console.log(e)
            let result = {}
            result.isSuccess = false
            result.error = e.message
            resolve(result)
        }
    })
}

async function SignDocumentAsync(selectedCertificate, dataToSign) {
    try {
        let oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store')
        await oStore.Open(
            CAPICOM_CURRENT_USER_STORE,
            CAPICOM_MY_STORE,
            CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        )

        let Certificates = await oStore.Certificates
        let oCertificates = await Certificates.Find(
            CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
            selectedCertificate,
        )

        let Count = await oCertificates.Count
        if (Count === 0) {
            throw 'Certificate not found'
        }
        let oCertificate = await oCertificates.Item(1)

        let oSigner = await cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner')
        await oSigner.propset_Certificate(oCertificate)

        let oSignedXML = await cadesplugin.CreateObjectAsync('CAdESCOM.SignedXML')

        await oSignedXML.propset_Content(dataToSign)
        await oSignedXML.propset_SignatureType(CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE)

        let sSignedMessage = await oSignedXML.Sign(oSigner)

        await oStore.Close()

        let result = {}
        result.isSuccess = true
        result.signedXml = sSignedMessage

        return result
    } catch (e) {
        console.log(e)
        let result = {}
        result.isSuccess = false
        result.error = e.message
        return result
    }
}

function SignDocumentSync(selectedCertificate, dataToSign) {
    return new Promise((resolve, reject) => {
        try {
            let oStore = cadesplugin.CreateObject('CAdESCOM.Store')
            oStore.Open(
                CAPICOM_CURRENT_USER_STORE,
                CAPICOM_MY_STORE,
                CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            )

            let Certificates = oStore.Certificates
            let oCertificates = Certificates.Find(
                CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
                selectedCertificate,
            )

            let Count = oCertificates.Count
            if (Count === 0) {
                reject('Certificate not found')
            }
            let oCertificate = oCertificates.Item(1)

            let oSigner = cadesplugin.CreateObject('CAdESCOM.CPSigner')
            oSigner.Certificate = oCertificate

            let oSignedXML = cadesplugin.CreateObject('CAdESCOM.SignedXML')

            oSignedXML.Content = dataToSign
            oSignedXML.SignatureType = CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE

            let sSignedMessage = oSignedXML.Sign(oSigner)

            oStore.Close()

            let result = {}
            result.isSuccess = true
            result.signedXml = sSignedMessage

            resolve(result)
        } catch (e) {
            console.log(e)
            let result = {}
            result.isSuccess = false
            result.error = e.message
            resolve(result)
        }
    })
}

async function VerifyData(dataToVerify) {
    try {
        let result = ''
        if (canAsync) {
            let oSignedXML = await cadesplugin.CreateObjectAsync('CAdESCOM.SignedXML')
            result = await oSignedXML.Verify(dataToVerify)
        } else {
            let oSignedXML = cadesplugin.CreateObject('CAdESCOM.SignedXML')
            result = oSignedXML.Verify(dataToVerify)
        }
        console.log(result)
        return true
    } catch (e) {
        return false
    }
}

async function InitializeHashedData(hashAlg, sHashValue) {
    // Создаем объект CAdESCOM.HashedData
    var oHashedData = await cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')

    // Инициализируем объект заранее вычисленным хэш-значением
    // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
    await oHashedData.propset_Algorithm(hashAlg)
    await oHashedData.SetHashValue(sHashValue)

    return oHashedData
}

function reverseBytes(string) {
    return string
        .match(/[a-fA-F0-9]{2}/g)
        .reverse()
        .join('')
}

function hexStringToByte(str) {
    if (!str) {
        return new Uint8Array()
    }

    var a = []
    for (var i = 0, len = str.length; i < len; i += 2) {
        a.push(parseInt(str.substr(i, 2), 16))
    }

    return new Uint8Array(a)
}

function appendBuffers(buffer1, buffer2) {
    var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength)
    tmp.set(new Uint8Array(buffer1), 0)
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength)
    return tmp.buffer
}
