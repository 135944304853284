// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".userPanel_KtF8T {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  font-size: 11px;\n}\n.separator_urUB8 {\n  height: 14px;\n  border-left: 0.5px solid #8c8c8c;\n  margin: 0 12px;\n}\n.lang_R7k4m {\n  cursor: pointer;\n}\n.logoutLink_hEg2T {\n  margin-left: 12px;\n  color: #ff4d4f !important;\n  opacity: 0.9;\n}\n.link_XMiwY {\n  height: 17px;\n  color: #434343;\n}\n.linkActive__oxG6 {\n  height: 17px;\n  color: #108061;\n}\n.disabled_NKH_M {\n  cursor: default;\n  color: #BFBFBF;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/components/UserPanel/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AACJ;AAEA;EACI,YAAA;EACA,gCAAA;EACA,cAAA;AAAJ;AAGA;EACI,eAAA;AADJ;AAIA;EACI,iBAAA;EACA,yBAAA;EACA,YAAA;AAFJ;AAKA;EACI,YAAA;EACA,cAAA;AAHJ;AAII;EACI,YAAA;EACA,cAAA;AAFR;AAOA;EACI,eAAA;EACA,cAAA;AALJ","sourcesContent":[".userPanel {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 11px;\n}\n\n.separator {\n    height: 14px;\n    border-left: 0.5px solid #8c8c8c;\n    margin: 0 12px;\n}\n\n.lang {\n    cursor: pointer;\n}\n\n.logoutLink {\n    margin-left: 12px;\n    color: #ff4d4f !important;\n    opacity: 0.9;\n}\n\n.link {\n    height: 17px;\n    color: #434343;\n    &Active {\n        height: 17px;\n        color: #108061;\n    }\n}\n\n// удалить когда включат уведомления\n.disabled {\n    cursor: default;\n    color: #BFBFBF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userPanel": "userPanel_KtF8T",
	"separator": "separator_urUB8",
	"lang": "lang_R7k4m",
	"logoutLink": "logoutLink_hEg2T",
	"link": "link_XMiwY",
	"linkActive": "linkActive__oxG6",
	"disabled": "disabled_NKH_M"
};
export default ___CSS_LOADER_EXPORT___;
