import { FC, useEffect, useState } from 'react'
import { Divider } from 'antd'
import { CenteredSpin, Pagination } from '../../../ui'
import style from './style.m.less'
import request from '../../../../services/request'
import { FilterActivated, GeneratorCard } from '../../components'
import { IGenerator } from '../../components/GeneratorCard/GeneratorCard'
import { ELoading } from '../../../../store/dictionaries/interfaces'
import { useAppSelector } from '../../../../store/hooks'
import moment from 'moment'
import { EGoStatusType } from '../../../../constants/approval'

interface IGenaratorData {
    gos_active: IGenerator[]
    total: number
}

const PAGE_SIZE = 10

const NonActiveGenerators: FC = () => {
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE)

    const [generators, setGenerators] = useState<IGenaratorData>()
    const [loading, setLoading] = useState(true)

    const [energyType, setEnergyType] = useState<number>()
    const [goType, setGoType] = useState<number>()
    const [dateFilter, setDateFilter] = useState<[moment.Moment, moment.Moment] | null>()
    const [region, setRegion] = useState<number>()
    const [hasBlocks, setHasBlocks] = useState<EGoStatusType>(EGoStatusType.All)

    useEffect(() => {
        const loadGenerators = async () => {
            try {
                setLoading(true)
                const res = await request.post('/api/generators/v1/gos-active', {
                    limit: pageSize,
                    offset: (page - 1) * pageSize,
                    filters: {
                        is_active: false,
                        renewable_energy_type_id: energyType,
                        go_type_id: goType,
                        qualification_date_from: dateFilter
                            ? moment(dateFilter[0]).startOf('day')
                            : undefined,
                        qualification_date_to: dateFilter
                            ? moment(dateFilter[1]).endOf('day')
                            : undefined,
                        region_id: region,
                        has_blocks:
                            hasBlocks === EGoStatusType.Blocked
                                ? true
                                : hasBlocks === EGoStatusType.NotBlocked
                                ? false
                                : null,
                    },
                })
                setGenerators(res.data)
            } finally {
                setLoading(false)
            }
        }
        loadGenerators()
    }, [energyType, goType, dateFilter, region, hasBlocks, page, pageSize])

    const dictionariesLoading = useAppSelector((state) => state.dictionaries.loading)

    const realLoading = dictionariesLoading !== ELoading.Loaded || loading || !generators

    return (
        <>
            <div className={style.contentWrapper}>
                <FilterActivated
                    onChangeEnergyType={setEnergyType}
                    onChangeGoType={setGoType}
                    onChangeRegionType={setRegion}
                    onChangeDate={setDateFilter}
                    onChangeStatusTypes={setHasBlocks}
                />
                <Divider />
                <div className={style.cardsBlock}>
                    {realLoading && <CenteredSpin />}
                    {generators?.gos_active?.map((item, index) => {
                        const { generator_id } = item
                        return (
                            <div key={generator_id}>
                                <GeneratorCard generatorData={item} />
                                {index !== generators?.gos_active?.length - 1 && <Divider />}
                            </div>
                        )
                    })}
                </div>
            </div>
            <Pagination
                customStyle={{ width: '100%' }}
                current={page}
                paginationChange={(receivedPage: number, size?: number) => {
                    setPage(receivedPage)
                    setPageSize(size ?? PAGE_SIZE)
                }}
                total={generators?.total ?? 0}
                showSizeChanger
                pageSize={pageSize}
            />
        </>
    )
}

export default NonActiveGenerators
