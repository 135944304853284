import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '../../../ui'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import truncate from 'lodash/truncate'
import style from './style.m.less'

interface IBlockingBlock {
    blockingInfo: any
    isDeposit: boolean
    id: string
}

const BlockingBlock: FC<IBlockingBlock> = ({ blockingInfo, isDeposit, id }) => {
    const { t } = useTranslation()
    const { ts_created, comment, event_id } = blockingInfo

    return (
        <div className={style.repaymentBlock}>
            <div className={style.repaymentHeader}>{`Блокировка сертификата ${
                isDeposit ? '(залог)' : ''
            }`}</div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Дата блокировки</div>
                <div className={style.nameBlock}>{moment(ts_created).format('DD.MM.YYYY')}</div>
            </div>
            <div className={style.objectBlock}>
                <div className={style.infoBlock}>Основание</div>
                <div className={style.nameBlock}>
                    {truncate(comment, {
                        length: 100,
                    })}
                </div>
            </div>
            <Link to={`/audit/${event_id}`}>
                <IconButton customStyle={{ marginTop: '24px' }}>Подробнее о блокировке</IconButton>
            </Link>
        </div>
    )
}

export default BlockingBlock
