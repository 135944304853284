// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerInfo_mlr9T {\n  position: relative;\n  bottom: 8px;\n  display: flex;\n  width: 183px;\n  justify-content: space-around;\n  flex-direction: column;\n}\n.footerInfoBlock_yISVp {\n  display: flex;\n  align-items: center;\n}\n.iconInfo_nZysC {\n  margin-left: 8px;\n}\n.phoneBlock_kgeET {\n  line-height: 19px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/components/FooterInfo/style.m.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,6BAAA;EACA,sBAAA;AACJ;AAEA;EACI,aAAA;EACA,mBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ;AAIA;EACI,iBAAA;AAFJ","sourcesContent":[".footerInfo {\n    position: relative;\n    bottom: 8px;\n    display: flex;\n    width: 183px;\n    justify-content: space-around;\n    flex-direction: column;\n}\n\n.footerInfoBlock {\n    display: flex;\n    align-items: center;\n}\n\n.iconInfo {\n    margin-left: 8px;\n}\n\n.phoneBlock {\n    line-height: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerInfo": "footerInfo_mlr9T",
	"footerInfoBlock": "footerInfoBlock_yISVp",
	"iconInfo": "iconInfo_nZysC",
	"phoneBlock": "phoneBlock_kgeET"
};
export default ___CSS_LOADER_EXPORT___;
