import { FC } from 'react'
import { Import, Tile, Location } from '../../../assets/icons'
import { IconButton, SortButton, InputSearch } from '../'
import { PageType } from '../interfaces'
import style from './style.m.less'

interface IControls {
    pageType: PageType
}

const Controls: FC<IControls> = ({ pageType }) => {
    return (
        <div className={style.controlsMain}>
            <SortButton />
            <IconButton
                icon={<Import />}
                customIconStyle={{ marginRight: '8px', marginTop: '3px' }}
            >
                Выгрузить данные
            </IconButton>
            <InputSearch isLong={pageType === 'owners'} />
            {pageType === 'generators' && (
                <div className={style.controlsSwitch}>
                    <IconButton icon={<Tile />} />
                    <IconButton icon={<Location />} />
                </div>
            )}
        </div>
    )
}

export default Controls
