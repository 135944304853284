import AntIcon from '@ant-design/icons'
import { FC } from 'react'

export const ArrowRight: FC = () => (
    <AntIcon
        component={() => (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.0013 18.3337C14.6037 18.3337 18.3346 14.6027 18.3346 10.0003C18.3346 5.39795 14.6037 1.66699 10.0013 1.66699C5.39893 1.66699 1.66797 5.39795 1.66797 10.0003C1.66797 14.6027 5.39893 18.3337 10.0013 18.3337Z"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.08203 10H12.082"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.418 12.5L12.918 10L10.418 7.5"
                    stroke="#528EFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )}
    />
)
