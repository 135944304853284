// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profileMain_F3sbb {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 10px 100px;\n  margin: 0 auto;\n  width: 1300px;\n}\n.mainHeader_ckE10 {\n  color: #262626;\n  font-weight: 600;\n  font-size: 25px;\n  line-height: 29px;\n  margin-bottom: 32px;\n}\n.tabs_zGa6Z {\n  font-weight: 600;\n  color: #595959;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,wBAAA;EACA,cAAA;EACA,aAAA;AACJ;AAEA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,cAAA;AADJ","sourcesContent":[".profileMain {\n    display: flex;\n    flex-direction: column;\n    padding: 40px 10px 100px;\n    margin: 0 auto;\n    width: 1300px;\n}\n\n.mainHeader {\n    color: #262626;\n    font-weight: 600;\n    font-size: 25px;\n    line-height: 29px;\n    margin-bottom: 32px;\n}\n\n.tabs {\n    font-weight: 600;\n    color: #595959;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileMain": "profileMain_F3sbb",
	"mainHeader": "mainHeader_ckE10",
	"tabs": "tabs_zGa6Z"
};
export default ___CSS_LOADER_EXPORT___;
