// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".infoAlert_mJ1Jp {\n  padding: 8px 16px;\n  border-radius: 12px;\n}\n.infoAlert_mJ1Jp.ant-alert-info {\n  border: 1px solid #BAE7FF;\n}\n.infoAlert_mJ1Jp.ant-alert-success {\n  border: 1px solid #D9F7BE;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Alert/style.m.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,mBAAA;AACJ;AACI;EACI,yBAAA;AACR;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".infoAlert {\n    padding: 8px 16px;\n    border-radius: 12px;\n\n    &:global(.ant-alert-info) {\n        border: 1px solid #BAE7FF;\n    }\n\n    &:global(.ant-alert-success) {\n        border: 1px solid #D9F7BE;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoAlert": "infoAlert_mJ1Jp"
};
export default ___CSS_LOADER_EXPORT___;
