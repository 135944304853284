import moment from 'moment'
import style from './style.m.less'

export interface IHistoryItem {
    ts_created: string
    need_block_add: boolean
    function_ids: number[]
    comment: string
    documents?: {
        id: string
        file_name: string
        file_size: number // размер в байтах
        ts_created: string
    }[]
}

const renderDate = (date: Date) => (date ? moment(date).format('DD.MM.YYYY') : '-')

const documents = (docs: any[]) => (docs?.length ? `Есть (${docs.length})` : 'Нет')

const blockAction = (props: boolean) => {
    return props ? 'Блокировка' : 'Снятие блокировки'
}

const getFunctionNames = (props: number[]) => {
    const arr: string[] = []
    props.forEach((item) => {
        if (item === 1) {
            arr.push('Фиксация Атрибутов генерации')
        }
        if (item === 2) {
            arr.push('Выпуск ЗИ')
        }
        if (item === 3) {
            arr.push('Передача АГ по ЗД')
        }
        if (item === 4) {
            arr.push('Собственное погашение АГ')
        }
    })
    return arr.map((item) => <div>{item}</div>)
}

const renderComment = (comment: string) => <div className={style.commentCell}>{comment}</div>

export const lockedColumns = [
    {
        title: 'Дата операции',
        dataIndex: 'ts_created',
        key: 'ts_created',
        render: renderDate,
        sorter: (a: IHistoryItem, b: IHistoryItem) => {
            return new Date(a.ts_created).getTime() - new Date(b.ts_created).getTime()
        },
    },
    {
        title: 'Действие',
        dataIndex: 'need_block_add',
        key: 'need_block_add',
        render: blockAction,
    },
    {
        title: 'Функционал',
        dataIndex: 'function_ids',
        key: 'function_ids',
        render: getFunctionNames,
    },
    {
        title: 'Основание',
        dataIndex: 'comment',
        key: 'comment',
        render: renderComment,
    },
    {
        title: 'Приложенные документы',
        dataIndex: 'documents',
        key: 'documents',
        render: documents,
    },
]
