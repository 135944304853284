// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainInfoWrapper_X2Kq1 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.infoBlock_Xclxv {\n  margin-left: 8px;\n}\n.iconWrapper_RZWat {\n  display: flex;\n  cursor: pointer;\n}\n.iconWrapperRight_B5jVW {\n  display: flex;\n  margin-left: auto;\n}\n.documentBlock_Kb8wo {\n  padding: 8px 0 8px 8px;\n}\n.documentName_FEtaG {\n  font-size: 13px;\n  line-height: 16px;\n  color: #262626;\n}\n.documentSize_RoeWL {\n  color: #bfbfbf;\n  font-size: 12px;\n}\n.documentDescription_gQTJ5 {\n  margin-top: 8px;\n  font-size: 12px;\n}\n.emptyDivider_NYav8 {\n  height: 16px;\n}\n.textArea_WqGIc {\n  width: 400px;\n  border-radius: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/Document/style.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,aAAA;EACA,eAAA;AADJ;AAIA;EACI,aAAA;EACA,iBAAA;AAFJ;AAKA;EACI,sBAAA;AAHJ;AAMA;EACI,eAAA;EACA,iBAAA;EACA,cAAA;AAJJ;AAOA;EACI,cAAA;EACA,eAAA;AALJ;AAQA;EACI,eAAA;EACA,eAAA;AANJ;AASA;EACI,YAAA;AAPJ;AAUA;EACI,YAAA;EACA,kBAAA;AARJ","sourcesContent":[".mainInfoWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.infoBlock {\n    margin-left: 8px;\n}\n\n.iconWrapper {\n    display: flex;\n    cursor: pointer;\n}\n\n.iconWrapperRight {\n    display: flex;\n    margin-left: auto;\n}\n\n.documentBlock {\n    padding: 8px 0 8px 8px\n}\n\n.documentName {\n    font-size: 13px;\n    line-height: 16px;\n    color: #262626;\n}\n\n.documentSize {\n    color: #bfbfbf;\n    font-size: 12px;\n}\n\n.documentDescription {\n    margin-top: 8px;\n    font-size: 12px;\n}\n\n.emptyDivider {\n    height: 16px;\n}\n\n.textArea {\n    width: 400px;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainInfoWrapper": "mainInfoWrapper_X2Kq1",
	"infoBlock": "infoBlock_Xclxv",
	"iconWrapper": "iconWrapper_RZWat",
	"iconWrapperRight": "iconWrapperRight_B5jVW",
	"documentBlock": "documentBlock_Kb8wo",
	"documentName": "documentName_FEtaG",
	"documentSize": "documentSize_RoeWL",
	"documentDescription": "documentDescription_gQTJ5",
	"emptyDivider": "emptyDivider_NYav8",
	"textArea": "textArea_WqGIc"
};
export default ___CSS_LOADER_EXPORT___;
