import { useEffect, useState } from 'react'
import request from '../../../../services/request'
import { lockedColumns, IHistoryItem } from './lockedColumns'
import { useParams } from 'react-router-dom'
import { OperationHistoryDetailsModal } from '../'
import { Table } from 'antd'

const PAGE_SIZE = 10

const LockedTable = () => {
    const { id } = useParams<{ id: string }>()

    const [data, setData] = useState<IHistoryItem[]>()
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState(1)
    const [total, setTotal] = useState<number>()
    const [pageSize, setPageSize] = useState(PAGE_SIZE)
    const [historyItem, setHistoryItem] = useState<IHistoryItem>()

    useEffect(() => {
        const load = async () => {
            try {
                setLoading(true)
                const res = await request.post<{
                    block_events: IHistoryItem[]
                    total: number
                }>(`/api/generators/v1/go-block-history?generator_id=${id}`, {
                    limit: pageSize,
                    offset: (pagination - 1) * pageSize,
                })
                const dataWithKeys = (res.data.block_events || []).map((row, index) => ({...row, key: index }))
                setData(dataWithKeys)
                setTotal(res.data.total)
            } finally {
                setLoading(false)
            }
        }
        load()
    }, [id, pagination, pageSize])

    return (
        <>
            <Table
                dataSource={data}
                columns={lockedColumns}
                loading={loading}
                pagination={{
                    position: ['bottomLeft'],
                    onChange: (page, pageSize) => {
                        setPagination(page)
                        setPageSize(pageSize)
                    },
                    total: total,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    current: pagination,
                }}
                onRow={(record) => ({
                    onClick: () => {
                        setHistoryItem(record)
                    },
                })}
            />
            {historyItem && (
                <OperationHistoryDetailsModal
                    isModalVisible={!!historyItem}
                    onClose={() => setHistoryItem(undefined)}
                    historyItem={historyItem}
                />
            )}
        </>
    )
}

export default LockedTable
